import { proxy } from "valtio";
import { loginStore } from "../state/CommonState";

let dtactionStore = proxy({
    list: [],
    current: {},
    previous: null,
    entity: {},
    booking: {},
    listLoadCounter: 0,
    triggerActionVisible: false,
    view: 'list',
    myActionVisible: false,
    modalVisible: false,
    modalVisible1:false,
    setComplete: () => {
        dtactionStore.current.status = 'COMPLETED'
        dtactionStore.current.completedBy = loginStore.email
        dtactionStore.current.completedOn = Date.now()
    },
    triggerModalVisible: false,
    modalMessage: '',
    setModalMessage: (message) => {
        dtactionStore.modalMessage = message
        dtactionStore.triggerModalVisible = true
    },
    setModalEnd: (message) => {
        dtactionStore.modalMessage = message
        dtactionStore.triggerModalVisible = true
        setTimeout(() => {
            dtactionStore.triggerModalVisible = false
        }, 2000);
    }
})
export { dtactionStore }