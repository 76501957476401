import { Box, Typography } from "@mui/material";
import { AttributeDetails } from "../mui-components/AttributeValueBox";
import Utils from "../util/Utils";
import { useSnapshot } from "valtio";
import { viewBookingStore } from "../state/CommonState";
import { dsrstatic_cn } from "../my-actions/dsr_static";
import { Title } from "@mui/icons-material";
import { ServerLists } from "../StaticData";





export function CustomsDetail({data}) {
    const booking = viewBookingStore.current;
    let exportClearance = booking?.exportClearance
    let importClearance = booking?.importClearance
    if (!data?.mismatch && data?.type == 'boe') {
        let boe = [{ label: 'Bill of Entry Checklist', value: `${data?.customerCompletedOn.replace(/\//g, '-').split('-').reverse().join('-')}` },
            { label: 'Bill of Entry Number', value: data.no },
            { label: 'Bill of Entry Date', value: `${data.date?.slice(4, 8)}-${data.date?.slice(2, 4)}-${data.date?.slice(0, 2)}` },
            { label: 'Out of Charge Date', value:`${data?.oocDt?.slice(4, 8)}-${data?.oocDt.slice(2, 4)}-${data?.oocDt.slice(0, 2)}` }
            ]
        importClearance = boe
        exportClearance = [{label: 'Customs Entry', value: ''},
            {label: 'Customs Reference Number', value: ''},
            {label: 'Submission to Customs Date', value: ''},
            {label: 'Cleared for export', value: ''}]
    }
    if (!data?.mismatch && data?.type == 'sbc') {
        let sbc = [{ label: 'Shipping Bill Checklist', value: `${data?.customerCompletedOn.replace(/\//g, '-').split('-').reverse().join('-')}` },
            { label: 'Shipping Bill Number', value: data.no },
            { label: 'Shipping Bill Date', value: `${data.date?.slice(4, 8)}-${data.date?.slice(2, 4)}-${data.date?.slice(0, 2)}` },
            { label: 'Let-Export-Order Date', value:`${data?.leoDt?.slice(4, 8)}-${data?.leoDt.slice(2, 4)}-${data?.leoDt.slice(0, 2)}` }
            ]
            
        exportClearance = sbc
        importClearance = [{label: 'Customs Entry', value: ''},
            {label: 'Customs Reference Number', value: ''},
            {label: 'Submission to Customs Date', value: ''},
            {label: 'Cleared for import', value: ''}]
    }
    console.log("booking",booking,data);
    return <Box sx={{ display: "flex", flexDirection: "column", gap: 1, paddingX: "10px" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Export Clearance ({Utils.extractCountry(booking.origin)})</Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <AttributeDetails details={exportClearance?.map((exportdetail) => (
                {
                    title: exportdetail.label,
                    value: Utils.isValidDate1(Utils.dateConversion(exportdetail.value)) ?
                        Utils.formatDateBooking(Utils.dateConversion(exportdetail.value), false)
                        : exportdetail.value
                }
            ))} />
        </Box>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Import Clearance ({Utils.extractCountry(booking.destination)})</Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <AttributeDetails details={importClearance?.map((exportdetail) => (
                {
                    title: exportdetail.label,
                    value:
                        Utils.isValidDate1(Utils.dateConversion(exportdetail.value)) ?
                            Utils.formatDateBooking(Utils.dateConversion(exportdetail.value), false)
                            : exportdetail.value

                }
            ))} />
        </Box>
    </Box>
}
