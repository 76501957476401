import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { configStore, EntityV2Store, loginStore,entityStore } from "../state/CommonState";
import AguserApi from "../state/AguserApi";
import { useEffect, useRef, useState } from "react";
import Dtinput from "./cps/Dtinputs";
import Dtselect from "./cps/Dtselect";
import Dtaddress from "./cps/Dtaddress";
import { commodityList } from "../StaticData";
import DtApi from "../state/dt-api";
import "./dt.css"
import { Dtyesno } from "./cps/Dtyesno";
import { loader } from "../util/loader";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import EntityApi from "../state/entity-api";
import BranchApi from "../state/branch-api";


export default function DtQuoteView() {
    useSnapshot(DtStore)
    useSnapshot(EntityV2Store)
    useSnapshot(configStore)
    const [customerlist, setCustomerlist] = useState([])
    const [salesList, setSalesList] = useState({})
    // const [branches, setbranches] = useState([])

  
    

    const ref = useRef(null)
    let dtStore = DtStore.DT
    console.log(dtStore,'dt12..');
    for (let i = 0; i < dtStore.details.length; i++) {
        let delivery = []
        for (let j = 0; j < dtStore.details[i].delivery.length; j++) {
            if (dtStore.details[i].delivery[j].address.trim().length > 0) {
                delivery.push(dtStore.details[i].delivery[j])
            }
        }
        if (JSON.stringify(dtStore.details[i].delivery) != JSON.stringify(delivery)) {
            dtStore.details[i].delivery = delivery
        }
        let vehicle = []
        for (let j = 0; j < dtStore.details[i].vehicle.length; j++) {
            if (dtStore.pricing_type == 'vehicle') {
                if (dtStore.details[i].vehicle[j].vehicle_amount.trim().length > 0) {
                    vehicle.push(dtStore.details[i].vehicle[j])
                }
            } else {
                if (dtStore.details[i]?.vehicle[j]?.amt_per_ton.trim().length > 0 && dtStore.details[i]?.vehicle[j]?.minimum.trim().length > 0) {
                    vehicle.push(dtStore.details[i].vehicle[j])
                }
            }

        }
        if (JSON.stringify(dtStore.details[i].vehicle) != JSON.stringify(vehicle)) {
            dtStore.details[i].vehicle = vehicle
        }
    }







    let quoteTC = DtStore.DT.tc
    let customTC = DtStore.DT.ctc.split(/\r?\n/)
    quoteTC.map(e => customTC.push(e))
    quoteTC = customTC

    const setLists = (users) => {
        let agusers = users.filter(e => e.email?.endsWith('agraga.com'))
        agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))
        let l = {}
        for (let i = 0; i < agusers.length; i++) {
            l[agusers[i]['email']] = `${agusers[i]['firstName']} ${agusers[i]['lastName']}`
        }
        setSalesList(l)
    }





    const getlist = async () => {
        if (Object.keys(customerlist).length == 0) {
            await AguserApi.getList(setLists)
        }
        if (Object.keys(customerlist).length == 0) {
            let l = {}
            for (let i = 0; i < EntityV2Store.list.length; i++) {
                l[EntityV2Store.list[i]['entityId']] = EntityV2Store.list[i]['entityName']
            }
            setCustomerlist(l)
        }
    }
    useEffect(() => {
        getlist()
    }, [])

    //  function checkBranchGst(data){
    //     let isGstAvail = false;
    //     console.log(data,'oooo');
    //     data.map((r,i)=>{
    //     if( r.status === "VERIFIED" && r.gst.length > 0 && r.branchAddresses[0].address.length > 0) {
    //       isGstAvail= true
    //       return isGstAvail
    //         }
    //     })
    //     return isGstAvail  
    //    }

       function checkEntityStatus(status){
        let isStatusAvail = false; 
        if(status === "VERIFIED") {
          isStatusAvail= true
          return isStatusAvail
        }  
        return isStatusAvail  
       }

    return (
        <>{
            (dtStore && Object.keys(customerlist).length > 0 && Object.keys(salesList).length > 0) ? <>
                <div class="columns">
                    <div class="column is-full">
                        <h1 className="title">Contract {`(${dtStore._id})`} - Domestic Transport<span style={{position:"relative",left:"15em"}}></span></h1>
                        
                         <div className="columns is-multiline is-vcentered">
                            <div className="column is-2">
                                Customer
                            </div>
                            <div className="column is-1">
                                :
                            </div>
                            <div className="column is-3">
                                <b>{customerlist[dtStore['entityId']]}</b>
                            </div>
                            <div className="column is-2">
                                Valid Upto
                            </div>
                            <div className="column is-1">
                                :
                            </div>
                            <div className="column is-3">
                                <b>{dtStore['valid_upto']}</b>
                            </div>
                            <div className="column is-2">
                                Relationship Manager
                            </div>
                            <div className="column is-1">
                                :
                            </div>
                            <div className="column is-3">
                                <b>{salesList[dtStore['relationship_manager']]}</b>
                            </div>
                            <div className="column is-2">
                                Commodity Type
                            </div>
                            <div className="column is-1">
                                :
                            </div>
                            <div className="column is-3">
                                <b>{dtStore['commodity_type']}</b>
                            </div>
                            <div className="column is-full">
                                <table className="table is-bordered">
                                    <thead>
                                        <tr>
                                            <th>Pickup Address</th>
                                            <th>Delivery Address</th>
                                            <th>Currency</th>
                                            <th>Vehicle Type</th>
                                            {
                                                (dtStore['pricing_type'] == 'vehicle') ?
                                                <>
                                                <th>Amount</th>
                                                </>:<>
                                                
                                                <th>Amount Per Ton</th>
                                                <th>Minimum Applicable</th>
                                                </>
                                            }
                                            

                                            <th>Delivery Appointment Needed?</th>
                                            {
                                                EntityV2Store.list.map(v2Data=>
                                                    v2Data['entityId'] == dtStore['entityId'] && v2Data['status'] != 'DEACTIVATED' ?
                                                (dtStore['status'] == 'ACTIVE' && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.DTBOOKING)) ? 
                                                <th>Action</th> : ""
                                                :""
                                                )
                                                }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dtStore.details.map((v, i) => {
                                                return v.vehicle.map((v1, i1) => {
                                                    let cp = 3
                                                    let rp = 0
                                                        rp = v.vehicle.length
                                                    
                                                    if(i1==0){
                                                        return <tr>
                                                        <td rowSpan={rp} >
                                                            
                                                        {
                                                                (v['pickup_2'] == undefined || v['pickup_2'].trim().length==0)?<>{v.pickup}</>:
                                                                <>
                                                                {v.pickup}<hr style={{ margin: "0px" }} />{v.pickup_2}
                                                                </>
                                                            }
                                                        </td>
                                                        <td rowSpan={rp}>
                                                            {
                                                                v.delivery.map((v1, i1) => {
    
                                                                    return <>
                                                                        {v1.address}
                                                                        {
                                                                            (i1 != v.delivery.length - 1) ? <hr style={{ margin: "0px" }} /> : ""
                                                                        }
                                                                    </>
                                                                })
                                                            }
                                                        </td>
                                                        <td rowSpan={rp}>
                                                            {v.currency}
                                                        </td>{
                                                            (dtStore.pricing_type == 'vehicle')?<>
                                                            <td >{v1.vehicle_type}</td>
                                                            <td >{v1.vehicle_amount}</td>
                                                            </>:<>
                                                                <td>{v1.vehicle_type}</td>
                                                                <td >{v1.amt_per_ton}</td>
                                                                <td >{v1.minimum}</td>
                                                                </>
                                                        }
                                                        <td rowSpan={rp}>
                                                        {v.delivery_ap}
                                                    </td>
                                                    {
                                                          EntityV2Store.list.map(v2Data=>
                                                            v2Data['entityId'] == dtStore['entityId'] && v2Data['status'] != 'DEACTIVATED' ?
                                                                (dtStore['status'] == 'ACTIVE' && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.DTBOOKING)) ?<td rowSpan={rp}><button className="button is-link" style={{ width: "100%" }} onClick={ () => {
                                                                        EntityApi.getEntity(dtStore.entityId,entityData=>{
                                                                        let entitystatusResult =  checkEntityStatus(entityData.status)
                                                                        if(entitystatusResult){
                                                                            DtStore.DTBooking = DtStore.createbooking(dtStore,v.contract_id)
                                                                            DtStore.bview = "create"
                                                                            DtStore.valid = false
                                                                            configStore.dtCurrentView = 'Booking'
                                                                        }else{
                                                                            configStore.setModalMessage(`Please submit entity details for verification to proceed with the booking`)
                                                                        }
                                                                    })
                                                    }}>Book Now</button></td>:""
                                                                :""                                                          
                                                          )
                                            }

                                                    </tr>
                                                    }else{
                                                        return <tr>
                                                        {
                                                            (dtStore.pricing_type == 'vehicle')?<>
                                                            <td >{v1.vehicle_type}</td>
                                                            <td >{v1.vehicle_amount}</td>
                                                            </>:<>
                                                                <td >{v1.vehicle_type}</td>
                                                                <td >{v1.amt_per_ton}</td>
                                                                <td >{v1.minimum}</td>
                                                                </>
                                                        }
                                                    </tr>
                                                    }
                                                })
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="column is-full">
                                <h3>Terms & Conditions</h3>
                            </div>
                            <div className="column is-full">
                                <ol type="1" style={{ paddingLeft: '20px' }}>
                                    <ol>
                                        {
                                            quoteTC.filter(e => e.length > 0).map((e, i) => <li>{e}</li>)
                                        }
                                    </ol>
                                </ol>

                            </div>
                        </div>
                    </div>
                </div>
            </> : ""
        }


        </>
    )
}
