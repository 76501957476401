import { useSnapshot } from "valtio";
import { BreadCrumb2 } from "../mui-components/BreadCrumb2";
import { breadCrumbStore } from "../state/CommonState";
import { QuotationAndContract } from "./QuotationAndContract";
import { Constants } from "../util/Utils";
import { ChooseSchedulePage } from "./ChooseSchedulePage";
import { ReviewDetailsPage } from "./PricingDetailsPage";
import { ShippingDetailsV2 } from "./ShippingDetailsV2";

const breadCrumbComponents = {
      [Constants.createbookings] : <QuotationAndContract/>,  
      [Constants.chooseschedule] : <ChooseSchedulePage/>,
      [Constants.bookingsummary] : <ReviewDetailsPage/>,
      "Additional Details" : <ShippingDetailsV2/>,
}

export function HomeMainNewV3(){
    const {currentComponent} =  useSnapshot(breadCrumbStore);
    
    return <div className="flex flex-col gap-[8px]">
         <p className="text-blue-dark font-bold text-xl font-roboto">{currentComponent} {breadCrumbStore.id?.length>0 ? ( "- "+breadCrumbStore.id) : ""}</p>
         <BreadCrumb2/>
         { currentComponent ? <>{breadCrumbComponents[currentComponent]}</> : <></>}
    </div>
}