import Api from "./Api";

const ENTITYDSRURI = {
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/dsr/createDsr`,
    GET: (id)=> `${process.env.REACT_APP_API_SERVER}/api/v1/dsr/checkDsr/entity/${id}`,

}


export default class EntityDSRApi {

    static createDsr(Data,callback) {
        console.log("about to call api.post");
        Api.post(ENTITYDSRURI.POST, Data, (data) => {
          console.log("data", data);
          if(data) {
            if(callback){
              callback(data)
            }
            // setloading(false)
          }
          // handleApiResponse(data);
          
        })
        
      }
      static checkDsr(entityId,callback) {
        console.log("about to call api.post");
        Api.fetch(ENTITYDSRURI.GET(entityId), (data) => {
          console.log("data", data);
          if(data) {
            if(callback){
              callback(data)
            }
            // setloading(false)
          }
          // handleApiResponse(data);
          
        })
        
      }
}