import { routerStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import ListEntitiesApproval from "./list-entityApproval"
import EditCreditApproval from "./edit-creditApproval.js"
import EditEntityApproval from "./edit-entityApproval"
import EditDriverApproval from "./edit-driverApproval"
import EditVehicleApproval from "./edit-vehicleApproval"
import EditBranchApproval from "./edit-branchApproval"
import EditUserApproval from "./edit-userApproval.js"


export default function MainEntityApproval() {
  const store = useSnapshot(routerStore)
  console.log(store.creditApplicationView)
  switch (store.creditApplicationView) {
    case "list":
      return <ListEntitiesApproval />
    case "view":
      return <EditCreditApproval mode="view" />
    case "edit":
      return <EditCreditApproval mode="edit" />
    case "new":
      return <EditCreditApproval mode="new" />
    case "entityAPP":
      return <EditEntityApproval mode="entityAPP" />
    case "driverAPP":
      return <EditDriverApproval mode="driverAPP" />
    case "vehicleAPP":
      return <EditVehicleApproval mode="vehicleAPP" />
    case "branchAPP":
      return <EditBranchApproval mode="branchAPP" />
    case "userAPP":
      return <EditUserApproval mode="userAPP" />
    default:
      return <ListEntitiesApproval />
  }
}
