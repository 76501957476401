import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { configStore, entityStore } from "../state/CommonState";
import AguserApi from "../state/AguserApi";
import { useEffect, useRef, useState } from "react";
import Dtinput from "./cps/Dtinputs";
import Dtselect from "./cps/Dtselect";
import Dtaddress from "./cps/Dtaddress";
import { commodityList } from "../StaticData";
import DtApi from "../state/dt-api";
import "./dt.css"
import { Dtyesno } from "./cps/Dtyesno";
import { loader } from "../util/loader";
export default function DtQuoteMailView({customerlist,salesList}) {
    useSnapshot(DtStore)
    useSnapshot(configStore)
    const ref = useRef(null)
    let dtStore = DtStore.DT
    for (let i = 0; i < dtStore.details.length; i++) {
        let delivery = []
        for (let j = 0; j < dtStore.details[i].delivery.length; j++) {
            if (dtStore.details[i].delivery[j].address.trim().length > 0) {
                delivery.push(dtStore.details[i].delivery[j])
            }
        }
        if (JSON.stringify(dtStore.details[i].delivery) != JSON.stringify(delivery)) {
            dtStore.details[i].delivery = delivery
        }
        let vehicle = []
        for (let j = 0; j < dtStore.details[i].vehicle.length; j++) {
            if (dtStore.pricing_type == 'vehicle') {
                if (dtStore.details[i].vehicle[j].vehicle_amount.trim().length > 0) {
                    vehicle.push(dtStore.details[i].vehicle[j])
                }
            } else {
                if (dtStore.details[i].vehicle[j].amt_per_ton.trim().length > 0 && dtStore.details[i].vehicle[j].minimum.trim().length > 0) {
                    vehicle.push(dtStore.details[i].vehicle[j])
                }
            }

        }
        if (JSON.stringify(dtStore.details[i].vehicle) != JSON.stringify(vehicle)) {
            dtStore.details[i].vehicle = vehicle
        }
    }
    let quoteTC = DtStore.DT.tc
    let customTC = DtStore.DT.ctc.split(/\r?\n/)
    quoteTC.map(e => customTC.push(e))
    quoteTC = customTC
    return (
        <>{
            (dtStore && Object.keys(customerlist).length > 0 && Object.keys(salesList).length > 0) ? <>
                <div class="columns">
                    <div class="column is-full">
                        <h1 className="title">Contract {`(${dtStore._id})`} - Domestic Transport</h1>
                        <div className="columns is-multiline">
                            <div className="columns" style={{display:"flex",width:"100%"}}>
                                    <div className="column" style={{position:"relative",left:"2em"}}>
                                        Customer
                                    </div>
                                    <div className="column">
                                        :
                                    </div>
                                    <div className="column" style={{position:"relative",right:"8em"}}>
                                        <b>{customerlist[dtStore['entityId']]}</b>
                                    </div>
                                    <div className="column" style={{position:"relative",left:"2em"}}>
                                    Valid Upto
                                    </div>
                                    <div className="column">
                                    :
                                    </div>
                                    <div className="column" style={{position:"relative",right:"8em"}}>
                                    <b>{dtStore['valid_upto']}</b>
                                    </div>
                            </div>
                            <div className="column" style={{display:"flex",width:"100%"}}>
                                <div className="column">
                                    Relationship Manager
                                </div>
                                <div className="column">
                                    :
                                </div>
                                <div className="column" style={{position:"relative",right:"8em"}}>
                                    <b>{salesList[dtStore['relationship_manager']]}</b>
                                </div>
                                <div className="column" style={{position:"relative",left:"1em"}}>
                                    Commodity Type
                                </div>
                                <div className="column">
                                    :
                                </div>
                                <div className="column" style={{position:"relative",right:"8em"}}>
                                    <b>{dtStore['commodity_type']}</b>
                                </div>
                            </div>
                            
                           
                            <div className="column is-full">
                                <table className="table is-bordered">
                                    <thead>
                                        <tr>
                                            <th>Pickup Address</th>
                                            <th>Delivery Address</th>
                                            <th>Currency</th>
                                            <th>Vehicle Type</th>
                                            {
                                                (dtStore['pricing_type'] == 'vehicle') ?
                                                <>
                                                <th>Amount</th>
                                                </>:<>
                                                
                                                <th>Amount Per Ton</th>
                                                <th>Minimum Applicable</th>
                                                </>
                                            }
                                                                        <th>Delivery Appointment Needed?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dtStore.details.map((v, i) => {
                                                return v.vehicle.map((v1, i1) => {
                                                    let cp = 3
                                                    let rp = 0
                                                        rp = v.vehicle.length
                                                    
                                                    if(i1==0){
                                                        return <tr>
                                                        <td rowSpan={rp} >
                                                            
                                                            {
                                                                (v['pickup_2'] == undefined || v['pickup_2'].trim().length==0)?<>{v.pickup}</>:
                                                                <>
                                                                {v.pickup}<hr style={{ margin: "0px" }} />{v.pickup_2}
                                                                </>
                                                            }
                                                        </td>
                                                        <td rowSpan={rp}>
                                                            {
                                                                v.delivery.map((v1, i1) => {
    
                                                                    return <>
                                                                        {v1.address}
                                                                        {
                                                                            (i1 != v.delivery.length - 1) ? <hr style={{ margin: "0px" }} /> : ""
                                                                        }
                                                                    </>
    
    
                                                                })
                                                            }
                                                        </td>
                                                        <td rowSpan={rp}>
                                                            {v.currency}
                                                        </td>{
                                                            (dtStore.pricing_type == 'vehicle')?<>
                                                            <td >{v1.vehicle_type}</td>
                                                            <td >{v1.vehicle_amount}</td>
                                                            </>:<>
                                                                <td>{v1.vehicle_type}</td>
                                                                <td >{v1.amt_per_ton}</td>
                                                                <td >{v1.minimum}</td>
                                                                </>
                                                        }
                                                        <td rowSpan={rp}>
                                                        {v.delivery_ap}
                                                    </td>
                                                    </tr>
                                                    }else{
                                                        return <tr>
                                                        {
                                                            (dtStore.pricing_type == 'vehicle')?<>
                                                            <td >{v1.vehicle_type}</td>
                                                            <td >{v1.vehicle_amount}</td>
                                                            </>:<>
                                                                <td >{v1.vehicle_type}</td>
                                                                <td >{v1.amt_per_ton}</td>
                                                                <td >{v1.minimum}</td>
                                                                </>
                                                        }
                                                        
                                                    </tr>
                                                    }
                                                })
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="column is-full">
                                <h3>Terms & Conditions</h3>
                            </div>
                            <div className="column is-full">
                                <ol type="1" style={{ paddingLeft: '20px' }}>
                                    <ol>
                                        {
                                            quoteTC.filter(e => e.length > 0).map((e, i) => <li>{e}</li>)
                                        }
                                    </ol>
                                </ol>

                            </div>
                        </div>
                    </div>
                </div>
            </> : ""
        }
        </>
    )
}
