import { proxy } from "valtio";
import { maxcargoWeightPerContainer } from "../StaticData";
import QuotationUtils from "../util/quotationUtil";
import Utils from "../util/Utils";
import Api from "./Api";
import {
  airChargesStore,
  cargoStore,
  chargesStore1,
  containerStore,
  fclChargesStore,
  quoteDataRec,
} from "./CommonState";



const getUNCodeFromLocationString = (locString) =>{
  if(locString.includes('(') && locString.includes(')')){
    return locString
    .trim()
    .slice(locString.lastIndexOf("(") + 1, locString.length - 1)
    .trim();
  }else{
    return locString
  }
}
  
const isUnLoc =  (locString) => {
  locString = getUNCodeFromLocationString(locString);
  return locString.length === 5 && /^[A-Z0-9]+$/.test(locString)
};

const QuotationValidationStore = proxy({
  message: null,
  originmsg : "",
  destinationmsg:"",
  quotationValidMap: {
    shipmentType: true,
    shipmentScope: true,
    entityId: true,
    entityName: true,
    carrier: true,
    portOfLoading: true,
    portOfReceipt: true,
    portOfDischarge: true,
    finalPlaceOfDelivery: true,
    cargoReadinessDate: true,
    validUpto: true,
    transitTime: true,
    quoteClass: true,
    packingGroup: true,
    unnumber: true,
    minTemperature: true,
    maxTemperature: true,
    temperature: true,
    cargoValue: true,
    airline: true,
    procurement:true
  },
  validate: (key) => {
    console.log('validating', key);
    QuotationValidationStore.message = null;
    switch (key) {
      case "shipmentType":
      case "shipmentScope":
      case "entityId":
        case "entityName":
      case "cargoReadinessDate":
      case "validUpto":
      case "procurement":
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec[key] !== "";
        break;
      case "carrier":
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec.shipmentType.toLowerCase().includes("air")
            ? true
            : quoteDataRec[key] !== "";
        break;
      case "airline":
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec.shipmentType.toLowerCase().includes("air")
            ? quoteDataRec[key] !== ""
            : true;
        break;
      case "portOfLoading":
        // Utils.validunloc1(quoteDataRec[key],key)  
        break;
      case "portOfReceipt":
        if(quoteDataRec.shipmentType.toLowerCase().includes("air")){
          QuotationValidationStore.quotationValidMap[key] = true
        }
        // else{
        //  Utils.validunloc1(quoteDataRec[key],key)
        // }
        break;
      case "portOfDischarge":
        // Utils.validunloc1(quoteDataRec[key],key)
        break;
      case "finalPlaceOfDelivery":
        if(quoteDataRec.shipmentType.toLowerCase().includes("air")){
          QuotationValidationStore.quotationValidMap[key] = true
        }
        // else{
          // Utils.validunloc1(quoteDataRec[key],key)
        // }
        break;
      case "transitTime":
        QuotationValidationStore.quotationValidMap.transitTime =
          quoteDataRec.transitTime !== "" && quoteDataRec.transitTime > 0;
        break;
      case "quoteClass":
      case "packingGroup":
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec.hazardous.toLowerCase() === "yes"
            ? quoteDataRec[key] !== ""
            : true;
        break;
      case "unnumber":
        // QuotationValidationStore.quotationValidMap[key] =
        //   quoteDataRec.hazardous.toLowerCase() === "yes"
        //     ? quoteDataRec[key] !== ""
        //     : true;
        // break;
        const unNumberRegex = /^[A-Z]{2}[0-9]{4}$/;
        QuotationValidationStore.quotationValidMap.unnumber = 
        quoteDataRec.unnumber !== "" && unNumberRegex.test(quoteDataRec.unnumber)
        break;
      case "temperature":
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes"
            ? quoteDataRec.shipmentType.toLowerCase().includes("air")
              ? true
              : quoteDataRec[key] !== "" &&
                quoteDataRec[key] >= -35 &&
                quoteDataRec[key] <= 30
            : true;
        break;
      case "cargoValue": // In below, the validation negated by adding > 100000 to existing condition
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes"
            ? quoteDataRec.shipmentType.toLowerCase().includes("air")
              ? true
              : quoteDataRec[key] !== "" &&
                quoteDataRec[key] > 0 &&
                parseInt(quoteDataRec[key]) <= 100000 || parseInt(quoteDataRec[key]) > 100000
            : true;
        break;
      case "minTemperature":
      case "maxTemperature":
        QuotationValidationStore.quotationValidMap[key] =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes" &&
          quoteDataRec.shipmentType.toLowerCase().includes("air")
            ? quoteDataRec[key] !== "" &&
              parseFloat(quoteDataRec[key]) >= -35 &&
              parseFloat(quoteDataRec[key]) <= 30 &&
              parseFloat(quoteDataRec.minTemperature) <
                parseFloat(quoteDataRec.maxTemperature)
            : true;
        break;
      default:
        QuotationValidationStore.quotationValidMap.shipmentType =
          quoteDataRec.shipmentType !== "";
        QuotationValidationStore.quotationValidMap.shipmentScope =
          quoteDataRec.shipmentScope !== "";
        QuotationValidationStore.quotationValidMap.carrier =
          QuotationValidationStore.quotationValidMap.carrier =
            quoteDataRec.shipmentType.toLowerCase().includes("air")
              ? true
              : quoteDataRec.carrier !== "";
        QuotationValidationStore.quotationValidMap.portOfLoading =
          quoteDataRec.portOfLoading !== "" && QuotationValidationStore.quotationValidMap.portOfLoading
        QuotationValidationStore.quotationValidMap.portOfReceipt =
          quoteDataRec.portOfReceipt === "" || QuotationValidationStore.quotationValidMap.portOfReceipt
        QuotationValidationStore.quotationValidMap.portOfDischarge =
          quoteDataRec.portOfDischarge === ""  || QuotationValidationStore.quotationValidMap.portOfDischarge
        QuotationValidationStore.quotationValidMap.finalPlaceOfDelivery =
          quoteDataRec.finalPlaceOfDelivery !== "" && QuotationValidationStore.quotationValidMap.finalPlaceOfDelivery
        QuotationValidationStore.quotationValidMap.cargoReadinessDate =
          quoteDataRec.cargoReadinessDate !== "";
        QuotationValidationStore.quotationValidMap.validUpto =
          quoteDataRec.validUpto !== "";
        QuotationValidationStore.quotationValidMap.transitTime =
          quoteDataRec.transitTime !== "" && quoteDataRec.transitTime > 0;
        QuotationValidationStore.quotationValidMap.quoteClass =
          quoteDataRec.hazardous.toLowerCase() === "yes"
            ? quoteDataRec.quoteClass !== ""
            : true;
        QuotationValidationStore.quotationValidMap.packingGroup =
          quoteDataRec.hazardous.toLowerCase() === "yes"
            ? quoteDataRec.packingGroup !== ""
            : true;
        QuotationValidationStore.quotationValidMap.unnumber =
          quoteDataRec.hazardous.toLowerCase() === "yes"
            ? quoteDataRec.unnumber !== ""
            : true;
        QuotationValidationStore.quotationValidMap.temperature =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes"
            ? quoteDataRec.shipmentType.toLowerCase().includes("air")
              ? true
              : quoteDataRec.temperature !== "" &&
                parseInt(quoteDataRec.temperature) >= -35 &&
                parseInt(quoteDataRec.temperature) <= 30
            : true;
        QuotationValidationStore.quotationValidMap.cargoValue =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes"
            ? quoteDataRec.shipmentType.toLowerCase().includes("air")
              ? true
              : quoteDataRec.cargoValue !== "" &&
                quoteDataRec.cargoValue > 0 &&
                parseInt(quoteDataRec.cargoValue) < 100000
            : true;
        QuotationValidationStore.quotationValidMap.minTemperature =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes" &&
          quoteDataRec.shipmentType.toLowerCase().includes("air")
            ? quoteDataRec.minTemperature !== "" &&
              parseFloat(quoteDataRec.minTemperature) >= -35 &&
              parseFloat(quoteDataRec.minTemperature) <= 30 &&
              parseFloat(quoteDataRec.minTemperature) <
                parseFloat(quoteDataRec.maxTemperature)
            : true;
        QuotationValidationStore.quotationValidMap.maxTemperature =
          quoteDataRec.temperatureControlled.toLowerCase() === "yes" &&
          quoteDataRec.shipmentType.toLowerCase().includes("air")
            ? quoteDataRec.maxTemperature !== "" &&
              parseFloat(quoteDataRec.maxTemperature) >= -35 &&
              parseFloat(quoteDataRec.maxTemperature) <= 30 &&
              parseFloat(quoteDataRec.minTemperature) <
                parseFloat(quoteDataRec.maxTemperature)
            : true;
        QuotationValidationStore.quotationValidMap.airline = 
        quoteDataRec.shipmentType.toLowerCase().includes("air")
          ? quoteDataRec.airline !== ""
          : true;
        if (
          quoteDataRec.shipmentType.toLowerCase().includes("lcl") ||
          quoteDataRec.shipmentType.toLowerCase().includes("air")
        ) {
          if (cargoStore.cargoList.length === 0)
            QuotationValidationStore.message = `Please add atleast one cargo details`;
          else
            cargoStore.cargoList.every((cargo, index) => {
              for (let cargoKey of Object.keys(cargo)) {
                if (!CargoValidationStore.isValidCargoValue(cargoKey, index)) {
                  QuotationValidationStore.message =
                    "Please check the cargo detail fields";
                  return false;
                }
              }
              return true;
            });
        } else if (quoteDataRec.shipmentType.toLowerCase().includes("fcl")) {
          let container = containerStore.list.map(container => container.containerType)
          let uniqueContainer = new Set(container)
          if (containerStore.list.length === 0)
            QuotationValidationStore.message = `Please add atleast one container to container details`;
          else if(container.length !== uniqueContainer.size){
            return QuotationValidationStore.message = `Same ContainerType Is Not Allowed.`;
          }
          else if (
            // [
            //   "FCL (Factory/Dock)",
            //   "FCL (Dock/Factory)",
            //   "FCL (Dock/Dock)",
            // ].includes(quoteDataRec.shipmentType) &&
            // ["Port-to-Door", "Door-to-Port", "Door-to-Door"].includes(
            //   quoteDataRec.shipmentScope
            // ) &&
            (QuotationUtils.hasCargoDetail()||Utils.outGaugeContainerCheck(containerStore.list)) &&
            cargoStore.cargoList.length === 0
          )
            QuotationValidationStore.message = `Please add atleast one cargo details`;
          else if (
            !containerStore.list
              .flatMap((container, index) =>
                Object.keys(container).map((containerKey) =>
                  ContainerValidationStore.isValidContainerValue(
                    containerKey,
                    index
                  )
                )
              )
              .every((check) => check)
          ){
            QuotationValidationStore.message =
            "Please check the container detail fields";
          }
        }
        let chargeStoreToValidate;
        if (quoteDataRec.shipmentType.toLowerCase().includes("lcl")){
          chargeStoreToValidate = chargesStore1;
        }
        else if (quoteDataRec.shipmentType.toLowerCase().includes("fcl")){
          chargeStoreToValidate = fclChargesStore;
          let container = containerStore.list.map(container => container.containerType)
          let uniqueContainer = new Set(container)
          if(container.length !== uniqueContainer.size){
            QuotationValidationStore.message = `Same ContainerType Is Not Allowed.`;
            return false
          }
          if (chargeStoreToValidate &&
            (!containerStore.list.every((container) => {
              console.log("container", container);
              return [
                // "Origin Charges",
                "Ocean Freight",
                // "Destination Charges",
              ].every((key) => {
                return chargeStoreToValidate.list.every(
                  (charge, index) =>
                    charge.chargeDescription !== key ||
                    (`${container.containerType}perWM` in charge &&
                      chargesValidationStore.isValidCharge(
                        `${container.containerType}perWM`,
                        index
                      ))
                );
              });
            }))
          ) {
            QuotationValidationStore.message = `Please check the charges detail fields`;
            return false;
          }
        }
        else if (quoteDataRec.shipmentType.toLowerCase().includes("air")){
          chargeStoreToValidate = airChargesStore;
        }
        if (chargeStoreToValidate &&
          !chargeStoreToValidate.list.every((charge, index) =>
            Object.keys(charge).every((chargeKey) =>
              chargesValidationStore.isValidCharge(chargeKey, index)
            )
          )
        ) {
          QuotationValidationStore.message = `Please check the charges detail fields`;
          return false;
        }
        break;
    }
  },
  isValid: () => {
    if (quoteDataRec.origin === "template") return true;
    QuotationValidationStore.validate("procurement")
    QuotationValidationStore.validate();
    console.log("++++++",QuotationValidationStore.quotationValidMap)
    let isValid = Object.values(
      QuotationValidationStore.quotationValidMap
    ).every((check) => check);
    if (!isValid) QuotationValidationStore.message = `Please check the input fields`;
    return QuotationValidationStore.message === null;
  },
  reset: () => {
    QuotationValidationStore.message = null;
    Object.keys(QuotationValidationStore.quotationValidMap).forEach(
      (key) => (QuotationValidationStore.quotationValidMap[key] = true)
    );
    chargesValidationStore.isNew = true;
  },
});

const SchedulesValidation = proxy({
  message: null,
  originmsg : "",
  destinationmsg:"",
  portOfReceiptmsg:"",
  schdeuleValidMap: {
   
    origin: true,
    destination: true,
    carrier: true,
    scheduleDate:true,
    portOfReceipt: true,

    
  },
  validate: (key) => {
    console.log('validating', key);
    SchedulesValidation.message = null;
    switch (key) {
      case "portOfReceipt":
          SchedulesValidation.schdeuleValidMap[key] = true
       
        break;
    }

  },
  isValid: () => {
    if (quoteDataRec.origin === "template") return true;
    QuotationValidationStore.validate("procurement")
    QuotationValidationStore.validate();
    let isValid = Object.values(
      QuotationValidationStore.quotationValidMap
    ).every((check) => check);
    if (!isValid) QuotationValidationStore.message = `Please check the fields`;
    return QuotationValidationStore.message === null;
  },
  reset: () => {
    QuotationValidationStore.message = null;
    Object.keys(QuotationValidationStore.quotationValidMap).forEach(
      (key) => (QuotationValidationStore.quotationValidMap[key] = true)
    );
    chargesValidationStore.isNew = true;
  },
});

const CargoValidationStore = {
  isValidCargoValue: (key, cargoRowIndex) => {
    let value;
    switch (key) {
      case "packageType":
        value = cargoStore.cargoList[cargoRowIndex][key];
        return value !== "";
      case "numPackages":
        value = cargoStore.cargoList[cargoRowIndex][key];
        return value > 0;
      case "wtPerPackage":
        value = parseFloat(cargoStore.cargoList[cargoRowIndex][key]);
        if (quoteDataRec.shipmentType.toLowerCase().includes("air")) {
          return value > 0 && value <= 6500;
        }
        return ["Port-to-Door", "Door-to-Port", "Door-to-Door"].includes(
          quoteDataRec.shipmentScope
        )
          ? value > 0 && value <= 3000
          : true;
      case "length":
        value = parseFloat(cargoStore.cargoList[cargoRowIndex][key]);
        if (quoteDataRec.shipmentType.toLowerCase().includes("air")) {
          return value > 0 && value <= 400;
        }
        return ["Port-to-Door", "Door-to-Port", "Door-to-Door"].includes(
          quoteDataRec.shipmentScope
        )
          ? value > 0 && value <= 1190
          : true;
      case "width":
        value = parseFloat(cargoStore.cargoList[cargoRowIndex][key]);
        if (quoteDataRec.shipmentType.toLowerCase().includes("air")) {
          return value > 0 && value <= 312.5;
        }
        return ["Port-to-Door", "Door-to-Port", "Door-to-Door"].includes(
          quoteDataRec.shipmentScope
        )
          ? value > 0 && value <= 230
          : true;
      case "height":
        value = parseFloat(cargoStore.cargoList[cargoRowIndex][key]);
        if (quoteDataRec.shipmentType.toLowerCase().includes("air")) {
          return value > 0 && value <= 280;
        }
        return ["Port-to-Door", "Door-to-Port", "Door-to-Door"].includes(
          quoteDataRec.shipmentScope
        )
          ? value > 0 && value <= 250
          : true;
      case "totalWeight":
      case "totalVolume":
        value = cargoStore.cargoList[cargoRowIndex][key];
        return quoteDataRec.shipmentType.toLowerCase().includes("lcl") &&
          ["Port-to-Port"].includes(quoteDataRec.shipmentScope)
          ? value > 0
          : true;
      default:
        return true;
    }
  },
};

const ContainerValidationStore = {
  isValidContainerValue: (key, containerRowIndex) => {
    let value;
    switch (key) {
      case "containerType":
        value = containerStore.list[containerRowIndex][key];
        return value !== "";
      case "numContainers":
      case "wtPerContainer":
        value = parseInt(containerStore.list[containerRowIndex][key]);
        return (
          value > 0 &&
          value <=
            maxcargoWeightPerContainer[
              containerStore.list[containerRowIndex]["containerType"]
            ]
        );
      default:
        return true;
    }
  },
};

const chargesValidationStore = proxy({
  isNew: false,
  isSaveBtnClicked: false,
  onSaveBtnClicked: () => {
    chargesValidationStore.isSaveBtnClicked = true;
    chargesValidationStore.isNew = false;
  },
  isValidCharge: (key, index) => {
    if (chargesValidationStore.isNew) return true;
    let value;
    let containerKey = ["perWM", "perShipment","perTon"];
    let containerList = []
    if(containerStore.list.length > 0) containerKey.forEach((key) => 
    containerStore.list.forEach((ele) => containerList.push(ele.containerType + key)))
    if (quoteDataRec.shipmentType.toLowerCase().includes("lcl")) {
      const cond = [
        // "Origin Charges",
        "Ocean Freight",
        // "Destination Charges",
      ].includes(chargesStore1.list[index]?.chargeDescription);
      switch (key) {
        case "perCBM":
        case "perTon":
        case "minimum":
          value = chargesStore1.list[index][key];
          return cond
          ? true // umakanth - value !== null && value !== "" && value != 0
          : ["perCBM", "perTon", "minimum", "maximum"]
              // .filter((val) => val !== key)
              .some(
                (val) =>
                  // chargesStore1.list[index][val] != "" ||
                  parseFloat(chargesStore1.list[index][val]) != 0
              )
          ? value !== null && value != "" && parseFloat(value) > 0
           : true;
        case "chargeDescription": 
          value = chargesStore1.list[index][key];
          return value !== null && value !== "" && value != undefined
        case "onaccount": 
          value = chargesStore1.list[index][key];
          return value !== null && value !== "" && value != undefined
        case "currency":
          value = chargesStore1.list[index][key];
          return cond
          ? value !== null && value !== "" && value != 0
          : ["perCBM", "perTon", "minimum", "maximum","perShipment"]
              .some(
                (val) =>{
                  if(val=="perShipment"){
                    if(isNaN(parseFloat(chargesStore1.list[index][val]))){
                      return false
                    }
                  }
                  return parseFloat(chargesStore1.list[index][val]) != 0
                }
              )
          ? value !== null && value != "" && parseFloat(value) != 0
           : true;
        default:
          return true;
      }
    } else if (quoteDataRec.shipmentType.toLowerCase().includes("fcl")) {
      const cond = [
        "Ocean Freight",
      ].includes(fclChargesStore.list[index]?.chargeDescription);
      switch (key) {
        case "chargeDescription": 
          value = fclChargesStore.list[index][key];
          return value !== null && value !== "" && value != undefined
        case "onaccount": 
          value = fclChargesStore.list[index][key];
          return value !== null && value !== "" && value != undefined
        case "currency":
          value = fclChargesStore.list[index][key];
          if(cond){
            return value !== null && value !== "" && parseFloat(value) != 0;
          }else if(containerList
            .some(
              (val) =>
              {
                if(val.includes("perTon")){
                  if(isNaN(parseFloat(fclChargesStore.list[index][val]))){
                    return false
                  }
                }
                return parseFloat(fclChargesStore.list[index][val]) != 0
              }
            )){
              return  value !== null && value != "" && parseFloat(value) != 0
          }else{
            return true;
          }
          default:
          if(key.includes("perWM")){
            value = fclChargesStore.list[index][key];
            if(cond){
              return value !== null && value !== "" && value != undefined && parseFloat(value) > 0
            }else{
              return value !== "" && value >= 0
            }
          }
          // if(key.includes("perTon")){
          //   value = fclChargesStore.list[index][key];
          //   if(cond){
          //     return value !== null && value !== "" && value != undefined && parseFloat(value) > 0
          //   }else{
          //     return value !== "" && value >= 0
          //   }
          // } 
          if(key.includes("perShipment")){
            if(fclChargesStore.list[index]?.disabled) return true;
            value = fclChargesStore.list[index][key];
            if(cond){
              return value !== null && value !== "" && value != undefined && parseFloat(value) >= 0
            }else{
              return value !== "" && parseFloat(value) >= 0
            }
          }
          return true;
      }
    } else if (quoteDataRec.shipmentType.toLowerCase().includes("air")) {
      const cond = [
        "Air Freight"
      ].includes(airChargesStore.list[index]?.chargeDescription);
      switch (key) {
        case "perKg":
        case "minimum":
          value = airChargesStore.list[index][key];
          return cond
          ? value !== null && value !== "" && value > 0
          : ["perKg", "minimum"]
              .some(
                (val) =>
                  parseFloat(airChargesStore.list[index][val]) != 0
              )
          ? value !== null && value != "" && parseFloat(value) > 0
           : true;
        case "chargeDescription": 
          value = airChargesStore.list[index][key];
          return value !== null && value !== "" && value != undefined
        case "onaccount": 
          value = airChargesStore.list[index][key];
          return value !== null && value !== "" && value != undefined
        case "currency":
          value = airChargesStore.list[index][key];
          return cond
          ? value !== null && value !== "" && value != 0
          : ["perAWB", "perKg", "minimum"]
              .some(
                (val) =>
                  parseFloat(airChargesStore.list[index][val]) != 0
              )
          ? value !== null && value != "" && parseFloat(value) != 0
           : true;
        default:
          return true;
      }
    }
  },
});

const CoiCreationValidation = proxy({
  message: null,
  riskcommencementlocationmsg : "",
  riskendinglocationmsg:"",
  portOfReceiptmsg:"",
  CoiCreationValidMap: {
   
    riskcommencementlocation: true,
    riskendinglocation: true,
    commericalInvoiceNumber: true,
    commericalInvoiceDate:true,
    commericalInvoiceValue:true
    
  },
  validate: (key) => {
    console.log('validating', key);
    CoiCreationValidation.message = null;
    // switch (key) {
    //   case "portOfReceipt":
    //       SchedulesValidation.schdeuleValidMap[key] = true
       
    //     break;
    // }

  },
  isValid: () => {
    if (quoteDataRec.origin === "template") return true;
    QuotationValidationStore.validate("procurement")
    QuotationValidationStore.validate();
    let isValid = Object.values(
      QuotationValidationStore.quotationValidMap
    ).every((check) => check);
    if (!isValid) QuotationValidationStore.message = `Please check the fields`;
    return QuotationValidationStore.message === null;
  },
  reset: () => {
    QuotationValidationStore.message = null;
    Object.keys(QuotationValidationStore.quotationValidMap).forEach(
      (key) => (QuotationValidationStore.quotationValidMap[key] = true)
    );
    chargesValidationStore.isNew = true;
  },
});


export {
  QuotationValidationStore,
  CargoValidationStore,
  ContainerValidationStore,
  chargesValidationStore,
  SchedulesValidation,
  CoiCreationValidation
};
