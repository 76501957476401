import { enquiryStore } from "../enquiry/enquiry-data";
import { contractsStore, quoteDataRec } from "../state/CommonState";
import { maxcargoWeightPerContainer } from "../StaticData";
import lclExportChargesData1 from "../charges_data/LCLExportCharges1";
import Utils from "./Utils";
import airImportChargesData from "../charges_data/AirImport";

const SHIPMENT_SCOPE = {
  P2P: "Port-to-Port",
  P2D: "Port-to-Door",
  D2P: "Door-to-Port",
  D2D: "Door-to-Door",
};

const SHIPMENT_SCOPE_NAME = Object.fromEntries(
  Object.entries(SHIPMENT_SCOPE).map((entry) => entry.reverse())
);

export { SHIPMENT_SCOPE, SHIPMENT_SCOPE_NAME };

export default class QuotationUtils {
  static hasCargoDetail(shipmentType, shipmentScope) {
    shipmentType = shipmentType || quoteDataRec.shipmentType;
    shipmentScope = shipmentScope || quoteDataRec.shipmentScope;
    if (shipmentType.toLowerCase().includes("fcl")) {
      const [stuffingType, destuffingType] = shipmentType
        .substring(shipmentType.indexOf("(") + 1, shipmentType.indexOf(")"))
        .split("/");
      switch (shipmentScope) {
        case "Port-to-Door":
          return destuffingType === "Dock";
        case "Door-to-Port":
          return stuffingType === "Dock";
        case "Door-to-Door":
          return stuffingType === "Factory" && destuffingType === "Factory"
            ? false
            : true;
        default:
          return false;
      }
    }
    return true;
  }

  static isValidCargo(shipmentType, shipmentScope, cargo) {
    shipmentType = shipmentType.toString().toLowerCase();
    if(shipmentScope === SHIPMENT_SCOPE.P2P) return true;
    if (
      shipmentType !== "fcl" ||
      (shipmentType === "fcl" &&
        QuotationUtils.hasCargoDetail(shipmentType, shipmentScope))
    )
      return (
        cargo.packageType.length > 0 &&
        parseInt(cargo.quantity) > 0 &&
        (shipmentType === "lcl" && shipmentScope === SHIPMENT_SCOPE.P2P
          ? (cargo.totalWeight?.value || cargo.totalWeight) &&
            parseFloat(cargo.totalWeight?.value || cargo.totalWeight) > 0 &&
            (cargo.totalVolume?.value || cargo.totalVolume) &&
            parseFloat(cargo.totalVolume?.value || cargo.totalVolume) > 0
          : true) &&
        (cargo?.weight?.value || cargo.wtPerPackage) > 0 &&
        Utils.stdUnits(
          parseFloat(cargo?.weight?.value || cargo.wtPerPackage),
          cargo?.weight?.unit || "Kgs"
        ) <= (shipmentType === "air" ? 6500 : 3000) &&
        Utils.stdUnits(
          parseFloat(cargo?.dimensions?.length || cargo.length),
          cargo?.dimensions?.unit || "Cms"
        ) <= (shipmentType === "air" ? 400 : 1190) &&
        Utils.stdUnits(
          parseFloat(cargo?.dimensions?.width || cargo.width),
          cargo?.dimensions?.unit || "Cms"
        ) <= (shipmentType === "air" ? 312.5 : 230) &&
        Utils.stdUnits(
          parseFloat(cargo?.dimensions?.height || cargo.height),
          cargo?.dimensions?.unit || "Cms"
        ) <= (shipmentType === "air" ? 280 : 250)
      );
    return true;
  }

  static isValidContainer({
    containerType,
    numContainers,
    wtPerContainer,
    ...container
  }) {
    return (
      containerType.length > 0 &&
      parseInt(numContainers) > 0 &&
      parseFloat(wtPerContainer) > 0 &&
      Utils.stdUnits(parseFloat(wtPerContainer), container?.unit || "Cms") <=
        maxcargoWeightPerContainer[containerType]
    );
  }

  static isCargoEditable(shipmentType, shipmentScope) {
    if (enquiryStore.current.nonStackable === "No") {
      if (shipmentType === "lcl") {
        if([SHIPMENT_SCOPE.D2P, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
          !contractsStore.current.chargesList.find(({chargeDescription}) => chargeDescription.toString().startsWith("Pick-up Charges")))
          return false; 
        if([SHIPMENT_SCOPE.P2D, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
          !contractsStore.current.chargesList.find(({chargeDescription}) => chargeDescription.toString().startsWith("Door Delivery Charges")))
          return false;
        const chargesListToCheck = contractsStore.current.chargesList.filter(
          (charge) =>
            !lclExportChargesData1.preList.find(
              ({ chargeDescription }) =>
                chargeDescription === charge.chargeDescription
            ) ||
            ([SHIPMENT_SCOPE.D2P, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
              charge.chargeDescription
                .toString()
                .startsWith("Pick-up Charges")) ||
            ([SHIPMENT_SCOPE.P2D, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
              charge.chargeDescription
                .toString()
                .startsWith("Door Delivery Charges"))
        );
        if (
          chargesListToCheck.every(
            ({ perShipment }) => parseFloat(perShipment) > 0
          )
        ) {
          return chargesListToCheck.every((charge) =>
            ["perCBM", "perTon", "maximum", "minimum"].some(
              (key) => parseFloat(charge[key]) > 0
            )
          );
        } else return true;
      } else if (shipmentType === "air") {
        if([SHIPMENT_SCOPE.D2P, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
          !contractsStore.current.chargesList.find(({chargeDescription}) => chargeDescription.toString().startsWith("Pick-up Charges")))
          return false; 
        if([SHIPMENT_SCOPE.P2D, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
          !contractsStore.current.chargesList.find(({chargeDescription}) => chargeDescription.toString().startsWith("Door Delivery Charges")))
          return false;
        const chargesListToCheck = contractsStore.current.chargesList.filter(
          (charge) =>
            !airImportChargesData.preList.find(
              ({ chargeDescription }) =>
                chargeDescription === charge.chargeDescription
            ) ||
            ([SHIPMENT_SCOPE.D2P, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
              charge.chargeDescription
                .toString()
                .startsWith("Pick-up Charges")) ||
            ([SHIPMENT_SCOPE.P2D, SHIPMENT_SCOPE.D2D].includes(shipmentScope) &&
              charge.chargeDescription
                .toString()
                .startsWith("Door Delivery Charges"))
        );
        if (chargesListToCheck.every(({ perAWB }) => parseFloat(perAWB) > 0)) {
          return chargesListToCheck.every((charge) =>
            ["perKg", "minimum"].some((key) => parseFloat(charge[key]) > 0)
          );
        } else return true;
      } else return false;
    } else return false;
  }
}
