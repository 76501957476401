import { useSnapshot } from "valtio"

export default function Dtselect(props) {
    const { label, record, name, dataList, callback = f => f, isValid = true, validationMsg, isDisabled  } = props
    const imRecord = useSnapshot(record)
    return (
        <div className="field">
            {
                label?<label className="label is-small " style={{ marginBottom: '0px' }}>{label}</label>:""
            }
            
            <div className="control">
                <div className={ isValid ? "select is-small" : "select is-small is-danger is-danger-border"} style={{width:"100%"}}>
                    <select {...props} name={name} value={imRecord[name]} style={{width:"100%"}}
                       {...isDisabled}
                        onChange={(e) => {
                            //console.log('FROM INSIDE AgSelect=', record, e.target.value)
                            record[name] = e.target.value
                            callback(e.target.value)
                        }} >
                            {
                                label?<option selected value = {''} hidden>
                                {`select ${label.toLowerCase()}`}
                                </option>:""
                            }
                            
                            {
                                             Object.entries(dataList).map(([key, value]) => (
                                                 <option value={value}>{key}</option>
                                             ))
                                         }
                    </select>
                </div>
            </div>
            { !isValid && label && <p class="help is-danger"> { validationMsg || `* Please select valid ${label}` }</p> }
        </div>
    )
}