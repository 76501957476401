import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './Excel.css';

const ExcelTable = ({ url }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const workbook = await fetchExcelFile(url);
        const jsonData = parseExcelFile(workbook);
        setData(jsonData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [url]);

  const fetchExcelFile = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer',
      });
      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: 'array' });
      return workbook;
    } catch (error) {
      throw new Error('Failed to fetch the Excel file.');
    }
  };

  const parseExcelFile = (workbook) => {
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
    return jsonData;
  };

  useEffect(() => {
    if (tableRef.current) {
      const thElements = tableRef.current.getElementsByTagName('th');
      const tdElements = tableRef.current.getElementsByTagName('td');

      for (let i = 0; i < thElements.length; i++) {
        let maxWidth = thElements[i].offsetWidth;
        for (let j = i; j < tdElements.length; j += thElements.length) {
          if (tdElements[j].offsetWidth > maxWidth) {
            maxWidth = tdElements[j].offsetWidth;
          }
        }
        thElements[i].style.width = `${maxWidth}px`;
      }
    }
  }, [data]);

  return (
    <div className="table-container">
      {error ? (
        <div className="error-message">Error: {error}</div>
      ) : (
        <table ref={tableRef} className="excel-table">
          <thead>
            {data.length > 0 && (
              <tr>
                {data[0].map((cell, index) => (
                  <th key={index}>{cell}</th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExcelTable;
