import React from 'react';
import Lottie from 'react-lottie';
import { truckJson } from '../assets/truck';
import { Box, Dialog } from '@mui/material';
import { proxy, useSnapshot } from 'valtio';

export const LottieAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: truckJson,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
  return (
    <div>
      <Lottie options={defaultOptions} height={150} width={150} />
    </div>
  );
};


export const truckLoader = proxy({
   open : false,
   show : ()=>{
    truckLoader.open = true
   },
   hide : ()=>{
    truckLoader.open = false;
   }
})

export function TruckLoader(){
    useSnapshot(truckLoader)
    return truckLoader.open ? <Dialog PaperProps={{
        sx:{
            backgroundColor:"transparent",
            border:"none",
            boxShadow:"none"
        }
       }} sx={{
        backgroundColor:"transparent !important",
        }} open={truckLoader.open} fullScreen fullWidth 
    //     slotProps={{
    //     backdrop :  {sx: { 
            
    //         backgroundColor: 'transparent',
    //         backdropFilter: 'blur(5px)' } }
    // }} 
    >
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%",backgroundColor:"transparent"}}>
        <LottieAnimation/>
        </Box>
    </Dialog> : <></>
};
