import Api from "./Api"
import { configStore, loginStore } from "./CommonState";
const MONGO = {
    FIND: (collection) => `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/find/${collection}`,
    AGGREGATE: (collection) => `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/aggregate/${collection}`,
    INSERTONE: (collection) => `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/insertone/${collection}`,
    REPLACEONE: (collection) => `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/replaceone/${collection}`,
    UPDATEONE: (collection) => `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/updateone/${collection}`,
    REMOVE: (collection) => `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/remove/${collection}`,
    NEXTNUM: `${process.env.REACT_APP_API_SERVER}/api/v1/mongo/nextnum`,
}
export default class MonApi {
    static post(url, payload, success, error) {
        const requestOptions = {
            method: 'POST',
            headers: Api.H(),
            body: JSON.stringify(payload)
        };
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(data => {(data.error) ? error(data.error) : success(data)})
            .catch(err => {
                console.log('POST Url: ' + url + ' Err=', err)
                error('' + err)
            })
    }
    static async apost(url, payload, method = 'POST') {
        let headerss = Api.H() 
        if (loginStore.isClient() && configStore.currentSelectedEntity.entityId.length === 0){
            headerss['Current-Entity'] = 'ALL'  
        }
        const requestOptions = {
            method: method,
            headers: headerss,
            body: JSON.stringify(payload)
        }
        return await fetch(url, requestOptions)
            .then(res => res.json())
    }
    static async aget(url) {
        const requestOptions = {
            method: 'GET',
            headers: Api.H()
        }
        return await fetch(url, requestOptions)
            .then(res => res.json())
    }
    static async get(url) {
        const requestOptions = {
            headers: Api.H()
        }
        return await fetch(url, requestOptions).then(res => res.json())
    }
    static async find(collection, filter, projection) {
        return await MonApi.apost(MONGO.FIND(collection), {filter, projection})
    }
    static async aggregate(collection, body) {
        return await MonApi.apost(MONGO.AGGREGATE(collection), body)
    }
    static async insertOne(collection, body) {
        return await MonApi.apost(MONGO.INSERTONE(collection), body)
    }
    static async replaceOne(collection, body) {
        return await MonApi.apost(MONGO.REPLACEONE(collection), body)
    }
    static async updateOne(collection, filter, updates) {
        return await MonApi.apost(MONGO.UPDATEONE(collection), {filter, updates})
    }
    static async remove(collection, body) {
        return await MonApi.apost(MONGO.REMOVE(collection), body)
    }
    static async nextnum(key) {
        return await MonApi.apost(MONGO.NEXTNUM, {key})
    }
}