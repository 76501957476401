import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
const Sorting = ({ column }) => (
    <span className="react-table-column-header sortable">
      {column.isSortedDesc === undefined ? (
        <SortIcon style={{width:"15px", height:"15px", marginLeft:"3px"}} />
      ) : (
        <span>
          {column.isSortedDesc
            ? <SortAscendingIcon style={{width:"15px", height:"15px", marginLeft:"3px"}} />
            : <SortDescendingIcon style={{width:"15px", height:"15px", marginLeft:"3px"}} />}
        </span>
      )}
    </span>
  );

export {
    Sorting
}