import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import RoleUtils from "../util/RoleUtils";
import TableHead from "../util/dataTable";
import { PERMISSIONS, ROLES, ROLESFILTER, STATUSFIELD, VERTICALS } from "../util/constants";
import {
    UserColumn
} from "../components/ag-table/columns"

import ReactTablePagination from '../components/ag-table/ReactTablePagination'
import TableFilter from "../components/ag-table/TableFilter"

import { Sorting } from "../components/ag-table/ag-sorting"
import {
    reducer, PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    PAGE_SORT_CHANGED,
    PAGE_FILTER_CHANGED,
    TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED
} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"
import { AgmodalStore, aguserStore, configStore, routerStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { AguserType } from "../state/Types"
import UsersApis from "../state/users-api";

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};



const DataTable = (props) => {
    useSnapshot(configStore)
    useSnapshot(aguserStore)

    const [statusFilter, SetStatusFilter] = useState(`ALL`);
    const [verticalFilter, SetVerticalFilter] = useState(`ALL`)
    const [roleFilter, SetRoleFilter] = useState(`ALL`)
    
    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    // let columns = useMemo( () => COLUMNS, [])
    let columns = UserColumn
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);
    useEffect(()=>{
        if(queryPayload.statusFilter && AgmodalStore.apiCall){
            ApiAgTable.usersData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res=>{
                res.clone().json().then((res) => {
                    if(res.results && res.results.length > 0){
                        setData1(res)
                    }else{
                        setData1({ count: 0, results: []})
                    }
                }, err => {
                    console.log(err)
                })
            })
        }
    },[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,AgmodalStore.apiCall])



    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results || [],
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );
    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { statusFilter: statusFilter, verticalFilter: verticalFilter, roleFilter: roleFilter } });
        gotoPage(0);
    }, [statusFilter, verticalFilter, roleFilter]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    

   
        return (
            <>
                <h1 className="title">Users</h1>
            <nav className="level">
                <div className="level-left">
                <div className="level-item">
                        <p className="subtitle is-5">
                            {data1?.count } users
                        </p>
                    </div>
                    {((RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_AGRAGAUSER))) &&
                    <p className="level-item">
                        <a className="button is-link" 
                            onClick={() => {
                                aguserStore?.aguserRec?.set(new AguserType());
                                AgmodalStore.pageVisible = true;
                                AgmodalStore.apiCall = false
                                AgmodalStore.modalPage = 'newAguser'
                            }}>New</a>
                    </p>  }
                    <div className="level-item is-hidden-tablet-only">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                    <div class="level-item level-left ml-6">
                         <div class="field">
                            Status
                             <p class="control has-icons-left has-icons-right">
                                 <span class="select">
                                     <select name="status" id="status" 
                                     value={statusFilter} onChange={(e) => {SetStatusFilter(e.target.value)
                                        }}
                                     >
                                         <option value="ALL">All</option>
                                         {
                                             Object.entries(STATUSFIELD).map(([key, value]) => (
                                                 <option value={key}>{value}</option>
                                             ))
                                         }
                                     </select>
                                     <span class="icon">
                                         <i class="fa-solid fa-filter"></i>
                                     </span>
                                 </span>
                             </p>
                         </div>
                     </div>
                     <div class="level-item level-left ml-6">
                         <div class="field">
                            Vertical
                             <p class="control has-icons-left has-icons-right">
                                 <span class="select">
                                     <select name="vertical" id="vertical" 
                                     value={verticalFilter} onChange={(e) => {SetVerticalFilter(e.target.value)
                                        SetRoleFilter('ALL')
                                        }}
                                     >
                                         <option value="ALL">All</option>
                                         {
                                             Object.entries(VERTICALS).map(([key, value]) => (
                                                 <option value={value}>{value}</option>
                                             ))
                                         }
                                     </select>
                                     <span class="icon">
                                         <i class="fa-solid fa-filter"></i>
                                     </span>
                                 </span>
                             </p>
                         </div>
                     </div>
                     <div class="level-item level-left ml-6">
                         <div class="field">
                            Role
                             <p class="control has-icons-left has-icons-right">
                                 <span class="select">
                                     <select name="role" id="role" 
                                     value={roleFilter} onChange={(e) => {SetRoleFilter(e.target.value)
                                        }}
                                     >
                                         <option value="ALL">All</option>
                                         {
                                              ROLESFILTER[verticalFilter] &&
                                              Object.entries(ROLESFILTER[verticalFilter]).map(([key, value]) => (
                                                  <option key={key} value={value}>
                                                      {value}
                                                  </option>
                                              ))
                                         }
                                     </select>
                                     <span class="icon">
                                         <i class="fa-solid fa-filter"></i>
                                     </span>
                                 </span>
                             </p>
                         </div>
                     </div>
                </div>
            </nav>

                {
                     <>
                        {
                            (typeof data1?.count === 'undefined' || data1?.count == 0) && <p>No results found</p>
                        }
                        {(data1?.count > 0) &&
                            <>
                             <div className="table-container">
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th>#</th>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <Sorting column={column} />
                                </th>
                            ))}
                        </tr>
                    ))}
                    <tbody {...getTableBodyProps()}>
                                                {page.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            <td>{(pageIndex*pageSize)+i+1}</td>
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        switch (cell['column']['Header']) {
                                                                            case 'Name':
                                                                                return <td {...cell.getCellProps()}><span>{e.firstName?e.firstName + ' ' + e.lastName:''}</span></td>
                                                                            case 'Phone':
                                                                                return <td {...cell.getCellProps()}><span>{e.phonePrefix} {e.phone}</span></td>
                                                                                case 'Role':
                                                                                    let role;
                                                                                    if (e?.AdminSalesVertical?.length > 0) {
                                                                                        role = e.AdminSalesVertical;
                                                                                    } else if (e?.RMSalesVertical?.length > 0) {
                                                                                        role = e.RMSalesVertical;
                                                                                    } else if (e?.role?.length > 0) {
                                                                                        role = e.role; 
                                                                                    }
                                                                                    return (
                                                                                        <td {...cell.getCellProps()}>
                                                                                            <span>
                                                                                            {(
                                                                                                e?.AdminSalesVertical?.length > 0 || e?.RMSalesVertical?.length > 0
                                                                                                ? e?.department + " - " 
                                                                                                : ""
                                                                                            ) + role?.map(str => str?.split('').join('')).join(', ')}
                                                                                            </span>
                                                                                        </td>
                                                                                    );
                                                                            case 'Actions':
                                                                                // return <td {...cell.getCellProps()}><div className="field has-addons"><p className="control"><button className="button is-dark is-inverted" onClick={() => { aguserStore.aguserRec.set(e); routerStore.agusersCurrentView = 'view'; }}><span className="icon is-small"><i className="fas fa-eye"></i></span></button></p>
                                                                                return <td {...cell.getCellProps()}><div className="field has-addons"><p className="control"><button className="button is-dark is-inverted" onClick={() => { UsersApis.viewUser(e.email) }}><span className="icon is-small"><i className="fas fa-eye"></i></span></button></p>

                                                                            {
                                                                                (!row.cells[1].value?.includes('@agraga.com') && !e?.role?.some(role => role?.toLowerCase().startsWith("CLI"))) ?
                                                                                (RoleUtils.isUserAuthorized( PERMISSIONS.EDIT.CUSTOMER_USERS)) &&
                                                                                    < p className="control">
                                                                                        <button className="button is-info is-inverted" onClick={() => { aguserStore?.aguserRec?.set(e); routerStore.agusersCurrentView = 'edit'; }}><span className="icon"><i className="fas fa-pen-to-square"></i></span>
                                                                                        </button></p> :
                                                                                    RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.AGRAGA_USER) &&
                                                                                    < p className="control">
                                                                                        <button className="button is-info is-inverted
                                                                                        " onClick={() => { 
                                                                                            aguserStore.aguserRec = new AguserType();
                                                                                            if (e.status !== 'NEWREG'){
                                                                                              e.inviteSend = true
                                                                                            }
                                                                                            aguserStore?.aguserRec?.set(e); 
                                                                                            AgmodalStore.pageVisible = true; 
                                                                                            AgmodalStore.apiCall = false; 
                                                                                            AgmodalStore.modalPage = 'editAguser'; 
                                                                                            }}><span className="icon"><i className="fas fa-pen-to-square"></i></span>
                                                                                        </button></p>
                                                                            }
                                                                            </div></td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                </table>
                <ReactTablePagination
                    page={page}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                    manualPageSize={manualPageSize}
                    dataLength={totalCount}
                    rows={rows}
                />
            </div>
                               
                            </>
                        }
                    </>
                }


            </>
        )
}



const ListAgusers = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default ListAgusers;