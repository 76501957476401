const TC = [
  {
    term: "Storage charges and Vehicle Halting charges, if any, will be applicable at Actuals (Receipted).",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Rates are subject to GST as per government regulations.",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Rates are subject to space availablity at the time of Booking, Carrier/ Airline Operations and Onward confirmation.",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Rates are subject to surcharges, if any, implemented by Carrier/ Airline, Port/ Airport Authorities, Terminals, ICD & CFS operators with or without prior notice.",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: `All Transit Times, Routings & Cut-off's are estimated based on current Carrier/ Airline schedules and therefore subject to change without prior notice.`,
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Rates and Services do not cover Marine Insurance for Cargo and Containers, unless opted for",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Booking Cancellations and/or Change Request in schedules may be subject to additional charges (including dead freight) at the discretion of the Carrier/ Airline.",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Quotations are based on information & cargo description provided by the customer and any deviations may result in additional charges at the time of execution, at the discretion of the Carrier/ Airline.",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: `Forwarder shall not be liable for any loss, damages, delays, extra charges or non-performance if such loss, damages, delays, extra charges or non-performance is due directly or indirectly to (a) An Event of Force Majeure, defined herein as including acts of God, acts of public enemies, fires, floods or unusually severe weather conditions, strikes, lockouts, disputes with workmen or other hostilities, embargoes, wars, riots or civil disturbances, epidemics or quarantine restrictions, delays or shortages of transportation, governmental action including the government's denial or failure to grant an Export/ Import permission or other needed government authorization. The customer shall reimburse forwarder any such costs for deviation or delays or any other increase of costs of whatever nature caused by Force Majeure.`,
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Customs Duty & Taxes applicable at Destination will be on actuals payable immediately",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "Rates are subject to Port/ ICD/ CFS handling charges as per respective Port/ ICD/ CFS tariff at Origin and Destination",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
    ],
  },
  {
    term: "The Customer shall defend, indemnify and hold harmless the Forwarder in respect of any claims for General Average (GA) contribution that may be made on the Forwarder, irrespective of whether the carriage charges are pre-paid or not. The Customer shall provide such security and complete all formalities as may be required by the Forwarder/ Shipping Line/ Average Adjuster etc, for GA contributions promptly and in a form acceptable to the Forwarder/ Shipping Line/ Average Adjuster etc.",
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
    ],
  },
  {
    term: `We raise our Invoices according to the exchange rate of the Consolidator's Association of India (CAI), applicable on the day of billing.`,
    shippingType: ["LCL"],
  },
  {
    term: "We raise our Invoices according to the exchange rate of the Carrier/ Airline, applicable on the day of billing",
    shippingType: [
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
  {
    term: "FSC & SSC are subject to change as per airline notification",
    shippingType: ["Air"],
  },
  {
    term: "Airline Delivery Order Charges at Actuals (Receipted), as applicable",
    shippingType: ["Air"],
  },
  {
    term: "Height cannot exceed 153 CMS",
    shippingType: ["Air"],
  },
  {
    term: `This is a system generated quotation and does not require any signatures.`,
    shippingType: [
      "LCL",
      "FCL (Factory/Factory)",
      "FCL (Factory/Dock)",
      "FCL (Dock/Factory)",
      "FCL (Dock/Dock)",
      "Air",
    ],
  },
];

const getTC = (shippingType) =>
  TC.filter((e) => e.shippingType.indexOf(shippingType) >= 0).map(
    (e) => e.term
  );
export default TC;
export { getTC };
