import { useState } from "react";
import { useSnapshot } from "valtio";
import { taskStore } from "./task-store";
import Api from "../state/Api";
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import { typeLabelMap } from "../StaticData";

export default function ScheduleDeviationsApproval(props) {
    useSnapshot(taskStore);
    useSnapshot(AgmodalStore);
    let deviation = taskStore.deviation;
    let task = taskStore.current;
    const [remark, setRemark] = useState("");
    const [isRemarksEmpty, setIsRemarksEmpty] = useState(false);

    const handleButtonClick = () => {
        if (!remark.trim()) {
            setIsRemarksEmpty(true);
            return;
        }

        const req = {
            id: task.bookingNum,
            num: task._id.num,
            remarks: remark,
            mileStones: task.deviation
        };
        Api.updateScheduleDeviations(req);
        // configStore.setModalMessage("Action triggered successfully");
        taskStore.myActionVisible = false;
        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
        }
    };

    return (
        <div className="box">
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th >
                            <h3 className="is-size-5 has-text-centered">Milestone</h3>
                        </th>
                        <th >
                            <h3 className="is-size-5 has-text-centered">Original Value</h3>
                        </th>
                        <th >
                            <h3 className="is-size-5 has-text-centered">New Value</h3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        task.deviation?.map((e, i) => {
                            return (
                                <tr key={e.id}>
                                    <td className="has-text-centered">{typeLabelMap[e.type]?.label || e.type}</td>
                                    <td className="has-text-centered">{e.originalValue}</td>
                                    <td className="has-text-centered">{e.newValue}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <div>
                <p>Remarks<span className="has-text-danger">*</span>:</p>
                {!task.remarks ? (
                    <>
                        <input className={`input ${task.remarks === "" ? "is-danger" : ""}`} type="text" value={remark} onChange={(e) => { setRemark(e.target.value); setIsRemarksEmpty(false); }}></input>
                        {isRemarksEmpty ? <span className="has-text-danger">Please provide your remarks for this change</span> : null}
                    </>
                ) : (
                    <p>{task.remarks}</p>
                )}
            </div>
            <div className="level-right" style={{ padding: "10px" }}>
                <div className="level-item">
                    {!task.remarks && (
                        <p className="control">
                            <button onClick={handleButtonClick} className="button is-danger is-small is-rounded" type="submit">Submit</button>
                        </p>
                    )}
                </div>
            </div>

        </div>
    );
}
