import { useSnapshot } from "valtio"
import { aguserStore, configStore, loginStore } from "../state/CommonState"
import { $c, $cg, $cr, AgAirline, AgInput, Agpassword, AgpasswordSmall, AgPhone, AgPhoneNew, AgSelect } from '../components/AgComponents'
import AguserApi from "../state/AguserApi"
import { useEffect, useRef, useState } from "react"
import { AgDisplay } from "../components/AgComponents"
import MyProfileEntity from "./my-profile-entity"
import EntityApi from "../state/entity-api"
import UsersApis from "../state/users-api"
import { AguserType } from "../state/Types"

function ChangePassword(props) {
    useSnapshot(loginStore)
    let txtPassword = useRef()
    let txtPassword1 = useRef()
    const aguser = loginStore.userRec.aguserRec

    return (
        <div className="panel" style={{ paddingLeft: '10px', paddingBottom: '3px', border: '1px solid lightgrey' }}>
            <div className="columns">
                <$c n='1'></$c>
                <$c n='3'>
                    <AgpasswordSmall myref={txtPassword} label='New Password'/>
                </$c>
                <$c n='4'>
                    <AgpasswordSmall myref={txtPassword1} label='Re-enter Password' />
                </$c>
                <$c n='2' style={{ paddingBottom: '0px', alignItems: 'flex-end' }}>
                    <button type="button" className="button is-small islink"
                        style={{ marginTop: '0.7rem' }}
                        onClick={(e) => {
                            let p1 = txtPassword.current.value
                            let p2 = txtPassword1.current.value
                            if (p1 !== p2) {
                                configStore.setModalMessage('Passwords do not match')
                                return
                            }
                            AguserApi.setNewPassword(aguser._id, p1,
                                (data) => {
                                    configStore.setModalMessage('Password Changed! Please login again with new password.')
                                    localStorage.clear()
                                    window.location.reload()
                                })
                            loginStore.reinitialize()
                            localStorage.removeItem('ag_email')
                            localStorage.removeItem('ag_userrec')
                        }}>
                        Save New Password
                    </button>
                </$c>
                <$c n='1'></$c>
            </div>
        </div>
    )
}
export default function MyProfileNew(props) {
    const [pwdVisible, setPwdVisible] = useState(false)
    const [userProfile , setUserProfile] = useState({})
    let title = 'My Profile'
    useSnapshot(loginStore)
    let aguser = loginStore.userRec.aguserRec
    useEffect(() => {
        // EntityApi.getList()
        UsersApis.getProfile(aguser.email,setUserProfile)
    }, [])
    console.log('Value of country = ', aguserStore.aguserRec.country)
    if (!aguser || !aguser.phonePrefix || aguser.phonePrefix.length <= 0) aguser.phonePrefix = '+91'
    return (
        <>
            <h1 className="title is-4">{title}</h1>
            <div className="columns">
                <$c n='3'>
                    <AgInput label='First Name' record={aguser} name='firstName' />
                </$c>
                <$c n='3'>
                    <AgInput label='Last Name' record={aguser} name='lastName' />
                </$c>
                <$c n='3'>
                    <AgDisplay label='Email' value={aguser.email} />
                </$c>
                <$c n='3'>
                    <AgPhoneNew label='Phone' record={aguser} name='phone' />
                </$c>
            </div>
            {
                (!loginStore.isClient()) ? <><div>
                <$c n='3'>
                <AgInput label = 'OutLook Password' record={aguser} name='outlookPassword'/>
                </$c>
            </div></> : <></>
            }
            
            <div className="columns tour4">
                <$c n='2'>
                    <button style={{ marginTop: '1.0rem', color: 'white' }}
                        className="button islink is-small is-rounded"
                        onClick={() => setPwdVisible(pwdVisible ? false : true)}>
                        Change Password
                    </button>
                </$c>
                <$c n='10'>
                    {
                    (pwdVisible) ? <ChangePassword /> : 
                    <button className="button islink is-small"
                        style={{ marginTop: '1.2rem', border: '2px solid lightblue', color: 'white',float: 'right' }}
                        onClick={(e) => {
                            console.log('Save clicked')
                            // if (!aguser.phone.match(/^[0-9]{10}$/)) {
                            //     configStore.setModalMessage(`Invalid phone number ${aguser.phone}`)
                            //     return
                            // }
                            aguserStore.aguserRec = new AguserType();   
                            aguserStore.aguserRec.set(aguser)
                            AguserApi.saveUser()
                        }}>
                        Save Profile Changes
                    </button>
                    }
                </$c>
            </div>
            {
                (loginStore.isClient()) ?
                    <div className="columns">
                        <$c n='12'>
                            <MyProfileEntity data={userProfile}/>
                        </$c>
                    </div> : <></>
            }

        </>
    )
}