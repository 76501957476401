import React, { useState } from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
function InputField({container,id,max,input_style,min,placeholder,onkeyup,children,type,input_class,value,label,disabled,
    reff,pattern,callback,label_class,maxLength,label_style,onkeydown,icon_childer,onblur,errorsmsg,mandatory,input_wrapeer,name,showlist,hideErr}) {
const [typeC,settypeC] = useState(type)   
  return (
    <div className={input_wrapeer}>
    <p className={label_class} style={{...label_style}}>
                {label}{(mandatory === true || mandatory === undefined )? '*' : ''} 
              </p>
    <div className={container} style={{border:errorsmsg && container?"1px solid #F25B5B":""}}>

    <input
                id={id}
                min={min}
                max={max}
                maxLength={maxLength}
                type={type}
                style={{...input_style,border:errorsmsg && !container?"1px solid #F25B5B":""}}
                className={input_class}
                value={value}
                ref={reff}
                pattern={pattern}
                onChange={(e) => callback(e)}
                onKeyDown={onkeydown}
                placeholder={placeholder}
                name={name}
                onKeyUp={onkeyup}
                autoComplete={"new-password"}
                disabled={disabled}
                onBlur={(e)=>onblur?onblur(e):console.log(e)}
                // onFocus={(e)=>onfocus(e)}
                {...children}
              />
              {icon_childer?<>
              {typeC === "password"?<VisibilityOutlinedIcon onClick={()=>{
                document.getElementById(id).setAttribute("type","text")
                settypeC("text")}}/>:
              <VisibilityOffOutlinedIcon onClick={()=>{
                document.getElementById(id).setAttribute("type","password")
                settypeC("password")}}/>}
              </>:null}
    </div>
                  {!hideErr && <p className="help is-input-danger-message">
                    {errorsmsg?errorsmsg:<span>&nbsp;</span>}
                  </p>}

    </div>
  )
}

export default InputField