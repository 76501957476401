import { Box, Dialog } from "@mui/material";
import bookingIcon from "../assets/images/booking.svg";

const IconBackgroundColorFinder = {
    "primary" : {
        innerCircle : "#2B6ED4",
        outerCircle : "#EAF6FF"
    },
    "secondary" : {
        innerCircle : "#EBB02D",
        outerCircle : "#F3E3BC"
    },
    "success" : {
        innerCircle : "#2C8A57",
        outerCircle : "#2C8A5733"
    }
    
}


export function MuiPopup({children,width="637px",height=400,iconSrc=bookingIcon,open,status="primary",setClose}){
    const iconBackgroundColor = IconBackgroundColorFinder[status.toLowerCase()]; 
    return <Dialog 
    onClick={()=>{
        if(setClose){
            setClose();
        }
    }}
    slotProps={{
     backdrop :  {sx: { 
         backgroundColor: 'transparent',
         backdropFilter: 'blur(5px)' } }
 }}
    PaperProps={{
     sx:{
         backgroundColor:"transparent",
         minWidth:width,
         border:"none",
         boxShadow:"none"
     }
    }} open={open} sx={{
     backgroundColor:"transparent !important",
     
     "&::-webkit-scrollbar-thumb": {
             backgroundColor: "#2C358A !important"
         }
     }}>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%",backgroundColor:"transparent"}}>
        <Box sx={{width:width,height:`${height+30}px`,position:"relative",backgroundColor:"transparent",display:"flex",justifyContent:"end"}}>
            
        <Box sx={{display:"flex",flexDirection:"column",height:`${height}px`,backgroundColor:"white",width:"100%",alignItems:"center",borderRadius:"10px",position:"absolute",bottom:0,padding:"15px",border:"solid 2px #E8E8E8"}}>
            {children}
        </Box>
        <Box sx={{width:"100%",height:"60px",position:"absolute",top:0,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"60px",width:"60px",borderRadius:"50%",backgroundColor:iconBackgroundColor.outerCircle}}>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"40px",width:"40px",borderRadius:"50%",backgroundColor:iconBackgroundColor.innerCircle}}>
                    <img src={iconSrc}/>
                    </Box>
                </Box>
            </Box>
        </Box>
        </Box>
     </Dialog>
}