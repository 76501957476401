import React, { useState, useEffect } from 'react';
import { Card, Table, Box, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Button, Stack, Typography, TablePagination, TextField, InputAdornment, Modal, MenuItem, Select, IconButton, Avatar, Dialog, DialogContent,Grid } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import PhoneField from '../mui-components/PhoneField'
import { EntityV2Store, branchStore, cususerStore, configStore, routerStore,aguserStore,loginStore,newDashboardState } from '../state/CommonState'
import { CiSearch } from "react-icons/ci";
import AddIcon from '@mui/icons-material/Add';
import ApiAgTable from "../components/ag-table/api_ag-table"
import closeIcon from "../assets/images/close.svg";
import { makeStyles } from '@mui/styles'
import Checkbox from '@mui/material/Checkbox';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useForm, Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import BranchApi from "../state/branch-api"
import { useSnapshot } from "valtio"
import UsersApis from "../state/users-api"
import { CustomeruserType } from "../state/Types"
import AguserApi from "../state/AguserApi";
import EntityUtils from "../util/entityUtils"
import { Shortcut } from '@mui/icons-material';
import LastPageIcon from '@material-ui/icons/LastPage';



const UsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [entStatus, setEntStatus] = useState({
    initial: '',
    entity: ''
  });
  const [userDataList, setUserDataList] = useState([]);
  const [loginDataFlag, setLoginDataFlag] = useState(false);
  const [BranchList, setBranchList] = useState([])
  const [userAvailable, setuserAvailable] = useState(false)
  const [result, setResult] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [listUserCheck, setListUserCheck] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortingCheck, setSortingCheck] = useState('role');
  const [userdata, setUserdata] = useState({})
  const [searchValue, setSearchValue] = useState("");
  const [adminUserFlag, setAdminUserFlag] = useState(false);
  const [invite, setInvite] = useState(false);
  const [userMode, setUserMode] = useState("list")
  const [checked, setChecked] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const { register, handleSubmit, control, reset, formState: { errors }, setValue,getValues } = useForm();
  useSnapshot(cususerStore)

  let user = cususerStore.cususerRec
  const useStyles = makeStyles((theme) => ({
    root: {
      // background: "#fafafa",
    }
  }));
  let styles = useStyles()
  const [controller, setController] = useState({
    role: { sortBy: 'role', sortDirection: 'asc' },
    status: { sortBy: 'status', sortDirection: 'asc' },
    page: 0,
    rowsPerPage: 10,
    queryPayload: {
      status: "",
      branch: "",
      keyword: ""
    },
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: []
  });
  let Entity = EntityV2Store.EntityRec;



  function getRoleStatus(type,mainEntityList,entityList) {   
    const mainEntity =  mainEntityList?.find(entity => entity.entityId === Entity.entityId);
    const entity = entityList?.find(entity => entity.entityId === Entity.entityId);
    if (mainEntity) {
     if(type == 'role') 
      return mainEntity.role 
    else if(type == 'status')
      return mainEntity.status
    else if(type == 'remarks')
        return mainEntity.remarks
    
    } else if(entity){
      if(type == 'role') 
        return entity.role 
      else if(type == 'status')
        return entity.status
      else if(type == 'remarks')
          return entity.remarks
    }
  }

  function setRoleAndDesignation(mainEntityList,entityList) {
    const mainEntity = mainEntityList?.find(entity => entity.entityId === Entity.entityId);
    const entity = entityList?.find(entity => entity.entityId === Entity.entityId);

    setSelectedBranches(entityList.flatMap(entityData=> {
      // if(entityData?.gstList?.length>0){
      //   return entityData.gstList
      // }else
       if(entityData?.branchList?.length>0){
        return entityData.branchList
      }
    }))
    mainEntityList.map(entity=>{
      if(entity.entityId === Entity.entityId){
        setValue('isAdminUser',entity.isAdmin?'yes':'no')
      }
    })
    if (mainEntity) {
      setValue('role', mainEntity.role && "Entity User");
      setValue('designation', mainEntity.designation); 
      setValue('isEntityAccess', mainEntity.status.toLowerCase()); 
      setEntStatus(prevState => ({
        ...prevState,
        initial: mainEntity.status.toLowerCase()
      }));
    } else if(entity){
      setValue('role', entity.role);
      setValue('designation', entity.designation); 
      setValue('isEntityAccess', entity.status.toLowerCase());
      setEntStatus(prevState => ({
        ...prevState,
        initial: entity.status.toLowerCase()
      }));
    }
  }

  const showUserForm = async (r, x) => {
    setUserMode(r)
    if (r == 'list') {
      // setValue('email', '')
      // setValue('designation', '')
      // setValue('role', '')
      // setValue('isAdminUser', 'no')
      // setValue('branches', [])
      setSelectedBranches([])
      setResult(false)
      setSelectedRole('')
      setEntStatus(prevState => ({
        initial: '',
        entity: ''
      }));
      setSearchValue('')
      // setEntStatus('')
      setRemarks('')
      reset();
      controller.queryPayload ={status:'',branch:'',keyword:''}
      await fetchData();
    }

    if (r === 'edit') {
      setValue('email', x.id)
      setValue('firstname', x.name.split(" ")[0])
      setValue('lastname', x.name.split(" ")[1])
      setRoleAndDesignation(x?.mainEntityList,x?.entityList)
      setValue('phone',x.phoneNum)
      setValue('phonePrefix',x.phonePrefixNum)
      setValue('status',x.userStatus)
      setValue('remarks',x.remarks)
      setRemarks(x.remarks)
      // setEntStatus(getValues('isEntityAccess'))
      // setValue('designation', x.)
      // setValue('role', '')
    }
  }

  useEffect(() => {
    if (userdata.status === 200) {
      const listEntities = userdata.data.entityList?.filter((r) => r.entityId === Entity.entityId)
      const listMainEntities = userdata.data.mainEntityList?.filter((r) => r.entityId === Entity.entityId)
      if (listEntities?.length > 0 || listMainEntities?.length > 0) {
        // setValue('email', '')
        // setValue('designation', '')
        // setValue('role', "Select Role")
        // setValue('isAdminUser', 'no')
        // setValue('branches', [])
        reset();
        setSelectedRole('')
        setSelectedBranches([])

        //  routerStore.entityUserManageView = "new"
        configStore.setModalMessage("User already registered in this entity update it if required but can't create a new one")
      } else {
        cususerStore.cususerRec.set(userdata.data)
        setValue('status',userdata.data.status)
        setuserAvailable(true)
      }
    } else if (userdata.status === 404) {
      if(userMode === 'new'){
        cususerStore.cususerRec.set(CustomeruserType)
        setValue('status','NEWREG')
      }
      if (userAvailable) {
        setuserAvailable(false)
      }
    }
  }, [setUserdata, userdata])


useEffect(()=>{
  AguserApi.getUser(loginStore.email,()=>{
    aguserStore.aguserRec.mainEntityList.map(entityData=>{
      console.log(entityData,'entityData',Entity.entityId);
      if(entityData.entityId == Entity.entityId && entityData.isAdmin == true){
        setAdminUserFlag(true)
      }
    })
  })
},[])

  const onSubmit = async (data) => {

    
    
    if (data?.role == "Entity User") {
      let obj = { entityIdentifier: Entity.panNum, entityId: Entity.entityId, isAdmin: data.isAdminUser == "yes" ? true : false, role: data.isAdminUser == "yes" ? "Entity Admin" : "Entity User", designation: data.designation, status: "ACTIVE", remarks: "" }
    
      if(userMode == 'edit'){
        user.entityList = user.entityList.filter((r)=>r.entityId !== Entity.entityId)
        user.mainEntityList = user.mainEntityList.filter((r)=>r.entityId !== Entity.entityId)
      }
      user.mainEntityList.push(obj)
    }
    else if (data?.role == "Branch User") {
      let obj = {}
    
        obj = { entityIdentifier: Entity.tinNum, entityId: Entity.entityId, role: "Branch User", designation: data.designation, gstList: [], status: "ACTIVE", remarks: "", branchList: selectedBranches }

      if(userMode == 'edit'){
        user.entityList = user.entityList.filter((r)=>r.entityId !== Entity.entityId)
        user.mainEntityList = user.mainEntityList.filter((r)=>r.entityId !== Entity.entityId)
      }
      user.entityList.push(obj)
    }

    if (user.mainEntityList.length > 0) {
      user.role = ["Client Admin"]
    } else if (user.entityList.length > 0) {
      user.role = ["Client User"]
    }

    user.email = data.email
    // user.firstName = "thameem"

    let finalData = {
      from: user.firstName + ' ' + user.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
    }
    if (user.email.includes("agraga")) {
      user.isAgragaUser = "Yes"
    } else
      user.country = Entity.entityCountry
   

    if(userMode == 'edit'){
      user.phone = getValues('phone')
      user.phonePrefix = getValues('phonePrefix')
      user.firstName = data.firstname
      user.lastName = data.lastname
      setEntityStatus(user.mainEntityList,user.entityList,data.isEntityAccess.toUpperCase())
      user.status = getValues('status')
      // setValue("role",'Select Role')
    }

    // if(userMode == 'new'){
    // user.status = 'ACTIVE'
    // }
    let data1 = {
      from: user.firstName + ' ' + user.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
    }
    data1.email = data.email

    await UsersApis.createUser(finalData, async () => {
      if (userMode === 'new' && getValues('status') === 'NEWREG') {
        // await UsersApis.sendInviteMail(data1,user)
      }
      if(finalData){
        setUsersList(prevList => [...prevList, user]);
        showUserForm('list') 
        

        if(loginStore.email == data.email) {
          if(loginDataFlag){
            loginStore.reinitialize()
            localStorage.removeItem('ag_email')
            localStorage.removeItem('tour')
            localStorage.removeItem('ag_current_entity')
            localStorage.removeItem('ag_current_branch')
            localStorage.removeItem('ag_userrec')
            localStorage.removeItem('devicetoken')
            localStorage.clear()
            newDashboardState.selectedTab = 0;
            newDashboardState.isProfileSelected = false;    
          }else{
            aguserStore.aguserRec.firstName = data.firstname
            aguserStore.aguserRec.lastName = data.lastname
            aguserStore.aguserRec.phone = data.phone
            aguserStore.aguserRec.phonePrefix = data.phonePrefix

            let rememberMe = localStorage.getItem("ag_remember_user");
            let userRecString;
            let localUser;
            if (rememberMe === "true") {
                localUser = JSON.parse(localStorage.getItem("ag_userrec"))
            } else {
                localUser = JSON.parse(sessionStorage.getItem("ag_userrec"));
            }
            localUser.aguserRec = aguserStore.aguserRec;
            userRecString = JSON.stringify(localUser);
            if (rememberMe === "true") {
                localStorage.setItem("ag_userrec", userRecString)
            } else {
                sessionStorage.setItem("ag_userrec", userRecString);
            }
          }
          console.log(aguserStore.aguserRec,'cususerStore.cususerRec');
        }
      }
    })

    //  await UsersApis.createUser(finalData)
  };





  const updateKeyword = (value) => {
    console.log(value);
    setSearchValue(value);
    setController(prevState => ({
      ...prevState,
      queryPayload: {
        ...prevState.queryPayload,
        keyword: value
      }
    }));
    console.log(controller.queryPayload.keyword, "key");
  };

  const handleSearchChange = (event) => {
    setListUserCheck('search')
    const { value } = event.target;
    updateKeyword(value);
  };

  let newSortDirection;
  const getCollectiveStatus = (userStatus,entityLevelStatus)=>{
    if(userStatus === 'ACTIVE'){
      return entityLevelStatus
    }else{
      return userStatus
    }
  }

  const fetchData = async () => {
    try {
      console.log("fetchData",searchValue != '');
      const page =  searchValue != '' ? 0 : controller.page
      const res = await ApiAgTable.usersList(
        Entity.entityId,
         page,
        controller.rowsPerPage,
        controller.queryPageFilter,
        controller.queryPageSortBy,
        controller.queryPayload,
      );
      const data = await res.json();
      const extractedData = data.results.map(result => {
        const entityLevelStatus = getRoleStatus('status',result.mainEntityList,result.entityList)
        setUserDataList(data.results)
        return ({name: result.firstName + " " + result.lastName,
        role: getRoleStatus('role',result.mainEntityList,result.entityList),
        id: result._id,
        phone: result.phonePrefix + result.phone,
        status: entityLevelStatus,
        mainEntityList:result.mainEntityList,
        entityList:result.entityList,
        phoneNum:result.phone,
        phonePrefixNum:result.phonePrefix,
        userStatus:result.status,
        remarks : getRoleStatus('remarks',result.mainEntityList,result.entityList),
        collectiveStatus: getCollectiveStatus(result.status,entityLevelStatus)})
      });
      console.log(extractedData,'extractedData..');
      setUsersList(extractedData);
      setUsersCount(data.count);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect(() => {
    if(listUserCheck != 'sort'){
      fetchData();
    }
  }, [controller]);

  
  useEffect(() => {
  if(getValues('role') == 'Branch User'){
    console.log('useffect if');
    BranchApi.getAllBranches(Entity.entityId, (data) => {
      setBranchList(data)
      if (BranchList.length <= 0) {
        setResult(true)
      }
    })
  }
  }, [getValues('role')]);


 const handleSort = (column) => {
  setListUserCheck('sort')
  const newSortDirection = controller[column].sortDirection === 'asc' ? 'desc' : 'asc';
  setSortingCheck(column)
  setController({
    ...controller,
    [column]: { ...controller[column], sortDirection: newSortDirection }
  });
};






  const sortedList = [...usersList].sort((a, b) => {
    console.log(a,"*****",b);
    //  sortingCheck=='role'
    if ((a.role !== b.role && sortingCheck=='role')) {
      if (controller.role.sortDirection === 'asc') {
        return a?.role?.localeCompare(b.role);
      } else {
        return b?.role?.localeCompare(a.role);
      }
    }
      if (controller.status.sortDirection === 'asc') {
        return a?.collectiveStatus?.localeCompare(b.collectiveStatus);
      } else {
        return b?.collectiveStatus?.localeCompare(a.collectiveStatus);
      }  
    
  });

console.log(sortedList,'sortedList');

function setEntityStatus(entityList, mainEntityList,newStatus) {
  for (let i = 0; i < entityList.length; i++) {
      if (entityList[i].entityId === Entity.entityId) {
          entityList[i].status = newStatus;
          entityList[i].remarks = getValues('remarks')
          break;
      }
  }

  for (let i = 0; i < mainEntityList.length; i++) {
      if (mainEntityList[i].entityId === Entity.entityId) {
          mainEntityList[i].status = newStatus;
          mainEntityList[i].remarks = getValues('remarks')
          break;
      }
  }
}







  const handlePageChange = (event, newPage) => {
    setListUserCheck('pagechange')
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setListUserCheck('rowchange')
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  // const handleSearchChange = (event) => {
  //   const { value } = event.target;
  //   setController(prevState => ({
  //     ...prevState,
  //     queryPayload: {
  //         ...prevState.queryPayload,
  //         keyword: value
  //     }
  // }));
  //   console.log(controller.queryPayload.keyword,"keyword");
  //   // setSearchValue(value);

  //     ApiAgTable.usersList(
  //       Entity.entityId,
  //       controller.page,
  //       controller.rowsPerPage,
  //       controller.queryPageFilter,
  //       controller.queryPageSortBy,
  //       controller.queryPayload,
  //     ).then((res) => {
  //       res
  //         .clone()
  //         .json()
  //         .then(
  //           (res) => {
  //             const extractedData = res.results.map(result => ({
  //               name: result.firstName + " " + result.lastName,
  //               role: result.role,
  //               id: result._id,
  //               phone: result.phonePrefix + result.phone,
  //               status: result.status
  //           }))
  //           setPassengersList(extractedData)
  //           setPassengersCount(res.count);
  //           },
  //           (err) => {
  //             console.log(err)
  //           }
  //         )
  //     })

  // };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f0f0f0',
    border: '2px solid grey.500',
    boxShadow: 24,
    borderRadius: '16px',
    pt: 2,
    px: 4,
    pb: 3,
  };



  const addNewUser = () => {
    // branchStore.BranchRec.set(new BranchType());
    // setBranchData(new BranchType())
    setValue('role','Select Role')
    showUserForm('new')
  }



  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  

  function statusColor (){
    const status = getValues('status')
    let colorObj = {}
    if(status === USER_STATUS.ACTIVE){
     colorObj.backGroundColor = '#87e8bf'
     colorObj.color = '#035e38'
    }else if (status === USER_STATUS.INACTIVE){
     colorObj.backGroundColor = '#f59aa6'
     colorObj.color = '#e82a43'
    }else if (status === USER_STATUS.NEWREG){
     colorObj.backGroundColor = '#fccd9a'
     colorObj.color = '#f57207'
    }
    return colorObj
 }

 function collectiveStatusColor (status){
  let colorObj = {}
  if(status === COLLECTIVE_STATUS.ACTIVE){
   colorObj.backGroundColor = '#87e8bf'
   colorObj.color = '#035e38'
  }else if (status === COLLECTIVE_STATUS.INACTIVE){
   colorObj.backGroundColor = '#f59aa6'
   colorObj.color = '#e82a43'
  }else if (status === COLLECTIVE_STATUS.NEWREG){
   colorObj.backGroundColor = '#fccd9a'
   colorObj.color = '#f57207'
  }else if (status === COLLECTIVE_STATUS.DEACTIVATED){
    colorObj.backGroundColor = '#edc0c0'
    colorObj.color = '#960505'
   }
  return colorObj
}

  const options = [
    { value: 'Tamilnadu', label: 'Tamilnadu' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Delhi', label: 'Delhi' }
  ];

  const roleOptions = [
    { value: 'Entity User', label: 'Entity User' },
    { value: 'Branch User', label: 'Branch User' },
  ];

  const USER_STATUS = { NEWREG:'NEWREG',ACTIVE:'ACTIVE',INACTIVE:'INACTIVE' }
  const COLLECTIVE_STATUS = { NEWREG:'NEWREG',ACTIVE:'ACTIVE',INACTIVE:'INACTIVE',DEACTIVATED:'DEACTIVATED' }

  const entityOptions = ['Yes', 'No']
  const statusOptions = [    
  { value: 'ACTIVE', label: 'Active' },
  { value: 'DEACTIVATED', label: 'De-active' }
]

  const sendmailInvite = async () => {
   const firstName =  getValues('firstname')
   const lastName =  getValues('lastname')
   const email =  getValues('email')
    let data = {
      from: firstName + ' ' + lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
    }
    data.email = email
    await UsersApis.sendInviteMail(data,user)
  }



  const checkType = (type)=>{
    const role = getValues('role')
    if ((role === "Entity Admin" || role === "Entity User") && type === 'Entity'){
      console.log("call1");
      return true
    }else if(role == "Branch User" && type == 'Branch'){
      return true
    }else{
      return false
    }
  }


  const handleLastPage = () => {
    const lastPage = Math.ceil(usersCount / controller.rowsPerPage);
    const currentPage = controller.page;
    
    if (currentPage !== lastPage - 1) {
        handlePageChange(null, lastPage - 1);
    }
  };
  


  return (
    <Box sx={{
      flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", overflowY: "auto", "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#2A6ED4 !important" ,  height: "100%", width: "100%"
      }
  }}>
      {userMode === 'list' &&
        <>
          <Stack direction='row' sx={{ justifyContent: 'space-between', padding: '8px' }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>{Entity.entityName}</Typography>
            { adminUserFlag &&
            <Button variant='contained' startIcon={<AddIcon />} sx={{ marginLeft: 'auto' }} onClick={() => addNewUser()} >Add User</Button>}
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', width: '25%', margin: '0 auto' }}>
            <TextField
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiSearch style={{ fontSize: 20 }} />
                  </InputAdornment>
                ),
                style: { borderRadius: '20px', height: '35px' },
              }}
              style={{ width: '100%', borderRadius: '20px', paddingBottom: '20px' }}
              placeholder="Search Users"
            />
          </div>


          <Card sx={{ margin: '8px', borderRadius: '8px' }}>
          <div style={{ maxWidth: '100%' }}>
            <Table style={{ borderCollapse: 'collapse', border: '1px solid #ddd' }}>
          <TableHead style={{ backgroundColor: "#f5f7fa" }}>
            <TableRow>
              <TableCell style={{ width: '20%' }}>
                <b>Name</b>
              </TableCell>
              <TableCell onClick={() => handleSort('role')} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '20%' }}>
                <b>Role</b>
                {controller.role.sortDirection === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </TableCell>
              <TableCell style={{ width: '20%' }}>
                <b>Email</b>
              </TableCell>
              <TableCell style={{ width: '20%' }}>
                <b>Phone</b>
              </TableCell>
              <TableCell onClick={() => handleSort('status')} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '20%' }}>
                <b>Status</b>
                {controller.status.sortDirection === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: 'auto', maxHeight: 300, width: '100%' }}>
                  {sortedList.map((user, index) => (
          <TableRow style={{ width: '100%', tableLayout: 'fixed', borderBottom: index === sortedList.length - 1 ? '1px solid #ddd' : 'none' }} key={user._id} onClick={() => {
                              userDataList.map(data=>{
                                if(data._id == user.id){
                                  cususerStore.cususerRec.set(data)
                                }
                              })
                            showUserForm('edit', user)
                            }}>
          <TableCell style={{ width: '30%' }}>
                                {user.name}
          </TableCell>
          <TableCell style={{ width: '30%' }}>
                                {user.role}
          </TableCell>
          <TableCell style={{ width: '30%' }}>
                                {user.id}
          </TableCell>
          <TableCell style={{ width: '30%' }}>
                                {user.phone}
          </TableCell>
          <TableCell style={{ width: '30%' }}>
          <span style={{ color: collectiveStatusColor(user.collectiveStatus).color, backgroundColor: collectiveStatusColor(user.collectiveStatus).backGroundColor, padding: '0 5px', borderRadius: '10px', display: 'inline-block', minWidth: '100px', textAlign: 'center' }}>
          <b>{user.collectiveStatus}</b>
          </span>
          </TableCell>
          </TableRow>
                ))}
        </TableBody>
          </Table>

            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <TablePagination
                  component="div"
                  onPageChange={handlePageChange}
                  page={controller.page}
                  count={usersCount}
                  rowsPerPage={controller.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

                  <IconButton onClick={handleLastPage}>
                    <LastPageIcon />
                  </IconButton>
              </div>
          </Card></>}
      {userMode === 'new' &&
        <Modal
          open={userMode === 'new'}
          //  onClose={() => showBranchForm('list')}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: '75%', marginTop: '-50px' }}>
            <IconButton sx={{ position: 'absolute', top: '-30px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
              <Avatar style={{ backgroundColor: '#266ed4' }}>
                <PersonAddIcon style={{ backgroundColor: '#266ed4' }} />
              </Avatar>
            </IconButton>
            <Typography variant="h6" align='center' sx={{ fontWeight: 600, marginTop: '30px' }}>Add New User</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3} mt={1} >
              <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-around'}}>
                  <Stack sx={{ pr: 4, pt: 4 }}>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Email*</b></Typography>
                    <TextField id='email' size='small' type='email' variant="outlined" className={styles.root} {...register('email', { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/})} error={!!errors.email} helperText={errors.email ? "Email is required" : ""} onChange={
                      e => {
                        if (e.target.value.toLowerCase().includes("agraga.com")) {
                          reset()
                          setSelectedRole('')
                          setSelectedBranches([])
                          configStore.setModalMessage("agraga mail id not allowed for creating client users")
                          // cususerStore.cususerRec.set(CustomeruserType)
                        } else if (!e.target.value.toLowerCase().includes("agraga.com") && (e.target.value.includes(".com") && e.target.value.includes("@"))) {
                          console.log(e.target.value.toLowerCase().trim(),'tttttt');
                          UsersApis.checkUser(e.target.value.toLowerCase().trim(), Entity.entityId,setUserdata)
                        }
                      }
                    }></TextField>
                  </Stack>
                  <Stack sx={{ pr: 4, pt: 4 }}>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Role*</b></Typography>
                    <Controller
                      name="role"
                      rules={{ required: value => value !== "Select Role" || 'Role is required' }}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            size='small'
                            defaultValue={"Select Role"}
                            variant="outlined"
                            className={styles.root}
                            error={!!errors.role}
                            // helperText={errors.role ? "Role is required" : ""}
                            {...field}
                            onChange={(e) => {
                              if (e.target.value == "Branch User") {
                                BranchApi.getAllBranches(Entity.entityId, (data) => {
                                  setBranchList(data)
                                  if (BranchList.length <= 0) {
                                    setResult(true)
                                  }
                                })
                              }
                               setSelectedRole(e.target.value);
                              field.onChange(e);
                            }}
                          >
                            <MenuItem value="Select Role" disabled>Select Role</MenuItem>
                            {roleOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                          </Select>
                          {errors.role && <p style={{ color: 'red', fontSize: '13px' }}>Role is required</p>}
                        </>
                      )}
                    />
                  </Stack>
                  <Stack sx={{ mt: 2, pt: 4  }}> 
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Designation</b></Typography>
                    <TextField id='designation' size='small' variant="outlined" className={styles.root} {...register('designation',{minLength:4})} helperText={errors.designation ? (
                      <span style={{ color: '#e02424', fontSize: '13px' }}>Valid Designation is required</span>
                    ) : (
                      ""
                    )} ></TextField>
                  </Stack>
                </Stack>
                {selectedRole == "Entity User" ?
                  <Stack direction='column' spacing={2} pb={5} pl={1.5}>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Is Admin User*</b></Typography>
                    <Controller
                      name="isAdminUser"
                      control={control}
                      defaultValue="no"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          {entityOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option.toLowerCase()}
                              control={<Radio color="primary" />}
                              label={option}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                      )}
                    /></Stack>
                 : (selectedRole == "Branch User" && BranchList.length > 0) ?
                    <Stack direction='column' spacing={2} pb={5} pl={1.5}>
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body1" sx={{ fontWeight: 500 }}><b>Select Branches*</b></Typography>
                      </Stack>
                      
                      <Grid container spacing={2}>
                        {BranchList?.map((option) => {
                            return (
                              <Grid item xs={6} md={3} >
                              <Controller
                                key={option.state + "--" + option.gst}
                                name="branches"
                                control={control}
                                defaultValue={selectedBranches}
                                rules={{ required: selectedBranches?.length == 0 ? "Select at least one branch":"" }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Checkbox
                                      color="primary"
                                      inputProps={{ 'aria-label': 'checkbox' }}
                                      checked={selectedBranches.includes(option.branchId)}
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                         if (checked) {
                                          onChange(setSelectedBranches([...selectedBranches, option.branchId]));
                                        } else {
                                          onChange(setSelectedBranches(selectedBranches.filter((item) => item !== option.branchId)));
                                        }
                                      }}
                                    />
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{option.state + "--" + option.gst}</Typography>
                                  </>
                                )}
                              />
                              </Grid>
                            );

                     
                        })}
                      </Grid>

                  
                      {errors.branches && <p style={{ color: '#e02424', fontSize: '13px' }}>Select atleast one of the branches</p>}
                    </Stack> : (selectedRole == "Branch User" && BranchList.length == 0) &&
                    (<><Dialog open={result}>
                      <DialogContent sx={{ backgroundColor: "#F5F5F5", padding: "20px" }} >
                        <Box
                          onClick={() => {
                            setResult(false);
                            reset()
                          }}
                          sx={{
                            display: "flex",
                            alignSelf: "flex-end", 
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#2B6ED4",
                            borderRadius: "5px",
                            width: "max-content",
                            padding: "8px",
                            position: "absolute", 
                            top: "10px",
                            right: "10px",
                            "&:hover": {
                              cursor: "pointer"
                            }
                          }}>
                          <img style={{ width: "18px", height: "18px" }} src={closeIcon} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: 2, padding: "25px 20px" }}>
                          <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: 1 }}>
                            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Please create atleast one branch</Typography>
                          </Box>
                        </Box>
                      </DialogContent>
                    </Dialog></>)}
              </Stack>
              <Box width='100%' sx={{ marginTop: 10 }}>
                <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={2}>
                  {/* {invite && <Button variant='contained'  onClick={sendmailInvite}>Invite</Button>} */}
                  <Button variant='outlined' p={2} onClick={() => showUserForm('list')}>Cancel</Button>
                  <Button variant='contained' type='submit'>Submit</Button>
                </Stack>
              </Box>
            </form>
            {/* <Box width='100%' sx={{ marginTop: 10 }}>
              <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={2}>
                <Button variant='outlined' p={2} onClick={() => showUserForm('list')}>Cancel</Button>
                <Button variant='contained' p={2} >Submit</Button>
              </Stack>
            </Box> */}
          </Box>


        </Modal>}
      {
        userMode === 'edit' &&
        <>
          <Modal
            open={userMode === 'edit'}
            //  onClose={() => showBranchForm('list')}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ ...style, width: '75%', marginTop: '-15px' }}>
              <IconButton sx={{ position: 'absolute', top: '-30px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Avatar style={{ backgroundColor: '#266ed4' }}>
                  <PersonAddIcon style={{ backgroundColor: '#266ed4' }} />
                </Avatar>
              </IconButton>
            
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
    <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'center', width: '60%',paddingLeft:'40%' }}>User Details</Typography>

    <Typography variant="h7" sx={{ fontWeight: 700, textAlign: 'right',position: 'absolute',right:90 }}>Status :</Typography>
    {/* <Box> */}
    <Typography variant="h8" sx={{ fontWeight: 500, textAlign: 'right', position: 'absolute',borderRadius:"15px", top: 38, right: getValues('status')=='ACTIVE'? 27:15,backgroundColor:statusColor().backGroundColor,padding:'3px',color:statusColor().color}}>{getValues('status')}</Typography>
    {/* </Box> */}
</Box>

     <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} mt={1}>
                  <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-around'}}>
                    <Stack sx={{ pr: 4, pt: 4 }}>
                      <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>First Name</b></Typography>
                      <TextField id='firstname' size='small' type='text' variant="outlined" className={styles.root} {...register('firstname')}  ></TextField>
                    </Stack>
                    <Stack sx={{ pr: 4, pt: 4 }}>
                      <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Last Name</b></Typography>
                      <TextField id='lastname' size='small' type='text' variant="outlined" className={styles.root} {...register('lastname')}  ></TextField>
                    </Stack>
                    <Stack sx={{ mt: 2, pt: 4 }}> {/* No padding added to the last field */}
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Designation</b></Typography>
                    <TextField id='designation' size='small' variant="outlined" className={styles.root} {...register('designation',{minLength:4})} onChange={()=>{setLoginDataFlag(true)}} helperText={errors.designation ? (
                      <span style={{ color: '#e02424', fontSize: '13px' }}>Valid Designation is required</span>
                    ) : (
                      ""
                    )} ></TextField>
                  </Stack>
                  </Stack>
                  <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-around'}}>
                  <Stack sx={{ pr: 4, pt: 4}}>
                      <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Email*</b></Typography>
                      <TextField id='email' disabled={true} size='small' type='email' variant="outlined" className={styles.root} {...register('email', { required: true })} error={!!errors.email} helperText={errors.email ? "Email is required" : ""}></TextField>
                    </Stack>
                    <Stack sx={{ pr: 4, pt: 4 }}>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Role*</b></Typography>
                    <Controller
                      name="role"
                      rules={{ validate: value => value !== "Select Role" || 'Role is required' }}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            size='small'
                            variant="outlined"
                            className={styles.root}
                            error={!!errors.role}
                            // helperText={errors.role ? "Role is required" : ""}
                            {...field}
                            onChange={(e) => {
                              setSelectedRole(e.target.value);
                              if (e.target.value == "Branch User") {
                                BranchApi.getAllBranches(Entity.entityId, (data) => {
                                  setBranchList(data)
                                  if (BranchList.length <= 0) {
                                    setResult(true)
                                  }
                                })
                              }
                              setLoginDataFlag(true)
                              field.onChange(e);
                            }}
                          >
                            <MenuItem value={getValues('role')?getValues('role'):"Select Role"} >{getValues('role')?getValues('role'):"Select Role"}</MenuItem>
                            {roleOptions.map((option) => {
                              if(option.value !== getValues('role')){
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                              }
                      })}
                          </Select>
                          {errors.role && <p style={{ color: 'red', fontSize: '13px' }}>Role is required</p>}
                        </>
                      )}
                    />
                  </Stack>
                  <Stack sx={{ width:'28.1%', pt: 4,mt:2 }}>
                  <PhoneField callback={(e)=> {
          // console.log(e)
        setValue('phone',e?.number)
        setValue('phonePrefix',e?.code)
          // aguserStore.aguserRec.phonePrefix = e?.code  
          // setError(e.error)
        }} countryCode={getValues("phonePrefix")} phone={getValues("phone")}></PhoneField>
                  </Stack>
                    </Stack>

                 {(selectedRole == "Entity User" || (checkType('Entity') && !selectedRole)) ?
                  <Stack pl={1.5}>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Is Admin User*</b></Typography>
                    <Controller
                      name="isAdminUser"
                      control={control}
                      defaultValue={getValues('isAdminUser')?getValues('isAdminUser'):"no"}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          value={value}
                          onChange={(e) => {onChange(e.target.value)
                            setLoginDataFlag(true)
                          }}
                        >
                          {entityOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option.toLowerCase()}
                              control={<Radio color="primary" />}
                              label={option}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                      )}
                    /></Stack>
                  :(selectedRole == "Branch User" && BranchList.length > 0) || (checkType('Branch') && BranchList.length > 0)?
                    <Stack pl={1.5}>
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body1" sx={{ fontWeight: 500 }}><b>Select Branches*</b></Typography>
                      </Stack>
                      <Grid container spacing={2}>        
                        {BranchList?.map((option) => {
                          let branchSelected = selectedBranches.includes(option.branchId);
                            return (
                             <Grid item xs={6} md={3} >
                              <Controller
                                key={option.state + "--" + option.gst}
                                name="branches"
                                control={control}
                                defaultValue={selectedBranches}
                                rules={{ required:  selectedBranches?.length == 0 ? "Select at least one branch" : '' }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Checkbox
                                      color="primary"
                                      inputProps={{ 'aria-label': 'checkbox' }}
                                      checked={selectedBranches.includes(option.branchId)}
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        setLoginDataFlag(true)
                                        if (checked) {
                                          onChange(setSelectedBranches([...selectedBranches , option.branchId]));
                                        } else {
                                          onChange(setSelectedBranches(selectedBranches.filter((item) => item !== option.branchId)));
                                        }
                                      }}
                                    />
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{option.state + "--" + option.gst}</Typography>
                                  </>
                                )}
                              />
                              </Grid>
                            );

                        })}


                      </Grid>
                      {errors.branches && <p style={{ color: '#e02424', fontSize: '13px' }}>Select atleast one of the branches</p>}
                    </Stack>
                    : (selectedRole == "Branch User" && BranchList.length == 0) &&(<><Dialog open={result}>
                      <DialogContent sx={{ backgroundColor: "#F5F5F5", padding: "20px" }} >
                        <Box
                          onClick={() => {
                            setResult(false);
                            reset()
                          }}
                          sx={{
                            display: "flex",
                            alignSelf: "flex-end", 
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#2B6ED4",
                            borderRadius: "5px",
                            width: "max-content",
                            padding: "8px",
                            position: "absolute", 
                            top: "10px",
                            right: "10px",
                            "&:hover": {
                              cursor: "pointer"
                            }
                          }}>
                          <img style={{ width: "18px", height: "18px" }} src={closeIcon} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: 2, padding: "25px 20px" }}>
                          <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: 1 }}>
                            <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Please create atleast one branch</Typography>
                          </Box>
                        </Box>
                      </DialogContent>
                    </Dialog></>)
                    }
                   
                    <Stack direction='row' paddingTop={3}  >
                  <Stack pl={1.2}>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}><b>Entity Access*</b></Typography>
                    <Controller
                      name="isEntityAccess"
                      defaultValue= "Active"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          value={value} 
                          onChange={(e) => {
                            console.log(e.target.value,'data1111');
                            onChange(e.target.value)
                            setValue('remarks','');
                            if(entStatus.initial === e.target.value){
                              setValue('remarks',remarks)
                            }
                            setLoginDataFlag(true)
                            setEntStatus(prevState => ({
                              ...prevState,
                              entity: e.target.value
                            }))
                       
                          }}
                        >
                          {statusOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option.value.toLowerCase()}
                              control={<Radio color="primary" />}
                              label={option.label}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                  )}
                    />
               
                    </Stack>
                    { (entStatus.entity && (entStatus.entity != entStatus.initial) || (entStatus.initial && getValues('remarks') != '') || (entStatus.entity && getValues('remarks') == '' && entStatus.entity != entStatus.initial) ) &&
                    <Stack style={{paddingLeft:'7.2%'}}>
                    <Typography variant="h7"  sx={{ fontWeight: 500 }} width={300}><b>{ getValues("isEntityAccess") === 'active' ?'Re-active Remarks*':'De-active Remarks*'}</b></Typography>
                    <TextField
                      id="remarks"
                      className={styles.root}
                      error={!!errors.remarks}
                      helperText={errors.remarks ? "Valid Remarks is required" : ""}
                      {...register('remarks', { required: true,minLength: 4 })}
                      multiline
                      maxRows={4}
                      disabled={(entStatus.initial && getValues('remarks') != '' && getValues('remarks')?.length >= 4 ) && true}
                      onChange={(e)=>{
                        setLoginDataFlag(true)
                      }}
                    />
                   
            </Stack> }
                  
                  
                    </Stack>   
                  {getValues('status') === 'INACTIVE' &&
                    <Typography variant="h7" sx={{ fontWeight: 500,textAlign: 'center' ,width:'100%',paddingTop:'25px' }} ><b>User have been In-activated. Please reach out to pat@agraga.com for further clarification.</b></Typography>       }    
                </Stack>
                <Box width='100%' sx={{ marginTop: 10 }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }} m={2}>
  {getValues('status') === 'NEWREG' ? 
    <Button variant='contained' endIcon={<Shortcut />} onClick={sendmailInvite}>Re-invite</Button>
 :<Stack></Stack> }
  <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
    <Button variant='outlined' onClick={() => showUserForm('list')}>Cancel</Button>
    {(adminUserFlag && getValues('status')!=='INACTIVE') && <Button variant='contained' type='submit'>Submit</Button>}
  </Stack>
</Stack>



</Box>


              </form>
            </Box>
          </Modal>
        </>
      }
   </Box>

  )
};



export default UsersList