import logo6E from './airimg/6E.JPG'
import logo9W from './airimg/9W.JPG'
import logoAC from './airimg/AC.JPG'
import logoAF from './airimg/AF.JPG'
import logoAI from './airimg/AI.JPG'
import logoBA from './airimg/BA.JPG'
import logoCA from './airimg/CA.JPG'
import logoCX from './airimg/CX.JPG'
import logoD5 from './airimg/D5.JPG'
import logoD7 from './airimg/D7.JPG'
import logoEK from './airimg/EK.JPG'
import logoET from './airimg/ET.JPG'
import logoEY from './airimg/EY.JPG'
import logoFX from './airimg/FX.JPG'
import logoHX from './airimg/HX.JPG'
import logoI5 from './airimg/I5.JPG'
import logoKL from './airimg/KL.JPG'
import logoLH from './airimg/LH.JPG'
import logoO3 from './airimg/O3.JPG'
import logoQR from './airimg/QR.JPG'
import logoSG from './airimg/SG.JPG'
import logoSQ from './airimg/SQ.JPG'
import logoTG from './airimg/TG.JPG'
import logoUK from './airimg/UK.JPG'
import logoUL from './airimg/UL.JPG'
import logoVS from './airimg/VS.JPG'

const airlogoMap = {
    '6E': logo6E,
    '9W': logo9W,
    'AC': logoAC,
    'AF': logoAF,
    'AI': logoAI,
    'BA': logoBA,
    'CA': logoCA,
    'CX': logoCX,
    'D5': logoD5,
    'D7': logoD7,
    'EK': logoEK,
    'ET': logoET,
    'EY': logoEY,
    'FX': logoFX,
    'HX': logoHX,
    'I5': logoI5,
    'KL': logoKL,
    'LH': logoLH,
    'O3': logoO3,
    'QR': logoQR,
    'SG': logoSG,
    'SQ': logoSQ,
    'TG': logoTG,
    'UK': logoUK,
    'UL': logoUL,
    'VS': logoVS
}
export default airlogoMap