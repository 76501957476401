const airExportChargesData = {
    initialLength: 15,
    preList: [{
            id: 1,
            chargeDescription: 'Pick-up Charges',
            currency: 'INR'
        },
        {
            id: 2,
            chargeDescription: 'Origin Customs Clearance (if applicable)',
            currency: 'INR',
            disabled: ['perKg', 'minimum']
        },
        {
            id: 3,
            chargeDescription: 'Airport Terminal Charges',
            currency: 'INR',
            disabled: ['perAWB']
        },
        {
            id: 4,
            chargeDescription: 'AWB Charges',
            currency: 'INR',
            disabled: ['perKg', 'minimum']
        },
        {
            id: 5,
            chargeDescription: 'AMS',
            currency: 'INR',
            disabled: ['perKg', 'minimum']
        },
        {
            id: 6,
            chargeDescription: 'Air Freight',
            currency: '',
            disabled: ['perAWB']
        },
        {
            id: 7,
            chargeDescription: 'Fuel Surcharge',
            currency: '',
            disabled: ['perAWB']
        },
        {
            id: 8,
            chargeDescription: 'Security Surcharge',
            currency: '',
            disabled: ['perAWB']
        },
        {
            id: 9,
            chargeDescription: 'X-Ray/Screening Charges',
            currency: '',
            disabled: ['perAWB']
        },
        {
            id: 10,
            chargeDescription: 'Landing Charges',
            currency: 'INR',
            disabled: ['perAWB']
        },
        {
            id: 11,
            chargeDescription: 'Hazardous Surcharges (if applicable)',
            currency: 'USD',
            disabled: ['perKg', 'minimum']
        },
        {
            id: 12,
            chargeDescription: 'Delivery Order Charges',
            currency: '',
            disabled: ['perKg', 'minimum']
        },
        {
            id: 13,
            chargeDescription: 'Destination Terminal Handling Charges',
            currency: '',
            disabled: ['perAWB']
        },
        {
            id: 14,
            chargeDescription: 'Destination Customs Clearance (if applicable)',
            currency: '',
            disabled: ['perKg', 'minimum']
        },
        {
            id: 15,
            chargeDescription: 'Door Delivery Charges (if applicable)',
            currency: 'INR'
        }
    ]
}
export default airExportChargesData