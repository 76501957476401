import React from 'react'

function SelectField({container,data,id,max,input_style,min,placeholder,children,type,classname,value,
  ref,pattern,callback,label_class,label_style,default_label,first_value,icon_childer ,errorsmsg}) {
return (
  <>
  <p className={label_class} style={{...label_style}}>
              {placeholder} *
            </p>
  {/* <div className={container}> */}

  <select className={classname} value={value} style={{...input_style}} {...children} onChange={(e) => callback(e)}>

    <option value={first_value}>{default_label}</option>
                {data}
    </select>

            {icon_childer}
            {errorsmsg && (
                  <p className="help is-input-danger-message">
                    {errorsmsg}
                  </p>
                )}
        
  </>
)
}

export default SelectField