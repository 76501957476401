import React, { useState, useEffect, useRef } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

import {
    DtactionsColumn, DtactionsColumnPending
} from "../components/ag-table/columns"

import ReactTablePagination from '../components/ag-table/ReactTablePagination'
import TableFilter from "../components/ag-table/TableFilter"

import { Sorting } from "../components/ag-table/ag-sorting"
import {
    reducer, PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    PAGE_SORT_CHANGED,
    PAGE_FILTER_CHANGED,
    TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED
} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"
import { aguserStore, configStore, loginStore, routerStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { AguserType } from "../state/Types"
import { dtactionStore } from "./dtaction-store"
import { DtStore } from "../dt/DtStore"
import DtactionsEdit from "./dtactions-edit"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

let roleFilter = {
    'AGRAGA ADMIN': 'ALL',
    'OPERATIONS': 'OPERATIONS',
    'FINANCE': 'FINANCE',
    'SALES': 'SALES'
}

const DataTable = (props) => {
    useSnapshot(configStore)
    useSnapshot(aguserStore)
    useSnapshot(dtactionStore)
    useSnapshot(loginStore)
    let role = loginStore.userRec.aguserRec.role
    let defaultActionType = role
    if (RoleUtils.isUserAuthorized(PERMISSIONS.OLD.MYACTIONS_ROLE) 
        || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_OPERATIONS) 
        || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_OPERATIONS_VIEW) 
        || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_BUSINESS_VIEW)) {
      defaultActionType = "OPERATIONS"
    } else if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE) 
        || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_OPERATIONS_VIEW)) {
      defaultActionType = "FINANCE"
    } else if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_BUSINESS)) {
      defaultActionType = "SALES"
    } else {
      defaultActionType = "OPERATIONS"
    }
 // if (!["OPERATIONS", "FINANCE", "SALES"].includes(role)) role = "OPERATIONS";
  /////////////////////////////////////////////////////////////////////////
    let [actionType, setActionType1] = useState(defaultActionType)
    const setActionType = (data) => {
        console.log('>>>>>>>>> now settin to ==', data)
        setActionType1(data)
    }
    const [displayStatus, setDisplayStatus] = useState('PENDING')
    const [searchTerm, setSearchTerm] = useState('')
    const [roleButtonVisible, setRoleButtonVisible] = useState(false)
    const [viewOnlyTabs, setViewOnlyTabs] = useState(false)
    const [displayData, setDisplayData] = useState(true)
    const initialRender = useRef(false)

    let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
    let inactiveStyle = { backgroundColor: '#888', color: 'white', border: '1px solid #bbbbbb', margin: '1px' }

    let pendingStyle = inactiveStyle
    let completedStyle = inactiveStyle
    if (displayStatus === 'PENDING') pendingStyle = activeStyle
    else completedStyle = activeStyle

    let operationsStyle = inactiveStyle
    let financeStyle = inactiveStyle
    let salesStyle = inactiveStyle
    let showOperationsBtn = false
    let showFinanceBtn = false
    let showBusinessBtn = false
    if (actionType === "OPERATIONS") { operationsStyle = activeStyle; showOperationsBtn = true }
    if (actionType.toUpperCase() === "FINANCE") { financeStyle = activeStyle; showFinanceBtn = true }
    if (actionType === "SALES") { salesStyle = activeStyle; showBusinessBtn = true }

    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    // let columns = useMemo( () => COLUMNS, [])
    let columns = DtactionsColumn
    if (displayStatus == 'PENDING') columns = DtactionsColumnPending
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState)
    useEffect(() => {
        let role = loginStore.userRec.aguserRec.role
        // if (role === 'AGRAGA ADMIN' || role == 'SALES' || role === 'Agraga Admin') setRoleButtonVisible(true)
        if (RoleUtils.isUserAuthorized(PERMISSIONS.OLD.MYACTIONS_ROLE)) {
          setRoleButtonVisible(true)
          // setActionType("OPERATIONS");
        }
        // else if (['OPERATIONS', 'FINANCE', 'SALES'].includes(role)) {
        else if (RoleUtils.isUserAuthorized(PERMISSIONS.OLD.MYACTIONS_TYPE)) {
            console.log('*** ABOUT TO SET THE ROLE TO=', role, '\n-----------------\n')
            setActionType(role)
      } else if(RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_OPERATIONS_VIEW) || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_BUSINESS_VIEW)){
        setViewOnlyTabs(true)
      } else if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_OPERATIONS)) {
        setActionType("OPERATIONS")
      } else if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE)) {
        setActionType("FINANCE")
      } else if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_BUSINESS)) {
        setActionType("SALES")
         
      } else {
        setDisplayData(false)
      }
    }, [])
    useEffect(() => {
        if(initialRender.current === true){
            let func1 = async () => {
            console.log('\n\nJUST BEFORE FIRING DTACTIONS===================', actionType, '\n', 
                JSON.stringify(queryPageFilter), '\n', JSON.stringify({"filter":{actionType, displayStatus, searchTerm}}), '***')
            let ret = await ApiAgTable.dtactions(queryPageIndex, queryPageSize, 
                queryPageFilter, queryPageSortBy, 
                {"filter":{actionType, displayStatus, searchTerm}})
            let res = await ret.json()
            console.log('JUST BEFORE SETTING THE RESULTS=', res.results.length, res.count)
            setData1(res)
            }
            func1()
            /*ApiAgTable.dtactions(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
                res.json().then((res) => {
                    console.log('\n\n\nWE ARE HERE\n', data1.results.length, res.results.length, '\n============\n')
                        //Commenting this out as it was not refreshing the results
                    setData1(res)
                }, err => {
                    console.log(err)
                })
            })*/
        }
        initialRender.current = true;
    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, dtactionStore.listLoadCounter])



    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results || [],
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );
    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { filter: { actionType, displayStatus, searchTerm } } });
        gotoPage(0);
    }, [actionType, displayStatus, searchTerm]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    if (!displayData) return <div>Domestic transport My Actions available only to selected roles</div>

    if (dtactionStore.view == 'view') return <DtactionsEdit />

    return (
        <>
            <div className="main-content">
                <div className="header-wrapper">
                    <div className="left-header">
                        <div className="page-heading">
                            <span className="heading">Domestic Transport - Actions</span>
                            <span>
                                <input className="input" value={searchTerm} placeholder="Search"
                                    style={{ marginLeft: '2rem' }}
                                    onChange={(ev) => {
                                        setSearchTerm(ev.target.value)
                                    }} />
                            </span>
                        </div>
                    </div>
                    <div className="right-header-2">
                        {
                            (roleButtonVisible || viewOnlyTabs) ? (
                            <span>
                                <button className="button is-small"
                                    onClick={() => setActionType('OPERATIONS')}
                                    style={{ borderRadius: '35px 0px 0px 35px', ...operationsStyle }}>OPERATIONS</button>
                                <button className="button is-small"
                                    onClick={() => setActionType('FINANCE')}
                                    style={{ ...financeStyle }}>FINANCE</button>
                              { (!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_BUSINESS_TAB_VIEW)) ?
                                <button className="button is-small"
                                    onClick={() => setActionType('SALES')}
                                    style={{ borderRadius: '0px 35px 35px 0px', ...salesStyle }}>BUSINESS</button>
                           :null
                           }
                            </span>
                        ) : <span>
                          {showOperationsBtn && (
                            <button
                              className="button is-small"
                              onClick={() => setActionType("OPERATIONS")}
                              style={{
                                borderRadius: "35px 35px 35px 35px",
                                ...operationsStyle,
                              }}
                            >
                              OPERATIONS
                            </button>
                          )}
                          {showFinanceBtn &&
                            <button
                              className="button is-small"
                              onClick={() => setActionType("FINANCE")}
                              style={{
                                borderRadius: "35px 35px 35px 35px",
                                ...financeStyle
                              }}
                            >
                              FINANCE
                            </button>}
                          {showBusinessBtn &&
                            <button
                              className="button is-small"
                              onClick={() => setActionType("SALES")}
                              style={{
                                borderRadius: "35px 35px 35px 35px",
                                ...salesStyle
                              }}
                            >
                              BUSINESS
                            </button>}
                        </span>}
                    </div>
                    <div className="right-header-2">
                        <span>
                            <button className="button is-small"
                                onClick={() => setDisplayStatus('PENDING')}
                                style={{ borderRadius: '25px 0px 0px 25px', ...pendingStyle }}>View Pending</button>
                            <button className="button is-small"
                                onClick={() => setDisplayStatus('COMPLETED')}
                                style={{ borderRadius: '0px 25px 25px 0px', ...completedStyle }}>View Completed</button>
                        </span>
                    </div>
                </div>
            </div>


            {
                <>
                    {
                        (typeof data1?.count === 'undefined' || data1?.count == 0) && <p>No results found</p>
                    }
                    {(data1?.count > 0) && (
                        <>
                            <div className="table-container">
                                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                <th>#</th>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        <Sorting column={column} />
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    <td>{(pageIndex * pageSize) + i + 1}</td>
                                                    {
                                                        row.cells.map(cell => {
                                                            {
                                                                let e = cell['row']['original']
                                                                switch (cell['column']['Header']) {
                                                                    case 'Customer Name':
                                                                        return <td {...cell.getCellProps()}><span>{e.entityName}</span></td>
                                                                    case 'Branch Name':
                                                                        return <td {...cell.getCellProps()}><span>{e.branch ? <span>{e.branch.state.split("/")[0]} - {e.branch.gst}</span> : null}</span></td>
                                                                    case 'Actions':
                                                                        return (<td {...cell.getCellProps()}>
                                                                            <div className="field has-addons">
                                                                                <p className="control">
                                                                                    <button className="button is-dark is-inverted"
                                                                                        onClick={() => {
                                                                                            dtactionStore.current = e
                                                                                            DtStore.DTBooking = e.booking
                                                                                            dtactionStore.view = 'view'
                                                                                        }}>
                                                                                        <span className="icon is-small">
                                                                                            {
                                                                                                e.status == 'COMPLETED' ? (
                                                                                                    <i className="fas fa-eye"></i>
                                                                                                  ) : (
                                                                                                    <i className="fas fa-pen-to-square"></i>
                                                                                            )}
                                                                                        </span></button></p>
                                                                            </div>
                                                                        </td>
                                                                      )
                                                                    case 'Completed Date and Time':
                                                                        return <td {...cell.getCellProps()}><span>{('' + new Date(e.tsCompletedOn)).split('GMT')[0]}</span></td>
                                                                    default:
                                                                        return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                }
                                                            }

                                                        })
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <ReactTablePagination
                                    page={page}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    pageCount={pageCount}
                                    setPageSize={setPageSize}
                                    manualPageSize={manualPageSize}
                                    dataLength={totalCount}
                                    rows={rows}
                                />
                            </div>
                        </>
                    )}
                </>
            }
        </>
    )
}



const Dtactionslist = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default Dtactionslist