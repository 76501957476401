import { useSnapshot } from "valtio"
import { routerStore } from "../../state/CommonState"
import ListUsers from "./list-user"
import EditUsers from "./edit-user"
// import EditCredit from "./edit-credit";
export default function MainUsers(fn, mode) {
  const store = useSnapshot(routerStore)
  switch (store.entityUserManageView) {
    case "list":
      return <ListUsers  fn={fn} />
    case "edit":
      return <EditUsers mode="edit" fn={fn} />
    case "new":
      return <EditUsers mode="new" fn={fn} />
    default:
      return <EditUsers mode="view" fn={fn} />
  }
}
