import { useSnapshot } from "valtio"
import { loginStore } from "../state/CommonState"
import { rtpStore } from "../pricing/pricing-store"
import LCLPricingSchedule from "../pricing/lcl.schedule"
import FCLPricingSchedule from "../pricing/fcl.schedule"

const ChargeTable = (props) => {
    if (!props.charge) return <></>
    return <>
        {
            Object.keys(props.charge).map(e => <tr>
                <td></td>
                <td>{e}</td>
                <td>{props.charge[e].category}</td>
                <td colSpan='2'>
                    {
                        props.charge[e].comp ?
                            Object.entries(props.charge[e].comp).map(e1 => '' + e1 + ', ') :
                            'Comp undefined'
                    }
                </td>
                <td>
                {
                     ((e==="Pick-up Charges" && props.charge[e].total===0) || (e==="Delivery Charges" && props.charge[e].total===0)) ?
                     "Charges awaited"
                     : <>
                    Total = {props.charge[e].total} {
                        typeof props.charge[e].markup != 'undefined' && props.charge[e].markup != 0 ? '(' + props.charge[e].markup + ')' : ''
                    }
                    </>
                }
                </td>
            </tr>)
        }
    </>
}
export default function SpotFCLDisplay(props) {
    useSnapshot(loginStore)
    useSnapshot(rtpStore)
    let routes = props.routes
    let cwfVisible = props.cwfVisible
    let setcwfVisible = props.setcwfVisible

    return (
        <>
            <table className="table is-bordered is-fullwidth is-narrow " style={{ fontSize: '0.75rem', padding: '0px' }}>
                {
                    (!loginStore.isClient()) ?
                        <thead>
                            <tr>
                                <th colSpan={7}>Total Routes = {routes.length}, Displaying max 100 routes</th>
                            </tr>
                            <tr>
                                <th>ID(Set)</th>
                                <th>POR</th>
                                <th>POL</th>
                                <th>RE</th>
                                <th>POD</th>
                                <th>FPOD</th>
                            </tr>
                        </thead>
                        : <></>
                }
                <tbody>
                    {
                        rtpStore.records.slice(0, 100).map((e, subscript) => <>
                            {
                                (!loginStore.isClient()) ?
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td>{e.ID}({e.set})</td>
                                        <td>{e.por}</td>
                                        <td>{e.pol}</td>
                                        <td>{e.rep}</td>
                                        <td>{e.pod}</td>
                                        <td>{e.fpod}</td>
                                    </tr>
                                    : <></>
                            }
                            {
                                !loginStore.isClient() ?
                                    <ChargeTable charge={e.charges} />
                                    : <></>
                            }
                            {
                                (!loginStore.isClient()) ?
                                    <tr style={{ color: '#a30' }}>
                                        <td colSpan='6'>
                                            Sources :
                                            {
                                                Object.keys(e.sources).map(k => <p>{JSON.stringify(e.sources[k]).replace(`{"_id":{"ptype":`, `{`)}</p>)
                                            }
                                        </td>
                                    </tr>
                                    : <></>
                            }
                            {
                                (e.dummyContract.schedules.length > 0) ?
                                    <tr style={{ color: '#a30' }}>
                                        <td colSpan='6'>
                                            {
                                                e.dummyContract.schedules.map(s => <FCLPricingSchedule
                                                    skipvalidation={true} pricingRec={e} data={s} contract={e.dummyContract} subscript={subscript}
                                                    hideme={setcwfVisible} cwfVisible={cwfVisible} />)
                                            }
                                        </td>
                                    </tr>
                                    : <></>
                            }
                            {
                                (e.dummyContract.schedules.length == 0) ?
                                    <tr style={{ color: '#a30' }}>
                                        <td colSpan='6'>
                                            <FCLPricingSchedule pricingRec={e} contract={e.dummyContract} isDummy={true} subscript={subscript}
                                                hideme={setcwfVisible} cwfVisible={cwfVisible} />
                                        </td>
                                    </tr>
                                    : <></>
                            }
                        </>)
                    }
                </tbody>
            </table>
        </>
    )
}