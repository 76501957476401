import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import Api from '../state/Api';
import { EmailListComp } from "../components/AgComponents"
import { Columns, Col, Col2, Col3, Col4, Col6, Col12 } from "../Controls"
import { toEmailStore, ccEmailStore, quoteDataRec, AgmodalStore, configStore, modalStore } from "../state/CommonState"
import { useSnapshot } from 'valtio';
import QuoteViewv2 from './quote-view-v2';
import EntityApi from '../state/entity-api';
import EntityV2Api from '../state/entities-api';
import { ENTITY_STATUS } from '../util/constants';

export default function QuoteViewMainv2() {
    useSnapshot(configStore)
    const [space, setSpace] = useState('')
    const [isButtonClicked, setButtonClicked] = useState(false);

    useLayoutEffect(() => {
        Api.getExchangerates()
        setTimeout(() => {
            setSpace(' ')
        }, 300);
    }, [])


    return (
        <>
            {space}
            <Columns>
                <Col6>
                    <EmailListComp label='TO - email ids' emailStore={toEmailStore} />
                </Col6>
                <Col6>
                    <EmailListComp label='CC - email ids' emailStore={ccEmailStore} />
                </Col6>
            </Columns>
            <QuoteViewv2 />
            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
                <div className="control">
                    <button className="button is-link"
                    disabled={isButtonClicked}
                        onClick={(e) => {
                            EntityV2Api.viewEntity(quoteDataRec.entityId , (data)=> {
                            if(data.status === ENTITY_STATUS.DEACTIVATED){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for sending mail`)
                            }else {
                                if( quoteDataRec.entityId === "TBA") {
                                Api.send3Mail()
                            }else{
                                Api.send2Mail()
                            }
                            
                            if(toEmailStore.emails.length < 1 ){
                                e.target.disabled = false
                                setButtonClicked(false)
                            }

                            }
                            })
                        
                            // else{
                            //     e.target.disabled = true
                            // }

                        }}>
                        Send mail
                    </button>
                </div>
                <div className="control">
                    <button class="button is-danger" onClick={() => {
                        AgmodalStore.pageVisible = false;
                        AgmodalStore.apiCall = true;
                    }}>
                        Cancel
                    </button>
                </div>
            </div>

            {/*<button className="button is-warning" style={{marginLeft: '10px'}} onClick={() => {
                Api.sendMailwithPDF()
            }}>
                Send PDF over mail
        </button>*/}
        </>
    )
}
