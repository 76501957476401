import React from 'react'

function InputField({container,id,max,input_style,min,placeholder,children,type,classname,value,
    ref,pattern,callback,label_class,maxLength,label_style,onkeydown,icon_childer,errorsmsg,mandatory}) {
  return (
    <>
    <p className={label_class} style={{...label_style}}>
                {placeholder}{(mandatory === true || mandatory === undefined )? '*' : ''} 
              </p>
    <div className={container}>

    <input
                id={id}
                min={min}
                max={max}
                maxLength={maxLength}
                type={type}
                style={{...input_style}}
                className={classname}
                value={value}
                ref={ref}
                pattern={pattern}
                onChange={(e) => callback(e)}
                onKeyDown={onkeydown}
                {...children}
              />

              {icon_childer}
    </div>
    {errorsmsg && (
                  <p className="help is-input-danger-message">
                    {errorsmsg}
                  </p>
                )}

    </>
  )
}

export default InputField