import Api from "./Api";
import {
  AgmodalStore,
  branchStore,
  configStore,
  EntityV2Store,
  routerStore,
} from "./CommonState";
import { EntityType, BranchType } from "./Types";
import { HTTP_STATUS_CODES } from "../util/constants";
import { loader } from "../util/loader";
//mport { loader } from "../util/loader";

const ENTITYURI = {
  POST: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/new`,
  CUSTOMERENTITYCREATION: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/entityCreationByCustomer`,
  CUSTOMERSIGNUP: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/newForCustomerSignUp`,
  CHECKPAGE: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/entityCreationPageCheck`,
  GET: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/` + encodeURI(id),
  GETBYNAME: (name) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entityByName?keyword=${encodeURIComponent(name)}`,
  GET_entityAndQuote: (entityID, quoteID) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entityAndQuote/${entityID}/${quoteID}`,
  GET2: (id) =>
    `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/stakeholder/entity/` + encodeURI(id),
  PUT: (entityID, branchGST) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${entityID}?GST=${branchGST}`,
  DELETE: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/delete/${id}`,
  DEACTIVATE: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/status/${id}?status=deactivated`,
  REACTIVATE: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/status/${id}?status=pending`,
  CHECKPAN: (data) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/pan/${data}`,
  CHECKTIN: (data) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/tin/${data}`,
  CHECKGST: (data) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/gst/${data}`,
   CREATTRADELINE: (data) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/servicibility/${data}`,
    COUNTRYALL: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getallcountry`,
    UPDATETIMELINE: () =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/timeline`,
    UPDATEREMARKS: () =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/remarksUpdate`,
    BankAccount: ()=>
    `${process.env.REACT_APP_API_SERVER}/api/v1/integration/createContact`,
    Payout: ()=>
    `${process.env.REACT_APP_API_SERVER}/api/v1/integration/payout`,
    PaymentEnable: ()=>
    `${process.env.REACT_APP_API_SERVER}/api/v1/integration/paymentEnable`,
    // SecureNowEnable: ()=>
    // `${process.env.REACT_APP_API_SERVER}/api/v1/integration/secureNowEnable`,
    ManualEnable: ()=>
    `${process.env.REACT_APP_API_SERVER}/api/v1/integration/manualEnable`,
    CHECKMYACTIONENT: ()=>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/myactionstatus`,
    VerifyGst:()=>
    `${process.env.REACT_APP_API_SERVER}/api/v1/integration/getGstDetails`,
    ADDSHIPPERS: ()=> `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/addShippers`,
    GETSHIPPERS:(id)=> `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listShippers/${id}`,
    LISTENTITY: (id)=>`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listEntity/${id}`,
    UPDATESHIPPER: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/updateShippers/${id}`,
    GETENTITYLSITWITHEMAIL: (id,entityId) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getEntity/${id}/${entityId}`,
    GETENTITYFORUSER: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entityforuser/${id}`,
};
export default class EntityV2Api {
  // static createEntity() {
  //   console.log("about to call api.post");
  //   Api.post(ENTITYURI.POST, EntityV2Store.EntityRec, (data) => {
  //     console.log("data", data);
  //     if (data.status !== 400) {
  //       configStore.setModalMessage("Entity Created Sucessfully");
  //       EntityV2Store.EntityRec.set(new EntityType());
  //       routerStore.entityCurrentViewNew = "list";
  //     } else {
  //       configStore.setModalMessage("Entity not Created Sucessfully");
  //       EntityV2Store.EntityRec.set(new EntityType());
  //       // routerStore.entityCurrentViewNew = "list";
  //     }
  //   });
  // }

  static getAllCountrys(callback = f => f) {
    Api.fetch(ENTITYURI.COUNTRYALL, (data) => {
        const filteredData = data.filter(entity => entity._id !== "TBA");
        callback(filteredData);
        return filteredData;
    });
 }
 
  static createEntityForCustomer(setloading,callback) {
    console.log("about to call api.post");
    Api.post(ENTITYURI.CUSTOMERSIGNUP, EntityV2Store.EntityRec, (data) => {
      console.log("data", data);
      if(data) {
        if(callback){
          callback(data)
        }
        setloading(false)
      }
      // handleApiResponse(data);
      
    })
    
  }
  static checkPage(type,setloading,callback) {
    console.log("about to call api.post");
    Api.post(ENTITYURI.CHECKPAGE, {Entity:EntityV2Store.EntityRec,type}, (data) => {
      console.log("data", data);
      if(data) {
        if(callback){
          callback(data)
        }
        setloading(false)
      }
      // handleApiResponse(data);
      
    })
    
  }
  
  static createEntity(setloading , Data , callback ) {
    console.log("about to call api.post");
    let data 
    if(Data){
    data = Data
    }else {
   data =EntityV2Store.EntityRec
    }
    console.log("S",EntityV2Store.EntityRec)
    Api.post(ENTITYURI.POST, data, (data) => {
      console.log("data", data);
      if(data) {
        setloading(false)}
      handleApiResponse(data, callback);
      
    });

    function handleApiResponse(data , callback) {
      if (data.status === HTTP_STATUS_CODES.CREATED) {
        handleSuccess();
        if(callback){
          callback(data)
        }
      } else if (data.status === HTTP_STATUS_CODES.BAD_REQUEST) {
        handleBadRequest();
      } else if (data.status === HTTP_STATUS_CODES.CONFLICT) {
        const message = data.message
        configStore.setModalMessage(
          message
        );
      }
       else {
        handleUnknownError();
      }
    }

    function handleSuccess() {
      AgmodalStore.pageVisible = false;
      AgmodalStore.apiCall = true;
      configStore.setModalMessage("Entity Created Successfully");
      EntityV2Store.EntityRec.set(new EntityType());
      routerStore.entityCurrentViewNew = "list";
    }

    function handleBadRequest() {
      configStore.setModalMessage("Entity not Created Successfully");
      EntityV2Store.EntityRec.set(new EntityType());
      // You can add additional handling specific to 400 status here
    }

    function handleUnknownError(data) {
      if (data.status === HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR) {
        configStore.setModalMessage(
          "An internal server error occurred while creating the entity."
        );

        console.error("Internal Server Error:", data.status);
      } else {
        configStore.setModalMessage(
          "An unknown error occurred while creating the entity."
        );

        console.error("Unknown status code:", data.status);
      }
    }
  }

  static createEntityBycustomer(setloading , Data , callback ) {
    console.log("about to call api.post");
    let data 
    if(Data){
    data = Data
    }else {
   data =EntityV2Store.EntityRec
    }
    console.log("S",EntityV2Store.EntityRec)
    Api.post(ENTITYURI.CUSTOMERENTITYCREATION, data, (data) => {
      console.log("data", data);
      if(data) {
        setloading(false)}
      handleApiResponse(data, callback);
      
    });

    function handleApiResponse(data , callback) {
      if (data.status === HTTP_STATUS_CODES.CREATED ) {
        handleSuccess();
        if(callback){
          callback(data)
        }
      } else if (data.status === HTTP_STATUS_CODES.BAD_REQUEST) {
        handleBadRequest();
      } else if (data.status === HTTP_STATUS_CODES.OK) {
        if(callback){
          callback(data)
        }
      }  else {
        handleUnknownError();
      }
    }

    function handleSuccess() {
      configStore.setModalMessage("Entity Created Successfully");
      EntityV2Store.EntityRec.set(new EntityType());
      routerStore.entityCurrentViewNew = "list";
    }

    function handleBadRequest() {
      configStore.setModalMessage("Entity not Created Successfully");
      EntityV2Store.EntityRec.set(new EntityType());
      // You can add additional handling specific to 400 status here
    }

    function handleUnknownError(data) {
      if (data.status === HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR) {
        configStore.setModalMessage(
          "An internal server error occurred while creating the entity."
        );

        console.error("Internal Server Error:", data.status);
      } else {
        configStore.setModalMessage(
          "An unknown error occurred while creating the entity."
        );

        console.error("Unknown status code:", data.status);
      }
    }
  }
  static async viewEntityAndQuote(entityID, quoteID, callback) {
    console.log("about to call api1", entityID, quoteID);
    let entityData;
    await Api.fetch1(ENTITYURI.GET_entityAndQuote(entityID, quoteID), (response) => {
      let data = response.Entites;
      if(!data?.funded_Id){
        let fund = data.funded_Id
        data.funded_Id=fund
      }
      entityData=data
      if (!data.branch.length > 0) {
        branchStore.BranchRec.set(new BranchType());
        let branchh = branchStore.BranchRec;
        branchh.country = data.entityCountry;
        data.branch.push(branchh);
      }
      // branchStore.BranchRec.set(BranchType)
      if(callback){
        callback(response)  
      }
      EntityV2Store.EntityRec.set(data);
      
    });
    return entityData
  }
  static async viewEntity(entityID,callback) {
    console.log("about to call api1.get");
    let entityData;
    await Api.fetch1(ENTITYURI.GET(entityID), (data) => {
      console.log("data", data);
      console.log("Funded",data?.funded_Id)
      if(!data?.funded_Id){
        let fund = data.funded_Id
        data.funded_Id=fund
      }
      entityData=data
      if (!data.branch.length > 0) {
        branchStore.BranchRec.set(new BranchType());
        let branchh = branchStore.BranchRec;
        branchh.country = data.entityCountry;
        data.branch.push(branchh);
      }
      // branchStore.BranchRec.set(BranchType)
      if(callback){
        callback(data)  
      }
      EntityV2Store.EntityRec.set(data);
      
    });
    return entityData
  }

  static async viewEntityByName(entityName,callback) {
    await Api.fetch1(ENTITYURI.GETBYNAME(entityName), (data) => {
      if(callback){
        callback(data)  
      }   
    });
  }

  static async viewEntityPricing(entityID,callback) {
    console.log("about to call api.get");
    let entityData;
    await Api.fetch1(ENTITYURI.GET2(entityID), (data) => {
      console.log("data", data);
      entityData=data
      if (!data.branch.length > 0) {
        branchStore.BranchRec.set(new BranchType());
        let branchh = branchStore.BranchRec;
        branchh.country = data.entityCountry;
        data.branch.push(branchh);
      }
      // branchStore.BranchRec.set(BranchType)
      if(callback){
        callback(data)  
      }
      EntityV2Store.EntityRec.set(data);
      
    });
    return entityData
  }
  
  static async viewEntity1(entity,setloading) {
    console.log("about to call api.get");
    console.log("entityID",entity)
    await Api.post(ENTITYURI.BankAccount(),entity, (data) => {
      console.log("data", data);
      configStore.setModalMessage("Fund Account Created Successfully...");
      this.viewEntity(entity.reference_id)
      // if (!data.branch.length > 0) {
      //   branchStore.BranchRec.set(new BranchType());
      //   let branchh = branchStore.BranchRec;
      //   branchh.country = data.entityCountry;
      //   data.branch.push(branchh);
      // }
      // branchStore.BranchRec.set(BranchType)
      
      //EntityV2Store.EntityRec.set(data);
      
    });
  }

  static async verifyGST(entity,callback = f => f) {
    loader.show()
    console.log("about to call api.get");
    console.log("entityID",entity)
    await Api.post(ENTITYURI.VerifyGst(),entity, (data) => {
      console.log("data", data);
      callback(data)
     // configStore.setModalMessage("Fund Account Created Successfully...");
    });
  }


  
  static async makePayment(entity) {
    console.log("about to Payment api call.",entity);
    let entityData =await this.viewEntity(entity.id)
    let paymentData = {
    "reference_id":entity.id,
    "account_number": entityData.vendor.accountNo,
    "fund_account_id": entityData.funded_Id,
    "amount": entity.amount,
    }
    await Api.post(ENTITYURI.Payout(),paymentData, (data) => {
      console.log("data", data);
      configStore.setModalMessage("Funded Account Created Successfully...");
      // if (!data.branch.length > 0) {
      //   branchStore.BranchRec.set(new BranchType());
      //   let branchh = branchStore.BranchRec;
      //   branchh.country = data.entityCountry;
      //   data.branch.push(branchh);
      // }
      // branchStore.BranchRec.set(BranchType)
      
      //EntityV2Store.EntityRec.set(data);
      
    });
  }
  static updateEntity(entityID, branchGST , setloading , callback , data) {
    console.log("about to call api.put");
    let Data
    if(data){
      Data= data

    }else {
      Data = EntityV2Store.EntityRec
    }
    console.log(EntityV2Store.EntityRec);
    Api.put(ENTITYURI.PUT(entityID, branchGST), Data, (data) => {
      console.log("data", data.data);
      if (data){setloading(false)}
      if(callback){
        callback(data)
      }
      EntityV2Store.EntityRec.set(data);
      AgmodalStore.pageVisible = false
      AgmodalStore.apiCall = true
      routerStore.entityCurrentViewNew = "list";
    });
    
  }

  static async deleteEntity(entityID, callback = (f) => f) {
    console.log("about to call delete api");
    Api.put(ENTITYURI.DELETE(entityID), EntityV2Store.EntityRec, (data) => {
      callback(data);
    });
  }

  static async deactivateEntity(entityID, callback = (f) => f) {
    console.log("about to call deactivate api");
    Api.put(ENTITYURI.DEACTIVATE(entityID), EntityV2Store.EntityRec, (data) => {
      callback(data);
    });
  }

  static async paymentEnable(entity, callback = (f) => f) {
    console.log("about to call paymentEnable api");
    console.log(entity)
    Api.post(ENTITYURI.PaymentEnable(),entity, (data) => {
      console.log("D",data)
    });
  }

  // static async secureNowEnable(entity, callback = (f) => f) {
  //   console.log("about to call secureNowEnable api");
  //   console.log(entity)
  //   Api.post(ENTITYURI.SecureNowEnable(),entity, (data) => {
  //     console.log("D",data)
  //   });
  // }

  static async manualEnable(entity, callback = (f) => f) {
    console.log("about to call manualEnable api");
    console.log(entity)
    Api.post(ENTITYURI.ManualEnable(),entity, (data) => {
      console.log("D",data)
    });
  }

  static async reactivateEntity(entityID, callback = (f) => f) {
    console.log("about to call reactivate api");
    Api.put(ENTITYURI.REACTIVATE(entityID), EntityV2Store.EntityRec, (data) => {
      callback(data);
    });
  }

  static async checkPan(data, callback = (f) => f ) {
    console.log("about to call check pan number api");
    await Api.fetch1(ENTITYURI.CHECKPAN(data), (response) => {  
      if(response?.data?.entityId !== EntityV2Store.EntityRec.entityId){
        callback(response);
      }
     
    });
  }
  static async checkTin(data, callback = (f) => f) {
    console.log("about to call check tin number api");
    await Api.fetch1(ENTITYURI.CHECKTIN(data), (response) => {
      if(response?.data?.entityId !== EntityV2Store.EntityRec.entityId){
        callback(response);
      }
    });
  }
  static async checkGst(data,  callback = (f) => f) {
    
      console.log("About to call check GST API");
      
      await Api.fetch1(ENTITYURI.CHECKGST(data), (response) => {
        if(response?.data?.entityId !== EntityV2Store.EntityRec.entityId){
          callback(response);
        }
      });
      
  }

  static async createTradeLine(id , data,  callback = (f) => f) {
    
    console.log("About to call check TradeLine API");
    await Api.put(ENTITYURI.CREATTRADELINE(id), data , (response) => {
      callback(response);
      if(response.lastErrorObject.updatedExisting === true){
        EntityV2Store.EntityRec.set(data);
        routerStore.entityCurrentViewNew = "list";
      }
    });
    
}

static updateEntityTimeLine(entityData) {
  console.log("about to call api.put",entityData);
  console.log(entityData);
  Api.post(ENTITYURI.UPDATETIMELINE(), entityData, (data) => {
    console.log("data", data);
    if (data){
    // EntityV2Store.EntityRec.set(data);
    routerStore.entityCurrentViewNew = "list";
  }}
  );
  
}
static updateEntityRemarks(entityData) {
  console.log("about to call api.put",entityData);
  console.log(entityData);
  Api.post(ENTITYURI.UPDATEREMARKS(), entityData, (data) => {
    console.log("data", data);
    if (data){
    // EntityV2Store.EntityRec.set(data);
    routerStore.entityCurrentViewNew = "list";
  }}
  );
  
}

static checkMyactionEnt(entityId,branchId ,callback) {
  console.log("about to call api.post",entityId);
  let data = {
    entityId : entityId,
  branchId : branchId  }

  Api.post(ENTITYURI.CHECKMYACTIONENT(), data, (datas) => {
    console.log("data", datas);
    if (datas.status === 200){
      callback(datas)
  }else if (datas.status === 204){
    configStore.setModalMessage(datas.message)
  }
}
  );
  
}
 

static AddShippers(shipperData,callback) {
  console.log("about to call api.put",shipperData);
  console.log(shipperData);
  Api.post(ENTITYURI.ADDSHIPPERS(), shipperData, (data) => {
    console.log("data", data);
    if (data){
      callback(data)
    routerStore.shipperManageView = "list";
  }}
  );
  
}
 static async GetShippers(filterData,callback) {
  console.log(filterData);
  Api.post(ENTITYURI.GETSHIPPERS(filterData.entityId),filterData, (data) => {
      callback(data);
      // return data;
  });
}
 static async ListEntity(entityId,callback) {
  Api.fetch(ENTITYURI.LISTENTITY(entityId), (data) => {
      callback(data);
      // return data;
  });
}
static updateShipper(id,shipperData) {
;
  Api.post(ENTITYURI.UPDATESHIPPER(id), shipperData, (data) => {
    console.log("data", data);
    if (data){
      routerStore.shipperManageView = "list";
  }}
  );
  
}
static GetEntityListWithEmail(id,entityId,callback) {

  Api.fetch(ENTITYURI.GETENTITYLSITWITHEMAIL(id,entityId), (data) => {
    console.log("data", data);
    if (data){
      callback(data)
  }
}
  );
  
}

static EntityListForUser(id , callback) {
  Api.fetch(ENTITYURI.GETENTITYFORUSER(id), (data) => {
      callback(data)
  })
}
//
}