import homeIcon from "../assets/images/home.svg";
import { breadCrumbStore } from "../state/CommonState";
import arrow from "../assets/images/actionarrow.svg";

function Point(){
    return <div className="min-h-[5px] min-w-[5px] rounded-[50%] bg-blue-dark"></div>
}

export function BreadCrumb2(){
    return <div className="flex w-full flex-row gap-[5px] justify-between items-center">
        <div className="flex flex-row gap-[5px]">
        <div className="flex flex-row gap-[5px] h-[14px] items-center">
            <img src={homeIcon} />
            <p className="text-xs text-blue-dark font-bold">Home</p>
            <Point/>
        </div>
         {breadCrumbStore.breadCrumbs.map((breadCrumb,i)=><div onClick={()=>{
             breadCrumbStore.setCurrentCrumb(i);
         }} className={`flex flex-row gap-[5px] h-[14px] items-center cursor-pointer`}>
         <p className={`text-xs ${ !(i===breadCrumbStore.breadCrumbs.length-1) ? "text-blue-dark font-bold" : "text-[black]"} hover:text-[black]`}>{breadCrumb}</p>
         { !(i===breadCrumbStore.breadCrumbs.length-1) && <Point/>}
         </div>)}
         </div>
        {breadCrumbStore.breadCrumbs.length>1 && <button type="button" onClick={()=>{
            breadCrumbStore.back();
        }} className="rounded-[5px] bg-blue-light text-white w-[80px] h-[35px] flex justify-center items-center border-0">
            <div className="flex flex-rows gap-2 items-center">
            <img style={{height:"9.3px",width:"5.4px",objectFit:"contain",filter:"brightness(0) invert(1)",transform:"rotate(180deg)"}} src={arrow} />
             <p>Back</p>
            </div></button>}
    </div>
}