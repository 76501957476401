import { useSnapshot } from "valtio"
import { taskStore } from "./task-store"

export default function Tasktriggermodal(props) {
    useSnapshot(taskStore)
    let modalClass = 'modal'
    if (taskStore.triggerModalVisible) modalClass += ' is-active'
    return (
        <div className={modalClass} onClick={()=>{taskStore.triggerModalVisible = false}} style={{zIndex:1000000}}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e)=>{e.stopPropagation()}} 
                style={{borderRadius: '10px'}}>
                <section className="modal-card-body">
                   <button className="delete" style={{float: 'right'}} 
                        onClick={() => {taskStore.triggerModalVisible = false}}></button>
                   <span>{taskStore.modalMessage}</span>
                </section>
            </div>
        </div>
    )
}