import React, { useState, useEffect, useRef } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from 'react-query'

import {
    MyActionAgragaComplete, MyActionClientPending,
    MyActionClientCompleted,
    MyActionAgragaPending,
    MyActionAgragaCustomerPending,
} from "../components/ag-table/columns"

import ReactTablePagination from '../components/ag-table/ReactTablePagination'
import TableFilter from "../components/ag-table/TableFilter"

import { Sorting } from "../components/ag-table/ag-sorting"
import {
    reducer, PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    PAGE_SORT_CHANGED,
    PAGE_FILTER_CHANGED,
    TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED
} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"
import { AgmodalStore, configStore, contractsStore, entityStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { taskStore } from "../mytasks/task-store"
import TaskContainer from "../mytasks/task-container"
import Utils from "../util/Utils"
import TableHead from "../util/dataTable";
import BookingsApi from "../state/bookings-api"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS, VERTICALS } from "../util/constants"
import { Box, Typography } from "@mui/material"
import noData from "../assets/images/nodata.svg";
import { CenteredCircularProgress } from "../mui-components/CircularProgress"
import { loader } from "../util/loader"
import { AgLoader } from "../components/ag-loader/ag-loader"

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};



const DataTable = (props) => {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    useSnapshot(entityStore)
    useSnapshot(loader)
    const [displayStatus, setDisplayStatus] = useState('PENDING')
    const [actionType, setActionType] = useState('AGRAGA')
    let showCustomerBtn = false, showActionsBtn = false;

    if (loginStore.isClient() && actionType == 'AGRAGA') {
        setActionType('CUSTOMER')
    } else if(loginStore.userRec.aguserRec.vertical == VERTICALS.CENTRAL){
    // if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MY_AGRAGA_ACTIONS_OPERATIONS)) {
                 showActionsBtn = true;
                 showCustomerBtn = true;
    }else if (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_CUSTOMER_FOLLOW_UP)) {
                if(actionType != 'CUSTOMER'){
                    setActionType('CUSTOMER')
                }
                showCustomerBtn = true;
            }
    
    let column = {}

    if (loginStore.isClient()) {
        if (displayStatus == 'PENDING' && actionType == 'CUSTOMER') {
            column = MyActionClientPending
        } else {
            column = MyActionClientCompleted
        }
    } else {
        if (displayStatus == 'PENDING' && actionType == 'CUSTOMER') {
            column = MyActionAgragaCustomerPending
        } else if (displayStatus == 'PENDING' && actionType == 'AGRAGA') {
            column = MyActionAgragaPending
        } else {
            column = MyActionAgragaComplete
        }
    }
    const [keyword, setKeyword] = useState('');
    const [isLoading,setLoading] = useState(true);
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    // let columns = useMemo( () => COLUMNS, [])
    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);
        const abortControllerRef = useRef(null);
    useEffect(()=>{
        console.log("/pg_getmyaction", queryPayload.displayStatus, AgmodalStore.apiCall)
        if(queryPayload.displayStatus && AgmodalStore.apiCall){
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
              }
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            setLoading(true)
            ApiAgTable.myactionsData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload,abortController.signal).then(res=>{
                res.clone().json().then((res) => {
                    if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                        if(JSON.stringify(data1)!=JSON.stringify(res) && res.results && res.results.length > 0){
                            setData1(res)
                        }else{
                            setData1({ count: 0, results: [] });
                        }
                    }
                    setLoading(false)
                }, err => {
                setLoading(false)
        
                    console.log(err)
                })
            }).catch((e)=>{
                if(e.name==="AbortError"){
                    setLoading(true)
                }else{
                    setLoading(false)
                } 
                setData1({results:[]})
            })
        }
},[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy, configStore.currentSelectedBranch , configStore.currentSelectedEntity])
    
    // let { isLoading, error, data, isSuccess } = useQuery(
    //     [queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload],
    //     () => ApiAgTable.myactionsData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload),
    //     {
    //         keepPreviousData: false,
    //         staleTime: Infinity,
    //     }
    // );
    // if (data) {
    //     try {
    //         data.clone().json().then((res) => {
    //             if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
    //                 setData1(res)
    //             }
    //         }, err => {
    //             console.log(err)
    //         })
    //     } catch (error) {

    //     }

    // }


    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );
    const manualPageSize = [];

    useEffect(()=>{
        if(isLoading){
           loader.show();
        }else{
         loader.hide();
        }
     },[isLoading])

     const setapicall = (type,payload)=>{
        if(loginStore.isClient() && !taskStore.myActionVisible && !AgmodalStore.apiCall){
            AgmodalStore.apiCall = true
        }
        if(!loginStore.isClient() && AgmodalStore.modalPage != "TaskContainer" && !AgmodalStore.apiCall){
            AgmodalStore.apiCall = true
        }
        if(AgmodalStore.apiCall){
            dispatch({ type: type, payload: payload });
        }
     }
     

    useEffect(() => {
        setapicall(PAGE_CHANGED,pageIndex)
    }, [pageIndex,taskStore.triggerActionVisible,taskStore.myActionVisible,AgmodalStore.apiCall,AgmodalStore.modalPage]);

    useEffect(() => {
        setapicall(PAGE_SIZE_CHANGED,pageSize)
        gotoPage(0);
    }, [pageSize, gotoPage,taskStore.triggerActionVisible,taskStore.myActionVisible,AgmodalStore.apiCall,AgmodalStore.modalPage]);

    useEffect(() => {
        setapicall(PAGE_SORT_CHANGED,sortBy)
        gotoPage(0);
    }, [sortBy, gotoPage,taskStore.triggerActionVisible,taskStore.myActionVisible,AgmodalStore.apiCall,AgmodalStore.modalPage]);

    useEffect(() => {
        setapicall(PAGE_FILTER_CHANGED,keyword)
        gotoPage(0);
    }, [keyword, gotoPage,taskStore.triggerActionVisible,taskStore.myActionVisible,AgmodalStore.apiCall,AgmodalStore.modalPage]);

    useEffect(() => {
        setapicall(PAYLOAD_CHANGED,{ displayStatus: displayStatus, actionType: actionType })
        gotoPage(0);
    }, [columns, displayStatus, actionType, displayStatus, actionType, taskStore,
        taskStore.current.status, configStore.currentSelectedEntity.gst,taskStore.triggerActionVisible,taskStore.myActionVisible,AgmodalStore.apiCall,AgmodalStore.modalPage]);

    React.useEffect(() => {
        if (data1?.count) {
            setapicall(TOTAL_COUNT_CHANGED,data1?.count)
        }
    }, [data1?.count,taskStore.triggerActionVisible,taskStore.myActionVisible,AgmodalStore.apiCall,AgmodalStore.modalPage]);

    function getBranchName (branchId) {
        console.log(configStore?.currentSelectedEntity);
     let branch = configStore?.currentSelectedEntity?.branches?.filter((x,i) => x.branchId === branchId)
     if(branch.length > 0){
        return `${branch[0]?.state?.split("/")[0]} - ${branch[0]?.gst}`
     }
     return '--'
    }

    let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
    let inactiveStyle = { backgroundColor: '#bbbbbb', color: 'white', border: '1px solid #bbbbbb' }
    let pendingStyle = inactiveStyle
    let completedStyle = inactiveStyle
    if (displayStatus === 'PENDING') pendingStyle = activeStyle
    else completedStyle = activeStyle
    let agragaStyle = inactiveStyle
    let customerStyle = inactiveStyle
    if (actionType === 'AGRAGA') agragaStyle = activeStyle
    else customerStyle = activeStyle
    if (taskStore.myActionVisible) return <TaskContainer />

        return (
            <>
            <AgLoader/>
                <div className="columns">
                    <div className="column is-2">
                        <h5 className="title is-4"
                            style={{ color: '#313131', fontFamily: 'product_sans_bold' }}>My Actions</h5>
                    </div>
                    <div className="column is-4">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                    <div className="column is-3">
                        {
                            (!loginStore.isClient()) ?
                                <span>
                            
                                    <>
                                        {showActionsBtn &&
                                            <button className="button is-small"
                                                onClick={() => {
                                                    actionType != 'AGRAGA' && 
                                                    setActionType('AGRAGA')
                                                }}
                                                style={{ borderRadius: '35px 35px 35px 35px', ...agragaStyle }}>Agraga Actions</button>}
                                        {showCustomerBtn &&
                                            <button className="button is-small"
                                                onClick={() => {
                                                    actionType != 'CUSTOMER' && 
                                                    setActionType('CUSTOMER')
                                                }}
                                                style={{ borderRadius: '35px 35px 35px 35px', ...customerStyle }}>Customer Follow-up</button>}
                                    </>
                                </span>
                                : <></>
                        }
                    </div>
                    {
                        loginStore.isClient() ?  <Box sx={{ display: "flex", flexDirection: "row", marginY:"10px",width: "20%",height:"40px", backgroundColor: "white", borderRadius: "20px", padding: "4px", fontSize: "14px", fontFamily: "Figtree" }}>
                             <Box  onClick={() => setDisplayStatus('PENDING')} sx={{
        borderRadius: "20px", padding : "5px",backgroundColor: displayStatus === "PENDING" ? "#2A6ED4" : "transparent", color:displayStatus === "PENDING"? "white" : "#555555", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontFamily: "Figtree",
        "&:hover": {
            cursor: "pointer"
        }
    }}>View Pending</Box>
     <Box  onClick={() => setDisplayStatus('COMPLETED')} sx={{
        borderRadius: "20px", padding : "5px",backgroundColor: displayStatus === "COMPLETED" ? "#2A6ED4" : "transparent", color: displayStatus === "COMPLETED" ? "white" : "#555555", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontFamily: "Figtree",
        "&:hover": {
            cursor: "pointer"
        }
    }}>View Completed</Box>
                        </Box>  :
                    <div className="column is-3">
                        <span>
                            <button className="button is-small"
                                onClick={() => setDisplayStatus('PENDING')}
                                style={{ borderRadius: '25px 0px 0px 25px', ...pendingStyle }}>View Pending</button>
                            <button className="button is-small"
                                onClick={() => setDisplayStatus('COMPLETED')}
                                style={{ borderRadius: '0px 25px 25px 0px', ...completedStyle }}>View Completed</button>
                        </span>
                    </div>
}
                </div>
                {
                    <>
                        {
                            (typeof data1?.count === 'undefined' || data1?.count == 0) && 
                            <>
                            <Box sx={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:'column',gap:2}}>
                            <img  src={noData} />
                            <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>No results found</Typography>
                            </Box>
                           
                            </>
}
                        {(data1?.count > 0) &&
                            <>
                                <div className="box" style={{flex:1}}>
                                   <div className="table-container">
                                        <table className="table is-narrow is-small is-fullwidth" {...getTableProps()}>
                                            <TableHead headerGroups={headerGroups} sort={true}/>
                                            <tbody className="contractlist" style={{ fontSize: '0.9rem' }} {...getTableBodyProps()}>
                                                {page.map(row => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()} onClick={() => {
                                                            let e = row['original']
                                                            if(!loginStore.isClient()) e = e['Myactions']
                                                            BookingsApi.getBooking(e.bookingNum, (data) => {
                                                                AgmodalStore.apiCall = false
                                                                contractsStore.current = data?.contract
                                                                // enquiryStore.initializeWithConract(data.contract)
                                                                console.log('MYACTION ROW CLICKED=', e)
                                                                taskStore.current = e
                                                                taskStore.current.actionType = actionType
                                                                taskStore.current.financeInformation = data?.finance
                                                                taskStore.currentContainer = ''
                                                                taskStore.current.lastview = actionType
                                                                if(taskStore.current.isValid == undefined) taskStore.current.isValid = true
                                                                if(taskStore.current.isValidfield == undefined) taskStore.current.isValidfield = []
                                                                if(loginStore.isClient()){
                                                                    taskStore.myActionVisible = true;
                                                                    taskStore.isViewAll = true;
                                                                }else{
                                                                    AgmodalStore.modalPage = "TaskContainer";
                                                                    AgmodalStore.pageVisible = true;
                                                                }
                                                            })
                                                        }}>
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        let b = cell['row']['original']
                                                                        let c = cell['row']['original']
                                                                        if(!loginStore.isClient()) {
                                                                            b = b['Branches']; 
                                                                            e = e['Myactions'] ;    
                                                                            c= c['Entities'];
                                                                        }
                                                                        // }else {
                                                                        //     b = b['Branches']; 
                                                                        //     e = e['Myactions'] ;
                                                                        // }


                                                                        switch (cell['column']['Header']) {
                                                                            case 'Due Date and Time':
                                                                                return <td {...cell.getCellProps()}><span>{e.dueDate ? Utils.formatToDateTime1(e?.dueDate, e?.dueTime): ""}</span></td>
                                                                            case "BranchName":
                                                                            if (loginStore.isClient()){
                                                                                return <td {...cell.getCellProps()}><span>{getBranchName(e.branchId)}</span></td>

                                                                            }else {
                                                                                return <td>{b.state ? `${b?.state?.split("/")[0]} - ${(b.gst.length > 0 ? b.gst : c.tinNum )}`: ""}</td>    
                                                                            }
                                                                              
                                                                                    // return <td>{e.branchId ? `${e?.Branches[0]?.state}-${e?.Branches[0]?.gst}`: ""}</td>
                                                                            case 'Completed Date and Time':
                                                                                if(loginStore.isClient()){
                                                                                    return <td {...cell.getCellProps()}><span>{e.customerCompletedOn ? Utils.formatToDateTime(e.customerCompletedOn) : ''}</span></td>
                                                                                }else{
                                                                                    return <td {...cell.getCellProps()}><span>{e.completedOn ? Utils.formatToDateTime(e.completedOn) : ''}</span></td>
                                                                                }
                                                                            case 'Timer':
                                                                                return (!loginStore.isClient() && displayStatus=='PENDING' && e.updatelist!=undefined && e.updatelist.length>0)?<td {...cell.getCellProps()}><span>{Utils.gettimer(e.updatelist[e.updatelist.length-1]['updatedOn'])}</span></td>:<td {...cell.getCellProps()}></td>
                                                                            case 'Customer Completion':
                                                                                return <td {...cell.getCellProps()}><span>{Utils.formatToDateTime(e.customerCompletedOn) + ' by ' + e.customerCompletedBy}</span></td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactTablePagination
                                        page={page}
                                        gotoPage={gotoPage}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        canPreviousPage={canPreviousPage}
                                        canNextPage={canNextPage}
                                        pageOptions={pageOptions}
                                        pageSize={pageSize}
                                        pageIndex={pageIndex}
                                        pageCount={pageCount}
                                        setPageSize={setPageSize}
                                        manualPageSize={manualPageSize}
                                        dataLength={totalCount}
                                        rows={rows}
                                    />
                                
                                </div>
                            </>
                        }
                    </>
                }


            </>
        )
}



const TaskList = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default TaskList;