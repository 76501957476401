import { useSnapshot } from "valtio"
import { dtactionStore } from "./dtaction-store"
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls"

export default function DtactionsVendorbank (props) {
    useSnapshot(dtactionStore)
    let action = dtactionStore.current
    let booking = action.booking

    return (
        <>
        <section class="section section-padding" >
                <div class="blue-heading"></div>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Vendor Name</Col3>
                    <Col4>{action.vendor}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Vendor GST#</Col3>
                    <Col4>{action.gst}</Col4>
                </Cols>
                {
                          (booking.bookingamount!=undefined && booking.vendor_agreed_cost!=undefined)  ?
                          <>
                          <Cols>
                                            <Col3 style={{ color: 'darkblue' }}>Gross Profit Amount</Col3>
                                            <Col4>{Number(booking.bookingamount)-Number(booking.vendor_agreed_cost)} (INR)</Col4>
                                            </Cols><Cols>
                                            <Col3 style={{ color: 'darkblue' }}>GP %</Col3>
                                            <Col4>{(((Number(booking.bookingamount)-Number(booking.vendor_agreed_cost))/Number(booking.vendor_agreed_cost))*100).toFixed(2) }</Col4>
                                        </Cols>
                          </>
                          :""
                        }
            </section>
            <section class="section section-padding" >
                <div class="blue-heading">Bank Account Details</div>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Account Number</Col3>
                    <Col4>{action.accountNo}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>Bank Name</Col3>
                    <Col4>{action.bankName}</Col4>
                </Cols>
                <Cols>
                    <Col3 style={{ color: 'darkblue' }}>IFSC Code</Col3>
                    <Col4>{action.IFSCcode}</Col4>
                </Cols>
            </section>
            </>
    )
}