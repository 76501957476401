import { routerStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
//import ListVehicle from "./list-vehicle"
import ListVehicle from "./list-vehicle-new";
//import EditVehicle from "./edit-vehicle";
import EditVehicle from "./edit-vehicle-new";
//import ViewVehicle from "./view-vehicle";

export default function MainVehicle(fn,mode) {
  const store = useSnapshot(routerStore);
  if (fn.fn) {
    return <EditVehicle mode="edit" fn={fn.fn}/>;
  }
  switch (store.vehicleCurrentView) {
    case "list":
      return <ListVehicle />;
    case "view":
      return <EditVehicle mode="view"  />;
    case "edit":
      return <EditVehicle mode="edit" />;
    case "new":
      return <EditVehicle mode="new" />;
    default:
      return <ListVehicle />;
  }
}
