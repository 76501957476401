import { Box, Checkbox, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { usePricing } from "../context/PricingContext";
import Utils from "../util/Utils";
import { breadCrumbStore, contractsStore } from "../state/CommonState";
import { enquiryStore } from "../enquiry/enquiry-data";
import { useSnapshot } from "valtio";

function checkForCharges(charge,rec){
    let isValidCharge = true;
     if((charge.chargeDescription === "Pick-up Charges" && charge.total ===0) || (charge.chargeDescription.includes("Delivery Charges") && charge.total ===0) || (rec?.fba==="Yes" && charge.chargeDescription === "Destination Customs Clearance") || rec?.mode==="CONTRACT" || (rec?.mode === "QUOTE" && !charge?.isIncluded) ){
        isValidCharge = false;
     }
    return isValidCharge;
}

let enabledCharges = [
    'Origin Customs Clearance',
    'Destination Customs Clearance',
    'Pick-up Charges',
    'Delivery Charges'
]
 
 function SelectableCharges({charge,backgroundColor}){
    console.log(" the chatges came here is ",charge.chargeDescription);
    const [value,setValue] = useState(charge?.isIncluded==="Yes");
    const {changeCharges,disableInclusion,isInidaDoor,rec} = usePricing();
    const [checkForInValidCharge,setValidCharge] = useState(true);
    
    useEffect(()=>{
        if(rec){
            setValidCharge(checkForCharges(charge,rec));  
        }
    },[rec])

    return <div className={`flex flex-row justify-between w-full py-[7px] items-center px-[17px] ${backgroundColor}`}>
  <span style={{display:"flex",flexDirection:"row",gap:0.6,alignItems:"center"}}>
   {checkForInValidCharge  && <Checkbox  iconStyle={{fill: '#2C358A'}} disabled={disableInclusion} checked={value} onChange={(e)=>{
        setValue(e.target.checked);
        changeCharges(e.target.checked,charge.chargeDescription)  
    }} sx={{padding:0}} size="small"/>}
    <p className="text-[15px] text-[#1F1F1F]" >{charge.chargeDescription}</p></span>
    <p className="text-[15px] text-[#1F1F1F]" >{charge.isDiscount && charge.volumeDiscount > 0 ? Utils.N(charge.total-charge.volumeDiscount)  : charge.total == 0 ? "Charges Awaited" : Utils.N(charge.total,charge.currency)}</p>
   </div>
}

function PriceDetails({charge}){
    useSnapshot(breadCrumbStore)
    let f = charge.charges.filter((e)=>e.chargeDescription=="Marine Insurance")
    const [shipperPDA,setShipperPDA] = useState(false)
    const {showShipperPDA,setShowShipperPDA,changeCharges} = usePricing();
    return <div className="flex flex-col w-full">
        {charge.charges.map((chargeDetail,i)=> enabledCharges.includes(chargeDetail.chargeDescription) || chargeDetail.chargeDescription?.includes("Delivery Charges") ? <SelectableCharges backgroundColor={i%2===0?"bg-[white]":"bg-[#F3F3F3]"} charge={chargeDetail}/> 
         : 
         <div className={`flex flex-row justify-between w-full py-[8px] items-center px-[17px] ${i%2===0?"bg-[white]":"bg-[#F3F3F3]"} gap-2.5`}>
    <p className="text-[15px] text-[#1F1F1F]">{chargeDetail.chargeDescription}</p>
    {
        (chargeDetail["chargeDescription"]=="Marine Insurance")?<p className="text-[15px] text-[#1F1F1F]">{Utils.N(Number(chargeDetail.total)+500,chargeDetail.currency)}</p>:
        <p className="text-[15px] text-[#1F1F1F]">{Utils.N(chargeDetail.total,chargeDetail.currency)}</p>
    }
    
   </div>
)}
{ f.length>0   && <div className={`flex flex-row justify-between w-full h-[35px] items-center px-[18px] ${charge.charges?.length%2===0?"bg-[white]":"bg-[#F3F3F3]"}`}>

<p className="text-[15px] text-[#1F1F1F] font-bold">Complimentary Marine Insurance</p>
<p className="text-[15px] text-[#1F1F1F] font-bold">{"-"}{Utils.N(500)}</p>
</div>}
{f[0]?.total && <div className={`flex flex-row justify-between w-full h-[35px] items-center px-[18px] ${charge.charges?.length%2===0?"bg-[white]":"bg-[#F3F3F3]"}`}>

<p className="text-[15px] text-[#1F1F1F] font-bold">Additional Premium Applicable</p>
<p className="text-[15px] text-[#1F1F1F] font-bold">{Utils.N(f[0]?.total)}</p>
</div>}
<div className={`flex flex-row justify-between w-full h-[35px] items-center px-[18px] ${charge.charges?.length%2===0?"bg-[white]":"bg-[#F3F3F3]"}`}>
<p className="text-[15px] text-[#1F1F1F] font-bold">Total Cost</p>
<p className="text-[15px] text-[#1F1F1F] font-bold">{Utils.N(charge.total)}</p>
</div>
</div>
}

export function PricingDetailsCard2({title = "Price Details",isAccordionView=false,charge}){
    const {rec,setShowShipperPDA} = usePricing();  
    useEffect(()=>{
      if(rec){
         if(rec?.shipmentType?.includes("FCL") && rec?.oih && charge?.account?.includes('Your')){
            setShowShipperPDA((prev)=>({...prev,include:true}));
         }
      }
    },[rec]) 
    return <div className="flex flex-col w-full">
          { !isAccordionView && <div className="flex flex-row justify-between w-full h-[35px] items-center px-[18px] bg-blue-dark">
            <p className="text-sm text-[white] font-bold">{title}</p>
            {/* <p className="text-sm text-[white] font-bold">Price in INR</p> */}
           </div>}
           <PriceDetails charge={charge}/>
    </div>
}