import { proxy } from "valtio"

let loader = proxy({
    
    show: ()=>{
        let loderbody = document.getElementsByClassName('loaderbody')
        loderbody[0].style.display="flex"
    },

    hide: ()=>{
        let loderbody = document.getElementsByClassName('loaderbody')
        loderbody[0].style.display="none"
    }
})

export { loader }