import airExportChargesData from "./charges_data/AirExport"
import airImportChargesData from "./charges_data/AirImport"
import fclExportDockChargesData from "./charges_data/FCLExportDock"
import fclExportFactoryChargesData from "./charges_data/FCLExportFactory"
import fclImportChargesData from "./charges_data/FCLImport"
import lclExportChargesData from "./charges_data/LCLExportCharges"
import lclExportChargesData1 from "./charges_data/LCLExportCharges1"
import lclImportChargesData from "./charges_data/LCLImportCharges"
import lclImportChargesData1 from './charges_data/LCLImportCharges1'
import commodityList from "./static-data/commodity"
import { hazardousList } from "./static-data/commodity"

const ServerLists = {
    countryList: [],
    countryNameList: [],
    phonePrefixList: [],
    setCountryList: (list) => {
        ServerLists.countryList = list
        ServerLists.countryNameList = list.map(e => e.name)
        ServerLists.countryNameList.unshift('')
        ServerLists.phonePrefixList = list.map(e => e.dialCode).filter(e => e !== '+91')
        ServerLists.phonePrefixList.sort()
        ServerLists.phonePrefixList.unshift('91')
    }
}
const packingGroupList = ['', 'II - Medium Danger', 'III - Low Danger']
const hazardousClassList = ['', '2.1 - Flammable gases',
    '2.2 - Non-flammable, non-toxic gases', '2.3 - Toxic gases', '3 - Flammable liquids',
    '4.1 - Flammable solids', '4.2 - Spontaneously combustible - either solids or liquids',
    '4.3 - Dangerous when wet', '5.1 - Oxidising Agents', '5.2 - Organic peroxides',
    '6.1 - Toxins', '8 - Corrosives', '9 - Miscellaneous dangerous goods'
]
const hazardousLCLClassList = ['', '3 - Flammable liquids', '6.1 - Toxins',
    '8 - Corrosives', '9 - Miscellaneous dangerous goods'
]

// const shipmentTypeList = [
//     '', 'LCL', 'Air', 
//     // 'Air (Normal)', 
//     // 'Air (Temperature Controlled)', 
//     'FCL (Factory/Factory)', 'FCL (Factory/CFS)', 
//     'FCL (CFS/Factory)', 'FCL (CFS/CFS)',
//     // 'FCL (De-Stuffing)'
//     // 'FCL (Temperature Controlled)'
//     // "FCL (CFS/Factory)" :"FCL (Dock/Factory)"
// ]
const shipmentTypeList = {
        // '':'',
        'LCL': 'LCL',
        'Air':'Air',
        'FCL (Factory/Factory)': 'FCL (Factory/Factory)',
        'FCL (Factory/CFS)': 'FCL (Factory/Dock)',
        'FCL (CFS/Factory)': 'FCL (Dock/Factory)',
        'FCL (CFS/CFS)': 'FCL (Dock/Dock)',
}
const shipmentScopeList = [
    '', 'Port-to-Port', 'Port-to-Door', 'Door-to-Port', 'Door-to-Door'
]
const incoTermsList = ['', 'EXW (Ex-works)', 'FCA (Free-Carrier)', 'FAS (Free-Alongside-Ship)',
'FOB (Free-on-Board)', 'CFR (Cost and Freight)', 'CIF (Cost, Insurance and Freight)',
'CPT (Carriage Paid To)', 'CIP (Carriage and Insurance Paid To)',
'DAP (Delivered at Place)', 'DPU (Delivered Place Unloaded)',
'DDP (Delivered Duty Paid)'
]
const incoTermsListAirPricing = ['','EXW (Ex-works)', 
'FCA (Free-Carrier)',
'FOB (Free-on-Board)',
'CIF (Cost, Insurance and Freight)',
'CIP (Carriage and Insurance Paid To)',
'DAP (Delivered at Place)', 
'DDU (Delivered Duty Unpaid)',
'DDP (Delivered Duty Paid)'
]
const packageTypeList = ['', 'Pallet', 'Drum', 'Crate', 'Skid', 'Loose Cartons', 'Box', 'Bales', 'Rolls']
const getHazardousClassList = (shippingType) => (
    (shippingType.indexOf('LCL') >= 0 || shippingType.indexOf('lcl') >= 0) ?
    hazardousLCLClassList :
    hazardousClassList
)
const containerTypeList = ['', `20'STD`, `40'STD`, `40'HC`,`45'HC`, `20'FR (In-gauge)`, `40'FR (In-gauge)`,
    `20'OT (In-gauge)`, `40'OT (In-gauge)`, `20'OT (Out-gauge)`, `40'OT (Out-gauge)`,`20'FR (Out-gauge)`, `40'FR (Out-gauge)`
]
const containerTypeListTC = [``,`20'Reefer`, `40'Reefer`]
const containerTypeweights = {
  "20'STD":{"KG":2500,"TEU":1}, 
    "40'STD":{"KG":4500,"TEU":2}, 
    "40'HC":{"KG":4500,"TEU":2}, 
    "45'HC":{"KG":4800,"TEU":1}, 
    "20'FR (In-gauge)":{"KG":2360,"TEU":1}, 
    "40'FR (In-gauge)":{"KG":5000,"TEU":2},
     "20'OT (In-gauge)":{"KG":2350,"TEU":1}, 
     "40'OT (In-gauge)":{"KG":3850,"TEU":2}, 
     "20'OT (Out-gauge)":{"KG":2350,"TEU":1}, 
     "40'OT (Out-gauge)":{"KG":3850,"TEU":2}, 
     "20'FR (Out-gauge)":{"KG":2360,"TEU":1}, 
     "40'FR (Out-gauge)":{"KG":5000,"TEU":2},
     "20'Reefer":{"KG":2860,"TEU":1}, 
     "40'Reefer":{"KG":4420,"TEU":2}
}
const currencyList = ['', 'AED', 'AUD', 'BDT', 'BRL', 'CAD', 'CHF', 'CNY', 'CZK',
    'DKK', 'EUR', 'GBP', 'HKD', 'IDR', 'ILS', 'INR', 'JPY', 'KES', 'KRW', 'KWD',
    'LKR', 'MAD', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'QAR', 'RUB', 'SAR', 'SEK',
    'SGD', 'THB', 'TRY', 'TWD', 'USD', 'VND', 'ZAR'
]
const chargesData = {
    "LCL Export": lclExportChargesData,
    "LCL Import": lclImportChargesData,
    "FCL Export (Factory Stuffing)": fclExportFactoryChargesData,
    "FCL Export (Dock Stuffing)": fclExportDockChargesData,
    "FCL Import": fclImportChargesData,
    "Air Export": airExportChargesData,
    "Air Import": airImportChargesData
}
const chargesData1 = {
    "LCL Export": lclExportChargesData1,
    "LCL Import": lclImportChargesData1,
    "FCL Export (Factory Stuffing)": fclExportFactoryChargesData,
    "FCL Export (Dock Stuffing)": fclExportDockChargesData,
    "FCL Import": fclImportChargesData,
    "FCL Import (Factory De-stuffing)": fclImportChargesData,
    "FCL Import (Dock De-stuffing)": fclImportChargesData,
    "Air Export": airExportChargesData,
    "Air Import": airImportChargesData,
    'lcl': lclExportChargesData1,
    'fcl': fclImportChargesData,
    'air': airImportChargesData
}
const Roles = {
    GUEST: 'GUEST',
    AGADMIN: 'AGRAGA ADMIN',
    AGSALES: 'SALES',
    AGCS: 'CUSTOMER SERVICE',
    AGPD: 'PRODUCTS',
    AGBPO: 'BPO',
    AGOP: 'OPERATIONS',
    FINANCE: 'FINANCE',
    CLADMIN: 'CLIENT ADMIN',
    CLUSER: 'CLIENT USER'
}

const consolidatorList =[
  {
    scacCode: "VLTQ",
    consolidatorAbbrName: "Agraga",
    consolidatorName: "Agraga"
  },
  {
    scacCode: "WAIC",
    consolidatorAbbrName: "Team Global",
    consolidatorName: "Teamglobal Logistics Pvt Ltd"
  },
  {
    scacCode: "GLOL",
    consolidatorAbbrName: "Globelink",
    consolidatorName: "Globelink WW India Pvt.Ltd"
  },
  {
    scacCode: "ECUI",
    consolidatorAbbrName: "Allcargo",
    consolidatorName: "Allcargo Logistics Limited"
  },
  {
    scacCode: "DTCL",
    consolidatorAbbrName: "Vanguard",
    consolidatorName: "Vanguard Logistics Service"
  },
  {
    scacCode: "CPWL",
    consolidatorAbbrName: "CP World",
    consolidatorName: "C P World Logistics India Pvt Ltd"
  },
  {
    scacCode: "EMUM",
    consolidatorAbbrName: "EMU",
    consolidatorName: "Emu Lines Pvt Ltd"
  },
  {
    scacCode: "CPWB",
    consolidatorAbbrName: "Total Transport",
    consolidatorName: "Total Transport Systems Ltd"
  },
  {
    scacCode: "SACCON",
    consolidatorAbbrName: "SACCON Lines",
    consolidatorName: "SACCON Lines India Pvt Ltd"
  },
  {
    scacCode: "MACOLINE",
    consolidatorAbbrName: "MACOLINE",
    consolidatorName: "MACOLINE Shipping Pvt Ltd"
  },
  {
    scacCode: "GLOBAL",
    consolidatorAbbrName: "Global Logistics",
    consolidatorName: "Global Logistics Solutions India Pvt Ltd"
  },
  {
    scacCode: "WORLDGATE",
    consolidatorAbbrName: "Worldgate Express",
    consolidatorName: "Worldgate Express Lines International Pvt Ltd"
  },
  {
    scacCode: "CARGOCON",
    consolidatorAbbrName: "Cargo Console",
    consolidatorName: "Cargo Console"
  },
  {
    scacCode: "MACNEL",
    consolidatorAbbrName: "Mac-Nels",
    consolidatorName: "Mac-Nels Container Lines Pvt Ltd"
  },
  {
    scacCode: "LINKER",
    consolidatorAbbrName: "Linkers India Logistics Pvt. Ltd",
    consolidatorName: "Linkers India Logistics Pvt. Ltd"
  },
  {
    scacCode: "IMAV",
    consolidatorAbbrName: "Freight Bridge Logistics",
    consolidatorName: "Freight Bridge Logistics Pvt. Ltd"
  },
  {
    scacCode: "CUIA",
    consolidatorAbbrName: "CTL LOGISTICS ( INDIA) PRIVATE LIMITED",
    consolidatorName: "CTL LOGISTICS ( INDIA) PRIVATE LIMITED"
  },
  {
    scacCode: "UFMA",
    consolidatorAbbrName: "UNITED FREIGHT MANAGEMENT PVT LTD",
    consolidatorName: "UNITED FREIGHT MANAGEMENT PVT LTD"
  },
  {
    scacCode: "GBOR",
    consolidatorAbbrName: "GREENWICH MERIDIAN LOGISTICS INDIA PVT LTD",
    consolidatorName: "GREENWICH MERIDIAN LOGISTICS INDIA PVT LTD"
  },
  {
    scacCode: "GGLQ",
    consolidatorAbbrName: "FM GLOBAL CONSOLIDATION SERVICES PRIVATED LIMITED",
    consolidatorName: "FM GLOBAL CONSOLIDATION SERVICES PRIVATED LIMITED"
  },
  {
    scacCode: "YLIP",
    consolidatorAbbrName: "Yasuda Logistics",
    consolidatorName: "Yasuda Logistics India Private Limited"
  },
  {
    scacCode: "MGLP",
    consolidatorAbbrName: " MATSU GLOBAL",
    consolidatorName: " MATSU GLOBAL LOGISTICS PRIVATE LIMITED "
  }
]

const getStaticConsolidatorList = () => consolidatorList.map(consolidator => {
    if (!consolidator?.scacCode || consolidator.scacCode == '') 
        consolidator['scacCode'] = consolidator.consolidatorAbbrName
    return consolidator;
})

const stuffingTypes = {'': '',
'Factory':'Factory',
'CFS':'Dock'}

const deStuffingTypes = {'': '',
'Factory':'Factory',
'CFS':'Dock'}

const MSDS_AND_DG_FILE_MISSING_WARN =`It is ultimately the shipper's responsibility to check and ensure that all chemical classifications are in compliance with applicable Local, National and International regulations, including IMDG recommendations on the transport of dangerous goods. Producer of the substance is responsible for the accuracy of the MSDS provided. If any doubts by shipper, suggest shipper to check and verify with relevant authorities. Virya Logistics Technologies Private Limited will not be responsible for classification of cargo according to IMDG CODE if not properly checked and declared by shipper.`

const maxcargoWeightPerContainer = {
  "20'STD": 28190,
  "40'STD": 28000,
  "40'HC": 28000,
  "45'HC": 27700,
  "20'OT (In-gauge)": 28130,
  "40'OT (In-gauge)": 26630,
  "20'Reefer": 21040,
  "40'Reefer": 26420,
  "20'FR (In-gauge)": 30140,
  "40'FR (In-gauge)": 40000,
  "20'OT (Out-gauge)": 28130, 
  "40'OT (Out-gauge)":26630,
  "20'FR (Out-gauge)":30140, 
  "40'FR (Out-gauge)":40000
};

const maxDimensions = {
   "lcl" : {
      "cms" : {
         length : 1190,
         width : 230,
         height : 250
      },
      "mm" : {
        length : 11900,
        width : 2300,
        height : 2500
      },
      "inches" : {
        length : 468.5,
        width : 90.5,
        height : 98
      },
      "meters" : {
        length : 11.9,
        width : 2.3,
        height : 2.5
      },
      "feet" : {
        length : 39,
        width : 7.5,
        height : 8.2
      }
   },
   "air" : {
    "cms" : {
       length : 400,
       width : 312.5,
       height : 280
    },
    "mm" : {
      length : 4000,
      width : 3125,
      height : 2800
    },
    "inches" : {
      length :157.4,
      width : 123,
      height : 110.2
    },
    "meters" : {
      length : 4,
      width : 3.1,
      height : 2.8
    },
    "feet" : {
      length : 13.1,
      width : 10.2,
      height : 9.1
    }
 }
}

const typeLabelMap = {
  etd_at_pol: {label:"ETD at POL", port:"pol"},
  ts1_departure_estimate:{label:'ETD Transhipment 1', port:'ts1_port'},   
  ts2_departure_estimate: {label:"ETD Transhipment 2", port:'ts2_port'},
  etd_pod:{label:"ETD from POD", port:'pod'},
  reworking_departure_estimate:{label:'ETD Re-working', port:'reworking_port'},
  etd_airport_of_departure:{label:'ETD Airport of Departure', port:'departure_airport'},
  eta_at_pod:{label:'ETA @ POD', port:'pod'},
  ts1_arrival_estimate:{label:'ETA Transhipment 1', port:'ts1_port'},
  ts2_arrival_estimate:{label:'ETA Transhipment 2', port:'ts2_port'},
  eta_fpod:{label:'ETA @ FPOD', port:'fpod_name'},
  reworking_pol_arrival_estimate:{label:'ETA Re-working', port:'reworking_port'},
  eta_at_arrival_airport:{label:'ETA @ Arrival airport', port:'arrival_airport'},
  ts1_port:{label:'Transhipment port',port:'ts1_port'},
  ts2_port:{label:'Transhipment port 2',port:'ts2_port'},
  ts1_airport:{label:'Transhipment airport',port:'ts1_airport'},
  ts2_airport:{label:'Transhipment airport 2',port:'ts2_airport'},
  fl_vessel_name: {label:'1st Leg vessel name', port:'sob_pol'},
  ts1_vessel_number:{label:'2nd Leg vessel name',port:'ts1_port'},
  ts2_vessel_number:{label:'3rd Leg vessel name',port:'ts2_port'},
  flight_number_departure_airport:{label:'Flight number from departure airport',port:'departure_airport'},
  flight_number_ts1:{label:'Flight Number from Transhipment 1 Airport',port:'ts1_airport'},
  flight_number_ts2:{label:'Flight Number from Transhipment 2 Airport',port:'ts2_airport'}
}


const lclWeightForPort = {
  totalweight : {
    kgs : 10000,
    tons : 10,
    lbs : 22000
  },
  totalvolume : {
    cbm : 30,
    "cu ft" : 1059 
  },
  grandtotalweight : {
    kgs : 20000,
    tons : 20,
    lbs : 44000
  },
  grandtotalvolume : {
    cbm : 55,
    "cu ft" : 1942 
  }
}

const weightPerPackage =  {
  "lcl" : {
    kgs : 2000,
    tons : 2,
    lbs : 4400
  },
  "air" : {
    kgs : 6500,
    tons : 6.3,
    lbs : 14330
  }
}

const maxWeightPerContainerFcl = {
  "20'STD": {
    kgs : 28190,
    tons : 28.19,
    lbs : 62018
  },
  "40'STD":  {
    kgs : 28000,
    tons : 28,
    lbs : 61600
  },
  "40'HC": {
    kgs : 28000,
    tons : 28,
    lbs : 61600
  },
  "45'HC": {
    kgs : 27700,
    tons : 27.7,
    lbs : 61067
  },
  "20'OT (In-gauge)": {
    kgs : 28130,
    tons :28.13,
    lbs : 61886
  },
  "40'OT (In-gauge)": {
    kgs : 26630,
    tons :26.63,
    lbs : 58586
  },
  "20'Reefer": {
    kgs : 21040,
    tons :21.4,
    lbs : 46288
  },
  "40'Reefer": {
    kgs : 26420,
    tons :26.42,
    lbs : 58124
  },
  "20'FR (In-gauge)": {
    kgs : 30140,
    tons :30.14,
    lbs : 66308
  },
  "40'FR (In-gauge)": {
    kgs : 40000,
    tons :40,
    lbs : 88000
  },"20'OT (Out-gauge)":{
    kgs : 28130,
    tons :28.13,
    lbs : 61886
  }, "40'OT (Out-gauge)":{
    kgs : 26630,
    tons :26.63,
    lbs : 58586
  },"20'FR (Out-gauge)":{
    kgs : 30140,
    tons :30.14,
    lbs : 66308
  },"40'FR (Out-gauge)":{
    kgs : 40000,
    tons :40,
    lbs : 88000
  }
}

const OUTCOME = {
    WON:`WON`,
    LOST:`LOST`,
    INVALID:`INVALID`,
}

const bookingCancellationReasonList = [
    `Duplicate`,
    `Price too High`,
    `Changed my mind`,
    `Others`
]



const bookingInformation = [
  {
  mandatory : true,
  milestone : "Shipment Scope",
  checked : true,
},
{
  mandatory : true,
  milestone : "Shipper Name",
  checked : true,
},
{
  mandatory : true,
  milestone : "Consignee Name",
  checked : true,
},
{
  mandatory : true,
  milestone : "Customer Reference Number",
  checked : true,
},
{
  mandatory : true,
  milestone : "Agraga Booking Reference Number",
  checked : true,
},
{
  mandatory : true,
  milestone : "Origin",
  checked : true,
},
{
  mandatory : true,
  milestone : "Destination",
  checked : true,
},
{
  mandatory : true,
  milestone : "Place of Receipt (POR)",
  checked : true,
},
{
  mandatory : true,
  milestone : "Port of Loading (POL)",
  checked : true,
},
{
  mandatory : true,
  milestone : "Port of Discharge (POD)",
  checked : true,
},
{
  mandatory : true,
  milestone : "Final Place of Delivery (FPOD)",
  checked : true,
},
]


export {
    shipmentTypeList,
    shipmentScopeList,
    packingGroupList,
    bookingInformation,
    getHazardousClassList,
    packageTypeList,
    containerTypeList,
    containerTypeListTC,
    currencyList,
    chargesData,
    chargesData1,
    incoTermsList,
    incoTermsListAirPricing,
    commodityList,
    hazardousList,
    Roles,
    ServerLists,
    weightPerPackage,
    maxDimensions,
    maxWeightPerContainerFcl,
    getStaticConsolidatorList,
    stuffingTypes,
    lclWeightForPort,
    deStuffingTypes,
    MSDS_AND_DG_FILE_MISSING_WARN,
    maxcargoWeightPerContainer,
    OUTCOME,
    bookingCancellationReasonList,
    typeLabelMap,
    containerTypeweights
}