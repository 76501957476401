import { useSnapshot } from "valtio";
import { DtStore } from "../dt/DtStore";
import { dtactionStore } from "./dtaction-store";
import Dtnewinput from "../dt/cps/Dtnewinput";
import { Agmongofilenew } from "../components/AgFile";
import { configStore, loginStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";
import Utils from "../util/Utils";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import { Col } from "reactstrap";
import { useEffect, useState } from "react";
import DtactionsVendorbank from "./dtactions-vendorbank";
import EntityApi from "../state/entities-api";
import DtactionPayout from "./dtaction-payout";
import Dtactionslist from "./dtactions-list";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
let balanceStages = {
    INITIAL: 0,
    SUBMITTED: 1,
    COMPLETED: 9
}
export {
    balanceStages
}

export default function DtactionsBalance(props) {
    const { setSubmitCallback } = props
    useSnapshot(DtStore)
    useSnapshot(dtactionStore)
    useSnapshot(loginStore)
    useSnapshot(configStore)
    let dtStore = DtStore.DTBooking
    let action = dtactionStore.current
    let booking = action.booking
    let server = process.env.REACT_APP_API_SERVER
    let client = loginStore.isClient()
    const [entityData,setEntiyData] = useState("")
    const [displayStatus, setDisplayStatus] = useState(!action?.manualEnable?"OFF":action.manualEnable)

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(value);

    const callback = () => {
        if (dtactionStore.current.stage == balanceStages.INITIAL) {
            if (!action.utrNo || !action.utrNo.length ||
                !action.paymentAmt || !(action.paymentAmt.toString()).length ||
                !action.paymentDate || !action.paymentDate.length) {
                configStore.setModalMessage('UTR No., Payment Amount & Date are mandatory')
                return 0
            }
            dtactionStore.current.booking.advancePayment = Utils.toNum(action.paymentAmt)
            action.stage = balanceStages.COMPLETED
            action.status = 'COMPLETED'
            dtactionStore.view = 'list'
            return 1
        }
        if (dtactionStore.current.stage == balanceStages.SUBMITTED) {
            action.stage = balanceStages.COMPLETED
            action.status = 'COMPLETED'
            dtactionStore.view = 'list'
            return 1
        }
    }
    useEffect(() => {
        console.log('\n\n\n$$$$$$$$$ setsubmitcallback calling')
        dtactionStore.submitCallback = callback
        EntityApi.viewEntity(dtactionStore.current.vendor.split(" (")[0]).then((data)=>{
            setEntiyData(data)
        })
    }, [])


   
    let handleChange = event => {
        let min =0;
        let { value,  max } = event.target;
        console.log(value, max)
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        action.paymentAmt= value
    };
   

    

    let balancePaymentDue = Utils.toNum(booking.vendor_agreed_cost) +
        Utils.toNum(booking.vendor_additional_cost) -
        Utils.toNum(booking.advancePayment)
    console.log("F",balancePaymentDue)

    let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
    let inactiveStyle = { backgroundColor: '#888', color: 'white', border: '1px solid #bbbbbb', margin: '1px' }
  
    let pendingStyle = inactiveStyle
    let completedStyle = inactiveStyle

    if (displayStatus === "ON") {
        pendingStyle = activeStyle;
      } else {
        completedStyle = activeStyle;
      }
    return (
        <>
            <hr />
            <DtactionsVendorbank />
            <hr />
            <DtactionPayout></DtactionPayout>
            {action?.rejecthistory && action?.rejecthistory.length>0 ?
                <section class="section section-padding">
                    <div>
                        <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '30px' }}>{action.rejecthistory[action.rejecthistory.length - 1].reason}</h6>
                    </div>
                </section>
                : <><section class="section section-padding" >
                    <div class="blue-heading">Payment Details</div>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Advance Payment Made</Col3>
                        <Col4>{Utils.N(booking.advancePayment)}</Col4>
                        <Col4>
                        {entityData?.funded_Id && entityData?.paymentEnable == "ON" && !action.utrNo && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) && displayStatus=="OFF" ?
                        <button
                        className="button is-link"
                        style={{
                          border: "1px solid #ffffff",
                          borderRadius: "8px",
                          height: "35px",
                          color: "white",
                          // left: "14em",
                          position: "relative",
                          width: "150px",
                          marginLeft: "5%"
                        }}
                       // {...(isViewMode() ? { disabled: true } : {})}
                        onClick={async (event) => {
                            dtactionStore.modalVisible1 = true

                        }}
                        >
                        Make Payment
                      </button>:null}
                      {
                               entityData?.funded_Id && entityData?.paymentEnable == "ON" && action.status=="PENDING" &&  RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) ? 
                               <span>
                                <span class="blue-heading" style={{ marginLeft: "5%"}}>Enter Manually:</span>
                               <button className="button is-small"
                                    onClick={async () => {
                                     await EntityApi.manualEnable({
                                        bookingRef:booking._id,
                                        actionName:action._id.actionName,
                                        displayStatus:"ON"
                                     })
                                     action.manualEnable="ON"
                                     setDisplayStatus('ON')
                                   }}
                                  style={{ borderRadius: '25px 0px 0px 25px',marginLeft: "5%",

                                  ...pendingStyle}}
                                   >ON</button>
                               <button className="button is-small"
                                   onClick={async() => 
                                    { 
                                     await EntityApi.manualEnable({
                                        bookingRef:booking._id,
                                        actionName:action._id.actionName,
                                        displayStatus:"OFF"
                                   })
                                   action.manualEnable="OFF"
                                   action.utrNo=""
                                   action.paymentAmt=0
                                   action.paymentDate=''
                                   setDisplayStatus('OFF')}}
                                  style={{ borderRadius: '0px 25px 25px 0px' ,                               ...completedStyle }}
                                   >OFF</button>
                           </span>:null                             }
                          
                        </Col4>                 
                    </Cols>
                    <Cols>
                        <Col3 style={{ color: 'darkblue' }}>Balance Payment Due</Col3>
                        <Col4>{Utils.N(balancePaymentDue)}</Col4>
                    </Cols>
                </section>
        
                {(action?.status=="PENDING" && action?.manualEnable=="ON") || (action?.status=="COMPLETED" && (action?.manualEnable=="ON" || action.manualEnable=="OFF"|| !action.manualEnable)) || (action?.status=="PENDING" && entityData?.paymentEnable=="OFF") || (!entityData?.paymentEnable && !entityData?.manualEnable)   ? <section class="section section-padding" >
                        <div class="blue-heading">Please Enter Payment Details</div>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>UTR No.</Col3>
                            <Col5>
                            { (action.status=="PENDING"&& (action.manualEnable == "ON" || action.manualEnable == "OFF" || !action.manualEnable ))     ?  <input className="input"
                                    value={action.utrNo}
                                    onChange={(ev) => {
                                        action.utrNo = ev.target.value
                                    }}
                                     />: <input className="input"
                                    value={action.utrNo}
                                    // onChange={(ev) => {
                                    //     action.utrNo = ev.target.value
                                    // }}
                                    disabled />
                                    }
                               
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Payment Amount</Col3>
                            <Col5>
                            {
                                         action.status=="PENDING"  && (action.manualEnable == "ON" || action.manualEnable == "OFF" || !action.manualEnable  )  ?(
                                            <div class="field">
                                            <div class="input-d-wrapper">
                                                <input
                                                className="input"
                                                type="number"
                                                value={action.paymentAmt}
                                                onChange={(ev) => {
                                                    action.paymentAmt = ev.target.value;
                                                }}
                                                />
                                                ;
                                                <div class="input-dropdown">
                                                {"INR"}
                                                {/* <img
                                                                src="./assets/chevron-white.png"
                                                                alt="chevron"
                                                                class="chevron-3"
                                                            /> */}
                                                {/* <div class="currency-div d-none">
                                                                <ul>
                                                                <li>INR</li>
                                                                <li>USD</li>
                                                                <li>DNR</li>
                                                                <li>EUR</li>
                                                                <li>DIR</li>
                                                                <li>QSD</li>
                                                                </ul>
                                                            </div> */}
                                                </div>
                                            </div>
                                            </div>
                                        ) : (
                                            <div class="field">
                                            <div class="input-d-wrapper">
                                                <input
                                                className="input"
                                                value={action.paymentAmt}
                                                //onChange={handleChange}
                                                disabled
                                                />
                                                ;
                                                <div class="input-dropdown">
                                                {"INR"}
                                                {/* <img
                                                                src="./assets/chevron-white.png"
                                                                alt="chevron"
                                                                class="chevron-3"
                                                            /> */}
                                                {/* <div class="currency-div d-none">
                                                                <ul>
                                                                <li>INR</li>
                                                                <li>USD</li>
                                                                <li>DNR</li>
                                                                <li>EUR</li>
                                                                <li>DIR</li>
                                                                <li>QSD</li>
                                                                </ul>
                                                            </div> */}
                                                </div>
                                            </div>
                                            </div>
                                        )
                                    }
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Payment Date</Col3>
                            <Col5>
                                {(action.status=="PENDING"  && (action.manualEnable == "ON" || action.manualEnable == "OFF"  || !action.manualEnable )  )  ? 
                                <input
                                    className="input"
                                    type="date"
                                    value={action.paymentDate}
                                    onChange={(ev) => {
                                        action.paymentDate = ev.target.value;
                                    }}
                                />:<input
                                className="input"
                                type="date"
                                value={action.paymentDate}
                                onChange={(ev) => {
                                  action.paymentDate = ev.target.value;
                                }}
                                disabled
                              />}
                                
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Remarks</Col3>
                            <Col5>
                               {action.status=="PENDING"  && (action.manualEnable == "ON" || action.manualEnable == "OFF" || !action.manualEnable  ) ? 
                                <textarea className="textarea" rows="5"
                                value={action.remarks}
                                onChange={(ev) => {
                                    action.remarks = ev.target.value
                                }} 
                                ></textarea>:
                                <textarea className="textarea" rows="5"
                                value={action.remarks}
                                onChange={(ev) => {
                                    action.remarks = ev.target.value
                                }} 
                                disabled></textarea>}
                            </Col5>
                        </Cols>
                    </section>:null}
                   
                    </>}

        </>
    )
}