import { useState } from "react";
import Api from "../state/Api";
import trackMyBookingOceanStore from "./TrackMyBooking-store";

export function Popup({ isOpen, onClose, data,refresh }) {
    const close = () => {
        onClose()
    }
    const Update = async () => {
        await Api.deleteBookings(data._id,refresh);
        onClose();
    }
    let modalClass = 'modal'
    if (isOpen) modalClass += ' is-active'
    return (
        <>
            {
                (isOpen) ?
                    <div className={modalClass} onClick={onClose}>
                        <div className="modal-background"></div>
                        <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }}>
                            <section className="modal-card-body" style={{ backgroundColor: 'whitesmoke' }}>
                                <h5 style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px', fontSize: '20px' }}>Are you sure want to Delete?</h5>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    <button className="button is-warning" onClick={close}>Cancel</button>
                                    <button className="button is-danger" onClick={Update}>Delete</button>
                                </div>
                            </section>
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}