import { useSnapshot } from "valtio";
import { AgTextShow } from "../components/AgCustomComponents";
import { contractsStore } from "../state/CommonState";
import { enquiryStore } from "./enquiry-data";

const getUNCodeFromLocationString = (locString) =>
  locString
    .trim()
    .slice(locString.lastIndexOf("(") + 1, locString.length - 1)
    .trim();

export function BookingLocations(props) {
  useSnapshot(contractsStore);
  useSnapshot(enquiryStore);
  return (
    <div
      style={{ boxShadow: "5px 5px #dddddd", border: "1px solid lightgrey" }}
    >
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column">
              <AgTextShow
                label="Place of Receipt"
                value={getUNCodeFromLocationString(
                  contractsStore.current.portOfReceipt
                )}
              />
            </div>
            <div className="column ">
              <AgTextShow
                label="Port of Loading"
                value={getUNCodeFromLocationString(
                  contractsStore.current.portOfLoading
                )}
              />
            </div>
            <div className="column">
              <AgTextShow
                label="Port of Discharge"
                value={getUNCodeFromLocationString(
                  contractsStore.current.portOfDischarge
                )}
              />
            </div>
            <div className="column ">
              <AgTextShow
                label="Final Place of Delivery"
                value={getUNCodeFromLocationString(
                  contractsStore.current.finalPlaceOfDelivery
                )}
              />
            </div>
            <div className="column ">
              {contractsStore.current.shipmentType
                .toLowerCase()
                .includes("lcl") ? (
                <AgTextShow
                  label={"Consolidator(s)"}
                  value={contractsStore.current.consolidatorList
                    .map(({ scacCode }) => scacCode)
                    .join(`, `)}
                />
              ) : (
                <AgTextShow
                  label={"Carrier"}
                  value={getUNCodeFromLocationString(
                    contractsStore.current.carrier
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
