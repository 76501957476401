import { subscribe,useSnapshot } from "valtio";
import  {useEffect,useState} from "react";
import { Agyesno } from "../components/Agyesno";
import { configStore } from "../state/CommonState";
import Igmmanifesttable from "./igm-manifest-table";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Awbupload(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    return (
        <>
            <Taskfiledisplay />
            <hr />
            <div>
                {
                    (task.isBank === 'Yes') ?
                        <strong>Consignee is a Bank</strong>
                        : <></>
                }
            </div>
        </>
    )
}
export function AwbuploadTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [disableButton,setDisableButton] = useState(true)
    let task = taskStore.current
    function check(){
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <div style={{float:'left'}}>
                <Agyesno label='Is the Consignee a Bank?' record={task} name='isBank' />
            </div>
            <hr/>
            <Taskfiletable />

            <p>
                <button className="button islink"
                disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'AGRAGA'
                        taskStore.setComplete()
                        taskStore.setCustomerComplete()
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}