import { useEffect, useLayoutEffect, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import Agswitch, { Agswitch1 } from "../components/ag-switch"
import AgLabel from "../components/AgLabel"
import Api from "../state/Api"
import { AgmodalStore, bookingsStore, configStore, contractsStore, entityStore, loginStore } from "../state/CommonState"
import SchApi from "../state/sch-api"
import AirScheduleComp from "./AirScheduleComp"
import HomescreenCargo from "./homescreen-cargo"
import HomescreenContainer from "./homescreen-container"
import CarrierWebForm from "./CarrierWebForm"
import ContainerClientView from "./ContainerClientView"
import LCLSchedules from "./LCLSchedules"
import HomescreenRoute from "./homescreen-route"
import { enquiryStore } from "./enquiry-data"
import EnquiryConfirmation from "./enquiry-confirmation"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import Utils from "../util/Utils"
import QuoteCalc from "../util/quote-calc"
import { ContainerDetails } from "../state/Types"
import { CargoDetails } from "../QuotationData"
import CarrierWebView from "./carrierweb-view"
import { useGlobalState } from "../GlobalState"
import { AgInput } from "../components/AgComponents"
import MonApi from "../state/mongo-api"
import TaskTrigger from "../mytasks/task-trigger"
import { taskStore } from "../mytasks/task-store"
import { Task } from "../mytasks/tasks-api"
import Tasktriggermodal from "../mytasks/task-trigger-modal"
import Newapi from "../state/new-api"
import { myactionsstore } from "../my-actions/store-myactions"
import { Visibility } from "../my-actions/visibility"
import { VisibilityGraph } from "../my-actions/visibilitygraph"
import BookingSchedule from "../pricing/booking.schedule"
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import ActrejectModal6 from "./enquirysuggestion"

export default function ViewEnquiry(props) {
    const [space, setSpace] = useState('')
    const [graphList, setGraphList] = useState([])
    const [checkgraphList, setCheckGraphList] = useState(false)
    const [isButtonClicked, setButtonClicked] = useState(false);
    const getExchangerates = async( ) =>{
        await Api.getExchangerates()
    }
    useLayoutEffect(() => {
        getExchangerates()
        setTimeout(() => {
            setSpace(' ')
        }, 300);
    }, [])
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    useSnapshot(taskStore)
    useSnapshot(myactionsstore)
    useSnapshot(AgmodalStore)
    const { changeCurrentMenu } = useGlobalState()
    const rec = contractsStore.current
    let e = enquiryStore.current
    let activeProduct = e.shipmentType.substr(0, 3).toLowerCase()
    console.log(enquiryStore.current)
    //console.log('>>>>>>>>>>>', rec, props.viewonly)
    /*if (!rec._id && props.viewonly) return <></>
    let title = `Create Booking - ${rec._id}`
    if (!props.viewonly) title = 'Enquiries'*/
    let title
    if (props.showbooking) {
        if (!bookingsStore.current.erpJobId) bookingsStore.current.erpJobId = ''
        title = 'Booking Detail - ' + bookingsStore.current._id
        configStore.homescreenCurrentShipmentScope = contractsStore.current.shipmentScope
    } else {
        title = 'Enquiry Detail - ' + enquiryStore.current._id
    }
    let displayCargo4fcl = false
    {
        let e = enquiryStore.current
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        configStore.homescreenCurrentShipmentType = stype
        if (stype === 'fcl' && e.shipmentScope === 'Port-to-Door') {
            displayCargo4fcl = enquiryStore.current.cargoDetails.some(container => container.packageType !=='');
            if (e.destuffingType === 'Dock')
                displayCargo4fcl = true
        }
        else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
            displayCargo4fcl = enquiryStore.current.cargoDetails.some(container => container.packageType !=='');
            if (e.stuffingType === 'Dock')
                displayCargo4fcl = true
        }
        else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Door') {
            displayCargo4fcl = true
            if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory')
                displayCargo4fcl = enquiryStore.current.cargoDetails.some(container => container.packageType !=='');
        }else if(stype === 'fcl'){
            displayCargo4fcl =  enquiryStore.current.cargoDetails.some(container => container.packageType !=='');
        }
        //console.log('#########>>>', stype, displayCargo4fcl)
    }
    useEffect(() => {
        console.log('*** subscribing ***********')
        const unsubscribe = subscribe(myactionsstore, () => {
            let graphList1 = []
            for (let i = 0; i < myactionsstore.list.length; i++) {
                let rec = myactionsstore.list[i]
                if (rec['booking']['containershow'] == undefined) {
                    rec['booking']['containershow'] = -1
                }
                console.log(rec, "++++")
                let oneGraph = []
                oneGraph = Visibility.GraphLine(rec)
                // if (rec.shipmentType !== 'Air') oneGraph = Visibility.oceanGraphLine(rec)
                // else oneGraph = Visibility.airGraphLine(rec)
                let activeOn = false
                let lastactive = -1
                for (let i = oneGraph.length - 1; i >= 0; i--) {
                    let node = oneGraph[i]
                    if (rec.status_closed == 'Y' || rec.status_closed == 'Yes' || rec.booking.status == 'ARCHIVED') {
                        node.isActive = true
                    }
                    if (node.isActive && !activeOn) {
                        node.activelines = 1
                        lastactive = i
                    }
                    if (node.isActive) activeOn = true
                    if (activeOn && !node.isActive) node.isActive = true
                    if (node.isActive && i < lastactive) node.activelines = 2
                }
                graphList1.push(oneGraph)
            }

            setGraphList(graphList1)
        })
        return unsubscribe
    }, [myactionsstore])
    if ((bookingsStore.current.status == 'ARCHIVED' || bookingsStore.current.status == 'INPROGRESS') && graphList.length == 0) {

        console.log(myactionsstore)
        console.log(graphList)
        if (checkgraphList == false) {
            Newapi.getAllDsr4Displaybyid(bookingsStore.current._id)
            setCheckGraphList(true)
        }

    } else {

        console.log(myactionsstore)
        console.log(graphList)
    }

    if (taskStore.triggerActionVisible) return <TaskTrigger />
    return (
        <>
            <ActrejectModal6 type={"cancel"} id={e._id} />
            {space}
            <div className="columns">
                <div className="column is-8">
                    <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                        {title}
                    </h5>
                </div>
                <div className="column is-1">
                    {
                        (props.showbooking && !loginStore.isClient()) ?
                            <button style={{ marginTop: '10px' }} className="button islink"
                                onClick={(e) => {
                                    taskStore.current = new Task('')
                                    taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                                    //console.log('ON BUTTON CLICK CREATED NEW TASK=', taskStore.current)
                                    taskStore.triggerActionVisible = true
                                }}>Action</button>
                            : <></>
                    }
                </div>
                <div className="column is-3">
                    {
                        (!loginStore.isClient() && props.showbooking) ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>
                                    <AgInput label='ERP Shipment #' record={bookingsStore.current}
                                        name='erpJobId' />
                                </span>
                                <span>
                                    <button className="button is-small islink"
                                        style={{ marginTop: '1rem' }}
                                        onClick={async () => {
                                            try {
                                                if (!(/^([A-Z]){3}([/])([A-Z]){2}([/])\d{2}([/])\d{2}([/])([A-Z]){1}([/])\d{4}/.test(bookingsStore.current.erpJobId))) {
                                                    configStore.setModalMessage('ERP Job ID is not valid.')
                                                    return;
                                                }
                                                let isBookingExistsWithSame = await MonApi
                                                    .find('Bookings', { erpJobId: bookingsStore.current.erpJobId }, { _id: 1 })
                                                    .then(res => Array.isArray(res) && res?.length > 0);
                                                if (isBookingExistsWithSame) {
                                                    configStore.setModalMessage('ERP Job ID with booking is already exists.')
                                                    return;
                                                }
                                                let ret = await MonApi.updateOne('Bookings',
                                                    { _id: bookingsStore.current._id },
                                                    { erpJobId: bookingsStore.current.erpJobId })
                                                if (ret.error) configStore.setModalMessage('' + ret.error)
                                                else configStore.setModalMessage('ERP Job ID updated successfully.')
                                            } catch (e) {
                                                configStore.setModalMessage('Error trying to save ERP ID')
                                            }
                                        }}>
                                        Save
                                    </button>
                                </span>
                            </div>
                            : <></>
                    }
                </div>
            </div>
            <div className="columns">
                <div className="column is-4">
                    <HomescreenRoute {...props} pureview='1' />
                </div>
                <div className="column is-8">
                    {
                        (activeProduct === 'fcl') ?
                            <HomescreenContainer {...props} pureview='1' /> :
                            <HomescreenCargo {...props} pureview='1' />
                    }
                </div>
            </div>
            {
                (displayCargo4fcl) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <HomescreenCargo pureview='1' {...props} displayCargo4fcl={true} />
                        </div>
                    </div> : <></>
            }
            {
                (!loginStore.isClient() && bookingsStore.current.createdFromPricing && AgmodalStore.modalPage !== "ViewEnquiry") ?
                    <BookingSchedule booking={bookingsStore.current} /> :
                    <></>
            }

            {
                (props.showbooking) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                <CarrierWebView showbooking={props.showbooking} />
                            }
                        </div>
                    </div>
                    : <></>
            }
            {
                (!props.showbooking && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENQUIRYTOQUOTATION)) ?
                    (e.status === 'CANCELLED') ? <div style={{ textAlign: 'center' }}>
                        <strong>Reason:</strong> {e.reason}
                    </div>
                        : <div className="columns">
                            <div className="column is-9">
                                <button style={{ float: 'right' }} className="button is-danger is-small is-rounded" onClick={() => {
                                    configStore.quotationModalVisible4 = true;
                                    AgmodalStore.apiCall = false;
                                }}>
                                    Cancel Enquiry</button>
                            </div>
                            <div className="column is-3">
                                <button style={{ float: 'right' }} disabled={isButtonClicked} onClick={() => {
                                    setButtonClicked(true);
                                    configStore.setModalMessage('Generating quotation from enquiry')
                                    EnquiriesApi.updateToQuote(e._id, () => {
                                        Api.getQuote(e._id)
                                        AgmodalStore.pageVisible = false;
                                        changeCurrentMenu('Quotationv2')
                                        AgmodalStore.modalPage = 'Quotev2';
                                        AgmodalStore.pageVisible = true;
                                    })

                                }}
                                    className="button is-small is-rounded islink">
                                    Generate Quotation from this Enquiry
                                </button>
                            </div>
                        </div>
                    : <></>
            }

            {
                ((props.showbooking && (bookingsStore.current.status == 'ARCHIVED' || bookingsStore.current.status == 'INPROGRESS') && graphList.length != 0)) ? <>
                    <hr />
                    <div>
                        <div className="box is-fullwidth">
                            {
                                graphList.map((e, i) => {
                                    let e1 = myactionsstore.list[i]
                                    return <VisibilityGraph graphNodes={e} dsrRec={e1} hideedit={true} />
                                })
                            }
                        </div>
                    </div>
                    {/* <h1 style={{textAlign:"center"}}><strong>DSR</strong></h1>
                    <ShipmentGraph graphNodes={graphList[0]} /> */}
                </> : <></>
            }
        </>
    )
}