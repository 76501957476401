import { useSnapshot } from "valtio"

import {
  ApprovalTabStore,
  aguserStore,
  configStore,
  loginStore,
  routerStore,
  vehicleStore,
} from "../state/CommonState"
import { useEffect, useRef, useState } from "react"
import { BsFillEyeFill } from "react-icons/bs"
import { CiFileOff } from "react-icons/ci";
//import { Col12, Col1,Col2, Col3, Col4, Col5, Col6, Columns } from "../Controls";
import {
  Col12,
  Col1,
  Col2,
  Col3,
  Col4,
  Col8,
  Col7,
  Col5,
  Cols,
  Col6,
  Columns,
  Col10,
  Col11,
} from "../Controls"

import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils"
import Approval_Apis from "../state/approval-api"

import MainVehicle from "../vehicle/main-vehicle"
import VehicleApi from "../state/vehicle_api"


export default function EditUserApproval(props) {
    const User = aguserStore.aguserRec
    const APP = ApprovalTabStore.ApprovalRec
  
    const [FormUserStatus, setFormUserStatus] = useState("")
    const [Remarks, setRemarks] = useState("")
    const [errors, setErrors] = useState({})
    const [pendingErrors, setPendingErrors] = useState({})
    const [approveCheckbox, setApproveCheckbox] = useState(false)
    const [approvalStatus, setApprovalStatus] = useState(null)
    const [message, setMessage] = useState("")
    const [downloadFormData, setDownloadFormData] = useState({})
    const [cursor, setCursor] = useState(0)
    const [subbtnstatus, setsubbtnstatus] = useState(false)
    const [RDIR, setRDIR] = useState(false)
    const [ROLE, setROLE] = useState("")
    const ref = useRef(null)
    const hiddenFileInput1 = useRef()
    let title = ""
    switch (props.mode) {
      case "edit":
        title = `Modify (${User?._id}) ${User?.Status}`
        break
      case "view":
        title = `View (${User?._id}) ${User?.Status}`
        break
      case "new":
        title = "Create User"
        break
      default:
        title = "User"
        break
    }
    // useSnapshot(EntityV2Store)
    useSnapshot(ApprovalTabStore)
    useSnapshot(loader)
    useEffect(() => {
      const input = ref.current
    }, [ref, cursor])
  console.log("hhhhhhhhhhhhhhh");
    useEffect(() => {
      setROLE(ApprovalTabStore.ApprovalRec.userRole)
      setFormUserStatus(ApprovalTabStore.ApprovalRec.action)
    }, [])
    const handleVerifyRadioChange = (event) => {
      // User.remarks = ""
      setRemarks("")
      setApprovalStatus(event.target.value)
      setApproveCheckbox(true)
      setMessage("")
    }
  
    const handleMessageChange = (e) => {
      // User.remarks = e.target.value
      setRemarks(e.target.value)
    }
  
  
    const timelinepushfn = (r) => {
      let timeline = {
        date: Date.now(),
        event: r,
        user: loginStore.email,
        role: RoleUtils.getUserRole()
      }
      User.timeline.push(timeline)
    }
    useEffect(() => {
      setRemarks(User?.Remarks)
      if(!User.timeline || User.timeline === null){
        User.timeline = []
      }
    }, [])
    
    const handleSubmit = async (e) => {
      // e.preventDefault()
      setsubbtnstatus(true)
      console.log(ROLE);
      // if (User.entityType === "Customer") {
        if (approvalStatus === "approve" ) {
          const chkBox = document.getElementById("approveChkbox")?.checked
          if (chkBox) {
            await timelinepushfn("Verified")
            let dataUpdate = {
              formdata: User,
              userTypeObj:{
              role: ROLE,
              status:"approve",
              updatedBy: loginStore.email,
              },
            }
            await Approval_Apis.driUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
              setsubbtnstatus(false)
            })
          }
          else {
            console.log("pls");
            setsubbtnstatus(false)
          } 
  
        } else if (approvalStatus === "reject") {
          if (Remarks.length < 1) {
            setsubbtnstatus(false)
            return configStore.setModalMessage("Please enter remarks , it should contain atleast 3 characters")
          }
          if (Remarks.trim().length < 3) {
            setsubbtnstatus(false)
            return configStore.setModalMessage("Remarks should contain atleast 3 characters")
          }
          await timelinepushfn("Rejected")
          let dataUpdate = {
            formdata: User,
            userTypeObj:{
              role: ROLE,
              status:"reject",
              rejectedrole: User.role,
              updatedBy: loginStore.email,
            },
            Remarks
          }
          await Approval_Apis.driUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
            setsubbtnstatus(false)
          })
        } 
        else{
          setsubbtnstatus(false)
        }
  
  
       
      
  
      
      // setsubbtnstatus(false)
    // }
  
    } 

    return (
      <>
      
      { <>
        <div class="main-content" style={{ zoom: "0.9" }}>
          <div className="header-wrapper">
            <div className="left-header">
              <div className="page-heading" >
  
                <span className="heading">
                 {"New User Onboarding"}{' '} 
                </span>
  
              </div>
            </div>
          </div>
          <section
            class="section section-padding"
            style={{ paddingTop: "0px" }}
          >
            <Cols>
              <Col7 style={{ zoom: "0.9",height:"70vh",overflowY:"scroll",display:"flex",flexWrap:"wrap" }}>
                  <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                  <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>First Name</label>
                  <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{User.firstName || "NA"}</Col12>
                  </div>
                  <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                  <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Last Name</label>
                  <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{User.lastName || "NA"}</Col12>
                  </div>
                  <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                  <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Email</label>
                  <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{User.email || "NA"}</Col12>
                  </div>
                  <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                  <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Phone Number</label>
                  <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{User.phone || "NA"}</Col12>
                  </div>  
                
              </Col7>
            </Cols>
          </section>
  
  
  
  
          <hr />
        </div>
        </>
  } </>
    )
  
    function clearErrorMessages(r) {
      pendingErrors[r] = ""
      errors[r] = ""
      setPendingErrors(pendingErrors)
    }
  
  }
  

