import { Autocomplete, Box, FormControl, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { CustomStyledTextField } from "./ColoredTextfield";
import { Controller } from "react-hook-form";
import { CustomStyledSelectField } from "./CustomStyledSelectField";

export default function LabeledSelectField({label,width,multiline,disabled,onKeyUp,min,maxRows,alignItems,height,onChange,control,rules, callback,fontSize,labelStyle,name,endAdornment , textfieldStyles,options , optionValue ,...rest}) {
    return (
    <FormControl sx={{  width:width || "100%",height:"100%",gap:0 }}>
      <Controller
       control={control}
       name={name}
       rules={rules}    
        render={({field,fieldState}) =>  <Grid container rowSpacing={label?"4px":0}>
        <Grid item xs={12}>
        <Typography style={{textAlign:"start",fontFamily:"Figtree",...labelStyle}}>{label}</Typography>
        </Grid>
        {
            <TextField  select  sx={{...textfieldStyles , margin : 0}}  m={0} disabled={disabled}
        fullWidth size="small" color="primary" error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant="outlined" {...rest}  margin="normal"   
         onChange={(e)=>{
          if(callback){
            callback(e);
          }
          field.onChange(e);
        }}>
        {label.toLowerCase().includes('state') && (
          options?.map((r,i)=> {
              return   ( <MenuItem value={r.name + "/" + r.isoCode} key={i}>{r.name}</MenuItem>)
            })
        )
        }
        {/* {label.toLowerCase() === 'country*' && (
          options?.map((r,i)=> {
              return   ( <MenuItem value={r} key={i}>{r.name}</MenuItem>)
            })
        )
        } */}
        {label.toLowerCase() === 'entity category*'   && (
            options?.map((r,i)=> {
              return   ( <MenuItem value={r} key={i}>{r}</MenuItem>)
            })
        )
        }
        {label.toLowerCase() !== 'state' && label.toLowerCase() !== 'entity category*'  && (
            options?.map((r,i)=> {
              return   ( <MenuItem value={r.name} key={i}>{r.name}</MenuItem>)
            })
        )
        }
        </TextField>
        }
       </Grid>
     }
      />
        </FormControl>
     
    );
  }
  