

export default class MyactionUtils {
    static twodeffdate = (date1, date2) =>{
        let arr = date2.split('-')
        date2 = `${arr[2]}-${arr[1]}-${arr[0]}`
        let d2 = new Date(date2)
        d2.setHours(0,0,0,0)
        d2  = new Date(d2)
        const diffTime = Math.abs(d2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if(diffDays){
            if(date1.getTime()>new Date(date2).getTime()){
                return "-"+diffDays
            }
        }
        return diffDays
    }
    static getdsrdatediff(date,type){
        let d = new Date()
        d = d.setHours(0,0,0,0);
        d = new Date(d)
        let n = this.twodeffdate(d,date)
        let x = ""
        switch (type) {
            case 'etd_airport_of_departure':
                x = "Next ETD Airport of Departure in " + n +  " Due Days "
                break;
            case 'ts1_arrival_estimate':
                x = "Next ETA Transhipment 1 in " + n +  " Due Days "
                break;
            case 'ts1_departure_estimate':
                x = "Next ETD Transhipment 1 in " + n +  " Due Days "
                break;
            case 'ts2_departure_estimate':
                x = "Next ETD Transhipment 2 in " + n +  " Due Days "
                break;
            case 'ts2_arrival_estimate':
                x = "Next ETA Transhipment 2 in " + n +  " Due Days "
                break;
            case 'ts3_arrival_estimate':
                x = "Next ETA Transhipment 3 in " + n +  " Due Days "
                break;
            case 'ts3_departure_estimate':
                x = "Next ETD Transhipment 3 in " + n +  " Due Days "
                break;
            case 'ts4_arrival_estimate':
                x = "Next ETA Transhipment 4 in " + n +  " Due Days "
                break;
            case 'ts4_departure_estimate':
                x = "Next ETD Transhipment 4 in " + n +  " Due Days "
                break;
            case 'reworking_pod_arrival_estimate':
                x = "Next ETA Re-working 2 in " + n +  " Due Days "
                break;
            case 'reworking_pod_departure_estimate':
                x = "Next ETD Re-working 2 in " + n +  " Due Days "
                break;
            case 'reworking_pol_departure_estimate':
                x = "Next ETD Re-working 1 in " + n +  " Due Days "
                break;
            case 'reworking_pol_arrival_estimate':
                x = "Next ETA Re-working 1 in" + n +  " Due Days "
                break;
            case 'eta_at_arrival_airport':
                x = "Next ETA @ Arrival Airport in " + n +  " Due Days "
                break;
            case 'estimated_delivery_date_to_consignee':
                x = "Next Estimated Delivery Date to Consignee in " + n +  " Due Days "
                break;
            case 'etd_at_pol':
                x = "Next ETD @ POL in " + n +  " Due Days "
                break;
            case 'eta_at_pod':
                x = "Next ETA at POD in " + n +  " Due Days "
                break;
            case 'eta_fpod':
                x = "Next ETA @ FPOD in " + n +  " Due Days "
                break;
            case 'etd_pod':
                x = "Next ETD from POD in " + n +  " Due Days "
                break;
        }
        return x
    }
    static getmindate(a1){
        let r = [];
        for (let i = 0; i < a1.length; i++) {
            r.push(Number(`${a1[i].split("-")[2]}${a1[i].split("-")[1]}${a1[i].split("-")[0]}`))
        }
        r = r.sort((a, b) => {
            return a - b;
        })
        r = r[r.length-1].toString()
        return `${r.slice(6, 8)}-${r.slice(4, 6)}-${r.slice(0, 4)}`
    }

}