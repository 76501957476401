import React from 'react';
import textfile from "./BulmaCSS.txt";

function AgStyles() {
  const [text, setText] = React.useState();
  fetch(textfile)
    .then((response) => response.text())
    .then((textContent) => {
      setText(textContent);
    });
  return text || "Loading...";
}
export default AgStyles