import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query"
import AddIcon from '@mui/icons-material/Add';
import Header from "../components/Header"
import SecondaryHeader from "../components/SecondaryHeader"
import { EntityV2Store, ShipperConsigneeStore, routerStore } from "../../state/CommonState";
import TableListView from "../components/TableListView";
import EntityV2Api from "../../state/entities-api";
import { ShipperConsignee } from "../../state/Types";
import { debounce } from "lodash";

const queryClient = new QueryClient()

const DataTable = (fn) => {
 const Entity =  EntityV2Store.EntityRec
const [shiiperData, setshipperData] = useState([])
const [count, setCount] = useState(0)
const [filter, setFilter] = useState({
  entityId:Entity.entityId,
  keyword:""
})
  const handleBackClick =() => {
    fn.fn.fn.fn(false);
  }

  const handleAddButton = () => {
    // addUser()
    ShipperConsigneeStore.ShipperConsigneeRec.set(new ShipperConsignee())
    routerStore.shipperManageView = "new"
  };
  useEffect(() => {
    getData()
  }, [])
  
const getData = async()=>{
  try {
    await EntityV2Api.GetShippers(filter,(response) => {
      console.log("response",response);
      if (response?.data.length > 0) {
        setCount(response.count)
        setshipperData(response?.data)
      }else{
        setCount(0)
        setshipperData([])
      }
    });
  } catch (error) {
    console.log(error);
  }
}
useEffect(() => {
  getData()
}, [filter])

  return (
    <>
      {
        <div id= "usersPg">
          <Header onClick={handleBackClick} Arrow={true} heading="My Shipper/Consignee"> </Header>
          <SecondaryHeader
            addButton={handleAddButton}
            data1={{ count }}
            icon={<AddIcon/>}
            defaultKeyword={""}
            onClickFilterCallback={debounce((e) => {
              setFilter(prevState => ({
                ...prevState,
                keyword:e
              }));
            }, 500)} 
            />
            <TableListView ShippersData={shiiperData} count={count}></TableListView>
       </div>
      }
     </>
  )
}

const ListShipperConsignee = (fn) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable fn={fn}/>
    </QueryClientProvider>
  )
}

export default ListShipperConsignee
