import { useSnapshot } from "valtio"
import { routerStore } from "../../state/CommonState"
import ListShipperConsignee from "./list-shipper-consignee"
import EditShipperConsignee from "./edit-shipper-consignee"

export default function MainShippers(fn, mode) {
  const store = useSnapshot(routerStore)
  switch (store.shipperManageView) {
    case "list":
      return <ListShipperConsignee  fn={fn} />
    case "edit":
      return <EditShipperConsignee mode="edit" fn={fn} />
    case "new":
      return <EditShipperConsignee mode="new" fn={fn} />
    default:
      return <EditShipperConsignee mode="view" fn={fn} />
  }
}
