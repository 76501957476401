import { useEffect, useState } from "react"
import AguserApi from "../state/AguserApi"

export default function Devices(props) {
    const [list, setList] = useState([])
    const set = (l) =>{
        if(JSON.stringify(l)!=JSON.stringify(list)){
            setList(l)
        }
    }
    const getlist = ()=>{
        AguserApi.devicelist(set)
    }
    useEffect(()=>{
        getlist()
    },[])

    return (
        <>
        <div class="box">
        <h1 class="title">Device List</h1>
        <div class="columns is-multiline is-vcentered">
          {
            list.map(e=>{
              return <>{e.login !="false"?<><div class="column is-6">
              {e.device}
            </div>
            <div class="column is-6 has-text-right">
            <button class="button is-danger" onClick={()=>{
              AguserApi.logout({token:e.token,email:e.email},getlist)
            }}>Remove</button>
            </div></>:""}</>
            })
          }
</div>
</div>
        </>)
}