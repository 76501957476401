import { proxy, subscribe } from "valtio"

let visibilityStore = proxy({
    list: [],
    recheck: 0,
    error: [],
    setList: (dsrList) => {
        visibilityStore.list = [...dsrList]
    }


})

export { visibilityStore }