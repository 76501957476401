import { useSnapshot } from "valtio"
import { configStore, loginStore } from "../state/CommonState"
import { enquiryStore } from "../enquiry/enquiry-data"

export default function EnquiryConfirmation(props) {
    useSnapshot(configStore)
    useSnapshot(enquiryStore)
    let modalClass = 'modal'
    if (configStore.enquiryModalVisible) modalClass += ' is-active'
    // console.log(enquiryStore.current)
    
    return (
        <div className={modalClass}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <section className="modal-card-head" style={{textAlign: 'left', fontSize: '1.4rem', fontWeight: 'bold'}}>
                    <span style={{width:'90%'}}>Enquiry Confirmation</span>
                    <button className="delete" style={{ float: 'right' }}
                        onClick={() => {
                           
                            // configStore.homescreenCurrentView = 'list'
                            
                            enquiryStore.initialize();
                            enquiryStore.currentCargoTableKey = "" + new Date().getMilliseconds();
                            enquiryStore.current.createdBy = loginStore.email;
                            enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
                            enquiryStore.current.shipmentType =
                              configStore.homescreenCurrentShipmentType;
                            configStore.homescreenCurrentView = "new";
                            configStore.scheduleOrCarrierform = "schedule";
                            configStore.enquiryModalVisible = false
                           
                        }}>
                        </button>
                </section>
                
                <section className="modal-card-body" style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                    <div>Thank you for your enquiry. We will work on it and share the quotation on priority. 
                        Please await a notification from our end on your registered Email ID</div>
                    <div>We appreciate your patience.</div>
                    <div>Team Agraga</div>
                   <h2>Enquiry Number:{configStore.enquiryModalNum}</h2>
                </section>
            </div>
        </div>
    )
}