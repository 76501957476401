/*
.schcircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #eea724;
  float: left;
}

.schline {
  margin-top: 5px;
  width: 78%;
  height: 1px;
  background-color: #d5d5d5;
  float: left;
}

*/
export function LCL1(props) {
    let bg1 = '#d5d5d5'
    let bg2 = '#d5d5d5'
    if (props.left) bg1 = 'white'
    if (props.right) bg2 = 'white'
    console.log('INSIDE LCL1', bg1, bg2)
    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{
                    width: '11px',
                    height: '11px',
                    borderRadius: '50%',
                    backgroundColor: '#eea724',
                    float: 'center',
                }}></div>
                <br />
            </div>
            <div style={{
                marginTop: '-12px',
                width: '50%',
                height: '1px',
                backgroundColor: bg1,
                float: 'left'
            }}>
            </div>
            <div style={{
                marginTop: '-12px',
                width: '50%',
                height: '1px',
                backgroundColor: bg2,
                float: 'right'
            }}>
            </div>
        </>
    )
}
export function LCL(props) {
    let dotted = `repeating-linear-gradient(
        to right,
        black,
        black 3px,
        white 3px,
        white 6px
      )`
    //let bg2 = '#acb5fa'
    let normal = `#acb5fa`
    let bg1 = normal
    let bg2 = normal
    if (props.left) bg1 = 'white'
    if (props.right) bg2 = 'white'
    if (props.origin) { bg1 = 'white'; bg2 = dotted}
    if (props.destination) { bg2 = 'white'; bg1 = dotted}
    return (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
            <div style={{
                marginTop: '5px',
                width: '50%',
                height: '1px',
                background: bg1,
                float: 'left',
                zIndex: 0
            }}>
            </div>
            <div style={{
                marginTop: '5px',
                width: '50%',
                height: '1px',
                background: bg2,
                float: 'right',
                zIndex: 0
            }}>
            </div>
            <div style={{
                position: 'absolute',
                top: '1px',
                left: '47%',
                width: '11px',
                height: '11px',
                borderRadius: '50%',
                backgroundColor: '#eea724',
                float: 'center',
                zIndex: 9
            }}></div>
        </div>
    )
}
export function LCLnew(props) {
    let bg1 = '#eea724'
    let bg2 = '#eea724'
    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{
                    width: '11px',
                    height: '11px',
                    borderRadius: '50%',
                    backgroundColor: '#eea724',
                    float: 'center',
                }}></div>
                <br />
            </div>
            <div style={{
                marginTop: '-12px',
                width: '100%',
                height: '1px',
                backgroundColor: '#d5d5d5',
                float: 'left'
            }}>
            </div>
        </>
    )
}
export function DLCL(props) {
    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{
                    width: '11px',
                    height: '11px',
                    borderRadius: '50%',
                    backgroundColor: '#cccccc',
                    float: 'center',
                }}></div>
                <br />
            </div>
            <div style={{
                marginTop: '-12px',
                border:'none',
                borderTop:'1px dashed #151515',
                width: '50%',
                height: '1px',
                backgroundColor: 'white',
                float: 'left'
            }}>
            </div>
        </>
    )
}
export function OLCL(props) {
    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{
                    width: '11px',
                    height: '11px',
                    borderRadius: '50%',
                    backgroundColor: '#cccccc',
                    float: 'center',
                }}></div>
                <br />
            </div>
            <div style={{
                marginTop: '-12px',
                border:'none',
                borderTop:'1px dashed #151515',
                width: '50%',
                height: '1px',
                backgroundColor: 'white',
                float: 'right'
            }}>
            </div>
        </>
    )
}
export function Line(props) {
    return (
            <div style={{
                marginTop: '5px',
                width: '100%',
                height: '1px',
                background: '#acb5fa',
                float: 'left',
                zIndex: 0
            }}>
            </div>
    )
    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{
                    width: '0px',
                    height: '0px',
                    borderRadius: '50%',
                    backgroundColor: '#ffffff',
                    float: 'center',
                }}></div>
                <br />
            </div>
            <div style={{
                padding: '0px',
                marginTop: '-12px',
                width: '100%',
                height: '1px',
                backgroundColor: '#d5d5d5',
                float: 'left'
            }}>
            </div>
        </>
    )
}
export function Line1(props) {
    return (
        <div>
            <span style={{
                marginTop: '5px',
                width: '50%',
                height: '1px',
                backgroundColor: 'red',
                float: 'left'
            }}>
            </span>
            <span style={{
                marginTop: '5px',
                width: '50%',
                height: '1px',
                backgroundColor: 'blue',
                float: 'left'
            }}>
            </span>
        </div>
    )
}
/*
export function Circle(props) {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{
                width: '11px',
                height: '11px',
                borderRadius: '50%',
                backgroundColor: '#eea724',
                float: 'center',
            }}></div>
        </div>
    )
}
export function Line(props) {
    return (
        <div style={{
            marginTop: '6px',
            width: '100%',
            height: '1px',
            backgroundColor: '#d5d5d5',
            float: 'left'
        }}>
        </div>
    )
}


*/

export function CustomProgressDottedBar({ setStyle }) {
    let dotted = `repeating-linear-gradient(
        to right,
        black,
        black 3px,
        white 3px,
        white 6px
      )`
    let normal = `#acb5fa`
    let hide = 'white'

    let [bg1, bg2] = setStyle(dotted,hide);
    return (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
            <div style={{
                marginTop: '5px',
                width: '50%',
                height: '1px',
                background: bg1 || normal,
                float: 'left',
                zIndex: 0
            }}>
            </div>
            <div style={{
                marginTop: '5px',
                width: '50%',
                height: '1px',
                background: bg2 || normal,
                float: 'right',
                zIndex: 0
            }}>
            </div>
            <div style={{
                position: 'absolute',
                top: '1px',
                left: '47%',
                width: '11px',
                height: '11px',
                borderRadius: '50%',
                backgroundColor: '#eea724',
                float: 'center',
                zIndex: 9
            }}></div>
        </div>
    )
}