import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

import {
    Trysignup
} from "../components/ag-table/columns"

import ReactTablePagination from '../components/ag-table/ReactTablePagination'

import { Sorting } from "../components/ag-table/ag-sorting"
import {
    reducer, PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    PAGE_SORT_CHANGED,
    TOTAL_COUNT_CHANGED
} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"



const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};



const DataTable = (props) => {
    const [data1, setData1] = useState({ results: [] })
    let columns = Trysignup
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);
useEffect(()=>{
    ApiAgTable.argno(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res=>{
        res.clone().json().then((res) => {
            if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
               if(JSON.stringify(data1)!=JSON.stringify(res)){
                setData1(res)
               }
            }
        }, err => {
            console.log(err)
        })
    })
},[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy])



    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results || [],
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );
    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);


    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    

   
        return (
            <>
                <h1 className="title">Non Login Agraga Users</h1>
                {
                    (data1?.count)?<nav className="level">
                    <div className="level-left">
                    <div className="level-item">
                            <p className="subtitle is-5">
                                {data1?.count } Result
                            </p>
                        </div>
                    </div>
                </nav>:""
                }
            

                {
                     <>
                        {
                            (typeof data1?.count === 'undefined' || data1?.count == 0) && <p>No results found</p>
                        }
                        {(data1?.count > 0) &&
                            <>
                             <div className="table-container">
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <thead>
                    {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        <th>#</th>
                                                        {headerGroup.headers.map(column => (
                                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                                {column.render('Header')}
                                                                <Sorting column={column} />
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                                                {page.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            <td>{(pageIndex*pageSize)+i+1}</td>
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        switch (cell['column']['Header']) {
                                                                            case 'Name':
                                                                                return <td {...cell.getCellProps()}><span>{e.firstName?e.firstName + ' ' + e.lastName:''}</span></td>
                                                                            case 'Phone':
                                                                                return <td {...cell.getCellProps()}><span>{e.phonePrefix} {e.phone}</span></td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                </table>
                {(rows.length > 0) && (
                                        <>
                                            <div className="columns" style={{width:"100%"}}>
                                                <div className="column is-6">
                                                    <ReactTablePagination
                                                        page={page}
                                                        gotoPage={gotoPage}
                                                        previousPage={previousPage}
                                                        nextPage={nextPage}
                                                        canPreviousPage={canPreviousPage}
                                                        canNextPage={canNextPage}
                                                        pageOptions={pageOptions}
                                                        pageSize={pageSize}
                                                        pageIndex={pageIndex}
                                                        pageCount={pageCount}
                                                        setPageSize={setPageSize}
                                                        manualPageSize={manualPageSize}
                                                        dataLength={totalCount}
                                                    />
                                                </div>
                                                <div className="column is-6" style={{ textAlign: "right" }}>
                                                    <div className="select">
                                                        <select
                                                            value={pageSize}
                                                            onChange={(e) => {
                                                                setPageSize(Number(e.target.value));
                                                            }}
                                                        >
                                                            {[5, 10, 20, 50, 100].map((pageSize) => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    Show {pageSize}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>


                                        </>
                                    )}
            </div>
                               
                            </>
                        }
                    </>
                }


            </>
        )
}



const ArgNoTable = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default ArgNoTable;