import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";

export default function Awbdraftapproval(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage === 1) {
        return (
            <div className="box">
                <p>Please approve the Airway Bill Draft</p>
                <p></p>
                {
                    (task.remarks) ?
                        <p>Remarks : {task.remarks}</p>
                        : <></>
                }
                <hr />
                <Taskfileapproval />
                <hr />
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected)
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                else {
                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                }
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p>The Airway Bill Draft was rejected. Please see reason below.</p>
                <hr />
                <Taskfiledisplay />
                <hr />
                <p>Upload revised Airway Bill draft</p>
                <Taskfiletable />
                <hr />
                <tr>
                    <td>Remarks:</td>
                    <td style={{ width: '20px' }}></td>
                    <td><textarea cols='50' value={task.remarks}
                        onChange={(e) => task.remarks = e.target.value} /></td>
                </tr>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage >= 3) {
        return (
            <>
                <p>The Airway Bill Draft was approved.</p>
                <hr />
                <Taskfiledisplay />
                <p>
                    <span>
                        {
                            (stage === 3) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 4
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </>
        )
    }
}
export function AwbdraftapprovalTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    let task = taskStore.current
    const [disableButton, setDisableButton] = useState(true)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if (taskStore.current.dueDate.length == 0) {
                disable = true
            }
            if(!disable){
                let checkfile = []
                taskStore.current.files.map((e) =>{
                    if(checkfile.indexOf(e.label) == -1){
                        if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                            disable = true
                        }else{
                            if(taskStore.current.original_file.indexOf(e.label) != -1){
                                checkfile.push(e.label)
                            }
                        }
                    }
                })
            }
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr />
            <Taskfiletable />
            {/* <table className="table is-fullwidth is-striped is-narrow" style={{ fontSize: '0.85rem' }}>
                <tr>
                    <td>Due Date</td>
                    <td><input type='date' value={task.validTill} onChange={(e) => task.validTill = e.target.value} /></td>
                    <td>Due Time</td>
                    <td>
                        <select style={{ width: '3rem' }} onChange={(e) => {
                            task.dueTime = e.target.value
                        }}>
                            {
                                ['', '0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00',
                                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
                                    '20:00', '21:00', '22:00', '23:00'].map(e => <option value={e}>{e}</option>)
                            }
                        </select> hrs
                    </td>
                </tr>
            </table> */}
            <p>
                <button className="button islink" disabled={disableButton}
                    onClick={async (e) => {
                        taskStore.current.stage = 1
                        taskStore.current.pendingWith = 'CUSTOMER'
                        await Taskapi.saveTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false
                    }}>Trigger Action</button>
            </p>
        </>
    )
}