import { useSnapshot } from "valtio";
import { PricingDetailsCard2 } from "../mui-components/PricingDetailsCard2";
import { configStore, viewBookingStore } from "../state/CommonState";
import { Box } from "@mui/material";
import Utils from "../util/Utils";
import { Accordions } from "../mui-components/AgMuiAccordion";
import { useEffect, useState } from "react";
import { PricingContextProvider, usePricing } from "../context/PricingContext";
import { Util } from "reactstrap";



function convertRate(e){
    let rate = 0;
    let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        rate = e.total * erate
        return rate;
}


function getChargesList(chargesList,mode){
    let chargeList = [];
    chargesList.forEach(element => {
        let index;
       
        if((+element.perKg) !== 0 || (+element.minimum) !== 0 || (+element.perAWB) !== 0 || (+element.total) !== 0){
            let convertedRate = convertRate(element);
             if(element.onaccount==="On Your Account"){
                 if(chargeList[0]){
                    chargeList[0].charges.push(element);
                 }else{
                    chargeList[0] = {account:"On Your Account",charges:[element],total:0.0}
                 }
                 index = 0;
             }else if(element.onaccount==="Consignee's Account"){
                if(chargeList[1]){
                    chargeList[1].charges.push(element);
                 }else{
                    chargeList[1] = {account:"On Consignee Account",charges:[element],total:0.0}
                 }
                 index = 1;
             }else if(element.onaccount.startsWith('Ship')){
                if(chargeList[2]){
                    chargeList[2].charges.push(element);
                 }else{
                    chargeList[2] = {account:"On Shipper Account",charges:[element],total:0.0}
                 }
                 index = 2;
             }
             if(element?.isIncluded==="Yes" || mode==="CONTRACT"|| mode==="QUOTE"){
                chargeList[index].total += convertedRate;
                }
        }
    });
    return chargeList;
}


function AccordionViewPricing({filteredChargesList}){
    const [selectedindex,setIndex] = useState(0);
    const accordions = [];
    filteredChargesList?.forEach((charge,i)=>{
        accordions.push({id: i,
        title: charge.account,
        price : Utils.N(charge.total),
        content: <PricingDetailsCard2 isAccordionView={true} charge={charge}/>})
    })
    return  filteredChargesList.length === 1 ? <Box sx={{display:"flex",height:"100%",width:"100%"}}>
       <Box sx={{width:"100%",height:"100%", backgroundColor:"white",borderRadius: "10px", paddingY: "8px", paddingX: "12px"}}> <PricingDetailsCard2 isAccordionView={true} title={filteredChargesList[0].account} charge={filteredChargesList[0]}/></Box>
    </Box> : <Accordions accordions={accordions} selectedIndex={selectedindex} setIndex={setIndex} />
}


function NonAccordionView({filteredChargesList}){
    return <Box sx={{display:"flex",flexDirection:"row",width:"100%",gap:1,justifyContent:"center"}}>
    {filteredChargesList.map((charge)=><Box sx={{minWidth:"30%",paddingY: "8px"}}><PricingDetailsCard2 title={charge.account} charge={charge}/></Box>)}
  </Box>
}


function AIRPricingContextWrapped({contract,isAccordionView,needToDisableInclusion}){
    // const {booking} = useSnapshot(viewBookingStore).current;
    const {setRec,setDisable,setTotal} = usePricing();
    useEffect(()=>{
        setRec(contract);
        setDisable(needToDisableInclusion);
    },[contract])
    const filteredChargesList = getChargesList(Utils.getChargeListFormatFromBooking(contract),contract.mode);
    if(contract.mode==="CONTRACT"|| contract.mode==="QUOTE"){
        setTotal(filteredChargesList[0]?.total)
    }
    return isAccordionView ? <AccordionViewPricing filteredChargesList={filteredChargesList} /> : <NonAccordionView filteredChargesList={filteredChargesList}/>
}


export function AIRBookingPricingDetail({contract,isAccordionView = false,disableInclusion=false}){
    return  <AIRPricingContextWrapped contract={contract} isAccordionView={isAccordionView} needToDisableInclusion={disableInclusion}/>
}