import React, { useState } from "react";
import { ref, useSnapshot } from "valtio";
import { Col, Columns } from "../Controls";
import Api from "../state/Api";
import {
  configStore,
  entityStore,
  EntityV2Store,
  quotatationImportStore,
} from "../state/CommonState";

export default function AgQuotationImportForm() {
  useSnapshot(quotatationImportStore);
  useSnapshot(entityStore);
  useSnapshot(EntityV2Store);
  const [space, setSpace] = useState("");
  return (
    <>
      <div class="card " style={{ margin: "15px" }}>
        <div class="card-content">
          <div class="content">
            <Columns>
              <Col>
                <div className="field">
                  <label
                    className="label is-small"
                    style={{ marginBottom: "0px" }}
                  >
                    <span style={{ color: "red" }}>*</span> Shipment Type
                  </label>
                  <div className="control">
                    <div className="select is-small">
                      <select
                        value={quotatationImportStore.selectedShipmentType}
                        onChange={(e) => {
                          quotatationImportStore.selectedShipmentType =
                            e.target.value;
                        }}
                      >
                        <option selected value={""} hidden>
                          {""}
                        </option>
                        {["LCL", "FCL", "Air"].map((type) => (
                          <option>{type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="field">
                  <label
                    className="label is-small"
                    style={{ marginBottom: "0px" }}
                  >
                    <span style={{ color: "red" }}>*</span> Customer
                  </label>
                  <div className="control">
                    <div className="select is-small">
                      <select
                        value={quotatationImportStore.entity}
                        onChange={(e) => {
                          quotatationImportStore.entity = e.target.value;
                        }}
                      >
                        <option selected value={""} hidden>
                          {""}
                        </option>
                        {EntityV2Store.Cuslist.map((entity) => (
                          <option value={entity.entityId}>
                            {entity.entityName} {entity.state}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="field">
                  <label
                    className="label is-small"
                    style={{ marginBottom: "0px" }}
                  >
                    <span style={{ color: "red" }}>*</span> Quotations sheet
                  </label>
                  <div className="control">
                    <div class="file is-small">
                      <label class="file-label">
                        <input
                          class="file-input"
                          type="file"
                          name="quotationSheetFile"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={(e) => {
                            quotatationImportStore.quotationSheetFile =
                              Array.from(e.target.files).map(ref).at(0);
                            setSpace(space === "" ? " " : "");
                          }}
                        />
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">
                            {quotatationImportStore.quotationSheetFile == null
                              ? "Upload here"
                              : quotatationImportStore.quotationSheetFile.name}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="field">
                  <div className="control">
                    <button
                      class="button is-link"
                      onClick={() => {
                        if (
                          quotatationImportStore.quotationSheetFile != null &&
                          quotatationImportStore.entity &&
                          quotatationImportStore.selectedShipmentType
                        ) {
                          Api.importQuotationsFromSheet();
                        } else {
                          configStore.setModalMessage("Please check fields");
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Col>
            </Columns>
          </div>
        </div>
      </div>
    </>
  );
}
