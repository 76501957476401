import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import { AgragaDtlist } from "../components/ag-table/columns";

import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";

import { Sorting } from "../components/ag-table/ag-sorting";

import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import {  AgmodalStore, configStore, customerStore, EntityV2Store } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { loader } from "../util/loader";
import DtApi from "../state/dt-api";
import Utils from "../util/Utils";
import TableHead from "../util/dataTable";
import { Buffer } from 'buffer';
import EntityApi from "../state/entity-api";
const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const DataTable = (props) => {
    useSnapshot(DtStore)
    useSnapshot(customerStore)
    useSnapshot(configStore)
    useSnapshot(loader)
    useSnapshot(EntityV2Store)
    const [customerlist, setCustomerlist] = useState([])
    let modalClass = 'modal'
    if (DtStore.modalVisible) modalClass += ' is-active'

    const filterStatus = {
        'DRAFT': 'DRAFT',
        'ACTIVE': 'ACTIVE',
        'EXPIRED': 'EXPIRED'
    }

    const [selectedFilter, SetSelectedFilter] = useState(`ALL`);
    const [selectedFilter1, SetSelectedFilter1] = useState(`ALL`);

    const [ch, setCh] = useState(0);
    const [deldata, setDeldata] = useState({});

    const changech = (data)=>{
        if(ch==0){
            setCh(1)
        }else{
            setCh(0)
        }
    }


    let column = AgragaDtlist

    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);

    useEffect(() => {
        if(queryPayload.status && AgmodalStore.apiCall){
            ApiAgTable.dtquotationData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
                res.clone().json().then((res) => {
                    if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                        if (JSON.stringify(data1) != JSON.stringify(res)) {
                            setData1(res)
                        }
                    }
                }, err => {
                    console.log(err)
                })
            })
        }
    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, AgmodalStore.apiCall])

    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );


    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    useEffect(() => {
        
        dispatch({ type: PAYLOAD_CHANGED, payload: { status: selectedFilter,gst: selectedFilter1,date:Utils.getCurrentDateString()  } });
        gotoPage(0);
    }, [ selectedFilter, selectedFilter1,ch]);

  



    const getlist = async () => {
        if (Object.keys(customerlist).length == 0) {
            let l = {}
            for (let i = 0; i < EntityV2Store.list.length; i++) {
                l[EntityV2Store.list[i]['entityName']] = EntityV2Store.list[i]['entityId']
            }
            setCustomerlist(l)
        }
    }
    const checkmultipickup = (d) =>{
        let l = false
        if(d.details.length>1){
            l = true
        }
        if(!l){
            for (let i = 0; i < d.details.length; i++) {
                if(d.details[i]['pickup_2'] != undefined && d.details[i]['pickup_2'].trim().length>0){
                    l = true
                }
            }
        }
        return l
    }
    useEffect(() => {
        getlist()
    }, [])

    const getdelivery = (d)=>{
        let a = []
        d.map((v)=>{
            let l = v.address.trim()
            if(l.length>0){
                a.push(l)
            }
        })
        if(a.length>1){
            return "Multiple"
        }else{
            return a[0]
        }
    }

    function checkEntityStatus(status){
        let isStatusAvail = false; 
        if(status === "VERIFIED") {
          isStatusAvail= true
          return isStatusAvail
        }  
        return isStatusAvail  
       }

    return (
        <>
            


            
            <h1 className="title">Domestic Transport</h1>

            <nav className="level">
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            {data1?.count} Quotes
                        </p>
                    </div>
                    {
                        RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_QUOTATIONS) &&
                    <p className="level-item">
                        <a className="button is-link"
                            onClick={() => {
                                DtStore.view = "new"
                                DtStore.DT = null
                                DtStore.valid = false
                                AgmodalStore.modalPage = 'DtQuote'
                                AgmodalStore.apiCall = false
                                AgmodalStore.pageVisible = true
                                console.log('New clicked')
                            }}>New</a>
                    </p>
                    }

                    <div class="level-item">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                </div>
                <div class="level-item level-left ml-6">
                    <div className="columns is-multiline is-vcentered">
                        <div className="column is-one-quarter">
                            <div class="field">
                                <p class="control has-icons-left has-icons-right">
                                    <span class="select">
                                        <select name="status" id="status"
                                            value={selectedFilter1} onChange={(e) => SetSelectedFilter1(e.target.value)}
                                        >
                                            <option value="ALL">ALL</option>
                                            {
                                                Object.entries(customerlist).map(([key, value]) => (
                                                    <option value={value}>{key}</option>
                                                ))
                                            }
                                        </select>
                                        <span class="icon">
                                            <i class="fa-solid fa-filter"></i>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="column is-one-quarter">
                            <div class="field">
                                <p class="control has-icons-left has-icons-right">
                                    <span class="select">
                                        <select name="status" id="status"
                                            value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                        >
                                            <option value="ALL">ALL</option>
                                            {
                                                Object.entries(filterStatus).map(([key, value]) => (
                                                    <option value={key}>{value}</option>
                                                ))
                                            }
                                        </select>
                                        <span class="icon">
                                            <i class="fa-solid fa-filter"></i>
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>



                </div>



            </nav>

            <div className="box">
                <div className="table-container" style={{ overflow: 'initial' }}>
                    <table className="table is-fullwidth is-small ">
                        <TableHead headerGroups={headerGroups} sort={true}/>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem' }} {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                    >
                                        {
                                            row.cells.map(cell => {
                                                {
                                                    let e = cell['row']['original']
                                                    switch (cell['column']['Header']) {
                                                        case "Valid Upto":
                                                            return <td>{Utils.ddmmyyyystring(e.DtQuotes.valid_upto)}</td>
                                                        case "Contract Reference #":
                                                            return <td>
                                                                <a
                                                                    target="_blank" onClick={(event) => {
                                                                        DtStore.DT = e.DtQuotes
                                                                        AgmodalStore.modalPage = 'DtQuoteView'
                                                                        AgmodalStore.pageVisible = true
                                                                        event.stopPropagation();
                                                                    }}
                                                                >{e.DtQuotes._id}</a>
                                                            </td>
                                                        case "Pick-up Address":
                                                            return<>
                                                            {
                                                                checkmultipickup(e.DtQuotes)?<td>
                                                                    Multiple
                                                                </td>:<td>
                                                                    {e.DtQuotes.details[0]['pickup']}
                                                                </td>
                                                            }
                                                            </>
                                                        case "Delivery Address":
                                                            return<>{
                                                                (e.DtQuotes.details.length>1)?<td>
                                                                    Multiple
                                                                </td>:<td>{getdelivery(e.DtQuotes.details[0]['delivery'])}</td>
                                                            }
                                                            </>
                                                        case "Actions":
                                                            return <td>
                                                                <div
                                                                    class='dropdown post-options is-right is-hoverable '
                                                                    onClick={null}
                                                                >
                                                                    <div class='dropdown-trigger'>
                                                                        <button
                                                                            class='button'
                                                                            aria-haspopup='true'
                                                                            aria-controls='dropdown-menu-post'
                                                                        >
                                                                            <span class='icon is-small'>
                                                                                <i
                                                                                    class='fas fa-ellipsis-h'
                                                                                    aria-hidden='true'
                                                                                ></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        class='dropdown-menu'
                                                                        id='dropdown-menu-post'
                                                                        role='menu'
                                                                    >
                                                                        <div class='dropdown-content'>
                                                                        {(e.DtQuotes.status === 'DRAFT')?
                                                                        <>
                                                                       { RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_QUOTATIONS) &&
                                                                        (<a
                                                                                    class='dropdown-item'
                                                                                    onClick={() => {
                                                                                        DtStore.DT = e.DtQuotes
                                                                        DtStore.view = 'edit'
                                                                        DtStore.valid = false
                                                                        AgmodalStore.modalPage = 'DtQuote'
                                                                        AgmodalStore.pageVisible = true
                                                                        
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </a>)}
                                                                                    {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CONVERT_QUOTATIONS_TO_CONTRACTS) && (
                                                                                <a
                                                                                    class='dropdown-item'
                                                                                    onClick={ () => {
                                                                                        EntityApi.getEntity(e?.DtQuotes?.entityId,async entityData=>{
                                                                                        let entitystatusResult =  checkEntityStatus(entityData.status)
                                                                                    if(entitystatusResult){
                                                                                        loader.show()
                                                                                        await DtApi.postActiveDt(e.DtQuotes,changech,Utils.formatToDate(Date.now()))
                                                                                        loader.hide()
                                                                                    }else{
                                                                                        configStore.setModalMessage(`Please submit entity details for verification to proceed with the booking`)
                                                                                    }})
                                                                                    }}
                                                                                >
                                                                                    Activate
                                                                                </a>)}
                                                                                <a
                                                                                    class='dropdown-item'
                                                                                    onClick={async () => {
                                                                                        setDeldata(e.DtQuotes)
                                                                                        DtStore.modalVisible = true
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </a>
                                                                        </>:""}
                                                                        
                                                                        <a
                                                                                    class='dropdown-item'
                                                                                    onClick={() => {
                                                                                        DtStore.DT = e.DtQuotes
                                                                                        AgmodalStore.modalPage = 'DtMail';
                                                                                        AgmodalStore.pageVisible = true;
                                                                                    }}
                                                                                >
                                                                                    Send email
                                                                                </a>
                                                                            {
                                                                                RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_QUOTATIONS) &&
                                                                                <a
                                                                                    class='dropdown-item'
                                                                                    onClick={() => {
                                                                                        DtStore.view = "new"
                                        DtStore.DT = DtStore.clone(e.DtQuotes)
                                        DtStore.valid = false
                                        AgmodalStore.modalPage = 'DtQuote'
                                        AgmodalStore.pageVisible = true
                                                                                        
                                                                                    }}
                                                                                >
                                                                                    Clone
                                                                                </a>
                                                                            }
                                                                                <a
                                                                                    class='dropdown-item'
                                                                                    onClick={async() => {
                                                                                        let res = await DtApi.getTransportQuotationDocumentById(e._id)

                                                                                        const buffer = Buffer.from(res);
                                                                                        const blob = new Blob([buffer]);
                                                                                        const fileURL = window.URL.createObjectURL(blob);
                                                                                        console.log(fileURL)
                                                                                        let alink = document.createElement('a');
                                                                                        alink.href = fileURL;
                                                                                        alink.download = `${e._id}.pdf`;
                                                                                        alink.click();
                                                                                    }}
                                                                                >
                                                                                    Download PDF
                                                                                </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        default:
                                                            return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                    }
                                                }

                                            })
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <ReactTablePagination
                        page={page}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        setPageSize={setPageSize}
                        manualPageSize={manualPageSize}
                        dataLength={totalCount}
                        rows={rows}
                    />
                </div>
            </div>
            <div className={modalClass} onClick={() => { DtStore.modalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>Are you sure you want to DELETE {deldata._id}</h2>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                                onClick={async ()=>{
                                                    DtStore.modalVisible = false
                                                    loader.show()
                                                    await DtApi.postDeleteDt(deldata,changech)
                                                    loader.hide()
                                                    
                                                }}
                                            >Yes</button>
                                            <button class="button is-warning" onClick={() => DtStore.modalVisible = false} >No</button>
                </section>
            </div>
        </div>
        </>
    )

}


const DtListQuote = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default DtListQuote;