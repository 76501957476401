import { Box, IconButton, Typography } from "@mui/material";
import eyeIcon from "../assets/images/eyeicon.svg";

import { DocumentViewer } from "../pages/DocumentViewer";
import { useState } from "react";
import pending from "../assets/images/pending.svg";
import approved from "../assets/images/approved.svg";
import rejected from "../assets/images/rejected.svg";
import { useSnapshot } from "valtio";
import { taskStore } from "../mytasks/task-store";
import Utils from "../util/Utils";

function getFileStatusColor(fileStatus){
    if(fileStatus==="approve"){
        return {backgroundColor:"#E9F9DC80",src:approved}
    }else if(fileStatus==="reject"){
        return {backgroundColor:"#F25B5B12",src:rejected}
    }else{
        return {backgroundColor:"#EBB02D33",src:pending}
    }
}

export function FileViewComponent({documentTitle,documentUrl,backgroundColor,onRejection,needsApproval,onApprove,onReject,viewFile=true}){
    const fileName = Utils.getFileExtension(documentTitle);
    const fileViewProperties = Utils.getPropertiesForFileExtension(fileName);
    const [open,setOpen] = useState(false);
    
    return <Box sx={{display:"flex",flexDirection:"row",width:"200px",backgroundColor:backgroundColor,height:"40px",borderRadius:"6px",alignItems:"center",border:backgroundColor?"":"1px solid #D4D4D4",gap:0.5,paddingX:"6px"}}>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"6px",height:"30px",minWidth:"30px",backgroundColor:fileViewProperties?.backgroundColor}}><img src={fileViewProperties?.icon}/></Box>
        <Box sx={{display:"flex",flexDirection:"row",justifyContent:"start"}}>
            <Typography sx={{fontSize:"12px",fontFamily:"Figtree",marginRight:"auto",color:"#555555",width:"95px",overflow:"hidden",textOverflow:"ellipsis"}}>{documentTitle}</Typography>
        <Typography sx={{fontSize:"12px",fontFamily:"Figtree",marginRight:"auto",color:"#555555"}}>{"."+fileName}</Typography>
        </Box>
        <IconButton onClick={()=>{
            // if(documentUrl.includes("xlsx") || documentUrl.includes("xls")){
            //     const link = document.createElement('a'); 
            //     link.href = documentUrl;
            //     link.setAttribute('download', documentTitle);
            //     document.body.appendChild(link);
            //     link.click();
            //     return ;
            //   }
            setOpen(true)
        }} ><img src={eyeIcon} /></IconButton>
      { viewFile &&  <DocumentViewer isExcel={documentUrl.includes("xlsx") || documentUrl.includes("xls")} onApprove={onApprove} onReject={onReject} onRejection={onRejection} needsApproval={needsApproval} documentTitle={documentTitle} documentUrl={documentUrl} open={open} onClose={()=>{
            setOpen(false);
        }}/>}
    </Box>
}
  

export function ApproveOrRejectFile({documentTitle,documentUrl,fileStatus,onRejection,needsApproval,onApprove,onReject}){
    const task = useSnapshot(taskStore).current
    const fileStatusStyle = getFileStatusColor(fileStatus); 
   return  <Box sx={{position:"relative",height:"48px",width:"208px"}}>
        <Box sx={{height:"max-content",width:"max-content",position:"absolute",bottom:0,}}>
            <FileViewComponent onApprove={onApprove} onReject={onReject} needsApproval={needsApproval} onRejection={onRejection} backgroundColor={fileStatusStyle.backgroundColor} documentTitle={documentTitle} documentUrl={documentUrl} />
        </Box>
        <Box sx={{position:"absolute",top:0,right:0,height:"16px",width:"16px"}}><img src={fileStatusStyle.src} /></Box>
    </Box>
}