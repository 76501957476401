const lclImportChargesData1 = {
    initialLength: 12,
    preList: [{
            id: 1,
            chargeDescription: 'Pick-up Charges',
            currency: 'INR',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            disabled: ['perCBM','perTon','maximum', 'minimum']
        },
        {
            id: 2,
            chargeDescription: 'Export Customs Clearance (if applicable)',
            currency: 'INR',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            disabled: ['perCBM','perTon','maximum', 'minimum']
        },
        {
            id: 3,
            chargeDescription: 'Origin Charges',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: 'INR'
        },
        {
            id: 4,
            chargeDescription: 'Origin Inland Haulage (if applicable)',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: 'INR'
        },
        {
            id: 5,
            chargeDescription: 'Ocean Freight',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: 'USD',
            disabled: ['perShipment']
        },
        {
            id: 6,
            chargeDescription: 'Hazardous Surcharge (if applicable)',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: 'USD'
        },
        {
            id: 7,
            chargeDescription: 'Non-Stackable Surcharge (if applicable)',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: 'USD'
        },
        {
            id: 8,
            chargeDescription: 'Destination Inland Haulage (if applicable)',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: ''
        },
        {
            id: 9,
            chargeDescription: 'Destination Charges',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: ''
        },
        {
            id: 10,
            chargeDescription: 'Destination CFS Charges',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: ''
        },
        {
            id: 11,
            chargeDescription: 'Import Customs Clearance (if applicable)',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            currency: '',
            disabled: ['perCBM','perTon','maximum', 'minimum']
        },
        {
            id: 12,
            chargeDescription: 'Door Delivery Charges (if applicable)',
            currency: '',
            perCBM: 0,
            perTon: 0,
            minimum: 0,
            maximum: 0,
            disabled: ['perCBM','perTon','maximum', 'minimum']
        }
    ]
}
export default lclImportChargesData1