
import React, { useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import { configStore, dataListStore } from '../state/CommonState';
import Api from '../state/Api';
import trackMyBookingOceanStore from '../trackmybookings/TrackMyBooking-store';
import Dtaddress from './dtAddress'
import { loader } from '../util/loader';

export default function TrackMyBookingsModal({ type, isOpen, onClose, callback, search }) {
    const [isValid, setValid] = useState(true)
    useSnapshot(dataListStore)
    useSnapshot(configStore)
    useSnapshot(trackMyBookingOceanStore)
    const [error, setError] = useState([])
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    const checkfield = () => {
        let e = []
        let erpk = []
        for (let i = 0; i < trackMyBookingOceanStore.details.length; i++) {
            if (trackMyBookingOceanStore.details[i]['pickup'].trim().length === 0) {
                e.push('pickup' + i)
            }
            if (trackMyBookingOceanStore.details[i]['pickup_2'].trim().length === 0) {
                erpk.push('pickup_2' + i)
            }
            if (trackMyBookingOceanStore['multipickup'] !== 'No') {
                if (erpk.length == trackMyBookingOceanStore.details.length) {
                    erpk.map((v, i) => {
                        e.push(v)
                    })
                }
            }
            let e1 = []
            for (let j = 0; j < trackMyBookingOceanStore.details[i]['delivery'].length; j++) {
                if (trackMyBookingOceanStore.details[i]['delivery'][j]['address'].trim().length === 0) {
                    e1.push(`address${i}${j}`)
                }
            }
            if (e1.length == trackMyBookingOceanStore.details[i]['delivery'].length) {
                e1.map((v, i) => {
                    e.push(v)
                })
            }
            setError(e)
            if (e.length == 0) {
                return true
            } else {
                return false
            }
        }
    }

    const checkvalid = (l) => {
        if (trackMyBookingOceanStore.valid) {
            if (error.indexOf(l) == -1) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    useEffect(() => {
        Api.getP44FCLCarrierList()
    }, [isValid]);
    const map = dataListStore.P44CarrierList.map((carrier) => ({
        key: carrier["Display Name"],
        value: `${carrier["Display Name"]} (${carrier["SCAC code"]})`,
    }))
    let list = ['Carrier Booking Reference#', 'Carrier MBL Number', 'Container Number']
    const handleClose = () => {
        onClose()
        trackMyBookingOceanStore.setTrackBooking()
        // callback()
    }
    const handleSubmit = () => {
        if (type === 'Domestic Transport') {
            trackMyBookingOceanStore.valid = true
            checkfield()
        }
        const status = type === 'Ocean' ? (!trackMyBookingOceanStore.containerNumber && !trackMyBookingOceanStore.carrierBookingRefNumber && !trackMyBookingOceanStore.carrierMBLNumber) || !trackMyBookingOceanStore.carrier
            : type === 'Air' ? !trackMyBookingOceanStore.MAWBNumber
                : trackMyBookingOceanStore.driverPhoneNumber.length !== 10 || !trackMyBookingOceanStore.vendorName || !trackMyBookingOceanStore.driverName || !trackMyBookingOceanStore.vehicleNumber
        if (status) {
            setValid(false)
            setTimeout(() => {
                setValid(true)
                trackMyBookingOceanStore.valid = false
            }, 2000)
        } else {
            setValid(true)
            loader.show()
            Api.addTrackBookings(trackMyBookingOceanStore, type, callback, search)
            onClose();
        }
    };
    const addpickuppoint = () => {
        trackMyBookingOceanStore["multipickup"] = "Yes"
    }
    const delpickup2 = () => {
        trackMyBookingOceanStore['multipickup'] = 'No'
        for (let i = 0; i < trackMyBookingOceanStore['details'].length; i++) {
            trackMyBookingOceanStore['details'][i]['pickup_2'] = "";
        }
    }
    const adddelivery = () => {
        for (let i = 0; i < trackMyBookingOceanStore.details.length; i++) {
            trackMyBookingOceanStore.details[i]['delivery'].push({ address: "" });
        }
    }
    const deldelivery = (i, j) => {
        let updatedDetails = [...trackMyBookingOceanStore.details];
        updatedDetails[i].delivery.splice(j, 1);
        trackMyBookingOceanStore.details = updatedDetails;
    };
    return (
        <div className={`modal ${isOpen ? "is-active" : ""}`} onClick={onClose}>
            <div className="modal-background"></div>
            <div className="modal-card" onClick={(e) => e.stopPropagation()} style={{ borderRadius: '10px', padding: '20px', width: '50%', margin: '0 auto' }}>
                {configStore.currentSelectedEntity.status==='DEACTIVATED'?<section className="modal-card-body" style={{ backgroundColor: 'whitesmoke' }}>
                    <div>
                    You have been deactivated. Please reach out to pat@agraga.com for further clarification.
                    </div>
                </section>:
                <section className="modal-card-body" style={{ backgroundColor: 'whitesmoke' }}>
                    <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Track My Bookings</h1>
                    {
                        type === 'Ocean' ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '20px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Customer Reference#</label>
                                    </div>
                                    <div>
                                        <input
                                            type='text'
                                            className='input is-small'
                                            value={trackMyBookingOceanStore.customerReference}
                                            ref={ref}
                                            onChange={(e) => {
                                                setCursor(e.target.selectionStart)
                                                trackMyBookingOceanStore.customerReference = e.target.value;
                                            }}
                                            style={{ padding: '4px' }}
                                        ></input>
                                    </div>
                                    <div className="field">
                                        <label className="label is-small" style={{ marginBottom: "0px" }}>
                                            *Carrier
                                        </label>
                                        <div className="control">
                                            <div
                                                className={trackMyBookingOceanStore.carrier || isValid ? "select is-small" : "select is-small is-danger"}
                                            >
                                                <select
                                                    style={{ padding: '4px', width: '200px' }}
                                                    name='carrier'
                                                    value={trackMyBookingOceanStore.carrier}
                                                    onChange={(e) => {
                                                        trackMyBookingOceanStore.carrier = e.target.value;
                                                    }}
                                                >
                                                    <option selected value={""} hidden>
                                                        Select an option
                                                    </option>
                                                    {map.map(({ key, value }) => (
                                                        <option value={value}>{key}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field" style={{ display: 'flex', flexDirection: 'column',width:'200px' }}>
                                    <div className="control" style={{ marginTop: '5px', marginLeft: '5px' }} >
                                        <label className="label is-small" style={{ marginBottom: "0px" }}>
                                            Select Type
                                        </label>
                                        <div
                                            className={trackMyBookingOceanStore.oceanNumber || isValid ? "select is-small" : "select is-small is-danger"}
                                        >
                                            <select
                                                style={{ padding: '4px', width: '200px' }}
                                                name='carrier'
                                                value={trackMyBookingOceanStore.oceanNumber}
                                                onChange={(e) => {
                                                    trackMyBookingOceanStore.oceanNumber = e.target.value;
                                                }}
                                            >
                                                <option selected value={""} hidden>
                                                    Select an option
                                                </option>
                                                {list.map((e) => (
                                                    <option value={e}>{e}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {trackMyBookingOceanStore.oceanNumber ? <div style={{  marginTop: '20px' }}>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>{"*"+trackMyBookingOceanStore.oceanNumber}</label>
                                        <input
                                            type='text'
                                            className={(trackMyBookingOceanStore.containerNumber || trackMyBookingOceanStore.carrierBookingRefNumber || trackMyBookingOceanStore.carrierMBLNumber) || isValid ? 'input is-small' : 'input is-small is-danger'}
                                            value={trackMyBookingOceanStore.oceanNumber === 'Carrier MBL Number' ? trackMyBookingOceanStore.carrierMBLNumber : trackMyBookingOceanStore.oceanNumber === 'Container Number' ? trackMyBookingOceanStore.containerNumber : trackMyBookingOceanStore.carrierBookingRefNumber}
                                            ref={ref}
                                            onChange={(e) => {
                                                setCursor(e.target.selectionStart)
                                                if (trackMyBookingOceanStore.oceanNumber === 'Carrier MBL Number') {
                                                    trackMyBookingOceanStore.containerNumber = '';
                                                    trackMyBookingOceanStore.carrierBookingRefNumber = '';
                                                    trackMyBookingOceanStore.carrierMBLNumber = e.target.value;
                                                } else if (trackMyBookingOceanStore.oceanNumber === 'Container Number') {
                                                    trackMyBookingOceanStore.carrierBookingRefNumber = '';
                                                    trackMyBookingOceanStore.carrierMBLNumber = '';
                                                    trackMyBookingOceanStore.containerNumber = e.target.value;
                                                } else {
                                                    trackMyBookingOceanStore.carrierBookingRefNumber = e.target.value;
                                                    trackMyBookingOceanStore.carrierMBLNumber = '';
                                                    trackMyBookingOceanStore.containerNumber = '';
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        ></input>
                                    </div> : <></>}

                                </div>
                                {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Carrier Booking Reference#</label>
                                        <input
                                            type='text'
                                            className={(trackMyBookingOceanStore.carrierBookingRefNumber && (!trackMyBookingOceanStore.carrierMBLNumber || !trackMyBookingOceanStore.containerNumber)) || isValid ? 'input is-small' : 'input is-small is-danger'}
                                            value={trackMyBookingOceanStore.carrierBookingRefNumber}
                                            ref={ref}
                                            onChange={(e) => {
                                                setCursor(e.target.selectionStart)
                                                trackMyBookingOceanStore.carrierBookingRefNumber = e.target.value;
                                            }}
                                            style={{ padding: '4px' }}
                                        ></input>
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>(or)</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Carrier MBL Number</label>
                                        <input
                                            type='text'
                                            className={(trackMyBookingOceanStore.carrierMBLNumber &&( !trackMyBookingOceanStore.carrierBookingRefNumber || !trackMyBookingOceanStore.containerNumber)) || isValid ? 'input is-small' : 'input is-small is-danger'}
                                            value={trackMyBookingOceanStore.carrierMBLNumber}
                                            ref={ref}
                                            onChange={(e) => {
                                                setCursor(e.target.selectionStart)
                                                trackMyBookingOceanStore.carrierMBLNumber = e.target.value;
                                            }}
                                            style={{ padding: '4px' }}
                                        ></input>
                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>(or)</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Container Number</label>
                                        <input
                                            type='text'
                                            className={(trackMyBookingOceanStore.containerNumber && (!trackMyBookingOceanStore.carrierBookingRefNumber || !trackMyBookingOceanStore.carrierMBLNumber)) || isValid ? 'input is-small' : 'input is-small is-danger'}
                                            value={trackMyBookingOceanStore.containerNumber}
                                            ref={ref}
                                            onChange={(e) => {
                                                setCursor(e.target.selectionStart)
                                                trackMyBookingOceanStore.containerNumber = e.target.value;
                                            }}
                                            style={{ padding: '4px' }}
                                        ></input>
                                    </div>
                                </div> */}
                            </div>
                            : type === 'Air' ?
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', margin: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: '20px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Customer Reference#</label>
                                            <input
                                                type='text'
                                                className='input is-small'
                                                value={trackMyBookingOceanStore.customerReference}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    trackMyBookingOceanStore.customerReference = e.target.value;
                                                }}
                                                style={{ padding: '4px' }}
                                            ></input>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold' }}>*MAWB Number</label>
                                            <input
                                                type='text'
                                                className={trackMyBookingOceanStore.MAWBNumber||isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.MAWBNumber}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    // if (e.target.value!='') {
                                                        trackMyBookingOceanStore.MAWBNumber = e.target.value;
                                                        // setValid(true);
                                                    // } else {
                                                        // setValid(false);
                                                    // }
                                                }}
                                                style={{ padding: '4px' }}
                                            ></input>
                                        </div>
                                        {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>*Airline Booking Reference#</label>
                                            <input
                                                type='text'
                                                className={isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.airlineBookingReference}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    if (e.target.value) {
                                                        trackMyBookingOceanStore.airlineBookingReference = e.target.value;
                                                        setValid(true);
                                                    } else {
                                                        setValid(false);
                                                    }
                                                }}
                                                style={{ padding: '4px' }}
                                            ></input>
                                        </div> */}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold' }}>*HAWB Number</label>
                                            <input
                                                type='text'
                                                className={isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.HAWBNumber}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        trackMyBookingOceanStore.HAWBNumber = e.target.value;
                                                        setValid(true);
                                                    } else {
                                                        setValid(false);
                                                    }
                                                }}
                                                style={{ padding: '4px' }}
                                            ></input>
                                        </div> */}
                                        {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold',textAlign:'center' }}>*MAWB Number</label>
                                            <input
                                                type='text'
                                                className={isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.MAWBNumber}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        trackMyBookingOceanStore.MAWBNumber = e.target.value;
                                                        setValid(true);
                                                    } else {
                                                        setValid(false);
                                                    }
                                                }}
                                                style={{ padding: '4px' }}
                                            ></input>
                                        </div> */}
                                    </div>
                                </div>
                                : <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold', flex: '0 0 140px' }}>Customer Reference#</label>
                                            <input
                                                type='text'
                                                className={'input is-small'}
                                                value={trackMyBookingOceanStore.customerReference}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    trackMyBookingOceanStore.customerReference = e.target.value;
                                                }}
                                                style={{ padding: '4px', flex: '1' }}
                                            />
                                            <label style={{ fontSize: '12px', fontWeight: 'bold', flex: '0 0 100px' }}>*Vendor Name</label>
                                            <input
                                                type='text'
                                                className={trackMyBookingOceanStore.vendorName || isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.vendorName}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    trackMyBookingOceanStore.vendorName = e.target.value;
                                                }}
                                                style={{ padding: '4px', flex: '1' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold', flex: '0 0 140px' }}>*Driver Name</label>
                                            <input
                                                type='text'
                                                className={trackMyBookingOceanStore.driverName || isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.driverName}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    trackMyBookingOceanStore.driverName = e.target.value;

                                                }}
                                                style={{ padding: '4px', flex: '1' }}
                                            />
                                            <label style={{ fontSize: '12px', fontWeight: 'bold', flex: '0 0 100px' }}>*Vehicle Number</label>
                                            <input
                                                type='text'
                                                className={trackMyBookingOceanStore.vehicleNumber || isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.vehicleNumber}
                                                ref={ref}
                                                onChange={(e) => {
                                                    setCursor(e.target.selectionStart)
                                                    trackMyBookingOceanStore.vehicleNumber = e.target.value;
                                                }}
                                                style={{ padding: '4px', flex: '1' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                            <label style={{ fontSize: '12px', fontWeight: 'bold', flex: '0 0 140px' }}>*Driver Phone Number</label>
                                            <input
                                                type='number'
                                                className={trackMyBookingOceanStore.driverPhoneNumber.length === 10 || isValid ? 'input is-small' : 'input is-small is-danger'}
                                                value={trackMyBookingOceanStore.driverPhoneNumber}
                                                onChange={(e) => {
                                                    trackMyBookingOceanStore.driverPhoneNumber = e.target.value;
                                                }}
                                                pattern="[1-9]{1}[0-9]{9}"
                                                style={{ padding: '4px', flex: '1' }}
                                            />
                                            {trackMyBookingOceanStore.driverPhoneNumber.length === 10 ? (
                                                <>
                                                    <button
                                                        className="button is-small button is-info"
                                                        onClick={async () => {
                                                            const data = await Api.checkDriverStatus(trackMyBookingOceanStore.driverPhoneNumber)
                                                            trackMyBookingOceanStore.phoneNumberStatus = data?.data
                                                        }}
                                                    >
                                                        Check Status
                                                    </button>
                                                    {trackMyBookingOceanStore.phoneNumberStatus ? <textarea value={trackMyBookingOceanStore.phoneNumberStatus} disabled /> : <></>}
                                                </>
                                            ) : null}
                                        </div>
                                        {/* pickup */}
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                                            {trackMyBookingOceanStore.details.map((v, i) => (
                                                <div key={i} >
                                                    {trackMyBookingOceanStore.multipickup === 'No' ? (
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '60px' }}>
                                                                <label style={{ fontSize: '12px', fontWeight: 'bold' }}>*Pickup Address</label>
                                                                <Dtaddress callback={checkfield} record={v} name="pickup" isValid={checkvalid(`pickup${i}`)} />
                                                                <button tabIndex={10} className="button is-success is-small" onClick={addpickuppoint}>
                                                                    <span className="icon is-small">
                                                                        <i className="fas fa-plus"></i>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{ marginBottom: '20px', padding: '10px', borderRadius: '5px' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                    <label style={{ fontSize: '12px', fontWeight: 'bold' }}>*Pickup Address 1</label>
                                                                    <Dtaddress callback={checkfield} record={v} name="pickup" isValid={checkvalid(`pickup${i}`)} />
                                                                </div>

                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                                                                    <label style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '10px' }}>*Pickup Address 2</label>
                                                                    <Dtaddress callback={checkfield} record={v} name="pickup_2" isValid={checkvalid(`pickup_2${i}`)} />
                                                                    <button tabIndex={10} className="button is-danger  is-small" onClick={delpickup2}>
                                                                        <span className="icon mt-0 pt-0">
                                                                            <i className="fas fa-trash"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        {/* Delivery */}
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                            {trackMyBookingOceanStore.details.map((v, i) => (
                                                <div key={i} style={{ marginBottom: '20px', borderRadius: '5px' }}>
                                                    {v.delivery.map((v1, i1) => (
                                                        <div key={i1} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
                                                                <label style={{ fontSize: '12px', fontWeight: 'bold' }}>{i1 == 0 ? "*Delivery Address" : "*Delivery Address" + " " + `${i1 + 1}`}</label>
                                                                <Dtaddress callback={checkfield} record={v1} name={`address`} isValid={checkvalid(`address${i}${i1}`)} />
                                                                {(i === trackMyBookingOceanStore.details.length - 1) && (i1 === v.delivery.length - 1) && (
                                                                    <button className="button is-success is-small" onClick={adddelivery}>
                                                                        <span className="icon is-small">
                                                                            <i className="fas fa-plus"></i>
                                                                        </span>
                                                                    </button>)}
                                                                {trackMyBookingOceanStore.details[0].delivery.length > 1 && (
                                                                    <button className="button is-danger is-small" onClick={() => { deldelivery(i, i1) }}>
                                                                        <span className="icon is-small">
                                                                            <i className="fas fa-trash"></i>
                                                                        </span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                        <button class="button is-danger" onClick={handleClose}>Cancel</button>
                        {type === 'Domestic Transport' && (trackMyBookingOceanStore.driverPhoneNumber.length !== 10 || trackMyBookingOceanStore.phoneNumberStatus !== 'ALLOWED')
                            ? <button class="button is-primary" disabled onClick={handleSubmit}>Submit</button>
                            : <button class="button is-primary" onClick={handleSubmit}>Submit</button>
                        }
                    </div>
                </section>}                
            </div >
        </div >

    );
}


//else
// <div style={{ display: 'flex', flexDirection: 'column',alignItems:'flex-start', gap: '20px', margin: '10px' }}>
//     <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'flex-start', gap: '1em' }} >
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Customer Reference#</label>
//         </div>
//         <div>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.HAWBNumber} onChange={(e) => {
//                 if (e.target.value) {
//                     trackMyBookingOceanStore.HAWBNumber = e.target.value;
//                     setValid(true);
//                 } else {
//                     setValid(false);
//                 }
//             }} style={{ padding: '4px' }}></input>
//         </div>
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Vendor Name</label>
//         </div>
//         <div>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.MAWBNumber} onChange={(e) => {
//                 if (e.target.value) {
//                     trackMyBookingOceanStore.MAWBNumber = e.target.value;
//                     setValid(true);
//                 } else {
//                     setValid(false);
//                 }
//             }} style={{ padding: '4px' }}></input>
//         </div>
//     </div>
//     <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'flex-start', gap: '3em' }} >
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Vehicle Number</label>
//         </div>
//         <div>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.HAWBNumber} onChange={(e) => {
//                 if (e.target.value) {
//                     trackMyBookingOceanStore.HAWBNumber = e.target.value;
//                     setValid(true);
//                 } else {
//                     setValid(false);
//                 }
//             }} style={{ padding: '4px' }}></input>
//         </div>
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Driver Number</label>
//         </div>
//         <div>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.MAWBNumber} onChange={(e) => {
//                 if (e.target.value) {
//                     trackMyBookingOceanStore.MAWBNumber = e.target.value;
//                     setValid(true);
//                 } else {
//                     setValid(false);
//                 }
//             }} style={{ padding: '4px' }}></input>
//         </div>
//     </div>
//     <div style={{ display: 'flex', flexDirection: 'row', gap: '40px' }} >
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Pickup Address</label>
//         </div>
//         <div>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.HAWBNumber} onChange={(e) => {
//                 if (e.target.value) {
//                     trackMyBookingOceanStore.HAWBNumber = e.target.value;
//                     setValid(true);
//                 } else {
//                     setValid(false);
//                 }
//             }} style={{ padding: '4px' }}></input>
//         </div>
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Delivery Address</label>
//         </div>
//         <div>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.MAWBNumber} onChange={(e) => {
//                 if (e.target.value) {
//                     trackMyBookingOceanStore.MAWBNumber = e.target.value;
//                     setValid(true);
//                 } else {
//                     setValid(false);
//                 }
//             }} style={{ padding: '4px' }}></input>
//         </div>
//     </div>
// </div>

//if

// <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '20px' }}>
//     <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Customer Reference#</label>
//         </div>
//         <div>
//             <input type='text' className='input is-small' value={trackMyBookingOceanStore.customerReference} onChange={(e) => {
//                 trackMyBookingOceanStore.customerReference = e.target.value
//             }} style={{ padding: '4px' }}></input>
//         </div>
//         <div>
//             <label className="label is-small" style={{ marginBottom: "0px" }}>
//                 *Carrier
//             </label>
//             <select style={{ padding: '4px', width: '200px' }}
//                 name='carrier' value={trackMyBookingOceanStore.carrier} onChange={(e) => {
//                     trackMyBookingOceanStore.carrier = e.target.value
//                 }}>
//                 <option selected value={""} hidden>
//                     Select Carrier Name
//                 </option>
//                 {map.map(({ key, value }) => (
//                     <option value={value}>{key}</option>
//                 ))}
//             </select>
//         </div>
//     </div>

//     <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} >
//         <div style={{ display: 'flex', justifyContent: 'space-around', gap: 5 }}>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Carrier Booking Reference#</label>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.carrierBookingRefNumber} onChange={(e) => {
//                 trackMyBookingOceanStore.carrierBookingRefNumber = e.target.value
//             }} style={{ padding: '4px' }}></input>
//         </div>
//         <div style={{ display: 'flex', justifyContent: 'space-around', gap: 30 }}>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Carrier MBL Number</label>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.carrierMBLNumber} onChange={(e) => {
//                 trackMyBookingOceanStore.carrierMBLNumber = e.target.value
//             }} style={{ padding: '4px' }}></input>
//         </div>
//         <div style={{ display: 'flex', justifyContent: 'space-around', gap: 40 }}>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Container Number</label>
//             <input type='text' className={isValid ? 'input is-small' : 'input is-small is-danger'} value={trackMyBookingOceanStore.containerNumber} onChange={(e) => {
//                 trackMyBookingOceanStore.containerNumber = e.target.value
//             }} style={{ padding: '4px' }}></input>
//         </div>
//     </div>
// </div>
// <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '20px' }}>
//     <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//         <div>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Customer Reference#</label>
//         </div>
//         <div>
//             <input
//                 type='text'
//                 className='input is-small'
//                 value={trackMyBookingOceanStore.customerReference}
//                 onChange={(e) => {
//                     trackMyBookingOceanStore.customerReference = e.target.value
//                 }}
//                 style={{ padding: '4px' }}
//             ></input>
//         </div>
//         <div>
//             <label className="label is-small" style={{ marginBottom: "0px" }}>
//                 *Carrier
//             </label>
//             <select
//                 style={{ padding: '4px', width: '200px' }}
//                 name='carrier'
//                 value={trackMyBookingOceanStore.carrier}
//                 onChange={(e) => {
//                     trackMyBookingOceanStore.carrier = e.target.value
//                 }}
//             >
//                 <option disabled value='' hidden>
//                     Select Carrier Name
//                 </option>
//                 {map.map(({ key, value }) => (
//                     <option key={value} value={value}>{key}</option>
//                 ))}
//             </select>
//         </div>
//     </div>

//     <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//         <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Carrier Booking Reference#</label>
//             <input
//                 type='text'
//                 className={isValid ? 'input is-small' : 'input is-small is-danger'}
//                 value={trackMyBookingOceanStore.carrierBookingRefNumber}
//                 onChange={(e) => {
//                     trackMyBookingOceanStore.carrierBookingRefNumber = e.target.value
//                 }}
//                 style={{ padding: '4px' }}
//             ></input>
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Carrier MBL Number</label>
//             <input
//                 type='text'
//                 className={isValid ? 'input is-small' : 'input is-small is-danger'}
//                 value={trackMyBookingOceanStore.carrierMBLNumber}
//                 onChange={(e) => {
//                     trackMyBookingOceanStore.carrierMBLNumber = e.target.value
//                 }}
//                 style={{ padding: '4px' }}
//             ></input>
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
//             <label style={{ fontSize: '12px', fontWeight: 'bold' }}>Container Number</label>
//             <input
//                 type='text'
//                 className={isValid ? 'input is-small' : 'input is-small is-danger'}
//                 value={trackMyBookingOceanStore.containerNumber}
//                 onChange={(e) => {
//                     trackMyBookingOceanStore.containerNumber = e.target.value
//                 }}
//                 style={{ padding: '4px' }}
//             ></input>
//         </div>
//     </div>
// </div>
