const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED';
const PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
const PAYLOAD_CHANGED = 'PAYLOAD_CHANGED'
const reducer = (state, { type, payload }) => {
    switch (type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case PAGE_SORT_CHANGED:
            return {
                ...state,
                queryPageSortBy: payload,
            };
        case PAGE_FILTER_CHANGED:
            return {
                ...state,
                queryPageFilter: payload,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        case PAYLOAD_CHANGED:
            return {
                ...state,
                queryPayload: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

export {
    reducer,
    PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    PAGE_SORT_CHANGED,
    PAGE_FILTER_CHANGED,
    TOTAL_COUNT_CHANGED,
    PAYLOAD_CHANGED
}