import { useState } from "react";
import { useSnapshot } from "valtio";
import { dtactionStore } from "./dtaction-store";
import MonApi from "../state/mongo-api";
import EntityApi from "../state/entities-api";
import { configStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import { loader } from "../util/loader";

export default function GSTVerify({data,gstDataClick, gstDataCancel }) {

  useSnapshot(dtactionStore);
  let booking = dtactionStore.current.booking;
  let modalClass = "modal";
  let tradeName;
  if (dtactionStore.modalVisible1) modalClass += " is-active";
  if(data?.tradeName==" " ||data?.tradeName=="-"){
    console.log("Test")
  } else{
    tradeName=data?.tradeName
  }

  return (
    <div
      className={modalClass}
      // onClick={() => {
      //   dtactionStore.modalVisible1 = false;
      // } }
    >
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ borderRadius: "10px" }}
      >
        <section className="modal-card-body">
        <Cols>
            <Col3 style={{ color: "darkblue" }}>Entity Name:</Col3>
            <Col5>
              {
               tradeName
              }
            </Col5>
          </Cols>
        <Cols>
            <Col3 style={{ color: "darkblue" }}>Nature Of Business :</Col3>
            <Col5>
              {
                data?.natureOfBusinessActivity.join(", ") }
            </Col5>
          </Cols>
          <Cols>
            <Col3 style={{ color: "darkblue" }}>Address :</Col3>
            <Col5>
              {data?.principalPlaceOfBusinessFields
                ?.principalPlaceOfBusinessAddress?.floorNumber +
                " " + data?.principalPlaceOfBusinessFields
                ?.principalPlaceOfBusinessAddress?.buildingNumber +
                " " +
                data?.principalPlaceOfBusinessFields
                ?.principalPlaceOfBusinessAddress?.buildingName +
                " " +
               
                data?.principalPlaceOfBusinessFields
                  ?.principalPlaceOfBusinessAddress?.streetName}
            </Col5>
          </Cols>
          <Cols>
            <Col3 style={{ color: "darkblue" }}>State :</Col3>
            <Col5>
              {
                data?.principalPlaceOfBusinessFields
                  ?.principalPlaceOfBusinessAddress.stateName
              }
            </Col5>
          </Cols>
          <Cols>
            <Col3 style={{ color: "darkblue" }}>City :</Col3>
            <Col5>
              {" "}
              {
                data?.principalPlaceOfBusinessFields
                  ?.principalPlaceOfBusinessAddress?.districtName
              }
            </Col5>
          </Cols>
          <Cols>
            <Col3 style={{ color: "darkblue" }}>Pincode :</Col3>
            <Col5>
              {
                data?.principalPlaceOfBusinessFields
                  ?.principalPlaceOfBusinessAddress?.pincode
              }
            </Col5>
          </Cols>
         
          <br></br>
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={gstDataClick}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={gstDataCancel}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}
