import { useSnapshot } from "valtio"
import {
    quoteDataRec, cargoStore,
    containerStore
} from './state/CommonState'
import { useEffect } from "react"
import Utils from "./util/Utils"

export default function CargoView() {
    const imCargoStore = useSnapshot(cargoStore)
    const imQuoteDataRec = useSnapshot(quoteDataRec)
    const imContainerStore = useSnapshot(containerStore)
    const displayCargo4fcl = Utils.outGaugeContainerCheck(imContainerStore.list)
    const isP2P = () => quoteDataRec.shipmentScope === 'Port-to-Port' && !displayCargo4fcl
    const hide = () => (!quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P() ? { className: 'is-hidden' } : {})
    const dispForair = () => (quoteDataRec.shipmentType.indexOf('Air') >= 0) ? {} : { className: 'is-hidden' }
    let totNumPackages = 0
    let totTotalWeight = 0
    let totTotalVolume = 0
    let totChargeableWeight = 0
    useEffect(() => {
        cargoStore.calculateCargoTotals()
    }, [cargoStore.cargoList])
    const Th2 = (props) => <th rowSpan="2" {...props} style={{ textAlign:'center', paddingTop: '1.5rem' }}>{props.children}</th>
    /*for (let i = 0; i < cargoStore.cargoList.length; i++) {
        const e = cargoStore.cargoList[i]
        totNumPackages += parseInt(e.numPackages)
        totTotalWeight += parseFloat(e.totalWeight)
        totTotalVolume += parseFloat(e.totalVolume)
        totChargeableWeight += parseInt(e.chargeableWeight)
    }*/
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th colSpan={(() => {
                                if (quoteDataRec.shipmentType.toLowerCase().includes('lcl') && isP2P()) return 5;
                                if (quoteDataRec.shipmentType.toLowerCase().includes('lcl') || quoteDataRec.shipmentType.toLowerCase().includes('fcl')) return 10;
                                else return 11;
                            })()}>
                            {/* <th colSpan={11}> */}
                                <h3 className="is-size-5 has-text-centered">{Utils.labelForOutGauge1(imQuoteDataRec,imContainerStore.list) ?'Cargo Details for Out-Gauge Containers':`Cargo Details`}</h3>
                            </th>
                        </tr>
                        <tr>
                            <Th2>S.No.</Th2>
                            <Th2>Package Type</Th2>
                            <Th2># of Packages</Th2>
                            {
                                quoteDataRec.shipmentType.toLowerCase().includes('air') ? ( <Th2>Weight per Package (kgs)</Th2>) : quoteDataRec.shipmentScope === 'Port-to-Port' && !displayCargo4fcl ? <></> : ( <Th2>Weight per Package (kgs)</Th2>)
                            }
                            {
                                !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope === 'Port-to-Port'&&!displayCargo4fcl ? <></> :<>
                            <th colSpan="3" style={{textAlign:'center'}}>Dimensions per Package (CMS)</th></> 
                            }
                            {
                                !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port' || displayCargo4fcl? <Th2 >Volume per Package (CBM)</Th2> : <></>
                            }
                            {
                                (<Th2>{quoteDataRec.shipmentType.toLowerCase().includes('air') ? 'Total Gross Weight (kgs)' : 'Total Weight (kgs)'}</Th2>)
                            }
                            {/* { quoteDataRec.shipmentType.indexOf('Air') >= 0 ? <Th2 {...dispForair()}>Chargeable weight (kgs)</Th2> : <></>} */}
                            <Th2>{quoteDataRec.shipmentType.toLowerCase().includes('air') ? 'Total Volume Weight (kgs)' : 'Total Volume (CBM)'}</Th2>
                        </tr>
                        {  !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope === 'Port-to-Port'&& !displayCargo4fcl  ? <></>: <tr>
                            <th style={{textAlign:'center'}}>L</th>
                            <th style={{textAlign:'center'}}>W</th>
                            <th style={{textAlign:'center'}}>H</th>
                        </tr> }
                    </thead>
                    <tbody>
                    <tr style={{display :'none'}} >
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            {  isP2P() ? <></> :
                            <><td {...hide()}></td>
                            <td {...hide()}></td>
                            <td {...hide()}></td>
                            <td {...hide()}></td>
                            <td {...hide()}></td></> 
                            }
                            <td ></td>
                            {/* { quoteDataRec.shipmentType.indexOf('Air') >= 0 ? <td {...dispForair()}></td> : <></>} */}
                            <td ></td>
                        </tr>
                        {
                            cargoStore.cargoList.map((e, i) =>  !quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P() ? 
                            (
                                <tr key={i + 1}>
                                    <td style={{textAlign:'center'}}>{i + 1}</td>
                                    <td style={{textAlign:'center'}}>{e.packageType}</td>
                                    <td style={{textAlign:'center'}}>{e.numPackages}</td>
                                    {/* <td style={{textAlign:'center'}} {...hide()}>{e.wtPerPackage}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.length}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.width}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.height}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.volPerPackage}</td> */}
                                    <td style={{textAlign:'center'}}>{e.totalWeight}</td> 
                                    { quoteDataRec.shipmentType.toLowerCase().includes('air') && <td style={{textAlign:'center'}} {...dispForair()}>{e.chargeableWeight}</td> }
                                    <td style={{textAlign:'center'}}>{e.totalVolume}</td>
                                </tr>
                            ):  (
                                <tr key={i + 1}>
                                    <td style={{textAlign:'center'}}>{i + 1}</td>
                                    <td style={{textAlign:'center'}}>{e.packageType}</td>
                                    <td style={{textAlign:'center'}}>{e.numPackages}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.wtPerPackage}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.length}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.width}</td>
                                    <td style={{textAlign:'center'}} {...hide()}>{e.height}</td>
                                    {
                                        !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port'||displayCargo4fcl ?
                                        <td style={{textAlign:'center'}} >{e.volPerPackage}</td> : <></>
                                    }
                                    <td style={{textAlign:'center'}}>{e.totalWeight}</td> 
                                    {/* { quoteDataRec.shipmentType.indexOf('Air') >= 0 && <td style={{textAlign:'center'}} {...dispForair()}>{e.chargeableWeight}</td>} */}
                                    <td style={{textAlign:'center'}}>{e.totalVolume}</td>
                                </tr>
                            )
                            )
                        }
                        <tr className="has-text-weight-bold">
                            <td ></td>
                            <td></td>
                            <td style={{textAlign:'center'}}>{cargoStore.totals.totNumPackages}</td>
                            {   !quoteDataRec.shipmentType.toLowerCase().includes('air') && isP2P() ? <></> :
                            <><td {...hide()}></td>
                            <td {...hide()}></td>
                            <td {...hide()}></td>
                            <td {...hide()}></td>
                            {
                                !quoteDataRec.shipmentType.toLowerCase().includes('air') && quoteDataRec.shipmentScope !== 'Port-to-Port' || displayCargo4fcl ? <td></td> : <></>
                            }
                            </> 
                            }
                            { <td style={{textAlign:'center'}}>{cargoStore.totals.totTotalWeight}</td> }
                            {/* { quoteDataRec.shipmentType.indexOf('Air') >= 0 ? <td style={{textAlign:'center'}} >{cargoStore.totals.totChargeableWeight}</td> : <></>} */}
                            <td style={{textAlign:'center'}}>{cargoStore.totals.totTotalVolume}</td>
                        </tr>
                        {
                            quoteDataRec.shipmentType.toLowerCase().includes('air') && 
                            <tr className="has-text-weight-bold">
                                <td colSpan={8}><p className="is-size-7 has-text-right has-text-danger">* Chargeable weight (kgs) &emsp;</p></td>
                                <td><p className="has-text-centered">{cargoStore.totals.totChargeableWeight}</p></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}