import React, { useRef, useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import uploadIcon from "../assets/images/upload.svg";
import { configStore } from '../state/CommonState';

const expand = keyframes`
  to {
    transform: scale(1);
  }
`;

const fillLeft = keyframes`
  to {
    transform: scale(4, 1.2);
  }
`;

const fadeUpOut = keyframes`
  to {
    opacity: 0;
    transform: translateY(-40%);
  }
`;

const fadeUpIn = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fillRight = keyframes`
  to {
    transform: scaleX(1);
  }
`;

const slideUp = keyframes`
  from {
    transform: scaleX(1) translateY(0);
  }
  to {
    transform: scaleX(1) translateY(-90%);
  }
`;

const strokeIn = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const slideDownInfo = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDownButton = keyframes`
  from {
    transform: scale(0.9) translateY(-100%);
  }
  to {
    transform: scale(0.9) translateY(0);
  }
`;

const slideDownProgress = keyframes`
  from {
    transform: scaleX(1) translateY(-90%);
  }
  to {
    transform: scaleX(1) translateY(10%);
  }
`;

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

const UploadBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  background: 'red',
  height:"40px",
  width:"200px",
  borderRadius: '10px',
//   boxShadow: '0 1.7px 1.4px rgba(0, 0, 0, 0.02), 0 4px 3.3px rgba(0, 0, 0, 0.028), 0 7.5px 6.3px rgba(0, 0, 0, 0.035), 0 13.4px 11.2px rgba(0, 0, 0, 0.042), 0 25.1px 20.9px rgba(0, 0, 0, 0.05), 0 60px 50px rgba(0, 0, 0, 0.07)',
  overflow: 'hidden',
  transform: 'rotate(0)',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: '16px',
  margin: 0,
  fontSize: '100%',
  fontFamily: 'inherit',
  color: 'white',
  background: 'none',
  border: 'none',
  borderRadius: 'inherit',
  outline: 'none',
  cursor: 'pointer',
  transform: 'scale(0.9)',
  '&::before': {
    position: 'absolute',
    content: '""',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#3bafda',
    borderRadius: 'inherit',
    transformOrigin: 'right',
  },
  '&.uploading::before': {
    animation: `${fillLeft} 1.2s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploading': {
    animation: `${expand} 0.3s forwards`,
  },
  '&.uploaded-after': {
    animation: `${slideDownButton} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
}));

const UploadProgress = styled(Box)(({ theme }) => ({
  position: 'absolute',
  content: '""',
  top: '90%',
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: 'white',
  background: '#2d334c',
  transform: 'scaleX(0)',
  transformOrigin: 'left',
  '&.uploading': {
    animation: `${fillRight} 2s 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploaded': {
    animation: `${slideUp} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploaded .check': {
    strokeDasharray: '16px',
    strokeDashoffset: '16px',
    marginRight: '6px',
    animation: `${strokeIn} 0.6s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
  '&.uploaded-after': {
    animation: `${slideDownProgress} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
}));

const UploadHint = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: 'white',
  transform: 'translateY(100%)',
  '&.uploading': {
    animation: `${fadeUpIn} 0.4s 0.8s forwards`,
  },
}));

const UploadInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  marginRight: '40px',
  '&.uploading > *': {
    animation: `${fadeUpOut} 0.4s 0.4s forwards`,
  },
  '&.uploaded-after': {
    animation: `${slideDownInfo} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards`,
  },
}));

export default function Upload() {
  const [status, setStatus] = useState('');
  const [fileList, setFileList] = useState([]);
  const fileInputRef = useRef(null);

  const updateFileState = (selectedFile)=>{
    if (selectedFile) {
    const pattern = /^[a-zA-Z0-9_ .()-]+$/;
    if(pattern.test(selectedFile['name'])){
    // if(onFileSelected){
    //      onFileSelected(selectedFile,()=>{
        
            setFileList((prev)=>[...prev, selectedFile]);
          
        //  })
    // }
  }
  }else{
    configStore.setModalMessage("Please Check File Name and Format");
    return
  }
  }

  const handleBoxDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      updateFileState(droppedFiles[0]);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    updateFileState(selectedFile);
  };
  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async () => {
    setStatus('uploading');
    await sleep(3000);
    setStatus('uploaded');
    await sleep(2000);
    setStatus('uploaded-after');
    await sleep(1000);
    setStatus('');
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height : "40px",fontFamily: 'Roboto, sans-serif', background: '#eceffc' }}>
      <UploadBox className={`upload ${status}`}>
        <UploadInfo className={status}>
              <Box onClick={()=>{
           handleBoxClick();
        }}  onDrop={handleBoxDrop}
  onDragOver={(e) => e.preventDefault()} sx={{border:"1px dashed #2C358A",borderRadius:"7px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",height:"40px",width:"200px",cursor:"pointer"}}>
            <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
            <IconButton onClick={()=>{
           handleBoxClick();
        }}>
                <img  src={uploadIcon} />
            </IconButton>
            <Box sx={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:"center",padding:"8px"}}>
                <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"12px",color:"#2C358A"}} >Click to upload</Typography>
                <Typography sx={{fontFamily:"Figtree",fontSize:"11px",color:"#AAAAAA"}} >or just drag and drop</Typography>
            </Box>
        </Box>
          {/* <Typography variant="body1" sx={{ paddingLeft: '8px' }}>
            Document.pdf
          </Typography> */}
        </UploadInfo>
        <UploadButton className={status} onClick={handleUpload}>
          <Typography className="upload__button__text" variant="body1">Upload</Typography>
        </UploadButton>
        <UploadHint className={status}>
          <Typography variant="body1">Uploading...</Typography>
        </UploadHint>
        <UploadProgress className={status}>
          <CheckIcon className="check" />
          <Typography variant="body1">Uploaded</Typography>
        </UploadProgress>
      </UploadBox>
    </Box>
  );
}
