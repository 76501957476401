import React from 'react'
import bg from "../assets/images/onboarding.svg"
import info from "../assets/images/info-temp.svg"
import { Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { useMode } from '../context/AuthContext';
function TempOnBoard({EntityData,callback , type }) {
  // const {setMode : callback} = useMode();
  return (
    <>  
        {/* <div style={{position:"absolute",right:"5%",top:"5%"}}>
       </div> */}
        <div className='onboard_logo'>
        <img src={bg}></img>
        </div>
        <div className='onboardForm'>
        <div className='info_logo'>
        <img src={info}></img>
        </div>
        <p style={{textAlign:"right",padding:"1rem",color:"red",cursor:"pointer"}} onClick={()=>{
          callback(false)
          if(type !== 'entityCreation'){
          localStorage.clear()
          window.location.reload()
          }
          }}><HighlightOffIcon/></p>
        <Typography variant="h5" fontFamily={"Figtree, sans-serif"} textAlign={"center"}
         lineHeight={"2.5rem"} padding={"3rem"} fontWeight={900} marginBottom={"1rem"} paddingBottom={"1rem"}
          gutterBottom>{type !== 'entityCreation' ?`Your login request is being processed. Kindly wait until your entity admin approves your request. Thank you for your patience.` : `Your Entity creation request is being processed. Kindly wait until your entity admin approves your request. Thank you for your patience.` }</Typography>
         {EntityData?.entityAdminData?.length > 0 && <div style={{display:"flex",width:"100%",padding:".3rem 1rem"}}>
         <p style={{width:"50%"}}>Entity Admin Contact</p>
         <p style={{width:"50%"}}>{EntityData?.entityAdminData?.map((d)=><p>{d?.email}</p>)}</p>
        </div>}
        {EntityData?.data?.crossBorder?.relationshipManager?<div style={{display:"flex",width:"100%",padding:".5rem 1rem"}}>
         <p style={{width:"50%"}}>Agraga CB Relationship Manager</p>
         <p style={{width:"50%"}}>{EntityData?.data?.crossBorder?.relationshipManager}</p>
        </div>:null}
        {EntityData?.data?.domesticTransport?.relationshipManager?<div style={{display:"flex",width:"100%",padding:".5rem 1rem"}}>
         <p style={{width:"50%"}}>Agraga DT Relationship Manager</p>
         <p style={{width:"50%"}}>{EntityData?.data?.domesticTransport?.relationshipManager}</p>
        </div>:null}
        {EntityData?.data?.crossBorder?.customerService?<div style={{display:"flex",width:"100%",padding:".5rem 1rem"}}>
         <p style={{width:"50%"}}>Agraga CB Customer Service</p>
         <p style={{width:"50%"}}>{EntityData?.data?.crossBorder?.customerService}</p>
        </div>:null}
        {EntityData?.data?.domesticTransport?.customerService? <div style={{display:"flex",width:"100%",padding:".5rem 1rem"}}>
         <p style={{width:"50%"}}>Agraga DT Customer Service</p>
         <p style={{width:"50%"}}>{EntityData?.data?.domesticTransport?.customerService}</p>
        </div>:null}
         <div style={{display:"flex",width:"100%",padding:".5rem 1rem",paddingBottom:"1rem"}}>
         <p style={{width:"50%"}}>Agraga Product Adoption</p>
         <p style={{width:"50%"}}>pat@agraga.com</p>
        </div>
        </div>
    </>
  )
}

export default TempOnBoard