import React from 'react';
import RoleUtils from '../../../src/util/RoleUtils'; 
import {PERMISSIONS} from '../../../src/util/constants'; 

const Button = ({ addButtonCallback, butttonText,icon }) => {
  return (
    RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.MANAGE_USERS) && (
      <p className="level-item">
        <button
          className="button is-link"
          onClick={addButtonCallback}
        > {icon}
          {butttonText}
        </button>
      </p>
    )
  );
};

export default Button;
