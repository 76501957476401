import { useSnapshot } from "valtio";
import { DtStore } from "../dt/DtStore";
import { dtactionStore } from "./dtaction-store";
import Dtnewinput from "../dt/cps/Dtnewinput";
import { Agmongofilenew } from "../components/AgFile";
import { configStore, loginStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";
import Utils from "../util/Utils";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import { Col } from "reactstrap";
import { useEffect, useState } from "react";
import DtactionsVendorbank from "./dtactions-vendorbank";
import { actions } from "react-table";
import DtactionPayout from "./dtaction-payout";
import EntityApi from "../state/entities-api";
import { data } from "../charts/c1";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import { act } from "react-dom/test-utils";

let paymentStages = {
    INITIAL: 0,
    SUBMITTED: 1,
    REJECTED: 2,
    COMPLETED: 9
}
export {
    paymentStages
}



export default function DtactionsPayment(props) {
    const { setSubmitCallback } = props
    useSnapshot(DtStore)
    useSnapshot(dtactionStore)
    useSnapshot(loginStore)
    useSnapshot(configStore)
    let dtStore = DtStore.DTBooking
    let action = dtactionStore.current
    let booking = action.booking
    let server = process.env.REACT_APP_API_SERVER
    let client = loginStore.isClient()
    
    const [entityData,setEntiyData] = useState("")
    const [amount,setAmount] = useState(0)
    const [manual,setManual] = useState("Non-Manual")
    const [displayStatus, setDisplayStatus] = useState(!action?.manualEnable?"OFF":action.manualEnable)
    // const [displayStatus, setDisplayStatus] = useState("OFF")

    // if(entityData.paymentEnable=="OFF"){
    //     setManual("Non-Manual")
    // }

    console.log("DSf",action)
    console.log("D",action.manualEnable)

   
    const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(value);

    let advancePayableAmount = Utils.toNum(action.booking.vendor_agreed_cost) * Utils.toNum(action.advancePayablePercent) / 100

    const callback = () => {
        let t = 0.0;
        let k = {};
        let ton = 0;
        if (booking.details[0].delivery[0]["ewaybillno"] == undefined) {
            for (let lz = 0; lz < booking.details[0].delivery.length; lz++) {
                booking.details[0].delivery[lz]["ewaybillno"] = "";
                booking.details[0].delivery[lz]["ewaybilldate"] = "";
                booking.details[0].delivery[lz]["lorryrecieptNo"] = "";
            }
        }
        if (booking.details[0].delivery[0]["lorryrecieptNo"] == undefined) {
            for (let lz = 0; lz < booking.details[0].delivery.length; lz++) {
                booking.details[0].delivery[lz]["lorryrecieptNo"] = "";
            }
        }
        if (booking.pricing_type == "vehicle") {
            let t = booking.details[0].vehicle.map((v) => {
                if (v.vehicle_type == booking.vehicle) {
                    return v.vehicle_amount;
                }
            });
            if (booking.bookingamount != undefined || booking.bookingamount != t) {
                booking.bookingamount = t;
            }
        }
        if (booking.pricing_type != "vehicle") {
            for (let i = 0; i < booking.details[0].vehicle.length; i++) {
                if (booking.details[0].vehicle[i].vehicle_type == booking.vehicle) {
                    k = booking.details[0].vehicle[i];
                }
            }
            t = k["minimum"];
            if (booking.totel_weight.length > 0) {
                ton = Number(k["minimum"]) / Number(k["amt_per_ton"]);
                if (booking.weight_type == "tons") {
                    let l = Number(booking.totel_weight);
                    if (l > ton) {
                        t = (l * Number(k["amt_per_ton"])).toFixed(2);
                    }
                }
                if (booking.weight_type == "kgs") {
                    let l = Number(booking.totel_weight) / 1000;
                    if (l > ton) {
                        t = (l * Number(k["amt_per_ton"])).toFixed(2);
                    }
                }
            }
            if (booking.bookingamount != t) {
                booking.bookingamount = t;
            }
        }
        if (dtactionStore.current.stage == paymentStages.INITIAL) {

            if (!action.utrNo || !action.utrNo.length ||
                !action.paymentAmt || !(action.paymentAmt.toString()).length || 
                !action.paymentDate || !action.paymentDate.length) {
                configStore.setModalMessage('UTR No., Payment Amount & Date are mandatory')
                return 0
            }
            dtactionStore.current.booking.advancePayment = Utils.toNum(action.paymentAmt)
            action.status = 'COMPLETED'
            action.stage = paymentStages.COMPLETED
            dtactionStore.view = 'list'
            return 1
        }
        if (dtactionStore.current.stage == paymentStages.SUBMITTED) {
            action.stage = paymentStages.COMPLETED
            action.status = 'COMPLETED'
            dtactionStore.view = 'list'
            return 1
        }
    }
    useEffect(() => {
        console.log('\n\n\n$$$$$$$$$ setsubmitcallback calling')
        dtactionStore.submitCallback = callback
        EntityApi.viewEntity(dtactionStore.current.vendor.split(" (")[0]).then((data)=>{
            setEntiyData(data)
        })
        
    }, [])

    let activeStyle = { backgroundColor: '#2c358f', color: 'white', border: '1px solid #2c358f' }
    let inactiveStyle = { backgroundColor: '#888', color: 'white', border: '1px solid #bbbbbb', margin: '1px' }
  
    let pendingStyle = inactiveStyle
    let completedStyle = inactiveStyle

    if (displayStatus === "ON") {
        pendingStyle = activeStyle;
      } else {
        completedStyle = activeStyle;
      }

    return (
        <>
            <hr />
            <DtactionsVendorbank />

            <hr />
            <DtactionPayout ></DtactionPayout>
            {action?.rejecthistory && action?.rejecthistory.length>0 ?
                <section class="section section-padding">
                    <div>
                        <h6 style={{ color: 'red', fontWeight: 'bold', fontSize: '30px' }}>{action.rejecthistory[action.rejecthistory.length - 1].reason}</h6>
                    </div>
                </section>
                : <>
                    <section class="section section-padding" >
                        <div class="blue-heading">Payment Details</div>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Advance Payable %</Col3>
                            <Col5>
                                <input className="input" type="number" min="0" max="100"
                                    value={action.advancePayablePercent}
                                    onChange={(ev) => {
                                        action.advancePayablePercent = ev.target.value
                                    }} />
                            </Col5>
                            <Col5>
                            {
                               entityData?.funded_Id && entityData?.paymentEnable == "ON" && !action.utrNo &&  RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) && displayStatus=="OFF" ?  <button
                               className="button is-link"
                               style={{
                                 border: "1px solid #ffffff",
                                 borderRadius: "8px",
                                 height: "35px",
                                 color: "white",
                                 // left: "14em",
                                 position: "relative",
                                 width: "150px",
                                 marginLeft: "10%"
                               }}
                              // {...(isViewMode() ? { disabled: true } : {})}
                               onClick={async (event) => {
                                   dtactionStore.modalVisible1 = true
                               }}
                               >
                               Make Payment
                             </button>:null
                            }
                              {
                               entityData?.funded_Id && entityData?.paymentEnable == "ON" && action.status=="PENDING" &&  RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.Razor_Pay) ? 
                               <span>
                                <span class="blue-heading" style={{ marginLeft: "5%"}}>Enter Manually:</span>
                               <button className="button is-small"
                                    onClick={async () => {
                                     await EntityApi.manualEnable({
                                        bookingRef:booking._id,
                                        actionName:action._id.actionName,
                                        displayStatus:"ON"
                                     })
                                     action.manualEnable="ON"
                                     setDisplayStatus('ON')
                                   }}
                                  style={{ borderRadius: '25px 0px 0px 25px',marginLeft: "5%",

                                  ...pendingStyle}}
                                   >ON</button>
                               <button className="button is-small"
                                   onClick={async() => 
                                    { 
                                     await EntityApi.manualEnable({
                                        bookingRef:booking._id,
                                        actionName:action._id.actionName,
                                        displayStatus:"OFF"
                                   })
                                   action.manualEnable="OFF"
                                   action.utrNo=""
                                   action.paymentAmt=0
                                   action.paymentDate=''
                                   setDisplayStatus('OFF')}}
                                  style={{ borderRadius: '0px 25px 25px 0px' ,                               ...completedStyle }}
                                   >OFF</button>
                           </span>:null                             }
                        
                        </Col5>   
                        </Cols>
                        <Cols>
                         
                            <Col3 style={{ color: 'darkblue' }}>Advance Payable Amount</Col3>
                            <Col5>{Utils.toNum(action.booking.vendor_agreed_cost) * Utils.toNum(action.advancePayablePercent) / 100}</Col5>

                        </Cols>

                    </section>
                    {(action?.status=="PENDING" && (action?.manualEnable=="ON")) || (action.status=="COMPLETED" && (action.manualEnable=="OFF"|| action.manualEnable=="ON"|| !action.manualEnable))|| (action?.status=="PENDING" && entityData?.paymentEnable=="OFF") || (!entityData?.paymentEnable && !entityData?.manualEnable) ? <section class="section section-padding" >
                        <div class="blue-heading">Please Enter Payment Details</div>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>UTR No.</Col3>
                            <Col5>
                            { (action.status=="PENDING" && (action.manualEnable == "ON" || action.manualEnable == "OFF" || !action.manualEnable )) ? <input className="input"
                                    value={action.utrNo}
                                    onChange={(ev) => {
                                        action.utrNo = ev.target.value
                                    }}
                                     />: <input className="input"
                                    value={action.utrNo}
                                    // onChange={(ev) => {
                                    //     action.utrNo = ev.target.value
                                    // }}
                                    disabled />
                                    }
                               
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Payment Amount</Col3>
                            <Col5>
                            {
                                         (action.status=="PENDING"&& (action.manualEnable == "ON" || action.manualEnable == "OFF" || !action.manualEnable ))    ? (
                                            <div class="field">
                                            <div class="input-d-wrapper">
                                                <input
                                                className="input"
                                                type="number"
                                                value={action.paymentAmt}
                                                onChange={(ev) => {
                                                    action.paymentAmt = ev.target.value;
                                                }}
                                                />
                                                ;
                                                <div class="input-dropdown">
                                                {"INR"}
                                                {/* <img
                                                                src="./assets/chevron-white.png"
                                                                alt="chevron"
                                                                class="chevron-3"
                                                            /> */}
                                                {/* <div class="currency-div d-none">
                                                                <ul>
                                                                <li>INR</li>
                                                                <li>USD</li>
                                                                <li>DNR</li>
                                                                <li>EUR</li>
                                                                <li>DIR</li>
                                                                <li>QSD</li>
                                                                </ul>
                                                            </div> */}
                                                </div>
                                            </div>
                                            </div>
                                        ) : (
                                            <div class="field">
                                            <div class="input-d-wrapper">
                                                <input
                                                className="input"
                                                value={action.paymentAmt}
                                                //onChange={handleChange}
                                                disabled
                                                />
                                                ;
                                                <div class="input-dropdown">
                                                {"INR"}
                                                {/* <img
                                                                src="./assets/chevron-white.png"
                                                                alt="chevron"
                                                                class="chevron-3"
                                                            /> */}
                                                {/* <div class="currency-div d-none">
                                                                <ul>
                                                                <li>INR</li>
                                                                <li>USD</li>
                                                                <li>DNR</li>
                                                                <li>EUR</li>
                                                                <li>DIR</li>
                                                                <li>QSD</li>
                                                                </ul>
                                                            </div> */}
                                                </div>
                                            </div>
                                            </div>
                                        )
                                    }
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Payment Date</Col3>
                            <Col5>
                                {(action.status=="PENDING"&&(action.manualEnable == "ON" || action.manualEnable == "OFF"|| !action.manualEnable  ))   ? 
                                <input
                                    className="input"
                                    type="date"
                                    value={action.paymentDate}
                                    onChange={(ev) => {
                                        action.paymentDate = ev.target.value;
                                    }}
                                />:<input
                                className="input"
                                type="date"
                                value={action.paymentDate}
                                onChange={(ev) => {
                                  action.paymentDate = ev.target.value;
                                }}
                                disabled
                              />}
                                
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col3 style={{ color: 'darkblue' }}>Remarks</Col3>
                            <Col5>
                               {action.status=="PENDING"&&(action.manualEnable == "ON" || action.manualEnable == "OFF" || !action.manualEnable  )    ? 
                                <textarea className="textarea" rows="5"
                                value={action.remarks}
                                onChange={(ev) => {
                                    action.remarks = ev.target.value
                                }} 
                                ></textarea>:
                                <textarea className="textarea" rows="5"
                                value={action.remarks}
                                onChange={(ev) => {
                                    action.remarks = ev.target.value
                                }} 
                                disabled></textarea>}
                            </Col5>
                        </Cols>
                    </section>:null}
                   
                    
                    </>
                    }

        </>
    )
}