
const Columns = ({children}) => <div className="columns">{children}</div>
const Cols = (props) => <div className="columns" {...props}>{props.children}</div>
const Col1 = (props) => <div className="column is-1" {...props}>{props.children}</div>
const Col2 = (props) => <div className="column is-2" {...props}>{props.children}</div>
const Col3 = (props) => <div className="column is-3" {...props}>{props.children}</div>
const Col4 = (props) => <div className="column is-4" {...props}>{props.children}</div>
const Col5 = (props) => <div className="column is-5" {...props}>{props.children}</div>
const Col6 = (props) => <div className="column is-6" {...props}>{props.children}</div>
const Col7 = (props) => <div className="column is-7" {...props}>{props.children}</div>
const Col8 = (props) => <div className="column is-8" {...props}>{props.children}</div>
const Col9 = (props) => <div className="column is-9" {...props}>{props.children}</div>
const Col10 = (props) => <div className="column is-10" {...props}>{props.children}</div>
const Col11 = (props) => <div className="column is-11" {...props}>{props.children}</div>
const Col12 = (props) => <div className="column is-12" {...props}>{props.children}</div>
const Col = ({children}) => <div className="column">{children}</div>

export { Columns, Cols, Col, Col1, Col2, Col3, Col4, Col5, Col6, Col7, Col8, Col9, Col10, Col11, Col12 }

export function PlainControl({name, value, setValue = f => f }) {
    return (
        <div className="field">
            <div className="control is-fullwidth">
                <input name={name}  defaultValue={value} onBlur={(e) => setValue(name, e.target.value)} 
                    className="input is-small" 
                    style={{border: 'none'}}
                    type="text"/>
            </div>
        </div>
    )
}
export function InputControl({type, name, label, value, setValue }) {
    return (
        <div className="field">
            <label className="label is-small" style={{marginBottom: '0px'}}>{label}</label>
            <div className="control is-fullwidth">
                <input name={name} value={value} onChange={(e) => setValue(name, e.target.value)} 
                    className="input is-small" 
                    type={type} placeholder={label} />
            </div>
        </div>
    )
}
export function TextareaControl({name, label, value, setValue}) {
    return (
        <div className="field">
        <label className="label is-small"  style={{marginBottom: '0px'}}>{label}</label>
        <div className="control">
            <textarea name={name} value={value} onChange={(e) => setValue(name, e.target.value)}  
                className="textarea is-small" placeholder={label}></textarea>
        </div>
    </div>
    )
}
export function CheckboxControl({name, label, value, setValue}) {
    return (
        <div className="field">
        <div className="control">
            <label className="checkbox">
                <input name={name} value={value}
                    onChange={(e) => setValue(name, e.target.value)}  
                    type="checkbox" style={{marginRight: '5px'}}/>
                {label}
            </label>
        </div>
    </div>
    )
}
export function SelectControl({name, label, value, setValue, dataList}) {
    console.log('inside select=', name, label, value)
    return (
        <div className="field">
            <label className="label is-small"  style={{marginBottom: '0px'}}>{label}</label>
            <div className="control">
                <div className="select is-small">
                    <select name={name} value={value} onChange={(e) => setValue(name, e.target.value)} >
                        {dataList.map(val => <option key={val}>{val}</option>)}
                    </select>
                </div>
            </div>
        </div>
    )
}
