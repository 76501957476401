import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import HomeList from "./home-list"
import HomeScreen from "./home-screen"
import { useEffect } from "react"
import Newapi from "../state/new-api"
import { useGlobalState } from "../GlobalState"

export default function HomeMain() {
    const a = useSnapshot(configStore)
    const { currentMenu } = useGlobalState()
    useSnapshot(contractsStore)
    useEffect(() => {
        if (!contractsStore.navigateFromCharts && (currentMenu == "Upload Schedules" || currentMenu == "Upload Schedulesv2" || currentMenu == "Create Bookingv2" || currentMenu == "Create Booking")) Newapi.getContracts()
    },[configStore.currentSelectedEntity])
    console.log("the config store home state ",configStore.homescreenCurrentView)
    switch(configStore.homescreenCurrentView) {
        case 'list':
            configStore.displaySchedules = false 
            return <HomeList/>
        case 'view':    
            //configStore.displaySchedules = true
            return <HomeScreen viewonly='1'/>
        case 'new': return <HomeScreen/>
    }
}