import { Box, IconButton, Typography } from "@mui/material";
import editIcon from '../assets/images/EditIcon1.svg'
import rightIcon from '../assets/images/RightIcon.svg'
import { useState } from "react";
import { OutlinedInput } from "@mui/material";

export function KeyDescription({ title, editLabel, isEditable }) {
    console.log(isEditable,"isEditable");
    const [edit, setEdit] = useState(false)
    const [fieldError,setFieldError] = useState(false)
    const [label, setLabel] = useState(title)
    return <Box sx={{ minWidth: "245px", maxWidth: "245px", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 1, wordBreak: "break-word" }} >
        {!edit ? <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', '&:hover .edit-icon': { display: 'flex' } }}>
            {
                isEditable ? <><Typography sx={{ '&:hover': { textDecoration: 'underline', }, fontFamily: "Figtree", fontSize: "14px", color: "black" }}>{title}</Typography>
                    <IconButton className="edit-icon" sx={{ display: 'none', borderRadius: '0px 4px 4px 0px', '&:hover': { backgroundColor: 'transparent' } }}
                        onClick={() => {
                            setEdit(true)
                        }}>
                        <img src={editIcon} alt="" />
                    </IconButton></> :
                    <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", color: "black" }}>{title}</Typography>
            }
        </Box> : <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <OutlinedInput sx={{
                '& input': {
                    padding: '0px 10px'
                }, borderRadius: '7px 0px 0px 7px',
                width: '200.36px',
                height: '30.01px'
            }}
                placeholder="Enter Document Name"
                value={label}
                error={fieldError}
                onChange={(e) => {
                    setLabel(e.target.value)
                }} />
            <IconButton sx={{ '&:hover': { backgroundColor: '#2B6ED4', }, width: '35px', height: '30px', backgroundColor: '#2B6ED4', borderRadius: '0px 7px 7px 0px' }}
                onClick={() => {
                    if(label !== ''){
                        editLabel(label)
                        setEdit(false)
                        setFieldError(false)
                    }else{
                        setFieldError(true)
                    }
                }}>
                <img src={rightIcon} alt="" />
            </IconButton></Box>
        }
        <Typography>:</Typography>
    </Box >
}