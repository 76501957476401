import { useSnapshot } from "valtio"
import { useState, useEffect } from "react"
import {
    aguserStore, configStore, entityStore
} from '../state/CommonState'
import EntityApi from "../state/entity-api"
import Select from 'react-select';

const customStyles = {
    option: (provided) => ({
      ...provided,
      background: '#fff',
      color: '#363636'
    }),
  };

export default function UserEntity({ onValidationComplete }) {
    let [space, setSpace] = useState('')
    let [newEntity, setNewEntity] = useState(null)
    let [clearEntity, setclearEntity] = useState(false)
    let [newRole, setNewRole] = useState('CLIENT USER') 
    useSnapshot(aguserStore)
    useSnapshot(entityStore)
    const aguser = aguserStore.aguserRec
    const [entityList, setEntityList] = useState(entityStore.list)
    let prevGst = ''
    let entityElem = {}
    const getEntityForGst = (gst) => {
        if (gst === prevGst) return entityElem
        let data = entityList.filter(e => e.gst === gst)
        if (data.length > 0) entityElem = data[0]
        else entityElem = {}
        prevGst = gst
        return entityElem
    }

    const setEntityListWrapper = (list) => {

    }
    useEffect(() => {
        EntityApi.getList((data) => {
            if (Object.keys(data).length > 0) {
                let l = [];
                for (let i = 0; i < data.length; i++) {
                    l.push({gst:data[i]['gst'],label:data[i]['entityName'],state:data[i]['state']})
                }
                setEntityList(l)
            }
        })
        
    }, [])

    useEffect(() =>{
        onValidationComplete(newEntity)
    }, [newEntity])

    const handleEntity = (e) => {
        setNewEntity(e)
        setclearEntity(true)
        
    }
    let filterEntity;
    if(aguser.entityList && aguser.entityList.length > 0 && entityList && entityList.length > 0){
        filterEntity = entityList.filter( obj1 => !aguser.entityList.find(obj2 => obj1.gst===obj2.gst));
    }else{ 
        filterEntity = entityList;
    }
    const deleteRec = (email, gst) => {
        const data = aguser.entityList.filter(e => (e.gst != gst))
        aguser.entityList = [...data]
    }
    console.log('aguser.entitylist =', aguser.entityList)
    aguser.entityList.map((e, i) => console.log(e.gst))
    return (
        <>{space}
            <hr />
            <div>
                <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th style={{width: '60%'}}>Entity</th>
                            <th style={{width: '17%'}}>GST</th>
                            <th style={{width: '15%'}}>Role</th>
                            <th style={{width: '8%'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            aguser.entityList.map((e, i) => {
                                return <tr key={i}>
                                    <td>{getEntityForGst(e.gst).label} - {getEntityForGst(e.gst).state}</td>
                                    <td>{e.gst}</td>
                                    <td>{e.role}</td>
                                    <td>
                                        <div className="field has-addons">
                                            <p className="control">
                                                <button className="button is-danger is-inverted"
                                                    onClick={() => {
                                                        deleteRec(aguser.email, e.gst)
                                                        setSpace(space === '' ? ' ' : '')
                                                    }}>
                                                    <span className="icon">
                                                        <i className="fas fa-trash"></i>
                                                    </span>
                                                </button>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            }
                            )
                        }
                        <tr style={{ border: '2px solid grey' }}>
                            <td >
                            <div>
                                {
                                    filterEntity !== null ? <Select
                                    value={newEntity}
                                    menuPlacement="top"
                                    menuPosition="absolute"
                                    onChange={handleEntity}
                                    options={filterEntity}
                                    isClearable={clearEntity}
                                    isSearchable={true}
                                    placeholder="Select an option"
                                    styles={customStyles}

                                /> : ""
                                }
                                
                            </div>
                            </td>
                            <td>{ newEntity === null ? "" : newEntity.gst}</td>
                            <td>
                                <div className="select">
                                    <select value={newRole}
                                        onChange={(e) => setNewRole(e.target.value)} >
                                        <option key='CLIENT USER'>CLIENT USER</option>
                                        <option key='CLIENT ADMIN'>CLIENT ADMIN</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="field has-addons">
                                    <p className="control">
                                        <button className="button is-success is-inverted"
                                            onClick={() => {
                                                if(!newEntity){
                                                    configStore.setModalMessage('Please Select An Entity')
                                                }else{
                                                    let data = aguser.entityList.filter(e => e.gst === newEntity.gst)
                                                    if (data || data.length === 0) {
                                                        let obj = {
                                                            email: aguser.email,
                                                            gst: newEntity === null ? "" : newEntity.gst,
                                                            role: newRole
                                                        }
                                                        aguser.entityList.push(obj)
                                                    } else {
                                                        configStore.setModalMessage('Entity already added for user')
                                                    }
                                                    setNewEntity(null)
                                                    setSpace(space === '' ? ' ' : '')
                                                }
                                            }}>
                                            <span className="icon is-small">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </button>
                                    </p>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
