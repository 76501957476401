import { useSnapshot } from "valtio";
import { quoteDataRec } from "./state/CommonState";

export default function ConsolidatorView(_props) {
  const imQuoteDataRec = useSnapshot(quoteDataRec);
  return (
    <>
      <hr />
      <div className="table-container">
        <table className="table is-bordered is-striped is-narrow is-hoverable ">
          <thead>
            <tr>
              <th>
                <h3 className="is-size-5 has-text-centered">Consolidators</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {imQuoteDataRec.consolidatorList.map((consolidator) => (
              <tr key={consolidator.scacCode}>
                <td>
                  {`${consolidator.consolidatorName}`}{"\n"}
                  <b>{`( ${consolidator.scacCode} )`}</b>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
