import { useSnapshot } from "valtio";
import { routerStore } from "../../state/CommonState";
import BranchesList from "./list-branch";
import EditBranch from "./edit-branch";
export default function MainBranch(fn,ch) {
  const store = useSnapshot(routerStore);
  if (fn.ch) {
    return <EditBranch mode="edit" ch={fn}/>;
  }
  switch (store.branchCurrentView) {
    case "list":
      return <BranchesList fn={fn} />;
    case "view":
      return <EditBranch mode="view" />;
    case "edit":
      return <EditBranch mode="edit" />;
    case "new":
      return <EditBranch mode="new" />;
    default:
      return <BranchesList />;
  }
}
