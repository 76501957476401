import { Box, Button, Checkbox, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import { AntSwitch } from '../mui-components/AntSwitch'
import logo from "../assets/images/Logo-new.svg"
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import Carousel from '../mui-components/Carousel'
import { useForm } from 'react-hook-form'
import Api from '../state/Api'
import { aguserStore, configStore, loginStore } from '../state/CommonState'
import AguserApi from '../state/AguserApi'
// import { useMode } from '../context/AuthContext'
function  ForgotPasswordNew({setuserMail,callback}) {
  // const {setMode : callback} = useMode();
  const [error, setError] = useState("")
  const ForgotForm = useForm({
    defaultValues:{
        email:"",
    }
})
const {handleSubmit,register,formState:{errors},control,getValues,setValue,watch,reset} = ForgotForm;

  const handleChange = (type,e)=>{
    console.log(e.target.value);
    if (type === "email") {
      let emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let ok =   emailCheck.test(e.target.value)
      console.log(ok);
      ok?setError(""):setError("Invalid email address")
    }
  }
  const submit =async (data)=>{
    console.log(data.email);
    try {
      
      await AguserApi.forgotPasswordAguserNew({email:data.email.toLowerCase()}, (user)=>{
        console.log(user);
        setuserMail(data.email)
        callback("mailConfirm")
        //  configStore.setModalMessage("Your Account successfully created. Please check your E-Mail.");
        }
      )
    
  } catch (error) {
    console.log(error);
     configStore.setModalMessage("Something went wrong")
  }
  }
  return (
    <>
  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade! </Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
    <div className='logo_container_for_mobile'>
         <img src={logo}></img>
    </div>
      <div className='field_wrapper'>
      <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={".4rem"} gutterBottom>Reset your password</Typography>
      <Typography variant="h6" fontFamily={"Figtree, sans-serif"} fontSize={"12px"} fontWeight={400} color={"#555"} marginBottom={"1rem"} gutterBottom>Enter the email address you used to register with.</Typography>
      <InputField 
      placeholder={"Enter Email"}
      label={"Email"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.email?.message || error}
      input_wrapeer={"input_wrapeer"}
      callback={(e)=>console.log(e)}
      type={"email"}
      children={{...register("email",{onChange:(e)=>handleChange("email",e),required: "Email is Required",
      pattern:{
        value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message : `Invalid email address`
      }})}}
      ></InputField>
        <Button variant="contained" className='btn_color' fullWidth onClick={handleSubmit(submit)} 
        // onClick={()=>{
        //     callback('mailConfirm')
        //   }}
          >Send Email</Button>
      </div>
      
      <p className='note_info'>Remember your password? <span className='clr_blue' onClick={()=>{
            callback('login')
          }}>Login</span></p>
    </div>

  </div>
    
    </>
  )
}


export default ForgotPasswordNew