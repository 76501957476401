import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import Dtinput from "./cps/Dtinputs";
import DtApi from "../state/dt-api";

export function DtupdateDriverNum() {
    useSnapshot(DtStore)
    let dtStore = DtStore.DTBooking
    const checkdrivernumstatus = async ()=>{
        if(DtStore.updatenumer.number.trim().length == 10){
            let callback = (data)=>{
                DtStore.updatenumer.message = data.data
            }
            await DtApi.getdrivernumstatus(DtStore.updatenumer.number.trim(),dtStore._id,callback)
        }else{
            DtStore.updatenumer.message = "Please Check deriver Number"
        }
    }
    const close = ()=>{
        DtStore.modalVisible1 = false
    }
    const Update = async ()=>{
        dtStore.drivernumber = DtStore.updatenumer.number
        DtApi.postUpdateDtBooking(dtStore, close)
    }
    let modalClass = 'modal'
    if (DtStore.modalVisible1) modalClass += ' is-active'
    return (
        <>
            {
                (DtStore.modalVisible1) ? <div className={modalClass} onClick={() => { DtStore.modalVisible1 = false }}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                        <section className="modal-card-body">
                        <div class="columns m-1 is-vcentered is-multiline">
                        <div class="column is-12 pb-0 mb-0">
                            <b><h3 className="title is-5">Update Driver Number</h3></b>
                        </div>
                        <div class="column is-3">
                            Driver Phone:
                        </div>
                        <div class="column is-3">
                            <Dtinput type="number" callback={()=>{
                                if(DtStore.updatenumer.message=="ALLOWED"){
                                    DtStore.updatenumer.message = ""
                                }
                            }} record={DtStore.updatenumer} name="number" />
                        </div>
                        <div class="column is-4">
                            <p>{DtStore.updatenumer.message}</p>
                        </div>
                    </div>
                            <button class="button is-warning" onClick={() => DtStore.modalVisible1 = false} >cancel</button>
                            {
                                DtStore.updatenumer.message!="ALLOWED"?<button class="button is-danger" style={{ float: "right" }} onClick={checkdrivernumstatus}>Check Status</button>:
                                <button class="button is-danger" style={{ float: "right" }} onClick={Update}>Update</button>
                            }
                            
                        </section>
                    </div>
                </div> : <></>
            }
        </>
    )
}