import { Box, Typography } from "@mui/material";
import { AttributeDetails } from "../mui-components/AttributeValueBox";
import Utils from "../util/Utils";
import { useSnapshot } from "valtio";
import { viewBookingStore } from "../state/CommonState";
import { dsrstatic_cn } from "../my-actions/dsr_static";





export function BookingContainerDetail() {
    const booking = viewBookingStore.current;

    

    return <Box sx={{ display: "flex", flexDirection: "column",gap:1,paddingX:"10px" }}>
        {booking.containerlist.map((containerdetail, index) => <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Container {index + 1}<span style={{ fontFamily: "Figtree", color: "#555555" }}> : {containerdetail.container_types}</span></Typography>
            <AttributeDetails details={Object.entries(containerdetail).map((detail)=>({title:dsrstatic_cn[detail[0]],
                value:
                Utils.isValidDate1(detail[1]) ?
                Utils.formatDateBooking(detail[1],false)
                :detail[1]
                }))} />
        </Box>)}
    </Box>
}
