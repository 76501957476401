import { routerStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import ListAgusers from "./list-agusers"
import EditAguser from "./edit-aguser"
import ViewAguser from "./view-aguser"

export default function MainAguser() {
    const store = useSnapshot(routerStore)
    switch(store.agusersCurrentView) {
        case 'list': return <ListAgusers/>
        case 'view': return <ViewAguser/>
        case 'edit': return <EditAguser mode='edit'/>
        case 'new': return <EditAguser mode='new'/>
    }
}