import { subscribe, useSnapshot } from "valtio"
import { AgInput, AgSelect, TableEdit, TableSelect } from "../components/AgComponents"
import { useEffect, useState } from "react"
import { CountryMarkup, markupsStore, rtpStore } from "./pricing-store"
import { ServerLists } from "../StaticData"
import airlineCodes from "../static-data/airlines"

function ActionButtons(props) {
    useSnapshot(markupsStore)
    let line = props.line
    let len = props.length
    return (
        <div className="field has-addons">
            {
                (line !== len - 1) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...markupsStore.current.countryMarkup]
                                list.splice(line, 1)
                                markupsStore.current.countryMarkup = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                markupsStore.current.countryMarkup.push(new CountryMarkup())
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function AirpricingCountrymarkup(props) {
    useSnapshot(markupsStore)
    useSnapshot(rtpStore)

    let rec = rtpStore.selectedMarkupCharge

    return (
        <>
            <table className="table is-narrow is-bordered is-small"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        {rec.hasorigin && <th>Origin Country</th>}
                        {rec.hasreworking && <th>IATA</th>}
                        {rec.hasdestination && <th>Destination Country</th>}
                        <th>Markup</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        markupsStore.current.countryMarkup?.map((e, i) => (
                            <tr key={'' + i + e.origin}>
                                <td>
                                    {i + 1}
                                </td>
                                {
                                    rec.hasorigin &&
                                    <td>
                                        <TableSelect record={e} name='origin'
                                            dataList={ServerLists.countryNameList} />
                                    </td>
                                }
                                {
                                    rec.hasreworking &&
                                    <td>
                                        <select value={e.iata} onChange={(ev) => {
                                            e.iata = ev.target.value
                                        }}>
                                        {
                                            airlineCodes.map(e => <option value={e.code}>{e.name}</option>)
                                        }
                                        </select>
                                    </td>
                                }
                                {
                                    rec.hasdestination &&
                                    <td>
                                        <TableSelect record={e} name='destination'
                                            dataList={ServerLists.countryNameList} />
                                    </td>
                                }
                                <td>
                                    <TableEdit type='number'
                                        style={{ width: '4rem', textAlign: 'right' }}
                                        record={e} name='markup' />
                                </td>
                                <td>
                                    <ActionButtons line={i} length={markupsStore.current.countryMarkup?.length} />
                                </td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>

        </>
    )
}