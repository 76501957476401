
export function DtMapVisibilty({ isOpen, onClose, mapUrl }) {
    let modalClass = 'modal'
    if (isOpen) modalClass += ' is-active'
    return ( 
        <>
            {
                (isOpen) ? <div className={modalClass} onClick={onClose}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: "90%", height: "90%" }} >
                        <div style={{
                            position: "relative",
                            width: "100%",
                            overflow: "hidden",
                            paddingTop: "56.25%",
                            height: "100%"
                        }}>
                            <iframe style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                bottom: "0",
                                right: "0",
                                width: "100%",
                                height: "100%",
                                border: "none"
                            }} src={mapUrl}></iframe>

                        </div>
                        <button class="button is-warning" style={{
                            float: "right", float: "right",
                            position: "absolute",
                            zIndex: "10000000000",
                            left: "80%",
                            width: "20%",
                            height: "64px"
                        }} onClick={onClose} >Close</button>
                    </div>
                </div> : <></>
            }
        </>
    )
}