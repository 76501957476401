import { Box, Button } from "@mui/material";
import { UploadDocuments } from "../../mui-components/UploadDocuments";
import { taskStore } from "../../mytasks/task-store";
import { useState } from "react";
import { LoaderButton } from "../../mui-components/LoaderButton";

export function CIPLUpload(){
    const task = taskStore.current;
    let stage = task.stage;
    const [isLoading,setIsLoading] = useState(false);
    return <Box sx={{height:"100%",width:"100%",display:"flex",flexDirection:"column",paddingX:"15px",gap:3}}>
        {stage===1 && <>        <Box sx={{display:"flex",flex:1,width:"100%",overflowY:"auto", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2C358A !important"
            }}}>
        <UploadDocuments task={task} />
        </Box>
<Box sx={{width:"100%",display:"flex",flexDirection:"row",gap:1,justifyContent:"end",alignItems:"center"}}>
   <Box sx={{width:"245px",display:"flex",flexDirection:"row",gap:"25px"}}>
   <Button  sx={{textTransform:"capitalize",backgroundColor:"#F5F5F5",color:"#2B6ED4",borderRadius:"5px","&:hover":{
                    backgroundColor:"#F5F5F5",color:"#2B6ED4"
                  }}} onClick={()=>{}}>Cancel</Button>
   <LoaderButton buttonContent="Submit" enableLoader={isLoading}  />
   </Box>
</Box>
</>
}
    </Box>
}