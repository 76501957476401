import MonApi from "./state/mongo-api";
import { loader } from "./util/loader";
import { useSnapshot } from "valtio";
import { taskStore } from "./mytasks/task-store";
import { configStore } from "./state/CommonState";
import { useEffect } from "react";
import { useState } from "react";
export default function PaymentLoading(props) {
  const { id } = props;
  useSnapshot(taskStore);
  useSnapshot(loader)
  const [msg,setMsg] = useState("Please do not refresh or go back.")
  const [url,setUrl] = useState("")
  const [countdown,setCountdown] = useState(5)
  //loader.show()
  let checkstart
  let count = 5
  const check = async ()=>{
    if(countdown!=0){
      let transactionCheckSatusURL = `${process.env.REACT_APP_API_SERVER}/api/v1/integration/apiPhonePayCheckStatus/${id}`;
      let d = await MonApi.get(transactionCheckSatusURL)
      if(d.message!=undefined){
        switch (d.message) {
          case "COMPLETED":
            setMsg("Your payment was completed successfully.")
            break;
          case "PENDING":
            setMsg("Please wait while we complete your payment.")
            break;
          case "FAILED":
            setMsg("This transaction request has failed. If the amount was debited from your Bank Account, please rest assured as it will be refunded to you within 5 working days.")
            break;
        }
      }
      if (d?.status === "Success") {
        loader.hide();
        if(d?.url){
          clearInterval(checkstart)
          setUrl(d.url)
          const interval = setInterval(() => {
            count--
            if(countdown!=0){
              setCountdown(count);
            }
            if (count === 0) {
                clearInterval(interval);
                clearInterval(checkstart)
                window.location.href = d.url;
            }
        }, 1000);
         }
      }
    }
  }

  
  
  useEffect(()=>{
    loader.show()
    if(countdown==5){
      checkstart = setInterval(()=>{
        check()
      },5000)
    }
  },[])
  return (
    <div
      style={{
        backgroundColor: "#e8e8e8",
        width: "100%",
        height: " auto",
        bottom: "0px",
        top: " 0px",
        left: "0",
        position: "absolute",
      }}
    >
      <div style={{
            position: "absolute",
            width: "260px",
            height: "50px",
            top: "55%",
            left:" 45%",
            marginTop:"-80px",
            marginLeft:"-50px",
            
      }}>
        <div className="table-container" style={{backgroundColor:"transparent"}}>
                            <table className="table is-narrow is-fullwidth is-small" style={{backgroundColor:"transparent"}}
                                >
                                <thead style={{backgroundColor:"transparent"}}>

                                    <tr style={{backgroundColor:"transparent"}}>
                                        <th style={{backgroundColor:"transparent", borderWidth:"0px"}}>
                                          
                                          {msg}
                                          {url.length>0?<>
                                            <p style={{fontSize:"75%",fontWeight:"500"}}>Redirecting in <span>{countdown}</span> seconds...</p>
                                          <p style={{fontSize:"75%",fontWeight:"500"}}>If you are not redirected, <a href={url}>click here</a>.</p>
                                          </>:""}
                                          
                                          </th></tr></thead></table></div>
        </div>
    </div>
  );
}
