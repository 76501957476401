import { Box, Button, Checkbox, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import { AntSwitch } from '../mui-components/AntSwitch'
import logo from "../assets/images/Logo-new.svg"
import Mailconfirm from "../assets/images/email-confirm.png"
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import Carousel from '../mui-components/Carousel'
function MailConfirm({userMail}) {
  return (
    <>
{/* <Container maxWidth="xl" className='margintop'>
    <Grid container spacing={2} className='signIN_container'>
      <Grid item  xl={5}  >
        dc
      </Grid>
      <Grid item  xl={7}>
        dcd
      </Grid>
    </Grid>

</Container> */}

  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
      <div className='field_wrapper'>
      <div className='mail_container'>
      <img src={Mailconfirm}></img>
      </div>
      <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={"1rem"} gutterBottom>We have sent a password reset mail 
to your <span className='colorGray'>{userMail}</span>.</Typography>
      </div>
      
      <p className='note_info'>Did not receive the email ? Check your spam folder
          </p>
    </div>

  </div>
    
    </>
  )
}


export default MailConfirm

