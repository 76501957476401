import { subscribe, useSnapshot } from "valtio"
import { AgInput, AgSelect, TableEdit, TableSelect } from "../components/AgComponents"
import { useEffect, useState } from "react"
import { CountryMarkup, CountryMarkupv2, markupsStore, rtpStore } from "./pricing-store"
import { ServerLists } from "../StaticData"
import airlineCodes from "../static-data/airlines"

function ActionButtons(props) {
    useSnapshot(markupsStore)
    let line = props.line
    let len = props.length
    return (
        <div className="field has-addons">
            {
                (line !== len - 1) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...markupsStore.freightify.countryMarkup]
                                list.splice(line, 1)
                                markupsStore.freightify.countryMarkup = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                markupsStore.freightify.countryMarkup.push(new CountryMarkupv2())
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function FreightifyCountryMarkup(props) {
    useSnapshot(markupsStore)
    useSnapshot(rtpStore)

    return (
        <>
            <table className="table is-narrow is-bordered is-small"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Origin Country</th>
                        <th>Destination Country</th>
                        <th colSpan={2}>Mark up for Per Container</th>
                        <th colSpan={2}>Mark up for Per BL</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Percentage</th>
                        <th>Value in USD</th>
                        <th>Percentage</th>
                        <th>Value in USD</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        markupsStore.freightify.countryMarkup?.map((e, i) => (
                            <tr key={'' + i + e.origin}>
                                <td>
                                    {i + 1}
                                </td>
                                <td>
                                    <TableSelect record={e} name='origin'
                                        dataList={ServerLists.countryNameList} />
                                </td>

                                <td>
                                    <TableSelect record={e} name='destination'
                                        dataList={ServerLists.countryNameList} />
                                </td>
                                <td>
                                    <TableEdit type='number'
                                        style={{ width: '4rem', textAlign: 'right' }} record={e}
                                        parent={e.markup.perUnit} name='percent' makezero='value'/>
                                </td>
                                <td>
                                    <TableEdit type='number'
                                        style={{ width: '4rem', textAlign: 'right' }} record={e}
                                        parent={e.markup.perUnit} name='value' makezero='percent'/>
                                </td>
                                <td>
                                    <TableEdit type='number'
                                        style={{ width: '4rem', textAlign: 'right' }} parent={e.markup.perShipment}
                                        record={e} name='percent' makezero='value'/>
                                </td>
                                <td>
                                    <TableEdit type='number'
                                        style={{ width: '4rem', textAlign: 'right' }} parent={e.markup.perShipment}
                                        record={e} name='value' makezero='percent'/>
                                </td>
                                <td>
                                    <ActionButtons line={i} length={markupsStore.freightify.countryMarkup?.length} />
                                </td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>

        </>
    )
}