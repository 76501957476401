import { useSnapshot } from "valtio"
import {
    quoteDataRec, airChargesStore, loginStore, bookingsStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { contractsStore } from "../state/CommonState"
import { TransportTable1 } from "../enquiry/LCLChargeClientView"
import ChargeTag from "../pricing/charges-tag"
import { rtpStore } from "../pricing/pricing-store"

export default function AirChargesClientView(props) {
    let { chargesName } = props
    if (!chargesName) chargesName = 'chargesList'
    useSnapshot(loginStore)
    useSnapshot(contractsStore)
    useSnapshot(bookingsStore)
    let rec = contractsStore.current
    if (props.dummyContract) rec = props.dummyContract
    if (!rec.terms) {
        rec.terms = {}
        rec.terms.your = []
        rec.terms.shipper = []
        rec.terms.consignee = []
    }
    let terms = rec.terms.your
    let heading = 'On Your Account'
    let heading1 = 'Your Account Total'
    let subscript = -1
    if (chargesName == 'chargesList') {
        if (props.subscript >= 0) subscript = props.subscript
    }
    switch (chargesName) {
        case 'shipperchargesList':
            heading = `On Your Shipper's Account`
            heading1 = `Shipper's Account Total`
            terms = rec.terms.shipper
            break
        case 'consigneechargesList':
            heading = `On Your Consignee's Account`
            heading1 = `Consignee's Account Total`
            terms = rec.terms.consignee
            break
    }
    const Tn = ({ i, name }) => {
        let list = rec[chargesName]
        const e = list[i]
        console.log(name, e.currency, e[name])
        let num = e[name]
        num = isNaN(num) ? 0.0 : Number(num)
        return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
    }

    let counter = 1
    let finalTotal = 0.0

    rec[chargesName].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
    })
    /*return <div>
        {
            JSON.stringify(rec.chargesList)
        }
    </div>*/
    if (loginStore.isClient() && rtpStore.pricingSourceScreen != 'booking') {
        return (
            <div style={{ display: 'block' }}>
                <div style={{ width: '100%', fontSize: '1.1rem', fontWeight: 'bold', display: 'flex' }}>
                    <p style={{ width: '50%', textAlign: 'left' }}>{heading1}</p>
                    {
                        subscript >= 0 ?
                        <div style={{ width: '50%', textAlign: 'right' }}>{Utils.N(rec.quoteValue - Utils.toNum(rec.quoteValueDeduction))}</div>
                        :
                        <div style={{ width: '50%', textAlign: 'right' }}>{Utils.N(finalTotal)}</div>
                    }
                </div>

                <div style={{ width: '100%', textAlign: 'right', color: 'blue' }}>
                    Taxes & Duties additional, as applicable
                </div>
                {
                    terms.map(e => <div style={{ width: '100%', textAlign: 'right', color: 'grey' }}>
                        {e}
                    </div>)
                }
                <div className="columns" style={{ width: '100%', backgroundColor: '#eee', marginTop: '1rem' }}>
                    <div className="column is-2" style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Inclusions</div>
                    <div className="column is-10 tags are-normal">
                        {
                            (rec.quoteValueDeduction <= 0) &&
                            <>
                                {
                                    rec[chargesName].filter(e => e.chargeDescription != 'Volume Discount').map((e, i) => {
                                        return <ChargeTag charge={e} subscript={subscript} />
                                    })
                                }
                            </>
                        }
                        {
                            (rec.quoteValueDeduction > 0) &&
                            <>
                                {
                                    rec[chargesName].filter(e =>
                                        e.chargeDescription != 'Volume Discount' &&
                                        e.chargeDescription != rec.hawbChargeHead &&
                                        e.chargeDescription != rec.agentChargeHead).map((e, i) => {
                                            return <ChargeTag charge={e} subscript={subscript} />
                                        })
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='8'>
                                <h3 className="is-size-5 has-text-centered">{heading}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th colSpan='2' className="has-text-centered">Per Unit</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th style={{ width: '20rem' }}>Charge Description</th>
                            <th>Currency</th>
                            <th style={{ textAlign: 'center' }}>Per Kg</th>
                            <th style={{ textAlign: 'center' }}>Minimum</th>
                            <th style={{ textAlign: 'center' }}>Per AWB</th>
                            <th style={{ textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rec[chargesName].map((e, i) => {
                                if (rec.quoteValueDeduction <= 0 || (
                                    e.chargeDescription != rec.hawbChargeHead &&
                                    e.chargeDescription != rec.agentChargeHead
                                ))
                                    return <tr key={e.id}>
                                        <td>{
                                            (e.transportRec) ?
                                                <div className="transporttooltip">{e.chargeDescription}
                                                    <span className="transporttooltiptext"><TransportTable1 data={e.transportRec} /> </span>
                                                </div>
                                                :
                                                e.chargeDescription
                                        }</td>
                                        <td>{e.currency}</td>
                                        <td style={{ textAlign: 'center' }}><Tn i={i} name='perKg' /></td>
                                        <td style={{ textAlign: 'center' }}><Tn i={i} name='minimum' /></td>
                                        <td style={{ textAlign: 'center' }}><Tn i={i} name='perAwb' /></td>
                                        <td style={{ textAlign: 'right' }}>{Utils.N(e.total, e.currency)}</td>
                                    </tr>
                            }
                            )
                        }
                        <tr>
                            <td colSpan={5}></td>
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(finalTotal - Utils.toNum(rec.quoteValueDeduction))}</strong></td>
                        </tr>
                        <tr>
                            <td colSpan={6} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
