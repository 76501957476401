import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import HomeScreenLCLv2 from "./home-screen-lcl-v2"

export default function HomeScreenv2(props) {
    useSnapshot(contractsStore)
    const rec = contractsStore.current
    //if (!rec || !rec.shipmentType) return <></>
    const stype = configStore.homescreenCurrentShipmentType
    console.log(props,"****",stype);
    switch(stype) {
        case 'LCL': return <HomeScreenLCLv2 shipmentType={stype} {...props}/>
        case 'FCL': return <HomeScreenLCLv2 shipmentType={stype} {...props}/>
        case 'Air': return <HomeScreenLCLv2 shipmentType={stype} {...props}/>
    }
}