import { useSnapshot } from "valtio";
import { markupsStore, rtpStore } from "./pricing-store";
import { Col1, Col12, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import PricingMarkupTable1 from "./pricing-markup-table1";
import PricingCountrymarkup from "./pricing-countrymarkup";
import PricingPortsmarkup from "./pricing-portsmarkup";
import MonApi from "../state/mongo-api";
import { useEffect } from "react";
import AirpricingPortsmarkup from "./airpricing-portsmarkup";
import AirpricingCountrymarkup from "./airpricing-countrymarkup";
import FreightifyCountryMarkup from "./freightify.country.markup";
import { configStore } from "../state/CommonState";

export default function FCLpricingMarkupEdit(props) {
    useSnapshot(rtpStore)
    useSnapshot(markupsStore)
    useEffect(() => {
        const f = async () => {
            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/markup/freightify`
            let ret = await MonApi.aget(url)
            if (ret.markup) markupsStore.freightify = ret.markup
            console.log('GOT RET AS=', ret, '\n==================\n\n')
        }
        f()
    }, [])
    let rec = rtpStore.selectedMarkupCharge

    return (
        <>
            <Cols style={{ fontFamily: 'Verdana, sans-serif', textAlign: 'center' }}>
                <Col2 style={{ paddingTop: '1rem' }}>
                    <h4 className="title is-5">
                        Ocean Freight
                    </h4>
                </Col2>
                <Col4>
                    <Cols>
                        <Col12 style={{ fontWeight: 'bold' }}>Mark Up for Per Container</Col12>
                    </Cols>
                    <Cols>
                        <Col6>Percentage</Col6>
                        <Col6>Value in USD</Col6>
                    </Cols>
                    <Cols>
                        <Col6>
                            <input type="number" className="input is-small"
                                value={markupsStore.freightify.defaultMarkup.perUnit.percent}
                                style={{ width: '5rem' }}
                                onChange={(ev) => {
                                    markupsStore.freightify.defaultMarkup.perUnit.percent = ev.target.value
                                    markupsStore.freightify.defaultMarkup.perUnit.value = 0
                                }} />
                        </Col6>
                        <Col6>
                            <input type="number" className="input is-small"
                                value={markupsStore.freightify.defaultMarkup.perUnit.value}
                                style={{ width: '5rem' }}
                                onChange={(ev) => {
                                    markupsStore.freightify.defaultMarkup.perUnit.value = ev.target.value
                                    markupsStore.freightify.defaultMarkup.perUnit.percent = 0
                                }} />
                        </Col6>
                    </Cols>
                </Col4>
                <Col4>
                    <Cols>
                        <Col12 style={{ fontWeight: 'bold' }}>Mark Up for Per BL</Col12>
                    </Cols>
                    <Cols>
                        <Col6>Percentage</Col6>
                        <Col6>Value in USD</Col6>
                    </Cols>
                    <Cols>
                        <Col6>
                            <input type="number" className="input is-small"
                                value={markupsStore.freightify.defaultMarkup.perShipment.percent}
                                style={{ width: '5rem' }}
                                onChange={(ev) => {
                                    markupsStore.freightify.defaultMarkup.perShipment.percent = ev.target.value
                                    markupsStore.freightify.defaultMarkup.perShipment.value = 0
                                }} />
                        </Col6>
                        <Col6>
                            <input type="number" className="input is-small"
                                value={markupsStore.freightify.defaultMarkup.perShipment.value}
                                style={{ width: '5rem' }}
                                onChange={(ev) => {
                                    markupsStore.freightify.defaultMarkup.perShipment.value = ev.target.value
                                    markupsStore.freightify.defaultMarkup.perShipment.percent = 0
                                }} />
                        </Col6>
                    </Cols>
                </Col4>
            </Cols>
            
            
            <hr/>
            <FreightifyCountryMarkup />
            <hr/>
            <section>
                <Cols>
                    <Col5></Col5>
                    <Col1>
                        <button className="button is-danger"
                            onClick={() => {
                                const f = async () => {
                                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/markup/freightify`
                                    let ret = await MonApi.aget(url)
                                    if (ret.markup) markupsStore.freightify = ret.markup
                                    console.log('GOT RET AS=', ret, '\n==================\n\n')
                                }
                                f()
                            }}
                        >Refresh</button>
                    </Col1>
                    <Col2>
                        <button className="button islink"
                            onClick={() => {
                                const f = async () => {
                                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/markup/freightify`
                                    let ret = await MonApi.apost(url, { markup: markupsStore.freightify })
                                    configStore.setModalMessage('FreightFy markup saved.')
                                }
                                f()
                            }}
                        >Save</button>
                    </Col2>
                </Cols>
            </section>
        </>
    )
}