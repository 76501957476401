import React from 'react'
import Utils from '../util/Utils'

export default function AgLastUpload(props) {
    const { updatedOn, updatedBy } = props.data
    return (
        <div>
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <tr>
                    <th>Last Updated On</th>
                    <td>{Utils.formatToDateTime(Number(updatedOn))}</td>

                </tr>
                <tr>
                    <th> Status</th>
                    <td><span style={{ color: updatedBy === 'completed' || updatedBy === 'Delivered' ? 'green' : '' }}>{updatedBy}</span></td>
                </tr>
            </table>
        </div>
    )
}

export function AgLastUpload1(props) {
    const { updatedOn, updatedBy } = props.data
    const status = updatedBy === 'new' ? 'Yet to Commence' : updatedBy
    return (
        <div>
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <tr>
                    <th>Last Updated On</th>
                    <td>{Utils.formatToDateTime(Number(updatedOn))}</td>

                </tr>
                <tr>
                    <th> Status</th>
                    <td><span style={{ color: status === 'invalid' ? 'red' : status === 'active' ? 'blue' : 'black' }}>{status}</span></td>
                </tr>
                {
                    (props.nextdue) ? <tr>
                        <th colspan="2">{props.nextdue}</th>
                    </tr> : ""
                }

            </table>
        </div>
    )
}

export function AgLastUpload2(props) {

    return (
        <div>
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">

                {
                    (props.nextdue) ? <tr>
                        <th colspan="2">{props.nextdue}</th>
                    </tr> : ""
                }

            </table>
        </div>
    )
}

