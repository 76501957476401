import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CustomStyledTextField } from "./ColoredTextfield";

export  function AgTimePicker(props){
    return <>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker 

          {...props} slotProps={{ textField: { size: 'small',placeholder:"Time" } }} renderInput={()=>{
            <CustomStyledTextField />
         }}
          />
         </LocalizationProvider>
       
    </>
}