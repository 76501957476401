import { useForm } from "react-hook-form";
import { Details } from "../v3-componets/AgDetails";
import { useEffect, useState } from "react";
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data";
import { Route } from "../v3-componets/AgRoute";
import RoleUtils from "../util/RoleUtils";
import { ENTITY_STATUS, PERMISSIONS } from "../util/constants";
import { breadCrumbStore, configStore, contractsStore, entityStore, filtersState, loginStore } from "../state/CommonState";
import QuoteCalcV2 from "../util/quote-calc-v2";
import Api from "../state/Api";
import SchApi from "../state/sch-api";
import { useSnapshot } from "valtio";
import ScheduleUtils from "../util/SchedulesUtils";
import Utils from "../util/Utils";
import { DummySchedulev3 } from "../enquiryv3/dummy-schedule-v3";
import AirScheduleCompv3 from "../enquiryv3/AirScheduleComp-v3";
import LCLSchedulesv3 from "../enquiryv3/LCLSchedules-v3";
import CarrierWebFormv3 from "../enquiryv3/CarrierWebForm-v3";
import { ViewSchedule } from "./ViewSchedules";
import { ChooseScheduleCard } from "../mui-components/ScheduleCard";
import { graphNode } from "../my-actions/shipment-graph";
import EntityV2Api from "../state/entities-api";
import { PricingContextProvider } from "../context/PricingContext";


export function ChooseSchedulePage(){
    let client = loginStore.isClient()
    let isUserAuthorized = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CB_QUOTATION_CREATE_DUMMY)
    let isUserAuthorizedForSaveOnly = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.SAVE_UPLOAD_SCHEDULES_ONLY)
    let rec = contractsStore.current;
    useSnapshot(contractsStore);
    useSnapshot(enquiryStore);
    useSnapshot(configStore)
    useSnapshot(entityStore)
    const [schedules, setSchedules] = useState([])
    const [fclSchedules, setFclSchedules] = useState([])
    const [show,setShow] = useState(false);
    const [isLoading,setLoading] = useState(false);
    const [activeProduct, setActiveProduct] = useState(configStore.homescreenCurrentShipmentType)
    let productList = ['LCL', 'FCL', 'Air']
    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            orginScope: false,
            destinationScope: false,
            originType: false,
            destinationType: false,
            showCargoDetailsOutGuage: false,
            showPalletLabel:false
        }
    })
    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = cargoForm;
    useEffect(()=>{
        if(contractsStore.current && configStore.displaySchedules && ((activeProduct === 'LCL' && schedules.length==0) || (activeProduct != 'LCL' && fclSchedules.length==0)) && (contractsStore.current.hasToShowDummySchedule==undefined || contractsStore.current.hasToShowDummySchedule==false) && contractsStore.current.status=='SCHEDULES_UPLOADED'){
            contractsStore.current.hasToShowDummySchedule = true
        }
        if(contractsStore.current && configStore.displaySchedules && ((activeProduct === 'LCL' && schedules.length>0) || (activeProduct != 'LCL' && fclSchedules.length>0)) && contractsStore.current.hasToShowDummySchedule!=undefined && contractsStore.current.hasToShowDummySchedule!=false && contractsStore.current.status=='SCHEDULES_UPLOADED'){
            contractsStore.current.hasToShowDummySchedule = false
        }
    },[fclSchedules,schedules])
    useEffect(()=>{
        // loader.show()
        
        const callback = (data)=>{
            let k = new QuoteCalcV2(rec).recalc()
            if(JSON.stringify(k)!=JSON.stringify(rec)){
                contractsStore.current = k
            }
            // loader.hide()
        }
        Api.getLiveExchangerates(callback)
       configStore.scheduleOrCarrierform = 'schedule'
        return()=>{
            // enquiryStore.initialize();
            configStore.homescreenCurrentView = "list";
        }
    },[])
    useEffect(()=>{
        console.log("++++++++++++++++++++++34",enquiryStore.current)
        if(enquiryStore.current.enqRoute!=undefined && enquiryStore.current.enqRoute.origin != undefined && enquiryStore.current.enqRoute.origin.length>0){
            reset({
                mode: "LCL",
                orginScope: false,
                destinationScope: false,
                originType: false,
                destinationType: false,
                showCargoDetailsOutGuage: false,
                showPalletLabel:false
            })
            if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
                enquiryStore.current.shipmentType = 'FCL'
                setValue("mode",'FCL');
            }else{
                setValue("mode",enquiryStore.current.shipmentType);
            }
            if(enquiryStore.current.multidest==undefined){
                if(enquiryStore.current.containerDetails==undefined) enquiryStore.current.containerDetails = [new Container()]
                if(enquiryStore.current.cargoDetails==undefined) enquiryStore.current.cargoDetails = [new Cargo()]
                setValue("multidest", [
                    {
                        destination:enquiryStore.current.enqRoute.destination,
                        containerdetails:enquiryStore.current.containerDetails,
                        cargodetails:enquiryStore.current.cargoDetails,
                    }
                ]);
                enquiryStore.current.multidest = [
                    {
                        destination:enquiryStore.current.enqRoute.destination,
                        containerdetails:enquiryStore.current.containerDetails,
                        cargodetails:enquiryStore.current.cargoDetails,
                    }
                ]
            }else{
                setValue("multidest",enquiryStore.current.multidest)
            }
            setValue("fbaalways","Yes")
            setValue("origin",enquiryStore.current.enqRoute.origin)
            setValue("cargoreadinessdate",enquiryStore.current.enqRoute.cargoReadinessDate)
            setValue("scope",enquiryStore.current.shipmentScope)
            setValue("shipmentType",enquiryStore.current.shipmentType)
            setValue("customerreference",enquiryStore.current.enqRoute.customerReferenceNum)
            setValue("hazardous",enquiryStore.current.hazardous === "No" ? false :true)
            setValue("quoteClass",enquiryStore.current.quoteClass)
            setValue("packingGroup",enquiryStore.current.packingGroup)
            setValue("temperature",enquiryStore.current.temperature)
            setValue("cargoValue",enquiryStore.current.cargoValue)
            setValue("unnumber",enquiryStore.current.unnumber)
            setValue("nonStackable", enquiryStore.current.nonStackable === "No" ? false :true)
            setValue("temperatureControlled", enquiryStore.current.temperatureControlled === "No" ? false :true)
            setValue("minTemperature",enquiryStore.current.minTemperature)
            setValue("maxTemperature",enquiryStore.current.maxTemperature)
            setValue("stuffingType",enquiryStore.current.stuffingType)
            setValue("destuffingType",enquiryStore.current.destuffingType)
            setValue("status",enquiryStore.current.status)
            setValue("containerDetails",enquiryStore.current.containerDetails)
            setValue("cargoDimensionUnit",enquiryStore.current.cargoDimensionUnit)
            setValue("enqRoute",enquiryStore.current.enqRoute)
            setValue("vehicledetails",enquiryStore.current.vehicles)
            setValue("isFba",enquiryStore.current.fba === "Yes" ? true :false)
            setValue("ior",enquiryStore.current.fbaIorRequired === "Yes" ? true :false)
            setValue("occ",enquiryStore.current.fbaOCC === "Yes" ? true :false)
            setValue("dcc",enquiryStore.current.fbaDCC === "Yes" ? true :false)
            setValue("survey",enquiryStore.current.fbaSurvey === "Yes" ? true :false)
            setValue("storageAtDestination",enquiryStore.current.fbaSAD === "Yes" ? true :false)
            setValue("palletizationandlabelling",enquiryStore.current.fbaPAL === "Yes" ? true :false)
            setValue("days",enquiryStore.current.fbaDays!=undefined?enquiryStore.current.fbaDays:0)
            setValue("pick_up_by_amazon",enquiryStore.current.pick_up_by_amazon === "Yes" ? true :false)
            // setValue("multidest",enquiryStore.current.multidest)
            setShow(true);
        }
    },[enquiryStore.current])

    useEffect(() => {
        if (!configStore.displaySchedules) configStore.showEnquiries = true
            const callbackcheck = (data)=>{
                let d = []
                for (let i = 0; i < data.length; i++) {
                    if(ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), data[i], contractsStore.current)){
                        d.push(data[i])
                    }
                }
                if(d.length>0){
                    (activeProduct === 'LCL') ? setSchedules(d) : setFclSchedules(d)
                }
            }
            (activeProduct === 'LCL') ?
                SchApi.getSchedules(rec._id, callbackcheck) :
                SchApi.getSchedules(rec._id, callbackcheck)
            if (!configStore.displaySchedules) configStore.displaySchedules = true

                if (contractsStore.current._id) {
                    let reqdata = {}
                    let shipmentType = configStore.homescreenCurrentShipmentType.toUpperCase()
    console.log(" the came insidew is ")
                    if (configStore.homescreenCurrentShipmentType == 'LCL') {
                        let currentDate = new Date()
                        let cargoReadinessDate = (contractsStore.current.cargoReadinessDate && contractsStore.current.cargoReadinessDate.length >= 10) ?
                            new Date(contractsStore.current.cargoReadinessDate) : currentDate
                        if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                        if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                        let startDays = (contractsStore.current.portOfLoading === contractsStore.current.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
                        let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
                        let endDate = Utils.mmddyyyy(Utils.newDate(contractsStore.current.validUpto))
                        reqdata = {
                            shipmentType: shipmentType,
                            startDate: startDate,
                            endDate: endDate
                        }
                    }
                    if (configStore.homescreenCurrentShipmentType == 'FCL') {
                        reqdata = {
                            shipmentType: shipmentType
                        }
                    }
                    if (shipmentType == 'LCL' || shipmentType == 'FCL') {
                        Api.getSchedulesList(contractsStore.current._id, reqdata, (data) => {
                            if (data.length > 0) {
                                if (shipmentType == 'LCL' && (schedules.join('') != data.join(''))) {
                                    setSchedules(data)
                                }
                                if (shipmentType == 'FCL') {
                                    setFclSchedules(data)
                                }
                                contractsStore.current.hasToShowDummySchedule = false;
                            }
                           
                        })
            }
        }
    }, [rec._id])

    function _g(node) {
        let e = new graphNode()
        //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
        Object.assign(e, node)
        e.isActive = true
        e.activelines = 2
        return e
    }
    
    let _date = (dt) => {
        return Utils.formatDateShort(new Date(dt))
    }

  console.log(" the rec is ", rec);

   return <div className="flex flex-col w-full gap-2">
    {/* {show && <Route form={cargoForm} viewonly={true} />} */}
    
      { show && 
      <>
      <div className="flex flex-row gap-1">
        <Route form={cargoForm} viewonly={true} />
      <Details form={cargoForm} viewonly={true}/>
      </div>
      <div className="flex flex-row gap-2 justify-end">
      { contractsStore.current.mode === "QUOTE" && <>
        {
                        ( rec.quoteStatus != 'ACCEPTED' && configStore.displaySchedules && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client) && (contractsStore.current['quoteStatus'] === "saved"  || contractsStore.current?.hasToShowDummySchedule || configStore.homescreenCurrentShipmentType === 'Air' || (schedules.length>0 || fclSchedules.length>0))  && rec.status == "SCHEDULES_UPLOADED") ?
                            <button type="button"
                            className="rounded-[52px] h-[40px] w-[84px] bg-white text-blue-dark border-solid border-blue-dark" onClick={() => {
                                setLoading(true);
                                    EntityV2Api.viewEntity(rec.entityId , (data)=> {
                            if(data.status === ENTITY_STATUS.DEACTIVATED || data.status === ENTITY_STATUS.PENDING){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Declining the quotation `)
                            }else {
                                configStore.setModalDecline(rec._id)
                            }  
                            setLoading(false);
                                 } )
                                  
                                }}>Decline</button> :
                            <></>


                    }
                    {
                        ( rec.quoteStatus != 'ACCEPTED' && configStore.displaySchedules && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client) && ((contractsStore.current['quoteStatus'] === "saved"  || contractsStore.current?.hasToShowDummySchedule) || (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current['quoteStatus'] === "FINAL") || (schedules.length>0 || fclSchedules.length>0))  && rec.status == "SCHEDULES_UPLOADED") ?
                            <button type="button"
                                className="rounded-[52px] h-[40px] w-[84px] bg-blue-dark text-white border-0" onClick={() => {
                                    setLoading(true);
                                    EntityV2Api.viewEntity(rec.entityId , (data)=> {
                                        if(data.status === ENTITY_STATUS.DEACTIVATED){
                                            setLoading(false);
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Accepting the quotation`)
            
                            }else {   
                                    Api.createContractFromQuote(rec._id)
                                    Api.getContracts()
                                    breadCrumbStore.id = rec._id
                                    configStore.setModalMessage("Quotation is accepted.")
                                    contractsStore.current['quoteStatus'] = "ACCEPTED"
                                    contractsStore.current.mode = "CONTRACT";
                                setLoading(false);
                            }
                                    }
                                    )
                                }}>Accept</button> :
                            <></>
                    }
      </> }
      </div>
      {
                (configStore.displaySchedules) ?
                    <div className="flex flex-col gap-2">
                            {
                                (() => {
                                    if (configStore.scheduleOrCarrierform === 'schedule') {
                                        filtersState.setRoutes(activeProduct.toUpperCase() === "FCL" ? fclSchedules : schedules);
                                        if (contractsStore.current?.hasToShowDummySchedule && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <PricingContextProvider><ChooseScheduleCard days={rec.transitTime} contract={rec} canProceedFurtherFromHere={contractsStore.current['quoteStatus'] === "ACCEPTED"} /></PricingContextProvider>;
                                        if (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <PricingContextProvider><ChooseScheduleCard days={rec.transitTime} contract={rec} canProceedFurtherFromHere={contractsStore.current['quoteStatus'] === "ACCEPTED"} /></PricingContextProvider>;
                                        else{ 
                                            console.log(" the hfhfchy hgjgv hjvgv dc hgdcvjgcdhgdc ")
                                            let theSChedules = activeProduct.toUpperCase() === "FCL" ? fclSchedules : schedules;
                                            return theSChedules.map((schedule,i)=>{
                                                let e = schedule;
                                                let showOrigin = false
                                                let showDestination = false
                                                const isLclOrAir = rec.shipmentType.includes("LCL") || rec.shipmentType.toLowerCase().includes("air") ;
                                                if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
                                                if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
                                              
                                                if (e.pod != e.fpod) etaDate = Utils.newDate(e.etafpod)
                                            
                                               
                                               
                                                
                                                if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
                                                let srec = {
                                                   'por': rec.portOfReceipt,
                                                   'pol': rec.portOfLoading,
                                                   'pod': rec.portOfDischarge,
                                                   'fpod': rec.finalPlaceOfDelivery,
                                                    vessel: e['VESSEL NAME'],
                                                    voyage: e['VOYAGE'],
                                                    cutOffDt: e['STUFFING CUT OFF DATE'],
                                                    etdPol: e['ETD (POL)'],
                                                    etaPod: e['ETA (POD)'],
                                                    coLoader: e['CO-LOADER']
                                                }
                                                console.log(" the srec ",srec);
                                                const pod = { location: srec.pod }
                                                const por = { location: srec.por }
                                                const pol = { location: srec.pol }
                                        
                                                let etaDate = Utils.newDate(srec.etaPod)
                                                let etdDate = Utils.newDate(srec.etdPol)
                                                let cutOffDate = Utils.newDate(srec.cutOffDt)
                                                let days = Utils.getDays(etdDate, etaDate)
                                                let startDate = Utils.newDate(rec.mailedOn)
                                                startDate = Utils.addDays(startDate, 3)
                                                // The below overrides the above startDate and cutoff. The below considers only the current date
                                                // and not the quote start date
                                                let currentDate = new Date()
                                                let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
                                                    new Date(rec.cargoReadinessDate) : currentDate
                                                if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                                                if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                                                cutOffDate = Utils.newDate(srec.etdPol)
                                                let startDays = (srec.pol === srec.por) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
                                                cutOffDate = Utils.addDays(cutOffDate, startDays * -1)
                                                startDate = Utils.addDays(cargoReadinessDate, startDays)
                                            
                                                // if (etdDate < startDate && !props.showbooking && !skipValidation) {
                                                //     if (loginStore.isClient()) return <></>
                                                //     return <div>
                                                //         POR = {srec.por}, POL = {srec.pol}, CRD = {cargoReadinessDate.toISOString().substring(0, 10)},
                                                //         ETD = {etdDate.toISOString().substring(0, 10)},
                                                //         Start Date as calculated = {startDate.toISOString().substring(0, 10)}<br />
                                                //         CONDITION FAILED = ETD less than START<hr />
                                                //     </div>
                                                // }
                                            
                                                let endDate = Utils.newDate(rec.validUpto)
                                                let endDays = por.location !== pol.location ? 8 : 2
                                                endDate = Utils.addDays(endDate, endDays * -1)
                                                // the below overrides the above
                                                endDate = Utils.newDate(rec.validUpto)
                                                // if (etdDate > endDate && !props.showbooking && !skipValidation) {
                                                //     if (loginStore.isClient()) return <></>
                                                //     return <div>
                                                //         POR = {srec.por}, POL = {srec.pol}, CRD = {cargoReadinessDate.toISOString().substring(0, 10)},
                                                //         ETD = {etdDate.toISOString().substring(0, 10)},
                                                //         End Date as calculated = {endDate.toISOString().substring(0, 10)}<br />
                                                //         CONDITION FAILED = ETD greater than END<hr />
                                                //     </div>
                                                // }
                                                let voldis = 0
                                                rec['chargesList'].map(e => {
                                                    let erate = configStore.getExchangeRate(e.currency)
                                                    if (!erate || erate === 0) erate = 1.0
                                                    if (e.chargeDescription == 'Volume Discount') {
                                                        voldis = e.total * erate
                                                    }
                                                })
                                                let usdRate = configStore.getExchangeRate('USD')
                                                let voldisusd = voldis / usdRate
                                                if(voldis>0){
                                                    voldis = -(voldis)
                                                    voldisusd = -(voldisusd)
                                                }
                                        
                                        
                                                let graphnodes = []
                                            //   if(isLclOrAir){
                                                if (showOrigin) {
                                                    graphnodes.push(_g({
                                                        r2: rec.origin,
                                                        graphic: 'origin'
                                                    }))
                                                }
                                               
                                            
                                                graphnodes.push(_g({
                                                    r1: 'Cut-off ' + _date(cutOffDate),
                                                    r2: por.location,
                                                    r3:  'ETD ' + Utils.formatDateShort(etdDate) 
                                                }))
                                                if (por.location !== pol.location) {
                                                    graphnodes.push(_g({
                                                        r2: srec.por,
                                                        r3: 'ETD ' + _date(etdDate)
                                                    }))
                                                }
                                              
                                                    graphnodes.push(_g({
                                                        r1: e['VESSEL NAME'] + '-' + e['VOYAGE'],
                                                        transparent: true
                                                    }))
                                                
                                                graphnodes.push(_g({
                                                    r1: 'ETA ' + _date(etaDate),
                                                    r2: srec.pod
                                                }))
                                                if (e.pod != e.fpod) {
                                                    graphnodes.push(_g({
                                                        r1: 'ETA ' + _date(etaDate),
                                                        r2: srec.fpod
                                                    }))
                                                }
                                                if (showDestination) {
                                                    graphnodes.push(_g({
                                                        r2: rec.destination,
                                                        graphic: 'destination'
                                                    }))
                                                }
                                                schedule.transitTime = days;
                                                console.log(" the graph node is ",graphnodes)
                                            //   }else{  if (showOrigin) {
                                            //         graphnodes.push(_g({
                                            //             r2: rec.origin,
                                            //             graphic: 'origin'
                                            //         }))
                                            //     }
                                            //     graphnodes.push(_g({
                                            //         r1: (isDummy || e.cutoffpor===0 ) ? '' : 'Cut-off ' + _date(e.cutoffpor),
                                            //         r2: por.location,
                                            //         r3: (!isDummy) ? 'ETD ' +  _date(por.location !== pol.location ? e.etdpor : e.etdpol) : ''
                                            //     }))
                                            //     if (por.location !== pol.location) {
                                            //         graphnodes.push(_g({
                                            //             r1: (isDummy || !e.etapol) ? '' : 'ETA ' + _date(e.etapol),
                                            //             r2: pol.location,
                                            //             r3: (isDummy || !e.etdpol) ? '' : 'ETD ' + _date(e.etdpol)
                                            //         }))
                                            //     }
                                            //     if (!isDummy) {
                                            //         graphnodes.push(_g({
                                            //             r1: e['v1name'] + ' (' + e['s1voy'] +')',
                                            //             r2: e.r1code,
                                            //             transparent: true
                                            //         }))
                                            //         if (e.ts1name && e.ts1name.length) {
                                            //             graphnodes.push(_g({
                                            //                 r1: 'ETA ' + _date(e.ts1eta),
                                            //                 r2: e.ts1name,
                                            //                 r3: 'ETD ' + _date(e.ts1etd)
                                            //             }))
                                            //             graphnodes.push(_g({
                                            //                 r1: e['v2name'] + ' (' + e['s2voy'] +')',
                                            //                 r2: e.r2code,
                                            //                 transparent: true
                                            //             }))
                                            //         }
                                            //         if (e.ts2name && e.ts2name.length) {
                                            //             graphnodes.push(_g({
                                            //                 r1: 'ETA ' + _date(e.ts2eta),
                                            //                 r2: e.ts2name,
                                            //                 r3: 'ETD ' + _date(e.ts2etd)
                                            //             }))
                                            //             graphnodes.push(_g({
                                            //                 r1: e['v3name'] + ' (' + e['s3voy'] +')',
                                            //                 r2: e.r3code,
                                            //                 transparent: true
                                            //             }))
                                            //         }
                                            //     }
                                            
                                            //     graphnodes.push(_g({
                                            //         r1: (isDummy || !e.etapod) ? '' : 'ETA ' + _date(e.etapod),
                                            //         r2: pod.location,
                                            //         r3: (isDummy || e.pod == e.fpod ||  !e.etdpod) ? '' : 'ETD ' + _date(e.etdpod),
                                            //     }))
                                            //     if (e.pod != e.fpod) {
                                            //         graphnodes.push(_g({
                                            //             r1: (isDummy|| !e.etafpod) ? '' : 'ETA ' + _date(e.etafpod),
                                            //             r2: e.fpod
                                            //         }))
                                            //     }
                                            //     if (showDestination) {
                                            //         graphnodes.push(_g({
                                            //             r2: rec.destination,
                                            //             graphic: 'destination'
                                            //         }))
                                            //     }}
                                            return  <PricingContextProvider><ChooseScheduleCard days={days} rate={rec.quoteValue} index={i} contract={rec} schedule={schedule} graphNode={graphnodes} canProceedFurtherFromHere={contractsStore.current['quoteStatus'] === "ACCEPTED"} /></PricingContextProvider>
                                        })};
                                    } else return <CarrierWebFormv3 />;
                                })()
                                // (configStore.scheduleOrCarrierform === 'schedule') ?
                                //     (configStore.homescreenCurrentShipmentType === 'Air') ?
                                //         <AirScheduleComp />
                                //         :
                                //         <LCLSchedules data={activeProduct === 'LCL' ? schedules : fclSchedules} />
                                //     :
                                //     <CarrierWebForm />
                            }
                        </div>
                    : <></>
            }
      </>}
     </div>
}
