import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import AgFile2 from "../components/agfile2"
import { configStore, loginStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import MonApi from "../state/mongo-api"
import Utils from "../util/Utils"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS, VERTICALS } from "../util/constants"

function DeleteConfirmation(props) {
    useSnapshot(configStore)
    let modalClass = 'modal'
    if (configStore.pricingDeleteModalVisible) modalClass += ' is-active'
    // console.log(enquiryStore.current)
    let ptype = props.ptype

    return (
        <div className={modalClass}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <section className="modal-card-head" style={{ textAlign: 'left', fontSize: '1.4rem', fontWeight: 'bold' }}>
                    <span style={{ width: '90%' }}>Delete Records?</span>
                    <button className="button is-danger" style={{ float: 'right', marginRight: '5px' }}
                        onClick={async () => {
                            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/deletecharges/${configStore.pricingDeletePtype}`
                            let ret = await MonApi.aget(url)
                            configStore.pricingDeleteModalVisible = false
                        }}>
                        Delete
                    </button>
                    <button className="button is-info" style={{ float: 'right' }}
                        onClick={() => {
                            console.log('CANCELLING ...')
                            configStore.pricingDeleteModalVisible = false
                        }}>
                        Cancel
                    </button>
                </section>
            </div>
        </div>
    )
}

let chargeHeadingsMap = {
    'origin_charges': 'Origin Charges',
    'origin_inland_haulage': 'Origin Inland Haulage',
    'oceanfreight_fairterms': 'Ocean Freight - Fair Terms',
    'oceanfreight_cif': 'Ocean Freight - CIF',
    'oncarriage_reworking': 'On Carriage - Re-working',
    'destination_inland_haulage': 'Destination Inland Haulage',
    'securityfilingcharges_export': 'Security Filing Charges (Export)',
    'securityfilingcharges_import': 'Security Filing Charges (Import)',
    'destination_cfs_charges': 'Destination CFS Charges',
    'destination_charge_reworking': 'Destination Charges (Re-working)',
    'destination_charge_cif': 'Destination Chareges (CIF)',
    'destination_charge_fairterm': 'Destination Charges (Fairterm)',
    'lclsailingschedulemaster': 'LCL Sailing Schedule Master'
}
export default function PricingUploads() {
    const store = useSnapshot(myactionsstore)
    useSnapshot(loginStore)
    useSnapshot(configStore)

    const [serverTime, setServerTime] = useState(0)
    const [routesEmptyMessage, setRoutesEmptyMessage] = useState('No Routes')
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [crd, setCrd] = useState('')
    const [routes, setRoutes] = useState([])
    const [data, setData] = useState({})
    const [chargeType, setChargeType] = useState('')
    const [charges, setCharges] = useState([])
    const [chargesVisible, setChargesVisible] = useState(false)
    const setPricing = (data) => {
        configStore.setModalMessage('Successfully updated details')
        setData(data)
    }
    const getRows = (headings, recs) => {
        let rows = []
        for (let i = 0; i < recs.length; i++) {
            let rec = recs[i]
            let o = []
            for (let j = 0; j < headings.length; j++) {
                let k = headings[j]
                o.push(rec[k])
            }
            rows.push(o)
        }
        return rows
    }
    const getCharges = async (ptype) => {
        setChargeType(chargeHeadingsMap[ptype])
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getcharges/${ptype}`
        let ret = await MonApi.aget(url)
        console.log(ret)
        setCharges(ret)
        setChargesVisible(true)
    }
    const getRoutes = async () => {
        setRoutes([])
        setRoutesEmptyMessage('Fetching Routes ...')
        let curDate = Utils.getCurrentDateString()
        console.log(origin, destination, crd, curDate)
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
        let start = Date.now()
        let ret = await MonApi.apost(url, {
            origin, destination, crd, curDate
        })
        setServerTime(Date.now() - start)
        setRoutes(ret)
        setRoutesEmptyMessage('No Routes')
    }
    useEffect(() => {
        if (charges && charges.length) {
            let headings = Object.keys(charges[0])
            console.log('headings=', headings)
            let ret1 = getRows(headings, charges)
            console.log(ret1)
        }
    }, [charges])
    let errors = data.validationErrors
    const ChargeRow = (props) => {
        const { cid, ptype, label, csvname } = props
        let url = '/api/v1/pricing/uploadcharges'
        if (['transport', 'pinunloc'].includes(ptype)) url = '/api/v1/pricing/uploadtransport'
        return (
            <tr id={cid}>
                <td>
                    <button className="button"
                        onClick={(e) => {
                            getCharges(ptype)
                        }}>
                        <span className="icon ">
                            <i className="fas fa-eye"></i>
                        </span>
                    </button>
                </td>
                {
                    (loginStore.userRec.aguserRec.role == 'Agraga Admin' ||
                        loginStore.userRec.aguserRec.role == 'Database') &&
                    <td>
                        <button className="button is-danger is-inverted"
                            onClick={(e) => {
                                configStore.pricingDeletePtype = ptype
                                configStore.pricingDeleteModalVisible = true
                            }}>
                            <span className="icon ">
                                <i className="fa-solid fa-trash"></i>
                            </span>
                        </button>
                    </td>
                }
                <td>
                    <a className="button is-link is-inverted" href={csvname} download={csvname}>
                        <span className="icon ">
                            <i className="fas fa-download"></i>
                        </span>
                    </a>
                </td>
                <td>
                    <div className="title is-6">{label}</div>
                </td>
                {
                    RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                    <td>
                        <AgFile2 callback={setPricing} form_data={{ chargeType: ptype }} parent={cid}
                            url={url} filetypes='.csv' />
                    </td>
                }
            </tr>
        )
    }
    if (chargesVisible) {
        let headings = []
        if (charges && charges.length) headings = Object.keys(charges[0])
        return (
            <div className="box">
                <div className="columns">
                    <div className="column is-10"><div className="title is-5">{chargeType}</div></div>
                    <div className="column is-2">
                        <button className="button islink"
                            onClick={(e) => {
                                setChargesVisible(false)
                            }}>
                            <span className="icon ">
                                <i className="fas fa-xmark"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <tr>
                        {
                            headings.map(e => <th>{e}</th>)
                        }
                    </tr>
                    {
                        getRows(headings, charges).map(e => <tr>
                            {
                                e.map(e1 => <td>{e1}</td>)
                            }
                        </tr>)
                    }
                </div>
            </div>
        )
    }
    return (
        <div>

            <DeleteConfirmation />
            <div className="columns" style={{ zoom: '0.9' }}>
                <div className="column is-6">
                    <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                        <tbody>
                            <ChargeRow cid='td1' label='Origin Charges'
                                csvname='Origin-Charges.csv' ptype='origin_charges' />
                            <ChargeRow cid='td2' label='Origin Inland Haulage'
                                csvname='Origin-Inland-Haulage.csv' ptype='origin_inland_haulage' />
                            <ChargeRow cid='td3' label='Ocean Freight (Fair Terms)'
                                csvname='Ocean-Frieght-_Fair-Terms_.csv' ptype='oceanfreight_fairterms' />
                            <ChargeRow cid='td4' label='Ocean Freight - CIF'
                                csvname='Ocean-Freight-CIF.csv' ptype='oceanfreight_cif' />
                            <ChargeRow cid='td5' label='On-carriage Re-working'
                                csvname='On-carriage-Re-working.csv' ptype='oncarriage_reworking' />
                            <ChargeRow cid='td6' label='Destination Inland Haulage'
                                csvname='Destination Inland Haulage.csv' ptype='destination_inland_haulage' />
                            <ChargeRow cid='td7' label='Security Filing Charges (Export)'
                                csvname='Security-Filing-Charges-_Export-_.csv' ptype='securityfilingcharges_export' />
                            <ChargeRow cid='td8' label='Security Filing Charges (Import)'
                                csvname='Security-Filing-Charges-_Import_.csv' ptype='securityfilingcharges_import' />
                        </tbody>
                    </table>
                </div>
                <div className="column is-6">
                    <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                        <tbody>
                            <ChargeRow cid='td9' label='Destination CFS Charges'
                                csvname='Destination CFS Charges.csv' ptype='destination_cfs_charges' />
                            <ChargeRow cid='td10' label='Destination Charges (Reworking)'
                                csvname='Destination Charges (Reworking).csv' ptype='destination_charge_reworking' />
                            <ChargeRow cid='td11' label='Destination Charges (CIF)'
                                csvname='Destination Charges (CIF).csv' ptype='destination_charge_cif' />
                            <ChargeRow cid='td12' label='Destination Charges (Fair Term)'
                                csvname='Destination Charges (Fair Term).csv' ptype='destination_charge_fairterm' />
                            <ChargeRow cid='td13' label='LCL Sailing Schedule Master'
                                csvname='LCL-Sailing-Schedule-Master.csv' ptype='lclsailingschedulemaster' />
                            <ChargeRow cid='td14' label='Origin Customs Clearance'
                                csvname='Origin Customs Clearance.csv' ptype='origin_customs' />
                            <ChargeRow cid='td15' label='Destination Customs Clearance'
                                csvname='Destination Customs Clearance.csv' ptype='destination_customs' />
                            <ChargeRow cid='td16' label='Transport Charges'
                                csvname='Transport-Charges.csv' ptype='transport' />
                            <ChargeRow cid='td17' label='PIN/ZIP - UNLOC mapping'
                                csvname='Pin-Unloc.csv' ptype='pinunloc' />
                        </tbody>
                    </table>
                </div>
            </div>

            {
                (data.isError) ?
                    <div>
                        <h4 className="title is-6">Errors in DSR data</h4>
                        <table className="table">
                            <tbody>
                                {
                                    Object.keys(errors).map(e => {
                                        return (
                                            <tr>
                                                <td>{e}</td>
                                                <td>{errors[e]}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    : <><h4 className="title is-6">
                        {
                            (Object.keys(data).length > 0) ? "Data uploaded successfully!" : ""
                        }
                    </h4></>
            }
        </div>
    )
}