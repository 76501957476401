import React, { useEffect, useState } from 'react';
import Api from './state/Api';
import QuoteView from "./QuoteView"
import { useSnapshot } from 'valtio';
import { quoteDataRec } from './state/CommonState';
import { useNavigate } from 'react-router-dom'

export default function AcceptQuote({ id }) {
    let navigate = useNavigate()
    useSnapshot(quoteDataRec)
    const [ret, setRet] = useState({})
    useEffect(() => {
        Api.updateQuoteStatus(id, 'ACCEPTED', (ret) => {
            console.log('Setting ret to ', ret)
            setRet(ret)
        })
        navigate('/', {replace: true})
    }, [])
    console.log('Value of ret = ', ret)
    return (
        <>
            <div className='box has-text-centered'>
                <strong>Booking Submitted Successfully</strong>
                <p>
                    <strong>Thank You</strong>
                </p>
                <p>
                        Your booking reference number: <strong>{id}</strong>
                </p>
                <hr />
                <p>
                    <div className='box has-text-centered'>
                        Thank you for the information provided.
                        Your booking is confirmed and our team will get back to you along-with further information.
                    </div>
                </p>
                <p>
                    <div className='box has-text-centered'>
                        If you need immediate assistance, please reach out to us on <strong>+91 97318 15003</strong>
                        or write to us at <strong>support@agraga.com</strong>
                    </div>
                </p>
                <div className='box has-text-centered'>
                    <p>
                        We appreciate your patience
                    </p>
                    <p>
                        <strong>Team Agraga</strong>
                    </p>
                </div>
            </div>

        </>
    )
}
