import { useState } from "react"
import { useSnapshot } from "valtio"
import AgModal from "../components/AgModal"
import { AgmodalStore, bookingsStore, loginStore } from "../state/CommonState"
import Taskfiledisplay from "./task-file-display"
import Taskfiletable from "./task-file-table"
import { taskStore } from "./task-store"
import Taskapi from "./tasks-api"
import Utils from "../util/Utils"

export  function BookingCancellationReq(props) {
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(AgmodalStore)
    let booking = bookingsStore.current
    let task = taskStore.current
    let stage = task.stage
    const [val1,setVal1] = useState(false)
    const [val2,setVal2] = useState(false)
    const [val3,setVal3] = useState(false)
    
    if (stage === 1){
        return (
            <div className="box">
                <strong>Booking cancellation requested by customer</strong>
                <br></br>
                <br></br>
                {   (task.reason) ? 
                    <p>Reason:{task.reason}</p> : <></>}
                    <br></br>
                    <br></br>
                {  
                     (taskStore.current.showModal && val1 === true && val2 === false && val3 === false)?<ActrejectModal1 />:<></>
                }
                {  
                     (taskStore.current.showModal && val1 === false && val2 === true && val3 === false)?<ActrejectModal2/>:<></>
                }
                {  
                     (taskStore.current.showModal && val3 === true)?<ActrejectModal3 />:<></>
                }
                <p>
                    <span>
                        <button  style={{ float: 'left' }} className="button islink" onClick={async (e) =>{
                                // setPop(true)
                                // taskStore.current.stage = 2
                                // taskStore.current.pendingWith = 'CUSTOMER'
                                // // taskStore.current.customerStatus = 'PENDING'
                                // await Taskapi.updateTask(taskStore.current)
                                // taskStore.triggerActionVisible = false
                                // taskStore.myActionVisible = false
                                // e.preventDefault()
                                taskStore.current.showModal = true    
                                setVal1(true)      

                        }}>Accept</button>
                    </span>
                    <span>
                        <button  style={{ float: 'right' }} className="button islink" onClick={ async(e) => {
                            // taskStore.current.stage = 3
                            // taskStore.current.pendingWith = 'CUSTOMER'
                            // // taskStore.current.customerStatus = 'PENDING'
                            // await Taskapi.updateTask(taskStore.current)
                            // taskStore.triggerActionVisible = false
                            // taskStore.myActionVisible = false
                            taskStore.current.showModal = true
                            setVal2(true)
                        }}>Accept With Additional Cost</button>
                    </span>
                </p>
                <br></br>
                <br></br>
            </div>
        )
    }
    if (stage === 2){
        return (
            <>
                <div className="box">
                <strong>Booking cancellation requested by customer</strong>
                <br></br>
                <br></br>
                {   (task.reason) ? 
                    <p>Reason:{task.reason}</p> : <></>}
                    <br></br>
                <p>
                    <span>
                    <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        booking.status = "CANCELLED"
                                        let ret = await Taskapi.replaceBooking(booking)
                                        if (!ret) return
                                        taskStore.current.stage = 4
                                        taskStore.current.pendingWith = 'CUSTOMER'
                                        // taskStore.current.customerStatus = 'PENDING'
                                        taskStore.setCustomerComplete()
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                        e.preventDefault()
                                    }}>Acknowledge</button>
                    </span>
                </p>
                <br></br>
                <br></br>
            </div>
            </>
        )
    }
    if (stage === 3){
        return (
            <>
            {  
                     (taskStore.current.showModal && val3 === true)?<ActrejectModal3 />:<></>
                }
                <div className="box">
                <strong>Booking cancellation requested on : {Utils.formatToDateTime(Number(task.createdOn))}</strong>
                <br></br>
                <br></br>
                {   (task.reason) ? 
                    <p>Reason:{task.reason}</p> : <></>}
                    <br></br>
                    <p>Please note that we are incurring additional cost(s) on account of {task.cost_reason}</p>
                    <br></br>
                    <p>Please Accept the invoice attached, so that we can proceed with the booking cancellation</p>
                    <Taskfiledisplay/>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" onClick={async (e)=>{
                            task.cancelstatus = 'Accept'
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                            taskStore.triggerModalVisible = false
                            taskStore.current.stage = 5
                            taskStore.current.pendingWith = 'AGRAGA'
                            await Taskapi.updateTask(taskStore.current)
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                        }}>Accept</button>
                    </span>
                    <span>
                        <button style={{ float: 'right' }} className="button islink" onClick={async (e) =>{
                            taskStore.current.showModal = true
                            setVal3(true)
                        }}>Reject</button>
                    </span>
                </p>
                <br></br>
                <br></br>
            </div>
            </>
        )
    }
    if(stage === 5) {
        return (
            <>
                 <div className="box">
                    <strong>Booking cancellation requested by customer</strong>
                    <br></br>
                    <br></br>
                    {   (task.reason) ? 
                        <p>Reason:{task.reason}</p> : <></>
                    }
                    <br></br>
                    {
                        (task.cost_reason) ? <p>Cost Reason:{task.cost_reason}</p> : <></>
                    }
                    <Taskfiledisplay/>
                    <div> <p>Status:{task.cancelstatus}</p>
                    {
                        (task.cancelstatus != 'Accept') ? <p>Reject Reason : {task.reject_reason}</p>:<></>
                    }
                    </div>
                    <br></br>
                    <p>
                        <span>
                        <button style={{ float: 'left' }} className="button islink"
                                        onClick={async (e) => {
                                            taskStore.current.stage = 4
                                            booking.status = "CANCELLED"
                                            let ret = await Taskapi.replaceBooking(booking)
                                            if (!ret) return
                                            taskStore.current.pendingWith = 'AGRAGA'
                                            // taskStore.current.customerStatus = ''
                                            taskStore.setCustomerComplete()
                                            taskStore.setComplete()
                                            await Taskapi.updateTask(taskStore.current)
                                            taskStore.triggerActionVisible = false
                                            taskStore.myActionVisible = false
                                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                                AgmodalStore.pageVisible = false
                                                AgmodalStore.apiCall = true
                                            }
                                        }}>Acknowledge</button>
                        </span>
                    </p>
                <br></br>
                <br></br>
                </div>
            </>
        )
    }

    if(stage === 4 || stage >=6) {
        return (
            <>
                 <div className="box">
                    <strong>Booking cancellation requested by customer</strong>
                    <br></br>
                    <br></br>
                    {   (task.reason) ? 
                        <p>Reason:{task.reason}</p> : <></>
                    }
                    <br></br>
                    {
                        (task.cost_reason) ? <p>Cost Reason:{task.cost_reason}</p> : <></>
                    }
                    <Taskfiledisplay/>
                    <div> <p>Status:{task.cancelstatus}</p>
                    {
                        (task.cancelstatus != 'Accept') ? <p>Reject Reason : {task.reject_reason}</p>:<></>
                    }
                    </div>
                </div>
            </>
        )
    }
}
export function ActrejectModal1() {
    const [reason, setReason] = useState("");
    useSnapshot(taskStore)
    useSnapshot(AgmodalStore)
    let modalClass = 'modal'
    if (taskStore.current.showModal) modalClass += ' is-active'
    const submit = async () => {
        taskStore.current.showModal = false
        // taskStore.current.rejectReason = reason
        // taskStore.current.stage = 2
        // taskStore.current.pendingWith = 'AGRAGA'
        // await Taskapi.updateTask(taskStore.current)
        // taskStore.triggerActionVisible = false
        // taskStore.myActionVisible = false


        taskStore.current.stage = 2
        taskStore.current.pendingWith = 'CUSTOMER'
        taskStore.current.customerStatus = 'PENDING'
        await Taskapi.updateTask(taskStore.current)
        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
        }
        taskStore.setModalMessage("Booking Cancelled Successfully.")
        // e.preventDefault()
}
    return (
        <div className={modalClass} onClick={() => { taskStore.current.showModal = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>Are you sure want to proceed cancellation</h2>
                                            <br></br>
                                            {/* <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br>  */}
                                            <button class="button is-danger" style={{ float: "right" }}
                                            // disabled = {reason.length==0}
                                                onClick={submit}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => taskStore.current.showModal = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}
export function ActrejectModal2() {
    const [reason, setReason] = useState("");
    useSnapshot(taskStore)
    useSnapshot(AgmodalStore)
    let modalClass = 'modal'
    if (taskStore.current.showModal) modalClass += ' is-active'
    const submit = async () => {
        taskStore.current.showModal = false
        taskStore.current.cost_reason = reason
       
        // taskStore.current.pendingWith = 'AGRAGA'
        // await Taskapi.updateTask(taskStore.current)
        // taskStore.triggerActionVisible = false
        // taskStore.myActionVisible = false

        taskStore.current.stage = 3
        taskStore.current.pendingWith = 'CUSTOMER'
        taskStore.current.customerStatus = 'PENDING'
        await Taskapi.updateTask(taskStore.current)
        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
        }
         // e.preventDefault()
}
    return (
        <div className={modalClass} onClick={() => { taskStore.current.showModal = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                {/* <h2 style={{ color: "red" }}>Are you sure want to proceed cancellation</h2> */}
                                            <br></br>
                                            <h3 style={{color:"red"}}>COST REASON:</h3>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br> 
                                            <br></br>
                                            <Taskfiletable/>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            disabled = {reason.length==0}
                                                onClick={submit}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => taskStore.current.showModal = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}
export function ActrejectModal3() {
    const [reason, setReason] = useState("");
    useSnapshot(taskStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let modalClass = 'modal'
    if (taskStore.current.showModal) modalClass += ' is-active'
    const submit = async () => {
        taskStore.current.showModal = false
        taskStore.current.reject_reason = reason
       
        // taskStore.current.pendingWith = 'AGRAGA'
        // await Taskapi.updateTask(taskStore.current)
        // taskStore.triggerActionVisible = false
        // taskStore.myActionVisible = false

        // taskStore.current.stage = 3
        // taskStore.current.pendingWith = 'CUSTOMER'
        // // taskStore.current.customerStatus = 'PENDING'
        // await Taskapi.updateTask(taskStore.current)
        // taskStore.triggerActionVisible = false
        // taskStore.myActionVisible = false
         // e.preventDefault()


        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
        task.cancelstatus = 'Decline'
        taskStore.current.stage = 5
        taskStore.current.pendingWith = 'AGRAGA'
        await Taskapi.updateTask(taskStore.current)
        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
        }
        // taskStore.triggerModalVisible = false
}
    return (
        <div className={modalClass} onClick={() => { taskStore.current.showModal = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                {/* <h2 style={{ color: "red" }}>Are you sure want to proceed cancellation</h2> */}
                                            <br></br>
                                            <h3 style={{color:"red"}}>REJECT REASON:</h3>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br> 
                                            <br></br>
                                            {/* <Taskfiletable/> */}
                                            <button class="button is-danger" style={{ float: "right" }}
                                            disabled = {reason.length==0}
                                                onClick={submit}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => taskStore.current.showModal = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}