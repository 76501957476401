import { useSnapshot } from "valtio";
import { taskStore } from "../../mytasks/task-store";
import { Documents } from "../../mui-components/Documents";
import { Box } from "@mui/material";


export function OtherActionsView(){
    const task = useSnapshot(taskStore).current;
    const stage = task.stage;
    return <Box sx={{height:"100%",width:"100%",display:"flex",flexDirection:"column",paddingX:"15px",gap:3}}>
        <Documents task={task} />
    </Box>
}