import { EntityV2Store, cargoStore, configStore, containerStore, customerStore, entityStore, loginStore, quoteDataRec } from "../state/CommonState"
import { useSnapshot } from "valtio"
import ContainerView from "../ContainerView"
import CargoView from "../CargoView"
import ChargesView1 from "../ChargesView1"
import FCLChargesView from "../FCLChargesView"
import { getTC } from "../TC"
import Utils from "../util/Utils"
import ChargesAirView from "../ChargesAirView"
import airlineCodes from "../static-data/airlines"
import ConsolidatorView from "../consolidatorView"
import QuotationUtils from "../util/quotationUtil"
import { useEffect, useLayoutEffect, useState } from "react"
import Api from "../state/Api"
import LCLChargesView from "./lcl-charges-view"
import AirChargesView2 from "./air-charges-view-v2"
import FCLChargesView2 from "./fcl-charges-view-v2"
import VehicleView from "./vehicle-view"
import EntityApi from "../state/entity-api"
import { loader } from "../util/loader"
import AguserApi from "../state/AguserApi"

const dt = (dt1) => {
    if (!dt1) return dt1
    if (dt1.length < 10) return dt1
    let s = dt1.split('-')
    return `${s[2]}-${s[1]}-${s[0]}`
}

export default function QuoteViewv2({ isMailBody }) {
    useSnapshot(configStore)
    useSnapshot(containerStore)
    const [space, setSpace] = useState('')
    const [prList, setPrList] = useState([])
    useLayoutEffect(() => {
        Api.getExchangerates()
        setTimeout(() => {
            setSpace(' ')
        }, 300);
    }, [])
    const imQuote = useSnapshot(quoteDataRec)
    let mailedOnDate = imQuote.mailedOn
    console.log('INSIDE QUOTEVIEW mailedOnDate=', mailedOnDate)
    if (!mailedOnDate || mailedOnDate.length < 8) mailedOnDate = Utils.getCurrentDateString()
    const Td = (props) => {
        return (
            <td>
                {props.label} <strong>{imQuote[props.name]}</strong>
            </td>
        )
    }
    const S = (props) => <strong>{props.children}</strong>
    let quoteTC = getTC(imQuote.shipmentType)
    let customTC = imQuote.customTC.split(/\r?\n/)
    quoteTC.map(e => customTC.push(e))
    quoteTC = customTC
    const style1 = `color: 'red'`
    let stuffinglabel = 'Stuffing'
    if (imQuote.shipmentType.indexOf('De-stuffing')) stuffinglabel = 'De-stuffing'
    let porLable = 'Place of Receipt'
    let polLabel = 'Port of Loading'
    let podLabel = 'Port of Discharge'
    if (imQuote.shipmentType.indexOf('Air') >= 0) {
        porLable = 'Airport of Receipt'
        polLabel = 'Airport of departure'
        podLabel = 'Airport of Arrival'
    }
    const TCComp = () => {
        if (quoteDataRec.temperatureControlled === 'Yes') {
            if (quoteDataRec.shipmentType.toLowerCase().includes('air')) {
                return (
                    <>
                        <td>Minimum Temperature: <S>{imQuote.minTemperature}</S></td>
                        <td>Maximum Temperature: <S>{imQuote.maxTemperature}</S></td>
                    </>
                )
            }
            return (
                <>
                    <td>Temperature: <S>{imQuote.temperature}</S></td>
                    <td>Cargo Value (USD): <S>{imQuote.cargoValue}</S></td>
                </>
            )
        }
        return <></>
    }
    const factoryOrDock = (shipmentType) => {
        if (shipmentType.indexOf('FCL') < 0) return <></>
        let label = 'Stuffing Type'
        if (shipmentType.indexOf('De-stuffing') >= 0) label = 'De-stuffing Type'
        return (
            <td>{label}: <S>{imQuote.factoryOrDockStuffing}</S></td>
        )
    }
    let displayAirline = imQuote.airline
    if (imQuote.airline) {
        let temp = airlineCodes.filter(e => e.code == imQuote.airline)
        if (temp.length > 0) displayAirline = temp[0].name
    }

    let reqdata = {}
    if(quoteDataRec.shipmentType == 'LCL'){
        let currentDate = new Date()
        let cargoReadinessDate = (quoteDataRec.cargoReadinessDate && quoteDataRec.cargoReadinessDate.length >= 10) ?
            new Date(quoteDataRec.cargoReadinessDate) : currentDate
        if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
        if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
        let startDays = (quoteDataRec.portOfLoading === quoteDataRec.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
        let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
        let endDate = Utils.mmddyyyy(Utils.newDate(quoteDataRec.validUpto))
        reqdata = {
            shipmentType:quoteDataRec.shipmentType,
            startDate:startDate,
            endDate:endDate
        }
    }else{
        reqdata = {
            shipmentType:quoteDataRec.shipmentType
        }
    }
    if(quoteDataRec.shipmentType == 'FCL'){
        reqdata = {
            shipmentType:quoteDataRec.shipmentType
        }
    }

    if(quoteDataRec.reqdata==undefined || JSON.stringify(quoteDataRec.reqdata) != JSON.stringify(reqdata)){
        quoteDataRec.reqdata = reqdata
    }
    const getpr = (d)=>{
        let l = d
        for (let i = 0; i < prList.length; i++) {
            if(d==prList[i]['email']){
                l = `${prList[i]['firstName']} ${prList[i]['lastName']} (${prList[i]['email']})`
            }
        }
        return l
    }
    useEffect(()=>{
        loader.show()
        const callback = (data)=>{
            if(JSON.stringify(data)!=JSON.stringify(entityStore.list)){
                entityStore.list = data
            }
            const callback1 = (data)=>{
                if(JSON.stringify(data)!=JSON.stringify(configStore.currencyList)){
                    configStore.currencyList = data
                }
                loader.hide()
            }
            Api.getLiveExchangerates(callback1)
        }
        EntityApi.getList(callback) 
        AguserApi.getUsers4role_new("Procurement", setPrList)
    },[])
    if(imQuote.fba==undefined){
        imQuote.fba='No'
    }
    const displayCargo4fcl = Utils.outGaugeContainerCheck(containerStore.list)
    return (
        <>
            <style>
                {style1}
            </style>
            {space}
            <body>
                {
                    (imQuote.quoteStatus == 'DECLINED') ?
                        <>
                            <div class="notification is-danger">
                                <div class="columns">
                                    <div class="column">
                                        <strong>Decline Reason : </strong>{imQuote.decline_reason}
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <strong>Decline By : </strong>{imQuote.tsDeclineBy}
                                    </div>
                                    <div class="column">
                                        <strong>Decline On : </strong>{Utils.formatToDateTime(Number(imQuote.tsDeclineOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (imQuote.quoteStatus == 'LOST') ?
                        <>
                            <div class="notification is-danger">
                                <div class="columns">
                                    <div class="column">
                                        <strong>Lost Reason : </strong>{imQuote.LostReason}
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <strong>Lost By : </strong>{imQuote.tsLostBy}
                                    </div>
                                    <div class="column">
                                        <strong>Lost On : </strong>{Utils.formatToDateTime(Number(imQuote.tsLostOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (imQuote.quoteStatus == 'INVALID') ?
                        <>
                            <div class="notification is-danger">
                                <div class="columns">
                                    <div class="column">
                                        <strong>Invalid Reason : </strong>{imQuote.InvalidReason}
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <strong>Invalid By : </strong>{imQuote.tsInvalidBy}
                                    </div>
                                    <div class="column">
                                        <strong>Invalid On : </strong>{Utils.formatToDateTime(Number(imQuote.tsInvalidOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (imQuote.quoteStatus == 'WON') ?
                        <>
                            <div class="notification is-success">
                                <div class="columns">
                                    <div class="column">
                                        <strong>WON By : </strong>{imQuote.tsWonBy}
                                    </div>
                                    <div class="column">
                                        <strong>Won On : </strong>{Utils.formatToDateTime(Number(imQuote.tsWonOn))}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <></>
                }
                <section class="section">
                    <div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: '40%' }}>
                                Quotation Reference # <S>{imQuote.quotationNum}</S>
                            </div>
                            <div style={{ width: '30%' }}>
                                {/* Customer: <S>{entityStore.getCustomer4gst(imQuote.gst)}</S> */}
                                  Customer: <S>{EntityV2Store.getEntityById(imQuote.entityId)}</S>
                            </div>
                            <div style={{ width: '30%' }}>
                                Quote Date: <S>{dt(mailedOnDate)}</S>
                            </div>
                        </div>
                        <br />
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                            <tbody>
                                {/*<tr>
                                <td>Customer: <S>{imQuote.customerEmail}</S></td>
                                <td>Shipping Type: <S>{imQuote.shipmentType}</S></td>
                                <td>Shipping Scope: <S>{imQuote.shipmentScope}</S></td>
    </tr>*/}
                                <tr>
                                    {(imQuote.shipmentType.indexOf('Air') < 0) ?
                                        <>
                                            <td>Origin: <S>{imQuote.origin}</S></td>
                                            <td>Destination: <S>{imQuote.destination}</S></td>
                                        </> :
                                        <>
                                            <td>Origin: <S>{imQuote.origin}</S></td>
                                            <td>Destination: <S>{imQuote.destination}</S></td>
                                        </>
                                    }
                                    <td>Cargo Readiness Date: <S>{dt(imQuote.cargoReadinessDate)}</S></td>
                                </tr>
                                <tr>
                                    <td>{porLable}: <S>{imQuote.portOfReceipt}</S></td>
                                    <td>{polLabel}: <S>{imQuote.portOfLoading}</S></td>
                                    <td>{podLabel}: <S>{imQuote.portOfDischarge}</S></td>
                                </tr>
                                <tr>
                                    <td>Final Place of Delivery: <S>{imQuote.finalPlaceOfDelivery}</S></td> 
                                    <td>Customer Reference#: <S>{imQuote.customerReferenceNum}</S></td>
                                    <td>Valid Upto: <S>{dt(imQuote.validUpto)}</S></td>
                                </tr>
                                <tr>
                                    <td>Commodity Type: <S>{imQuote.commodityType}</S></td>
                                    <td>Incoterms: <S>{imQuote.incoTerms}</S></td>
                                    <td>Sales Person: <S>{imQuote.salesperson}</S></td>
                                </tr>
                                <tr>
                                    <td>Hazardous: <S>{imQuote.hazardous}</S></td>
                                    {(quoteDataRec.hazardous === 'Yes') ?
                                        <>
                                            <td>Class: <S>{imQuote.quoteClass}</S></td>
                                            <td>Packing Group: <S>{imQuote.packingGroup}</S></td>
                                            {/* <td>UN Number: <S>{imQuote.unnumber}</S></td> */}
                                        </> :
                                        <></>}
                                </tr>
                                <tr>{
                                    (quoteDataRec.hazardous === 'Yes')? <><td>UN Number: <S>{imQuote.unnumber}</S></td>
                                    </>:<></>}</tr>
                                <tr>
                                    {
                                        (!Utils.displayNonStackable(imQuote.shipmentType, imQuote.shipmentScope)) ?
                                            <></> :
                                            <td>Non Stackable: <S>{imQuote.nonStackable}</S></td>
                                    }

                                    <>
                                        {(quoteDataRec.shipmentType.indexOf('Air') >= 0) ? <></> : <td>Carrier: <S>{imQuote.carrier}</S></td>}
                                        <td>Tentative transit Time: <S>{imQuote.transitTime}</S></td>
                                        {
                                        (quoteDataRec.shipmentType.indexOf('Air') != -1) ?
                                        <td>Airline: <S>{displayAirline}</S></td>:""    
                                    }
                                    </>
                                </tr>
                                <tr>
                                {
                                        (!isMailBody && imQuote.procurement!=undefined && imQuote.procurement.length>0)?<td>Procurement: <S>{getpr(imQuote.procurement)}</S></td>:""
                                    }
                                    <td>FBA:<S>{imQuote.fba}</S></td>
                                </tr>
                                <tr>
                                    {/* {factoryOrDock(imQuote.shipmentType)} */}
                                    {
                                        (Utils.displayTemperatureControlled(quoteDataRec.shipmentType)) ?
                                            <>
                                                <td>Temperature Controlled: <S>{imQuote.temperatureControlled}</S></td>
                                                <TCComp />
                                            </> :
                                            <></>
                                    }
                                </tr>
                            </tbody>
                        </table>
                        <VehicleView vehicleDetails={quoteDataRec.vehicleDetails} />

                        {
                            (!isMailBody && quoteDataRec.consolidatorList != null && quoteDataRec.consolidatorList?.length !== 0 && quoteDataRec.shipmentType.toLowerCase().includes('lcl')) && <ConsolidatorView />
                        }
                        {
                            (quoteDataRec.shipmentType.indexOf('FCL') >= 0) ?
                                <ContainerView /> :
                                <></>
                        }
                        {((Utils.displayNonStackable(imQuote.shipmentType, imQuote.shipmentScope) && QuotationUtils.hasCargoDetail())||displayCargo4fcl) ?
                            <CargoView /> :
                            <></>
                        }
                        {
                            (quoteDataRec.shipmentType.indexOf('LCL') >= 0) ?
                                <LCLChargesView isMailBody={isMailBody} /> :
                                <></>
                        }
                        {
                            (quoteDataRec.shipmentType.indexOf('Air') >= 0) ?
                                <AirChargesView2 /> :
                                <></>
                        }
                        {
                            (quoteDataRec.shipmentType.indexOf('FCL') >= 0) ?
                                <FCLChargesView2 /> :
                                <></>
                        }
                        {
                            (!isMailBody) ?
                                <>
                                    <h5 className="title is-5">Terms & Conditions</h5>
                                    <ol style={{paddingLeft:'30px',paddingBottom:'10px'}}>
                                        {
                                            quoteTC.filter(e => e.length > 0).map((e, i) => <li>{e}</li>)
                                        }
                                    </ol>
                                </> :
                                <>
                                    <h5 className="title is-5">Terms and Conditions apply</h5>
                                </>
                        }


                    </div>
                </section>
            </body>
        </>
    )
}