import { useEffect } from "react"
import { subscribe, useSnapshot } from "valtio"
import lclExportChargesData1 from "../charges_data/LCLExportCharges1"
import airImportChargesData from "../charges_data/AirImport"
import { AgInput, AgSelect, TableEdit, TableSelect, TableSelect7 } from "../components/AgComponents"
import { Agfacdoc } from "../components/Agfacdoc"
import { Agyesno } from "../components/Agyesno"
import { configStore, contractsStore, enquiriesValidationStore, loginStore } from "../state/CommonState"
import { getHazardousClassList, packageTypeList, packingGroupList } from "../StaticData"
import { Cargo, enquiryStore } from "./enquiry-data"
import QuotationUtils from "../util/quotationUtil"
import Utils from "../util/Utils"

function CargoButtons({ isFromUploadSchedules, cargoDetail, ...props }) {
    useSnapshot(enquiryStore)
    let line = props.line
    let len = props.length
    return (
        <div className="field has-addons">
            {
                (len !== 1) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...enquiryStore.current.cargoDetails]
                                list.splice(line, 1)
                                enquiryStore.current.cargoDetails = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                if (isFromUploadSchedules) {
                                    enquiryStore.current.cargoDetails.push({ ...new Cargo(), isFromUploadSchedules })
                                    return;
                                }
                                enquiryStore.current.cargoDetails.push(new Cargo())
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function HomescreenCargo(props) {


    useSnapshot(contractsStore)
    let { inPricing } = props
    let tableKey

    useSnapshot(enquiryStore)
    const rec = enquiryStore.current
    let cid = enquiryStore.current._id
    if (!cid) {
        cid = contractsStore.current._id
        if (!cid) cid = '' + enquiryStore.currentCargoTableKey
    }


    useEffect(() => {
        tableKey = '' + new Date().getMilliseconds()
        const unsubscribe = subscribe(enquiryStore.current.cargoDetails, () => {
            console.log('cargoDetails CHANGED=')
            enquiryStore.cargoDetailsChanges++
            configStore.displaySchedules = false
        })
        console.log('###   ', cid)
        // if (props.displayCargo4fcl) {
        //     rec.cargoDetails.map((currentVal) => {
        //         currentVal.initialize()
        //     })
        // }
        return unsubscribe
    }, [enquiryStore.current.cargoDetails, cid])
    let e = enquiryStore.current
    if (!rec) return <></>
    const cargoList = enquiryStore.current.cargoDetails
    let disabled = {}
    if (props.viewonly) disabled = { disabled: '1' }
    let pureview = {}
    if (props.pureview) {
        pureview = { disabled: '1' }
        disabled = { disabled: '1' }
    }
    let editFields = disabled
    const stype = configStore.homescreenCurrentShipmentType.toLowerCase()
    const sscope = configStore.homescreenCurrentShipmentScope
    console.log(stype, 'type');
    if (props.pureview) editFields = disabled
    else if (contractsStore?.current?.chargesList?.length > 0) editFields = { disabled: !QuotationUtils.isCargoEditable(stype, sscope) };
    if (inPricing && !pureview) disabled = false
    if (inPricing && !pureview) editFields = false
    editFields = disabled
    let wtperLabel = `Weight per Package`
    let volperLabel = 'Volume per Package'
    if (stype !== 'lcl') {
        wtperLabel = 'Weight per piece'
        volperLabel = 'Volume per piece'
    }
    let hazardousSType = stype
    // The below lines are a patch. Initial specs was for different formats for lcl, fcl, air 
    // etc. However now the specs are like one format for lcl/p2p and another format for others
    let tablestype = stype

    let tablesscope = sscope
    if (tablestype != 'lcl') {
        tablestype = 'lcl'
        tablesscope = 'Port-to-Door'
    } console.log(tablestype, "tablestype");

    return (
        <>
            <div style={  loginStore.isClient() ? {backgroundColor:"white",borderRadius:"5px"} : { boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon is-small mr-2">
                            <i className="fas fa-cube"></i>
                        </span>
                        {Utils.labelForOutGauge({shipmentType:stype,shipmentScope:sscope,obj:rec,containerDetails:enquiryStore.current.containerDetails})?'Cargo Details for Out-Gauge Containers':"Cargo Details"}
                    </p>
                </header>
                <div className="card-content" style={{ paddingTop: '0px' }}>
                    <div className="content">
                        <div className="columns" style={{ margin: '0px' }}>
                            <div className="column is-3 is-fullwidth" style={{ alignText: 'left' }}>
                                {
                                    (!props.displayCargo4fcl) && rec.temperatureControlled === 'No' ?
                                        <Agyesno {...disabled} label='Hazardous?' record={rec} name='hazardous' callback={(value) => {
                                            if (value === 'No') {
                                                rec.quoteClass = '';
                                                rec.packingGroup = '';
                                                rec.unnumber = '';
                                            }
                                        }} />
                                        : <></>
                                }
                            </div>
                            <div className="column is-3 is-fullwidth">
                                {
                                    (stype === 'fcl' && !props.displayCargo4fcl) ?
                                        <Agfacdoc {...pureview} label='Stuffing type' record={rec} name='stuffingType' />
                                        : <></>
                                }
                            </div>
                            <div className="column is-3 is-fullwidth">
                                {
                                    (stype === 'fcl' && !props.displayCargo4fcl) ?
                                        <Agfacdoc {...pureview} label='De-Stuffing type' record={rec} name='destuffingType' />
                                        : <></>
                                }
                            </div>
                            <div className="column is-3 is-fullwidth">
                                <span style={{ float: 'right' }}>
                                    {
                                        (stype !== 'fcl' || props.displayCargo4fcl) ?
                                            <Agyesno {...disabled} isDisabled={Object.keys(disabled).length===0? false: true } label='Non-stackable?' record={rec} name='nonStackable' />
                                            : <></>
                                    }
                                    {
                                        ((stype === 'fcl' || stype === 'air') && !props.displayCargo4fcl && rec.hazardous === 'No') ?
                                            <Agyesno {...disabled} label='Temperature Controlled?' record={rec} name='temperatureControlled'
                                            isDisabled={Object.keys(disabled).length===0? false: true } callback={(value) => {
                                                    if (value === 'No') {
                                                        rec.minTemperature = '';
                                                        rec.maxTemperature = '';
                                                        rec.temperature = '';
                                                        rec.cargoValue = 0;
                                                    }
                                                }} />
                                            : <></>
                                    }
                                </span>
                            </div>
                        </div>

                        <div className="columns" >
                            {
                                (rec.hazardous === 'Yes' && rec.temperatureControlled === 'No' && !props.displayCargo4fcl) ?
                                    <div className="columns" style={{ margin: '0px' }}>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgSelect {...editFields} {...pureview} label='Class' record={rec}  {...pureview}
                                                name='quoteClass' dataList={getHazardousClassList(hazardousSType)} />
                                        </div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgSelect {...editFields} {...pureview} label='Packing Group' record={rec}  {...pureview}
                                                name='packingGroup' dataList={packingGroupList} />
                                        </div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgInput {...editFields} {...pureview} type='text' style={{ alignText: 'right' }}
                                                label='UN Number' record={rec} name='unnumber' />
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                (stype === 'air' && rec.temperatureControlled === 'Yes' && rec.hazardous === 'No') ?
                                    <>
                                        <div className="column is-3"></div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgInput {...editFields} {...pureview} type='number' style={{ alignText: 'right' }}
                                                label='Minimum Temperature' record={rec} name='minTemperature' />
                                        </div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgInput {...editFields} {...pureview} type='number' style={{ alignText: 'right' }}
                                                label='Maximum Temperature' record={rec} name='maxTemperature' />
                                        </div>
                                    </>
                                    : <></>
                            }
                        </div>
                        <div className="table-container">
                            <table className="table is-narrow is-bordered is-fullwidth is-small"
                                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                                <thead>

                                    <tr>
                                        <th>Package Type*</th>
                                        <th>Quantity*</th>
                                        {((tablestype === 'lcl' && tablesscope === 'Port-to-Port') || tablestype === 'air') ?
                                            <>
                                                <th colSpan={1}>Total Weight*</th>
                                            </> : <></>
                                        }
                                        {(tablestype === 'lcl' && tablesscope === 'Port-to-Port') ?
                                            // <>
                                            //     <th colSpan={1}>Total Volume*</th><div>
                                            //     <TableSelect  {...disabled} style={{ border: 'none' }} record={enquiryStore.current} name='unit' dataList={['CBM', 'cu ft']} />
                                            //     </div>
                                            // </> : <></>
                                            
                                            <th colSpan={1}>
                                                <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{ marginTop: '5px' }}>Total Volume* -</div>
                                                    <TableSelect7 style={{ border: 'none', fontSize: '0.8rem', fontWeight: 'bold', color: 'grey' }} record={enquiryStore.current}
                                                        name='unit'
                                                        dataList={['CBM', 'cu ft']} />
                                                </span>
                                            </th> : <></>
                                            
                                            // :<></>
                                        }                                        {(tablestype === 'air') ?
                                            <th colSpan={1}>Chargeable Weight</th> : <></>
                                        }
                                        {(!(tablestype === 'lcl' && tablesscope === 'Port-to-Port') && tablestype !== 'air') ?
                                            <th colSpan={1}>{`${wtperLabel}` + '*'}</th> : <></>
                                        }
                                        {(tablestype !== 'lcl') ?
                                            <th colSpan={1}>{volperLabel}</th> : <></>
                                        }
                                        {(tablestype === 'lcl' && tablesscope !== 'Port-to-Port') ?

                                            <th colSpan={3}>
                                                <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{ marginTop: '5px' }}>Dimensions* -</div>
                                                    <TableSelect {...disabled} style={{ border: 'none', fontSize: '0.8rem', fontWeight: 'bold', color: 'grey' }} record={enquiryStore.current}
                                                        name='cargoDimensionUnit'
                                                        dataList={['Cms', 'MM', 'Inches', 'Meters', 'Feet']} />
                                                </span>
                                            </th> : <></>
                                        }
                                        <th></th>
                                    </tr>
                                    {(tablestype === 'lcl' && tablesscope !== 'Port-to-Port') ?
                                        <tr>
                                            {(tablestype !== 'lcl') ?
                                                <th colSpan={4}></th> :
                                                <th colSpan={3}></th>
                                            }
                                            <th>Length</th>
                                            <th>Width</th>
                                            <th>Height</th>
                                            {(tablestype === 'lcl' && tablesscope == 'Port-to-Port') ?
                                                <th colSpan={2}></th> : <></>
                                            }
                                            <th></th>
                                        </tr> : <></>
                                    }
                                </thead>
                                <tbody>
                                    {
                                        cargoList.map((e, i) => (
                                            <tr key={cid + i}>
                                                <td>
                                                    <TableSelect {...disabled}
                                                        isDisabled={Object.keys(disabled).length===0? false: true } 
                                                        record={e} name='packageType'
                                                        dataList={packageTypeList} />
                                                </td>
                                                <td>
                                                    <TableEdit  type='number'  {...editFields} style={{ width: '4rem', textAlign: 'right' }}
                                                        record={e} name='quantity' />
                                                </td>
                                                {

                                                    ((tablestype === 'lcl' && tablesscope == 'Port-to-Port') || tablestype === 'air') ?
                                                        <>
                                                            <td>
                                                                <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <TableEdit type='number'  {...editFields} style={{ width: '4rem', textAlign: 'right' }} record={e.totalWeight} name='value' />
                                                                    <TableSelect  {...disabled} style={{ border: 'none' }} record={e.totalWeight} name='unit' dataList={['Kgs', 'Tons', 'Lbs']} />
                                                                </span>
                                                            </td>
                                                        </> :
                                                        <></>
                                                }
                                                {(tablestype === 'lcl' && tablesscope == 'Port-to-Port') ?
                                                    <>
                                                        <td>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TableEdit type='number' {...editFields} style={{ width: '4rem', textAlign: 'right' }} record={e.totalVolume} name='value' />
                                                                {/* <TableSelect  {...disabled} style={{ border: 'none' }} record={e.totalVolume} name='unit' dataList={['CBM', 'cu ft']} /> */}
                                                            </span>
                                                        </td>
                                                    </> : <></>
                                                }
                                                {(tablestype === 'air') ?
                                                    <>
                                                        <td>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TableEdit type='number' {...editFields} style={{ width: '4rem', textAlign: 'right' }} record={e.chargeableWeight} name='value' />
                                                                <TableSelect {...disabled} style={{ border: 'none' }} record={e.volume} name='unit' dataList={['CBM', 'cu ft']} />
                                                            </span>
                                                        </td>
                                                    </> : <></>
                                                }
                                                {
                                                    (!(tablestype === 'lcl' && tablesscope === 'Port-to-Port') && tablestype !== 'air') ?
                                                        <td>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TableEdit type='number' {...editFields} style={{ width: '4rem', textAlign: 'right' }} record={e.weight} name='value' />
                                                                <TableSelect {...disabled} style={{ border: 'none' }} record={e.weight} name='unit' dataList={['Kgs', 'Tons', 'Lbs']} />
                                                            </span>
                                                        </td> :
                                                        <></>
                                                }
                                                {(tablestype !== 'lcl') ?
                                                    <>
                                                        <td>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TableEdit type='number' {...editFields} style={{ width: '4rem', textAlign: 'right' }} record={e.volume} name='value' />
                                                                <TableSelect {...disabled} style={{ border: 'none' }} record={e.volume} name='unit' dataList={['CBM', 'cu ft']} />
                                                            </span>
                                                        </td>
                                                    </> : <></>
                                                }
                                                {(tablestype === 'lcl' && tablesscope !== 'Port-to-Port') ?
                                                    <>
                                                        <td>
                                                            <TableEdit type='number' {...editFields} style={{ width: '3rem', textAlign: 'right' }} record={e.dimensions} name='length' />
                                                        </td>
                                                        <td>
                                                            <TableEdit type='number'  {...editFields} style={{ width: '3rem', textAlign: 'right' }} record={e.dimensions} name='width' />
                                                        </td>
                                                        <td>
                                                            <TableEdit type='number'  {...editFields} style={{ width: '3rem', textAlign: 'right' }} record={e.dimensions} name='height' />
                                                        </td>
                                                    </> : <></>
                                                }
                                                <td>
                                                    {(!props.viewonly || !editFields?.disabled) ?
                                                        <CargoButtons line={i} length={cargoList.length} {...props} cargoDetail={e} />
                                                        : <></>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}