import { routerStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
//import ListDrivers from "./list-drivers"
//import EditDrivers from "./edit-drivers"
import ListDrivers from "./list-drivers-new";
import EditDrivers from "./edit-drivers-new";

export default function MainDrivers(fn,mode) {
  const store = useSnapshot(routerStore);
  if (fn.fn) {
    return <EditDrivers mode="edit" fn={fn.fn}/>;
  }
  switch (store.driverCurrentView) {
    case "list":
      return <ListDrivers />;
      case "view":
        return <EditDrivers mode="view"  />;
    case "edit":
      return <EditDrivers mode="edit" />;
    case "new":
      return <EditDrivers mode="new" />;
      default:
        return <ListDrivers />;
  }
}
