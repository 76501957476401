import Api from "./Api"
import DtQuoteMailView from "../dt/DtQuoteMailView"
import { DtStore } from "../dt/DtStore"
import { ccEmailStore, configStore, toEmailStore } from "./CommonState"
import AguserApi from "./AguserApi"
import Utils from "../util/Utils"
import ReactDOMServer from "react-dom/server"
const GET_VECHICLE_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/vehiclelist`
const CREATE_DT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/new`
const EDIT_DT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/edit`
const CREATE_BK_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/new/booking`
const EDIT_BK_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/edit/booking`
const STOPTRACKING = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/stoptracking`
const UPDATE_BK_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/update/booking`
const CANCEL_BK_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/cancel/booking`
const DELETE_DT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/delete`
const ACTIVE_DT_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/active`
const POST_TRANSPORT_EMAIL_URI = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/sendtransport/email`
const GET_TRANSPORT_QUOTATION_DOCUMENT_URI =(transNum)=> `${process.env.REACT_APP_API_SERVER}/api/v1/dt/getTransportDocument/${transNum}`
const GET_DRIVERNUMSTATUS =(phnum,id)=> `${process.env.REACT_APP_API_SERVER}/api/v1/dt/cheaknumber/${phnum}/${id}`
const GET_VEHICLESTATUS =(vehicle_no,id)=> `${process.env.REACT_APP_API_SERVER}/api/v1/dt/cheakvehicle_no/${vehicle_no}/${id}`
const GET_ERPSTATUS =(id)=> `${process.env.REACT_APP_API_SERVER}/api/v1/dt/cheakErp_no/${id}`
const GET_ACTIONS =(id)=> `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions/actions/${id}`
export default class DtApi {
    static getVechicleList(callback = f => f){
        Api.fetch(GET_VECHICLE_URI, (data) => {
            callback(data)
        })
    }

    static postCreateDt(d,callback = f => f){
        Api.post(CREATE_DT_URI, d, (data) => {
            callback(data)
        })
    }

    static postEditDt(d,callback = f => f){
        Api.post(EDIT_DT_URI, d, (data) => {
            callback(data)
        })
    }

    static postDeleteDt(d,callback = f => f){
        Api.post(DELETE_DT_URI, d, (data) => {
            callback(data)
        })
    }

    static postActiveDt(d,callback = f => f,date){
        console.log(date)
        d["effective_date"] = date
        Api.post(ACTIVE_DT_URI, d, (data) => {
            callback(data)
        })
    }
    static postCreateDtBooking(d,callback = f => f){
        Api.post(CREATE_BK_URI, d, (data) => {
            callback(data)
        })
    }
    static postEditDtBooking(d,callback = f => f){
        Api.post(EDIT_BK_URI, d, (data) => {
            callback(data)
        })
    }
    static postStopTracking(d,callback = f => f){
        Api.post(STOPTRACKING, d, (data) => {
            callback(data)
        })
    }
    static postUpdateDtBooking(d,callback = f => f){
        Api.post(UPDATE_BK_URI, d, (data) => {
            callback(data)
        })
    }
    static postCancelDtBooking(d,callback = f => f){
        Api.post(CANCEL_BK_URI, d, (data) => {
            callback(data)
        })
    }
    static async sendTransportMailCommon(url,customerlist,salesList) { // assumed to be invoked only from QuoteViewMain
        configStore.setModalMessage('Sending Email ...')
        const agUserList = await AguserApi.getAguserList();
        let checkedToMailIds = toEmailStore.emails.filter(email => Utils.isEmail(email) && (email.split('@')[1] === 'agraga.com' || agUserList.map(({ email }) => email).includes(email)));
        let checkedCCMailIds = ccEmailStore.emails.filter(email => Utils.isEmail(email) && (email.split('@')[1] === 'agraga.com' || agUserList.map(({ email }) => email).includes(email)));
        let quoteTemplate = await ReactDOMServer .renderToString(<DtQuoteMailView  customerlist={customerlist} salesList={salesList} />)
        // quoteTemplate = quoteTemplate.replace('Sales Person','Relationship Manager')
        const emailDocument = {
            quoteNum:DtStore.DT._id,
            toEmails: checkedToMailIds,
            ccEmails: checkedCCMailIds,
            reqdata:DtStore.DT.details,
            quoteTemplate
        }
        if (checkedToMailIds.length === 0) {
            configStore.setModalMessage(`No Valid EmailIds Found.`);
            return;
        }       
        Api.post(url, emailDocument, (data) => {
            // configStore.setModalMessage(`Email sent to ${data.message.accepted}`)
            emailDocument.toEmails = []
            emailDocument.ccEmails = []
            configStore.dtCurrentView = "list"
            toEmailStore.emails = []
            ccEmailStore.emails = []
        })
    }
    static sendTransportMail(customerlist,salesList) { // assumed to be invoked only from QuoteViewMain
        DtApi.sendTransportMailCommon(POST_TRANSPORT_EMAIL_URI,customerlist,salesList)
    }
    static async getTransportQuotationDocumentById(transNum) {
        let isFailed = false;
        let header = Api.H()
        header['Content-Type']= 'application/pdf'
        header['Accept']= '*/*'
        try {
            let a = await fetch(GET_TRANSPORT_QUOTATION_DOCUMENT_URI(transNum), { method:"GET",headers: header })
            a = await a.json() 
            return a.data
        } catch (err) {
            configStore.setModalMessage(err?.error || err.toString())
        }
    }

    static async getdrivernumstatus(phnum,id,callback) {
        Api.fetch(GET_DRIVERNUMSTATUS(phnum,id), (data) => {
            callback(data)
        })
    }

    static async getcheakvehicle_no(vehicle_no,id,callback) {
        Api.fetch(GET_VEHICLESTATUS(vehicle_no,id), (data) => {
            callback(data)
        })
    }
    static async getcheakErp_no(erp,id,callback) {
        Api.post(GET_ERPSTATUS(id),{erp:erp}, (data) => {
            callback(data)
        })
    }
    static async getactions(id,callback) {
        Api.fetch(GET_ACTIONS(id), (data) => {
            callback(data)
        })
    }
}