import { Box } from '@mui/material'
import React from 'react'

const CreditList = () => {
  return (
    <Box>
    Credit List
      
    </Box>
  )
}

export default CreditList
