import Utils from "../util/Utils"
import MyactionUtils from "../my-actions/Myactionutils"
import { graphNode } from "../my-actions/shipment-graph"

export class VisibilityOcean {
    static GraphLine(rec){
        let _date = (dt) => {
            return Utils.formatDateShort(new Date(dt))
        }
        let st = rec.shipmentType
        let sc = rec.shipmentScope
        let pod = rec.pod
        let fpod = rec.fpod
        let por = rec.por
        let pol = rec.pol
        let oneGraph = []
        let cv = 0
        let g = new graphNode()
        //1
        

            
            if(por != pol){
                if(rec.por && rec.por.length>0){
                    let g = new graphNode()
    
                    if(rec.por_location && rec.por_location.length>0){
                        g.r2 = rec.por_location
                    }else{
                        g.r2 = rec.por
                    }
                  
                    
                    let n = Number(rec?.containershow)
                    let a = []
                    if(n==-1){
                        if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                            let cv = rec.containerlist.length
                            for (let i = 0; i < rec.containerlist.length; i++) {
                                if(rec.containerlist[i]['container_handover']!=undefined && rec.containerlist[i]['container_handover'].length>0){
                                    a.push(rec.containerlist[i]['container_handover'])
                                }
                            }
                            if(a.length>0){
                                g.isActive = true
                                g.r1 = 'Handover ' + MyactionUtils.getmindate(a)
                                g.tcn = `${a.length} out of ${cv}`
                            }
                        }
                    }else{
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['container_handover']!=undefined && rec.containerlist[i]['container_handover'].length>0){
                                a.push(rec.containerlist[i]['container_handover'])
                            }
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['container_handover']!=undefined && rec.containerlist[n]['container_handover'].length>0){
                            g.isActive = true
                            g.r1 = 'Handover ' + rec.containerlist[n]['container_handover']
                            g.tcn = `${a.length} out of ${cv}`
                        }
                        
                    }
                    a = []
                    if(n==-1){
                        if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                            let cv = rec.containerlist.length
                            for (let i = 0; i < rec.containerlist.length; i++) {
                                if(rec.containerlist[i]['por_to_pol_movement']!=undefined && rec.containerlist[i]['por_to_pol_movement'].length>0){
                                    a.push(rec.containerlist[i]['por_to_pol_movement'])
                                }
                            }
                            if(a.length>0){
                                g.isActive = true
                                g.r3 = 'Gate-out ' + MyactionUtils.getmindate(a)
                                g.cn = `${a.length} out of ${cv}`
                            } 
                        }
                    }else{
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['por_to_pol_movement']!=undefined && rec.containerlist[i]['por_to_pol_movement'].length>0){
                                a.push(rec.containerlist[i]['por_to_pol_movement'])
                            }
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['por_to_pol_movement']!=undefined && rec.containerlist[n]['por_to_pol_movement'].length>0){
                            g.isActive = true
                            g.r3 = 'Gate-out ' + rec.containerlist[n]['por_to_pol_movement']
                            g.cn = `${a.length} out of ${cv}`
                        }
                        
                    }

                    
                    if(rec['container_handover']!=undefined && rec['container_handover'].length>0){
                        g.isActive = true
                        g.r1 = 'Handover ' + rec['container_handover']
                    }
                    if(rec['por_to_pol_movement']!=undefined && rec['por_to_pol_movement'].length>0){
                        g.isActive = true
                        g.r3 = 'Gate-out ' + rec['por_to_pol_movement']
                    }
                    
                    oneGraph.push(g)
                }
            }
            if(rec.pol && rec.pol.length>0){
                let g = new graphNode()
                if(rec.pol_location && rec.pol_location.length>0){
                    g.r2 = rec.pol_location
                }else{
                    g.r2 = rec.pol
                }
              
                let n = Number(rec?.containershow)
                let a = []
                if(n==-1){
                    if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['gatein_pol']!=undefined && rec.containerlist[i]['gatein_pol'].length>0){
                                a.push(rec.containerlist[i]['gatein_pol'])
                            }
                        }
                        if(a.length>0){
                            g.isActive = true
                            g.r1 = 'Gate-in ' + MyactionUtils.getmindate(a)
                            g.tcn = `${a.length} out of ${cv}`
                        }
                    }
                }else{
                    let cv = rec.containerlist.length
                    for (let i = 0; i < rec.containerlist.length; i++) {
                        if(rec.containerlist[i]['gatein_pol']!=undefined && rec.containerlist[i]['gatein_pol'].length>0){
                            a.push(rec.containerlist[i]['gatein_pol'])
                        }
                    }
                    if(rec.containerlist[n] && rec.containerlist[n]['gatein_pol']!=undefined && rec.containerlist[n]['gatein_pol'].length>0){
                        g.isActive = true
                        g.r1 = 'Gate-in ' + rec.containerlist[n]['gatein_pol']
                        g.tcn = `${a.length} out of ${cv}`
                    }
                    
                }
                if(rec.etd_at_pol != undefined && rec.etd_at_pol.length != 0){
                    g.r3 = 'ETD ' + rec.etd_at_pol
                }
                // if(rec.container_handover!=undefined && rec.container_handover.length !=0 && rec.por == rec.pol){
                //     // g.isActive = true
                //     g.r1 = 'Handover ' + rec.container_handover

                // }
                if(rec.gatein_pol !=undefined && rec.gatein_pol.length !=0){
                    g.isActive = true
                    g.r1 = 'Gate-in  ' + rec.gatein_pol

                }
                if(rec.sob_pol != undefined && rec.sob_pol.length != 0){
                    g.isActive = true
                    g.r3 = 'SOB ' + rec.sob_pol
                }
                if(rec.fl_vessel_name != undefined && rec.fl_vessel_name.length != 0){
                    g.al = rec.fl_vessel_name
                }
                if(rec.fl_vessel_number != undefined && rec.fl_vessel_number.length != 0){
                    g.bl = rec.fl_vessel_number
                }
                oneGraph.push(g)
            }
   

            for (let i = 0; i < 4; i++) {
                if(rec[`ts${i+1}_port`]!=undefined && rec[`ts${i+1}_port`]!=null && rec[`ts${i+1}_port`].trim().length > 0){
                    
                    let g = new graphNode()
                    
                    if(rec[`ts${i+1}_port_location`] && rec[`ts${i+1}_port_location`].length>0){
                        g.r2 = rec[`ts${i+1}_port_location`]
                    }else{
                        g.r2 = rec[`ts${i+1}_port`]
                    }
                    if(rec[`ts${i+1}_vessel_name`] != undefined && rec[`ts${i+1}_vessel_name`].length != 0){
                        g.al = rec[`ts${i+1}_vessel_name`]
                    }
                    if(rec[`ts${i+1}_vessel_number`] != undefined && rec[`ts${i+1}_vessel_number`].length != 0){
                        g.bl = rec[`ts${i+1}_vessel_number`]
                    }
                    if(rec[`ts${i+1}_arrival_estimate`] != undefined && rec[`ts${i+1}_arrival_estimate`].length != 0){
                        g.r1= "ETA "+ rec[`ts${i+1}_arrival_estimate`]
                    }
                    if(rec[`ts${i+1}_arrival`] != undefined && rec[`ts${i+1}_arrival`].length != 0){
                        g.isActive = true
                        g.r1= "ATA "+ rec[`ts${i+1}_arrival`]
                    }
                    if(rec[`ts${i+1}_departure_estimate`] != undefined && rec[`ts${i+1}_departure_estimate`].length != 0){
                        g.r3= "ETD "+ rec[`ts${i+1}_departure_estimate`]
                    }
                    if(rec[`ts${i+1}_departure`] != undefined && rec[`ts${i+1}_departure`].length != 0){
                        g.isActive = true
                        g.r3= "ATD "+ rec[`ts${i+1}_departure`]
                    }
                    oneGraph.push(g)
                }
            }
     



            if(pod != fpod){
            if(rec.pod != undefined && rec.pod.length != 0){
                let g = new graphNode()
                if(rec.pod_location && rec.pod_location.length>0){
                    g.r2 = rec.pod_location
                }else{
                    g.r2 = rec.pod
                }
                if(rec.eta_at_pod != undefined && rec.eta_at_pod.length != 0){
                    g.r1= "ETA "+ rec.eta_at_pod
                }
                if(rec.pod_arrival != undefined && rec.pod_arrival.length != 0){
                    g.isActive = true
                    g.r1= "ATA "+ rec.pod_arrival
                }
                if(rec.etd_pod != undefined && rec.etd_pod.length != 0){
                    g.r3= "ETD "+ rec.etd_pod
                }
                let n = Number(rec?.containershow)
                let a = []
                if(n==-1){
                    if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['gateout_to_fpod']!=undefined && rec.containerlist[i]['gateout_to_fpod'].length>0){
                                a.push(rec.containerlist[i]['gateout_to_fpod'])
                            }
                        }
                        if(a.length>0){
                            g.isActive = true
                            g.r3 = 'ATD ' + MyactionUtils.getmindate(a)
                            g.cn = `${a.length} out of ${cv}`
                        }
                    }
                }else{
                    let cv = rec.containerlist.length
                    for (let i = 0; i < rec.containerlist.length; i++) {
                        if(rec.containerlist[i]['gateout_to_fpod']!=undefined && rec.containerlist[i]['gateout_to_fpod'].length>0){
                            a.push(rec.containerlist[i]['gateout_to_fpod'])
                        }
                    }
                    if(rec.containerlist[n] && rec.containerlist[n]['gateout_to_fpod']!=undefined && rec.containerlist[n]['gateout_to_fpod'].length>0){
                        g.isActive = true
                        g.r3 = 'ATD ' + rec.containerlist[n]['gateout_to_fpod']
                        g.cn = `${a.length} out of ${cv}`
                    }
                    
                }
                
                if(rec.gateout_to_fpod!=undefined && rec.gateout_to_fpod.length>0){
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.gateout_to_fpod
                }
                
                oneGraph.push(g)
            }
        }
       
                if(rec.fpod != undefined && rec.fpod.length != 0){
                    let g = new graphNode()
                    if(rec.fpod_location && rec.fpod_location.length>0){
                        g.r2 = rec.fpod_location
                    }else{
                        g.r2 = rec.fpod
                    }
                    if(rec.eta_fpod != undefined && rec.eta_fpod.length != 0){
                        g.r1= "ETA "+ rec.eta_fpod
                    }
                    let n = Number(rec?.containershow)
                    let a = []
                    let b = []
                    if(n==-1){
                        if (rec.containerlist != undefined && rec.containerlist.length != 0) {
                            let cv = rec.containerlist.length
                            for (let i = 0; i < rec.containerlist.length; i++) {
                                if(rec.containerlist[i]['gatein_fpod']!=undefined && rec.containerlist[i]['gatein_fpod'].length>0){
                                    a.push(rec.containerlist[i]['gatein_fpod'])
                                }
                                if(rec.containerlist[i]['container_pickup_fpod']!=undefined && rec.containerlist[i]['container_pickup_fpod'].length>0){
                                    b.push(rec.containerlist[i]['container_pickup_fpod'])
                                }
                            }
                            if(a.length>0){
                                g.isActive = true
                                g.r1 = 'ATA ' + MyactionUtils.getmindate(a)
                                g.tcn = `${a.length} out of ${cv}`
                            }
                            if(b.length>0){
                                g.r3 = 'Picked-up ' + MyactionUtils.getmindate(b)
                                g.isActive = true
                                g.cn = `${a.length} out of ${cv}`
                            }
                        }
                    }else{
                        let cv = rec.containerlist.length
                        for (let i = 0; i < rec.containerlist.length; i++) {
                            if(rec.containerlist[i]['gatein_fpod']!=undefined && rec.containerlist[i]['gatein_fpod'].length>0){
                                a.push(rec.containerlist[i]['gatein_fpod'])
                            }
                            if(rec.containerlist[i]['container_pickup_fpod']!=undefined && rec.containerlist[i]['container_pickup_fpod'].length>0){
                                b.push(rec.containerlist[i]['container_pickup_fpod'])
                            }
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['gatein_fpod']!=undefined && rec.containerlist[n]['gatein_fpod'].length>0){
                            g.r1 = 'ATA ' + rec.containerlist[n]['gatein_fpod']
                            g.tcn = `${a.length} out of ${cv}`
                        }
                        if(rec.containerlist[n] && rec.containerlist[n]['container_pickup_fpod']!=undefined && rec.containerlist[n]['container_pickup_fpod'].length>0){
                            g.r3 = 'Picked-up ' + rec.containerlist[n]['container_pickup_fpod']
                            g.isActive = true
                            g.cn = `${a.length} out of ${cv}`
                        }
                        
                    }
                    if(rec['gatein_fpod']!=undefined && rec['gatein_fpod'].length>0){
                        g.isActive = true
                        g.r1 = 'ATA ' + rec['gatein_fpod']
                    }
                    if(rec['container_pickup_fpod']!=undefined && rec['container_pickup_fpod'].length>0){
                        g.isActive = true
                        g.r3 = 'Picked-up ' + rec['container_pickup_fpod']
                    }
                    oneGraph.push(g)
                }

        return oneGraph
    }
    static AirLine(rec){
        let oneGraph =[]
        
            if (rec.departure_airport && rec.departure_airport.length>0) {
                let g = new graphNode()
                if(rec.departure_airport_location && rec.departure_airport_location.length>0){
                    g.r2 = rec.departure_airport_location
                }else{
                    g.r2 = rec.departure_airport
                }
                let cv = 0
                if (rec.vorgin != undefined && rec.vorgin.length != 0) {
                    cv = rec.vorgin.length
                    let a = []
                    for (let i = 0; i < rec.vorgin.length; i++) {
                        if(rec.vorgin[i]['arrival_dispatch_airport']!=undefined && rec.vorgin[i]['arrival_dispatch_airport'].length>0){
                            a.push(rec.vorgin[i]['arrival_dispatch_airport'])
                        }
                    }
                    if(a.length>0){
                        g.isActive = true
                        g.r1 = 'Gate-in ' + MyactionUtils.getmindate(a)
                        g.tvl = `${a.length} out of ${cv}`
                    }
                    
                }
                if(rec['arrival_dispatch_airport']!=undefined && rec['arrival_dispatch_airport'].length>0){
                    g.isActive = true
                    g.r1 = 'Gate-in ' + rec['arrival_dispatch_airport']
                }
                if (rec.etd_airport_of_departure && rec.etd_airport_of_departure.length>0) {
                    g.r3 = 'ETD ' + rec.etd_airport_of_departure
                }
                if (rec.loaded_on_board && rec.loaded_on_board.length>0) {
                    g.isActive = true
                    g.r3 = 'ATD ' + rec.loaded_on_board
                }
                if(rec.flight_number_departure_airport && rec.flight_number_departure_airport.length>0){
                    g.al = rec.flight_number_departure_airport
                }
                oneGraph.push(g)
            }

            for (let i = 0; i < 2; i++) {
                if(rec[`ts${i+1}_airport`]!=undefined && rec[`ts${i+1}_airport`]!=null && rec[`ts${i+1}_airport`].trim().length > 0){
                    let g = new graphNode()
                    if(rec[`ts${i+1}_airport_location`] && rec[`ts${i+1}_airport_location`].length>0){
                        g.r2 = rec[`ts${i+1}_airport_location`]
                    }else{
                        g.r2 = rec[`ts${i+1}_airport`]
                    }
                    if(rec[`ts${i+1}_arrival_estimate`] && rec[`ts${i+1}_arrival_estimate`].length>0){
                        g.r1 = 'ETA '+rec[`ts${i+1}_arrival_estimate`]
                    }
                    if(rec[`ts${i+1}_arrival`] && rec[`ts${i+1}_arrival`].length>0){
                        g.isActive = true
                        g.r1 = 'ATA '+rec[`ts${i+1}_arrival`]
                    }
                    if(rec[`ts${i+1}_departure_estimate`] && rec[`ts${i+1}_departure_estimate`].length>0){
                        g.r3 = 'ETD '+rec[`ts${i+1}_departure_estimate`]
                    }
                    if(rec[`ts${i+1}_departure`] && rec[`ts${i+1}_departure`].length>0){
                        g.isActive = true
                        g.r3 = 'ATD '+rec[`ts${i+1}_departure`]
                    }
                    if(rec[`flight_number_ts${i+1}`] && rec[`flight_number_ts${i+1}`].length>0){
                        g.al = rec[`flight_number_ts${i+1}`]
                    }
                    oneGraph.push(g)
                }
            }

            if (rec.arrival_airport && rec.arrival_airport.length>0) {
                let g = new graphNode()
                if(rec.arrival_airport_location && rec.arrival_airport_location.length>0){
                    g.r2 = rec.arrival_airport_location
                }else{
                    g.r2 = rec.arrival_airport
                }
                if (rec.eta_at_arrival_airport && rec.eta_at_arrival_airport.length>0) {
                    g.r1 = 'ETA ' + rec.eta_at_arrival_airport
                }
                if (rec.ata_at_arrival_airport && rec.ata_at_arrival_airport.length>0) {
                    g.isActive = true
                    g.r1 = 'ATA ' + rec.ata_at_arrival_airport
                }
                let cv = 0
                if (rec.vdes != undefined && rec.vdes.length != 0) {
                    cv = rec.vdes.length
                    let a = []
                    for (let i = 0; i < rec.vdes.length; i++) {
                        if(rec.vdes[i]['dispatched_from_arrival_airport']!=undefined && rec.vdes[i]['dispatched_from_arrival_airport'].length>0){
                            a.push(rec.vdes[i]['dispatched_from_arrival_airport'])
                        }
                    }
                    if(a.length>0){
                        g.isActive = true
                        g.r3 = 'Gate-out ' + MyactionUtils.getmindate(a)
                        g.vl = `${a.length} out of ${cv}`
                    }
                    
                }
                if(rec['dispatched_from_arrival_airport']!=undefined && rec['dispatched_from_arrival_airport'].length>0){
                    g.isActive = true
                    g.r3 = 'Gate-out ' + rec['dispatched_from_arrival_airport']
                }
                oneGraph.push(g)
            }
            return oneGraph
        }
}