import { proxy, useSnapshot } from "valtio"
import { useState, useEffect, useRef } from "react"
import {
    aguserStore, configStore, entityStore, loginStore
} from '../state/CommonState'
import { Agyesno } from "../components/Agyesno"
import AguserApi from "../state/AguserApi"


export default function InviteUser(props) {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [admin, setAdmin] = useState(props.admin ? props.admin : 'No')
    useSnapshot(aguserStore)
    let txtMail = useRef()

    return (
        <div className="panel"
            style={{
                backgroundColor: 'white', paddingLeft: '10px',
                borderRadius: '10px', paddingBottom: '3px', border: '1px solid lightgrey'
            }} {...props}>
            <div style={{
                borderRadius: '10px', backgroundColor: 'white', display: 'flex',
                paddingLeft: '5px', paddingRight: '5px'
            }}>
                {
                    (props.admin) ?
                        <>
                            <div style={{ marginRight: '8px', marginTop: '5px', color: '#2c358a' }}>
                                <span className="label">Name</span>
                            </div>
                            <div style={{ marginTop: '5px', width: '10rem' }}>
                                <input className="input is-small" value={name}
                                    onChange={(e) => setName(e.target.value)} />
                            </div>
                        </> : <></>
                }
                <div style={{ marginRight: '8px', marginTop: '5px', color: '#2c358a' }}>
                    <span className="label">Email</span>
                </div>
                <div style={{ marginTop: '5px', width: '10rem' }}>
                    <input className="input is-small" value={email}
                        ref={txtMail} 
                        type='email' onInput={(e) => {
                            e.target.value = e.target.value.replace(/ /g, '')
                            setEmail(e.target.value)
                        console.log(e.target.value)}} />
                </div>
                <div style={{ marginLeft: '5px', marginRight: '15px' }}>
                    <Agyesno label='Admin?' value={admin} callback={setAdmin} />
                </div>
                <div style={{ marginTop: '5px' }}>
                    <button className="button is-small islink"
                        onClick={() => {
                            let entity = entityStore.list.filter(e => e.gst === props.gst)
                            if (entity.length !== 1) {
                                configStore.setModalMessage(`Issue in getting entity for GST ${props.gst}`)
                                return
                            }
                            entity = entity[0]
                            let [firstName, lastName] = name.split(' ')
                            if (!firstName) firstName = ''
                            if (!lastName) lastName = ''
                            let user = {
                                firstName,
                                lastName,
                                email: email.trim(),
                                companyName: entity.entityName + ' ' + entity.state,
                                country: entity.country
                            }
                            let role = 'CLIENT USER'
                            if (admin === 'Yes') role = 'CLIENT ADMIN'
                            user.role = role
                            user.status = 'ACTIVE'
                            user.entityList = [{
                                email: email.trim(),
                                gst: props.gst,
                                role: role
                            }]
                            AguserApi.signUpUser(user)
                            props.resetState('')
                        }}>
                        Confirm
                    </button>
                </div>
                <div style={{ marginTop: '5px' }}>
                    <button className="button is-small is-danger"
                        onClick={() => props.resetState('')}>
                        <span className="icon is-small">
                            <i className="fas fa-xmark"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}