import { useState, useEffect } from "react"
import on from '../../components/on.png'
import off from '../../components/off.png'

export function Dtyesno(props) {
    const {  record, name, callback = f => f , isDisabled = false } = props
    const [checked1, setChecked] = useState(record?record[name]:props.value)
    if (record) {
        if (record[name] !== checked1) setChecked(record[name])
    }
    useEffect(() => {

    })
    return (
        <div className="field" style={{textAlign: 'center'}}>
            <div className="control" style={{cursor : isDisabled ? "not-allowed" : "default"}}>
            <div className="columns is-vcentered">
                                                    <div className="column is-4">No</div>
                                                    <div className="column is-4" onClick={ isDisabled ? 
                       null :
                        (() => {
                            console.log('inside yes/no')
                            if (props.disabled) return
                            const newval = checked1 === 'Yes'? 'No' : 'Yes'
                            if (props.record) record[name] = newval
                            callback(newval)
                            setChecked(newval)
                            console.log(newval)
                    })}>{
                            (checked1 === 'Yes') ?
                                <img height='36px' width='36px' src={on}></img> :
                                <img height='36px' width='36px' src={off}></img>
                        }</div>
                                                    <div className="column is-4">Yes</div>
                                                    </div>
           
            </div>
        </div>
    )
}