import { Box, Button, Checkbox, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import { AntSwitch } from '../mui-components/AntSwitch'
import logo from "../assets/images/Logo-new.svg"
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import Carousel from '../mui-components/Carousel'
import { useForm } from "react-hook-form";
import CircleIcon from '@mui/icons-material/Circle';
import AguserApi from '../state/AguserApi'
import { configStore } from '../state/CommonState'
// import { useMode } from '../context/AuthContext'
function ChangePassword({token,callback}) {
  // const {setMode : callback} = useMode();
  const [error, setError] = useState({
    password:"",
    confirmPassword:""
  }
)
  const [passwordCondition, setpasswordCondition] = useState({
    lower: false,
    uper: false,
    number:false,
    specialCharecter:false,
    length:false
  });
  const signupForm = useForm({
    defaultValues:{
        password : "",
        confirmPassword : "",
    }
})
const {register,handleSubmit,watch,formState:{errors},getValues,reset,control} = signupForm;
console.log(errors);
const submit = async(data)=>{
  if (error.password || error.confirmPassword) return ""
  console.log("The sign up data ",data,token);
  await AguserApi.resetPasswordAguserNew({...data,token:token},(data)=>{
    console.log(data);
    configStore.setModalMessage(data.msg)
    // callback("login")
    window.location.replace("/")
})

}
const handleChange = (type,e)=>{
console.log(e.target.value);
if (type === "confirmPassword" || type === "password") {
  if (getValues("password") !== getValues("confirmPassword")) {
    setError(prevState => {
      return {...prevState,...{confirmPassword : "The confirmed password does not match."}};
    });
  }
  else{
    setError(prevState => {
      return {...prevState,...{confirmPassword : ""}};
    });
  }
  
}
if(type === "password"){
  checkStrength(e.target.value)
}
}

const strength = { 
1: "very Weak", 
2: "Weak", 
3: "Meduim", 
4: "Strong", 
5: "Strong+++", 
}; 
function checkStrength(pass) { 
let count = 0; 
if (pass.length >= 8) {
  count++
setpasswordCondition(prevState => {
  return {...prevState,...{length : true}};
});
}else {
  setpasswordCondition(prevState => {
    return {...prevState,...{length : false}};
  });
}
let regex1 = /[a-z]/; 
if (regex1.test(pass)) {
    count++
    setpasswordCondition(prevState => {
      return {...prevState,...{lower : true}};
    });
  }else{
    setpasswordCondition(prevState => {
      return {...prevState,...{lower : false}};
    });
}
let regex2 = /[A-Z]/; 
if (regex2.test(pass)){
   count++
   setpasswordCondition(prevState => {
    return {...prevState,...{uper : true}};
  });
  }else{
    setpasswordCondition(prevState => {
      return {...prevState,...{uper : false}};
    });
  }
let regex3 = /[\d]/; 
if (regex3.test(pass)){
   count++
   setpasswordCondition(prevState => {
    return {...prevState,...{number : true}};
  }); 
  }else{
    setpasswordCondition(prevState => {
      return {...prevState,...{number : false}};
    }); 
  }
let regex4 = /[!@#$%^&*.?]/; 
if (regex4.test(pass)){
   count++
   setpasswordCondition(prevState => {
    return {...prevState,...{specialCharecter : true}};
  });
  }else{
    setpasswordCondition(prevState => {
      return {...prevState,...{specialCharecter : false}};
    });
  }
  if (count < 5) {
    setError(prevState => {
      return {...prevState,...{password : "Please add all necessary characters to create safe password."}};
    });
  }
  else{
    setError(prevState => {
      return {...prevState,...{password : ""}};
    });
  }
console.log(pass, "Pasword is " + strength[count],count); 
} 

  return (
    <>
  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
    <div className='logo_container_for_mobile'>
         <img src={logo}></img>
    </div>
      <div className='field_wrapper'>
      <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={"1rem"} gutterBottom>Forgot Password</Typography>
      <InputField 
      placeholder={"Enter New Password"}
      label={"New Password"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.password?.message || error.password}
      input_wrapeer={"input_wrapeer"}
      type={"password"}
      callback={(e)=>console.log(e)}
      container={"input_icon_styles"}
      icon_childer={true}
      id={"password"}
      children={{...register("password",{onChange:(e)=>handleChange("password",e),required: "Password is Required"})}}
      ></InputField>
      <InputField 
      placeholder={"Enter Confirm Password"}
      label={"Confirm Password"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.confirmPassword?.message || error.confirmPassword }
      input_wrapeer={"input_wrapeer"}
      type={"password"}
      callback={(e)=>console.log(e)}
      container={"input_icon_styles"}
      id={"confirmPassword"}
      icon_childer={true}
      children={{...register("confirmPassword",{onChange:(e)=>handleChange("confirmPassword",e),required: "confirmPassword is Required"})}}
      ></InputField>
        {getValues("password")?<div className='password_wrapper'>
        <div>
      <p style={{fontSize:"10px",color:passwordCondition.lower?"#000":"red"}}><CircleIcon fontSize='2px'/> One lowercase character</p>
      <p style={{fontSize:"10px",color:passwordCondition.uper?"#000":"red"}}><CircleIcon fontSize='2px'/> One uppercase character</p>
      <p style={{fontSize:"10px",color:passwordCondition.number?"#000":"red"}}><CircleIcon fontSize='2px'/> One number</p>
        </div>
        <div>
      <p style={{fontSize:"10px",color:passwordCondition.specialCharecter?"#000":"red"}}><CircleIcon fontSize='2px'/> One symbol</p>
      <p style={{fontSize:"10px",color:passwordCondition.length?"#000":"red"}}><CircleIcon fontSize='2px'/> 8 characters minimum</p>
        </div>
      </div>:null}
        <Button variant="contained" className='btn_color' fullWidth onClick={handleSubmit(submit)} >Change Password</Button>
      </div>
      
      <p className='note_info'>Remember your password? <span className='clr_blue' 
      onClick={()=>{
            window.location.replace('/')
            callback('login')
          }}
          >Login</span></p>
    </div>

  </div>
    
    </>
  )
}


export default ChangePassword

