import { Avatar, Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BusinessIcon from '@mui/icons-material/Business';
import EntityCreationModal from './entityCreationModal';



const   EntityCreWarnPopup = (props) =>  {
    const [showEntityCreModal, setshowEntityCreModal] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        bgcolor: '#f0f0f0',
        border: '2px solid grey.500',
        boxShadow: 24,
        borderRadius: '16px',
        pt: 2,
        px: 4,
        pb: 3,
    };

    function addNewEntity () {
        setshowEntityCreModal(true)

    }
    function handleEntityPopup (x) {
        if (x === false){
            props.cancelfn(0)
        }

    }
    let message = 'To Confirm your bookings you have to enter your entity details in manage entity section'
    if(props.dashboardTabIndex === 2){
        message = 'To Confirm your view enquiry you have to enter your entity details in manage entity section'
    } if(props.dashboardTabIndex === 3){
        message = 'To Confirm your to View Contract you have to enter your entity details in manage entity section'
    }if(props.dashboardTabIndex === 4){
        message = 'To Confirm your to View Pending Items  you have to enter your entity details in manage entity section'
    }if(props.dashboardTabIndex === 5){
        message = 'To Confirm your to View Booking Items  you have to enter your entity details in manage entity section'
    }
  return (
    <Box>
    {!showEntityCreModal && (
        <Modal
  open={true}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  >
 <Box sx={{ ...style, width: '35%' }}>
 <IconButton sx={{ position: 'absolute', top: '-25px', left: '50%', transform: 'translateX(-50%)', zIndex: 1, padding: '0.5%', backgroundColor: '#f0f0f0' }}>
 <Avatar style={{ backgroundColor: '#266ed4' }}>
                            <ErrorIcon style={{ backgroundColor: '#266ed4' }} />
                        </Avatar>
                    </IconButton>
  <Typography variant="h6" align='center' sx={{ fontWeight: 500 }} mt={2} >{message}</Typography>
  <Stack direction='row' spacing={2} pb={0} mt={2} mb={6}>
  <SettingsIcon  style={{marginLeft : '5%'}} />
   <Typography>Settings</Typography>
   <ChevronRightIcon></ChevronRightIcon>
   <BusinessIcon/>
   <Typography>Entity</Typography>
   <ChevronRightIcon></ChevronRightIcon>
   <BusinessIcon/>
   <Typography>Entity Details</Typography>

  </Stack>
  <Stack direction='row' spacing={2} pb={0} >
  <Button variant='outlined' onClick={()=>props.cancelfn(0)} style={{marginLeft : '40%'}}>Cancel</Button>
  <Button variant='contained' onClick={()=>addNewEntity()}>Manage Entity</Button>

   </Stack>
  </Box>
    </Modal>
    )

    }
    {
        showEntityCreModal && (
            <EntityCreationModal props={(x)=>handleEntityPopup(x)} type='fromQuickQuote'></EntityCreationModal>
        )
    }
 
    </Box>
  )
}

export default EntityCreWarnPopup