import Api from "./Api"
import { entityStore, configStore, EntityV2Store } from "./CommonState"
const SHIPPERURI = {
    LIST: `${process.env.REACT_APP_API_SERVER}/api/v1/entity`,
    GET: (id, country) => `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listShippers/${id}/${country}`,
    GETBYID: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/shipper/getBy/${id}`,
    GETENTITYWITHUSER: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/entitywithuser/${id}`,
    PUT: `${process.env.REACT_APP_API_SERVER}/api/v1/entity`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/addShippers`,
    CREATEFORUSER: (email) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/addforuser/${email}`,
    CHANGE: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/change/${id}`
}
export default class ShipperApi {
    // static getList(callback = f => f) {
    //     Api.fetch(ENTITYURI.LIST, (data) => {
    //         EntityV2Store.list = data  
    //         EntityV2Store.Cuslist= data.filter((r,i) => r.entityType === "Customer")
    //         callback(data)
    //     })
    // }
    static getShipper(id, country) {
        return new Promise((resolve, reject) => {
            Api.fetch(SHIPPERURI.GET(id, country), (data) => {
                resolve(data);
            });
        });
    }
    static getShipperById(id) {
        return new Promise((resolve, reject) => {
            Api.fetch(SHIPPERURI.GETBYID(id), (data) => {
                resolve(data);
            });
        });
    }
    
    // static getEntityWithUser(id,callback=f=>f) {
    //     Api.fetch(ENTITYURI.GETENTITYWITHUSER(id), (data) => {
    //       callback(data)
    //     })
    // }
    // static saveEntity() {
    //     console.log('about to call api.put')
    //     Api.put(ENTITYURI.PUT, entityStore.entityRec, (data) => {
    //         configStore.setModalMessage('Entity Details Saved')
    //     })
    // }
    // static saveGivenEntity(entity) {
    //     console.log('about to call api.put')
    //     Api.put(ENTITYURI.PUT, entity, (data) => {
    //         //configStore.setModalMessage('Entity Details Saved')
    //     })
    // }
    static createShipper(req,callback=f=>f) {
        Api.post(SHIPPERURI.POST, req, (data) => {
            callback(data)
        })
    }
    // static createEntityForUser(email, entity, callback=f=>f) {
    //     console.log('about to call api.post')
    //     Api.post(ENTITYURI.CREATEFORUSER(email), entity, (data) => {
    //         configStore.setModalMessage('New Entity created')
    //         callback(data)
    //     })
    // }
    // static changeEntity(id) {
    //     Api.fetch(ENTITYURI.CHANGE(id), (data) => {
            
    //     })
    // }
}