import { Box, Typography } from "@mui/material";

export function CustomTabBar({ selected, title, icon, onClick }) {
    return <Box onClick={onClick} sx={{
        // width:"180px",
        height: "100%", borderRadius: "5px", display: "flex", flexDirection: 'row', gap: 1, justifyContent: "center", alignItems: "center", padding: "0px 8px", backgroundColor: selected ? "#2A6ED4" : "transparent", color: selected ? "white" : "#555555", '&:hover': {
            cursor: "pointer"
        }
    }}>
        <img style={{ filter: selected ? "brightness(0) invert(1)" : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)" }} height="13%" width="13%" src={icon} />
        <Typography fontSize="14px" fontFamily="Figtree" fontWeight={600}>{title}</Typography>
    </Box>
}