import { Box, Button, Dialog, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import cancelicon from "../assets/images/cancelicon.svg";
import { useEffect, useState } from "react";
import { bookingsStore } from "../state/CommonState";
import { taskStore } from "../mytasks/task-store";


export function CancelBookingRequest({open,onCancel,onSubmit}){
    const [value, setValue] = useState('Placed by Mistake');
    const [reason,setReason] = useState("");
    const handleChange = (event) => {
        setValue(event.target.value);
      };

      useEffect(()=>{
         return ()=>{
            bookingsStore.requestCancellationReason = null;
            taskStore.current.actionName = "";
         }
      },[])

      useEffect(()=>{
        bookingsStore.requestCancellationReason = reason;
      },[reason])

      useEffect(()=>{
        if(value==="Other reasons"){
            bookingsStore.requestCancellationReason = null
        }else{
            bookingsStore.requestCancellationReason = value
        }
      },[value])
    
   return <Dialog 
   slotProps={{
    backdrop :  {sx: { 
        backgroundColor: 'transparent',
        backdropFilter: 'blur(3px)' } }
}}
   PaperProps={{
    sx:{
        backgroundColor:"transparent",
        minWidth:"637px",
        border:"none",
        boxShadow:"none"
    }
   }} open={open} sx={{
    backgroundColor:"transparent !important",
    
    "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2C358A !important"
        }
    }}>
   
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%",backgroundColor:"transparent"}}>
        <Box sx={{width:"637px",height:"430px",position:"relative",backgroundColor:"transparent",display:"flex",justifyContent:"end"}}>
          
            <Box sx={{display:"flex",flexDirection:"column",height:"400px",backgroundColor:"white",width:"100%",alignItems:"center",borderRadius:"10px",position:"absolute",bottom:0,padding:"15px",justifyContent:"space-between",border:"solid 1px #E8E8E8"}}>
                 {  value==="Other reasons" ? <Box sx={{ height:"100%",width:"100%",display:"flex",flexDirection:"column",alignItems:"center",gap:3,marginTop:"30px"}}>
                 <Typography sx={{fontFamily:"Figtree",fontSize:"20px",fontWeight:"bold"}}>
                 Mention your reason below :-
                          </Typography>
                          <TextField 
                          value={reason}
                          onChange={(e)=>{
                            setReason(e.target.value);
                          }}
                          fullWidth InputProps={{
                            style : {
                                height:"220px",
                                borderRadius:"10px",
                                alignItems:"start"
                            }
                          }} multiline maxRows={4} placeholder="Type here"/>
                 </Box> : <> <Box sx={{display:"flex",flexDirection:"column",width:"max-content",marginTop:"40px",gap:2}}>
                          <Typography sx={{fontFamily:"Figtree",fontSize:"20px",fontWeight:"bold"}}>
                          Please select valid reason for cancellation ?
                          </Typography>
                          <Box>
                          <FormControl component="fieldset">

<RadioGroup
  value={value}
  onChange={handleChange}
>
  <FormControlLabel style={{color:"black",fontWeight:"bold",fontSize:"18px"}} value="Placed by Mistake" control={<Radio  />} label="Placed by Mistake ?" />
  <FormControlLabel style={{color:"black",fontWeight:"bold",fontSize:"18px"}} value="Price too high" control={<Radio />} label="Price too high ?" />
  <FormControlLabel style={{color:"black",fontWeight:"bold",fontSize:"18px"}} value="Other reasons" control={<Radio />} label="Other reasons ?" />
</RadioGroup>
</FormControl>
                          </Box>
                  </Box>
                  <Typography sx={{fontFamily:"Figtree",fontSize:"14px"}} ><span style={{fontWeight:"bold"}}>Important:</span> Cancellation charges might be applicable. If you still want to proceed with cancellation, Team Agraga will check and confirmation cancellation charges, if any.</Typography>
                  </>
                 }
                  <Box sx={{display:"flex",flexDirection:"row",justifyContent:"end",gap:1,width:"100%"}}>
                  <Button onClick={()=>{
                    if(onCancel){
                        onCancel();
                    }
                    setValue('Placed by Mistake');
                  }} sx={{textTransform:"capitalize",backgroundColor:"#F5F5F5",color:"#2B6ED4",borderRadius:"5px","&:hover":{
                    backgroundColor:"#F5F5F5",color:"#2B6ED4"
                  }}}>Cancel</Button>
                    <Button onClick={()=>{
                        if(onSubmit){
                            onSubmit(bookingsStore.requestCancellationReason);
                        }

                    }} sx={{textTransform:"capitalize", backgroundColor:"#2B6ED4",color:"#F5F5F5",borderRadius:"5px","&:hover":{
                    backgroundColor:"#2B6ED4",color:"#F5F5F5"
                  }}}>Submit</Button>
                  </Box>
            </Box>
            <Box sx={{width:"100%",height:"60px",position:"absolute",top:0,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"60px",width:"60px",borderRadius:"50%",backgroundColor:"#FEEFEF"}}>
                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"40px",width:"40px",borderRadius:"50%",backgroundColor:"#F25B5B"}}>
                    <img src={cancelicon}/>
                    </Box>
                </Box>
            </Box>
        </Box>
        </Box>
 
   </Dialog>
}