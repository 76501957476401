import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { TsUnloc } from "../components/AgComponents";
import { configStore } from "../state/CommonState";
import { useEffect, useState } from "react";
import { OneDatetable,  OneInput5table } from "../mytasks/act-common";
import { dsrstatic_FCLDockDock_keys, dsrstatic_FCLDockFactory_keys, dsrstatic_FCLFactoryDock_keys, dsrstatic_FCLFactoryFactory_keys, dsrstatic_cn } from "./dsr_static";

export function CnviewModal(props){
useSnapshot(myactionsstore)
useSnapshot(configStore)

let ret = configStore.modalvisibledata
let st = ret.shipmentType
let sc = ret.shipmentScope
let por = ret.por
let pol = ret.pol
let pod = ret.pod
let fpod = ret.fpod
let containerlist = ret.containerlist

if(containerlist == undefined || containerlist.length==0){
    //numContainers
    containerlist = []
    let a = ret?.booking?.contract?.containerList
    if(a){
        for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < Number(a[i]["numContainers"]); j++) {
               containerlist.push({"container_types":a[i]["containerType"]})
            }
       }
    }
    

}

let keys = []
let obj = dsrstatic_cn
let arr = ["gatein_pol","gatein_fpod","container_pickup_fpod","empty_return"]

if(sc == "Port-to-Door" || sc =="Door-to-Door"){
    arr.push("estimate_delivery_date_to_consignee")
    arr.push("container_delivery")
    arr.push("container_arrival_at_fpod")
    arr.push("container_de_vanning")
}
if(sc == "Door-to-Port" || sc =="Door-to-Door"){
    arr.push("empty_container_pickup")
    arr.push("container_arrival_factory")
    arr.push("loaded_and_dispatch")
    arr.push("stuffing_confirmation")
}
if(por != pol){
    arr.push("container_handover")
    arr.push("por_to_pol_movement")
}
if(pod != fpod){
    arr.push("gateout_to_fpod")
}

if("FCL (Factory/Factory)"==st){
    keys = dsrstatic_FCLFactoryFactory_keys
}

if("FCL (Dock/Factory)"==st){
    keys = dsrstatic_FCLDockFactory_keys
}

if("FCL (Factory/Dock)"==st){
    keys = dsrstatic_FCLFactoryDock_keys
}
if("FCL (Dock/Dock)"==st){
    keys = dsrstatic_FCLDockDock_keys
}




let modalClass = 'modal'
    if (myactionsstore.cnviewModalVisible) modalClass += ' is-active'
    return (
        <>
        {
            (myactionsstore.cnviewModalVisible)?<div className={modalClass} onClick={() => { myactionsstore.cnviewModalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px',width:"90%" }} >
                <section className="modal-card-body"  >
                <div className="table-container" onClick={(e)=> e.stopPropagation()}>
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                        <th>{obj['container_number']}</th>
                        <th>{obj['container_types']}</th>
                        {
                            keys.map(e=>{
                                if(arr.indexOf(e) != -1){
                                    return <th>{obj[e]}</th>
                                }
                            })
                        }
                    </thead>
                    <tbody>
                        {
                            containerlist.map(e=>{
                                return <>
                                <tr>
                                <td>{(e['container_number'])?e['container_number']:""}</td>
                                <td>{e['container_types']}</td>
                                {
                                    keys.map(a=>{
                                        if(arr.indexOf(a) != -1){
                                            return <td>{(e[a])?e[a]:""}</td>
                                        }
                                    })
                                }
                                </tr>
                                </>
                                
                            })
                        }
                    </tbody>
                    
                    </table></div>


                        <button class="button is-warning" onClick={() => myactionsstore.cnviewModalVisible = false} >close</button>
                    </section>
            </div>
        </div>:<></>
        }
        </>
    )
}