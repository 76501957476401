import { usePlacesWidget } from "react-google-autocomplete";
import { useEffect, useRef, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import "./ag-address.css"
import on from '../on2.png'
import off from '../off.png'
import Api from "../../state/Api";
import { configStore, loginStore, modalStore } from "../../state/CommonState";
import Utils from "../../util/Utils";
import { useGlobalState } from "../../GlobalState";
import { enquiryStore, routeFlag } from "../../enquiry/enquiry-data"
import AsyncSelect from 'react-select/async';
import Newapi from "../../state/new-api";



export function AgAddress(props) {
  const { label, record, name, callback, isValid = true, validationMsg, isDisabled, ShipmentType, handleChange, isInvalid } = props
  let flag = (name == 'origin' ? routeFlag.originRouteFlag : routeFlag.destinationFlag);
  const [checked, setChecked] = useState(flag)
  useSnapshot(record)
  useSnapshot(enquiryStore)


  // if (record) {
  if (flag !== checked) setChecked(flag)
  // }

  return (
    <>
      <div class="columns m-0" >
        <div class="column is-2 p-0" style={{ fontSize: "12px" }}><strong>Port</strong></div>
        <div class="column is-2 p-0">
          <div onClick={isDisabled ?
            null :
            (() => {
              record[name] = ''
              // let cargoList = enquiryStore.current.cargoDetails
              // configStore.addressvalidation = true
              console.log('inside yesno')
              if (props.disabled) return
              console.log(checked, 'newval');
              const newval = checked === 'Yes' ? 'No' : 'Yes'
              if (name == 'origin') {
                routeFlag.originRouteFlag = newval
              } else {
                routeFlag.destinationFlag = newval
              }
              // console.log(rec.cargoDetails, "CCCCCCCCCCCCCCCCC")
              enquiryStore.current.cargoDetails = [
                {
                  packageType: '',
                  quantity: '',
                  weight: {
                    value: 0.0,
                    unit: 'Kgs'
                  },
                  chargeableWeight: {
                    value: 0.0,
                    unit: 'Kgs'
                  },
                  volume: {
                    value: 0.0,
                    unit: 'CBM'
                  },
                  totalWeight: {
                    value: 0.0,
                    unit: 'Kgs'
                  },
                  totalVolume: {
                    value: 0.0,
                    unit: 'CBM'
                  },
                  dimensions: {
                    length: 0.0,
                    width: 0.0,
                    height: 0.0,
                    unit: 'Cms'
                  }
                }
              ]
              // console.log(rec.cargoDetails, "CCCCCCCCCFFFFFFFF")

              setChecked(newval)
              console.log(checked, newval, flag, '455');
              if (label === 'Origin' && newval === 'Yes') {
                modalStore.checkingOrigin = true
              }
              if (label === 'Destination' && newval === 'Yes') {
                modalStore.checkingDestination = true
              }
              if (label === 'Origin' && newval === 'No') {
                modalStore.checkingOrigin = false
              }
              if (label === 'Destination' && newval === 'No') {
                modalStore.checkingDestination = false
              }

            })}
          >
            {
              (checked === 'Yes') ?
                <img height='36px' width='36px' src={on}></img> :
                <img height='36px' width='36px' src={off}></img>
            }
          </div>
        </div>
        <div class="column is-8 p-0" style={{ fontSize: "12px" }}><strong>Door</strong></div>
      </div>
      {
        (checked != 'No') ? <AgGoogleMap isValid={!isInvalid} isDisabled={isDisabled} 
        callback={(val) => {
          handleChange[name] = val?.label === "" || val === null;
        }}
        validationMsg = "- Please enter valid address/zipcode/pincode."
        label={label} record={record} name={name} ShipmentType={ShipmentType} /> : <AgUnlocde
        isInvalid={isInvalid} callback={(value, list) => {
            handleChange[name] = !list.some(ele => ele === value);
          }}isDisabled={isDisabled} label={label} record={record} name={name} ShipmentType={ShipmentType} />
      }
    </>
  )
}

export function AgGoogleMapOld(props) {

  const { label, record, name, callback = f => f, validationMsg, isDisabled, ShipmentType } = props
  const [isValid, setValid] = useState(true)
  const [ch, setCh] = useState(false)
  const [cursor, setCursor] = useState(0)

  const imRecord = useSnapshot(record)
  useSnapshot(configStore)
  console.log("abcd");
  const [postcode, setPostcode] = useState("")
  let tooltip = ""
  if ((ShipmentType == 'LCL' || ShipmentType == 'FCL') && label == 'Origin') {
    tooltip = 'Choose whether you will handover at the Port/ ICD/ CFS or you want Agraga to arrange the Pick-up'
  }
  if ((ShipmentType == 'LCL' || ShipmentType == 'FCL') && label == 'Destination') {
    tooltip = 'Choose whether the consignee will pick-up at the Port/ ICD/ CFS or you want Agraga to arrange the Delivery'
  }
  if (ShipmentType == 'Air' && label == 'Origin') {
    tooltip = 'Choose whether you will handover at the Airport or you want Agraga to arrange the Pick-up'
  }
  if (ShipmentType == 'Air' && label == 'Destination') {
    tooltip = 'Choose whether the consignee will pick-up at the Airport or you want Agraga to arrange the Delivery'
  }



  useEffect(() => {
    if (loginStore.isClient()) {
      if (ch) {
        check()
      } else {
        if (record[name].length > 0) {
          check()
        }
      }

      if (configStore.addressvalidation) {
        if (!isValid) setValid(true)
      } else {
        if (isValid) setValid(false)
      }
    }
    let unsubscribe = subscribe(configStore, () => {
      if (loginStore.isClient()) {
        if (configStore.addressvalidation) {
          if (!isValid) setValid(true)
        } else {
          if (isValid) setValid(false)
        }
      }
    })
    return unsubscribe
  }, [configStore.addressvalidation, ch])

  function checkcurrent() {
    if (record[name].length == 0) {
      return false
    } else {
      console.log("the postcode ",postcode);
      if (postcode.length == 0) {
        if (!Utils.checkAddress(record[name])) {
          return false
        } else {
          let a = record[name].split(' ')
          let l = false
          for (let i = 0; i < a.length; i++) {
            let k = a[i].replace(',', '').trim()
            if (Utils.checkZipcode(k)) {
              l = true
              i = a.length + 1
            }
          }
          if (!l) {
            let a = record[name].split(',')
            for (let i = 0; i < a.length; i++) {
              let k = a[i].trim()
              if (Utils.checkZipcode(k)) {
                l = true
                i = a.length + 1
              }
            }
          }
          if (!l) {
            return false
          } else {
            return true
          }
        }
      } else {
        return true
      }

    }
  }


  function trimStr(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, '');
  }

  const handleChange = (e, name) => {
    setCursor(e.target.selectionStart)
    if (name == 'origin' || name == 'destination') {
      record[name] = trimStr(e.target.value)
    } else {
      record[name] = e.target.value.trim()
    }
    // record[r] = trimStr(e.target.value)
  }

  function check() {
    console.log("check");
    if (record[name].length == 0) {
      console.log("check2");
      if (configStore.addressvalidation) configStore.addressvalidation = false
    } else {
      console.log("check3");
      if (postcode.length == 0) {
        if (!Utils.checkAddress(record[name])) {
          if (configStore.addressvalidation) configStore.addressvalidation = false
        } else {
          console.log("check4");
          let a = record[name].split(' ')
          let l = false
          for (let i = 0; i < a.length; i++) {
            let k = a[i].replace(',', '').trim()
            if (Utils.checkZipcode(k)) {
              l = true
              i = a.length + 1
            }
          }
          console.log("check5");
          if (!l) {
            console.log("check6");
            let a = record[name].split(',')
            for (let i = 0; i < a.length; i++) {
              let k = a[i].trim()
              if (Utils.checkZipcode(k)) {
                l = true
                i = a.length + 1
              }
            }
          }
          console.log(l, "Lllllllllll")
          if (!l) {
            if (configStore.addressvalidation) configStore.addressvalidation = false
          } else {
            if (!configStore.addressvalidation) configStore.addressvalidation = true
          }
        }
      } else {
        if (!configStore.addressvalidation) configStore.addressvalidation = true
      }

    }
    setCh(false)
    setPostcode("")
  }

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyClVZz_gAAGluKpMp4oQN87R7ZPY2-t1i0",
    onPlaceSelected: (place) => {
      console.log('PLACE SELECTED=', place)
      let ps = ""
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "postal_code": {
            ps = `${component.long_name}${ps}`;
            break;
          }

          case "postal_code_suffix": {
            ps = `${[ps]}-${component.long_name}`;
            break;
          }
        }

      }


      setPostcode(ps)
      setCh(true)
      if (place.formatted_address && place.formatted_address.length != 0) {
        record[name] = place.formatted_address
      }
    },
    options: {
      types: []
    },
  });


  return (
    <>


      <div className="field">
        {
          (label == 'Origin' || label == 'Destination') ?

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label} <span class="icon" tooltip={tooltip} flow="right">
              <i class="fas fa-info-circle" ></i>
            </span></label>

            : <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
        }


        <div className="control is-fullwidth">

          <input ref={ref} {...props} name={`${name}address`} value={imRecord[name]} placeholder="Enter Address" id={`${name}address`}
            {...isDisabled}
            onChange={(e) => {
              console.log('GOT ADDRESS=', e.target.value)
              // record[name] = e.target.value
              handleChange(e, name)
              callback(e.target.value)
            }}
            onBlur={() => check()}
            className={isValid ? "input is-small" : "input is-small is-danger"} />

        </div>
        {(!isValid && !checkcurrent()) && <> <p class="help is-danger"> * Pin/Zip code Missing</p><p class="help is-danger"> * Please Enter Pin/Zip code</p></>}
      </div>
    </>

  )

}

export function AgGoogleMap(props) {
  const error = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #f14668',
    }),
    menu: (base) => ({ 
      ...base, 
      position: 'relative' 
    })
  }
  const customStyles = {
    menu: (base) => ({ 
      ...base, 
      position: 'relative' 
    })
  }
    
  const { label, record, name,isValid, callback = f => f,  validationMsg ,isDisabled,ShipmentType } = props
  let defaultValue = null
  let defaultOptions = []
  if (record[name] && record[name].length > 0) {
    defaultValue = { value: record[name], label: record[name] }
    defaultOptions.push({ value: record[name], label: record[name] })
  }else{
    defaultValue = null;
    defaultOptions = [];
  }
  const imRecord = useSnapshot(record)
  useSnapshot(configStore)
  let tooltip = ""
  if ((ShipmentType == 'LCL' || ShipmentType == 'FCL') && label == 'Origin') {
    tooltip = 'Choose whether you will handover at the Port/ ICD/ CFS or you want Agraga to arrange the Pick-up'
  }
  if ((ShipmentType == 'LCL' || ShipmentType == 'FCL') && label == 'Destination') {
    tooltip = 'Choose whether the consignee will pick-up at the Port/ ICD/ CFS or you want Agraga to arrange the Delivery'
  }
  if (ShipmentType == 'Air' && label == 'Origin') {
    tooltip = 'Choose whether you will handover at the Airport or you want Agraga to arrange the Pick-up'
  }
  if (ShipmentType == 'Air' && label == 'Destination') {
    tooltip = 'Choose whether the consignee will pick-up at the Airport or you want Agraga to arrange the Delivery'
  }

  function checkcurrent() {
    if (record[name].length == 0) {
      return false
    } else {
      if (!Utils.checkAddress(record[name])) {
        return false
      } else {
        let a = record[name].split(' ')
        let l = false
        for (let i = 0; i < a.length; i++) {
          let k = a[i].replace(',', '').trim()
          if (Utils.checkZipcode(k)) {
            l = true
            i = a.length + 1
          }
        }
        if (!l) {
          let a = record[name].split(',')
          for (let i = 0; i < a.length; i++) {
            let k = a[i].trim()
            if (Utils.checkZipcode(k)) {
              l = true
              i = a.length + 1
            }
          }
        }
        if (!l) {
          return false
        } else {
          return true
        }
      }
    }
  }


  const getdata = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let results
      let s = encodeURI(inputValue)
      let url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchaddress/${s}`
      results = await Newapi.get(url)
      let a = results.map(e => { return { value: e, label: e } })
      return a
    } else {
      return []
    }
  }

  const loadOptions = async (
    inputValue,
    callback
  ) => {
    let v = await getdata(inputValue)
    callback(v)
  };

  const change = (e) => {
    console.log(e, e?.lable)
    if (e && e.value) {
      record[name] = e.label
    } else {
      console.log(e)
      record[name] = ""
    }

  }



  return (
    <>


      <div className="field">
        {
          (label == 'Origin' || label == 'Destination') ?

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label} <span class="icon" tooltip={tooltip} flow="right">
              <i class="fas fa-info-circle" ></i>
            </span></label>
         
        :<label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
      }
            
            
            <div className="control is-fullwidth">
              {(isDisabled && isDisabled.disabled!=undefined && isDisabled.disabled)?<input value={imRecord[name]} placeholder="Enter Address" className="input is-small" id={`${name}address`}
                {...isDisabled}/>:<AsyncSelect defaultOptions={defaultOptions} value={defaultValue} isClearable 
                styles={isValid ? customStyles : error} 
                loadOptions={loadOptions} onChange={(val) => {
                  change(val)
                  callback(val)
              }} />}
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select an UN location`}</p>}
        {/* </div> */}
        {/* {(!isValid && checkcurrent()) && <> <p class="help is-danger"> * Pin/Zip code Missing</p><p class="help is-danger"> * Please Enter Pin/Zip code</p></>} */}
      </div>
    </>

  )

}

export function AgUnlocde(props) {
  console.log("props1", props.name);
  const { label, record, name, callback = f => f, isInvalid, validationMsg, handleChange, isDisabled, ShipmentType } = props
console.log(label, "1",record,"2", name,"3", callback,"4", validationMsg,"5", isDisabled,"6", ShipmentType, "ereee");
  const { currentMenu } = useGlobalState();

  const [isValid, setValid] = useState(true)
  console.log(isValid,"Dddddd");
  const imRecord = useSnapshot(record)
  const [cursor, setCursor] = useState(0)

  const [list, setList] = useState([])
  let tooltip = ""
  if ((ShipmentType == 'LCL' || ShipmentType == 'FCL') && label == 'Origin') {
    tooltip = 'Choose whether you will handover at the Port/ ICD/ CFS or you want Agraga to arrange the Pick-up'
  }
  if ((ShipmentType == 'LCL' || ShipmentType == 'FCL') && label == 'Destination') {
    tooltip = 'Choose whether the consignee will pick-up at the Port/ ICD/ CFS or you want Agraga to arrange the Delivery'
  }
  if (ShipmentType == 'Air' && label == 'Origin') {
    tooltip = 'Choose whether you will handover at the Airport or you want Agraga to arrange the Pick-up'
  }
  if (ShipmentType == 'Air' && label == 'Destination') {
    tooltip = 'Choose whether the consignee will pick-up at the Airport or you want Agraga to arrange the Delivery'
  }


  function trimStr(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, '');
  }

  let shipmentType = ShipmentType;
  return (
    <>
      <div className="field">
        {
          (label == 'Origin' || label == 'Destination') ?

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label} <span class="icon" tooltip={tooltip} flow="right">
              <i class="fas fa-info-circle" ></i>
            </span></label>

            : <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
        }
        <div className="control is-fullwidth">
          {
            (currentMenu == "Enquiries" || currentMenu == 'Pricing Calculator') ?
              <>
              <input  {...props} name={`${name}unlocode`} value={imRecord[name]} list={name} id={`${name}unlocode`}
                {...isDisabled}
                
                onChange={(e) => {
                  if (!e.nativeEvent.inputType) {
                    e.target.blur();
                  }
                  record[name] = e.target.value
                  Api.getMapUnloc({ descriptions: [e.target.value, shipmentType] }, (data) =>{
                      setList(data)
                      callback(e.target.value, data)
                  });   // TODO handle
              }}
                autoComplete="off"
                placeholder="Enter Unlocode"
                className={!isInvalid ? "input is-small" : "input is-small is-danger"} />
              <datalist id={name}>
                    {
                        list.map((e, i) => <option key={i} value={e} />)
                    }
                </ datalist>
              </> :
              <>
              <input  {...props} name={`${name}unlocode`} value={imRecord[name]} list={name} id={`${name}unlocode`}
                {...isDisabled}
                onChange={(e) => {
                  if (!e.nativeEvent.inputType) {
                    e.target.blur();
                  }
                  record[name] = e.target.value
                  Api.getMapUnloc({ descriptions: [e.target.value, shipmentType] }, (data) =>{
                      setList(data)
                      callback(e.target.value, data)
                  });   // TODO handle
              }}
                autoComplete="off"
                placeholder="Enter Unlocode"
                className={!isInvalid ? "input is-small" : "input is-small is-danger"} />
              <datalist id={name}>
                    {
                        list.map((e, i) => <option key={i} value={e} />)
                    }
                </ datalist>
              </>

          }

        </div>
        {
          isInvalid ? (<p class="help is-danger"> {validationMsg || `* Please select an UN location`}</p>) : <></>
        }

      </div>
    </>

  )
}
