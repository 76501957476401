import { BorderBottom, ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Box, Collapse, Typography } from "@mui/material";
import accordionIcon from "../assets/images/accordionicon.svg";

function CustomAccordion({ title, price,startIcon, open, content, src, setAccordion,isLast=false }) {
    return <Box sx={{ display: "flex", flexDirection: "column", padding: "10px 0px 10px 0px",backgroundColor: "white", width: "100%",borderBottom: isLast ? "" : "0.5px solid #D4D4D4" }}>
        <Box onClick={() => {
            setAccordion();
        }} sx={{
            "&:hover": {
                cursor: "pointer",
            }, display: 'flex', flexDirection: "row", justifyContent: "space-between",alignItems:"center",paddingX:"8px"
        }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                <img src={accordionIcon} />
                <Typography sx={{fontFamily:"Roboto,sans-serif",color:"black",fontWeight:"bold",fontSize:"15px"}}>{title}</Typography>
            </Box>
            <Typography sx={{fontFamily:"Roboto,sans-serif",color:"#F4B14A",fontWeight:"bold"}}>{price}</Typography>
        </Box>
        <Collapse sx={{ padding: "8px" }} in={open} timeout="auto" unmountOnExit={true}>
            {content}
        </Collapse>
    </Box>
}



export function Accordions({ accordions, selectedIndex, setIndex }) {

    return <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {accordions.map((accordion, i) =>
            <div id={accordion.id + "acc"} style={{ width: "100%" }} >
                <CustomAccordion startIcon={accordion.src} isLast={i===accordions.length-1} price={accordion.price} title={accordion.title} content={accordion.content} open={i === selectedIndex} setAccordion={() => {
                    if (i === selectedIndex) {
                        setIndex(-1)
                    } else {
                        setIndex(i);
                    }
                }} />
            </div>
        )}
    </Box>
}
