export default function VehicleView(props) {
    let v = props.vehicleDetails
    if (!v) return <></>
    let fields = v.fields
    if (!fields) return <></>
    let keys = Object.keys(fields)
    let str = ''
    for (let i = 0; i < keys.length; i++) {
        let k = keys[i]
        let n = Number(v[k])
        if (n > 0) {
            str += `${fields[k]} : ${n}, `
        }
    }
    if (str.length < 3) return <></>
    return (
        <div style={{ padding: '3px', border: '1px solid darkblue' }}>
            <div className="card">
                <strong>Vehicle Details </strong>
                {str}
            </div>
        </div>
    )
}