const lclImportChargesData = {
    colNames : {
        twoSpan: 'Per Unit',
        twoSpan1: 'Per W/M',
        twoSpan2: 'Minimum',
        perShipment: 'Per Shipment'
    },
    initialLength: 12,
    preList: [{
            id: 1,
            chargeDescription: 'Pick-up Charges',
            currency: 'INR',
            disabled: ['perWM', 'minimum']
        },
        {
            id: 2,
            chargeDescription: 'Export Customs Clearance (if applicable)',
            currency: 'INR',
            disabled: ['perWM', 'minimum']
        },
        {
            id: 3,
            chargeDescription: 'Origin Charges',
            currency: 'INR'
        },
        {
            id: 4,
            chargeDescription: 'Origin Inland Haulage (if applicable)',
            currency: 'INR'
        },
        {
            id: 5,
            chargeDescription: 'Ocean Freight',
            currency: 'USD',
            disabled: ['perShipment']
        },
        {
            id: 6,
            chargeDescription: 'Hazardous Surcharge (if applicable)',
            currency: 'USD'
        },
        {
            id: 7,
            chargeDescription: 'Non-Stackable Surcharge (if applicable)',
            currency: 'USD'
        },
        {
            id: 8,
            chargeDescription: 'Destination Inland Haulage (if applicable)',
            currency: ''
        },
        {
            id: 9,
            chargeDescription: 'Destination Charges',
            currency: ''
        },
        {
            id: 10,
            chargeDescription: 'Destination CFS Charges',
            currency: ''
        },
        {
            id: 11,
            chargeDescription: 'Import Customs Clearance (if applicable)',
            currency: '',
            disabled: ['perWM', 'minimum']
        },
        {
            id: 12,
            chargeDescription: 'Door Delivery Charges (if applicable)',
            currency: '',
            disabled: ['perWM', 'minimum']
        }
    ]
}
export default lclImportChargesData