import React from 'react';

const Header = ({ heading, onClick,Arrow }) => {
  return (
    <div id="usersPg" className='mb-4'>
      <div className="back-div is-flex">
        {Arrow?<img
          src="./assets/Back.png"
          alt="Back"
          className="back"
          width="32px"
          onClick={onClick} 
        />:null}
        <h1 className="title ml-2">{heading}</h1>
      </div>
    </div>
  );
};

export default Header;
