import { useEffect, useState } from "react"
import { proxy, useSnapshot } from "valtio"
import { bookingsStore, configStore, entityStore, loginStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import MonApi from "../state/mongo-api"
import Utils from "../util/Utils"
import { AgUnloc } from "../components/AgComponents"
import AgCustlist from "../components/AgCustlist"
import HomescreenRoute from "../enquiry/homescreen-route"
import HomescreenCargo from "../enquiry/homescreen-cargo"
import { enquiryStore } from "../enquiry/enquiry-data"
import { DummySchedule } from "../enquiry/dummy-schedule"
import LCLScheduleComp from "../enquiry/LCLScheduleComp"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import NewAddress from "../components/new-address"
import TempRoute from "../enquiry/temp-route"
import VehicleScreen from "../enquiry/vechile-screen"
import ShipmentGraph, { ShipmentTable, graphNode } from "../my-actions/shipment-graph"
import LCLPricingSchedule from "./lcl.schedule"
import CarrierWebForm from "../schedules/CarrierWebForm"
import Pricingcwf from "./pricing.cwf"
import BookingConfirmation from "../schedules/booking-confirmation"
import Api from "../state/Api"
import ReactDOMServer from 'react-dom/server'
import { GenerateCargoTable } from "./pricing-utils"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import EnquiryConfirmation from "../enquiry/enquiry-confirmation"
import { rtpStore } from "./pricing-store"

function _g(node) {
    let e = new graphNode()
    //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
    Object.assign(e, node)
    e.isActive = true
    e.activelines = 2
    return e
}

export async function SendBookingMailer(booking) {
    let graphnodes = booking.graphNodes
    let props = {
        insidepricing: true,
        graphNodes: graphnodes
    }

    let gst = configStore.currentSelectedEntity.gst
    let entityurl = `${process.env.REACT_APP_API_SERVER}/api/v1/entity/${gst}`
    let entity = await MonApi.aget(entityurl)
    let entityDetail = ''
    if (entity) entityDetail = `${entity.entityName}, ${entity.state}`
    let schedule = ShipmentTable(props)

    let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
    let to = loginStore.email
    let olocation = booking.contract.origin
    {
        let arr = olocation.split(',')
        if (arr.length > 0) olocation = arr[arr.length - 1]
    }
    let dlocation = booking.contract.destination
    {
        let arr = dlocation.split(',')
        if (arr.length > 0) dlocation = arr[arr.length - 1]
    }
    let subject = `${booking._id}//SPOT// ${olocation} to ${dlocation}// ${booking.contract.shipmentScope}`
    const _chargesTable = (chargesList) => {
        let charges = `<table><tbody>`
        for (let i = 0; i < chargesList.length; i++) {
            let c = chargesList[i]
            let amt = c.total
            let erate = configStore.getExchangeRate(c.currency)
            if (!erate) erate = 1.0
            amt = amt * erate
            charges += `<tr>
            <td style="border: 1px solid lightgrey">${c.chargeDescription}</td>
            <td style="border: 1px solid lightgrey">${Utils.N(amt)}</td>
            </tr>`
        }
        charges += `</tbody></table>`
        return charges
    }
    let charges = _chargesTable(booking.contract.chargesList)
    let cargoTable = GenerateCargoTable(booking.contract.shipmentScope, booking.contract.cargoList)
    let message = `
        <p>${new Date(Number(booking.tsCreatedOn))}</p>
        <p>${entityDetail}</p>
        <p>${booking.firstName} ${booking.lastName} (${booking.emailid})</p>
        <p><h3>On Your Account</h3>${charges}</p>
        <p><h3>Schedule</h3>${schedule}</p>
        <p><h3>Cargo Details</h3>${cargoTable}</p>
`

    Api.sendClientMail(from, to, subject, message)
}
let pricingStore = proxy({
    gst: '',
    origin: '',
    destination: ''
})

function creategraphnode() {
    let e = new graphNode()
    e.isActive = true
    e.activelines = 2
    Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
    return e
}

function EnquiryOption(props) {
    return (
        <div style={{
            width: '50%', margin: 'auto', padding: '3rem', border: '1px solid black',
            backgroundColor: '#D4F2B5'
        }}>
            <div>
                We do not have these routes real-time but, would love to provide a customized solution.
                We will get in touch with your shortly over Phone or E-mail.
            </div>
            <button className="button islink" style={{ float: 'right', marginLeft: '2rem' }}
                onClick={(e) => {
                    EnquiriesApi.saveEnquiry()
                    props.setMessage('')
                }}
            >OK</button>
            <button className="button is-danger" style={{ float: 'right' }}
                onClick={(e) => {
                    props.setMessage('')
                }}
            >No</button>
        </div>
    )
}
function RoutesEmpty({ message, setMessage }) {
    if (message == 'No Routes') return <EnquiryOption setMessage={setMessage} />
    return (
        <div className="title is-5">{'' + message}</div>
    )

}
export default function PricingScreen() {
    let routeFilterList = ['Cheapest', 'Fastest']
    const [routeFilter, setRouterFilter] = useState('Cheapest')
    let cheapestColor = routeFilter == 'Cheapest' ? 'islink' : ''
    let fastestColor = routeFilter == 'Fastest' ? 'islink' : ''
    const store = useSnapshot(myactionsstore)
    const [cwfVisible, setcwfVisible] = useState(false)
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(pricingStore)
    useSnapshot(entityStore)
    useSnapshot(enquiryStore)
    useSnapshot(rtpStore)
    const [serverTime, setServerTime] = useState(0)
    const [routesEmptyMessage, setRoutesEmptyMessage] = useState('')
    const [sscope, setsscope] = useState('')
    const [crd, setCrd] = useState('')
    const [routes, setRoutes] = useState([])
    const _params = (label, name, type = 'text', dataList = []) => {
        return {
            type, name, label, record: pricingStore,
            dataList
        }
    }
    useEffect(() => {
        let r = [...routes]
        if (routeFilter == 'Cheapest')
            r.sort((a, b) => a.dummyContract.quoteValue < b.dummyContract.quoteValue ? -1 : 1)
        else
            r.sort((a, b) => a.minTransitTime < b.minTransitTime && a.minTransitTime > 0 ? -1 : 1)
        setRoutes(r)
    }, [routeFilter])
    useEffect(() => {

    }, [enquiryStore.current.originType, enquiryStore.current.destinationType])
    useEffect(() => {
        setRoutes([])
        setRoutesEmptyMessage('')
    }, [enquiryStore.current.enqRoute.origin, enquiryStore.current.enqRoute.destination, enquiryStore.cargoDetailsChanges])
    useEffect(() => {
        rtpStore.records = routes
    }, [routes])
    const genEnquiry = (p) => {
        EnquiriesApi.saveEnquiry()
    }
    const getRoutes = async () => {
        setRoutes([])
        setRoutesEmptyMessage('Fetching Routes ...')
        setRouterFilter('Cheapest')
        let curDate = Utils.getCurrentDateString()
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
        let start = Date.now()
        if (loginStore.isClient()) pricingStore.gst = configStore.currentSelectedEntity.gst
        else configStore.currentSelectedEntity = { gst: pricingStore.gst }
        enquiryStore.current.shipmentType = 'LCL'
        enquiryStore.current.gst = pricingStore.gst
        enquiryStore.current.entity = pricingStore.gst
        enquiryStore.current.createdBy = loginStore.email
        let otype = enquiryStore.current.originType
        if (!otype) otype = 'Port'
        let dtype = enquiryStore.current.destinationType
        if (!dtype) dtype = 'Port'
        enquiryStore.current.shipmentScope = `${otype}-to-${dtype}`
        let crd = ''+enquiryStore.current.enqRoute.cargoReadinessDate
        crd = crd.trim()
        if (crd == '') {
            crd = curDate
            enquiryStore.current.enqRoute.cargoReadinessDate = curDate
        }
        let payload = {
            entity: pricingStore.gst,
            origin: enquiryStore.current.enqRoute.origin,
            destination: enquiryStore.current.enqRoute.destination,
            sscope: `${otype}-to-${dtype}`,
            crd: enquiryStore.current.enqRoute.cargoReadinessDate,
            curDate,
            cargoDetails: enquiryStore.current.cargoDetails,
            vehicleDetails: enquiryStore.current.vehicleDetails,
            enquiry: enquiryStore.current
        }
        let cargo = payload.cargoDetails
        for (let i = 0; i < cargo.length; i++) {
            let o = cargo[i]
            o.dimensions.unit = payload.enquiry.cargoDimensionUnit
        }
        let ocountry = 'origin'
        let portre = /.*\((.....)\)$/
        let doorre = /.*\[(..,.*)\]$/
        let origin1 = payload.origin
        let destination1 = payload.destination
        if (origin1.includes('(') || origin1.includes('[')) {
            if (otype == 'Port') ocountry = origin1.match(portre)[1].slice(0, 2)
            else ocountry = origin1.match(doorre)[1].slice(0, 2)
        }
        let dcountry = 'destination'
        if (destination1.includes('(') || destination1.includes('[')) {
            if (dtype == 'Port') dcountry = destination1.match(portre)[1].slice(0, 2)
            else dcountry = destination1.match(doorre)[1].slice(0, 2)
        }
        console.log('$$$$', payload.origin, payload.destination, ocountry, dcountry)
        if (ocountry == dcountry) {
            configStore.setModalMessage('We do not have Domestic Transport Capabilities at present'+ocountry+'/'+dcountry)
            return
        }
        if (ocountry != 'IN' && ocountry != 'origin' && dcountry != 'IN' && dcountry != 'destination') {
            configStore.setModalMessage('We do not serve these routes at present and will keep you posted when we launch in these markets')
            enquiryStore.current.enqRoute.origin = ''
            enquiryStore.current.enqRoute.destination = ''
            return
        }
        let ret
        if (enquiryStore.current.hazardous == 'Yes' || enquiryStore.current.nonStackable == 'Yes') {
            ret = []
        } else {
            ret = await MonApi.apost(url, payload)
        }
        console.log('payload==', JSON.stringify(payload))
        //console.log('new data=', enquiryStore.current.originType, enquiryStore.current.destinationType)
        setServerTime(Date.now() - start)
        if (ret.error) {
            setRoutesEmptyMessage('')
            configStore.setModalMessage('' + ret.error)
        } else {
            setRoutes(ret)
            if (!ret || !ret.length) setRoutesEmptyMessage('No Routes')
        }
    }
    let a = {
        "Origin Charges": {
            "comp": {
                "Per Shipment": 25,
                "Origin Rate 1": 25,
                "Origin Rate 2": 10,
                "VGM (Flat Fee)": 15
            },
            "total": 75
        },
        "Ocean Freight": {
            "comp": {
                "Ocean Freight": 75
            },
            "total": 75
        },
        "Total": { "comp": 150, "total": 150 }
    }
    let shipmentnodes = [creategraphnode(), creategraphnode(), creategraphnode()]

    const ChargeTable = (props) => {
        /*return <p>
            {JSON.stringify(props.charge)}
        </p>*/
        return <>
            {
                Object.keys(props.charge).map(e => <tr>
                    <td></td>
                    <td>{e}</td>
                    <td>{props.charge[e].category}</td>
                    <td colSpan='2'>
                        {
                            props.charge[e].comp ?
                                Object.entries(props.charge[e].comp).map(e1 => '' + e1 + ', ') :
                                'Comp undefined'
                        }
                    </td>
                    <td>
                        Total = {props.charge[e].total}
                    </td>
                </tr>)
            }
        </>
    }
    const displayVehicleTable = () => {
        let otype = enquiryStore.current.originType
        if (!otype) otype = 'Port'
        let dtype = enquiryStore.current.destinationType
        if (!dtype) dtype = 'Port'
        let flag = false
        let origin = enquiryStore.current.enqRoute.origin
        let destination = enquiryStore.current.enqRoute.destination
        console.log('\nAAAAA==========', origin, otype, destination, dtype)
        if (otype != 'Port' && origin.includes(',India,')) flag = true
        else if (dtype != 'Port' && destination.includes(',India,')) flag = true
        return flag
    }
    return (
        <div>
            <EnquiryConfirmation />
            {
                !loginStore.isClient() ?
                    <div className="columns">
                        <div className="column is-4">
                            <label><strong>Customer</strong></label>
                            <AgCustlist {..._params('', 'gst', 'select', entityStore.list)} />
                        </div>
                    </div>
                    : <></>
            }
            <div className="columns">
                <div className="column is-3">
                    <TempRoute viewonly={cwfVisible} />
                </div>
                <div className={'column ' + (displayVehicleTable() ? 'is-6' : 'is-9')}>
                    <HomescreenCargo inPricing={true} pureview={cwfVisible} />
                </div>
                {
                    (displayVehicleTable()) ?
                        <div className="column is-3">
                            <VehicleScreen />
                        </div>
                        : <></>
                }
            </div>

            <BookingConfirmation />
            {
                (!cwfVisible) ?
                    <div className="columns">
                        <div className="column is-9">
                        </div>
                        <div className="column is-1">
                            <button className="button" style={{ marginLeft: '3px' }}
                                onClick={(e) => {
                                    enquiryStore.initialize()
                                    enquiryStore.current.originType = 'Port'
                                    enquiryStore.current.destinationType = 'Port'
                                }}
                            >Clear</button>
                        </div>
                        <div className="column is-1">
                            <button className="button islink " style={{ marginLeft: '3px' }}
                                onClick={(e) => {
                                    //console.log(JSON.stringify(enquiryStore))
                                    //console.log('ENQUIRY STORE=', JSON.stringify(enquiryStore.current))
                                    bookingsStore.initialize()
                                    bookingsStore.entityId = enquiryStore.current.entityId
                                    getRoutes()
                                    //testmail()
                                }}
                            >Get Rates</button>
                        </div>
                    </div>
                    : <></>
            }
            <hr />
            {
                (cwfVisible) ?
                    <Pricingcwf hideme={setcwfVisible} />
                    :
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (!routes.length) ?
                                    <RoutesEmpty message={routesEmptyMessage} setMessage={setRoutesEmptyMessage} />
                                    :
                                    <>
                                        <div className="columns">
                                            <div className="column is-4">
                                                {/*<Agproducttab productList={routeFilterList}
                                                    activeProduct={routeFilter} setActiveProduct={setRouterFilter} />
                            */}
                                                <button className={'button ' + cheapestColor} onClick={(e) => {
                                                    setRouterFilter('Cheapest')
                                                }}>
                                                    <span className="icon" style={{ marginRight: '5px' }}>
                                                        <i class="fa-solid fa-money-check-dollar"></i>
                                                    </span>
                                                    Cheapest
                                                </button>
                                                <button className={'button ' + fastestColor}
                                                    style={{ marginLeft: '1rem' }} onClick={(e) => {
                                                        setRouterFilter('Fastest')
                                                    }}>
                                                    <span className="icon" style={{ marginRight: '5px' }}>
                                                        <i class="fa-solid fa-clock"></i>
                                                    </span>
                                                    Fastest
                                                </button>
                                            </div>
                                            <div className="column is-4"></div>
                                        </div>
                                        <table className="table is-bordered is-fullwidth is-narrow " style={{ fontSize: '0.75rem', padding: '0px' }}>
                                            {
                                                (!loginStore.isClient()) ?
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={7}>Total Routes = {routes.length}, Displaying max 100 routes, API duration = {serverTime / 1000} secs</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Set #</th>
                                                            <th>POR</th>
                                                            <th>POL</th>
                                                            <th>RE</th>
                                                            <th>POD</th>
                                                            <th>FPOD</th>
                                                        </tr>
                                                    </thead>
                                                    : <></>
                                            }
                                            <tbody>
                                                {
                                                    rtpStore.records.slice(0, 100).map((e, subscript) => <>
                                                        {
                                                            (!loginStore.isClient()) ?
                                                                <tr style={{ fontWeight: 'bold' }}>
                                                                    <td>{e.set}</td>
                                                                    <td>{e.por}</td>
                                                                    <td>{e.pol}</td>
                                                                    <td>{e.rep}</td>
                                                                    <td>{e.pod}</td>
                                                                    <td>{e.fpod}</td>
                                                                </tr>
                                                                : <></>
                                                        }
                                                        {
                                                            !loginStore.isClient() ?
                                                                <ChargeTable charge={e.charges} />
                                                                : <></>
                                                        }
                                                        {
                                                            (!loginStore.isClient()) ?
                                                                <tr style={{ color: '#a30' }}>
                                                                    <td colSpan='6'>
                                                                        Sources :
                                                                        {
                                                                            Object.keys(e.sources).join(', ')
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                : <></>
                                                        }
                                                        {
                                                            (e.dummyContract.schedules.length > 0) ?
                                                                <tr style={{ color: '#a30' }}>
                                                                    <td colSpan='6'>
                                                                        {
                                                                            e.dummyContract.schedules.map(s => <LCLPricingSchedule
                                                                                skipvalidation={true} data={s} contract={e.dummyContract} subscript={subscript}
                                                                                hideme={setcwfVisible} cwfVisible={cwfVisible} />)
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                : <></>
                                                        }
                                                        {
                                                            (e.dummyContract.schedules.length == 0) ?
                                                                <tr style={{ color: '#a30' }}>
                                                                    <td colSpan='6'>
                                                                        <LCLPricingSchedule contract={e.dummyContract} isDummy={true} subscript={subscript}
                                                                            hideme={setcwfVisible} cwfVisible={cwfVisible} />
                                                                    </td>
                                                                </tr>
                                                                : <></>
                                                        }
                                                    </>)
                                                }
                                            </tbody>
                                        </table>
                                    </>
                            }
                        </div>
                    </div>
            }
        </div >
    )
}
