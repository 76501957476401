import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import quotes from "../assets/images/quotes.svg"
import Shyam from "../assets/images/Shyam.png"
import covalent from "../assets/images/covalent.png"
import talbros from "../assets/images/talbros.png"
const Carousel = ({interval = 3000  }) => {
    const images = [
        <CardContainer name={"Akshata Teli"} role={"Export Executive - Shyam Chemicals Pvt. Ltd"} img={Shyam}
        text={`Quick service turnaround and prompt responses.Competitive rates and reliable service.`}/>,
        <CardContainer name={"M. Sravan Tej"} role={"Sr. Executive - Logistics - Covalent Laboratories Pvt. Ltd"} img={covalent} 
        text={"Agraga gives us multiple solutions for all our Export and Import Consignments on a timely basis."}/>,
        <CardContainer name={"N. Santhosh"} role={"Sr. Executive Exports - QH Talbros Pvt. Ltd."} img={talbros}
        text={`We are happy with the services of Agraga
        and the IT systems which enable us to track
        shipments online.`}/>,
      ];
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
  const autoPlayInterval = setInterval(nextSlide, interval);

    return () => {
      clearInterval(autoPlayInterval);
    };
  }, [interval]);

// var myTimer = setInterval(nextSlide, 3000);
  const cc = document.getElementById("carousel")
//   console.log(cc);
// cc?.addEventListener("mouseover", function(){ clearInterval(myTimer)});
// cc?.addEventListener("mouseout", function(){ myTimer = setInterval(nextSlide, 3000);});
  return (
    <div className="carousel" id="carousel" >
      {/* <button onClick={prevSlide} className="carousel__btn carousel__btn--prev">
        &lt;
      </button> */}
      {images[activeIndex]}
      {/* <button onClick={nextSlide} className="carousel__btn carousel__btn--next">
        &gt;
      </button> */}
      <CarouselIndicators
        images={images}
        activeIndex={activeIndex}
        onClick={goToSlide}
      />
    </div>
  );
};
export default Carousel;


const CarouselIndicators = ({ images, activeIndex, onClick }) => {
    return (
      <div className="carousel__indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel__indicator ${
              index === activeIndex ? 'active' : ''
            }`}
            onClick={() => onClick(index)}
          />
        ))}
      </div>
    );
  };



  function CardContainer({name,role,img,text}) {
    return (
      <div className='card_container'>
      <img src={quotes} className='qoute_logo'></img>
    {/* <FormatQuoteRoundedIcon className='qoute_logo'/> */}
  <Typography variant="h6" fontFamily={"Figtree, sans-serif"}
  textAlign={"left"}
  padding={"3.5rem"} paddingBottom={"0"}
   color={"#fff"}
   fontSize={"20px"} fontWeight={500} marginBottom={"1rem"} lineHeight={"30px"}
   gutterBottom>"{text}"</Typography>
   <div className='userFeedback_container'>
      <div className='userFeedback_img'>
        <img src={img}></img>
      </div>
      <div>
      <Typography variant="h6"
       fontFamily={"Figtree, sans-serif"}
        color={"#fff"} 
        fontWeight={400}
         marginBottom={"0"}
         fontSize={"14px"}
          gutterBottom>{name}</Typography>
      <Typography variant="h6" fontFamily={"Figtree, sans-serif"} fontSize={"12px"} color={"#D4D4D4"} fontWeight={300} marginBottom={"0"} gutterBottom>{role}</Typography>
  
      </div>
   </div>
    </div>
    )
  }