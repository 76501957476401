import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { taskStore } from "./task-store"
import { OneCell } from "./act-common"
import { TableSelect, TableSelect1 } from "../components/AgComponents"
import { packageTypeList } from "../StaticData"
import { loginStore } from "../state/CommonState"

function AddDelButtons(props) {
    useSnapshot(taskStore)
    let line = props.line
    let len = props.length
    return (
        <div className="field has-addons">
            {
                (line !== len - 1) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...taskStore.current.bolDetails]
                                list.splice(line, 1)
                                taskStore.current.bolDetails = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                let bolLine = { ...taskStore.current.bolDetail }
                                taskStore.current.bolDetails.push(bolLine)
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function BolDetailsFCL(props) {
    const [rowNum, setRowNum] = useState(1)
    useSnapshot(taskStore)
    let task = taskStore.current
    useEffect(() => {
        if (!task.bolDetails.length) {
            task.bolDetails.push({ ...taskStore.current.bolDetail })
        }
    }, [])
    return (
        <>
            <div style={ loginStore.isClient() ? { backgroundColor:'white', borderRadius:"10px" } :  { boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon is-small mr-2">
                            <i className="fas fa-cube"></i>
                        </span>
                        Bill of Lading Details
                    </p>
                </header>
                <div className="card-content" style={{ paddingTop: '0px' }}>
                    <div className="content">
                        <div className="table-container">
                            <table className="table is-narrow is-bordered is-fullwidth is-small"
                                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={3}></th>
                                        <th colSpan={7}>Description of Goods</th>
                                        <th colSpan={4}></th>
                                    </tr>
                                    <tr>
                                        <th>Marks / Nos</th>
                                        <th>No. of pkgs </th>
                                        <th>Pkg type </th>
                                        <td>Container #</td>
                                        <td>S/B #</td>
                                        <td>Custom Seal #</td>
                                        <td>Line Seal #</td>
                                        <td>HS Code(s)</td>
                                        <td>Commercial Invoice #</td>
                                        <td>Other Information</td>
                                        <th>Net Weight</th>
                                        <th>Gross Weight</th>
                                        <th>Volume / CBM</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        task.bolDetails.map((e, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <OneCell record={e} name='marks' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='numPackages' />
                                                </td>
                                                <td>
                                                    {/* <OneCell record={e} name='pkgType' /> */}
                                                    <TableSelect1  record={e} name='pkgType' dataList={packageTypeList} />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='containerNum' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='sbNum' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='customSealNum' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='lineSealNum' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='hsCodes' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='commercialInvoiceNum' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='otherInfo' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='netWeight' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='grossWeight' />
                                                </td>
                                                <td>
                                                    <OneCell record={e} name='volume' />
                                                </td>
                                                <td>
                                                    <AddDelButtons line={i} length={taskStore.current.bolDetails.length} />
                                                </td>
                                            </tr>
                                        )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}