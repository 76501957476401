import { useSnapshot } from "valtio"
import { bookingsStore } from "../state/CommonState"
import BookingListv2 from "./booking-list-v2"

import ViewEnquiryv2 from "../enquiryv2/view-enquiry-v2"
import { useEffect } from "react"
import { rtpStore } from "../pricing/pricing-store"

export default function ListBookingsMainv2(props) {
    useSnapshot(bookingsStore)
    useSnapshot(rtpStore)
    useEffect(()=>{
        rtpStore.pricingSourceScreen = 'booking'
      return ()=>{
        bookingsStore.currentBookingsListComponent = "list"
      }
    },[])

    switch (bookingsStore.currentBookingsListComponent) {
        case 'list':
            //BookingsApi.getAllBookings()
            return <BookingListv2 />
        case 'view':
            return (
                <>
                    <ViewEnquiryv2 viewonly='1' showbooking='1' />
                </>
            )
        default: return <h1>Not Found</h1>
    }
}