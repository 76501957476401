import React, { useState } from "react";
import { DtStore } from "../DtStore";
import { CargoDetails } from '../../state/Types'
import on from '../../components/on2.png'
import off from '../../components/off.png'
import { useSnapshot } from "valtio"
import { getHazardousClassList, packageTypeList, packingGroupList } from "../../StaticData"
import DtApi from "../../state/dt-api";
const CargoButtons = (props) => {
    useSnapshot(DtStore)
    let {line, length} = props;
    return (
        <div className="field has-addons">
            {
                (length !== 1) ?
                    <p className="control">
                        <button style={{border: 'none', color: 'red'}} className="button is-small is-inverted"
                            onClick={() => {
                                let list = [...DtStore.DTBooking.cargo_details]
                                list.splice(line, 1)
                                DtStore.DTBooking.cargo_details = list
                            }}
                            >
                            <span className="icon is-small">
                            <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === length - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                DtStore.DTBooking.cargo_details.push(new CargoDetails())
                            }}
                            >
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}


const TableSelect = (props) => {
    const { record, name, dataList, tabIndex = 0, callback = f => f, isValid = true, isDisabled } = props;
    const imRecord = useSnapshot(record)

    return (
        <div className={isValid ? "select is-small" : "select is-small is-danger is-danger-border"} >
            <select style={{ color: 'black' }}
            {...props} 
                tabIndex={tabIndex} 
                name={name}
                value={imRecord[name]} 
                onChange={(e) => {
                    record[name] = e.target.value
                    callback(e.target.value)
                }}
                {...isDisabled}>
                <option selected={!isDisabled} value={''} hidden>
                    {''}
                </option>
                {dataList.filter(val => val !== '').map(val => <option selected={val === imRecord[name]} key={val}>{val}</option>)}
            </select>
        </div>
    )
}

const TableEdit = (props) => {
    const { record, name, type = 'text', isreadonly = false, callback = f => f, isValid = true, isDisabled } = props;
    const imRecord = useSnapshot(record)
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        record[name] = newValue; // Update the Valtio state
        callback(newValue);
    };
    return (
        <div className="control is-fullwidth is-small">
            <input
                style={{ color: 'black' }}
                {...props}
                type={type}
                name={name}
                value={imRecord[name]}
                onKeyDown={(e) =>
                    ["e", "E", "+"].includes(e.key) && e.preventDefault()
                }
                onChange={handleInputChange}
                className={isValid ? "input is-small" : "input is-small is-danger is-danger-border"}
                inputmode={type}
                {...isDisabled}
                readOnly={isreadonly}
            />
        </div>
    )
}

const AgSelect =(props) => {
    const { label, record, name, dataList, callback = f => f, isValid, validationMsg, isDisabled } = props;
    const imRecord = useSnapshot(record);
    
    return (    
        <div className="field">
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control">
                <div className={isValid ? "select is-small" : "select is-small is-danger is-danger-border"}>
                    <select {...props} name={name} 
                    value={imRecord[name]}  
                        {...isDisabled}
                        onChange={(e) => {
                            record[name] = e.target.value
                            callback(e.target.value)

                        }} >
                        <option selected value={''} hidden>
                            {''}
                        </option>
                        {dataList.filter(val => val !== '').map(val => <option key={val}>{val}</option>)}
                    </select>
                </div>
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please select valid ${label}`}</p>}
        </div>
    )
}

const AgInput = (props) => {

    const { isDisabled, label, record, name,  callback = f => f, isValid = true, validationMsg } = props;
    const imRecord = useSnapshot(record);


    let align = {}
    return (
        <div className="field">

            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control is-fullwidth">
                <input style={{ ...align, borderColor: props.isInvalid ? 'red' : '' }} {...props} id={name} name={name} 
                    value={imRecord[name]} 
                    onChange={(e) => {
                        record[name] = e.target.value

                        callback(e.target.value)
                    }}

                    {...isDisabled}
                    className={isValid ? "input is-small" : "input is-small is-danger is-danger-border"}
                    
                    placeholder={label} 
                   
                />
            </div>
            {!isValid && <p class="help is-danger"> {validationMsg || `* Please enter valid ${label}`}</p>}
        </div>
    )
}

export function Agyesno(props) {
    const { label, record, name, callback = f => f , isDisabled = false,  } = props
    const [checked1, setChecked] = useState(record?record[name]:props.value)
    useSnapshot(record)
    if (record) {
        if (record[name] !== checked1) setChecked(record[name])
    }

    return (
        <div className="field" style={{textAlign: 'center'}}>
            <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label>
            <div className="control" style={{cursor : isDisabled ? "not-allowed" : "default"}}>
                    <div onClick={ isDisabled ? 
                       null :
                        (() => {
                            if (props.disabled) return
                            const newval = checked1 === 'Yes'? 'No' : 'Yes'
                            if (props.record) record[name] = newval
                            callback(newval)
                            setChecked(newval)
                        })}>
                        {
                            (checked1 === 'Yes') ?
                                <img height='36px' width='36px' alt="on-switch" src={on}></img> :
                                <img height='36px' width='36px' alt="off-switch" src={off}></img>
                        }
                    </div>
            </div>
        </div>
    )
}

export default function Dtcargodetails({modalClose}){
    useSnapshot(DtStore)
    const [visibleHazaradousList, setvisibleHazaradousList] = useState(DtStore.DTBooking.hazardous)
    let dtStore = DtStore.DTBooking;
    let cargoList = DtStore.DTBooking.cargo_details;
    let disabled = {};
    DtStore.DTBooking['status'] !== 'Yet to Commence' ? disabled = { disabled: '1' } : disabled = {};
    let editFields = disabled;

    return(
        <>
            <div id="modal-js-example" class="modal is-active">
                <div class="modal-background"></div>

                <div  className="modal-content modal-card-content">
                    <div className="modal-card modal-card-content" onClick={(e) => e.stopPropagation()} style={{ borderRadius: '10px', padding: '20px', margin: '0 auto' }}>
                        <section className="modal-card-body">
                            <div style={{ margin: '10px', border: '1px solid lightgrey' }}>
                                <header className="card-header">
                                    <p className="card-header-title">
                                    <span className="icon is-small mr-2">
                                            <i className="fas fa-cube"></i>
                                        </span>
                                        Cargo Details
                                    </p>
                                </header>
                                <div className="card-content" style={{ paddingTop: '0px' }}>
                                <div className="content">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px' }}>
                                        <div className="column is-3 is-fullwidth" style={{ alignText: 'left' }}>
                                            <Agyesno {...disabled} isDisabled={Object.keys(disabled).length===0? false: true } label='Hazardous?' name='hazardous' record={DtStore.DTBooking}  callback={(value) => {
                                                setvisibleHazaradousList(value)
                                                if(value === 'No'){
                                                    DtStore.DTBooking.quoteClass = '';
                                                    DtStore.DTBooking.packingGroup = '';
                                                    DtStore.DTBooking.unnumber = '';
                                                }
                                            }}/>
                                        </div>
                                        <div className="column is-3 is-fullwidth" style={{ alignText: 'left' }}>
                                        <Agyesno {...disabled} isDisabled={Object.keys(disabled).length===0? false: true } label='Non-stackable?' record={DtStore.DTBooking} name='nonStackable' />
                                        </div>
                                    </div>
                                    {
                                        visibleHazaradousList === 'Yes' ? 
                                            <div className="columns is-4">
                                                <div className="columns " style={{ display: 'flex', justifyContent: 'space-between', margin: '0px' }}>
                                                    <div className="column is-3 is-fullwidth"
                                                        style={{ margin: '0px', padding: '0px' }}>
                                                        <AgSelect {...editFields} label='Class*' 
                                                            record={DtStore.DTBooking}
                                                            name='quoteClass' dataList={getHazardousClassList('')}
                                                            callback={() =>
                                                                DtStore.validate("quoteClass")
                                                            }
                                                            isValid={
                                                                DtStore.cargoValdationMap["quoteClass"]
                                                            }
                                                        />
                                                    </div>
                                                    <div className="column is-3 is-fullwidth"
                                                        style={{ margin: '0px', padding: '0px' }}>
                                                        <AgSelect {...editFields} label='Packing Group*' 
                                                            record={DtStore.DTBooking}
                                                            name='packingGroup' dataList={packingGroupList} 
                                                            callback={() =>
                                                                DtStore.validate("packingGroup")
                                                            }
                                                            isValid={
                                                                DtStore.cargoValdationMap["packingGroup"]
                                                            }
                                                        />
                                                    </div>
                                                    <div className="column is-3 is-fullwidth"
                                                        style={{ margin: '0px', padding: '0px' }}>
                                                        <AgInput {...editFields} type='text' record={DtStore.DTBooking}
                                                            label='UN Number*' name='unnumber' 
                                                            callback={() =>
                                                                DtStore.validate("unnumber")
                                                            }
                                                            isValid={
                                                                DtStore.cargoValdationMap["unnumber"]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div> :
                                        <></>
                                    }
                                </div>
                                </div>
                                <div className="table-container">
                                    <table className="table is-narrow is-bordered is-fullwidth is-small"
                                        style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ fontSize: '0.8rem', textAlign: 'center' }}>Package Type*</th>
                                                <th style={{ fontSize: '0.8rem', textAlign: 'center' }}>Quantity*</th>
                                                <th style={{ fontSize: '0.8rem', textAlign: 'center' }}>Weight per Package*</th>
                                                <th colSpan={3}>
                                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ marginTop: '5px' }}>Dimensions* -</div>
                                                        <TableSelect {...disabled} style={{ border: 'none', fontSize: '0.8rem', fontWeight: 'bold', color: 'grey' }} 
                                                            record={cargoList[0]}
                                                            name='dimensions'
                                                            dataList={['Cms', 'MM', 'Inches', 'Meters', 'Feet']}
                                                        />
                                                    </span>
                                                </th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th style={{ fontSize: '0.8rem', textAlign: 'center' }}>Length</th>
                                                <th style={{ fontSize: '0.8rem', textAlign: 'center' }}>Width</th>
                                                <th style={{ fontSize: '0.8rem', textAlign: 'center' }}>Height</th>
                                                <th ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cargoList.map((element, index) => (
                                                    <tr key={index + 1}>
                                                        <td>
                                                            <TableSelect {...disabled}
                                                                isDisabled={Object.keys(disabled).length===0? false: true } 
                                                                record={element} name='packageType'
                                                                dataList={packageTypeList} 
                                                                callback={() =>
                                                                    DtStore.validate("packageType", index)
                                                                }
                                                                isValid={
                                                                    DtStore.cargoValdationMap['cargo_details'][index+'packageType']
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <TableEdit {...disabled} type='number' 
                                                                style={{ width: '4rem', textAlign: 'right' }} 
                                                                record={element} name='numPackages' 
                                                                callback={() =>
                                                                    DtStore.validate("numPackages", index)
                                                                }
                                                                isValid={
                                                                    DtStore.cargoValdationMap['cargo_details'][index+'numPackages']
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <TableEdit {...disabled} type='number' 
                                                                    style={{ width: '4rem', textAlign: 'right' }} 
                                                                    name='totalWeight' record={element} 
                                                                    callback={() =>
                                                                        DtStore.validate("totalWeight", index)
                                                                    }
                                                                    isValid={
                                                                        DtStore.cargoValdationMap['cargo_details'][index+'totalWeight']
                                                                    }
                                                                />
                                                                <TableSelect {...disabled} style={{ border: 'none' }} record={element} name='weightPerPackage' dataList={['Kgs', 'Tons', 'Lbs']} 
                                                                />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <TableEdit type='number' {...editFields} 
                                                            style={{ width: '3rem', textAlign: 'right' }} 
                                                            record={element} name='length' 
                                                            callback={() =>
                                                                DtStore.validate("length", index)
                                                            }
                                                            isValid={
                                                                DtStore.cargoValdationMap['cargo_details'][index+'length']
                                                            }
                                                        />
                                                        </td>
                                                        <td>
                                                            <TableEdit type='number'  {...editFields} 
                                                            style={{ width: '3rem', textAlign: 'right' }} 
                                                            record={element} name='width' 
                                                            callback={() =>
                                                                DtStore.validate("width", index)
                                                            }
                                                            isValid={
                                                                DtStore.cargoValdationMap['cargo_details'][index+'width']
                                                            }
                                                        />
                                                        </td>
                                                        <td>
                                                            <TableEdit type='number' {...editFields} 
                                                            style={{ width: '3rem', textAlign: 'right' }} 
                                                            record={element} name='height'
                                                            callback={() =>
                                                                DtStore.validate("height", index)
                                                            }
                                                            isValid={
                                                                DtStore.cargoValdationMap['cargo_details'][index+'height']
                                                            }
                                                        />
                                                        </td>
                                                        <td>
                                                            {DtStore.DTBooking['status'] === 'Yet to Commence' ?
                                                                <CargoButtons line={index} length={cargoList.length} cargoDetail={element} />
                                                                : <></>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                             }
                                        </tbody>                
                                    </table>
                                </div>
                            </div>
                            <div style={{textAlign: 'end', margin: '20px 50px 0px 0px'}}>
                                {DtStore.DTBooking['status'] === 'Yet to Commence' ? <button style={{background: '#2c358a', color: '#fff', fontSize: '15px', fontWeight:500}} 
                                class='mr-3 button is-info'
                                onClick={() =>{
                                    if(DtStore.isValid()){
                                        DtApi.postEditDtBooking(dtStore,(data) => {
                                            if(data.acknowledged) modalClose(false)
                                        });
                                    }
                                }}
                                >Save</button> : <></>}
                                <button style={{background: '#2c358a', color: '#fff', fontSize: '15px', fontWeight:500}} 
                                class='ml-3 button is-info'
                                onClick={() => {
                                    modalClose(false)
                                    }}>Cancel</button>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </>
    )
}

