import React, { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED, PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import {  configStore, customerStore, entityStore,EntityV2Store } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { loader } from "../util/loader";
import Utils from "../util/Utils";
import { Nodata } from "../mui-components/nodatafound";
import BranchApi from "../state/branch-api";
import EntityApi from "../state/entity-api";
const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const DataTable = (props) => {
    useSnapshot(DtStore)
    useSnapshot(customerStore)
    useSnapshot(configStore)
    useSnapshot(loader)
    useSnapshot(entityStore)

    const [selectedFilter, SetSelectedFilter] = useState('ALL');
    const [expandrow, setExpandrow] = useState(-1);

   




    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
 


    const [{ queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    React.useReducer(reducer, initialState);

    useEffect(() => {
        ApiAgTable.dtContractData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
            res.clone().json().then((res) => {
                if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                    if (JSON.stringify(data1) != JSON.stringify(res)) {
                        setData1(res)
                    }
                }
            }, err => {
                console.log(err)
            })
        })


    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy , configStore.currentSelectedEntity , configStore.currentSelectedBranch])



    const [pageIndex,setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState('20');
    const [sortBy, setSortBy] = useState([]);
    const [showfillter, setShowfillter] = useState(false);
    // const [branches, setbranches] = useState([])
    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: +pageSize });
        setPageIndex(0)
    }, [pageSize]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    }, [sortBy]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        setPageIndex(0)
    }, [keyword]);

   

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { status: selectedFilter,date:Utils.getCurrentDateString() } });
        setPageIndex(0)
    }, [ selectedFilter, configStore.currentSelectedEntity]);

   



    
   

    const checknext = ()=>{
      let l = Number(data1['count']) - ((Number(pageIndex)+1)*Number(pageSize))
      if(l<=0){
        return true
      }else{
        return false
      }
    }

       const changefilter = (v)=> {
        SetSelectedFilter(v)
        setShowfillter(false)
       }

       const changeexpand=(i)=>{
        if(i==expandrow){
          setExpandrow(-1)
        }else{
          setExpandrow(i)
        }
       }

       const changesort = (key)=>{
        if (sortBy.length==0){
          setSortBy([{id:key,desc:true}])
        }else{
          if(sortBy[0]['id']==key){
            if(sortBy[0]['desc']){
              setSortBy([{id:key,desc:false}])
            }else{
              setSortBy([{id:key,desc:true}])
            }
          }else{
            setSortBy([{id:key,desc:true}])
          }
        }
       }

      //   function checkBranchGst(data){
      //   let isGstAvail = false;
      //   console.log(data,'oooo');
      //   data.map((r,i)=>{
      //   if( r.status === "VERIFIED" && r.gst.length > 0 && r.branchAddresses[0].address.length > 0) {
      //     isGstAvail= true
      //     return isGstAvail
      //       }
      //   })
      //   return isGstAvail  
      //  }

        function checkEntityStatus(status){
        let isStatusAvail = false; 
        if(status === "VERIFIED") {
          isStatusAvail= true
          return isStatusAvail
        }  
        return isStatusAvail  
       }

    return (
        <>
            


            
            <div className="main-content-2" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}} onClick={()=>{
          if(showfillter){
            setShowfillter(false)
          }
        }}>
  <div className="top-header" style={{padding:"0",margin:"0"}}>
    <div className="page-heading" style={{fontSize:"28px"}}>Create Booking</div>
    <div className="search-wrapper" style={{padding:"0",margin:"0"}}>
      <div className="search-div" style={{padding:"0",margin:"0"}}>
        <div className="search-img-div" style={{padding:"0",margin:"0"}}>
          <img
            src="./assets/search-grey.png"
            alt="Search"
            className="search-img"
          />
        </div>
        <input
          type="search"
          placeholder="Search"
          className="search-contracts"
          onChange={(e)=>{
            setKeyword(e.target.value)
          }}
        />
      </div>

      <div className="sort-div" style={{padding:"0",margin:"0"}}>
        <div className="sort-part" onClick={()=>{
          if(showfillter){
            setShowfillter(false)
          }else{
            setShowfillter(true)
          }
        }}>
          <span className="sort-option">{selectedFilter.charAt(0)+selectedFilter.slice(1).toLowerCase()}</span>
          <div className="search-chevron-wrapper">
            <img
              src="./assets/chevron-white.png"
              className="chevron-white"
              alt="Chevron"
            />
          </div>
        </div>

        <div className={showfillter?"sort-dropdown d-flex":"d-none"}>
          <span className={selectedFilter=="ALL"?"sort-options sort-options-active":"sort-options"} onClick={()=>changefilter("ALL")}>All</span>
          <span className={selectedFilter=="ACTIVE"?"sort-options sort-options-active":"sort-options"} onClick={()=>changefilter("ACTIVE")}>Active</span>
          <span className={selectedFilter=="EXPIRED"?"sort-options sort-options-active":"sort-options"} onClick={()=>changefilter("EXPIRED")}>Expired</span>
        </div>
      </div>
    </div>
  </div>

  <div className="whole-table whole-table1"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0",backgroundColor:"white"}}>
    <div className="heading-row" style={{padding:"0",margin:"0"}} >
      <span className="heading-table-cells heading-filler-start"></span>
      <span className="heading-table-cells contract-reference"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
        Contract Reference
      </span>
      <span className="heading-table-cells pickup-address"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>Pickup Address</span>
      <span className="heading-table-cells delivery-address"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
        Delivery Address
      </span>
      <span className="heading-table-cells created-date"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
        Created Date
        <img src="./assets/Sort-blue.png" alt="Sort" className="sort-blue" onClick={()=>changesort("DtQuotes.CreatedDate")}/>
      </span>
      <span className="heading-table-cells effective-date"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
        Effective Date
        <img src="./assets/Sort-blue.png" alt="Sort" className="sort-blue" onClick={()=>changesort("DtContracts.effective_date")}/>
      </span>
      <span className="heading-table-cells valid-upto"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
        Valid Upto
        <img src="./assets/Sort-blue.png" alt="Sort" className="sort-blue" onClick={()=>changesort("DtContracts.valid_upto")}/>
      </span>
      <span className="heading-table-cells status"  style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>Status</span>
      <span className="heading-table-cells heading-filler-end"></span>
    </div>

    <div className="table-wrapper" style={{heigth:"3.5rem"}}>
{
  data1?.results?.map((e, i) => {
    return <div className={i==expandrow?(e.DtContracts['delivery'].length>1 || (e.DtContracts['pickup_2'] !=undefined && e.DtContracts['pickup_2'].trim().length>0))?"table-row height-300":"table-row height-120":"table-row"} style={{padding:"0",margin:"0"}}>
      
      <span className={(e.DtContracts['delivery'].length>1 || (e.DtContracts['pickup_2'] !=undefined && e.DtContracts['pickup_2'].trim().length>0)) && i==expandrow?"table-cells heading-filler-start transform-down ":"table-cells heading-filler-start"} onClick={()=>changeexpand(i)}>
        <img
          src="./assets/chevron-grey.png"
          alt="Accordian"
          className={i==expandrow?"chevron-grey rotate-arrow":"chevron-grey"}
        />
      </span>
    <span className="table-cells contract-reference" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>{e.DtContracts["_id"]}</span>
    {
      (e.DtContracts['delivery'].length>1 || (e.DtContracts['pickup_2'] !=undefined && e.DtContracts['pickup_2'].trim().length>0))?<>
      <span className="table-cells pickup-address" onClick={()=>changeexpand(i)}>
        {
          (e.DtContracts['pickup_2'] !=undefined && e.DtContracts['pickup_2'].trim().length>0)?<span className="white-space-normal height-fit" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>Mulitple (2)</span>:
          <span className="white-space-normal height-fit" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>Single</span>
        }
          
        </span>
        <span className="table-cells delivery-address" onClick={()=>changeexpand(i)}>
          {
            (e.DtContracts['delivery'].length>1)?<span className="white-space-normal height-fit" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>Mulitple ({e.DtContracts['delivery'].length})</span>:
            <span className="white-space-normal height-fit" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>
          Single
        </span>
          }
          
        </span>
      </>:<>
      <span className="table-cells pickup-address" onClick={()=>changeexpand(i)}>
        {
          (i==expandrow)?<span className="white-space-normal height-fit" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>
          {e.DtContracts['pickup']}
        </span>:<span style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>{e.DtContracts['pickup']}</span>
        }
          
        </span>
        <span className="table-cells delivery-address" onClick={()=>changeexpand(i)}>
        {
          (i==expandrow)?<span className="white-space-normal height-fit" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
          {e.DtContracts['delivery'][0]['address']}
        </span>:<span style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>{e.DtContracts['delivery'][0]['address']}</span>
        }
        </span>
      </>
    }
    <span className="table-cells created-date" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>{Utils.ddmmyyyystring(e.DtQuotes['CreatedDate'])}</span>
    <span className="table-cells effective-date" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>{Utils.ddmmyyyystring(e.DtContracts['effective_date'])}</span>
    <span className="table-cells valid-upto" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>{Utils.ddmmyyyystring(e.DtContracts['valid_upto'])} </span>
    {
      (e.DtContracts['status'] != "EXPIRED")?<><span className="table-cells status">
      <span className="active-status"  style={{fontFamily:"Hanken Grotesk, san-seri"}} >Active</span>
    </span>
    <span className="table-cells heading-filler-end">
      <button className="book-button pointer" onClick={()=>{
        BranchApi.getAllBranches(e.DtContracts.entityId, branches => {
           // setbranches(branches);
        if(branches?.length>0){
          EntityApi.getEntity(e.DtContracts.entityId,entityData=>{
          // let entityData =entityStore.entityRec
          let entitystatusResult = checkEntityStatus(entityData.status)
          if(entitystatusResult){
            DtStore.DTBooking = DtStore.createbooking(e.DtContracts)
            DtStore.bview = "create"
            DtStore.valid = false
            configStore.dtCurrentView = 'Booking'
            // let results = checkBranchGst(branches)
            // if (results) {
              // DtStore.DTBooking = DtStore.createbooking(e.DtContracts)
              // DtStore.bview = "create"
              // DtStore.valid = false
              // configStore.dtCurrentView = 'Booking'
          //   }else {
          //   configStore.setModalMessage(`Please submit branch details for verification to proceed with the booking.
          //   kindly contact the Reporting manager or Customer Success to update and proceed Booking .
          //   Contact ID :
  
          //   Reporting Manager - ${entityData?.customer?.domesticTransport?.relationshipManager}
          //   Customer Success - ${entityData?.customer?.domesticTransport?.customerService}
          //   Customer ID ${e.DtContracts.entityId}.`)    
          // }
          }else{
            configStore.setModalMessage(`Please submit entity details for verification to proceed with the booking.
            kindly contact the Reporting manager or Customer Success to update and proceed Booking .
            Contact ID :
  
            Reporting Manager - ${entityData?.customer?.domesticTransport?.relationshipManager}
            Customer Success - ${entityData?.customer?.domesticTransport?.customerService}
            Customer ID ${e.DtContracts.entityId}.`)
          }
        })
        } 
      }) 
     }}>
        <img
          src="./assets/save-booking-white.png"
          alt="Save-Booking"
          className="save-booking-white"
        />
        Book
      </button>
    </span></>:<><span className="table-cells status">
          <span className="expired-status" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A"}}>Expired</span>
        </span>
        <span className="table-cells heading-filler-end visibility-hidden">
          <button className="book-button ">
            <img
              src="./assets/save-booking-white.png"
              alt="Save-Booking"
              className="save-booking-white"
            />
            Book
          </button>
        </span></>
    }
    {((e.DtContracts['delivery'].length>1 || (e.DtContracts['pickup_2'] !=undefined && e.DtContracts['pickup_2'].trim().length>0)) && i==expandrow)?<div className="address-div">
          {
            (e.DtContracts['pickup_2'] !=undefined && e.DtContracts['pickup_2'].trim().length>0)?<>
            <div className="add-block block-1">
            <label for="" className="add-heading">
              Pickup Address 1
            </label>
            <div className="add-div">
              <span className="address">
                {e.DtContracts['pickup']}
              </span>
            </div>
          </div>
          <div className="thread-div">
            <span className="thread"></span>
          </div>
          <div className="add-block block-1">
            <label for="" className="add-heading">
              Pickup Address 2
            </label>
            <div className="add-div">
              <span className="address">
                {e.DtContracts['pickup_2']}
              </span>
            </div>
          </div>
            </>:<div className="add-block block-1">
            <label for="" className="add-heading">
              Pickup Address
            </label>
            <div className="add-div">
              <span className="address">
                {e.DtContracts['pickup']}
              </span>
            </div>
          </div>
          }
          
          
          {
            e.DtContracts['delivery'].map((v, j) => {
              return <>
              <div className="thread-div">
            <span className="thread"></span>
          </div>

          <div className="add-block block-1">
            {
              (e.DtContracts['delivery'].length>1)?<label for="" className="add-heading">
              Delivery Address-{j+1}
            </label>:<label for="" className="add-heading">
              Delivery Address
            </label>
            }
            
            <div className="add-div">
              <span className="address">
                {v.address}
              </span>
            </div>
          </div>
              </>
            })
          }
        </div>:<></>}
    
  </div>
  })
}
{ data1?.results?.length === 0 && <Nodata width="300px" height="300px" />}
      </div>

    <div className="footer-row">
      <div className="left-footer">
        <span className="rows-per-page">Show rows per page</span>
        <div className="row-select-dropdown">
          <div className="row-select-wrapper">
            <select className="no-of-rows" onChange={(e)=>{
              setPageSize(e.target.value)
            }}>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <img
              src="./assets/chevron-grey.png"
              alt="Chevron"
              className="chevron-grey-2"
            />
          </div>
        </div>
      </div>

      {
  (data1['count']>0)?<div className="right-footer margin-right-5">
  <div className="no-of-pages">
  <span class="first-no">{((+pageIndex)*(+pageSize))+1}&nbsp;</span>
      <span class="hiphen">-&nbsp;</span>
      <span class="last-no">{(((+pageIndex+1)*(+pageSize))>(+data1['count']))?data1['count']:((+pageIndex+1)*(+pageSize))}&nbsp;</span>
      <span class="of">of&nbsp;</span>
      <span class="counts">{data1['count']}</span>
  </div>
  <span className="prev" onClick={()=>{
    if(pageIndex!=0){
      setPageIndex(pageIndex-1)
    }
  
}}>
    <img
      src="./assets/chevron-grey.png"
      alt="Chevron"
      className={pageIndex==0?"opacity-less chevron-grey-3":"chevron-grey-3"}
    />
  </span>
  <span className="next"  onClick={()=>{
    if(!checknext()){
      setPageIndex(pageIndex+ 1)
    }
  
}}>
    <img
      src="./assets/chevron-grey.png"
      alt="Chevron"
      className={checknext()?"opacity-less chevron-grey-4":"chevron-grey-4"}
    />
  </span>
</div>:""
}
    </div>
  </div>
</div>
        </>
    )

}


const DtContractList = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default DtContractList;