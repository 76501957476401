import { Box, Button, IconButton } from "@mui/material";
import { breadCrumbStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
import backarrow from "../assets/images/backarrow.svg";
import rightarrow from "../assets/images/rightarrow2.svg";

export function BreadCrumb(){
    const {breadCrumbs} = useSnapshot(breadCrumbStore);
    return breadCrumbs.length>0 ? <Box sx={{ maxWidth: "100%", display: "flex", flexDirection: "row" }}>
    <IconButton disableRipple onClick={() => {
        breadCrumbStore.back();
    }}>
        <img src={backarrow} />
    </IconButton>
    {breadCrumbs.map((breadCrumb, i) => 
    <Box sx={{display:"flex",flexDirection:"row",alignItems:"center",gap:0.5}}>
    <Button onClick={() => {
        breadCrumbStore.setCurrentCrumb(i);
    }} disableRipple sx={{ fontFamily: "Figtree", textTransform: "capitalize", color: i === breadCrumbs.length - 1 ? "#2C358A" : "black", padding: 0, "&:hover": { backgroundColor: "transparent" }, fontWeight: "bold" }}>{breadCrumb}</Button>
   {i === breadCrumbs.length - 1 ? <></> : <img src={rightarrow} />}
    </Box>
    )}
    </Box> : <></>
}