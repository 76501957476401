import { viewBookingStore } from "../state/CommonState"
import BookingsApi from "../state/bookings-api"
import { ShareButton } from "./ShareButton"

export function ShareBooking({bookingId,entityId,branchId}){
   
        return <ShareButton  width="30px" height="30px" title="Share Live Tracking" onSubmit={(emails)=>{
        BookingsApi.sendTrackBookingLink({id:bookingId,entityId,branchId,to:emails})
        }} />
      }
