import { useSnapshot } from "valtio";
import { DtStore } from "../DtStore";
import Utils from "../../util/Utils";

export default function Dttrackingtimedate(props) {
useSnapshot(DtStore);
let dtStore = DtStore.DTBooking;
let dz = dtStore["trackinglist"][0]
//propsstart
const { callback = f => f } = props
let dv =  dz[props.namekey]
let du =  dz[props.prevkey]
let dw = {date:"",time:""}
if(props.nextkey.length>0){
    console.log(dz)
    console.log(props.nextkey)
    dw =  dz[props.nextkey]
}
//propsend
let dvalue = dv['date']
let tvalue = dv['time']
let disabled = false
let min = ""
let max = Utils.getCurrentDateString()
if(du['date'].length==0){
    disabled = true
}else{
    min = du['date']
}

if(dw['date'].length>0){
    max = dw['date']
}
const datechange=(v)=>{
    dtStore["trackinglist"][0][props.namekey]['date'] = v
    timechange(tvalue)
    callback(tvalue)
}
const checksettime = v =>{
    if(v.length==5){
        return v
    }else{
        if(v.length==0){
            return '00:00'
        }else{
            let l = v.split(':')
            l[0] = String(l[0]).padStart(2, '0')
            l[1] = String(l[1]).padStart(2, '0')
            return l[0]+":"+l[1]
        }
    }
}

const timechange=(v)=>{
    if(Utils.getCurrentDateString()==dvalue){
        const dn = new Date();
        const h = dn.getHours();
        const m = dn.getMinutes();
        let l = +v.replace(":","")
        let l2 = +checksettime(`${h}:${m}`).replace(":","")
        if(l>l2){
            v = checksettime(`${h}:${m}`)
        }
    }
    if(dvalue==min){
        let l = +v.replace(":","")
        let l2 = +checksettime(du['time']).replace(":","")
        if(l>l2){
            dtStore["trackinglist"][0][props.namekey]['time'] = v
        }else{
            dtStore["trackinglist"][0][props.namekey]['time'] = checksettime(du['time'])
        }
    }else{
        if(dw['date'].length>0 && dvalue==max){
            let l = +v.replace(":","")
            let l2 = +checksettime(dw['time']).replace(":","")
            if(l<l2){
                dtStore["trackinglist"][0][props.namekey]['time'] = v
            }else{
                dtStore["trackinglist"][0][props.namekey]['time'] = checksettime(dw['time'])
            }
        }else{
            dtStore["trackinglist"][0][props.namekey]['time'] = v
        }
        
    }
}

return (
    <>
    <div class="time-date-div">
        <input type="date" class="date" min={min} max={max} value={dvalue} disabled={disabled} onChange={(e)=>{
        datechange(e.target.value)
        }}/>
        <input type="time" class="time" value={tvalue} disabled={disabled} onChange={(e)=>{
        timechange(e.target.value)
        }}/>
        {/* <img src="./assets/clock.png" className="clock1"/> */}
    </div>
    </>
)
}