import React from 'react'
import Utils from '../util/Utils'

export default function AgLastUpload(props) {
  const {updatedOn,updatedBy} = props.data

  return (
    <div>
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
  <tr>
    <th>Last Updated On</th>
    <td>{Utils.formatToDateTime(Number(updatedOn))}</td>
    
  </tr>
  <tr>
  <th> Last Updated By</th>
   <td>{updatedBy}</td>
  </tr>
</table>
    </div>
  )
}

export function AgLastUpload1(props) {
  const {updatedOn,updatedBy} = props.data
  

  return (
    <div>
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
  <tr>
    <th>Last Updated On</th>
    <td>{Utils.formatToDateTime(Number(updatedOn))}</td>
    
  </tr>
  <tr>
  <th> Last Updated By</th>
   <td>{updatedBy}</td>
  </tr>
  {
    (props.nextdue)?<tr>
    <th colspan="2">{props.nextdue}</th>
    </tr>:""
  }
  
</table>
    </div>
  )
}

export function AgLastUpload2(props) {

  return (
    <div>
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
  
  {
    (props.nextdue)?<tr>
    <th colspan="2">{props.nextdue}</th>
    </tr>:""
  }
  
</table>
    </div>
  )
}

