import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import DtApi from "../state/dt-api";

export function DtStopTracking() {
  useSnapshot(DtStore);
  let dtStore = DtStore.DTBooking;
  const close = (data) => {
    DtStore.DTBooking = data
    DtStore.stoptrackingmodal = false;
  };
  const Update = async () => {
    DtApi.postStopTracking(dtStore, close);
  };
  let modalClass = "modal";
  if (DtStore.stoptrackingmodal) modalClass += " is-active";
  return (
    <>
      {DtStore.stoptrackingmodal ? (
        <div
          className={modalClass}
          onClick={() => {
            DtStore.stoptrackingmodal = false;
          }}
        >
          <div className="modal-background"></div>
          <div
            className="modal-card"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ borderRadius: "10px" }}
          >
            <section className="modal-card-body">
              <div class="columns m-1 is-vcentered is-multiline">
                <div class="column is-12 pb-0 mb-0">
                  <b>
                    <h3 className="title is-5">Stop Tracking</h3>
                  </b>
                </div>
                <div class="column is-12">Are you certain you want to proceed? You cannot restart tracking if you confirm.</div>
              </div>
              <button
                class="button is-warning"
                onClick={() => (DtStore.stoptrackingmodal = false)}
              >
                No
              </button>
              <button
                class="button is-danger"
                style={{ float: "right" }}
                onClick={Update}
              >
                Yes
              </button>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
