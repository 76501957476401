const commodityList = [
    '',
    'Machinery',
    'Spare Parts',
    'Antennas',
    'Equipment',
    'Plastic',
    'Containerized general merchandise',
    'Medical Equipment',
    'Automotive Spare Parts & Components',
    'Rubber',
    'Timber',
    'Metal - Steels, Zinc, Lead, Aluminum, Copper Sheets or Ingots or Coils, High-value electronic scrap',
    'Packaged Food Items (Canned, Dry Fruits & Nuts, Dressings, Cinnamon, Spices, Rice, Sugar of any kind excluding only raw sugar, Coffee/ Tea, Milk Powder)',
    'Leather & Finished Leather Products',
    'Textiles/ Fabrics/ Apparel/ Accessories/ Footwear',
    'Toys',
    'Wheels and Tyres',
    'Casino Machines',
    'Raw materials in general classified as high risk',
    'Raw or bulk polypropylene, polyethylene and latex',
    'Biomedical, Pharmaceutical and Veterinary products, other than animal food',
    'Non-Hazardous Chemicals (including agrochemicals, fungicides, insecticides, fertilizers)',
    'Wood and wooden articles',
    'Fragile items such as tableware, ceramics, glass, among other',
    'Cigarettes, tobacco, spirits, beers, wines, and similar products',
    'Business personal computers or laptops, computer parts such as displays, hard drives, modems, electronic cards or their spare parts',
    'Printers and their parts',
    'Sound equipment',
    'Video games and consoles',
    'Video, TV or Photography cameras',
    'Televisions - LEDs, LCDs, Flat screens, Plasmas or the like',
    'Appliances such as refrigerators, washing machines, portable air conditioners, dryers, blenders, kitchen and other home appliances',
    'Articles related to COVID (medical implements, masks, tests, antibacterial, among others)'
]
const hazardousList = [
    'Tapes and Adhesives',
    'Cosmetics, perfumes, colognes and similar products (including personal hygiene products)',
    'Paints, solvents, raw materials for paints',
    'Motor Vehicles/ Automobiles',
    'Alcohol (Spirits/ Beer/ Wines/ Liquor)',
    'Printing Ink'
]
export default commodityList
export {
    hazardousList
}