import { Box, Button, CircularProgress } from "@mui/material";

export function LoaderButton(props){
    
    const {progressColor="white",progressSize,buttonContent,disableColor="#1565c0",enableLoader,size,variant,type,sx,width,fullWidth=true,...rest} = props
    
    return <Box sx={{width:width||"100%"}}><Button  disabled={enableLoader}  sx={{textTransform:"capitalize",fontFamily:"Figtree", '&.Mui-disabled': {
        backgroundColor: disableColor,
        color: '#9e9e9e',
      },...sx}} {...rest} size={size? size : 'medium'} variant={variant} type={type ? type : 'submit'}    fullWidth={fullWidth}>{ enableLoader ? <CircularProgress size={progressSize||25} sx={{color:progressColor||"white"}} /> :  buttonContent}</Button></Box>
}