import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { AgReportInput } from "./components/AgComponents";
import AguserApi from "./state/AguserApi";
import { reportStore } from "./state/reportStore.js";
import RoleUtils from "./util/RoleUtils.js";
import { PERMISSIONS } from "./util/constants.js";



export default function Report() {
    useSnapshot(reportStore)
    const [salesList, setSalesList] = useState([])
    const setLists = (users) => {
        console.log('USERS=', users)
        let agusers = users.filter(e => e.email?.endsWith('agraga.com'))
        agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))
        setSalesList(agusers)
        reportStore.sales_manager = 'all'
    }
    useEffect(() => {
        if (salesList.length == 0) {
            AguserApi.getList(setLists)
        }
    }, [])
    console.log(reportStore)
    return (
        <>
            <div class="columns is-vcentered">
                <h1 class="title">Report</h1>
            </div>
            <div class="columns is-vcentered">
                <hr style={{ backgroundColor: "#000", width: "100%", height: "1px", margin: 0, marginTop: "20px" }} />
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Name</strong></h1></div>
                {/* <div class="column is-3"><h1><strong>From Date</strong></h1></div>
            <div class="column is-3"><h1><strong>To Date</strong></h1></div> */}
                <div class="column is-6 has-text-right"><h1><strong>Action</strong></h1></div>
            </div>
            <div class="columns is-vcentered">
                <hr style={{ backgroundColor: "#000", width: "100%", height: "1px", margin: 0 }} />
            </div>
            {/* <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Entities V1</strong></h1></div> */}
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="entities_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="entities_to_date"/></div> */}
                {/* <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/entities/${new Date(new Date(reportStore.entities_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.entities_to_date).setHours(24, 0, 0, 0)).getTime()}/Entity.csv`}>Generate</a></div> */}
            {/* </div> */}
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Entities - Customer</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="entities_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="entities_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/customerEntitiesV2/${new Date(new Date(reportStore.entities_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.entities_to_date).setHours(24, 0, 0, 0)).getTime()}/Entity_Customer.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Entities - Vendor</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="entities_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="entities_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/entitiesV2/${new Date(new Date(reportStore.entities_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.entities_to_date).setHours(24, 0, 0, 0)).getTime()}/Entity_Vendor.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Drivers</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="entities_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="entities_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/drivers/${new Date(new Date(reportStore.entities_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.entities_to_date).setHours(24, 0, 0, 0)).getTime()}/Drivers.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Vehicles</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="entities_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="entities_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/vehicles/${new Date(new Date(reportStore.entities_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.entities_to_date).setHours(24, 0, 0, 0)).getTime()}/Vehicles.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Users</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="users_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="users_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/users/${new Date(new Date(reportStore.users_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.users_to_date).setHours(24, 0, 0, 0)).getTime()}/Users.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>Users Client</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="users_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="users_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/usersClient/${new Date(new Date(reportStore.users_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.users_to_date).setHours(24, 0, 0, 0)).getTime()}/Users.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>DSR & My Actions Report</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="dsr_myaction_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="dsr_myaction_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/dsr_myaction/${new Date(new Date(reportStore.dsr_myaction_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.dsr_myaction_to_date).setHours(24, 0, 0, 0)).getTime()}/DSR & My Actions Report.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-6"><h1><strong>DSR Not Updated</strong></h1></div>
                {/* <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="dsr_not_update_from_date"/></div>
            <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="dsr_not_update_to_date"/></div> */}
                <div class="column is-6 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/dsr_not_update/${new Date(new Date(reportStore.dsr_not_update_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.dsr_not_update_to_date).setHours(24, 0, 0, 0)).getTime()}/DSR Not Updated.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <hr style={{ backgroundColor: "#000", width: "100%", height: "1px", margin: 0 }} />
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Name</strong></h1></div>
                <div class="column is-3"><h1><strong>From Date</strong></h1></div>
                <div class="column is-3"><h1><strong>To Date</strong></h1></div>
                <div class="column is-3 has-text-right"><h1><strong>Action</strong></h1></div>
            </div>
            <div class="columns is-vcentered">
                <hr style={{ backgroundColor: "#000", width: "100%", height: "1px", margin: 0 }} />
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>My Actions</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="my_actions_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="my_actions_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/my_actions/${new Date(new Date(reportStore.my_actions_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.my_actions_to_date).setHours(24, 0, 0, 0)).getTime()}/My Actions.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Enquiry</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="enquiry_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="enquiry_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/enquiry/${new Date(new Date(reportStore.enquiry_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.enquiry_to_date).setHours(24, 0, 0, 0)).getTime()}/Enquiry.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Quotation Report</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="quotation_report_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="quotation_report_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/quotation/${new Date(new Date(reportStore.quotation_report_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.quotation_report_to_date).setHours(24, 0, 0, 0)).getTime()}/Quotation Report.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>LCL Real-time Pricing</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="pricing_report_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="pricing_report_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_PRICINGAPI_SERVER + `/api/v1/pricing/generate/report/${reportStore.pricing_report_from_date}/${reportStore.pricing_report_to_date}/LCL Pricing Report.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>FCL Real-time Pricing</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="fclpricing_report_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="fclpricing_report_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_PRICINGAPI_SERVER + `/api/v1/fclpricing/generate/report/${reportStore.fclpricing_report_from_date}/${reportStore.fclpricing_report_to_date}/FCL Pricing Report.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Air Real-time Pricing</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="pricing_report_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="pricing_report_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_PRICINGAPI_SERVER + `/api/v1/airpricing/generate/report/${reportStore.pricing_report_from_date}/${reportStore.pricing_report_to_date}/Pricing Report.csv`}>Generate</a></div>
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>DT Report</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="dt_report_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="dt_report_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/dt/${new Date(new Date(reportStore.dt_report_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.dt_report_to_date).setHours(24, 0, 0, 0)).getTime()}/DT Report`}>Generate</a></div>
            </div>
            {RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.LOGIN_REPORT) ?
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Login Report</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="login_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="login_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/login/${new Date(new Date(reportStore.login_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.login_to_date).setHours(24, 0, 0, 0)).getTime()}/login Report`}>Generate</a></div>
            </div> : <></>}
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>PhonePe Report</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="phonepe_report_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="phonepe_report_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/TransactionDetails/${new Date(new Date(reportStore.phonepe_report_from_date)).toISOString().split("T")[0]}/${new Date(new Date(reportStore.phonepe_report_to_date)).toISOString().split("T")[0]}/PhonePe Report`}>Generate</a></div>
            </div>
            {/* <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Vendor Payout Report</strong></h1></div>
                <div class="column is-3"><AgReportInput lable="From Date" record={reportStore} type="date" name="payout_from_date" /></div>
                <div class="column is-3"><AgReportInput lable="To Date" record={reportStore} type="date" name="payout_to_date" /></div>
                <div class="column is-3 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/VendorPayout/${reportStore.payout_from_date}/${reportStore.payout_to_date}/Vendor_Payout.csv`}>Generate</a></div>
            </div> */}
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Name</strong></h1></div>
                <div class="column is-3"><h1><strong>Relationship Manager</strong></h1></div>
                <div class="column is-2"><h1><strong>From Date</strong></h1></div>
                <div class="column is-2"><h1><strong>To Date</strong></h1></div>
                <div class="column is-2 has-text-right"><h1><strong>Action</strong></h1></div>
            </div>
            <div class="columns is-vcentered">
                <hr style={{ backgroundColor: "#000", width: "100%", height: "1px", margin: 0 }} />
            </div>
            <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Sales Report</strong></h1></div>
                <div class="column is-3">
                    <select className="select is-fullwidth" value={reportStore.sales_manager} onChange={(e) => reportStore.sales_manager = e.target.value}>
                        <option value='all' selected>All</option>
                        {salesList.map(e => <option value={e.email}>{e.firstName + ' ' + e.lastName}</option>)}
                    </select>
                </div>
                <div class="column is-2"><AgReportInput lable="From Date" record={reportStore} type="date" name="sales_report_from_date" /></div>
                <div class="column is-2"><AgReportInput lable="To Date" record={reportStore} type="date" name="sales_report_to_date" /></div>
                <div class="column is-2 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/salesreport/${reportStore.sales_manager}/${new Date(new Date(reportStore.sales_report_from_date).setHours(0, 0, 0, 0)).getTime()}/${new Date(new Date(reportStore.sales_report_to_date).setHours(24, 0, 0, 0)).getTime()}`}>Generate</a></div>
            </div>
            {/* <div class="columns is-vcentered">
                <div class="column is-3"><h1><strong>Vendor Payout Report</strong></h1></div>
                <div class="column is-3">
                   
                </div>
                <div class="column is-2"><AgReportInput lable="From Date" record={reportStore} type="date" name="payout_from_date" /></div>
                <div class="column is-2"><AgReportInput lable="To Date" record={reportStore} type="date" name="payout_to_date" /></div>
                <div class="column is-2 has-text-right"> <a className="button is-danger is-small is-rounded" style={{ backgroundColor: "#2c358a" }} href={process.env.REACT_APP_API_SERVER + `/api/v1/common/report/VendorPayout/${reportStore.payout_from_date}/${reportStore.payout_to_date}/Vendor_Payout.csv`}>Generate</a></div>
            </div> */}

        </>
    )
}
