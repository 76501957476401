import { subscribe, useSnapshot } from "valtio"
import { AgInput, AgSelect, TableEdit, TableSelect } from "../components/AgComponents"
import { Agfacdoc } from "../components/Agfacdoc"
import { Agyesno } from "../components/Agyesno"
import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { getHazardousClassList, packageTypeList, packingGroupList } from "../StaticData"
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data"
import { containerTypeList, containerTypeListTC } from '../StaticData'
import { useEffect, useState } from "react"

function ContainerButtons(props) {
    useSnapshot(enquiryStore)
    let line = props.line
    let len = props.length
    console.log(enquiryStore.current.containerDetails, "enquiryStore.current.containerDetails", line !== len - 1);
    return (
        <div className="field has-addons">
            {

                (len !== 1) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...enquiryStore.current.containerDetails];
                                console.log(list, "list....");
                                list.splice(line, 1)
                                enquiryStore.current.containerDetails = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                enquiryStore.current.containerDetails.push(new Container())
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function HomescreenContainer(props) {
    console.log(props, 'props');
    const [stuffingDisabled, setStuffingDisabled] = useState({})
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    let cid = contractsStore.current._id
    if (!cid) cid = ''
    const rec = enquiryStore.current;
    console.log(rec, "rec..");
    useEffect(() => {
        const unsubscribe = subscribe(enquiryStore.current.containerDetails, () => {
            console.log('CONTAINERDETAILS CHANGED=', enquiryStore.current.containerDetails[0].containerType)
            configStore.displaySchedules = false
        })
        return unsubscribe
    }, [enquiryStore.current.containerDetails])
    if (!rec) return <></>
    const containerList = enquiryStore.current.containerDetails
    console.log(containerList, 'containerList');
    let disabled = {}
    if (props.viewonly) disabled = { disabled: '1' }
    let pureview = {}
    if (props.pureview) {
        pureview = { disabled: '1' }
        disabled = { disabled: '1' }
    }
    let editFields = disabled
    const stype = configStore.homescreenCurrentShipmentType.toLowerCase()
    console.log(stype, 'stype1');
    const sscope = configStore.homescreenCurrentShipmentScope
    let typeList = containerTypeList
    if (rec.temperatureControlled === 'Yes') typeList = containerTypeListTC
    // else {
    //     if (rec.stuffingType !== 'Factory' || rec.destuffingType !== 'Factory') {
    //         typeList = containerTypeList.slice(0, 4)
       
    //     }
    // }


    if (rec.stuffingType === 'Dock' && rec.destuffingType === 'Dock') {
        console.log('inside check');
        rec.temperatureControlled = 'No'

    }

    console.log('*** home container rendering again', disabled)
    return (
        <>
            <div style={ loginStore.isClient() ? {backgroundColor:"white",borderRadius:"10px"} : { boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
                <header className="card-header">
                    <p className="card-header-title">
                        <span className="icon is-small mr-2">
                            <i className="fas fa-cube"></i>
                        </span>
                        Container Details
                    </p>
                </header>
                <div className="card-content" style={{ paddingTop: '0px' }}>
                    <div className="content">
                        <div className="columns" style={{ margin: '0px' }}>
                            <div className="column is-3 is-fullwidth" style={{ alignText: 'left' }}>
                                {
                                    (rec.temperatureControlled === 'No') ?
                                        <Agyesno {...disabled} isDisabled={Object.keys(disabled).length===0? false: true } label='Hazardous?' record={rec} name='hazardous' callback={(value) => {
                                            if (value === 'No') {
                                                rec.quoteClass = '';
                                                rec.packingGroup = '';
                                                rec.unnumber = '';
                                            }
                                        }} /> : <></>
                                }
                            </div>
                            <div className="column is-3 is-fullwidth">
                                {
                                    (stype === 'fcl') ?
                                        <Agfacdoc {...stuffingDisabled} isDisabled={Object.keys(disabled).length===0? false: true } {...disabled} label='Stuffing type?'
                                           
                                            record={rec} name='stuffingType' />
                                        : <></>
                                }
                            </div>
                            <div className="column is-3 is-fullwidth">
                                {
                                    (stype === 'fcl') ?
                                        <Agfacdoc {...stuffingDisabled} isDisabled={Object.keys(disabled).length===0? false: true } {...disabled} label='De-Stuffing type?'
                                           
                                            record={rec} name='destuffingType' callback={(data) => {

                                            }} />
                                        : <></>
                                }
                            </div>

                            <div className="column is-3 is-fullwidth">
                                <span style={{ float: 'right' }}>
                                    {(rec.hazardous === 'No' && !(rec.stuffingType !== 'Factory' && rec.destuffingType !== 'Factory')) ?
                                        <Agyesno {...disabled} isDisabled={Object.keys(disabled).length===0? false: true } label='Temperature Controlled?'
                                          
                                            record={rec} name='temperatureControlled' callback={(data) => {
                                               
                                                    rec.minTemperature = '';
                                                    rec.maxTemperature = '';
                                                    rec.temperature = '';
                                                    rec.cargoValue = '';
                                                
                                            }} />
                                        : <></>
                                    }
                                </span>
                            </div>
                        </div>
                        {/* {
                                (stype === 'fcl') ?  <div className="columns" style={{ margin: '0px', padding: '0px' }}><div className="column is-3 is-fullwidth">

                                {
                                 <Agyesno {...disabled} label='Do you require a Direct Carrier MBL?' record={rec} name='carrierMBL' callback={(data) => {
                                
                                 }}/>
                                }
                                </div>
                                                                
                            </div> : <></>

                            } */}


                        <div className="columns" style={{ margin: '0px', padding: '0px' }}>
                            {
                                (rec.hazardous === 'Yes') ?
                                    <>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgSelect {...editFields} {...pureview} label='Class' record={rec}
                                                name='quoteClass' dataList={getHazardousClassList(stype)} />
                                        </div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgSelect {...editFields} {...pureview} label='Packing Group' record={rec}
                                                name='packingGroup' dataList={packingGroupList} />
                                        </div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgInput {...disabled} type='text' style={{ alignText: 'right' }}
                                                label='UN Number' record={rec} name='unnumber' />
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                (rec.temperatureControlled === 'Yes' && rec.hazardous === 'No' && !(rec.stuffingType !== 'Factory' && rec.destuffingType !== 'Factory')) ?
                                    <>
                                        <div className="column is-4"></div>
                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgInput {...disabled} type='number' style={{ alignText: 'right' }}
                                                label='Temperature' record={rec} name='temperature' />

                                        </div>

                                        <div className="column is-3 is-fullwidth"
                                            style={{ margin: '0px', padding: '0px' }}>
                                            <AgInput {...disabled} type='number' style={{ alignText: 'right' }}
                                                label='Cargo Value(USD)' record={rec} name='cargoValue' />
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>

                        <div className="table-container">
                            <table className="table is-narrow is-bordered is-fullwidth is-small"
                                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th>Container Type*</th>
                                        <th>Number of Containers*</th>
                                        <th colSpan={1}>Weight (per container)*</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        containerList.map((e, i) => (
                                            <tr key={'' + cid + i}>
                                                <td>
                                                    <TableSelect {...disabled} record={e} name='containerType'
                                                        dataList={typeList} />
                                                </td>
                                                <td>
                                                    <TableEdit type='number' {...pureview}
                                                        style={{ width: '4rem', textAlign: 'right' }}
                                                        record={e} name='numContainers' />
                                                </td>
                                                <td colSpan={1}>
                                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <TableEdit type='number'  {...disabled} style={{ width: '4rem', textAlign: 'right' }}
                                                            record={e} name='wtPerContainer' />
                                                        <TableSelect  {...disabled} style={{ border: 'none' }}
                                                            record={e} name='unit' dataList={['Kgs', 'Tons', 'Lbs']} />
                                                    </span>
                                                </td>
                                                <td>
                                                    {(!props.viewonly) ?
                                                        <ContainerButtons line={i} length={containerList.length} />
                                                        : <></>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}