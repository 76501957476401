import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { TsUnloc } from "../components/AgComponents";
import { configStore } from "../state/CommonState";
import { useEffect, useState } from "react";

export function TsairModal(props){
useSnapshot(myactionsstore)
useSnapshot(configStore)
const [list, setList] = useState([])
const [ch, setCh] = useState(true)
const [sv, setSv] = useState(0)
const [rmkeys, setRmkeys] = useState([])
let ret = configStore.modalvisibledata

if(list && list.length==0 && ch){
    let l = []
    let a = 0
    let rm  = []
    for (let i = 0; i < 2; i++) {
        if(ret[`ts${i+1}_airport`]!=undefined && ret[`ts${i+1}_airport`]!=null && ret[`ts${i+1}_airport`].trim().length > 0){
            let l1 = {}
            l1['port'] = ret[`ts${i+1}_airport`]
            l1['arrival_estimate'] = ret[`ts${i+1}_arrival_estimate`]
            l1['arrival'] = ret[`ts${i+1}_arrival`]
            l1['flight_number'] = ret[`flight_number_ts${i+1}`]
            l1['departure_estimate'] = ret[`ts${i+1}_departure_estimate`]
            l1['departure'] = ret[`ts${i+1}_departure`]
            l.push(l1)
            rm.push(`ts${i+1}_airport`)
            rm.push(`ts${i+1}_arrival_estimate`)
            rm.push(`ts${i+1}_arrival`)
            rm.push(`flight_number_ts${i+1}`)
            rm.push(`ts${i+1}_departure_estimate`)
            rm.push(`ts${i+1}_departure`)
            a =a +1
        }
        
    }
    setRmkeys(rm)
    setList(l)
    setSv(a)
    setCh(false)
}

const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index)
  ]
  const getUNCodeFromLocationString = (locString) =>{
    console.log(locString)
    if(locString.indexOf('(') != -1 && locString.indexOf(')') != -1){
        return locString
        .trim()
        .slice(locString.lastIndexOf("(") + 1, locString.length - 1);
    }else{
        return locString.trim()
    }
}
  const isUnLoc2 =  (locString) => {
    locString = getUNCodeFromLocationString(locString);
    return locString.length === 5 && /^[A-Z0-9]+$/.test(locString)
  };

const add = () =>{
    if(myactionsstore.tsunloc.length == 5 || isUnLoc2(myactionsstore.tsunloc)){
        myactionsstore.isvalid = false
        let l = {'port' : myactionsstore.tsunloc,
        'arrival_estimate':"",
        'arrival':"",
        "flight_number":"",
        'departure_estimate':"",
        'departure': ""
    }
        if(list.length==0){
            setList([l])
            myactionsstore.tsunloc = ""
            setSv(1)
        }else{
            let lv = 0
            for (let i = 0; i < list.length; i++) {
                if(myactionsstore.tsunloc==list[i].port){
                    lv=1
                }
            }
            if(lv==0){
                setList(insert(list,Number(sv),l))
                myactionsstore.tsunloc = ""
                setSv(Number(sv)+1)
            }
        }
    }else{
        myactionsstore.isvalid = true
    }
    
}

const del = (id) =>{
    let l = []
    for (let i = 0; i < list.length; i++) {
        if(i!=id){
            l.push(list[i])
        }
    }
    setList(l)
    setSv(Number(sv)-1)
}

const up = (old_index, new_index) =>{
    let carr = list[old_index]
    let an = []
    for (let i = 0; i < list.length; i++) {
        if(i==new_index){
            an.push(carr)
        }
        if(i!=old_index){
            an.push(list[i])
        }
    }
    setList(an)
};

const down = (old_index, new_index) =>{
    let carr = list[old_index]
    let an = []
    for (let i = 0; i < list.length; i++) {
        if(i<old_index){
            an.push(list[i])
        }else if(i==old_index){
            an.push(list[i+1])
        }else if(i==new_index){
            an.push(carr)
        }else{
            an.push(list[i])
        }
    }
    setList(an)
}





// useEffect(() => {
//     setCh(true)
    
// },[configStore.modalvisibledata])

useEffect(()=>{
    if(myactionsstore.tsairModalVisible==true){
        setList([])
        setRmkeys([])
        setCh(true)
    }
},[myactionsstore.tsairModalVisible])
const onsubmit = () =>{
    console.log(rmkeys)
    let li = Object.keys(ret)
    let lvs = {}
    for (let i = 0; i < li.length; i++) {
        if(rmkeys.indexOf(li[i])==-1){
            lvs[li[i]] = ret[li[i]]
        }
    }
    for (let i = 0; i < list.length; i++) {
        lvs[`ts${i+1}_airport`] = list[i]['port']
        lvs[`ts${i+1}_arrival_estimate`] = list[i]['arrival_estimate']
        lvs[`ts${i+1}_arrival`] = list[i]['arrival']
        lvs[`flight_number_ts${i+1}`] = list[i]['flight_number']
        lvs[`ts${i+1}_departure_estimate`] = list[i]['departure_estimate']
        lvs[`ts${i+1}_departure`] = list[i]['departure']
    }
    configStore.modalvisibledata = lvs
    myactionsstore.tsairModalVisible= false
}


let modalClass = 'modal'
    if (myactionsstore.tsairModalVisible) modalClass += ' is-active'
    return (
        <>
        {
            (myactionsstore.tsairModalVisible)?<div className={modalClass} onClick={() => { myactionsstore.tsairModalVisible= false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px',width:"75rem" }} >
                <section className="modal-card-body" style={{width: "30em", borderRadius:"7px",position:"relative",left:"23em"}} >
                    
                    {(list && list.length<4)?<>
                    {
                        (list && list.length>0)?<>
                        <div className="columns  is-vcentered">
                            <div class="column is-12 m-0 p-0">
                                <TsUnloc label = 'Transhipment Ports' record={myactionsstore} name="tsunloc"/>
                            </div>
                            </div>
                            <div className="columns  is-vcentered m-0 p-0">
                            <div class="column is-6 m-0 p-0">
                            <label className="label is-small" style={{ marginBottom: '0px' }}>After Transhipment </label> 
                                
                            </div>
                            <div class="column is-5 m-0 p-0">
                            <select  name="unloc" id="unloc"  value={sv}
                                onChange={(e) => {
                                    setSv(e.target.value)
                                }} >
                                <option value={0}>Before {list[0].port}</option>
                                {list.map((e,i) => <option value={i+1}>{e.port}</option>)}
                            </select>
                            </div>
                            <div class="column is-1 m-0 p-0">
                            <button  onClick={add} className="button is-success is-inverted">
                                <span className="icon is-small">
                                    <i className="fas fa-plus fas fa-lg"></i>
                                </span>
                            </button>
                            </div>
                        </div>
                        </>:
                        <>
                        <div className="columns  is-vcentered m-0 p-0">
                            <div class="column is-11 m-0 p-0">
                                <TsUnloc label = 'Transhipment Ports' record={myactionsstore} name="tsunloc"/>
                            </div>
                            <div class="column is-1 m-0 p-0">
                            <button  onClick={add} className="button is-success is-inverted">
                                <span className="icon is-small">
                                    <i className="fas fa-plus fas fa-lg"></i>
                                </span>
                            </button>
                            </div>
                        </div>
                        
                        
                        </>
                    }
                    </>:""
                    
                }

                    {list.map((e,i) =>
                    <>
                    <div className="columns  is-vcentered m-0 p-0">
                    <div class="column is-2 m-0 p-0">
                            <label className="label is-small" style={{ marginBottom: '0px' }}>TS - {i+1}</label> 
                                </div>
                            <div class="column is-7 m-0 p-0">
                            <label className="label is-small" style={{ marginBottom: '0px' }}>{e.port}</label> 
                                </div>
                                <div class="column is-1 m-0 p-0">
                                    {
                                        (i!=0)?<button className="button is-success is-inverted" onClick={()=>up(i, i-1)}>
                                        <span class="icon-text has-text-danger">
                                            <span class="icon">
                                                <i class="fas fa-arrow-up fas fa-lg"></i>
                                            </span>
                                            </span>
                                        </button>:""
                                    }
                                
                                </div>
                                <div class="column is-1 m-0 p-0">
                                    {
                                        (i!=list.length-1)?<button className="button is-success is-inverted" onClick={()=>down(i, i+1)}>
                                        <span class="icon-text has-text-danger">
                                            <span class="icon">
                                                <i class="fas fa-arrow-down fas fa-lg"></i>
                                            </span>
                                            </span>
                                        </button>:""
                                    }
                                
                                </div>
                                <div class="column is-1 m-0 p-0">
                                <button className="button is-success is-inverted" onClick={()=>del(i)}>
                                <span class="icon-text has-text-danger">
                                    <span class="icon">
                                        <i class="fas fa-trash fas fa-lg"></i>
                                    </span>
                                    </span>
                                </button>
                                </div>
                        </div>
                        <hr className="m-0 p-0"/>
                        </>
)}

                        <button class="button is-warning" onClick={() => myactionsstore.tsairModalVisible= false} >cancel</button>
                        <button class="button is-danger" style={{ float: "right" }} onClick={onsubmit}>Submit</button>
                    </section>
            </div>
        </div>:<></>
        }
        </>
    )
}