import { subscribe, useSnapshot } from "valtio"
import { AgInput, AgSelect, TableEdit, TableSelect } from "../components/AgComponents"
import { useEffect, useState } from "react"
import { PortsMarkup, markupsStore, rtpStore } from "./pricing-store"
import { ServerLists } from "../StaticData"

function ActionButtons(props) {
    useSnapshot(markupsStore)
    let line = props.line
    let len = props.length
    return (
        <div className="field has-addons">
            {
                (line !== len - 1) ?
                    <p className="control">
                        <button className="button is-danger is-small is-inverted"
                            onClick={() => {
                                let list = [...markupsStore.current.portsMarkup]
                                list.splice(line, 1)
                                markupsStore.current.portsMarkup = list
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-trash"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
            {
                (line === len - 1) ?
                    <p className="control">
                        <button className="button is-success is-small is-inverted"
                            onClick={() => {
                                markupsStore.current.portsMarkup.push(new PortsMarkup())
                            }}>
                            <span className="icon is-small">
                                <i className="fas fa-plus"></i>
                            </span>
                        </button>
                    </p> : <></>
            }
        </div>
    )
}
export default function PricingPortsmarkup(props) {
    useSnapshot(markupsStore)
    useSnapshot(rtpStore)

    let rec = rtpStore.selectedMarkupCharge

    return (
        <>
            <table className="table is-narrow is-bordered is-small"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        {rec.haspor && <th>POR</th>}
                        {rec.haspol && <th>POL</th>}
                        {rec.hasrep && <th>RW</th>}
                        {rec.haspod && <th>POD</th>}
                        {rec.hasfpod && <th>FPOD</th>}
                        <th>Markup</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        markupsStore.current.portsMarkup?.map((e, i) => (
                            <tr key={'' + i + e.por+e.pol+e.rep+e.pod+e.fpod}>
                                <td>
                                    {i + 1}
                                </td>
                                {rec.haspor &&
                                    <td><TableSelect record={e} name='por'
                                        dataList={rec.porlist} /></td>}
                                {rec.haspol &&
                                    <td><TableSelect record={e} name='pol'
                                        dataList={rec.pollist} /></td>}
                                {rec.hasrep &&
                                    <td><TableSelect record={e} name='rep'
                                        dataList={rec.replist} /></td>}
                                {rec.haspod &&
                                    <td><TableSelect record={e} name='pod'
                                        dataList={rec.podlist} /></td>}
                                {rec.hasfpod &&
                                    <td><TableSelect record={e} name='fpod'
                                        dataList={rec.fpodlist} /></td>}
                                <td>
                                    <TableEdit type='number'
                                        style={{ width: '4rem', textAlign: 'right' }}
                                        record={e} name='markup' />
                                </td>
                                <td>
                                    <ActionButtons line={i} length={markupsStore.current.portsMarkup?.length} />
                                </td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>

        </>
    )
}