import React from 'react'
import logo from "./assets/images/Logo-new.svg"
import appstore from "./assets/images/appstore.png"
import playstore from "./assets/images/playstore.png"
import { loginStore } from './state/CommonState'
import { GoDotFill } from "react-icons/go";
import './App.css'
function MobileAppTemp() {
    return (
        <>
            <div className='mobile_temp_wrapper'>
                <div className='logo_container_for_mobile'>
                    <img src={logo}></img>
                </div>
                <div className='mobile_temp_text_content'>
                    <p style={{fontWeight:600,fontSize:"18px"}}>Hi {loginStore.userRec.firstName + ' ' + loginStore.userRec.lastName},👋</p>
                    <p className='gradient_text'>Thank you for choosing Agraga!</p>
                    <p style={{color:"#2C358A",fontSize:"20px",fontWeight:700}}>We are committed to providing you with the <span style={{color:"#F09D2E"}}>best experience possible.</span></p>
                    <p className='mobile_temp_instruct'>While our web-portal is not yet fully optimized for mobile browsers, you can enjoy a seamless access by downloading our mobile application. Our Mobile-app is designed to give you full functionality and convenience, enabling you to stay connected and informed, On-the-Go!</p>
                   
                </div>
            </div>
            <p className='mobile_temp_badge' style={{margin:"0 1.5rem"}}> <GoDotFill /> Stay updated anytime, anywhere</p>
            <p className='mobile_temp_badge' style={{float:"inline-end",width:"70%",borderTopRightRadius:0,borderBottomRightRadius:0}}> <GoDotFill /> Access all features with ease</p>
            <p className='mobile_temp_badge' style={{width:"95%",borderTopLeftRadius:0,borderBottomLeftRadius:0}}> <GoDotFill /> Enjoy a smooth and efficient user experience</p>
            <div className="app-store-buttons">
                <a className='app_logo_container_for_mobile' href="https://apps.apple.com/in/app/agraga/id6478482709" target="_blank" rel="noopener noreferrer">
                    <img
                        src={appstore}
                        alt="Download on the Play Store"
                       
                    />
                </a>
                <a className='app_logo_container_for_mobile' href="https://play.google.com/store/apps/details?id=com.agraga_customer" target="_blank" rel="noopener noreferrer">
                    <img
                        src={playstore}
                        alt="Download on the Play Store"
                       
                    />
                </a>
            </div>
            <p className="closing-text">
                Thank you for being a valued part of the Agraga community.
            </p>
            <div className='regards_divider'>
            <span>Best regards,</span>
            <div className='sm_logo_container_for_mobile'>
                <img src={logo}></img>
            </div>
            </div>

        </>
    )
}

export default MobileAppTemp