import { useEffect } from "react"
import { useSnapshot } from "valtio"
import { useGlobalState } from "../GlobalState"
import { configStore, contractsStore, loginStore } from "../state/CommonState"
import entityicon from '../entity.png'
import { tourStore } from "../tourStore"
import EntityApi from "../state/entity-api"
import { enquiryStore } from "../enquiry/enquiry-data";

export default function OperatingCompany(props) {
    const { changeCurrentMenu } = useGlobalState()
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(tourStore)
    
    let lastStoredEntity = localStorage.getItem('ag_current_entity')
    useEffect(() => {
        if (lastStoredEntity) {
            try {
                let entity = JSON.parse(lastStoredEntity)
                configStore.currentSelectedEntity = entity
            } catch (e) { }
        }
        if (loginStore.userRec.companyList) {
            if (loginStore.userRec.companyList.length == 1) {
                configStore.currentSelectedEntity = loginStore.userRec.companyList[0]
            }
        }

    }, [loginStore])
    

    // const setSelectedBranch = (e)=> {
    //   setBranches(e.branches)
    // }
   console.log(loginStore); 
    
    return (
        <>
            <div className='navbar-item'>{configStore.currentSelectedEntity.entityName}</div>
            {/* {
                (loginStore.userRec.companyList.length > 1 && !contractsStore.navigateFromCharts) ? */}
                    <><div className="navbar-item has-dropdown is-hoverable" onMouseOver={()=>(tourStore.step==1)?tourStore.changestep(tourStore.step):""}>
                        <div className={"navbar-link tour"+ (tourStore.step==2 && tourStore.enable ? ' is-active' : '')}>
                            <span className="icon">
                                {/*<img src={entityicon} />*/}
                                <span className="icon is-small">
                                        <i className="fa-regular fa-building"></i>
                                    </span>
                            </span>
                        </div>
                        
                        <div className="navbar-dropdown tour1" style={{display: tourStore.step==2 && tourStore.enable ? 'block' : ''}}>
                            {(loginStore.userRec.companyList) ?
                                loginStore.userRec.companyList.map(e => {
                                    return (
                                        <a className="navbar-item" onClick={() => 
                                            (configStore.currentSelectedEntity= e,
                                            enquiryStore.current.entityId = e.entityId)
                                        }>
                                            {e.entityName}
                                        </a>
                                    )
                                }) : <></>
                            }
                        </div>                             
                    </div></>
                    {/* : <></>
            } */}

         <div className='navbar-item'>{configStore.currentSelectedBranch.gst}</div>
         <><div className="navbar-item has-dropdown is-hoverable" onMouseOver={()=>(tourStore.step==1)?tourStore.changestep(tourStore.step):""}>
                        <div className={"navbar-link tour"+ (tourStore.step==2 && tourStore.enable ? ' is-active' : '')}>
                            <span className="icon">
                                {/*<img src={entityicon} />*/}
                                <span className="icon is-small">
                                        <i className="fa-regular fa-building"></i>
                                    </span>
                            </span>
                        </div>
                        
                        <div className="navbar-dropdown tour1" style={{display: tourStore.step==2 && tourStore.enable ? 'block' : ''}}>
                            {(configStore.currentSelectedEntity.branches) ?
                                configStore.currentSelectedEntity.branches.map(e => {
                                    return (
                                        <a className="navbar-item" onClick={() => {
                                            // EntityApi.changeEntity(e.gst)
                                            configStore.currentSelectedBranch = e
                                            tourStore.changestep(tourStore.step)
                                            localStorage.setItem('ag_current_entity', JSON.stringify(e))
                                            // enquiryStore.current.gst = e.gst;
                                            //changeCurrentMenu('Dashboard')
                                        }}>
                                            {e.gst}
                                        </a>
                                    )
                                }) : <></>
                            }
                        </div>

                         
                   
                        
                       
                    </div></>
        </>
    )
}