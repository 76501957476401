import { useSnapshot } from "valtio"
import {
    contractsStore, loginStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { VehicleTypes } from "./enquiry-data"
import ChargeTag from "../pricing/charges-tag"
import { rtpStore } from "../pricing/pricing-store"

export function TransportTable1(props) {
    useSnapshot(loginStore)
    let vehicleRates = props.data
    let vtypes = new VehicleTypes()
    let fields = vtypes.fields
    let vehicles = Object.keys(vehicleRates)

    return (
        <div className="p-4" style={{ width: '300px' }}>
            {
                vehicles.map(e => {
                    if (vehicleRates[e].cost && vehicleRates[e].numVehicles)
                        return (
                            <div className="columns">
                                <div className="column is-6" style={{ padding: '0px' }}>{fields[e]} ({vehicleRates[e].numVehicles})</div>
                                {
                                    (loginStore.isClient()) ? <></> :
                                        <div className="column is-3" style={{ padding: '0px' }}>{vehicleRates[e].vendorId}</div>
                                }
                                <div className="column is-3" style={{ padding: '0px' }}>{vehicleRates[e].cost}</div>
                            </div>
                        )
                    return <></>
                })
            }
        </div>
    )
}
export function TransportTableRow(props) {
    let num = Utils.toNum(props.textval)
    //if (!num) return <></>
    return (
        <div className="columns">
            <div className="column is-5" style={{ padding: '0px', textAlign: 'right' }}>{props.label}</div>
            <div className="column is-1"></div>
            <div className="column is-6" style={{ padding: '0px', textAlign: 'left' }}>{props.textval}</div>
        </div>
    )
}
export function TransportTable(props) {
    let e = props.data
    let tataAce = Number(e.tataAce.cost)
    let bolero = Number(e.bolero.cost)
    let trailer14 = Number(e.trailer14.cost)
    let tata407 = Number(e.tata407.cost)
    let trailer20 = Number(e.trailer20.cost)
    let trailer24 = Number(e.trailer24.cost)
    let sxl32 = Number(e.sxl32.cost)
    let mxl32 = Number(e.mxl32.cost)
    return (
        <div className="p-4" style={{ width: '300px' }}>
            {/*<TransportTableRow label='Vendor ID' textval={e.vendorId} />
            <TransportTableRow label='Vendor Name' textval={e.vendorName} />*/}
            {
                (tataAce > 0) ?
                    <TransportTableRow label='Tata Ace' textval={Utils.N(e.tataAce)} /> :
                    <></>
            }
            {
                (bolero > 0) ?
                    <TransportTableRow label='Bolero' textval={Utils.N(e.bolero)} /> :
                    <></>
            }
            {
                (trailer14 > 0) ?
                    <TransportTableRow label='14 Foot Trailer' textval={Utils.N(e.trailer14)} /> :
                    <></>
            }
            {
                (tata407 > 0) ?
                    <TransportTableRow label='Tata 407' textval={Utils.N(e.tata407)} /> :
                    <></>
            }
            {
                (trailer20 > 0) ?
                    <TransportTableRow label='20 Foot Trailer' textval={Utils.N(e.trailer20)} /> :
                    <></>
            }
            {
                (trailer24 > 0) ?
                    <TransportTableRow label='24 Foot Trailer' textval={Utils.N(e.trailer24)} /> :
                    <></>
            }
            {
                (sxl32 > 0) ?
                    <TransportTableRow label='32 Foot SXL' textval={Utils.N(e.sxl32)} /> :
                    <></>
            }
            {
                (mxl32 > 0) ?
                    <TransportTableRow label='32 Foot MXL' textval={Utils.N(e.mxl32)} /> :
                    <></>
            }
        </div>
    )
}
export default function LCLChargeClientView(props) {
    let { chargesName } = props
    if (!chargesName) chargesName = 'chargesList'
    useSnapshot(contractsStore)
    useSnapshot(loginStore)
    useSnapshot(rtpStore)
    let rec = contractsStore.current
    let tc = []
    if (props.dummyContract) {
        rec = props.dummyContract
        tc = rec.tc
    }
    if (!rec.terms) {
        rec.terms = {}
        rec.terms.your = []
        rec.terms.shipper = []
        rec.terms.consignee = []
    }
    let terms = rec.terms.your
    let heading = 'On Your Account'
    let heading1 = 'Your Account Total'
    let subscript = -1
    if (chargesName == 'chargesList') {
        if (props.subscript >= 0) subscript = props.subscript
    }
    switch (chargesName) {
        case 'shipperchargesList':
            heading = `On Your Shipper's Account`
            heading1 = `Shipper's Account Total`
            terms = rec.terms.shipper
            break
        case 'consigneechargesList':
            heading = `On Your Consignee's Account`
            heading1 = `Consignee's Account Total`
            terms = rec.terms.consignee
            break
    }
    let colspan = 8
    if (loginStore.isClient()) colspan = 3
    const Tn = ({ i, name }) => {
        let list = rec[chargesName]
        const e = list[i]
        if (name === 'total') {
            return (
                <span style={{ textAlign: 'right' }}>{Utils.N(e[name], e.currency)}</span>
            )
        } else {
            //console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    let grandTotal = 0.0
    let voldis = 0.0
    rec[chargesName].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
            //grandTotal -= voldis
        }
        else if (e.isIncluded == 'Yes')
            grandTotal += e.total * erate
    })
    let counter = 1
    if (loginStore.isClient() && rtpStore.pricingSourceScreen != 'booking') {
        return (
            <div style={{ display: 'block' }}>
                <div style={{ width: '100%', fontSize: '1.1rem', fontWeight: 'bold', display: 'flex' }}>
                    <p style={{ width: '50%', textAlign: 'left' }}>{heading1}</p>
                    <div style={{ width: '50%', textAlign: 'right' }}>{Utils.N(grandTotal)}</div>
                </div>
                {
                    (voldis > 0) ?
                        <>
                            <div style={{ width: '100%', fontSize: '1.1rem', fontWeight: 'bold', display: 'flex' }}>
                                <p style={{ width: '50%', textAlign: 'left' }}>(Less) Volume Discount</p>
                                <div style={{ width: '50%', textAlign: 'right' }}>{Utils.N(voldis)}</div>
                            </div>
                        </> :
                        <></>
                }
                {
                    (voldis > 0 && grandTotal - voldis > 0) ?
                        <>
                            <div style={{ width: '100%', fontSize: '1.1rem', fontWeight: 'bold', display: 'flex' }}>
                                <p style={{ width: '50%', textAlign: 'left' }}>Final Discounted Offer</p>
                                <div style={{ width: '50%', textAlign: 'right' }}>{Utils.N(grandTotal - voldis)}</div>
                            </div>
                        </> :
                        <></>
                }
                <div style={{ width: '100%', textAlign: 'right', color: 'blue' }}>
                    Taxes & Duties additional, as applicable
                </div>
                {
                    terms.map(e => <div style={{ width: '100%', textAlign: 'right', color: 'grey' }}>
                        {e}
                    </div>)
                }
                <div className="columns" style={{ width: '100%', backgroundColor: '#eee', marginTop: '1rem' }}>
                    <div className="column is-2" style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Inclusions</div>
                    <div className="column is-10 tags are-normal">
                        <>
                            {
                                rec[chargesName].filter(e => e.chargeDescription != 'Volume Discount').map((e, i) => {
                                    return <ChargeTag charge={e} subscript={subscript} />
                                })
                            }
                        </>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <td colspan={colspan + 4}>
                                <div style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    backgroundColor: '#ffeee0',
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold'
                                }}>{heading}</div>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {
                                (!loginStore.isClient()) ?
                                    <>
                                        <th colSpan='4' className="has-text-centered">Per Unit</th>
                                        <th></th>
                                    </>
                                    : <></>
                            }
                            <th></th>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th style={{ textAlign: 'center' }}>Charge Description</th>
                            <th>Currency</th>
                            {
                                (!loginStore.isClient()) ?
                                    <>
                                        <th style={{ textAlign: 'center' }}>Per CBM</th>
                                        <th style={{ textAlign: 'center' }}>Per Ton</th>
                                        <th style={{ textAlign: 'center' }}>Minimum</th>
                                        <th style={{ textAlign: 'center' }}>Maximum</th>
                                        <th style={{ textAlign: 'center' }}>Per Shipment</th>
                                    </>
                                    : <></>
                            }
                            <th style={{ textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rec[chargesName].map((e, i) => {
                                if (e.chargeDescription != 'Volume Discount') return (
                                    <tr key={e.id}>
                                        <td >{counter++}</td>
                                        <td>{
                                            (e.transportRec) ?
                                                <div className="transporttooltip">{e.chargeDescription}
                                                    <span className="transporttooltiptext"><TransportTable1 data={e.transportRec} /> </span>
                                                </div>
                                                :
                                                e.chargeDescription
                                        }</td>
                                        <td>
                                            {e.currency}
                                        </td>
                                        {
                                            (!loginStore.isClient()) ?
                                                <>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perCBM' /></td>
                                                    <td style={{ textAlign: 'enter' }}><Tn i={i} name='perTon' /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='minimum' /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='maximum' /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perShipment' /></td>
                                                </>
                                                : <></>
                                        }
                                        <td style={{ textAlign: 'right' }}><Tn i={i} name='total' /></td>
                                    </tr>
                                )
                            }
                            )
                        }
                        <tr>
                            {
                                (!loginStore.isClient()) ?
                                    <>
                                        <td colSpan={8}></td>
                                    </>
                                    : <td colSpan={3}></td>
                            }
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal)}</strong></td>
                        </tr>
                        {
                            (voldis > 0) ?
                                <>
                                    <tr>
                                        <td colSpan={colspan}>(Less) Volume Discount</td>
                                        <td style={{ textAlign: 'right' }}><strong>{Utils.N(voldis)}</strong></td>
                                    </tr>
                                </> :
                                <></>
                        }
                        {
                            (voldis > 0 && grandTotal - voldis > 0) ?
                                <>
                                    <tr>
                                        <td colSpan={colspan}>Final Discounted Offer</td>
                                        <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal - voldis)}</strong></td>
                                    </tr>
                                </> :
                                <></>
                        }
                        <tr>
                            <td colSpan={9} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>
                        {/*
                            tc.map(e => <tr>
                                <td colSpan={colspan + 1} style={{ textAlign: 'right' }}>{e}</td>
                            </tr>
                            )
                            */}
                    </tbody>
                </table>
            </div>
        </>
    )
}
