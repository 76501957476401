
export function ViewBox({title,children}){
    return <div className="flex flex-col p-4 bg-white rounded-lg w-full grow gap-2">
        <div className="flex bg-[#DBDEFFFC] rounded h-[40px] items-center">
            <p className="flex text-blue-dark font-bold pl-[12px]">{title}</p>
        </div>
        <div>
            {children}
        </div>
    </div>
}