import { loginStore } from "../state/CommonState"

export function ChartBlueHeading(props) {
    return (
        <div style={
            {
                width: '100%',
                textAlign: 'center',
                fontSize: '1.8rem',
                fontWeight: 'bold',
                textDecoration: 'underline',
                marginLeft:"34px",marginTop:"-6px"
            }
        } className="">
            {props.headingText}
        </div>
    )
}
export function ChartBlueBox(props) {
    return (
        <div style={
            loginStore.isClient() ? { width: '100%', height: '100%',
            textAlign: 'center',
            fontWeight: 'bold', backgroundColor:"white",borderRadius:"10px"} : {
                width: '100%', height: '100%',
                textAlign: 'center',
                fontWeight: 'bold',
                borderRadius: '30px',
                border: '1px solid black',
                background: '#ddeeff',
                paddingBottom : "8px"
            }
        } >
            {
                (props.headingText) ?
                    <ChartBlueHeading headingText={props.headingText} />
                    :
                    <></>
            }
            {props.children}
        </div>
    )
}