import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"
import {
    EntityColumn,
    EntityColumnnonagraga,
    UserColumn
} from "../components/ag-table/columns"

import ReactTablePagination from '../components/ag-table/ReactTablePagination'
import TableFilter from "../components/ag-table/TableFilter"

import { Sorting } from "../components/ag-table/ag-sorting"
import {
    reducer, PAGE_CHANGED,
    PAGE_SIZE_CHANGED,
    PAGE_SORT_CHANGED,
    PAGE_FILTER_CHANGED,
    TOTAL_COUNT_CHANGED, PAYLOAD_CHANGED
} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table"
import { aguserStore, configStore, entityStore, loginStore, routerStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { AguserType, EntityType } from "../state/Types"
import InviteUser from "../agusers/invite-user"

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};



const DataTable = (props) => {
    useSnapshot(configStore)
    useSnapshot(entityStore)
    const domain = loginStore.email.split('@')[1]
    const filterStatus = {
        'Yes':'Credit-Clients',
        'No': 'Non-Credit',
        'TEMPORARY':'TEMPORARY',
        'VERIFIED':'VERIFIED',
        'KYC-VERIFIED':'KYC-VERIFIED'
    }
    const filterMsme = {
        'Yes':'Yes',
        'No':'No'
    }
    const [selectedFilter, SetSelectedFilter] = useState(`ALL`);
    const [selectedMsme, SetSelectedMsme] = useState(`ALL`);
    
    const [keyword, setKeyword] = useState('');
    const [inviteGst, setInviteGst] = useState('')
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    let column = {}
    if(domain === 'agraga.com'){
        column = EntityColumn
    }else{
        column = EntityColumnnonagraga
    }
    // let columns = useMemo( () => COLUMNS, [])
    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
        React.useReducer(reducer, initialState);
    useEffect(()=>{
    ApiAgTable.entitiesData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res=>{
        res.clone().json().then((res) => {
            if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
               if(JSON.stringify(data1)!=JSON.stringify(res)){
                setData1(res)
               }
            }
        }, err => {
            console.log(err)
        })
    })
},[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy])

    


    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results || [],
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );
    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }, [keyword, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { statusFilter: selectedFilter, msmeFilter: selectedMsme } });
        gotoPage(0);
    }, [selectedFilter, selectedMsme]);

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

   
        return (
            <>
                <h1 className="title">Entities</h1>
            <nav className="level">
                <div className="level-left">
                <div className="level-item">
                        <p className="subtitle is-5">
                            {data1?.count } entities
                        </p>
                    </div>
                    {
                        (RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_ENTITY)) &&
                    <p className="level-item">
                        <a className="button is-link"
                            onClick={() => {
                                entityStore.entityRec.set(new EntityType())
                                routerStore.entityCurrentView = 'new'
                            }}>New</a>
                    </p>
                    }
                    <div className="level-item is-hidden-tablet-only">
                        <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} />
                    </div>
                    <div class="level-item level-left ml-6">
                         <div class="field">
                         <p>Status</p>
                             <p class="control has-icons-left has-icons-right">
                                 <span class="select">
                                     <select name="status" id="status" 
                                     value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                     >
                                         <option value="ALL">All</option>
                                         {
                                             Object.entries(filterStatus).map(([key, value]) => (
                                                 <option value={key}>{value}</option>
                                             ))
                                         }
                                     </select>
                                     <span class="icon">
                                         <i class="fa-solid fa-filter" style={{color:"red"}}></i>
                                     </span>
                                 </span>
                             </p>
                         </div>
                     </div>
                     <div class="level-item level-left ml-6">
                         <div class="field">
                         <p>MSME</p>
                             <p class="control has-icons-left has-icons-right">
                                 <span class="select">
                                     <select name="msme" id="msme" 
                                     value={selectedMsme} onChange={(e) => SetSelectedMsme(e.target.value)}
                                     >
                                         <option value="ALL">All</option>
                                         {
                                             Object.entries(filterMsme).map(([key, value]) => (
                                                 <option value={key}>{value}</option>
                                             ))
                                         }
                                     </select>
                                     <span class="icon">
                                         <i class="fa-solid fa-filter" style={{color:"red"}}></i>
                                     </span>
                                 </span>
                             </p>
                         </div>
                     </div>
                </div>
            </nav>

                {
                     <>
                        {
                            (typeof data1?.count === 'undefined' || data1?.count == 0) && <p>No results found</p>
                        }
                        {(data1?.count > 0) &&
                            <>
                             <div className="table-container">
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <thead>
                    {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        <th>#</th>
                                                        {headerGroup.headers.map(column => (
                                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                                {column.render('Header')}
                                                                <Sorting column={column} />
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                                                {page.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <>
                                                        <tr {...row.getRowProps()}>
                                                            <td>{(pageIndex*pageSize)+i+1}</td>
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        switch (cell['column']['Header']) {
                                                                            case 'Actions':
                                                                                return <td><div className="field has-addons">
                                                                                {
                                                                                    (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIES_VIEW)) &&
                                                                                <p className="control">
                                                                                    <button title="View" className="button is-dark is-inverted"
                                                                                        style={{ paddingLeft: '0px' }}
                                                                                        onClick={() => {
                                                                                            entityStore.entityRec.set(e)
                                                                                            routerStore.entityCurrentView = 'view'
                                                                                        }}>
                                                                                        <span className="icon is-small">
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </span>
                                                                                    </button>
                                                                                </p>
                                                                                }
                                                                                {/* {(RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIES) || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIES_STATUS)) && ( */}
                                                                                {(RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENTITIES)) && (
                                                                                <p className="control">
                                                                                    <button title="Edit" className="button is-danger is-inverted"
                                                                                        style={{ paddingLeft: '5px' }}
                                                                                        onClick={() => {
                                                                                            entityStore.entityRec.set(e)
                                                                                            routerStore.entityCurrentView = 'edit'
                                                                                        }}>
                                                                                        <span className="icon">
                                                                                            <i className="fas fa-pen-to-square"></i>
                                                                                        </span>
                                                                                    </button>
                                                                                </p>)}
                                                                                {
                                                                                    (e.status !== 'TEMPORARY1') ?
                                                                                        <p className="control">
                                                                                            <button title="Invite User" className="button is-link is-inverted "
                                                                                                style={{ borderRadius: '5px', padding: '3px' }}
                                                                                                onClick={() => {
                                                                                                    setInviteGst(e.gst)
                                                                                                }}>
                                                                                                <span className="icon">
                                                                                            <i className="fas fa-user-plus"></i>
                                                                                        </span>
                                                                                            </button>
                                                                                        </p> : <></>
                                                                                }
                                                                            </div></td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </tr>
                                                        {
                                                            (inviteGst === row.original.gst) ?
                                                                <tr>
                                                                    <td style={{backgroundColor: 'grey'}} colSpan={9}>
                                                                        <InviteUser gst={row.original.gst} resetState={setInviteGst}
                                                                            admin='Yes' style={{ float: 'right' }} />
                                                                    </td>
                                                                </tr> : <></>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                </table>
                {(rows.length > 0) && (
                                        <>
                                            <div className="columns" style={{width:"100%"}}>
                                                <div className="column is-6">
                                                    <ReactTablePagination
                                                        page={page}
                                                        gotoPage={gotoPage}
                                                        previousPage={previousPage}
                                                        nextPage={nextPage}
                                                        canPreviousPage={canPreviousPage}
                                                        canNextPage={canNextPage}
                                                        pageOptions={pageOptions}
                                                        pageSize={pageSize}
                                                        pageIndex={pageIndex}
                                                        pageCount={pageCount}
                                                        setPageSize={setPageSize}
                                                        manualPageSize={manualPageSize}
                                                        dataLength={totalCount}
                                                    />
                                                </div>
                                                <div className="column is-6" style={{ textAlign: "right" }}>
                                                    <div className="select">
                                                        <select
                                                            value={pageSize}
                                                            onChange={(e) => {
                                                                setPageSize(Number(e.target.value));
                                                            }}
                                                        >
                                                            {[5, 10,20, 50, 100].map((pageSize) => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    Show {pageSize}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>


                                        </>
                                    )}
            </div>
                               
                            </>
                        }
                    </>
                }


            </>
        )
}



const ListEntities = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default ListEntities;