import React from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation } from 'react-router';
import './index.css';
import App from './App';
import Login from './Login'
import Main from './Main'
import Agapp from './Agapp';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalState, { useGlobalState } from './GlobalState';
import { BrowserRouter } from 'react-router-dom';
/*const root1 = ReactDOM.createRoot(document.getElementById('root'));
root1.render(
  <GlobalState>
    <Router>
      <App />
    </Router>
  </GlobalState>
);*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <GlobalState>
    <Agapp/>
  </GlobalState>
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
