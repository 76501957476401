import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import {
  Col1,
  Col2,
  Col3,
  Col4,
  Col5,
  Col6,
  Col8,
  Cols,
  Col7,
} from "../Controls";
import { dtactionStore } from "../dtactions/dtaction-store";
import { exportToExcel } from "react-json-to-excel";
import Utils from "../util/Utils";
import { AgMultiSelectWithCheckBoxes } from "../components/AgCustomComponents";
import { SchedulesPortToPort } from "../state/Types";
import { portToPortSchedules } from "../state/CommonState";
import { multiSelectListStore } from "../state/CommonState";
import { getStaticConsolidatorList } from "../StaticData";
import { useLayoutEffect } from "react";
import { SchedulesValidation } from "../state/validation-stores";
import { AgUnloc, AgUnloc2 } from "../components/AgComponents";
export default function LCLFCLSchedule(data) {
  useSnapshot(multiSelectListStore);
  const [selected, setSelected] = useState(null);
  const [POR, SetPOR] = useState(null);
  const [consolidator, SetConsolidator] = useState(false);
  console.log("DS", data);
  let checkboxState = data.checkbox.filter((e) => e == false);
  console.log("C", checkboxState);
  const consolidatorListKey = "consolidatorList";
  const [selectedValue, setSelectedValue] = useState(" ");
  const _params = (label, name, type = "text", dataList = []) => {
    return {
      type,
      name,
      label,
      record: portToPortSchedules,
      dataList,
    };
  };
  useEffect(() => {
    if (dtactionStore.modalVisible1 == true) {
      setSelected(null);
      setSelectedValue(" ");
      SetConsolidator(false);
      portToPortSchedules.portOfReceipt = "";
      SchedulesValidation.schdeuleValidMap["portOfReceipt"] = true;
    }
  }, [dtactionStore.modalVisible1]);
  const options = ["LCL", "FCL"];
  useEffect(() => {}, [POR]);
  useLayoutEffect(() => {
    if (options[selected] == "LCL") {
      if (portToPortSchedules?.consolidatorList == "")
        portToPortSchedules.consolidatorList = selectedConsolidatorList;
      multiSelectListStore.selectList[consolidatorListKey] =
        selectedConsolidatorList?.map(({ consolidatorAbbrName, scacCode }) => ({
          id: consolidatorAbbrName,
          name: consolidatorAbbrName,
          scacCode: scacCode,
          checked: portToPortSchedules.consolidatorList
            ?.map((addedConsolidator) => addedConsolidator.consolidatorAbbrName)
            .includes(consolidatorAbbrName),
        }));
    } else portToPortSchedules.consolidatorList = "";
  }, [portToPortSchedules.consolidatorList, selected]);

  function onChange(i) {
    setSelected((prev) => (i === prev ? null : i));
  }

  useSnapshot(dtactionStore);
  const selectedConsolidatorList = getStaticConsolidatorList();
  console.log("SD", selectedConsolidatorList);
  let modalClass = "modal";
  if (dtactionStore.modalVisible1) modalClass += " is-active";
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    SetConsolidator(false);
  };

  function formatDate(date) {
    console.log("Dq",date.split("T")[0])
    var d = new Date(date.split("T")[0]),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
   console.log([day, month, year].join('-'))
    return [day, month, year].join('-');
}
  const download = async () => {
    console.log("S", options[selected]);
    console.log("S", portToPortSchedules);
   

    let _date = (dt) => {
     
      return Utils.formatDateShort(new Date(new Date(dt.split("T")[0]).toISOString().split("T")[0]));
    };
    let selectedstate = options[selected];
    if (selectedstate == "FCL") {
      let dataz = [];
      for (let z = 0; z < data.data.length; z++) {
        let l = {};
        let e = { ...data.data[z]._id, ...data.data[z].sch };
        if (e.checkboxEnabled == true && checkboxState.length > 0) {
          if (!e["POR (CFS / ICD / PORT)"])
            e["POR (CFS / ICD / PORT)"] = e["POL"];

          if (e["POR Name"]) {
            l["POR Name"] = e["POR Name"];
          } else {
            l["POR Name"] = "";
          }
          if (e["POR Code"]) {
            l["POR Code"] = e["POR Code"];
          } else {
            l["POR Code"] = "";
          }
          if (e["Departure from POR"]) {
            l["Departure from POR"] = e["Departure from POR"];
          } else {
            l["Departure from POR"] = "";
          }
          if (e["oname"]) {
            l["oname"] = e["oname"];
          } else {
            l["oname"] = "";
          }
          if (e["ocode"]) {
            l["ocode"] = e["ocode"];
          } else {
            l["ocode"] = "";
          }
          if (e["oetd"]) {
            l["oetd"] = formatDate(e["oetd"])
            
          } else {
            l["oetd"] = "";
          }
          if (e["r1code"]) {
            l["r1code"] = e["r1code"];
          } else {
            l["r1code"] = "";
          }
          if (e["v1name"]) {
            l["v1name"] = e["v1name"];
          } else {
            l["v1name"] = "";
          }
          if (e["s1voy"]) {
            l["s1voy"] = e["s1voy"];
          } else {
            l["s1voy"] = "";
          }
          if (e["ts1name"] && e["ts1name"] != e["dname"]) {
            l["ts1name"] = e["ts1name"];
          } else {
            l["ts1name"] = "";
          }
          if (e["ts1code"] && e["ts1name"] != e["dname"]) {
            l["ts1code"] = e["ts1code"];
          } else {
            l["ts1code"] = "";
          }
          if (e["r1code"] && e["ts1name"] != e["dname"]) {
            l["r1code"] = e["r1code"];
          } else {
            l["r1code"] = "";
          }
          if (e["ts1eta"] && e["ts1name"] != e["dname"]) {
            l["ts1eta"] = formatDate(e["ts1eta"])
            // Utils.ddmmyyyy(
            //   new Date(new Date(e["ts1eta"]).toISOString().split("T")[0])
            // );
          } else {
            l["ts1eta"] = "";
          }
          if (e["ts1etd"] && e["ts1name"] != e["dname"]) {
            l["ts1etd"] = formatDate(e["ts1etd"])
            // Utils.ddmmyyyy(
            //   new Date(new Date(e["ts1etd"]).toISOString().split("T")[0])
            // );
          } else {
            l["ts1etd"] = "";
          }
          if (e["r2code"] && e["ts1name"] != e["dname"]) {
            l["r2code"] = e["r2code"];
          } else {
            l["r2code"] = "";
          }
          if (e["v2name"] && e["ts1name"] != e["dname"]) {
            l["v2name"] = e["v2name"];
          } else {
            l["v2name"] = "";
          }
          if (e["s2voy"] && e["ts1name"] != e["dname"]) {
            l["s2voy"] = e["s2voy"];
          } else {
            l["s2voy"] = "";
          }
          if (
            e["ts2name"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["ts2name"] = e["ts2name"];
          } else {
            l["ts2name"] = "";
          }
          if (
            e["ts2code"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["ts2code"] = e["ts2code"];
          } else {
            l["ts2code"] = "";
          }
          if (
            e["ts2eta"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["ts2eta"] = formatDate(e["ts2eta"]);
          } else {
            l["ts2eta"] = "";
          }
          if (
            e["ts2etd"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["ts2etd"] = formatDate(e["ts2etd"]);
          } else {
            l["ts2etd"] = "";
          }
          if (
            e["r3code"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["r3code"] = e["r3code"];
          } else {
            l["r3code"] = "";
          }
          if (
            e["v3name"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["v3name"] = e["v3name"];
          } else {
            l["v3name"] = "";
          }
          if (
            e["s3voy"] &&
            e["r3code"] &&
            e["r3code"].length > 0 &&
            e["ts2name"] != e["dname"]
          ) {
            l["s3voy"] = e["s3voy"];
          } else {
            l["s3voy"] = "";
          }
          if (e["dname"]) {
            l["dname"] = e["dname"];
          } else {
            l["dname"] = "";
          }
          if (e["dcode"]) {
            l["dcode"] = e["dcode"];
          } else {
            l["dcode"] = "";
          }
          if (e["deta"]) {
            l["deta"] = formatDate(e["deta"])
            // Utils.ddmmyyyy(
            //   new Date(new Date(e["deta"]).toISOString().split("T")[0])
            // );
          } else {
            l["deta"] = "";
          }
          if (e["cscac"]) {
            l["cscac"] = e["cscac"];
          } else {
            l["cscac"] = "";
          }
          dataz.push(l);
        } else {
          if (checkboxState.length == 0) {
            if (!e["POR (CFS / ICD / PORT)"])
              e["POR (CFS / ICD / PORT)"] = e["POL"];

            if (e["POR Name"]) {
              l["POR Name"] = e["POR Name"];
            } else {
              l["POR Name"] = "";
            }
            if (e["POR Code"]) {
              l["POR Code"] = e["POR Code"];
            } else {
              l["POR Code"] = "";
            }
            if (e["Departure from POR"]) {
              l["Departure from POR"] = e["Departure from POR"];
            } else {
              l["Departure from POR"] = "";
            }
            if (e["oname"]) {
              l["oname"] = e["oname"];
            } else {
              l["oname"] = "";
            }
            if (e["ocode"]) {
              l["ocode"] = e["ocode"];
            } else {
              l["ocode"] = "";
            }
            if (e["oetd"]) {
              l["oetd"] = formatDate(e["oetd"])
              // Utils.ddmmyyyy(
              //   new Date(new Date(e["oetd"]).toISOString().split("T")[0])
              // );
            } else {
              l["oetd"] = "";
            }
            if (e["r1code"]) {
              l["r1code"] = e["r1code"];
            } else {
              l["r1code"] = "";
            }
            if (e["v1name"]) {
              l["v1name"] = e["v1name"];
            } else {
              l["v1name"] = "";
            }
            if (e["s1voy"]) {
              l["s1voy"] = e["s1voy"];
            } else {
              l["s1voy"] = "";
            }
            if (e["ts1name"] && e["ts1name"] != e["dname"]) {
              l["ts1name"] = e["ts1name"];
            } else {
              l["ts1name"] = "";
            }
            if (e["ts1code"] && e["ts1name"] != e["dname"]) {
              l["ts1code"] = e["ts1code"];
            } else {
              l["ts1code"] = "";
            }
            if (e["r1code"] && e["ts1name"] != e["dname"]) {
              l["r1code"] = e["r1code"];
            } else {
              l["r1code"] = "";
            }
            if (e["ts1eta"] && e["ts1name"] != e["dname"]) {
              l["ts1eta"] = formatDate(e["ts1eta"])
              // Utils.ddmmyyyy(
              //   new Date(new Date(e["ts1eta"]).toISOString().split("T")[0])
              // );
            } else {
              l["ts1eta"] = "";
            }
            if (e["ts1etd"] && e["ts1name"] != e["dname"]) {
              l["ts1etd"] = formatDate(e["ts1etd"])
              // Utils.ddmmyyyy(
              //   new Date(new Date(e["ts1etd"]).toISOString().split("T")[0])
              // );
            } else {
              l["ts1etd"] = "";
            }
            if (e["r2code"] && e["ts1name"] != e["dname"]) {
              l["r2code"] = e["r2code"];
            } else {
              l["r2code"] = "";
            }
            if (e["v2name"] && e["ts1name"] != e["dname"]) {
              l["v2name"] = e["v2name"];
            } else {
              l["v2name"] = "";
            }
            if (e["s2voy"] && e["ts1name"] != e["dname"]) {
              l["s2voy"] = e["s2voy"];
            } else {
              l["s2voy"] = "";
            }
            if (
              e["ts2name"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["ts2name"] = e["ts2name"];
            } else {
              l["ts2name"] = "";
            }
            if (
              e["ts2code"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["ts2code"] = e["ts2code"];
            } else {
              l["ts2code"] = "";
            }
            if (
              e["ts2eta"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["ts2eta"] = formatDate(e["ts2eta"]);
            } else {
              l["ts2eta"] = "";
            }
            if (
              e["ts2etd"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["ts2etd"] = formatDate(e["ts2etd"]);
            } else {
              l["ts2etd"] = "";
            }
            if (
              e["r3code"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["r3code"] = e["r3code"];
            } else {
              l["r3code"] = "";
            }
            if (
              e["v3name"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["v3name"] = e["v3name"];
            } else {
              l["v3name"] = "";
            }
            if (
              e["s3voy"] &&
              e["r3code"] &&
              e["r3code"].length > 0 &&
              e["ts2name"] != e["dname"]
            ) {
              l["s3voy"] = e["s3voy"];
            } else {
              l["s3voy"] = "";
            }
            if (e["dname"]) {
              l["dname"] = e["dname"];
            } else {
              l["dname"] = "";
            }
            if (e["dcode"]) {
              l["dcode"] = e["dcode"];
            } else {
              l["dcode"] = "";
            }
            if (e["deta"]) {
              l["deta"] = formatDate(e["deta"])
              // Utils.ddmmyyyy(
              //   new Date(new Date(e["deta"]).toISOString().split("T")[0])
              // );
            } else {
              l["deta"] = "";
            }
            if (e["cscac"]) {
              l["cscac"] = e["cscac"];
            } else {
              l["cscac"] = "";
            }
            dataz.push(l);
          }
        }
      }
      exportToExcel(
        [
          {
            sheetName: "P44_FCL_Schedules",
            details: dataz,
          },
        ],
        "P44_FCL_Schedules",
        true
      );
      dtactionStore.modalVisible1 = false;
    }

    if (selectedstate == "LCL") {
     
      if (selectedValue == " ") {
        SetConsolidator(true);
      }
      console.log("s", portToPortSchedules.portOfReceipt);
      if (portToPortSchedules.portOfReceipt.length == 0) {
        SchedulesValidation.schdeuleValidMap["portOfReceipt"] = false;
        SetPOR(SchedulesValidation.schdeuleValidMap["portOfReceipt"]);
      } else {
        let dataz = [];
        if (selectedValue != " ") {
          for (let z = 0; z < data.data.length; z++) {
            let l = {};
            let e = { ...data.data[z]._id, ...data.data[z].sch };
            console.log("XS",e)
            console.log("CSDC",formatDate(e["etd"]))
            console.log("CSDC1",formatDate(e["deta"]))
         
            if (checkboxState.length == 0 ) {
              if (!e["POR (CFS / ICD / PORT)"])
                e["POR (CFS / ICD / PORT)"] = portToPortSchedules.portOfReceipt;

              if (e["POR (CFS / ICD / PORT)"] && e.checkboxEnabled == true) {
                l["POR (CFS / ICD / PORT)"] = e["POR (CFS / ICD / PORT)"];
              } else {
                l["POR (CFS / ICD / PORT)"] = "";
              }
              if (e["POL"]) {
                l["POL"] = e["POL"];
              } else {
                l["POL"] = "";
              }
              if (e["POD UNLOC"]) {
                l["POD UNLOC"] = e["POD UNLOC"];
              } else {
                l["POD UNLOC"] = "";
              }
              if (e["v1name"]) {
                l["VESSEL NAME"] = e["v1name"];
              } else {
                l["VESSEL NAME"] = "";
              }
              if (e["s1voy"]) {
                l["VOYAGE"] = e["s1voy"];
              } else {
                l["VOYAGE"] = "";
              }
              if (e["STUFFING CUT OFF DATE"]) {
                l["STUFFING CUT OFF DATE"] = e["STUFFING CUT OFF DATE"];
              } else {
                l["STUFFING CUT OFF DATE"] = "";
              }
              if (e["etd"]) {
                l["ETD (POL)"] =formatDate(e["oetd"])
                // Utils.ddmmyyyy(
                //   new Date(new Date(e["etd"]).toISOString().split("T")[0])
                // );
              } else {
                l["ETA (POL)"] = "";
              }
              if (e["deta"]||e["etapod"]) {
                l["ETA (POD)"] = e["deta"]?formatDate(e["deta"]):formatDate(e["etapod"])
              
              } else {
                l["ETA (POD)"] = "";
              }
              if (e.checkboxEnabled == true) {
                l["CO-LOADER"] = selectedValue;
              } else {
                l["CO-LOADER"] = "";
              }

              dataz.push(l);
            } else {
              if (checkboxState.length> 0 && e.checkboxEnabled == true) {
                console.log("dcTesting")
                if (!e["POR (CFS / ICD / PORT)"])
                  e["POR (CFS / ICD / PORT)"] =
                    portToPortSchedules.portOfReceipt;

                if (e["POR (CFS / ICD / PORT)"] && e.checkboxEnabled == true) {
                  l["POR (CFS / ICD / PORT)"] = e["POR (CFS / ICD / PORT)"];
                } else {
                  l["POR (CFS / ICD / PORT)"] = "";
                }
                if (e["POL"]) {
                  l["POL"] = e["POL"];
                } else {
                  l["POL"] = "";
                }
                if (e["POD UNLOC"]) {
                  l["POD UNLOC"] = e["POD UNLOC"];
                } else {
                  l["POD UNLOC"] = "";
                }
                if (e["v1name"]) {
                  l["VESSEL NAME"] = e["v1name"];
                } else {
                  l["VESSEL NAME"] = "";
                }
                if (e["s1voy"]) {
                  l["VOYAGE"] = e["s1voy"];
                } else {
                  l["VOYAGE"] = "";
                }
                if (e["STUFFING CUT OFF DATE"]) {
                  l["STUFFING CUT OFF DATE"] = e["STUFFING CUT OFF DATE"];
                } else {
                  l["STUFFING CUT OFF DATE"] = "";
                }
                if (e["etd"]) {
                  l["ETD (POL)"] = formatDate(e["etd"])
                } else {
                  l["ETA (POL)"] = "";
                }
                if (e["deta"]||e["etapod"]) {
                  l["ETA (POD)"] = e["deta"]?formatDate(e["deta"]):formatDate(e["etapod"])
                
                } else {
                  l["ETA (POD)"] = "";
                }
                if (e.checkboxEnabled == true) {
                  l["CO-LOADER"] = selectedValue;
                } else {
                  l["CO-LOADER"] = "";
                }

                dataz.push(l);
              }
            }
          }
          exportToExcel(
            [
              {
                sheetName: "P44_LCL_Schedules",
                details: dataz,
              },
            ],
            "P44_LCL_Schedules",
            true
          );
          dtactionStore.modalVisible1 = false;
        }
      }
    }
  };
  console.log("DSs", portToPortSchedules?.consolidatorList?.length);
  return (
    <div
      className={modalClass}
      // onClick={() => {
      //   dtactionStore.modalVisible1 = false;
      // } }
    >
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ borderRadius: "10px" }}
      >
        <section className="modal-card-body">
          <Cols>
            <Col8 style={{ color: "darkblue" }}>
              Do you want to download the LCL or FCL schedules :
            </Col8>
            <Col2>
              <div>
                {options.map((o, i) => (
                  <label key={i}>
                    {o + " "}
                    <input
                      type="checkbox"
                      checked={i === selected}
                      onChange={() => onChange(i)}
                    />
                    <br></br>
                  </label>
                ))}
              </div>
            </Col2>
          </Cols>

          <Cols>
            {options[selected] == "LCL" ? (
              <>
                <Col3 style={{ color: "darkblue" }}>Place of Receipt:</Col3>
                <Col3>
                  <AgUnloc2
                    {..._params("", "portOfReceipt")}
                    isValid={
                      SchedulesValidation.schdeuleValidMap["portOfReceipt"]
                    }
                    callback={(value, list) => {
                      SchedulesValidation.validate("portOfReceipt");
                      SchedulesValidation.schdeuleValidMap.portOfReceipt =
                        list.some((ele) => ele === value);
                      SetPOR(
                        SchedulesValidation.schdeuleValidMap.portOfReceipt
                      );
                    }}
                    validationMsg={SchedulesValidation.portOfReceiptmsg}
                  />
                </Col3>
              </>
            ) : (
              ""
            )}
          </Cols>
          <Cols>
            {options[selected] == "LCL" ? (
              <>
                <Col3 style={{ color: "darkblue" }}>Consolidator:</Col3>
                <Col3>
                  <select value={selectedValue} onChange={handleChange}>
                    <option value=" "> </option>
                    {selectedConsolidatorList.map((e) => (
                      <option value={e.consolidatorAbbrName}>
                        {e.consolidatorAbbrName}
                      </option>
                    ))}
                  </select>

                  {consolidator == true && (
                    <p class="help is-danger">
                      {" "}
                      {`* Please select a consolidator`}
                    </p>
                  )}
                </Col3>
              </>
            ) : (
              ""
            )}
          </Cols>
          <br></br>
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={async () => {
              download();
            }}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={() => (dtactionStore.modalVisible1 = false)}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}
