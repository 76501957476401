import { currencyList, chargesData } from "../StaticData"
import { TableEdit, TableSelect, TableLabel, AgInput, AgSelect } from "../components/AgComponents"
import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import {
    quoteDataRec, chargesStore1, cargoStore, configStore, entityStore, EntityV2Store
} from '../state/CommonState'
import ReactDOMServer from 'react-dom/server'
import Utils from "../util/Utils"
import { chargesValidationStore } from "../state/validation-stores"
console.log("chargesStore1--------->", chargesStore1)


export default function LCLChargesCompv2() {
    const [grandTotal, setGrandTotal] = useState(0.0)
    const [shipTotal, setShipTotal] = useState(0.0)
    const [conTotal, setConTotal] = useState(0.0)
    const [onaccount, setonaccount] = useState(['On Your Account'])
    const [volumeDiscount, setVolumeDiscount] = useState(0)
    const [showtotal, setShowtotal] = useState(false)

    useSnapshot(cargoStore)
    useSnapshot(quoteDataRec)
    useSnapshot(entityStore)
    console.log('ChargesComp rendering')
    const data = chargesData[quoteDataRec.shipmentType]
    let [space, setSpace] = useState('')

    let imChargesStore = useSnapshot(chargesStore1)
    let a = imChargesStore.newRec
    let b = imChargesStore.editRec

    /*useEffect(() => {
        console.log('SETTING VOLUME DISCOUNT TO 0')
        setVolumeDiscount(0)
    }, [chargesStore1.list])*/
    useEffect(() => {
        let list = chargesStore1.list
        for (let i = 0; i < list.length; i++) {
            if (!list[i].onaccount || !list[i].onaccount.length) list[i].onaccount = 'On Your Account'
        }
        if (!grandTotal) {
            calculateFinalTotal()
            let vd = chargesStore1.list.find(e => e.chargeDescription == 'Volume Discount')
            if (vd) {
                let of = chargesStore1.list.filter(e => e.chargeDescription == 'Ocean Freight')
                if (of && of.length) {
                    if (vd.total > 0) vd.total *= -1
                    let voldis = vd.total 
                    let e = of[0]
                    if (e.perCBM > 0 ||
                        e.perTon > 0 ||
                        e.minimum > 0 ||
                        e.maximum > 0 ||
                        e.perShipment > 0) {
                            e.perShipment = (e.total>0?e.total:e.total*-1) + voldis
                            if (e.perShipment > 0) e.perShipment *= -1
                            console.log('\n\n\nSetting pershipment of OF to', e.perShipment, '\n-----------\n')
                        }
                }
            }
        }
    }, [])
    useEffect(() => {
        let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }

            if (cecisorigin && sscope.endsWith('port')) setonaccount(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) setonaccount(['On Your Account', `Shipper's Account`])
            else setonaccount(['On Your Account'])
            chargesStore1.list.map((e) => {
                if(sscope.length!=0 && country.length!=0 && por.length!=0 && pod.length!=0){
                    if(e.onaccount!=undefined){
                        if (cecisorigin && sscope.endsWith('port')){
                            if(['On Your Account', `Consignee's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else if (cecisdestination && sscope.startsWith('port')){
                            if(['On Your Account', `Shipper's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else{
                            e.onaccount = "On Your Account"
                        }
                    }
                }
                return e
            })
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)
    }, [
        quoteDataRec.entity,quoteDataRec.entityId, quoteDataRec.portOfLoading,
        quoteDataRec.finalPlaceOfDelivery, quoteDataRec.shipmentScope
    ])
    const deleteRec = (id) => {
        if (id <= imChargesStore.initialLength) return
        const index = chargesStore1.list.findIndex(e => e.id === id)
        let records = chargesStore1.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        chargesStore1.list = records
        //console.log('AFTER DELETE=', JSON.stringify(chargesStore1.cargoList))
    }
    const Th2 = (props) => <th rowSpan="2" {...props} style={{ paddingTop: '1.5rem' }}>{props.children}</th>
    const Ts = ({ i, name, dataList, ...otherProps }) => <TableSelect
        record={chargesStore1.list[i]} name={name} dataList={dataList} isValid={chargesValidationStore.isValidCharge(name, i)} {...otherProps} />
    const Te = ({ i, name }) => {
        const e = chargesStore1.list[i]
        if ((name === 'chargeDescription' && i < chargesStore1.initialLength) || e.chargeDescription == 'Volume Discount'){
            return <TableLabel record={chargesStore1.list[i]} name={name} />
        }else{
            return <TableEdit record={chargesStore1.list[i]} name={name} isValid={chargesValidationStore.isValidCharge(name, i)}/>
        }
        
    }
    const Tn = ({ i, name, ...otherProps }) => {
        const e = chargesStore1.list[i]
        if ((e.disabled && e.disabled.indexOf(name) >= 0) || e.chargeDescription == 'Volume Discount')
            return (
                <input disabled='true' style={{ width: '3rem', backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEdit record={chargesStore1.list[i]} name={name} type='number' isValid={chargesValidationStore.isValidCharge(name, i)} {...otherProps} />
        )
    }
    const Tsn = ({ name, dataList }) => <TableSelect
        record={chargesStore1.newRec} name={name} dataList={dataList} />
    const Ten = ({ name }) => <TableEdit record={chargesStore1.newRec} name={name} />
    const Tnn = ({ name }) => <TableEdit record={chargesStore1.newRec} name={name} type='numeric' />
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0

    let finalTotal = 0.0
    const calculateFinalTotal = () => {
        let tot = 0
        let shiptot = 0
        let contot = 0
        chargesStore1.list.map((e) => {
            let erate = configStore.getExchangeRate(e.currency)
            if (!erate || erate === 0) erate = 1.0
            if (e.chargeDescription == 'Volume Discount') {
                if (e.total > 0) e.total *= -1
                setVolumeDiscount(e.total * erate)
            } else {
                if (e.onaccount && e.onaccount.startsWith('Cons')) contot += e.total * erate
                else if (e.onaccount && e.onaccount.startsWith('Ship')) shiptot += e.total * erate
                else {
                    tot += e.total * erate
                }
            }
        })
        setGrandTotal(tot)
        setShipTotal(shiptot)
        setConTotal(contot)
        return finalTotal;
    }

    return (
        <>
            <hr />
            <div className="table-container" onClick={(e) => e.stopPropagation()}>
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {/* <th colSpan='2'>
                                <button className="button is-link is-small is-rounded" onClick={() => {
                                    setVolumeDiscount(0)
                                    cargoStore.calculateCargoTotals()
                                    let voldis = chargesStore1.calculateLCLChargesTotals()
                                    calculateFinalTotal()
                                    setSpace(space === '' ? ' ' : '')
                                    setShowtotal(true)
                                }}>Click to Re-Calculate Charges</button>
                            </th> */}
                            <th colSpan='14'>
                                <h3 className="is-size-5 has-text-centered">Charges{space}</h3>
                            </th>
                        </tr>
                        {ifNotFCL() ?
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colSpan='4' className="has-text-centered" style={{ backgroundColor: '#efefef' }}>Per Unit</th>
                                <th></th>
                                <th ></th>
                                <th></th>
                            </tr> : <></>
                        }
                        <tr>
                            <th style={{ width: '5rem' }}>ID</th>
                            <th style={{ width: '20rem' }}>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            <th>Per CBM</th>
                            <th>Per Ton</th>
                            <th>Minimum</th>
                            <th>Maximum</th>
                            <th>Per shipment</th>   
                            <th style={{ textAlign: 'right' }}>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            chargesStore1.list
                                .map((e, i) => {
                                    {
                                        if (e.chargeDescription == 'Volume Discount')
                                        return <></>
                                        else 
                                        return <tr key={e.id}>
                                            <td >{e.id}</td>
                                            <td><Te i={i} name='chargeDescription' /></td>
                                            <td><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                            <td><Ts i={i} name='currency' dataList={configStore.getCurrencyList()} /></td>
                                            <td><Tn i={i} name='perCBM' /></td>
                                            <td><Tn i={i} name='perTon' /></td>
                                            <td><Tn i={i} name='minimum' /></td>
                                            <td><Tn i={i} name='maximum' /></td>
                                            <td><Tn i={i} name='perShipment' /></td>
                                            <td style={{ textAlign: 'right' }}>{
                                                (e.total != 0 && e.currency != '') ? Utils.N(e.total, e.currency) : ''
                                            }</td>
                                            <td>

                                                <div className="field has-addons">
                                                    {
                                                        (i >= chargesStore1.initialLength) && (<p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>)}
                                                    {
                                                        i == chargesStore1.list.length - 1 && (<p className="control">
                                                            <button className="button is-success is-inverted"
                                                                onClick={() => {
                                                                    let obj = chargesStore1.newRec
                                                                    obj = { ...obj }
                                                                    chargesStore1.list.push({ ...obj, id: chargesStore1.list.length + 1 })
                                                                    //chargesStore1.calculateLCLChargesTotals()
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon is-small">
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                            </button>
                                                        </p>)
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                }
                                )
                        }

                        {!showtotal?<tr><td colSpan={11} style={{ textAlign: 'right' }}>
                        <button className="button is-info is-inverted"
                                                        
                                                        onClick={() => {
                                                            setVolumeDiscount(0)
                                    cargoStore.calculateCargoTotals()
                                    let voldis = chargesStore1.calculateLCLChargesTotals()
                                    calculateFinalTotal()
                                    setSpace(space === '' ? ' ' : '')
                                    setShowtotal(true)
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-calculator"></i>
                                                        </span>
                                                    </button>
                            </td></tr>:""}

                        {
                            showtotal?<>
                            <tr>
                            <td colSpan={8}></td>
                            <td colSpan={2} style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal)}</strong></td>
                            <td style={{ textAlign: 'center' }} rowSpan={(conTotal && shipTotal && volumeDiscount)?6:(volumeDiscount && (conTotal || shipTotal))?5:(volumeDiscount && !conTotal && !shipTotal)?4:(conTotal && shipTotal)?4:(conTotal || shipTotal)?3:2}>
                        <button className="button is-info is-inverted"
                                                        
                                                        onClick={() => {
                                                            setVolumeDiscount(0)
                                    cargoStore.calculateCargoTotals()
                                    let voldis = chargesStore1.calculateLCLChargesTotals()
                                    calculateFinalTotal()
                                    setSpace(space === '' ? ' ' : '')
                                    setShowtotal(true)
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-calculator"></i>
                                                        </span>
                                                    </button>
                            </td>
                        </tr>
                        {
                            volumeDiscount != 0 &&
                            <>
                                <tr>
                                    <td colSpan={8} style={{ textAlign: 'right' }}>Volume Discount :</td>
                                    <td colSpan={2} style={{ textAlign: 'right' }}><strong>{Utils.N(volumeDiscount)}</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={8} style={{ textAlign: 'right' }}>TOTAL :</td>
                                    <td colSpan={2} style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal + volumeDiscount)}</strong></td>
                                </tr>
                            </>
                        }
                        {
                            shipTotal > 0 &&
                            <tr>
                                <td colSpan={9} style={{ textAlign: 'right' }}>Total of On Shipper's Account : </td>
                                <td><strong>{Utils.N(shipTotal)}</strong></td>
                            </tr>
                        }
                        {
                            conTotal > 0 &&
                            <tr>
                                <td colSpan={9} style={{ textAlign: 'right' }}>Total of On Consignee's Account : </td>
                                <td><strong>{Utils.N(conTotal)}</strong></td>
                            </tr>
                        }
                            </>:""
                        }

                        
                    </tbody>
                </table>
            </div>
        </>
    )
}
