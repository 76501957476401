import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const CustomStyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root':{
       fontSize:'13px'
    },
    '& .MuiInputBase-root.Mui-focused': {
        '& fieldset': {
            borderColor: '#2B6ED4',
        },
    },
    '& .MuiInputBase-root.Mui-error': {
        '& fieldset': {
            borderColor: 'red',
        },
    },
}));
