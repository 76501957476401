import { useSnapshot } from "valtio"
import Utils from "../util/Utils"
import { taskStore } from "./task-store"


export default function TaskStatusList(props) {
    useSnapshot(taskStore)
    let task = taskStore.current
    if(task.updatelist==undefined){
        task.updatelist = []
    }
    return (
        <div className="box">
        <tr>
            <th>Updated On <span>&nbsp;&nbsp;</span></th>
            <th>Update By <span>&nbsp;&nbsp;</span></th>
            <th>Behalf</th>  
        </tr>
        <tbody className="contractlist" style={{ fontSize: '0.8rem' }}>
        {
            task.updatelist.map(e => {
                return (
                    <tr>
            <td>{Utils.formatToDateTime(Number(e.updatedOn))}&nbsp;&nbsp;</td>
            <td>{e.updatedBy}&nbsp;&nbsp;</td>
            {(e.status)?<td>{e.status.toUpperCase()}</td>:<td></td>}
            
        </tr>
                )
            })
        }
        
        </tbody>
    </div>
    )
}