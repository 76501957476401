import { Box, IconButton, Typography } from "@mui/material";
import Warehouse from "../assets/images/warehouse.svg";
import anchor from "../assets/images/anchor.svg";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import leftarrow from "../assets/images/leftarrow.svg";
import rightarrow from "../assets/images/rightarrow.svg";
import wave from "../assets/images/wave.svg";
import { HorizontalDashedLine } from "./HorizontalDashedLine";
import airline from "../assets/images/air-line.svg";
import { AirLineSvg } from "./airlinesvg";
import shipment from "../assets/images/shipmenticon.svg";
import plane from "../assets/images/plane.svg";
import airplane from "../assets/images/air-visibility.svg";
import Utils from "../util/Utils";
import truck from "../assets/images/truck.svg";
import airpng from "../assets/images/airpng.png";
import { viewBookingStore } from "../state/CommonState";



function getPanels(parent, child) {
    let panels = [];
    let totalWidth = child;
    let width = parent;
    console.log(totalWidth, width);
    let maxPanels = Math.ceil(totalWidth / width);
    let i = 0;
    let previousValues = 0;
    while (i < maxPanels) {
        let rem = 0;
        if (i === maxPanels - 1) {
            rem = totalWidth % width;
            if (rem === 0) {
                rem = width;
            }
        } else {
            rem = width;
        }
        previousValues += rem;
        rem = previousValues;
        panels.push(rem);
        i++;
    }
    console.log("panels ", panels);
    return panels;
}

function Arch2({milestone}){
    const [show, setShow] = useState(false);
    useLayoutEffect(() => {
        const element = document.getElementById(`${milestone.milestone+viewBookingStore.current._id}`);
        console.log("the element ", element.offsetWidth);
        dimensions.current = {
            pathHeight: 38,
            pathWidth: element.offsetWidth,
            curveAt: element.offsetWidth / 2,
        };
        pathDimensions.current = `M 0,${dimensions.current.pathHeight} Q ${dimensions.current.curveAt},0 ${dimensions.current.pathWidth},${dimensions.current.pathHeight}`;
       
        setShow(true);
    }, []);
    const dimensions = useRef();
    const pathDimensions = useRef();
    return show ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${dimensions.current?.pathWidth} 38`}
            preserveAspectRatio="none"
            style={{ transform: "translateY(-40%)",backgroundSize:"100% 100%" }}
        >
            <path
                d={pathDimensions.current}
                fill="none"
                stroke="#2A6ED4"
                stroke-width="2"
            />
        </svg>
    ) : (
        <></>
    );
}

function Arch({milestone}) {
    const [show, setShow] = useState(false);
   
    useEffect(() => {
        const element = document.getElementById(`${milestone.milestone+milestone.bookingId}`);
        console.log("the element ", element);
        dimensions.current = {
            pathHeight:35,
            pathWidth: element.offsetWidth,
            curveAt: element.offsetWidth / 2,
        };
        pathDimensions.current = `M 0,${dimensions.current.pathHeight} Q ${dimensions.current.curveAt},0 ${dimensions.current.pathWidth},${dimensions.current.pathHeight}`;
        console.log("dimensions ", pathDimensions.current);
        setShow(true);
    }, []);

    useEffect(()=>{ 
          console.log("the show is ",show)
    },[show])
   
    const dimensions = useRef();
    const pathDimensions = useRef();
    return show ? (
        // <Box sx={{width:"100%",height:"50px",backgroundColor:"red"}}></Box>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${dimensions.current?.pathWidth} 41`}
            width="100%"
            height="100%"
            style={{ transform: "translateY(-40%)" }}
        >
            <path
                d={pathDimensions.current}
                fill="none"
                stroke="#2A6ED4"
                stroke-width="2"
            />
        </svg>
    ) 
    : (
        <></>
    );
}

function AirPng(){
    return <img src={airpng} style={{width:"100%",height:"35px"}} />
}


function getProgressComponent(milestone) {
    if (!milestone.isPathCompleted) {
        if (milestone.milestoneCompleted) {
            return <Box sx={{ height: "3px", display: "flex", backgroundColor: "#2C8A57", width: "100%" }}></Box>
        } else if (milestone.milestoneAdvanced) {
            if(milestone.isPortMilestone){
                return milestone.shipmentType.toLowerCase() === "air" ?
                // <Box sx={{height:"18px",width:"100%"}}>
<Arch milestone={milestone} />
                // </Box>
                 : <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Box sx={{ height: "5px", display: "flex", flex: 1, width: "100%", backgroundImage: `url(${wave})`, backgroundRepeat: "repeat-x" }}></Box>
            </Box>
            }else{
                return <Box sx={{ height: "3px", display: "flex", backgroundColor: "#2B6ED4", width: "100%" }}></Box>
            }
        } else {
            return <HorizontalDashedLine width="100%" dashArray="5,5" stroke="#555555" strokeWidth="6" />
        }
    }
}

function MilestoneAvatar({ milestone }) {

    const isAir = milestone.shipmentType.toLowerCase() === "air";
    return <Box sx={{ display: "flex", flexDirection: "column", width: "100%", paddingTop: "15px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
            <Box sx={{ borderRadius: "50%", minHeight: "35px", minWidth: "35px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: milestone.milestoneCompleted || milestone.milestoneAdvanced ? "#2C8A57" : "white", border: milestone.milestoneCompleted || milestone.milestoneAchieved || milestone.milestoneAdvanced ? "2px solid #2C8A57" :"2px solid #555555" }}>
                <img style={{ filter:  milestone.milestoneAdvanced || milestone.milestoneCompleted ? "brightness(0) invert(1)" : milestone.milestoneAchieved ? 'invert(43%) sepia(44%) saturate(578%) hue-rotate(95deg) brightness(95%) contrast(93%)':""}} src={milestone.dynamicIcon ? isAir ? airplane : anchor : Warehouse} />
            </Box>
            <Box id={`${milestone.milestone+viewBookingStore.current._id}`} sx={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", height: "100%", justifyContent: "center", position: "relative" }}>
                { !milestone.milestoneCompleted && milestone.milestoneAdvanced && !milestone.isPathCompleted && <Box sx={{ display: "flex",position: "absolute", top: milestone.isPortMilestone? isAir ? "-18px" : "-3px" : "0px" }}><img src={  milestone.isPortMilestone ?  milestone.shipmentType.toLowerCase() === "air" ? plane : shipment : truck} /></Box>}
                {getProgressComponent(milestone)}
            </Box>
        </Box>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: 600, color: "black", fontSize: "12px !important", textTransform: 'uppercase', padding: 0, height: "14px",overflow:"hidden",textOverflow:"ellipsis",maxWidth:"213px"}}>{milestone.location}</Typography>
    </Box>
}

export function VisibilityTracker({ booking, isViewFull = false,mode,container }) {
    const panel = useRef(0);
    const leftArrow = useRef(0);
    const arrowClikcked = useRef(null);
    const panelMoved = useRef([]);
    const scrollRef = useRef(null);
    const childRef = useRef(null);
    const [showIcons, setShowIcon] = useState(false);
    const [recentlyAchievedMilestone,setRecentlyAchievedMilestone] = useState(0);


    useEffect(()=>{
        console.log("the booking is hkjfv ",booking)
      if(!isViewFull){
        
        for(let i=0;i<booking.length;i++){
            if(!booking[i+1]?.milestoneAchieved){
                setRecentlyAchievedMilestone(i)
                return;
            }
        }
      }
     
    },[])

    useEffect(()=>{
       if(recentlyAchievedMilestone>0){
        const recentAChiveedMilesotneIndex = recentlyAchievedMilestone === 1 ? 0 : recentlyAchievedMilestone-1;
        const recentMilestone = booking[recentAChiveedMilesotneIndex]
        //  const elementWidthStart = document.getElementById(recentMilestone.milestone+recentMilestone.bookingId);
         const startingPoint = 213*recentAChiveedMilesotneIndex;
        //  console.log("the starting point is ", startingPoint-80," ",recentMilestone.bookingId);
         panelMoved.current = [];
         panelMoved.current.push(startingPoint);
         if (scrollRef.current) {
            scrollRef.current.scrollTo({
                left: startingPoint,
                behavior: 'smooth'
            })
        }
       }
    },[recentlyAchievedMilestone])

    
  

    return <Box onMouseLeave={() => {
        setShowIcon(false);
    }} onMouseEnter={() => {
        if (scrollRef.current.offsetWidth < childRef.current.scrollWidth) {
            setShowIcon(true);
        }
    }} sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%", width: "100%", gap: 3, justifyContent: "center", cursor: isViewFull ? "default" : "pointer" }}>
        {!isViewFull && <IconButton sx={{ visibility: showIcons ? "visible" : "hidden" }} onClick={() => {
            panel.current = 0;
            if(panelMoved.current.length ===0 && scrollRef.current){
               panelMoved.current = [scrollRef.current.offsetWidth]
            }
            let panels = panelMoved.current;


           
            if (scrollRef.current) {
                scrollRef.current.scrollBy({
                    left: -panels[leftArrow.current] ,
                    behavior: 'smooth'
                })
            }

            leftArrow.current += 1;
            if (leftArrow.current >= panels.length) {
                panelMoved.current = [];
                leftArrow.current = 0
            }
        }} ><img src={leftarrow} /></IconButton>}
        <Box ref={scrollRef} sx={{
            overflow: "auto", height: "100%", width: "100%", display: "flex", flexDirection: "row", "&::-webkit-scrollbar": {
                display: "none"
            }
        }}>
           
            <Box ref={childRef} sx={{ display: "flex", position:"relative",flexDirection: "row", alignItems: "center", height: "100%", width: "100%" }}>
           { container && <Box sx={{position:"absolute",top:-3}}>{container}</Box>}
                {booking.map((milestone) => <Box id={milestone.milestone+milestone.bookingId} sx={{
                    display: "flex", minWidth: milestone.isPathCompleted ? "max-content" : "213px",
                    flex: milestone.isPathCompleted ? 0 : 1
                    , height: "100%", flexDirection: "column", justifyContent: "end", alignItems: "start",
                }}>
                   {/* {containerName && <Box>{containerName}</Box>} */}
                   
                  { 
                //   milestone.milestoneAdvanced && 
                  milestone.isPortMilestone && 
                  !milestone.isPathCompleted && 
                  <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column", width: "100%"}}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} >
                            { mode.toLowerCase() === "air" ? <Typography sx={{ textAlign: "center", fontFamily: "Figtree", fontSize: "12px" }}><span style={{fontFamily: "Figtree", fontSize: "12px",fontWeight:"bold",color:"black"}}>{milestone?.flightNumber}</span> </Typography>  : milestone.vesselName?.length>0 && milestone.vesselNumber?.length>0  ?  
                             <Typography sx={{ textAlign: "center", fontFamily: "Figtree", fontSize: "12px" }}>
                                {/* <span style={{fontFamily: "Figtree", fontSize: "12px",fontWeight:"bold",color:"black"}}>{milestone?.carrier?.split(" ")[0]}</span> 
                                 */}
                                <span style={{color:"#2A6ED4",fontWeight:"bold"}}>{milestone?.vesselName} </span>
                                - 
                                {milestone?.vesselNumber}</Typography> 
                                : <></>
                                // <Typography sx={{ textAlign: "center", fontFamily: "Figtree", fontSize: "12px" }}><span style={{fontFamily: "Figtree", fontSize: "12px",fontWeight:"bold",color:"black"}}>{milestone?.carrier?.split(" ")[0]}</span></Typography>
                             }
                        </Box>
                        
                    </Box>}
                                        <MilestoneAvatar milestone={milestone} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", minWidth: "max-content", paddingTop: "2px" }}>
                        <Box sx={{minHeight:"44px",display:"flex",flexDirection:"column"}}>
                        {milestone.estimateDetails.map((estDetail, index) => {
                            let showActiveColor = (index===0 && milestone.milestoneAchieved ) || (index===1 && milestone.milestoneAdvanced)
                            let isCountAvailable = estDetail.count  && estDetail.count.split("/")[0]!=="0" && milestone.containerIndex === -1;
                        
                            return <Box sx={{display:"flex",gap:0.4,flexDirection:"row"}}>{estDetail.isLabel ? <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", fontWeight: 400 }}>{estDetail.label}</Typography> : <Typography fontWeight={showActiveColor?"bold":"normal"} sx={{ fontFamily: "Figtree", fontSize: "14px",color:showActiveColor? "#2A6ED4" : "#555555" }}>{ estDetail.value && estDetail.value.length>0 ? index === 0 ? milestone.milestoneAchieved ? estDetail.activeLabel : estDetail.label : milestone.milestoneAdvanced ? estDetail.activeLabel : estDetail.label : "" } {estDetail.value && estDetail.value.length>0 ? ":" : "" } {Utils.formatDateBooking(estDetail.value, false)}</Typography>}{ isCountAvailable && <Typography sx={{fontSize:"14px",fontFamily:"Figtree",fontWeight:"bold",color:"black",marginLeft:"5px"}}>{`( ${estDetail.count} )`}</Typography>}</Box>
                        })}
                        </Box>
                        {/* <Typography sx={{fontFamily:"Figtree",fontSize:"14px"}}>Cargo Received</Typography>
                <Typography sx={{fontFamily:"Figtree",fontSize:"14px"}}>Departed: 19 May’23</Typography> */}
                    </Box>
                </Box>)}
            </Box>

        </Box>
        {!isViewFull && <IconButton sx={{ visibility: showIcons ? "visible" : "hidden" }} onClick={() => {
            leftArrow.current = 0;
            arrowClikcked.current = "-";
            let panels = getPanels(
                scrollRef.current.offsetWidth,
                childRef.current.scrollWidth
            );
            panelMoved.current.push(panels[panel.current]);
            if (scrollRef.current) {
                scrollRef.current.scrollTo({
                    left: panels[panel.current],
                    behavior: 'smooth'
                })
            }
            panel.current += 1;
            if (panel.current === panels.length) {
                panel.current = 0;
            }
        }}><img src={rightarrow} /></IconButton>}
    </Box>
}