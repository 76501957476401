import React, { useState, useRef } from 'react';

const OtpInput = ({ length,callback,errorsmsg }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));
  const inputRefs = useRef([]);

  // Function to handle input change
  const handleChange = (index, value) => {
    value = value?.slice(0,1)
    // value = value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1')
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field automatically
    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
    callback(newOtp.join(''))
  };

  // Function to handle paste event
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain').trim();
    if (pastedData.length === length,/^\d{0,6}$/.test(pastedData)) {
      // Update OTP with pasted value
      setOtp(pastedData.split('').slice(0, length));

      // Move focus to next input
      if (inputRefs.current[length - 1]) {
        inputRefs.current[length - 1].focus();
      }
      callback(pastedData.split('').slice(0, length).join(''))
    }
  };

  // Function to handle keydown event for navigation
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && inputRefs.current[index - 1]) {
      // Move to previous input on backspace if current input is empty
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
    <div style={{margin:"1rem auto",display:'flex',gap:'.8rem'}}>
      {otp.map((value, index) => (
        <input
          key={index}
          type="number"
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E') {
              e.preventDefault();
            } else {
              handleKeyDown(e, index);
            }
          }}
          onPaste={handlePaste}
          ref={(ref) => (inputRefs.current[index] = ref)}
          style={{ width: '44px',borderRadius:'4px',height:'44px',outline:'none',textAlign:'center',fontSize:'20px',fontWeight:900,border:errorsmsg?"1px solid #F25B5B":""}}
        />
      ))}

    </div>
    
            <p className='help is-input-danger-message' style={{fontSize:'11.4px',margin:".5rem auto"}}>{
          
          errorsmsg?errorsmsg:<span>&nbsp;</span>
           }</p></>
  );
};

export default OtpInput;
