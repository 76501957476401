import React, { useEffect, useState } from 'react'
import {  Controller, useForm } from "react-hook-form";
import Select from 'react-select'
import { City, Country, State } from "country-state-city";
function CountrySelect({callback,errorsmsg,value}) {
    const EntityForm = useForm({
        defaultValues:{
            city:"",
            state:"",
            country:"",
        }
    })
    const DefaultIndiaValue = {
        "name": "India",
        "isoCode": "IN",
        "flag": "🇮🇳",
        "phonecode": "91",
        "currency": "INR",
        "latitude": "20.00000000",
        "longitude": "77.00000000",
        "timezones": [
            {
                "zoneName": "Asia/Kolkata",
                "gmtOffset": 19800,
                "gmtOffsetName": "UTC+05:30",
                "abbreviation": "IST",
                "tzName": "Indian Standard Time"
            }
        ]
    }
    const [Countries, setCountries] = useState([]);
    const [Value2, setValue2] = useState([])
    const filterCountries = () => {
        const array = Country.getAllCountries().filter(
          (x, i) => State.getStatesOfCountry(x.isoCode).length !== 0
        );
        console.log(array);
        setValue2(array.filter((r)=> r.name === value))
        setCountries(array);
      };
      useEffect(() => {
        filterCountries()
        // StatesforBranch()
      }, [value])
    const {register,handleSubmit,clearErrors,setValue,setError,watch,formState:{errors},getValues,reset,control} = EntityForm;
  return (
    <>
              <div className={"input_wrapeer m0 wd_30"}>
        <p className={"label_styles"} >
        Country*
                  </p>
        <div className={""} >
            <Controller
            name={"country"}
            control={control}
            rules={{ required: "Please select your Country" }}
            defaultValue='India'
            render={({ field,formState:{errors}  }) => (
              <>
              <Select
                {...field}
                className={`selectBox_styles ${errors?.country?"border_red":""}`}
                classNamePrefix="select"
                // isClearable={true}
                isSearchable={true}
                options={Countries}
                placeholder={getValues("country")}
                // defaultValue={{ label: "name", value: "India" }}
                getOptionLabel={(options) => options['name']}
                getOptionValue={(options) => options['flag']}
                value={Value2}
                onChange={(e)=>
                 {   setValue("country",e)
                    callback(e)}
                }
              />
                  <p className="help is-input-danger-message">{errorsmsg?errorsmsg:<span>&nbsp;</span>}</p> 
              </>
            )}
            
          />
        </div>
          </div>
    </>
  )
}

// export default CountrySelect



function StateSelect({countryValue, callback,value,errorsmsg, disabled,label}) {
    console.log("countryValue",countryValue);
    const EntityForm = useForm({
        defaultValues:{
            city:"",
            state: value,
            country:"",
        }
    })
    const { setValue } = EntityForm;
      const [States, setStates] = useState([])
      const [Value2, setValue2] = useState([])
      useEffect(() => {
        const CountryList = Country.getAllCountries().filter(
          (r) => r.name === (countryValue === "Hong Kong" ? "Hong Kong S.A.R." : countryValue)
      );
        console.log("CountryList",CountryList);
        const Statess = State.getStatesOfCountry(CountryList[0]?.isoCode);
        setValue2(Statess.filter((r)=> r.name === value))
        console.log("Statess",Statess,value);
        setStates(Statess);
    setValue("state", value);
  }, [countryValue, value, setValue]);

  const { register, handleSubmit, clearErrors, setError, watch, formState: { errors }, getValues, reset, control } = EntityForm;
    
  return (
<div className={label?"input_wrapeer":"input_wrapeer m0 wd_30"}>
    {label?'':<p className={"label_styles"} >
    State*
              </p>}
    <div className={""} >
        <Controller
        name={"state"}
        control={control}
        // rules={{ required: "Please select your State" }}
        render={({ field,formState:{errors}  }) => (
          <>
          <Select
            {...field}
            className={`selectBox_styles ${errors?.state?"border_red":""}`}
            classNamePrefix="select"
            // isClearable={true}
            isSearchable={true}
            options={States}
            getOptionLabel={(options) => options['name']}
            getOptionValue={(options) => options['name']}
            value={Value2}
            isDisabled={disabled}
            onChange={(e)=> { 
            setValue("state",e)
            setValue2(e)
            callback(e)
        }}
          />
             <p className="help is-input-danger-message">{errorsmsg?errorsmsg:<span>&nbsp;</span>}</p> 
          </>
        )}
        
      />
    </div>
    </div>
  )
}



function CitySelect({callback,stateValue,value,errorsmsg, disabled,label}) {
  console.log({value,stateValue});
  const [CityList, setCityList] = useState([])
  const [Value2, setValue2] = useState([])
    const EntityForm = useForm({
        defaultValues:{
            city:"",
            state: stateValue,
            country:"",
        }
    })
    useEffect(() => {
      const CurrentState = State.getAllStates().filter(
        (r) => r.name === stateValue
      );
      console.log(CurrentState);
    const cityList =   City?.getCitiesOfState(CurrentState[0]?.countryCode,CurrentState[0]?.isoCode)
    setValue2(cityList.filter((r)=> r.name === value))
    setCityList(cityList)
    }, [stateValue,value])
    
    const {register,handleSubmit,clearErrors,setValue,setError,watch,formState:{errors},getValues,reset,control} = EntityForm;
  return (
    <>
    <div className={label?"input_wrapeer":"input_wrapeer m0 wd_30"}>
    {label?'':<p className={"label_styles"} >
    City*
              </p>}
    <div className={""} >
        <Controller
        name={"city"}
        control={control}
        // rules={{ required: "Please select your City" }}
        render={({ field,formState:{errors}  }) => (
          <>
          <Select
            {...field}
            className={`selectBox_styles ${errors?.city?"border_red":""}`}
            classNamePrefix="select"
            // isClearable={true}
            isSearchable={true}
            options={CityList}
            isDisabled={disabled}
            getOptionLabel={(options) => options['name']}
            getOptionValue={(options) => options['name']}
            value={Value2}
            onChange={(e)=> {
                setValue("city",e)
                callback(e)
            }}
          />
        <p className="help is-input-danger-message">{errorsmsg?errorsmsg:<span>&nbsp;</span>}</p> 
          </>
        )}
        
      />
    </div>
          </div>
    </>
  )
}

export default Country

export  {StateSelect,CountrySelect,CitySelect}