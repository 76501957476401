import { useSnapshot } from "valtio"
import {
    quoteDataRec, airChargesStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { TransportTable1 } from "../enquiry/LCLChargeClientView"

export default function AirChargesView2 (props) {

    let imChargesStore = useSnapshot(airChargesStore)
    console.log('Inside ChargesView render=', imChargesStore.list.length)
    console.log('Full list = ', JSON.stringify(airChargesStore.list))
    const Tn = ({ i, name, list }) => {
        const e = list[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <div style={{color:'#dddddd', backgroundColor: '#dddddd', border: 'none', width: '5rem' }} >.</div>
            )
        else {
            console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num)?0.0:Number(num)
            return num>0?<span>{Utils.N(num, e.currency)}</span>:<></>
        }
    }
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0
    const displayRec = (e) => {
        const perKg = parseFloat(e.perKg)
        const perAWB = parseFloat(e.perAWB)
        const total = parseFloat(e.total)
        console.log(perKg, perAWB, total)
        if (perKg != 0 || perAWB != 0 || total != 0) return true
        return false
    }
    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < airChargesStore.list.length; i++) {
            let e = airChargesStore.list[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        return <>
        {
            (listo.length>0)?<AirChargesView2 data={listo} chargeType='On Your Account' />:""
        }
        {
            (listc.length>0)?<AirChargesView2 data={listc} chargeType='On Consignee Account' />:""
        }
        {
            (lists.length>0)?<AirChargesView2 data={lists} chargeType='On Shipper Account' />:""
        }
        </>
    }
    let counter = 1
    let finalTotal = 0.0
    props.data.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
    })
    let checkValue = false;
    props.data.forEach(element => {
        if((+element.perKg) !== 0 || (+element.minimum) !== 0 || (+element.perAWB) !== 0 || (+element.total) !== 0){
            checkValue = true;
        }
    });
    return (
        <>
            {
                checkValue ? 
                <>
                <hr />
                <div className="table-container">
                    <table className="table is-bordered is-striped is-narrow is-hoverable ">
                        <thead>
                            <tr>
                                <th colSpan='8'>
                                    <h3 className="is-size-5 has-text-centered">{props.chargeType}</h3>
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colSpan='2' className="has-text-centered">Per Unit</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>ID</th>
                                <th style={{width: '20rem'}}>Charge Description</th>
                                <th>Currency</th>
                                <th style={{textAlign:'center'}}>Per Kg</th>
                                <th style={{textAlign:'center'}}>Minimum</th>
                                <th style={{textAlign:'center'}}>Per AWB</th>
                                <th style={{textAlign:'right'}}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.data.map((e, i) => {
                                    {
                                        return (displayRec(e)) ? 
                                        <tr key={e.id}>
                                            <td >{counter++}</td>
                                            <td>{
                                                (e.transportRec) ?
                                                    <div className="transporttooltip">{e.chargeDescription}
                                                        <span className="transporttooltiptext"><TransportTable1 data={e.transportRec} /> </span>
                                                    </div>
                                                    :
                                                    e.chargeDescription
                                            }</td>
                                            <td>{e.currency}</td>
                                            <td  style={{textAlign:'center'}}><Tn i={i} name='perKg' list={props.data} /></td>
                                            <td style={{textAlign:'center'}}><Tn i={i} name='minimum' list={props.data} /></td>
                                            <td style={{textAlign:'center'}}><Tn i={i} name='perAWB' list={props.data}/></td>
                                            <td style={{textAlign:'right'}}>{Utils.N(e.total, e.currency)}</td>
                                        </tr> : <></>
                                    }
                                }
                                )
                            }
                            <tr>
                                <td colSpan={6}></td>
                                <td style={{textAlign: 'right'}}><strong>{Utils.N(finalTotal)}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan={7} style={{textAlign: 'right'}}>Taxes & Duties additional, as applicable</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                </> : <></>
            }
        </>
    )
}
