import { useSnapshot } from "valtio"
import { loader } from "../util/loader"
import { useEffect, useState } from "react"
import Api from "../state/Api"
import { DtMapVisibilty } from "./dtMapVisibilty"
import { DtUpdatePhoneNumber } from "./dtUpdatePhoneNumber"
import { loginStore } from "../state/CommonState"
import { Button } from "@mui/material"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import Taskfiletable from "./taskFileTable"

export function DtVisibity(props) {
    useSnapshot(loader)
    let e1 = props.dsrRec
    const [input, setInput] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [custReference, setCustReference] = useState('')
    const [showDocuments, setShowDocuments] = useState('')

    useEffect(() => {
        setCustReference(e1['customerReference']);
        setInput(true)
        setShowDocuments(false)
    }, [e1]);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openUpdateModal = () => {
        setIsUpdateModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
    };
    return (
        <div className="box is-fullwidth" style={{ border: '1px solid lightgrey' }}>
            <div className="columns" style={{ fontSize: '0.8rem', color: '#000055' }}>
                <div className="column is-6" style={{ textAlign: 'left' }}>
                    {/* {e1.type === 'Domestic Transport' ?  */}
                    <div>Customer Reference# : <input type="text" disabled={input} value={custReference}  onChange={(async (e) => {
                        setCustReference(e.target.value);
                    })} /> 
                        {input ? <i class="fa fa-pencil" style={{paddingLeft:'10px'}} onClick={() => {
                            setInput(!input)
                        }}></i> 
                        : ''}
                        {!input && custReference.length > 0 ? <i class="fa fa-save" style={{paddingLeft:'10px'}} onClick={async () => {
                            if (custReference !== '' && custReference !== e1['customerReference']) {
                                await Api.updateCustomerReferenceId(e1["_id"], custReference)
                                setInput(!input)
                            }
                        }}></i> : ''}
                    </div> 
                    {/* // : ''} */}
                    {e1['_id'] ? <div>Booking ID : <strong>{e1['_id']}</strong></div> : ''}
                    {e1['driverName'] ? <div>Driver Name : <strong>{e1['driverName']}</strong></div> : ''}
                    {e1['driverPhoneNumber'] && e1['tripStatus'] === 'started'
                        ? <div>Driver Phone Number : <input type="number" disabled value={e1['driverPhoneNumber']} />
                            &nbsp;
                            <button 
                                className="add-contact"
                                onClick={openUpdateModal}
                            >
                                Update Number
                            </button></div> :
                        <div>Driver Phone Number :<strong>{e1['driverPhoneNumber']}</strong></div>
                    }

                    {e1['details'][0].pickup ? <div>{e1['details'][0].pickup_2 ? "Pickup 1 :" : "Pickup :"}<strong>{e1['details'][0].pickup}</strong></div> : ''}
                    {e1['details'][0].pickup_2 ? <div>Pickup 2 : <strong>{e1['details'][0].pickup_2}</strong></div> : ''}
                </div>
                <div className="column is-6" style={{ textAlign: 'right' }}>
                    {e1['tripStatus'] ? <div>Trip Status : <strong>{e1['tripStatus']}</strong></div> : ''}
                    {e1['vendorName'] ? <div>Vendor Name : <strong>{e1['vendorName']}</strong></div> : ''}
                    {e1['vehicleNumber'] ? <div>Vehicle Number : <strong>{e1['vehicleNumber']}</strong></div> : ''}
                    {e1['details'][0]?.delivery.map((e, index) => (
                        <div key={index}>
                            {e.address ? (
                                <div>
                                    Delivery {index + 1}: <strong>{e.address}</strong>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>

            </div >
            <div className="columns">
                <div className="column is-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <button class="button is-danger" disabled={e1['tripStatus'] === 'complete'} onClick={async () => {
                        await Api.endTrip(e1['_id'], e1['tripId'])
                        props.refresh();
                    }}>End Trip</button>
                    <button class="button is-primary" onClick={openModal}>View Map</button>
                </div>
            </div>
            <DtMapVisibilty isOpen={isModalOpen} onClose={closeModal} mapUrl={e1['publicLink']} />
            <DtUpdatePhoneNumber isOpen={isUpdateModalOpen} onClose={closeUpdateModal} data={e1} />
        
                <div style={{ textAlign: 'center' }}>
                    {
                        (showDocuments) ?
                            loginStore.isClient() ?
                                <Button size='small' onClick={() => {
                                    setShowDocuments(false)
                                }} variant='contained' endIcon={<ArrowDropUp />} fullWidth sx={{
                                    display: "flex", textTransform: "capitalize", alignItems: "center", backgroundColor: "#ffdc7d", color: "black", "&:hover": {
                                        backgroundColor: "#ffdc7d"
                                    }
                                }} >  Hide Document Center</Button>

                                :

                                <button className='button is-warning is-small is-fullwidth'
                                    onClick>
                                    Hide Document Center
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-up"></i>
                                    </span>
                                </button> :
                            loginStore.isClient() ? <Button size="small" onClick={() => {
                                setShowDocuments(true)
                                // setShowDetails(false)
                            }} variant='contained' endIcon={<ArrowDropDown />} fullWidth sx={{ textTransform: "capitalize", alignItems: "center" }} >  Document Center</Button> :
                                <button className='button is-info is-small is-fullwidth'
                                    onClick={() => {
                                        setShowDocuments(true)
                                        // setShowDetails(false)
                                    }}>
                                    Document Center
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-down"></i>
                                    </span>
                                </button>

                    }

                    {
                        (showDocuments) ?
                            <div className="columns" style={{
                                fontSize: '0.8rem'
                            }}>
                                <div className="column is-12" >
                                    <Taskfiletable data={e1} />
                                </div>
                            </div> : <></>
                    }


                </div>
        </div >)
}