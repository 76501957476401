import React, { useEffect } from 'react';

const LinkedEntityPopup = ({ show, onClose, entityList, onEntitySelect, entityId }) => {
    
    if (!show) return null;

    return (
        <>
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }} onClick={onClose}></div>
            <div style={{ position: 'fixed', top: '50%', left: '30%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', zIndex: 10000 }}>
                <div className='exist_customer_entity_wrapper'>
                    <div className='exist_customer_entity_container'>
                        <h1 style={{ fontWeight: 900 }}>Link Existing Customer Entity</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Entity ID</th>
                                    <th>Entity Name</th>
                                    <th>Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.map((d) => (
                                    <tr key={d._id}>
                                        <td>
                                            <div className='flexWithCenter'>
                                                <input type="radio" id={`linkedRadio-${d._id}`} name="fav_language" defaultChecked={entityId === d._id} defaultValue={entityId === d._id} value={d._id} onClick={() => onEntitySelect(d._id)} />
                                                <label htmlFor={`linkedRadio-${d._id}`}>{d._id}</label>
                                            </div>
                                        </td>
                                        <td>{d?.entityName}</td>
                                        <td>{d?.entityCountry}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ textAlign: "right", margin: "1rem" }}>
                            <button className="button" style={{ fontWeight: 500, margin: "0 1rem" }} onClick={onClose}>Cancel</button>
                            <button className="button is-link" style={{ backgroundColor: "#2c358a", fontWeight: 500 }} onClick={onClose}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LinkedEntityPopup;
