import { useEffect, useRef, useState } from "react"
import { useSnapshot } from "valtio"
import Utils from "../../util/Utils"
import { DtStore } from "../DtStore"

export default function Dtinput(props) {
    const { label, record, name, type = 'text', validate = f => f, callback = f => f, isValid = true, validationMsg } = props
    let { isDisabled } = props
    const imRecord = useSnapshot(record)
    useSnapshot(DtStore)
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    let ph = ""
    if (label) {
        ph = label
    }
    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    let min = ''
    let max = '2120-01-01'
    if (type === 'date') {
        min = Utils.getCurrentDateString()
    }
    if(type === 'date'&& name == 'pickupdate'){
        min = Utils.getCurrentDateString()
        max = DtStore.DTBooking.valid_upto
    }
    let align = {}
    if (type === 'number') align = { align: 'right' }
    if (type === 'text') align = { align: 'right' }
    const handleChange = (e, name) => {
        if(name=='vehicle_no'){
            setCursor(e.target.selectionStart)
            record[name] = e.target.value.trim().replace(/[^0-9a-zA-Z]/g, '')
            callback(e.target.value)
        }else{
            setCursor(e.target.selectionStart)
            record[name] = e.target.value.trim()
            callback(e.target.value)
        }
        
    }
    return (
        <div className="field">
            {
                label ? <label className="label is-small" style={{ marginBottom: '0px' }}>{label}</label> : ""
            }

            <div className="control is-fullwidth">

                {
                    (type === 'date' || type=="time") ? <input style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                        record[name] = e.target.value
                        callback(e.target.value)
                    }}
                        {...isDisabled}
                        className={isValid ? "input is-small" : "input is-small is-danger is-danger-border"}
                        type={type} min={min} max={max} onFocus={(e) => {
                            if(type === 'date'){
                                document.getElementById(name).showPicker()
                            }
                            
                        }}
                        placeholder={ph} /> : <>
                        {
                            (type === 'number' || type=='email') ? <input style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                                handleChange(e, name)
                            }}
                                {...isDisabled}
                                className={isValid ? "input is-small" : "input is-small is-danger is-danger-border"}
                                type={type} min={min} max={max}
                                placeholder={ph} /> : <input ref={ref} style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                                    handleChange(e, name)
                                }}
                                    {...isDisabled}
                                    className={isValid ? "input is-small" : "input is-small is-danger is-danger-border"}
                                    type={type} min={min} max={max}
                                    placeholder={ph} />
                        }
                    </>
                }



            </div>
            {!isValid && label && <p class="help is-danger"> {validationMsg || `* Please enter valid ${label}`}</p>}
        </div>
    )
}