import { useEffect, useRef, useState } from "react"
import { proxy, ref, useSnapshot } from "valtio"
import Api from "../state/Api"
import { configStore, contractsStore, entityStore, loginStore, modalStore, quoteStore, enquiriesValidationStore, bookingsStore, EntityV2Store } from "../state/CommonState"
import SchApi from "../state/sch-api"
import HomescreenCargo from "./homescreen-cargo"
import HomescreenContainer from "./homescreen-container"
import { enquiryStore } from "../enquiry/enquiry-data"
import EnquiryConfirmation from "./enquiry-confirmation"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import Utils from "../util/Utils"
import { useGlobalState } from "../GlobalState"
import PricingRoute2 from "../pricing/pricing.route.v2"
import { rtpStore } from "../pricing/pricing-store"
import PricingUtils from "../pricing/pricing.utils"
import MonApi from "../state/mongo-api"
import AgCustlist from "../components/AgCustlist"
import LCLPricingSchedule from "../pricing/lcl.schedule"
import VehicleScreen from "../spotv3/vechile-screen"
import BookingConfirmation from "../schedules/booking-confirmation"
import SpotLCLDisplay from "./spot.lcl.display"
import SpotAirDisplay from "./spot.air.display"
import Pricingcwf from "../pricing/pricing.cwf"
import { Button } from "@mui/material"
import SpotFCLDisplay from "./spot.fcl.display"
import BoxPlotComponent from "./stacking"
import VehicleSelection from "./stacking"
import { AgSelectCountry } from "../components/AgComponents"
import { ServerLists } from "../StaticData"
import ActrejectModal6 from "../enquiry/enquirysuggestion"

let pricingStore = proxy({
    gst: '',
    origin: '',
    destination: ''
})
function EnquiryOption(props) {
    useSnapshot(enquiryStore)
    useSnapshot(loginStore)
    useSnapshot(rtpStore)
    let message = `We do not have these routes real-time but, would love to provide a customized solution.
    We will get in touch with your shortly over Phone or E-mail.`
    let oklabel = 'OK'
    let cancellabel = 'No'
    if (!loginStore.isClient()) {
        message = 'Real-time rates unavailable'
        oklabel = 'Submit Enquiry'
        cancellabel = 'Cancel'
    }
    return (
        <div style={{
            width: '50%', margin: 'auto', padding: '3rem', border: '1px solid black',
            backgroundColor: '#D4F2B5'
        }}>
            <div>{message}</div>
            {
                (rtpStore.customerTBA != 'TBA' && (!rtpStore.records || !rtpStore.records?.length)) &&
                <button className="button islink" style={{ float: 'right', marginLeft: '2rem' }}
                    onClick={(e) => {
                        //enquiryStore.current.entityId = 'TBA'
                        /*EnquiriesApi.saveEnquiry()
                        configStore.enquiryModalVisible = true*/
                        props.setMessage('')
                        if(!loginStore.isClient()){
                            configStore.quotationModalVisible4 = true
                        }else{
                            EnquiriesApi.saveEnquiry()
                        }
                    }}
                >{oklabel}</button>
            }
            <button className="button is-danger" style={{ float: 'right' }}
                onClick={(e) => {
                    props.setMessage('')
                }}
            >{cancellabel}</button>
        </div>
    )
}
function RoutesEmpty({ message, setMessage }) {
    useSnapshot(rtpStore)
    let marr = ['Enumerating Routes...', 'Calculating Cost...',
        'Loading Schedules...', 'Fetching Transport Rates...', 'Optimizing Results...',
        'Sorting Results...', 'Serializing ...']
    if (message == 'No Routes') return <EnquiryOption setMessage={setMessage} />

    message = '' + message
    if (message.startsWith('Fetching')) {
        let m = marr[rtpStore.loadingCounter % 5]
        if (!m || typeof m == 'undefined') m = 'Enumerating Routes...'
        return <div className="title is-3" style={{ color: 'blueviolet', marginBottom: "100px" }}>{'' + m}</div>
    }
    return (
        <div className="title is-5">{'' + message}</div>
    )

}
export default function SpotBookingv3(props) {
    let routeFilterList = ['Cheapest', 'Fastest']
    const [routeFilter, setRouterFilter] = useState('Cheapest')
    let cheapestColor = routeFilter == 'Cheapest' ? 'islink' : ''
    let fastestColor = routeFilter == 'Fastest' ? 'islink' : ''
    const [cwfVisible, setcwfVisible] = useState(false)
    const [Countries, setCountries] = useState([])
    const buttonRef = useRef();
    useSnapshot(configStore)
    useSnapshot(loginStore)
    useSnapshot(pricingStore)
    useSnapshot(entityStore)
    useSnapshot(enquiryStore)
    useSnapshot(rtpStore)
    useSnapshot(bookingsStore)
    useSnapshot(modalStore)

    const [serverTime, setServerTime] = useState(0)
    const [routesEmptyMessage, setRoutesEmptyMessage] = useState('')
    const [routes, setRoutes] = useState([])
    const [distance, setDistance] = useState({
        "from": "",
        "to": "",
        "distance": "",
        "duration": ""
    })
    const _params = (label, name, type = 'text', dataList = []) => {
        return {
            type, name, label, record: pricingStore,
            dataList
        }
    }
    useEffect(() => {
        let s = ServerLists.countryNameList
        setCountries(s)
        enquiryStore.current.countryFromEnquiry = 'India'
    }, [])
    useEffect(() => {
        rtpStore.countryName = enquiryStore.current.countryFromEnquiry
    }, [enquiryStore.current.countryFromEnquiry])
    useEffect(() => {
        let r = [...routes]
        if (routeFilter == 'Cheapest')
            r.sort((a, b) => a.dummyContract.quoteValue < b.dummyContract.quoteValue ? -1 : 1)
        else
            r.sort((a, b) => a.minTransitTime < b.minTransitTime && a.minTransitTime > 0 ? -1 : 1)
        setRoutes(r)
    }, [routeFilter])
    useEffect(() => {
        rtpStore.records = routes;
        if (routes.length > 0) {
            setTimeout(() => {
                if (buttonRef.current) {
                    buttonRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 200)
        }
    }, [routes])
    useEffect(() => {
        rtpStore.pricingSourceScreen = 'pricing'
        return () => {
            configStore.homescreenCurrentView = "list"
        }
    }, [])
    useEffect(() => {
        setRoutes([])
        setRoutesEmptyMessage('')
    }, [enquiryStore.current.enqRoute.origin, enquiryStore.current.enqRoute.destination, enquiryStore.cargoDetailsChanges])
    useEffect(() => {
        rtpStore.records = routes
    }, [routes])
    const { currentMenu } = useGlobalState();
    console.log('INSIDE HOMESCREEN spot V3')
    const rec = contractsStore.current

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [fclSchedules, setFclSchedules] = useState([])
    const [activeProduct, setActiveProduct] = useState(configStore.homescreenCurrentShipmentType)
    let productList = ['LCL', 'FCL', 'Air']
    useEffect(() => {

    }, [activeProduct])
    let e = enquiryStore.current
    if (loginStore.isClient() && contractsStore.current.isFromEnquiry && e.tsClientviewOn == undefined) {
        e.tsClientviewOn = Date.now()
        let id = contractsStore.current._id
        if (id.endsWith('C')) {
            id = id.substr(0, id.length - 1)
        }
        EnquiriesApi.updateToClientView(id)
    }

    // below line is a temporary fix to hide Air. To be removed.
    // productList = ['LCL', 'FCL']
    if (contractsStore.current['mode'] == 'CONTRACT' && contractsStore.current['quoteStatus'] != 'DECLINED' && contractsStore.current['quoteStatus'] != 'ACCEPTED') {
        contractsStore.current['quoteStatus'] = "ACCEPTED"
    }


    // let enquiryNum = configStore.enquiryModalNum
    const ChargeTable = (props) => {
        return <p>
            {JSON.stringify(props.charge)}
        </p>
        return <>
            {
                Object.keys(props.charge).map(e => <tr>
                    <td></td>
                    <td>{e}</td>
                    <td>{props.charge[e].category}</td>
                    <td colSpan='2'>
                        {
                            props.charge[e].comp ?
                                Object.entries(props.charge[e].comp).map(e1 => '' + e1 + ', ') :
                                'Comp undefined'
                        }
                    </td>
                    <td>
                        Total = {props.charge[e].total} {
                            typeof props.charge[e].markup != 'undefined' && props.charge[e].markup != 0 ? '(' + props.charge[e].markup + ')' : ''
                        }
                    </td>
                </tr>)
            }
        </>
    }
    const displayVehicleTable = () => {
        return false
        if (activeProduct == 'FCL' || activeProduct == 'LCL') return false
        let otype = enquiryStore.current.originType
        if (!otype) otype = 'Port'
        let dtype = enquiryStore.current.destinationType
        if (!dtype) dtype = 'Port'
        let flag = false
        let origin = enquiryStore.current.enqRoute.origin
        let destination = enquiryStore.current.enqRoute.destination

        if (otype != 'Port' && origin.includes(',India,')) flag = true
        else if (dtype != 'Port' && destination.includes(',India,')) flag = true
        return flag
    }
    const getRoutes = async () => {
        console.log('\n\n\n\n\n==============================')
        console.log('/', enquiryStore.current.enqRoute.origin, '/', enquiryStore.current.enqRoute.destination, rtpStore ,'/\n===============\n\n\n')
        setRoutes([])
        setRoutesEmptyMessage('Fetching Routes ...')
        rtpStore.loadingMessage = 'Fetching Routes '
        rtpStore.loadingTimer = setInterval(() => {
            rtpStore.loadingCounter++
        }, 10000);
        setRouterFilter('Cheapest')
        let curDate = Utils.getCurrentDateString()
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
        if (activeProduct == 'FCL') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getroutesv2`
        else if (activeProduct == 'Air') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/getroutes`
        let start = Date.now()
        if (loginStore.isClient()) pricingStore.entityId = configStore.currentSelectedEntity.entityId
        else configStore.currentSelectedEntity = { entityId: pricingStore.entityId }
        if (loginStore.isClient()) enquiryStore.current.entityId = configStore.currentSelectedEntity.entityId
        enquiryStore.current.shipmentType = 'LCL'
        // enquiryStore.current.entity = pricingStore.gst
        enquiryStore.current.createdBy = loginStore.email
        let otype = enquiryStore.current.originType
        if (!otype) otype = 'Port'
        let dtype = enquiryStore.current.destinationType
        if (!dtype) dtype = 'Port'
        enquiryStore.current.shipmentScope = `${otype}-to-${dtype}`
        let crd = '' + enquiryStore.current.enqRoute.cargoReadinessDate
        crd = crd.trim()
        if (crd == '') {
            crd = curDate
            enquiryStore.current.enqRoute.cargoReadinessDate = curDate
        }
        if (rtpStore.customerTBA == 'TBA') {
            enquiryStore.current.checkAgCustomer = 'No'
            enquiryStore.current.entityName = "YET TO BE ASSIGNED"
            enquiryStore.current.entityId = "TBA"
            enquiryStore.current.gst = 'GSTTBA000000000'
            enquiryStore.current.state = ''
            enquiryStore.current.createdBy = loginStore.email
            enquiryStore.current.isfromAgragaEnquiry = 'Yes'
        }
        let dimunit = enquiryStore.current.cargoDimensionUnit
        for (let e of enquiryStore.current.cargoDetails) {
            e.dimensions.unit = dimunit
        }
        let payload = {
            entity: enquiryStore.current.entityId,
            // entity: "CEN0665",
            origin: enquiryStore.current.enqRoute.origin,
            destination: enquiryStore.current.enqRoute.destination,
            sscope: `${otype}-to-${dtype}`,
            crd: enquiryStore.current.enqRoute.cargoReadinessDate,
            curDate,
            cargoDetails: enquiryStore.current.cargoDetails,
            vehicleDetails: enquiryStore.current.vehicleDetails,
            fclCargoApplicable: displayCargo4fcl,
            enquiry: enquiryStore.current
        }
        if (rtpStore.customerTBA == 'TBA') {
            payload.entity = 'COUNTRY:' + rtpStore.countryName
        }
        if (!PricingUtils.getTotalVehicleCount(payload.vehicleDetails) && displayVehicleTable()) {
            configStore.setModalMessage('At least one vehicle needs to be selected for India transport=')
            return
        }
        let cargo = payload.cargoDetails
        for (let i = 0; i < cargo.length; i++) {
            let o = cargo[i]
            o.dimensions.unit = payload.enquiry.cargoDimensionUnit
        }
        let ocountry = 'origin'
        let portre = /.*\((.....)\)$/
        let doorre = /.*\[(..,.*)\]$/
        let origin1 = payload.origin
        let destination1 = payload.destination
        if (origin1.includes('(') || origin1.includes('[')) {
            if (otype == 'Port') ocountry = origin1.match(portre)[1].slice(0, 2)
            else ocountry = origin1.match(doorre)[1].slice(0, 2)
        }
        let dcountry = 'destination'
        if (destination1.includes('(') || destination1.includes('[')) {
            if (dtype == 'Port') dcountry = destination1.match(portre)[1].slice(0, 2)
            else dcountry = destination1.match(doorre)[1].slice(0, 2)
        }
        //console.log('$$$$', payload.origin, payload.destination, ocountry, dcountry)
        if (ocountry == dcountry) {
            configStore.setModalMessage('We do not have Domestic Transport Capabilities at present' + ocountry + '/' + dcountry)
            return
        }
        if (ocountry != 'IN' && ocountry != 'origin' && dcountry != 'IN' && dcountry != 'destination') {
            configStore.setModalMessage('We do not serve these routes at present and will keep you posted when we launch in these markets')
            enquiryStore.current.enqRoute.origin = ''
            enquiryStore.current.enqRoute.destination = ''
            return
        }
        // ##################################################################
        let ret
        ret = await MonApi.apost(url, payload)
        if (ret.additionalsets) rtpStore.additionalsets = ret.additionalsets
        if (ret.finalset) ret = ret.finalset
        rtpStore.dpd = 'Direct Port Delivery'
        // ##################################################################
        console.log('payload==', JSON.stringify(payload))
        //console.log('new data=', enquiryStore.current.originType, enquiryStore.current.destinationType)
        setServerTime(Date.now() - start)
        clearInterval(rtpStore.loadingTimer)
        rtpStore.loadingCounter = 0
        console.log("The ret is ", ret);
        if (ret.error) {
            setRoutesEmptyMessage('')
            configStore.setModalMessage('' + ret.error, [], true, [], '', 5000)
        } else {
            for (let i = 0; i < ret.length; i++) {
                ret[i].dummyContract['entityId'] = enquiryStore.current.entityId
                ret[i].quote.quoteData['entityId'] = enquiryStore.current.entityId
                ret[i].quote.quoteSummary['entityId'] = enquiryStore.current.entityId
                if (i == ret.length - 1) {
                    setRoutes(ret)
                }
            }
            //  ret[0].dummyContract['entityId']= enquiryStore.current.entityId
            //   setRoutes(ret)
            if (!ret || !ret.length) setRoutesEmptyMessage('No Routes')
        }
    }

    useEffect(() => {
        if (!configStore.displaySchedules) configStore.showEnquiries = true
        if (props.viewonly || !props.viewonly) {
            (activeProduct === 'LCL') ?
                SchApi.getSchedules(rec._id, setSchedules) :
                SchApi.getSchedules(rec._id, setFclSchedules)
        }
        if (props.viewonly) {
            if (!configStore.displaySchedules) configStore.displaySchedules = true
        }
    }, [rec._id], e.stuffingType, e.destuffingType)
    console.log('*** ### INSIDE SPOT.BOOKING.V3.JS', rec, props.viewonly)
    if (!rec._id && props.viewonly) return <></>
    let contractId = rec._id
    if (rec.mode === 'QUOTE') contractId = contractId.substr(0, contractId.length - 1)
    let title = `Create Booking - ${contractId}`
    if (!props.viewonly) title = 'Enquiries'
    title = 'Quick Quote'
    let displayCargo4fcl = false


    {
        let e = enquiryStore.current
        // let stype = activeProduct.toLowerCase()
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        displayCargo4fcl = false
        if (stype === 'fcl') {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            if (e.originType != 'Port' && e.stuffingType != 'Factory') displayCargo4fcl = true
            if (e.destinationType != 'Port' && e.destuffingType != 'Factory') displayCargo4fcl = true
        }
    }

    { //assign shipping type to enquiry
        let e = enquiryStore.current
        let stype = activeProduct.toLowerCase()
        if (stype === 'lcl') {
            e.shipmentType = 'LCL'
        } else if (stype === 'air') {
            // if (e.temperatureControlled === 'Yes')
            //     e.shipmentType = 'Air (Temperature Controlled)'
            // else
            //     e.shipmentType = 'Air (Normal)'
            e.shipmentType = 'Air'
        } else {
            // if (e.temperatureControlled === 'Yes') {
            //     e.shipmentType = 'FCL (Temperature Controlled)'
            // } else {
            //     e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
            // }
            e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
        }
        //console.log('*** SHIPMNET TYPE ***', e.shipmentType)
    }
    const getDistance = async () => {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchdistance`
        let payload = {
            fromAddress: enquiryStore.current.enqRoute.origin,
            toAddress: enquiryStore.current.enqRoute.destination
        }
        let ret = await MonApi.apost(url, payload)
        setDistance(ret)
    }

    //console.log(activeProduct, 'activeProduct');
    //console.log(displayCargo4fcl, 'displayCargo4fcl2');
    return (
        <>
            <p><hr /></p>
            <EnquiryConfirmation />
            <ActrejectModal6 fromPricing={true} />
            <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                {title}
            </h5>
            {
                !loginStore.isClient() &&
                <div className="columns">
                    {/*<div className="column is-3" style={{ marginTop: '10px' }}>
                        <label style={{ display: "flex", gap: "1em" }}>
                            <strong>Customer Yet to be Assigned?</strong>
                            <input
                                id=""
                                type="checkbox"
                                checked={rtpStore.customerTBA == 'TBA'}
                                onChange={() => {
                                    rtpStore.customerTBA = rtpStore.customerTBA == 'TBA' ? 'A' : 'TBA'
                                }}
                            />

                        </label>
                            </div>*/}
                    {
                        (rtpStore.customerTBA == 'TBA') ?
                            <div className="column is-4" style={{ zoom: '1.3' }}>
                                <AgSelectCountry type='select' name='countryName' label='Customer Country'
                                    dataList={Countries} record={rtpStore} isChecked={rtpStore.customerTBA == 'TBA'}
                                />
                            </div>
                            :
                            <div className="column is-4" >
                                <label><strong>Customer</strong></label>
                                <AgCustlist {..._params('', '', 'entityId', EntityV2Store.Cuslist)} name='entityId' record={enquiryStore.current} />
                            </div>
                    }
                </div>
            }
            {
                (!props.viewonly && !cwfVisible) ?
                    <div style={{ width: loginStore.isClient() ? "20%" : "100%" }}>
                        <Agproducttab productList={productList}
                            activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
                    </div>
                    : <></>
            }
            {/*
                !loginStore.isClient() ?
                    <div className="columns">
                        <div className="column is-4">
                            <label><strong>Customer</strong></label>
                            <AgCustlist {..._params('', '', 'entityId', EntityV2Store.Cuslist)} name='entityId' record={enquiryStore.current} />
                        </div>
                    </div>
                    : <></>
        */}

            <div className="columns">
                <div className="column is-4">
                    <PricingRoute2 pureview={cwfVisible} />
                </div>
                <div className="column is-8">
                    <div className="columns">
                        <div className={'column ' + (displayVehicleTable() ? 'is-8' : 'is-12')}>
                            {
                                (activeProduct === 'FCL') ?
                                    <HomescreenContainer pureview={cwfVisible} {...props} /> :
                                    <HomescreenCargo {...props} isFromUploadSchedules={true} pureview={cwfVisible || routes.length} />
                            }
                            {
                                rtpStore.distanceButtonVisible ?
                                    <div className="columns">
                                        <div className="column is-2">
                                            <button className="button islink " style={{ marginLeft: '0px', padding: '0px' }}
                                                onClick={(e) => {
                                                    getDistance()
                                                }}
                                            >Distance</button>
                                        </div>
                                        <div className="column is-9">
                                            <div>From <strong>{distance.from}</strong></div>
                                            <div>To <strong>{distance.to}</strong></div>
                                            <div><strong>{distance.distance}</strong> in <strong>{distance.duration}</strong></div>
                                        </div>
                                    </div> :
                                    <></>
                            }
                        </div>
                        {
                            (displayVehicleTable()) &&
                            <div className="column is-4">
                                <VehicleScreen activeProduct={activeProduct} />
                            </div>
                        }
                    </div>
                    {
                        (displayCargo4fcl) ?
                            <div className="columns">
                                <div className="column is-12">
                                    <HomescreenCargo {...props}
                                        displayCargo4fcl={true}
                                    />
                                </div>
                            </div> : <></>
                    }

                </div>
            </div>


            <div className="columns">
                <div className="column is-12">
                    {
                        (props.viewonly && loginStore.isClient()) ?
                            <button style={{ float: 'left' }}
                                className="button is-rounded is-small islink" onClick={() => {
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc'].map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                }}>Terms and Conditions</button> :
                            <></>


                    }


                    <BookingConfirmation />
                    {
                        (!cwfVisible) ?
                            <div className="columns">
                                <div className="column is-9">
                                    {
                                        (!routes.length) ?
                                            <RoutesEmpty message={routesEmptyMessage} setMessage={setRoutesEmptyMessage} />
                                            : <></>
                                    }
                                </div>
                                <div className="column is-1">
                                    {loginStore.isClient() ? <Button onClick={(e) => {
                                        enquiryStore.initialize()
                                        enquiryStore.current.originType = 'Port'
                                        enquiryStore.current.destinationType = 'Port'
                                    }} sx={{
                                        backgroundColor: "white", textTransform: "capitalize", marginRight: "5px", height: "40px", "&:hover": {
                                            backgroundColor: "white"
                                        }
                                    }} >Clear</Button> : <button className="button" style={{ marginLeft: '3px' }}
                                        onClick={(e) => {
                                            enquiryStore.initialize()
                                            enquiryStore.current.originType = 'Port'
                                            enquiryStore.current.destinationType = 'Port'
                                        }}
                                    >Clear</button>}
                                </div>
                                <div className="column is-2">
                                    {loginStore.isClient() ? <Button onClick={(e) => {
                                        //console.log(JSON.stringify(enquiryStore))
                                        //console.log('ENQUIRY STORE=', JSON.stringify(enquiryStore.current))
                                        bookingsStore.initialize()
                                        bookingsStore.entityId = enquiryStore.current.entityId
                                        getRoutes()
                                        //testmail()
                                    }} variant="contained" size="medium" sx={{ textTransform: "capitalize", height: "40px" }}>Get Rates</Button> : <button className="button islink " style={{ marginLeft: '3px' }}
                                        onClick={(e) => {
                                            //console.log(JSON.stringify(enquiryStore))
                                            //console.log('ENQUIRY STORE=', JSON.stringify(enquiryStore.current))
                                            bookingsStore.initialize()
                                            getRoutes()
                                            //testmail()
                                        }}
                                    >Get Rates</button>}
                                </div>
                            </div>
                            : <></>
                    }
                    {/* <div className="columns">
                                <div className="column is-12">
                     <VehicleSelection/>
                     </div>
                </div> */}



                </div>
            </div >
            <hr />
            {
                (cwfVisible) ?
                    <Pricingcwf hideme={setcwfVisible} activeProduct={activeProduct} />
                    :
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (!routes.length) ?
                                    <></>
                                    :
                                    <> {
                                        activeProduct == 'LCL' &&
                                        <div className="columns">
                                            <div className="column is-4">
                                                {loginStore.isClient() ? <Button ref={buttonRef} onClick={() => {
                                                    setRouterFilter('Cheapest')
                                                }} variant={routeFilter === "Cheapest" ? "contained" : ""} startIcon={<i class="fa-solid fa-money-check-dollar"></i>} sx={{
                                                    textTransform: "capitalize", marginRight: "10px", backgroundColor: routeFilter === "Cheapest" ? "#2A6ED4" : "white", "&:hover": {
                                                        backgroundColor: routeFilter === "Cheapest" ? "#2A6ED4" : "white"
                                                    }
                                                }}>Cheapest</Button> : <button className={'button ' + cheapestColor} onClick={(e) => {
                                                    setRouterFilter('Cheapest')
                                                }}>
                                                    <span className="icon" style={{ marginRight: '5px' }}>
                                                        <i class="fa-solid fa-money-check-dollar"></i>
                                                    </span>
                                                    Cheapest
                                                </button>}
                                                {loginStore.isClient() ? <Button onClick={() => {
                                                    setRouterFilter('Fastest')
                                                }} startIcon={<i class="fa-solid fa-clock"></i>} variant={routeFilter === "Cheapest" ? "" : "contained"} sx={{
                                                    textTransform: "capitalize"
                                                    , backgroundColor: routeFilter === "Cheapest" ? "white" : "", "&:hover": {
                                                        backgroundColor: routeFilter === "Cheapest" ? "white" : "#2A6ED4"
                                                    }
                                                }}>Fastest</Button> : <button className={'button ' + fastestColor}
                                                    style={{ marginLeft: '1rem' }} onClick={(e) => {
                                                        setRouterFilter('Fastest')
                                                    }}>
                                                    <span className="icon" style={{ marginRight: '5px' }}>
                                                        <i class="fa-solid fa-clock"></i>
                                                    </span>
                                                    Fastest
                                                </button>}
                                            </div>
                                            <div className="column is-4"></div>
                                        </div>
                                    }
                                        {
                                            activeProduct == 'LCL' &&
                                            <SpotLCLDisplay routes={routes}
                                                cwfVisible={cwfVisible} setcwfVisible={setcwfVisible} />
                                        }
                                        {
                                            activeProduct == 'FCL' &&
                                            <SpotFCLDisplay routes={routes}
                                                cwfVisible={cwfVisible} setcwfVisible={setcwfVisible} />
                                        }
                                        {
                                            activeProduct == 'Air' &&
                                            <SpotAirDisplay routes={routes}
                                                cwfVisible={cwfVisible} setcwfVisible={setcwfVisible} />
                                        }
                                    </>
                            }
                        </div>
                    </div>
            }
        </>

    )
}