import { useSnapshot } from "valtio"
import { configStore } from "../state/CommonState"
import { myactionsstore } from "./store-myactions"
import { OneDatetable, OneInput5table } from "../mytasks/act-common"
import { ServerLists } from "../StaticData"
import { useForm } from 'react-hook-form';
import Api from "../state/Api";
import { useEffect, useState } from "react"
import Utils from "../util/Utils";

export function CustomModal(props) {
    useSnapshot(myactionsstore)
    useSnapshot(configStore)
    let ret = configStore.modalvisibledata
    console.log(myactionsstore);
    const [exportClearance, setExportClearance] = useState([])
    const [importClearance, setImportClearance] = useState([])
    const [icegateData, seticegateData] = useState(null)
    const { register, handleSubmit, errors } = useForm();

    const exportIndia = [{
        label: "Shipping Bill Checklist",
        value: ""
    }, {
        label: "Shipping Bill Number",
        value: ""
    }, {
        label: "Shipping Bill Date",
        value: ""
    }, {
        label: "Let-Export-Order Date",
        value: ""
    }]

    const exportNonIndia = [{
        label: "Customs Entry",
        value: ""
    }, {
        label: "Customs Reference Number",
        value: ""
    }, {
        label: "Submission to Customs Date",
        value: ""
    }, {
        label: "Cleared for export",
        value: ""
    }]

    const importIndia = [{
        label: "Bill of Entry Checklist",
        value: ""
    }, {
        label: "Bill of Entry Number",
        value: ""
    }, {
        label: "Bill of Entry Date",
        value:  ""
    }, {
        label: "Out of Charge Date",
        value: ""
    }]

    const importNonIndia = [{
        label: "Customs Entry",
        value: ""
    }, {
        label: "Customs Reference Number",
        value: ""
    }, {
        label: "Submission to Customs Date",
        value: ""
    }, {
        label: "Customs Clearance Complete",
        value: ""
    }]
   async function getData() {
        await Api.getICEGATEData({id:configStore.modalvisibledata?._id || props.data?._id},async(data)=>{
            console.log(data?.data);
            let resData = data?.data
            if (resData?.length > 0 && resData[0]?.icegateResData) {
                let tempData = {
                    res:resData[0]?.icegateResData?.response,
                    req:resData[0]?.icegateReqData,
                    customerCompletedOn:Utils.convertEpochDate(resData[0]?.customerCompletedOn)?.split(',')[0],
                    fullDate:Utils.convertEpochDate(resData[0]?.customerCompletedOn)
                }
                seticegateData(tempData)
               const date=  Utils.convertEpochDate(resData[0]?.customerCompletedOn)
               console.log(date.split(',')[0]);
            }
        })
    } 

    useEffect(() => {
        getData()
    }, [configStore.modalvisibledata]);
    useEffect(() => {
    
        if (Utils.extractCountry(props.data.origin) === 'India') {
            setExportClearance(props?.data?.exportClearance?.length>0 ? props?.data?.exportClearance : exportIndia)
        }
        if (Utils.extractCountry(props.data.destination) === 'India') {
            setImportClearance(props?.data?.importClearance?.length>0 ? props?.data?.importClearance : importIndia)
        }
        if (Utils.extractCountry(props.data.destination) !== 'India') {
            setImportClearance(props?.data?.importClearance?.length>0 ? props?.data?.importClearance : importNonIndia)
        }
        if (Utils.extractCountry(props.data.origin) !== 'India') {
            setExportClearance(props?.data?.exportClearance?.length>0 ? props?.data?.exportClearance : exportNonIndia)
        }
    }, [props?.data]);

    let modalClass = 'modal'
    if (props.open) modalClass += ' is-active'

    const onSubmit = () => {
            ret.exportClearance = exportClearance 
            ret.importClearance = importClearance
            configStore.modalvisibledata = ret
        props.close()
    }
    return (
        <>
            {
                <div className={modalClass}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: "75%" }} >
                        <div className="modal-card-body" >
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                <div style={{ backgroundColor: "grey", color: "white", height: "25px", width: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", cursor: 'pointer' }} onClick={props.close}>X</div>
                            </div>
                            <div className="table-container" onClick={(e) => e.stopPropagation()}>
                                <div style={{ paddingBottom: '20px', fontWeight: 'bold' }}>Export Clearance ({Utils.extractCountry(props.data.origin)})</div>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            {exportClearance?.map((e) => <th>{e.label}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {exportClearance?.map((e, index) => (
                                                <td key={index}>
                                                    {index === 1 ? (
                                                        <input
                                                            className="input is-normal"
                                                            type="text"
                                                            disabled={icegateData && (e.label === "Shipping Bill Number")?true:props.disable}
                                                            value={icegateData && e.label === "Shipping Bill Number"?exportClearance[index].value=icegateData?.req?.no:exportClearance[index].value}
                                                            onChange={(e) => {
                                                                setExportClearance((prev) => {
                                                                    const updatedDocumentList = [...prev];
                                                                    updatedDocumentList[index].value = e.target.value
                                                                    return updatedDocumentList
                                                                })
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                        <input
                                                            className="input is-normal"
                                                            type="date"
                                                            max={Utils.dateConversion(new Date(), true)}
                                                            onKeyDown={(e) => e.preventDefault()} 
                                                            disabled={icegateData && (e.label === "Shipping Bill Date"||e.label === "Let-Export-Order Date" || e.label === "Shipping Bill Checklist")?true:props.disable}
                                                            value={
                                                                icegateData && e.label === "Shipping Bill Checklist"?exportClearance[index].value=`${icegateData?.customerCompletedOn.replace(/\//g, '-').split('-').reverse().join('-')}`:
                                                                icegateData && e.label === "Shipping Bill Date"?exportClearance[index].value=`${icegateData?.req?.date?.slice(4, 8)}-${icegateData?.req?.date?.slice(2, 4)}-${icegateData?.req?.date?.slice(0, 2)}`:
                                                                icegateData && e.label === "Let-Export-Order Date"?exportClearance[index].value=`${icegateData?.res?.leoDt?.slice(4, 8)}-${icegateData.res.leoDt?.slice(2, 4)}-${icegateData.res.leoDt?.slice(0, 2)}`:exportClearance[index].value
                                                            }
                                                            placeholder="Enter a Number"
                                                            onChange={(e) => {
                                                                // const today = new Date().toISOString().split('T')[0];
                                                                // const selectedDate = e.target.value;
                                                                // if (selectedDate <= today) {
                                                                    setExportClearance((prev) => {
                                                                        const updatedDocumentList = [...prev];
                                                                        updatedDocumentList[index].value = e.target.value
                                                                        return updatedDocumentList
                                                                    })
                                                                // } else {
                                                                //     configStore.setModalMessage("Enter a valid date")
                                                                // }
                                                            }}
                                                        />
                                                        {/* {icegateData && e.label === "Shipping Bill Checklist"?icegateData?.fullDate:null} */}
                                                        </>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-container" onClick={(e) => e.stopPropagation()}>
                                <div style={{ paddingBottom: '20px', fontWeight: 'bold' }}> Import Clearance ({Utils.extractCountry(props.data.destination)})</div>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            {importClearance?.map((e) => <th>{e.label}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {importClearance?.map((e, index) => (
                                                <td key={index}>
                                                    {index === 1 ? (
                                                        <input
                                                            className="input is-normal"
                                                            type="text"
                                                            disabled={(icegateData && e.label === "Bill of Entry Number")?true:props.disable}
                                                            value={icegateData && e.label === "Bill of Entry Number"?importClearance[index].value=icegateData.req.no:importClearance[index].value}
                                                            onChange={(e) => {
                                                                setImportClearance((prev) => {
                                                                    const updatedDocumentList = [...prev];
                                                                    updatedDocumentList[index].value = e.target.value
                                                                    return updatedDocumentList
                                                                })
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                        <input
                                                            className="input is-normal"
                                                            type="date"
                                                            max={Utils.dateConversion(new Date(), true)}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            disabled={icegateData && (e.label === "Bill of Entry Date"||e.label === "Out of Charge Date" ||e.label ===  "Bill of Entry Checklist")?true:props.disable}
                                                            value={
                                                                icegateData && e.label === "Bill of Entry Date"?importClearance[index].value =`${icegateData.req.date?.slice(4, 8)}-${icegateData.req.date?.slice(2, 4)}-${icegateData.req.date?.slice(0, 2)}`:
                                                                icegateData && e.label === "Bill of Entry Checklist"?importClearance[index].value=`${icegateData?.customerCompletedOn.replace(/\//g, '-').split('-').reverse().join('-')}`:
                                                                icegateData && e.label === "Out of Charge Date"?importClearance[index].value=`${icegateData?.res?.oocDt?.slice(4, 8)}-${icegateData.res.oocDt.slice(2, 4)}-${icegateData.res.oocDt.slice(0, 2)}`:importClearance[index].value
                                                            }
                                                            placeholder="Enter a Number"
                                                            onChange={(e) => {
                                                                // const today = new Date().toISOString().split('T')[0];
                                                                // const selectedDate = e.target.value;
                                                                // if (selectedDate <= today) {
                                                                    setImportClearance((prev) => {
                                                                        const updatedDocumentList = [...prev];
                                                                        updatedDocumentList[index].value = e.target.value
                                                                        return updatedDocumentList
                                                                    })
                                                                // } else {
                                                                //     configStore.setModalMessage("Enter a valid date")
                                                                // }
                                                            }}
                                                        />
                                                        {/* {icegateData && e.label === "Bill of Entry Checklist"?icegateData?.fullDate:null} */}
                                                       </>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {!props.disable ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                {/* <button class="button is-danger" onClick={() => {
                                    setExportClearance(exportClearance)
                                    setImportClearance(importClearance)
                                    props.close()
                                }}>Cancel</button> */}
                                <button type="submit" class="button is-primary" onClick={onSubmit}>Submit</button>
                            </div> : <></>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}





