import { useEffect, useState } from "react"
import { proxy, useSnapshot } from "valtio"
import { configStore, entityStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import MonApi from "../state/mongo-api"
import Utils from "../util/Utils"
import { AgUnloc } from "../components/AgComponents"
import AgCustlist from "../components/AgCustlist"

let pricingStore = proxy({
    gst: '',
    origin: '',
    destination: ''
})
export default function PricingCalc() {
    const store = useSnapshot(myactionsstore)
    useSnapshot(pricingStore)
    useSnapshot(entityStore)
    const [serverTime, setServerTime] = useState(0)
    const [routesEmptyMessage, setRoutesEmptyMessage] = useState('No Routes')
    const [sscope, setsscope] = useState('')
    const [crd, setCrd] = useState('')
    const [routes, setRoutes] = useState([])
    const _params = (label, name, type = 'text', dataList = []) => {
        return {
            type, name, label, record: pricingStore,
            dataList
        }
    }
    const getRoutes = async () => {
        setRoutes([])
        setRoutesEmptyMessage('Fetching Routes ...')
        let curDate = Utils.getCurrentDateString()
        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
        let start = Date.now()
        let ret = await MonApi.apost(url, {
            entity: pricingStore.gst,
            origin: pricingStore.origin,
            destination: pricingStore.destination,
            sscope, crd, curDate
        })
        setServerTime(Date.now() - start)
        if (ret.error) {
            configStore.setModalMessage('' + ret.error)
        } else
            setRoutes(ret)
        setRoutesEmptyMessage('No Routes')
    }
    let a = {
        "Origin Charges": {
            "comp": {
                "Per Shipment": 25,
                "Origin Rate 1": 25,
                "Origin Rate 2": 10,
                "VGM (Flat Fee)": 15
            },
            "total": 75
        },
        "Ocean Freight": {
            "comp": {
                "Ocean Freight": 75
            },
            "total": 75
        },
        "Total": { "comp": 150, "total": 150 }
    }
    const ChargeTable = (props) => {
        return <>
            {
                Object.keys(props.charge).map(e => <tr>
                    <td></td>
                    <td>{e}</td>
                    <td colSpan='3'>
                        {
                            Object.entries(props.charge[e].comp).map(e1 => '' + e1[0] + '->' + e1[1] + ', ')
                        }
                    </td>
                    <td>
                        Total = {props.charge[e].total}
                    </td>
                </tr>)
            }
        </>
    }
    return (
        <div>
            <div className="columns">
                <div className="column is-2">
                    <label>Customer</label>
                    <AgCustlist {..._params('', 'gst', 'select', entityStore.list)} />
                </div>
                <div className="column is-2">
                    <label>Origin</label>
                    <AgUnloc {..._params('', 'origin')} />
                </div>
                <div className="column is-2">
                    <label>Destination</label>
                    <AgUnloc {..._params('', 'destination')} />
                </div>
                <div className="column is-2">
                    <label>Shipment Scope</label>
                    <select className="select" value={sscope} onChange={(e) => setsscope(e.target.value)}>
                        <option value='Port-to-Port'>Port-to-Port</option>
                        <option value='Port-to-Door'>Port-to-Door</option>
                        <option value='Door-to-Port'>Door-to-Port</option>
                        <option value='Door-to-Door'>Door-to-Door</option>
                    </select>
                </div>
                <div className="column is-2">
                    <label>Cargo Readiness</label>
                    <input type='date' className="input is-small" placeholder="Origin"
                        value={crd} onChange={(e) => setCrd(e.target.value)} />
                </div>
                <div className="column is-2">
                    <button className="button islink " style={{ marginLeft: '3px' }}
                        onClick={(e) => {
                            getRoutes()
                            //console.log(pricingStore.gst, pricingStore.origin, pricingStore.destination)
                        }}
                    >Fetch Routes</button>
                </div>
            </div>
            <div className="columns">
                <div className="column is-12">
                    {
                        (!routes.length) ?
                            <div className="title is-5">{routesEmptyMessage}</div>
                            :
                            <table className="table is-bordered is-fullwidth is-narrow " style={{ fontSize: '0.75rem', padding: '0px' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={7}>Total Routes = {routes.length}, Displaying max 100 routes, API duration = {serverTime / 1000} secs</th>
                                    </tr>
                                    <tr>
                                        <th>Set #</th>
                                        <th>POR</th>
                                        <th>POL</th>
                                        <th>RE</th>
                                        <th>POD</th>
                                        <th>FPOD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        routes.slice(0,100).map(e => <>
                                            <tr style={{fontWeight: 'bold'}}>
                                                <td>{e.set}</td>
                                                <td>{e.por}</td>
                                                <td>{e.pol}</td>
                                                <td>{e.rew}</td>
                                                <td>{e.pod}</td>
                                                <td>{e.fpod}</td>
                                            </tr>
                                            <ChargeTable charge={e.charges} />
                                            <tr style={{color: '#a30'}}>
                                                <td colSpan='6'>
                                                    Sources : 
                                                {
                                                    Object.keys(e.sources).join(', ')
                                                }
                                                </td>
                                            </tr>
                                        </>)
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>

        </div >
    )
}