import { useSnapshot } from "valtio"
import {
    quoteDataRec, containerStore
} from './state/CommonState'

export default function ContainerView() {
    const imContainerStore = useSnapshot(containerStore)
    const imQuoteDataRec = useSnapshot(quoteDataRec)

    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='5'>
                                <h3 className="is-size-5 has-text-centered">Container Requirements</h3>
                            </th>
                        </tr>
                        <tr>
                            <th style={{textAlign: 'center'}}>ID</th>
                            <th style={{textAlign: 'center'}}>Container Type</th>
                            <th style={{textAlign: 'center'}}># of Containers</th>
                            <th style={{textAlign: 'center'}} >Cargo Weight per Container (kgs)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            containerStore.list.map((e, i) => {
                                {
                                    return <tr key={i + 1}>
                                        <td style={{textAlign: 'center'}}>{i + 1}</td>
                                        <td style={{textAlign: 'center'}}>{e.containerType}</td>
                                        <td style={{textAlign: 'center'}}>{e.numContainers}</td>
                                        <td style={{textAlign: 'center'}}>{e.wtPerContainer}</td>
                                    </tr>
                                }
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
