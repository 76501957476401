import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table"
import { QueryClient, QueryClientProvider } from "react-query";

import { reducer, PAGE_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAGE_FILTER_CHANGED,  PAYLOAD_CHANGED } from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import {  configStore, customerStore, entityStore, loginStore } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import { loader } from "../util/loader";
import Utils from "../util/Utils";
import TableHead from "../util/dataTable";
import { DtMap } from "./DtMap";
import DtApi from "../state/dt-api";
import { Box } from "@mui/material";
import { CustomTabBar } from "../mui-components/CustomTabBar";
import { Nodata } from "../mui-components/nodatafound";
import EntityApi from "../state/entity-api";
const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const DataTable = (props) => {
    useSnapshot(DtStore)
    useSnapshot(customerStore)
    useSnapshot(configStore)
    useSnapshot(loader)
    useSnapshot(entityStore)
    let modalClass = 'modal'
    if (DtStore.modalVisible) modalClass += ' is-active'
    const [reason, setReason] = useState("");

    const [selectedFilter, SetSelectedFilter] = useState('INPROGRESS');
    const [expandrow, setExpandrow] = useState(-1);

   




    const [keyword, setKeyword] = useState('');
    const [data1, setData1] = useState({ results: [] })
 


    const [{ queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    React.useReducer(reducer, initialState);

    useEffect(() => {
        ApiAgTable.dtBookingData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
            res.clone().json().then((res) => {
                if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
                    if (JSON.stringify(data1) != JSON.stringify(res)) {
                        setData1(res)
                    }
                }
            }, err => {
                console.log(err)
            })
        })


    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy , configStore.currentSelectedEntity , configStore.currentSelectedBranch])



    const [pageIndex,setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState('20');
    const [sortBy, setSortBy] = useState([]);
    const [showfillter, setShowfillter] = useState(-1);
    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
        setShowfillter(-1)
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: +pageSize });
        setPageIndex(0)
        setShowfillter(-1)
    }, [pageSize]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        setShowfillter(-1)
    }, [sortBy]);

    useEffect(() => {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        setPageIndex(0)
        setShowfillter(-1)
    }, [keyword]);

   

    useEffect(() => {
        dispatch({ type: PAYLOAD_CHANGED, payload: { status: selectedFilter,date:Utils.getCurrentDateString() } });
        setPageIndex(0)
        setShowfillter(-1)
    }, [ selectedFilter , configStore.currentSelectedEntity]);

   
    const checknext = ()=>{
      let l = Number(data1['count']) - ((Number(pageIndex)+1)*Number(pageSize))
      if(l<=0){
        return true
      }else{
        return false
      }
    }

       const changefilter = (v)=> {
        SetSelectedFilter(v)
        setShowfillter(false)
       }

       const changeexpand=(i)=>{
        if(i==expandrow){
          setExpandrow(-1)
        }else{
          setExpandrow(i)
        }
       }

       const changesort = (key)=>{
        if (sortBy.length==0){
          setSortBy([{id:key,desc:true}])
        }else{
          if(sortBy[0]['id']==key){
            if(sortBy[0]['desc']){
              setSortBy([{id:key,desc:false}])
            }else{
              setSortBy([{id:key,desc:true}])
            }
          }else{
            setSortBy([{id:key,desc:true}])
          }
        }
       }
       const declinesubmit = () => {
        loader.show()
        DtApi.postCancelDtBooking({id:DtStore.cancelid,reason:reason},cancelmsg)
        DtStore.modalVisible = false
    }
    const cancelmsg = ()=>{
      loader.hide()
      setReason("")
      configStore.setModalMessage("Booking is Cancelled.")
      DtStore.btabview = "Cancelled"
  }

  function checkEntityStatus(status){
    let isStatusAvail = false; 
    if(status === "VERIFIED") {
      isStatusAvail= true
      return isStatusAvail
    }  
    return isStatusAvail  
   }
    return (
        <>
        <div className="main-content-3" style={{fontFamily:"Hanken Grotesk, san-seri",color:"#3A3A3A",padding:"0"}}>
  <div className="top-header" style={{padding:"0",margin:"0"}}>
    <div className="page-heading" style={{fontSize:"28px"}}>My Bookings</div>

   { loginStore.isClient() ?
       <Box sx={{display: "flex", flexDirection: 'row', gap: 1,backgroundColor:"white",borderRadius:"5px", marginY : "5px",padding:"5px",height:"45px"}}>
          <CustomTabBar selected={selectedFilter=="INPROGRESS"} icon="./assets/Tabler-Progress-2.png" onClick={()=>{SetSelectedFilter("INPROGRESS")}} title="In-Progress" />
          <CustomTabBar selected={selectedFilter=="ARCHIVED"} icon="./assets/Archive-2.png" onClick={()=>{SetSelectedFilter("ARCHIVED")}} title="Completed" />
          <CustomTabBar selected={selectedFilter=="CANCELLED"} icon="./assets/Calender-cancel-2.png" onClick={()=>{SetSelectedFilter("CANCELLED")}} title="Cancelled" />
       </Box>
   : <div className="switch-content">
      <div className={selectedFilter=="INPROGRESS"?"in-progress-tab":"archived-tab"} onClick={()=>{SetSelectedFilter("INPROGRESS")}}>
        <img
          src="./assets/Tabler-Progress-2.png"
          alt="In-Progress"
          className={selectedFilter=="INPROGRESS"?"switch-icon d-block":"switch-icon d-none"}
        />
        <img
          src="./assets/Tabler-Progress-1.png"
          alt="In-Progress"
          className={selectedFilter=="INPROGRESS"?"switch-icon d-none":"switch-icon d-block"}
        />
        <span className="switch-text">In-Progress</span>
      </div>

      <div className={selectedFilter=="ARCHIVED"?"in-progress-tab":"archived-tab"} onClick={()=>{SetSelectedFilter("ARCHIVED")}}>
        <img
          src="./assets/Archive-2.png"
          alt="Archived"
          className={selectedFilter=="ARCHIVED"?"switch-icon d-block":"switch-icon d-none"}
        />
        <img
          src="./assets/Archive-1.png"
          alt="Archived"
          className={selectedFilter=="ARCHIVED"?"switch-icon d-none":"switch-icon d-block"}
        />
        <span className="switch-text">Completed</span>
      </div>
      <div className={selectedFilter=="CANCELLED"?"in-progress-tab":"archived-tab"} onClick={()=>{SetSelectedFilter("CANCELLED")}}>
        <img
          src="./assets/Calender-cancel-2.png"
          alt="Canceled"
          className={selectedFilter=="CANCELLED"?"switch-icon d-block":"switch-icon d-none"}
        />
        <img
          src="./assets/Calender-cancel-1.png"
          alt="Canceled"
          className={selectedFilter=="CANCELLED"?"switch-icon d-none":"switch-icon d-block"}
        />
        <span className="switch-text">Canceled</span>
      </div>
    </div>}

    <div className="search-wrapper">
      <div className="search-div">
        <div className="search-img-div">
          <img
            src="./assets/search-grey.png"
            alt="Search"
            className="search-img"
          />
        </div>
        <input
          type="search"
          placeholder="Search"
          className="search-contracts"
          onChange={(e)=>{
            setKeyword(e.target.value)
          }}
        />
      </div>
    </div>
  </div>

  <div className="whole-table" style={{backgroundColor:'white'}}>
    <div className="heading-row">
      <span className="heading-table-cells heading-filler-start"></span>
      <span className="heading-table-cells booking-id ">Booking Id</span>
      <span className="heading-table-cells customer-ref-no">
        Customer Ref. No.
      </span>
      <span className="heading-table-cells vehicle-type">Vehicle Number</span>
      <span className="heading-table-cells booking-date">
        Booking Date
        <img src="./assets/Sort-blue.png" alt="Sort" className="sort-blue" onClick={()=>{changesort("DtBookings.bookingdate")}}/>
      </span>
      <span className="heading-table-cells pickup-date">
        Pickup Date
        <img src="./assets/Sort-blue.png" alt="Sort" className="sort-blue" onClick={()=>{changesort("DtBookings.pickupdate")}}/>
      </span>
      <span className="heading-table-cells pickup-address">Pickup Address</span>
      <span className="heading-table-cells delivery-address">
        Delivery Address
      </span>
      <span className="heading-table-cells status">Status</span>
      <span className="heading-table-cells track">Track</span>
      <span className="heading-table-cells heading-filler-end"></span>
    </div>

    <div className="table-wrapper">

    {
  data1?.results?.map((e, i) => {
return <div className={i==expandrow?(e.DtBookings['details'][0]['delivery'].length>1 || (e.DtBookings['details'][0]['pickup_2'] !=undefined && e.DtBookings['details'][0]['pickup_2'].trim().length>0))?"table-row height-300":"table-row height-200":"table-row"}>
      
<span className={(e.DtBookings['details'][0]['delivery'].length>1 || (e.DtBookings['details'][0]['pickup_2'] !=undefined && e.DtBookings['details'][0]['pickup_2'].trim().length>0)) && i==expandrow?"table-cells heading-filler-start transform-down ":"table-cells heading-filler-start"} onClick={()=>changeexpand(i)}>
  <img
    src="./assets/chevron-grey.png"
    alt="Accordian"
    className={i==expandrow?"chevron-grey rotate-arrow":"chevron-grey"}
  />
</span>
<a className="table-cells booking-id underline" onClick={()=>{
  DtStore.DTBooking = e.DtBookings
  DtStore.bview = "edit"
  DtStore.valid = false
  configStore.dtCurrentView = 'Booking'
}}>
  {e.DtBookings['_id']}{" "}
  <img
    src="./assets/Link-arrow (1).png"
    alt="Booking-link"
    title="Booking-Link"
    className="booking-link"
  />
</a>
<span className="table-cells customer-ref-no"><span>{e.DtBookings['customer_ref_no']}</span></span>
<span className="table-cells vehicle-type" title={e.DtBookings.vehicle}><span>{e.DtBookings['vehicle_no']}</span> </span>
<span className="table-cells booking-date">{Utils.ddmmyyyystring(e.DtBookings['bookingdate'])}</span>
<span className="table-cells pickup-date">{Utils.ddmmyyyystring(e.DtBookings['pickupdate'])}</span>
{
      (e.DtBookings['details'][0]['delivery'].length>1 || (e.DtBookings['details'][0]['pickup_2'] !=undefined && e.DtBookings['details'][0]['pickup_2'].trim().length>0))?<>
      <span className="table-cells pickup-address" onClick={()=>changeexpand(i)}>
      {
          (e.DtBookings['details'][0]['pickup_2'] !=undefined && e.DtBookings['details'][0]['pickup_2'].trim().length>0)?<span className="white-space-normal height-fit">Mulitple (2)</span>:
          <span className="white-space-normal height-fit">Single</span>
        }
        </span>
        <span className="table-cells delivery-address" onClick={()=>changeexpand(i)}>
        {
            (e.DtBookings['details'][0]['delivery'].length>1)?<span className="white-space-normal height-fit">Mulitple ({e.DtBookings['details'][0]['delivery'].length})</span>:
            <span className="white-space-normal height-fit">
          Single
        </span>
          }
          
        </span>
      </>:<>
      <span className="table-cells pickup-address" onClick={()=>changeexpand(i)}>
        {
          (i==expandrow)?<span className="white-space-normal height-fit">
          {e.DtBookings['details'][0]['pickup']}
        </span>:<span>{e.DtBookings['details'][0]['pickup']}</span>
        }
          
        </span>
        <span className="table-cells delivery-address" onClick={()=>changeexpand(i)}>
        {
          (i==expandrow)?<span className="white-space-normal height-fit">
          {e.DtBookings['details'][0]['delivery'][0]['address']}
        </span>:<span>{e.DtBookings['details'][0]['delivery'][0]['address']}</span>
        }
        </span>
      </>
    }
<span className="table-cells status">
  {(selectedFilter=="INPROGRESS")?<span className={e.DtBookings.status == 'Yet to Commence'?"ytc-status":e.DtBookings.status == 'Commenced'?"ytc-status":e.DtBookings.status == 'In-Transit'?"i-n-status":"r-a-p-status"}>{e.DtBookings['status']}</span>:""}
  {(selectedFilter=="ARCHIVED")?<span className="cm-status">{e.DtBookings['status']}</span>:""}
  {(selectedFilter=="CANCELLED")?<span className="cn-status">{e.DtBookings['status']}</span>:""}
</span>
<span className="table-cells track">
  {
    (e.DtBookings.publiclink)?<a className="track-button" onClick={()=>{
      DtStore.MapUrl = e.DtBookings.publiclink
      DtStore.modalVisible2 = true
  }}>
<img
src="./assets/Track-points.png"
alt="Track"
className="track-img"
/>
</a>:""
  }
  
</span>

<span className="table-cells heading-filler-end pointer" style={{position:"relative"}}>
  <img src="./assets/menu-dots.png" alt="Menu" className="menu-dots" onClick={()=>{
          if(showfillter == i){
            setShowfillter(-1)
          }else{
            setShowfillter(i)
          }
        }}/>
  <div className={showfillter==i?(e.DtBookings.status != 'Yet to Commence' && e.DtBookings.status != 'Report at Pickup')? "action-dropdown action-dropdown1":"action-dropdown":"action-dropdown d-none"}>
    <span className="clone" onClick={() => {
      console.log(configStore.currentSelectedEntity._id,'entityId');
         EntityApi.getEntity(configStore.currentSelectedEntity._id,entityData=>{
          let entitystatusResult =  checkEntityStatus(entityData.status)
          if(entitystatusResult){
          DtStore.DTBooking = DtStore.clonebooking(e.DtBookings)
          DtStore.bview = "create"
          DtStore.valid = false
          configStore.dtCurrentView = 'Booking'
        } else{
          configStore.setModalMessage(`Please submit entity details for verification to proceed with the booking.
            kindly contact the Reporting manager or Customer Success to update and proceed Booking .
            Contact ID :
  
            Reporting Manager - ${entityData?.customer?.domesticTransport?.relationshipManager}
            Customer Success - ${entityData?.customer?.domesticTransport?.customerService}
            Customer ID ${configStore.currentSelectedEntity._id}.`)
      }
      }) 
      }}>Repeat Booking</span>
    {(e.DtBookings.request_cancel == 'Yes') ?
    <span className="cancel" onClick={() => {
      setShowfillter(-1)
      DtStore.cancelid = e.DtBookings._id
      setReason("")
      DtStore.modalVisible = true
  }}>Cancel Booking</span>:""}
  </div>
</span>

{((e.DtBookings['details'][0]['delivery'].length>1 || (e.DtBookings['details'][0]['pickup_2'] !=undefined && e.DtBookings['details'][0]['pickup_2'].trim().length>0)) && i==expandrow)?<div className="address-div" >
{
            (e.DtBookings['details'][0]['pickup_2'] !=undefined && e.DtBookings['details'][0]['pickup_2'].trim().length>0)?<>
            <div className="add-block block-1" >
            <label for="" className="add-heading">
              Pickup Address 1
            </label>
            <div className="add-div" >
              <span className="address" >
                {e.DtBookings['details'][0]['pickup']}
              </span>
            </div>
          </div>
          <div className="thread-div">
            <span className="thread"></span>
          </div>
          <div className="add-block block-1" >
            <label for="" className="add-heading">
              Pickup Address 2
            </label>
            <div className="add-div" >
              <span className="address" >
                {e.DtBookings['details'][0]['pickup_2']}
              </span>
            </div>
          </div>
            </>:
            <div className="add-block block-1" >
            <label for="" className="add-heading">
              Pickup Address
            </label>
            <div className="add-div" >
              <span className="address" >
                {e.DtBookings['details'][0]['pickup']}
              </span>
            </div>
          </div>
          }
          
          
          {
            e.DtBookings['details'][0]['delivery'].map((v, j) => {
              return <>
              <div className="thread-div">
            <span className="thread"></span>
          </div>

          <div className="add-block block-1" >
            {
              (e.DtBookings['details'][0]['delivery'].length>1)?<label for="" className="add-heading" >
              Delivery Address-{j+1}
            </label>:
            <label for="" className="add-heading" >
            Delivery Address
          </label>
            }
            
            <div className="add-div" >
              <span className="address" >
                {v.address}
              </span>
            </div>
          </div>
              </>
            })
          }
        </div>:<></>}
</div>
  })}

   { data1?.results?.length===0 && <Nodata width="300px" height="300px"/>}     

     
    </div>

    <div className="footer-row">
      <div className="left-footer">
        <span className="rows-per-page">Show rows per page</span>
        <div className="row-select-dropdown">
          <div className="row-select-wrapper">
            <select className="no-of-rows" onChange={(e)=>{
              setPageSize(e.target.value)
            }}>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <img
              src="./assets/chevron-grey.png"
              alt="Chevron"
              className="chevron-grey-2"
            />
          </div>
        </div>
      </div>

{
  (data1['count']>0)?<div className="right-footer margin-right-5">
  <div className="no-of-pages">
  <span class="first-no">{((+pageIndex)*(+pageSize))+1}&nbsp;</span>
      <span class="hiphen">-&nbsp;</span>
      <span class="last-no">{(((+pageIndex+1)*(+pageSize))>(+data1['count']))?data1['count']:((+pageIndex+1)*(+pageSize))}&nbsp;</span>
      <span class="of">of&nbsp;</span>
      <span class="counts">{data1['count']}</span>
  </div>
  <span className="prev" onClick={()=>{
    if(pageIndex!=0){
      setPageIndex(pageIndex-1)
    }
  
}}>
    <img
      src="./assets/chevron-grey.png"
      alt="Chevron"
      className={pageIndex==0?"opacity-less chevron-grey-3":"chevron-grey-3"}
    />
  </span>
  <span className="next"  onClick={()=>{
    if(!checknext()){
      setPageIndex(pageIndex+ 1)
    }
  
}}>
    <img
      src="./assets/chevron-grey.png"
      alt="Chevron"
      className={checknext()?"opacity-less chevron-grey-4":"chevron-grey-4"}
    />
  </span>
</div>:""
}
      
    </div>
  </div>
</div>
<DtMap />
<div className={modalClass} onClick={() => { DtStore.modalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>CANCELLATION REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)} value={reason}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                                onClick={()=>{
                                                    declinesubmit()
                                                    
                                                }}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => DtStore.modalVisible = false} >cancel</button>
                </section>
            </div>
        </div>

        </>
    )

}


const DtBookingListClient = (props) => {
 
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default DtBookingListClient;