import { useSnapshot } from "valtio"
import Newapi from "../../state/new-api"
import AsyncSelect from 'react-select/async';
import { loginStore } from "../../state/CommonState";

export default function Dtsearchselect(props) {
    useSnapshot(loginStore)
    const { record, name,keyname, callback = f => f, isValid = true, validationMsg, isDisabled } = props
    let defaultValue = null
    let defaultOptions = []
    if(record[name] && record[name].length>0){
        defaultValue = {value:record[name],label:record[name]}
        defaultOptions.push({value:record[name],label:record[name]})
    }
    
    
    const getdata = async (inputValue)=>{
      if(inputValue && inputValue.length>0){
            let s = inputValue
            let url = `${process.env.REACT_APP_API_SERVER}/api/v1/dt/getsearchlist/${keyname}`
            let results 
            let payload = {}
            if(keyname=='vechicles'){
                let l = ""
                for (let i = 0; i < s.length; i++) {
                    if(i==0){
                        l = l+s[i]
                    }else{
                        l = l+".*"+s[i]
                    }
                }
                payload = {
                    vechicles:record['vehicle'],
                    keyword:l
                }
            }else{
                payload = {
                    keyword:s
                }
            }
            results = await Newapi.post(url,payload)
            return results
        }else{
            return []
        }
    }
      
      const loadOptions = async (
        inputValue,
        callback
      ) => {
        let v = await getdata(inputValue)
        callback(v);
      };

      const change= (e)=>{
        if(e && e.value){
            if(keyname=='driver' && record['tripstatus'] !=undefined && record['tripstatus'].length==0){
                
                record['drivernumber'] = e.value.MobileNumber
                record['driverstatus'] = ""
                record['driverCreatedBy'] = loginStore.email
            }
            console.log(e)
          record[name] = e.value['lable']
        }else{
            console.log(e)
          record[name] = ""
        }
        
      }
   
    return (
        <div className="field">
            <div className= {!isValid?"control is-fullwidth is-danger is-danger-border":"control is-fullwidth"}>
            <AsyncSelect defaultOptions={defaultOptions} defaultValue={defaultValue} isClearable styles={{ menu: (base) => ({ ...base, position: 'relative' }) }} loadOptions={loadOptions} onChange={(val)=>change(val)} />
            </div>
        </div>
    )
}