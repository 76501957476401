import { useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { Col1, Col11, Col12, Col2, Col3, Col4, Col6, Cols } from "../Controls";
import { bookingsStore, configStore, entityStore } from "../state/CommonState";
import { ServerLists } from "../StaticData";
import { OneInput, OneSelect, OneSelect2, OneSelect5, ShipConInput, ShipConSelect } from "./act-common";
import { taskStore } from "./task-store";
import ShipperApi from "../state/shipper-api";
import Utils from "../util/Utils";

export default function SITopForm(props) {
    const [shipperDetails, setShipperDetails] = useState({ _id: "", firstName: "", lastName: "", emailId: "", mobileNumber: "", companyName: "", address: "", pincode: "", country: "", role: "", city: "", state: "", newShipper: false })
    const [shipperList, setShipperList] = useState([])
    const searchListRef = useRef(null);
    const [cecOc, setCecOc] = useState(null)
    const [cecOd, setCecOd] = useState(null)
    const [newShipper, setNewShipper] = useState(false)
    const [showSearchList, setShowSearchList] = useState(false);
    const [shipperSearch, setShipperSearch] = useState('')

    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    let brec = bookingsStore.current
    let task = taskStore.current
    let stage = task.stage
    let p = {
        borderBottom: '1px solid #eeeeee'
    }
    let bold = {
    }
    const filteredShipperList = shipperList?.filter(shipper =>
        shipper.companyName?.toLowerCase().includes(shipperSearch?.toLowerCase())
    );
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchListRef.current && !searchListRef.current.contains(event.target)) {
                setShowSearchList(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const origin = brec.contract.shipmentType.toLowerCase().includes('air') ?
        Utils.extractCountry(brec.contract.originAirport)
        : Utils.extractCountry(brec.contract.portOfReceipt)
        const destination  = Utils.extractCountry(brec.contract?.finalPlaceOfDelivery)
        setCecOc(brec.country === origin);
        setCecOd(brec.country === destination);
        task["newShipper"] = false
        const fetchShipperData = async () => {
            if (brec.contract.shipperConsigneeId || brec.shipperConsigneeId) {
                try {
                    const shipperData = await ShipperApi.getShipperById(brec.contract.shipperConsigneeId || brec.shipperConsigneeId);
                    setShipperDetails(shipperData.data[0]);
                    setShipperSearch(shipperData.data[0].companyName);
                } catch (error) {
                    console.error(error);
                }
            }

                ShipperApi.getShipper(brec.entityId,cecOc? destination: origin)
                    .then((res) => {
                        setShipperList(res.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
        };
        if(cecOc){task["expName"]= brec.company}
        if(cecOd){task["impName"]= brec.company}
        cecOc? task['role'] = "imp": task['role']= "exp"
    
        fetchShipperData();
    },[brec.contract.shipperConsigneeId, cecOc, cecOd, brec.contract, brec.country, brec.origin, task, brec.shipperConsigneeId, brec.entityId, brec.company]);
    
    ;
    const handleSearchChange = (e) => {
        setNewShipper(true)
        task["newShipper"] = true
        setShipperSearch(e.target.value);
        setShowSearchList(true);
        if (!cecOc){
            task['expName'] = e.target.value
        }else if(!cecOd){
            task['impName'] = e.target.value
        }
    };


    return (
        <div>
            {
                ([11, 12].indexOf(stage) == -1) ? <hr /> : <></>
            }
            <Cols>
                <Col6>
                    <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Shipper / Exporter Details</th>
                        </tr>
                        <tr>
                            <td><label className="label">Shipper / Exporter</label></td>
                            <td>
                                <div className="select is-small" style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        className="input is-small"
                                        placeholder="Search Shipper"
                                        value={cecOc ? task['expName'] : shipperSearch}
                                        onChange={handleSearchChange}
                                        onClick={() => setShowSearchList(true)}
                                        disabled={cecOc}
                                    />
                                    {showSearchList && !cecOc && (
                                        <div className="select-menu" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999, backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '0.5rem' }}ref={searchListRef}>
                                            <ul>
                                                {filteredShipperList?.map((shipper, index) => (
                                                    <li key={index} onClick={() => {
                                                        setNewShipper(false);
                                                        task["newShipper"] = false
                                                        if (!cecOc) {
                                                            task["expName"] = shipper.companyName;
                                                            setShipperDetails(shipper);
                                                            setShipperSearch(shipper.companyName);
                                                        }
                                                        task["expName"] = shipper.companyName;
                                                        setShowSearchList(false); // Close search list on click
                                                    }}>
                                                        {shipper.companyName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <ShipConInput label='Address'disabled={!cecOc && !newShipper} name='expAddress' val={cecOc ? brec.address : shipperDetails.address} colSpan='3' />
                            <ShipConInput label='City'disabled={!cecOc && !newShipper} name='expCity' val={cecOc ? brec.city : shipperDetails.city} colSpan='3' />
                        </tr>
                        <tr>
                            <ShipConInput label='State' disabled={!cecOc && !newShipper} name='expState' val={cecOc ? brec.state : shipperDetails.state} colSpan='3' />
                            <ShipConInput label='PIN/ZIP Code'disabled={!cecOc && !newShipper} name='expZip' val={cecOc ? brec.pin : shipperDetails.pincode} colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='expCountry' /> */}
                            <ShipConSelect label='Country'disabled={!cecOc && !newShipper} name='expCountry' val={cecOc ? brec.country : shipperDetails.country} dataList={ServerLists.countryNameList} />
                            <OneInput label='Tax ID #' name='expTaxid' />
                        </tr>

                        <tr>
                            <ShipConInput label='Contact Person'disabled={!cecOc && !newShipper} val={cecOc ? brec.firstName + ' ' + brec.lastName : shipperDetails.firstName = ' ' + shipperDetails.lastName} name='expContactPerson' />
                            <ShipConInput label='Contact Number' disabled={!cecOc && !newShipper} val={cecOc ? brec.phone : shipperDetails.mobileNumber} name='expContactNumber' message={true} />
                        </tr>
                        <tr>
                            <ShipConInput label='Email ID' disabled={!cecOc && !newShipper} val={cecOc ? brec.emailid : shipperDetails.emailId} name='expEmail' colSpan='3' />
                        </tr>


                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Notify Party 1 Details</th>
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Name' name='party1Name' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Address' name='party1Address' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='City' name='party1City' />
                            <OneInput label='State' name='party1State' />
                        </tr>
                        <tr>
                            <OneInput label='Zip Code' name='party1Zip' colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='party1Country' colSpan='3' /> */}
                            <OneSelect label='Country' name='party1Country' dataList={ServerLists.countryNameList} colSpan={3} />
                        </tr>
                        <tr>
                            <OneInput label='Contact Person' name='party1ContactPerson' />
                            <OneInput label='Contact Number' name='party1ContactNumber' message={true} />
                        </tr>
                        <tr>
                            <OneInput label='Email ID' name='party1Email' colSpan='3' />
                        </tr>
                    </table>
                </Col6>
                <Col6>
                {!(!cecOc && !cecOd) ?
                    <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Consignee / Importer Details</th>
                        </tr>
                        <tr>
                            <td><label className="label" >Consiginee / Importer</label></td>
                            <td>
                                <div className="select is-small" style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        className="input is-small"
                                        placeholder="Search Shipper"
                                        value={cecOd ? task["impName"] : shipperSearch}
                                        onChange={handleSearchChange}
                                        onClick={() => setShowSearchList(true)}
                                        disabled={cecOd}
                                    />
                                    {showSearchList && !cecOd && (
                                        <div className="select-menu" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999, backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '0.5rem' }} ref={searchListRef}>
                                            <ul>
                                                {filteredShipperList?.map((shipper, index) => (
                                                    <li key={index} onClick={() => {
                                                        setNewShipper(false);
                                                        task["newShipper"] = false
                                                        if (!cecOd) {
                                                            setShipperDetails(shipper);
                                                            setShipperSearch(shipper.companyName);
                                                        }
                                                        task["impName"] = shipper.companyName;
                                                        setShowSearchList(false); // Close search list on click
                                                    }}>
                                                        {shipper.companyName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <ShipConInput label='Address' name='impAddress' disabled={!cecOd && !newShipper} val={cecOd ? brec.address : shipperDetails.address} colSpan='3' />
                            <ShipConInput label='City' name='impCity' disabled={!cecOd && !newShipper} val={cecOd ? brec.city : shipperDetails.city} colSpan='3' />
                        </tr>
                        <tr>
                            <ShipConInput label='State' name='impState' disabled={!cecOd && !newShipper} val={cecOd ? brec.state : shipperDetails.state} colSpan='3' />
                            <ShipConInput label='PIN/ZIP Code'disabled={!cecOd && !newShipper} name='impZip' val={cecOd ? brec.pin : shipperDetails.pincode} colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='expCountry' /> */}
                            <ShipConSelect label='Country' name='impCountry' disabled={!cecOd && !newShipper} val={cecOd ? brec.country : shipperDetails.country} dataList={ServerLists.countryNameList} />
                            <OneInput label='Tax ID #' name='impTaxid' />
                        </tr>

                        <tr>
                            <ShipConInput label='Contact Person' disabled={!cecOd && !newShipper} val={cecOd ? brec.firstName + ' ' + brec.lastName : shipperDetails.firstName = ' ' + shipperDetails.lastName} name='impContactPerson' />
                            <ShipConInput label='Contact Number' disabled={!cecOd && !newShipper} val={cecOd ? brec.phone : shipperDetails.mobileNumber} name='impContactNumber' message={true} />
                        </tr>
                        <tr>
                            <ShipConInput label='Email ID' disabled={!cecOd && !newShipper} val={cecOd ? brec.emailid : shipperDetails.emailId} name='impEmail' colSpan='3' />
                        </tr>
                        <tr >
                            <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Notify Party 2 Details</th>
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Name' name='party2Name' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='Notify Party Address' name='party2Address' colSpan='3' />
                        </tr>
                        <tr>
                            <OneInput label='City' name='party2City' />
                            <OneInput label='State' name='party2State' />
                        </tr>
                        <tr>
                            <OneInput label='Zip Code' name='party2Zip' colSpan='3' />
                        </tr>
                        <tr>
                            {/* <OneInput label='Country' name='party2Country' colSpan='3' /> */}
                            <OneSelect label='Country' name='party2Country' dataList={ServerLists.countryNameList} colSpan={3} />

                        </tr>
                        <tr>
                            <OneInput label='Contact Person' name='party2ContactPerson' />
                            <OneInput label='Contact Number' name='party2ContactNumber' message={true} />
                        </tr>
                        <tr>
                            <OneInput label='Email ID' name='party2Email' colSpan='3' />
                        </tr>

                    </table>:
                   <table className="table is-fullwidth is-bordered is-narrow" style={{ fontSize: '0.85rem' }}>
                   <tr >
                       <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Consignee / Importer Details</th>
                   </tr>
                   <tr>
                       <OneInput label='Consignee/Importer' name='impName' colSpan='3' />
                   </tr>
                   <tr>
                       <OneInput label='Address' name='impAddress' colSpan='3' />
                   </tr>
                   <tr>
                       <OneInput label='City' name='impCity' />
                       <OneInput label='State' name='impState' />
                   </tr>
                   <tr>
                       <OneInput label='PIN/ZIP Code' name='impZip' colSpan='3' />
                   </tr>
                   <tr>
                       {/* <OneInput label='Country' name='impCountry' /> */}
                       {/* <select className="select is-fullwidth" value={entity.country}
                           onChange={(e) => entity.country = e.target.value} >
                           {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                       </select> */}
                       <OneSelect5 label='Country' name='impCountry' dataList={ServerLists.countryNameList}/>

                       <OneInput label='Tax ID #' name='impTaxid' />
                   </tr>
                   <tr>
                       <OneInput label='Contact Person' name='impContactPerson' />
                       <OneInput label='Contact Number' name='impContactNumber' message={true}/>
                   </tr>
                   <tr>
                       <OneInput label='Email ID' name='impEmail' colSpan='3' />
                   </tr>
                   <tr >
                       <th colSpan={10} style={{ backgroundColor: '#ccccff', textAlign: 'center' }}>Notify Party 2 Details</th>
                   </tr>
                   <tr>
                       <OneInput label='Notify Party Name' name='party2Name' colSpan='3' />
                   </tr>
                   <tr>
                       <OneInput label='Notify Party Address' name='party2Address' colSpan='3' />
                   </tr>
                   <tr>
                       <OneInput label='City' name='party2City' />
                       <OneInput label='State' name='party2State' />
                   </tr>
                   <tr>
                       <OneInput label='Zip Code' name='party2Zip' colSpan='3' />
                   </tr>
                   <tr>
                       {/* <OneInput label='Country' name='party2Country' colSpan='3' /> */}
                       <OneSelect label='Country' name='party2Country' dataList={ServerLists.countryNameList} colSpan={3}/>

                   </tr>
                   <tr>
                       <OneInput label='Contact Person' name='party2ContactPerson' />
                       <OneInput label='Contact Number' name='party2ContactNumber' message={true} />
                   </tr>
                   <tr>
                       <OneInput label='Email ID' name='party2Email' colSpan='3' />
                   </tr>

               </table>

}
                </Col6>
            </Cols>
        </div>
    )
}