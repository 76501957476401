import Api from "./Api";
import {
  configStore,
  routerStore,
  branchStore,
  DBbranchStore,
  EntityV2Store
} from "./CommonState";
import { BranchType } from "./Types";
import EntityV2Api from "./entities-api";

const BRANCHURI = {
  POST: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/branch/new`,
  GET: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/branch/${id}`,
  PUT: (id) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/branch/${id}`,
  // PUT: (id) =>
  // `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/branch/status/DZGF_C_MM_42_128?status=rejected/${id}`,
  CHECKGST: (data) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/gst/${data}`,
  GETALLBRANCHES: (entityId) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/list/branches/${entityId}`,
}; //stakeholder/list/branches/DRDO_C_MM_29

//branch/status/DZGF_C_MM_42_128?status=rejected
export default class BranchApi {
  static saveDriver() {
    console.log("about to call api.post");
    Api.put(BRANCHURI.PUT, branchStore.BranchRec, (data) => {
      configStore.setModalMessage("Branch Details Saved");
    });
    routerStore.branchCurrentView = "list";
  }

  static createBranch(setloading , data , callback) {
    console.log("about to call api.post");
    let DATA 
    if(data){
      DATA= data
    }else {
      DATA = branchStore.BranchRec
    }
    Api.post(BRANCHURI.POST, DATA, (data) => {
      console.log("datacr++", data);
      if (data) {
        if(callback){
          return callback(data)
        }
        setloading(false);
        configStore.setModalMessage("Branch Created Successfully");
        branchStore.BranchRec.set(new BranchType());
        routerStore.branchCurrentView = "list";
      }
    });
  }

    static async  viewBranch(branchID,callback) {
        console.log('about to call api.get')
      await  Api.fetch(BRANCHURI.GET(branchID), async(data) => {
            console.log("dataviewbranch", data.data)
            if(callback){
            // const entData =   await  EntityV2Api.viewEntity(data.data.entityId)
              // EntityV2Store.EntityRec.set(entData)
              callback(data)  
            }        
            branchStore.BranchRec.set(data.data); 
            DBbranchStore.BranchRec.set(data.data);  
        })
    }
   
    static  async updateBranch(branchID, Branchgst ,setloading ,callback , data) {
      let DATA 
      if(data){
        DATA= data
      }else {
        DATA = branchStore.BranchRec
      }
      await  console.log('about to call api.put')
         Api.put(BRANCHURI.PUT(branchID), DATA, (data) => {
            console.log(data);
            if(data){
              if (callback) {
                callback(data)
              }
            // console.log("data", data.data)   
            setloading(false)
            routerStore.branchCurrentView = "list";     
            }
        })
    
    }

    static  checkGst(data, callback = (f) => f) {
      console.log("about to call check pan number api");
      Api.fetch(BRANCHURI.CHECKGST(data), (response) => {
          if(response?.data?.branchId !== branchStore?.BranchRec?.branchId){
              callback(response);
            }
        });
    }

    static  getAllBranches(entityId, callback = (f) => f, r) {
      console.log("about to call get branch list api");
       Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/list/branches/${entityId}`, (response) => {
          if(response){
            
              callback(response.results);
              if(r === 'return'){
                return response.results
              }
            }
        });
    }
    static  getAllBranchesPricing(entityId, callback = (f) => f, r) {
      console.log("about to call get branch list api");
       Api.fetch(`${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/stakeholder/list/branches/${entityId}`, (response) => {
          if(response){
            
              callback(response.results);
              if(r === 'return'){
                return response.results
              }
            }
        });
    }
}