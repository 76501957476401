import { proxy } from 'valtio'
import Utils from '../util/Utils'
let reportStore = proxy({
    entities_from_date: Utils.getCurrentDateString(),
    entities_to_date:Utils.getCurrentDateString(),
    users_from_date: Utils.getCurrentDateString(),
    users_to_date:Utils.getCurrentDateString(),
    dsr_myaction_from_date: Utils.getCurrentDateString(),
    dsr_myaction_to_date:Utils.getCurrentDateString(),
    dsr_not_update_from_date: Utils.getCurrentDateString(),
    dsr_not_update_to_date:Utils.getCurrentDateString(),
    my_actions_from_date: Utils.getCurrentDateString(),
    my_actions_to_date:Utils.getCurrentDateString(),
    enquiry_from_date: Utils.getCurrentDateString(),
    enquiry_to_date:Utils.getCurrentDateString(),
    quotation_report_from_date: Utils.getCurrentDateString(),
    quotation_report_to_date:Utils.getCurrentDateString(),
    pricing_report_from_date: Utils.getCurrentDateString(),
    pricing_report_to_date:Utils.getCurrentDateString(),
    fclpricing_report_from_date: Utils.getCurrentDateString(),
    fclpricing_report_to_date:Utils.getCurrentDateString(),
    airpricing_report_from_date: Utils.getCurrentDateString(),
    airpricing_report_to_date:Utils.getCurrentDateString(),
    sales_report_from_date:Utils.getCurrentDateString(),
    sales_report_to_date:Utils.getCurrentDateString(),
    dt_report_from_date: Utils.getCurrentDateString(),
    dt_report_to_date:Utils.getCurrentDateString(),
    payout_from_date:Utils.getCurrentDateString(),
    payout_to_date:Utils.getCurrentDateString(),
    login_from_date:Utils.getCurrentDateString(),
    login_to_date:Utils.getCurrentDateString(),
    sales_manager:"",
    phonepe_report_from_date: Utils.getCurrentDateString(),
    phonepe_report_to_date:Utils.getCurrentDateString(),
    phonepe_report_from_date1: Utils.getCurrentDateString(),
    phonepe_report_to_date1:Utils.getCurrentDateString(),

})

export {
    reportStore
}

