import { useEffect, useLayoutEffect, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import Api from "../state/Api"
import { bookingsStore, configStore, contractsStore, entityStore, loginStore } from "../state/CommonState"
import EnquiriesApi from "../state/enq-api"
import { useGlobalState } from "../GlobalState"
import { AgInput } from "../components/AgComponents"
import MonApi from "../state/mongo-api"
import TaskTrigger from "../mytasks/task-trigger"
import { taskStore } from "../mytasks/task-store"
import { Task } from "../mytasks/tasks-api"
import Newapi from "../state/new-api"
import { myactionsstore } from "../my-actions/store-myactions"
import { Visibility } from "../my-actions/visibility"
import { VisibilityGraph } from "../my-actions/visibilitygraph"
import BookingSchedule from "../pricing/booking.schedule"
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import CarrierWebViewv2 from "./carrierweb-view-v2"
import HomescreenCargov2 from "./homescreen-cargo-v2"
import HomescreenContainerv2 from "./homescreen-container-v2"
import HomescreenRoutev2 from "./homescreen-route-v2"
import { enquiryStorev2 } from "./enquiry-data-v2"
import { IconButton } from "@mui/material"
import backarrow  from "../assets/images/backarrow.svg";
import EntityApi from "../state/entity-api"
import EntityV2Api from "../state/entities-api"
import AguserApi from "../state/AguserApi"
import CarrierWebView from "../enquiry/carrierweb-view"
import Utils from "../util/Utils"

export default function ViewEnquiryv2(props) {
    const [space, setSpace] = useState('')
    const [graphList, setGraphList] = useState([])
    const [checkgraphList, setCheckGraphList] = useState(false)
    const [prList, setPrList] = useState([])
    const getExchangerates = async( ) =>{
        await Api.getExchangerates()
      }
      const getpr = (d)=>{
        let l = d
        for (let i = 0; i < prList.length; i++) {
            if(d==prList[i]['email']){
                l = `${prList[i]['firstName']} ${prList[i]['lastName']} (${prList[i]['email']})`
            }
        }
        return l
    }
    useLayoutEffect(() => {
        getExchangerates()
        setTimeout(() => {
            setSpace(' ')
        }, 300);
        AguserApi.getUsers4role_new("Procurement", setPrList)
    }, [])
    useSnapshot(contractsStore)
    useSnapshot(enquiryStorev2)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    useSnapshot(taskStore)
    useSnapshot(myactionsstore)
    const { changeCurrentMenu } = useGlobalState()
    const shipConsMigratDate = new Date("2024-05-28");
    const rec = contractsStore.current
    let e = enquiryStorev2.current
    let activeProduct = e.shipmentType.substr(0, 3).toLowerCase()
    console.log(enquiryStorev2.current)
    //console.log('>>>>>>>>>>>', rec, props.viewonly)
    /*if (!rec._id && props.viewonly) return <></>
    let title = `Create Booking - ${rec._id}`
    if (!props.viewonly) title = 'Enquiries'*/
    let title
    if (props.showbooking) {
        if (!bookingsStore.current.erpJobId) bookingsStore.current.erpJobId = ''
        title = 'Booking Detail - ' + bookingsStore.current._id
        configStore.homescreenCurrentShipmentScope = contractsStore.current.shipmentScope
    } else {
        title = 'Enquiry Detail - ' + enquiryStorev2.current._id
    }
    let displayCargo4fcl = false
    {
        let e = enquiryStorev2.current
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        configStore.homescreenCurrentShipmentType = stype
        if (stype === 'fcl' && e.shipmentScope === 'Port-to-Door') {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStorev2.current.containerDetails)
            if (e.destuffingType === 'Dock')
                displayCargo4fcl = true
        }
        else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStorev2.current.containerDetails)
            if (e.stuffingType === 'Dock')
                displayCargo4fcl = true
        }
        else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Door') {
            displayCargo4fcl = true
            if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory')
                displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStorev2?.current?.containerDetails)
        }else if(stype==='fcl'){
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStorev2.current.containerDetails)
        }
        //console.log('#########>>>', stype, displayCargo4fcl)
    }
    useEffect(() => {
        
        const unsubscribe = subscribe(myactionsstore, () => {
            let graphList1 = []
            for (let i = 0; i < myactionsstore.list.length; i++) {
                let rec = myactionsstore.list[i]
                if (rec['booking']['containershow'] == undefined) {
                    rec['booking']['containershow'] = -1
                }
                console.log(rec, "++++")
                let oneGraph = []
                oneGraph = Visibility.GraphLine(rec)
                // if (rec.shipmentType !== 'Air') oneGraph = Visibility.oceanGraphLine(rec)
                // else oneGraph = Visibility.airGraphLine(rec)
                let activeOn = false
                let lastactive = -1
                for (let i = oneGraph.length - 1; i >= 0; i--) {
                    let node = oneGraph[i]
                    if (rec.status_closed == 'Y' || rec.status_closed == 'Yes' || rec.booking.status == 'ARCHIVED') {
                        node.isActive = true
                    }
                    if (node.isActive && !activeOn) {
                        node.activelines = 1
                        lastactive = i
                    }
                    if (node.isActive) activeOn = true
                    if (activeOn && !node.isActive) node.isActive = true
                    if (node.isActive && i < lastactive) node.activelines = 2
                }
                graphList1.push(oneGraph)
            }

            setGraphList(graphList1)
        })
        return unsubscribe
    }, [myactionsstore])
    if ((bookingsStore.current.status == 'ARCHIVED' || bookingsStore.current.status == 'INPROGRESS') && graphList.length == 0) {

        console.log(myactionsstore)
        console.log(graphList)
        if (checkgraphList == false) {
            Newapi.getAllDsr4Displaybyid(bookingsStore.current._id)
            setCheckGraphList(true)
        }

    } else {

        console.log(myactionsstore)
        console.log(graphList)
    }

    if (taskStore.triggerActionVisible) return <TaskTrigger />
    return (
        <>
            {space}
            <div className="columns">
                <div className="column"  style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                { loginStore.isClient() &&  <IconButton disableRipple onClick={()=>{
                 bookingsStore.currentBookingsListComponent = "list"
            }}>
                <img src={backarrow}/>
            </IconButton> }
                    <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                        {title}
                    </h5>
                    {/* { loginStore.isClient() && <button  className="button is-small islink"
                                onClick={ (e) => {
                                     bookingsStore.currentBookingsListComponent = "list"
                                }}>
                                <span className="icon ">
                                    <i className="fas fa-xmark"></i>
                                </span>
                            </button>
} */}
                    <></>
                </div>
                <div className="column is-1">
                    {
                        (props.showbooking && !loginStore.isClient() && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.BOOKING_ACTION)) ?
                            <button disabled={!bookingsStore.current.contract} style={{ marginTop: '10px' }} className="button islink"
                                onClick={async(e) => {
                                   EntityV2Api.checkMyactionEnt(bookingsStore.current.entityId ,bookingsStore.current.branchId , (data) => {
                                    if(data.status === 200) {
                                        taskStore.current = new Task('')
                                    taskStore.current.initialize(bookingsStore.current, entityStore.entityRec)
                                    taskStore.triggerActionVisible = true
                                    }
                                  } )
                                }}>Action</button>
                            : <></>
                    }
                </div>
                <div className="column is-3">
                    {
                        (!loginStore.isClient() && props.showbooking) ?
                            <div style={!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CB_ERP_SHIPMENT_NUMBER) ? { display: 'flex', justifyContent: 'center', pointerEvents: 'none', opacity: 0.6 } : { display: 'flex', justifyContent: 'center' }}>
                                <span>
                                    <AgInput label='ERP Shipment #' record={bookingsStore.current}
                                        name='erpJobId' />
                                </span>
                                <span>
                                    <button className="button is-small islink"
                                        style={{ marginTop: '1rem' }}
                                        onClick={async () => {
                                            try {
                                                // if (!(/^([A-Z]){3}([/])([A-Z]){2}([/])\d{2}([/])\d{2}([/])([A-Z]){1}([/])\d{4}/.test(bookingsStore.current.erpJobId))) {
                                                //     configStore.setModalMessage('ERP Job ID is not valid.')
                                                //     return;
                                                // }
                                                if(bookingsStore.current.erpJobId && bookingsStore.current.erpJobId.trim().length==0){
                                                    configStore.setModalMessage('Please Enter ERP Job ID')
                                                    return;
                                                }
                                                let isBookingExistsWithSame = await MonApi
                                                    .find('Bookings', { erpJobId: bookingsStore.current.erpJobId }, { _id: 1 })
                                                    .then(res => Array.isArray(res) && res?.length > 0);
                                                if (isBookingExistsWithSame) {
                                                    configStore.setModalMessage('ERP Job ID with booking is already exists.')
                                                    return;
                                                }
                                                let ret = await MonApi.updateOne('Bookings',
                                                    { _id: bookingsStore.current._id },
                                                    { erpJobId: bookingsStore.current.erpJobId })
                                                if (ret.error) configStore.setModalMessage('' + ret.error)
                                                else configStore.setModalMessage('ERP Job ID updated successfully.')
                                            } catch (e) {
                                                configStore.setModalMessage('Error trying to save ERP ID')
                                            }
                                        }}>
                                        Save
                                    </button>
                                </span>
                            </div>
                            : <></>
                    }
                </div>
            </div>
            
            <div className="columns">
                <div className="column is-4">
                    <HomescreenRoutev2 {...props} pureview='1' />
                    <button style={{ float: 'left', marginTop:"10px" }}
                                className="button is-rounded is-small islink" onClick={() => {
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc']?.map(e => customTC.push(e))
                                    rec['temptc']?.map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                }}>Terms and Conditions</button>
                </div>
                <div className="column is-8">
                    {
                        (activeProduct === 'fcl') ?
                            <HomescreenContainerv2 {...props} pureview='1' /> :
                            <HomescreenCargov2 {...props} pureview='1' />
                    }
                    {  
                (!displayCargo4fcl && !loginStore.isClient() && bookingsStore.current && bookingsStore.current.contract && bookingsStore.current.contract.procurement!=undefined && bookingsStore.current.contract.procurement.length>0) ?
                    <div className="columns mt-3">
                        <div className="column is-12">
                        <strong>Procurement :</strong> {getpr(bookingsStore.current.contract.procurement)}
                        </div>
                    </div> : <></>
            }
                </div> 
            </div>
            
            {
                (displayCargo4fcl) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <HomescreenCargov2 pureview='1' {...props} displayCargo4fcl={true} />
                          
                            {
                (!loginStore.isClient() && bookingsStore.current && bookingsStore.current.contract && bookingsStore.current.contract.procurement!=undefined && bookingsStore.current.contract.procurement.length>0) ?
                    <div className="columns mt-3">
                        <div className="column is-12">
                        <strong>Procurement :</strong> {getpr(bookingsStore.current.contract.procurement)}
                        </div>
                    </div> : <></>
            }
                        </div>
                    </div> : <></>
            }
            {
                (!loginStore.isClient() && bookingsStore.current.createdFromPricing) ? 
                    <BookingSchedule booking={bookingsStore.current} /> : 
                    <></>
            }

            {
                (props.showbooking) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (new Date (bookingsStore.current.bookingDate) >= shipConsMigratDate)?
                                <CarrierWebViewv2 showbooking={props.showbooking} />:<CarrierWebView showbooking={props.showbooking} />
                            }
                        </div>
                    </div>
                    : <></>
            }
            {
                (!props.showbooking && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENQUIRYTOQUOTATION)) ?
                    <div className="columns">
                        <div className="column is-10">
                            <button style={{ float: 'right' }} onClick={() => {
                                configStore.setModalMessage('Generating quotation from enquiry')
                                EnquiriesApi.updateToQuote(e._id, () => {
                                    Api.getQuote(e._id)
                                    changeCurrentMenu('Quotation')
                                    configStore.quoteCurrentView = 'edit';
                                    configStore.modalVisible = false
                                })

                            }}
                                className="button is-small is-rounded islink">
                                Generate Quotation from this Enquiry
                            </button>
                        </div>
                    </div>
                    : <></>
            }

            {
                (((bookingsStore.current.status == 'ARCHIVED' || bookingsStore.current.status == 'INPROGRESS') && graphList.length != 0)) ? <>
                    <hr />
                    <div>
                        <div className="box is-fullwidth">
                            {
                                graphList.map((e, i) => {
                                    let e1 = myactionsstore.list[i]
                                    return <VisibilityGraph graphNodes={e} dsrRec={e1} hideedit={true} />
                                })
                            }
                        </div>
                    </div>
                    {/* <h1 style={{textAlign:"center"}}><strong>DSR</strong></h1>
                    <ShipmentGraph graphNodes={graphList[0]} /> */}
                </> : <></>
            }
            
        </>
    )
}