import Api from "../state/Api"

const SITEINFORMATIONURI = {
    MAPLIST: `${process.env.REACT_APP_API_SERVER}/api/v1/siteinformation/map`
}
export default class SiteApi {
    static map(callback = f => f){
        Api.fetch(SITEINFORMATIONURI.MAPLIST,(data)=>{
            callback(data)
        })
    }
}