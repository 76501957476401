let sample = {
    "fields": {
        "tataAce": "Tata Ace",
        "bolero": "Bolero",
        "trailer14": "14 Foot Trailer",
        "tata407": "Tata 407",
        "trailer20": "20 Foot Trailer",
        "trailer24": "24 Foot Trailer",
        "sxl32": "32 Foot SXL",
        "mxl32": "32 Foot MXL"
    },
    "fieldkeys": ["tataAce", "bolero", "trailer14", "tata407", "trailer20", "trailer24", "sxl32", "mxl32"
    ],
    "tataAce": 0,
    "bolero": 0,
    "trailer14": 0,
    "tata407": 0,
    "trailer20": 0,
    "trailer24": 0,
    "sxl32": 0,
    "mxl32": 0
}
export default class PricingUtils {
    static getTotalVehicleCount(vehicleDetails) {
        let keys = vehicleDetails.fieldkeys
        let total = 0
        keys.forEach(e => {
            let count = vehicleDetails[e]?Number(vehicleDetails[e]):0
            total += count
        })
        if (total <= 0) {
            vehicleDetails['trailer14'] = 1
            total = 1
        }
        return total
    }
} 