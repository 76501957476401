import { useRef } from "react"
import Api from "./state/Api"
import { configStore } from "./state/CommonState"

export default function AddCustomer() {
    const txtEntityName = useRef()
    const txtGst = useRef()
    return (
        <div className="field is-horizontal" style={{border: '1px solid black'}}>
            <div className="field-label is-normal">
                <label className="label">Entity</label>
            </div>
            <div className="field-body">
                <div className="field">
                    <p className="control is-expanded has-icons-left">
                        <input ref={txtEntityName} className="input" type="text" placeholder="Name"/>
                    </p>
                </div>
            </div>
            <div className="field-label is-normal">
                <label className="label">GST</label>
            </div>
            <div className="field-body">
                <div className="field">
                    <p className="control is-expanded has-icons-left">
                        <input ref={txtGst} className="input" type="text" placeholder="GST"/>
                    </p>
                </div>
            </div>
            <div className="field-body">
                <div className="field">
                    <p className="control is-expanded has-icons-left has-text-centered">
                        <button className="button is-success is-centered" onClick={() => {
                            Api.addCustomer(txtEntityName.current.value, txtGst.current.value)
                            configStore.addNewCustomerVisible = false
                        }}>Add</button>
                    </p>
                </div>
            </div>
        </div>
    )
}