import { useSnapshot } from "valtio"
import {
    quoteDataRec, chargesStore1
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
export default function LCLChargesView(props) {
    let isMailBody=props.isMailBody
    let imChargesStore = useSnapshot(chargesStore1)

    const Tn = ({ i, name }) => {
        const e = props.data[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <div style={{ color: '#dddddd', backgroundColor: '#dddddd', border: 'none', width: '5rem' }} >.</div>
            )
        else if (name === 'total') {
            return (
                <span style={{ textAlign: 'right' }}>{Utils.N(e[name], e.currency)}</span>
            )
        } else {
            //console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num != 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    const displayRec = (e) => {
        const perCBM = parseFloat(e.perCBM)
        const perTon = parseFloat(e.perTon)
        const perShipment = parseFloat(e.perShipment)
        const total = parseFloat(e.total)
        console.log(perCBM, perTon, perShipment, total)
        if (perCBM != 0 || perTon != 0 || perShipment != 0 || total != 0) return true
        return false
    }
    if (!props.data) {
        if (props.dummyContract) {
            chargesStore1.list = props.dummyContract.chargesList
        }
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < chargesStore1.list.length; i++) {
            let e = chargesStore1.list[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        return <>
        {
            (listo.length>0)?<LCLChargesView data={listo} chargeType='On Your Account'  dummyContract={props.dummyContract} isMailBody={isMailBody}/>:""
        }
        {
            (listc.length>0)?<LCLChargesView data={listc} 
            chargeType='On Consignee Account' dummyContract={props.dummyContract} isMailBody={isMailBody}/>:""
        }
        {
            (lists.length>0)?<LCLChargesView data={lists} chargeType='On Shipper Account' 
            dummyContract={props.dummyContract} isMailBody={isMailBody}/>:""
        }
        </>
    }
    let grandTotal = 0.0
    let volumeDiscount = 0.0
    props.data.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            volumeDiscount = (e.total * erate)
            if (volumeDiscount > 0) volumeDiscount *= -1
        } else {
            grandTotal += parseFloat(e.total) * erate
        }
        console.log('E.TOTAL***=', e.total, grandTotal, e.currency, erate)
    })
    let counter = 1

    let checkValue = false;
    props.data.forEach(element => {
        if((+element.perCBM) !== 0 || (+element.perShipment) !== 0 || (+element.perTon) !== 0 || (+element.minimum) !== 0 || (+element.maximum) !== 0 || (+element.total) !== 0){
            checkValue = true;
        }
    });
    return (
        <>
            {checkValue ?
            <>
            <hr />
            <div className="table-container">

            <table className="table is-bordered is-striped is-narrow is-hoverable ">
                <thead>
                    <tr>
                        <th colSpan='12'>
                            <h3 className="is-size-5 has-text-centered">{props.chargeType}</h3>
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {
                            (!isMailBody) ?
                                <>
                                    <th colSpan='4' className="has-text-centered">Per Unit</th>
                        <th></th>
                                </>
                                : <></>
                        }
                        
                        <th></th>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <th style={{ width: '20rem' }}>Charge Description</th>
                        <th>Currency</th>
                        {
                            (!isMailBody) ?
                                <>
                                    <th style={{ textAlign: 'center' }}>Per CBM</th>
                                    <th style={{ textAlign: 'center' }}>Per Ton</th>
                                    <th style={{ textAlign: 'center' }}>Minimum</th>
                                    <th style={{ textAlign: 'center' }}>Maximum</th>
                                    <th style={{ textAlign: 'center' }}>Per shipment</th>
                                </>
                                : <></>
                        }
                        
                        <th style={{ textAlign: 'right' }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data
                            .filter(e => e.chargeDescription != 'Volume Discount')
                            .map((e, i) => {
                                {
                                    return (displayRec(e)) ?
                                        <tr key={e.id}>
                                            <td >{counter++}</td>
                                            <td>{e.chargeDescription}</td>
                                            <td>{e.currency}</td>
                                            {
                            (!isMailBody) ?
                                <>
                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perCBM' /></td>
                                            <td style={{ textAlign: 'enter' }}><Tn i={i} name='perTon' /></td>
                                            <td style={{ textAlign: 'center' }}><Tn i={i} name='minimum' /></td>
                                            <td style={{ textAlign: 'center' }}><Tn i={i} name='maximum' /></td>
                                            <td style={{ textAlign: 'center' }}><Tn i={i} name='perShipment' /></td>
                                </>
                                : <></>
                        }
                                            
                                            <td style={{ textAlign: 'right' }}><Tn i={i} name='total' /></td>
                                        </tr> : <></>
                                }
                            }
                            )
                    }
                    <tr>
                    {
                            (!isMailBody) ?
                                <>
                                    <td colSpan={8}></td>
                                </>
                                : <td colSpan={3}></td>
                        }
                        
                        <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal)}</strong></td>
                    </tr>
                    {
                        volumeDiscount != 0 && <>
                        {
                            (!isMailBody)?<tr>
                            <td colSpan={8} style={{ textAlign: 'right' }}>Volume Discount :</td>
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(volumeDiscount)}</strong></td>
                        </tr>:<tr>
                                <td colSpan={3} style={{ textAlign: 'right' }}>Volume Discount :</td>
                                <td style={{ textAlign: 'right' }}><strong>{Utils.N(volumeDiscount).replace("-","")}</strong></td>
                            </tr>
                        }
                        {
                            (!isMailBody)?<tr>
                            <td colSpan={8} style={{ textAlign: 'right' }}>TOTAL :</td>
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal + volumeDiscount)}</strong></td>
                        </tr>:<>
                        {((grandTotal + volumeDiscount)>0)?
                        <tr>
                        <td colSpan={3} style={{ textAlign: 'right' }}>TOTAL :</td>
                        <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal + volumeDiscount)}</strong></td>
                    </tr>:""
                        }
                        </>
                        }
                            
                        </>
                    }
                    <tr>
                        <td colSpan={9} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                    </tr>
                </tbody>
            </table>
        </div></> : <></>
            
    }</>
            
    )
}
