import { Sorting } from "../components/ag-table/ag-sorting"; 

export default function TableHead(props){
    const { headerGroups, sort } = props;
    return(
        <thead className="is-scrollable-header has-background-white-ter is-unselectable">
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            {sort ? <Sorting column={column} /> : <></>}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    )
}

{/* <thead className="is-scrollable-header has-background-white-ter is-unselectable"></thead> */}
