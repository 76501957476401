export const truckJson = {
    "nm": "Optima",
    "mn": "",
    "layers": [
        {
            "ty": 4,
            "nm": "line/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": -1.00000004073083,
            "op": 156.00000635401,
            "ip": 153.000006231818,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        73.5,
                        20,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                192.23,
                                150.114,
                                0
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                183.23,
                                150.614,
                                0
                            ],
                            "t": 154
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                180.23,
                                150.614,
                                0
                            ],
                            "t": 155.000006313279
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            20,
                                            20
                                        ],
                                        [
                                            89,
                                            20
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    56.588,
                                    103.625
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 0
        },
        {
            "ty": 4,
            "nm": "travelling box/Optima_ae Outlines 2",
            "mn": "",
            "sr": 1,
            "st": 24.00000097754,
            "op": 160.000006516934,
            "ip": 149.000006068894,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        42.5,
                        42.5,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        60,
                        60,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                181,
                                137,
                                0
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                136,
                                0
                            ],
                            "t": 156.00000635401
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                1
                            ],
                            "t": 154
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 156.00000635401
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                720
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                307
                            ],
                            "t": 155
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 156.00000635401
                        }
                    ],
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            1.481,
                                            1.638
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.637,
                                            1.481
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.482,
                                            -1.637
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            1.638,
                                            -1.481
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.481,
                                            -1.638
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            1.639,
                                            -1.481
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            1.482,
                                            1.639
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.638,
                                            1.482
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -4.843,
                                            40.113
                                        ],
                                        [
                                            -40.397,
                                            0.806
                                        ],
                                        [
                                            -40.114,
                                            -4.843
                                        ],
                                        [
                                            -0.807,
                                            -40.397
                                        ],
                                        [
                                            4.843,
                                            -40.114
                                        ],
                                        [
                                            40.396,
                                            -0.807
                                        ],
                                        [
                                            40.113,
                                            4.842
                                        ],
                                        [
                                            0.806,
                                            40.396
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0.4824,
                                    0.9137
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    42.128,
                                    42.128
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 1
        },
        {
            "ty": 4,
            "nm": "t4 Outlines",
            "mn": "",
            "sr": 1,
            "st": 27.0000010997325,
            "op": 159.000006476203,
            "ip": 151.000006150356,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        38,
                        38,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        285,
                        200,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                94
                            ],
                            "t": 152
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 156.00000635401
                        }
                    ],
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    37.601,
                                    37.602
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 152
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 156.00000635401
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 2
        },
        {
            "ty": 4,
            "nm": "t3 Outlines",
            "mn": "",
            "sr": 1,
            "st": 27.0000010997325,
            "op": 159.000006476203,
            "ip": 151.000006150356,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        38,
                        38,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        186,
                        200,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.721,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.601,
                                            0.001
                                        ],
                                        [
                                            0,
                                            17.602
                                        ],
                                        [
                                            -17.601,
                                            0.001
                                        ],
                                        [
                                            0,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    37.601,
                                    37.602
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 152
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 156.00000635401
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 3
        },
        {
            "ty": 4,
            "nm": "Shape Layer 1",
            "mn": "",
            "sr": 1,
            "st": 6.00000024438501,
            "op": 100.000004073084,
            "ip": 78.0000031770051,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        200,
                        150.5,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Rectangle 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "rc",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Rect",
                            "nm": "Rectangle Path 1",
                            "d": 1,
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    84.703,
                                    67.092
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    13.852,
                                    -83.954
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 4
        },
        {
            "ty": 4,
            "nm": "1/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 3.00000012219251,
            "op": 100.000004073084,
            "ip": 75.0000030548126,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        40,
                        20,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        220,
                        104.5,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 94,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            12,
                                            20
                                        ],
                                        [
                                            68.5,
                                            20
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 9,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 5
        },
        {
            "ty": 4,
            "nm": "b/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 13.0000005295009,
            "op": 151.000006150356,
            "ip": 109.000004439661,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        46.5,
                        46.5,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        115,
                        115,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        200.5,
                        107.001,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            14.379,
                                            0
                                        ],
                                        [
                                            0,
                                            14.379
                                        ],
                                        [
                                            -14.378,
                                            0
                                        ],
                                        [
                                            0,
                                            -14.379
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -14.379,
                                            0
                                        ],
                                        [
                                            0,
                                            -14.379
                                        ],
                                        [
                                            14.379,
                                            0
                                        ],
                                        [
                                            0,
                                            14.379
                                        ]
                                    ],
                                    "v": [
                                        [
                                            0,
                                            26.077
                                        ],
                                        [
                                            -26.076,
                                            -0.001
                                        ],
                                        [
                                            0,
                                            -26.077
                                        ],
                                        [
                                            26.076,
                                            0.001
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    46.076,
                                    46.076
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 109
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 115
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 145
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 151.000006150356
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 6
        },
        {
            "ty": 4,
            "nm": "a/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 16.0000006516934,
            "op": 159.000006476203,
            "ip": 106.000004317469,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        72,
                        92,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                200,
                                168,
                                0
                            ],
                            "t": 106
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                200,
                                125,
                                0
                            ],
                            "t": 111.000004521123
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            28.579,
                                            0
                                        ],
                                        [
                                            0,
                                            -28.579
                                        ],
                                        [
                                            -1.974,
                                            -2.199
                                        ],
                                        [
                                            -1.852,
                                            2.063
                                        ],
                                        [
                                            0,
                                            35.467
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -28.579,
                                            0
                                        ],
                                        [
                                            0,
                                            35.467
                                        ],
                                        [
                                            1.856,
                                            2.066
                                        ],
                                        [
                                            1.974,
                                            -2.199
                                        ],
                                        [
                                            -0.001,
                                            -28.579
                                        ]
                                    ],
                                    "v": [
                                        [
                                            0,
                                            -71.814
                                        ],
                                        [
                                            -51.83,
                                            -19.985
                                        ],
                                        [
                                            -3.473,
                                            69.749
                                        ],
                                        [
                                            3.473,
                                            69.749
                                        ],
                                        [
                                            51.83,
                                            -19.985
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    71.83,
                                    91.814
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 0,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 106
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 111
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 145
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 152.000006191087
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 7
        },
        {
            "ty": 4,
            "nm": "road/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": -3.00000012219251,
            "op": 147.000005987433,
            "ip": 107.000004358199,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        88,
                        74,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        200,
                        196,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            15.946,
                                            -0.406
                                        ],
                                        [
                                            9.602,
                                            -2.624
                                        ],
                                        [
                                            -15.689,
                                            -6.193
                                        ],
                                        [
                                            -12.341,
                                            -2.577
                                        ],
                                        [
                                            1.687,
                                            -17.182
                                        ],
                                        [
                                            11.365,
                                            -0.02
                                        ],
                                        [
                                            0.965,
                                            -27.635
                                        ],
                                        [
                                            -8.012,
                                            0.574
                                        ]
                                    ],
                                    "o": [
                                        [
                                            -15.887,
                                            -1.083
                                        ],
                                        [
                                            -9.86,
                                            0.251
                                        ],
                                        [
                                            -13.959,
                                            3.817
                                        ],
                                        [
                                            11.781,
                                            4.651
                                        ],
                                        [
                                            11.351,
                                            2.371
                                        ],
                                        [
                                            -1.695,
                                            17.23
                                        ],
                                        [
                                            -17.898,
                                            0.029
                                        ],
                                        [
                                            -0.339,
                                            9.717
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            56.288,
                                            -51.208
                                        ],
                                        [
                                            8.558,
                                            -53.507
                                        ],
                                        [
                                            -21.186,
                                            -51.01
                                        ],
                                        [
                                            -22.389,
                                            -28.882
                                        ],
                                        [
                                            16.028,
                                            -21.027
                                        ],
                                        [
                                            66.222,
                                            1.781
                                        ],
                                        [
                                            17.929,
                                            16.003
                                        ],
                                        [
                                            -67.57,
                                            39.182
                                        ],
                                        [
                                            -46.799,
                                            53.339
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [
                                {
                                    "nm": "dash",
                                    "mn": "",
                                    "n": "d",
                                    "v": {
                                        "a": 0,
                                        "k": 12,
                                        "ix": 1
                                    }
                                },
                                {
                                    "nm": "offset",
                                    "mn": "",
                                    "n": "o",
                                    "v": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 7
                                    }
                                }
                            ],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    87.909,
                                    73.913
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 123
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 136
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 150.000006109625
                            }
                        ],
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 115
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 123
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 136
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 150.000006109625
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 8
        },
        {
            "ty": 4,
            "nm": "w_circle/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 150.000006109625,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        29.5,
                        32,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        269,
                        156,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            6.627
                                        ],
                                        [
                                            -6.627,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -6.627,
                                            0
                                        ],
                                        [
                                            0,
                                            -6.627
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            8.5,
                                            12
                                        ],
                                        [
                                            2.5,
                                            12
                                        ],
                                        [
                                            -9.5,
                                            0
                                        ],
                                        [
                                            2.5,
                                            -12
                                        ],
                                        [
                                            9.5,
                                            -12
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    29.5,
                                    32
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 57
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 61
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 94
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 97.000003950891
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 9
        },
        {
            "ty": 4,
            "nm": "rupee/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 5.00000020365417,
            "op": 99.0000040323527,
            "ip": 72.0000029326201,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        35,
                        44,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        84,
                        84,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        221,
                        160,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 79
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 94.0000038286985
                        }
                    ],
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -4.239,
                                            2.048
                                        ],
                                        [
                                            -0.167,
                                            3.924
                                        ],
                                        [
                                            8,
                                            2
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            4.57,
                                            0
                                        ],
                                        [
                                            3.403,
                                            -1.645
                                        ],
                                        [
                                            0.234,
                                            -5.482
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            10.325,
                                            24
                                        ],
                                        [
                                            -10.325,
                                            1.025
                                        ],
                                        [
                                            3.402,
                                            -0.666
                                        ],
                                        [
                                            9.977,
                                            -9.582
                                        ],
                                        [
                                            -0.676,
                                            -24
                                        ],
                                        [
                                            -7.073,
                                            -24
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    32.074,
                                    44
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 2",
                    "ix": 2,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            24.398,
                                            32.781
                                        ],
                                        [
                                            49.398,
                                            32.781
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 3",
                    "ix": 3,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            20,
                                            20
                                        ],
                                        [
                                            49.736,
                                            20
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 4,
                    "e": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 72
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 79
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 94
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 97.000003950891
                            }
                        ],
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 0,
                        "k": 0,
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 10
        },
        {
            "ty": 4,
            "nm": "f_right/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 1.00000004073083,
            "op": 56.0000022809268,
            "ip": 38.0000015477717,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        86,
                        47.5,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                256,
                                139.5,
                                0
                            ],
                            "t": 38
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                256,
                                139,
                                0
                            ],
                            "t": 42
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                256,
                                139,
                                0
                            ],
                            "t": 46
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                256,
                                139,
                                0
                            ],
                            "t": 54.0000021994651
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -64.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -116.32,
                                                        -26.292
                                                    ],
                                                    [
                                                        0.912,
                                                        -26.792
                                                    ],
                                                    [
                                                        27.919,
                                                        -27.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 38
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -45.32,
                                                        -61.792
                                                    ],
                                                    [
                                                        55.912,
                                                        -61.792
                                                    ],
                                                    [
                                                        33.92,
                                                        -26.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 40
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -38.32,
                                                        27.208
                                                    ],
                                                    [
                                                        65.912,
                                                        27.208
                                                    ],
                                                    [
                                                        42.92,
                                                        -26.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 42
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -37.32,
                                                        33.208
                                                    ],
                                                    [
                                                        66.912,
                                                        33.208
                                                    ],
                                                    [
                                                        42.92,
                                                        -26.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 43
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -38.32,
                                                        27.208
                                                    ],
                                                    [
                                                        65.912,
                                                        27.208
                                                    ],
                                                    [
                                                        42.92,
                                                        -26.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 46
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -38.32,
                                                        27.208
                                                    ],
                                                    [
                                                        65.912,
                                                        27.208
                                                    ],
                                                    [
                                                        42.92,
                                                        -26.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 54
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -45.32,
                                                        -61.792
                                                    ],
                                                    [
                                                        55.912,
                                                        -61.792
                                                    ],
                                                    [
                                                        33.92,
                                                        -26.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 56
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -65.412,
                                                        -27.208
                                                    ],
                                                    [
                                                        -80.82,
                                                        -44.042
                                                    ],
                                                    [
                                                        13.912,
                                                        -42.792
                                                    ],
                                                    [
                                                        30.92,
                                                        -26.941
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 57
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -64.912,
                                                        -27.208
                                                    ],
                                                    [
                                                        -116.32,
                                                        -26.292
                                                    ],
                                                    [
                                                        0.912,
                                                        -26.792
                                                    ],
                                                    [
                                                        27.919,
                                                        -27.441
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 58.0000023623884
                                    }
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    85.912,
                                    47.208
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 11
        },
        {
            "ty": 4,
            "nm": "travelling box/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 150.000006109625,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        42.5,
                        42.5,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                60,
                                60,
                                100
                            ],
                            "t": 0
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                60,
                                60,
                                100
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                47,
                                47,
                                100
                            ],
                            "t": 25
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                60,
                                60,
                                100
                            ],
                            "t": 39
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                50,
                                50,
                                100
                            ],
                            "t": 79
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                60,
                                60,
                                100
                            ],
                            "t": 87.0000035435826
                        }
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                136,
                                0
                            ],
                            "t": 0
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                136,
                                0
                            ],
                            "t": 9
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                134,
                                0
                            ],
                            "t": 10
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                136,
                                0
                            ],
                            "t": 11
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                136.136,
                                0
                            ],
                            "t": 15
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                137,
                                0
                            ],
                            "t": 16
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                134,
                                0
                            ],
                            "t": 17
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                258,
                                134,
                                0
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                270,
                                136,
                                0
                            ],
                            "t": 26
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                274,
                                145,
                                0
                            ],
                            "t": 27
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                225,
                                149,
                                0
                            ],
                            "t": 39
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                208,
                                90,
                                0
                            ],
                            "t": 40
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                208,
                                28,
                                0
                            ],
                            "t": 47
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                221,
                                123,
                                0
                            ],
                            "t": 54
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                208,
                                142,
                                0
                            ],
                            "t": 55
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                216,
                                117,
                                0
                            ],
                            "t": 79
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                216,
                                186,
                                0
                            ],
                            "t": 87
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                216,
                                71,
                                0
                            ],
                            "t": 99
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                234,
                                96,
                                0
                            ],
                            "t": 101.000004113814
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 27
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 28
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 39
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 40
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 54
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 55
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 78
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 79
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100
                            ],
                            "t": 97
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 98.0000039916218
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 0
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                59
                            ],
                            "t": 25
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                87
                            ],
                            "t": 27
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 39
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                66
                            ],
                            "t": 47
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                360
                            ],
                            "t": 55
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                442
                            ],
                            "t": 87
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                767
                            ],
                            "t": 99.0000040323527
                        }
                    ],
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            1.481,
                                            1.638
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.637,
                                            1.481
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.482,
                                            -1.637
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            1.638,
                                            -1.481
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.481,
                                            -1.638
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            1.639,
                                            -1.481
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            1.482,
                                            1.639
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -1.638,
                                            1.482
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -4.843,
                                            40.113
                                        ],
                                        [
                                            -40.397,
                                            0.806
                                        ],
                                        [
                                            -40.114,
                                            -4.843
                                        ],
                                        [
                                            -0.807,
                                            -40.397
                                        ],
                                        [
                                            4.843,
                                            -40.114
                                        ],
                                        [
                                            40.396,
                                            -0.807
                                        ],
                                        [
                                            40.113,
                                            4.842
                                        ],
                                        [
                                            0.806,
                                            40.396
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0.4824,
                                    0.9137
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    42.128,
                                    42.128
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 12
        },
        {
            "ty": 4,
            "nm": "f_left/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 1.00000004073083,
            "op": 56.0000022809268,
            "ip": 38.0000015477717,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        59.5,
                        47.5,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                151,
                                139,
                                0
                            ],
                            "t": 42
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                151,
                                139,
                                0
                            ],
                            "t": 46
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                151,
                                139,
                                0
                            ],
                            "t": 54.0000021994651
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -26.409
                                                    ],
                                                    [
                                                        59.913,
                                                        -27.558
                                                    ],
                                                    [
                                                        133.996,
                                                        -27.792
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 38
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -24.909
                                                    ],
                                                    [
                                                        -32.087,
                                                        -62.558
                                                    ],
                                                    [
                                                        21.497,
                                                        -62.792
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 40
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -24.909
                                                    ],
                                                    [
                                                        -39.087,
                                                        29.442
                                                    ],
                                                    [
                                                        11.497,
                                                        30.208
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 42
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -24.909
                                                    ],
                                                    [
                                                        -39.087,
                                                        26.442
                                                    ],
                                                    [
                                                        11.497,
                                                        27.208
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 46
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -24.909
                                                    ],
                                                    [
                                                        -39.087,
                                                        26.442
                                                    ],
                                                    [
                                                        11.497,
                                                        27.208
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 54
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -24.909
                                                    ],
                                                    [
                                                        -32.087,
                                                        -62.558
                                                    ],
                                                    [
                                                        21.497,
                                                        -62.792
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 56
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -13.796,
                                                        -26.409
                                                    ],
                                                    [
                                                        59.913,
                                                        -27.558
                                                    ],
                                                    [
                                                        133.996,
                                                        -27.792
                                                    ],
                                                    [
                                                        39.087,
                                                        -27.208
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 58.0000023623884
                                    }
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    59.087,
                                    47.208
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 13
        },
        {
            "ty": 4,
            "nm": "t2/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 28.0000011404634,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        38,
                        38,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100,
                                100,
                                100
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                79.3,
                                79.3,
                                100
                            ],
                            "t": 26
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                69,
                                69,
                                100
                            ],
                            "t": 27
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0,
                                0,
                                100
                            ],
                            "t": 28.0000011404634
                        }
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200,
                                0
                            ],
                            "t": 15
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                192,
                                0
                            ],
                            "t": 16
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                199,
                                0
                            ],
                            "t": 17
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200,
                                0
                            ],
                            "t": 18
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200.027,
                                0
                            ],
                            "t": 19
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200.065,
                                0
                            ],
                            "t": 20
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200.074,
                                0
                            ],
                            "t": 21
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200.071,
                                0
                            ],
                            "t": 22
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200.061,
                                0
                            ],
                            "t": 23
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                287,
                                200.051,
                                0
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                276.999,
                                200.019,
                                0
                            ],
                            "t": 26
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                277,
                                200.006,
                                0
                            ],
                            "t": 27
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                297,
                                200,
                                0
                            ],
                            "t": 28.0000011404634
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    37.601,
                                    37.602
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 14
        },
        {
            "ty": 4,
            "nm": "t1/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 28.0000011404634,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        38,
                        38,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100,
                                100,
                                100
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                69,
                                69,
                                100
                            ],
                            "t": 27
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0,
                                0,
                                100
                            ],
                            "t": 28.0000011404634
                        }
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                189,
                                200,
                                0
                            ],
                            "t": 9
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                189,
                                193,
                                0
                            ],
                            "t": 10
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                189,
                                192,
                                0
                            ],
                            "t": 11
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                189,
                                200,
                                0
                            ],
                            "t": 12
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                189,
                                200,
                                0
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                193.501,
                                200,
                                0
                            ],
                            "t": 25
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                210,
                                200,
                                0
                            ],
                            "t": 26
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                217,
                                200,
                                0
                            ],
                            "t": 27.0000010997325
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.721,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.601,
                                            0.001
                                        ],
                                        [
                                            0,
                                            17.602
                                        ],
                                        [
                                            -17.601,
                                            0.001
                                        ],
                                        [
                                            0,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    37.601,
                                    37.602
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 15
        },
        {
            "ty": 4,
            "nm": "1/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 28.0000011404634,
            "ip": 24.00000097754,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        40,
                        20,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                135,
                                151,
                                0
                            ],
                            "t": 24
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                157,
                                151,
                                0
                            ],
                            "t": 26
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                165,
                                151,
                                0
                            ],
                            "t": 27.0000010997325
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 2,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": false,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            20,
                                            20
                                        ],
                                        [
                                            60,
                                            20
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 24
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 28.0000011404634
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 16
        },
        {
            "ty": 4,
            "nm": "r/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 156.00000635401,
            "ip": 28.0000011404634,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        68,
                        68,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100,
                                100,
                                100
                            ],
                            "t": 56
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 60
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 102
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 149
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100,
                                100,
                                100
                            ],
                            "t": 155.000006313279
                        }
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                240,
                                150,
                                0
                            ],
                            "t": 28
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                240,
                                150,
                                0
                            ],
                            "t": 32
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                240,
                                160,
                                0
                            ],
                            "t": 38
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                240,
                                160,
                                0
                            ],
                            "t": 56
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                221,
                                160,
                                0
                            ],
                            "t": 60
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                221,
                                160,
                                0
                            ],
                            "t": 102
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                183,
                                195,
                                0
                            ],
                            "t": 106
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                183,
                                195,
                                0
                            ],
                            "t": 149
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                221,
                                160,
                                0
                            ],
                            "t": 152
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                193.063,
                                128.204,
                                0
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                200,
                                131,
                                0
                            ],
                            "t": 155.000006313279
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        43.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        -43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        47.978,
                                                        43.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 101
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        43.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        -43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        47.978,
                                                        43.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 102
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.027,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        3.027,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        79.986,
                                                        31.978
                                                    ],
                                                    [
                                                        -44.498,
                                                        31.978
                                                    ],
                                                    [
                                                        -49.978,
                                                        27.978
                                                    ],
                                                    [
                                                        -33.841,
                                                        -43.977
                                                    ],
                                                    [
                                                        -28.362,
                                                        -47.977
                                                    ],
                                                    [
                                                        67.548,
                                                        -47.977
                                                    ],
                                                    [
                                                        73.028,
                                                        -43.977
                                                    ],
                                                    [
                                                        85.465,
                                                        27.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 106
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.027,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        3.027,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        79.986,
                                                        31.978
                                                    ],
                                                    [
                                                        -44.498,
                                                        31.978
                                                    ],
                                                    [
                                                        -49.978,
                                                        27.978
                                                    ],
                                                    [
                                                        -33.841,
                                                        -43.977
                                                    ],
                                                    [
                                                        -28.362,
                                                        -47.977
                                                    ],
                                                    [
                                                        67.548,
                                                        -47.977
                                                    ],
                                                    [
                                                        73.028,
                                                        -43.977
                                                    ],
                                                    [
                                                        85.465,
                                                        27.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 149
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        43.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        -43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        47.978,
                                                        43.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 152
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -11.51,
                                                        5.59
                                                    ],
                                                    [
                                                        4.803,
                                                        -38.228
                                                    ],
                                                    [
                                                        97.37,
                                                        -38.728
                                                    ],
                                                    [
                                                        101.272,
                                                        -28.592
                                                    ],
                                                    [
                                                        101.022,
                                                        64.471
                                                    ],
                                                    [
                                                        86.635,
                                                        68.109
                                                    ],
                                                    [
                                                        -30.728,
                                                        68.609
                                                    ],
                                                    [
                                                        -29.519,
                                                        28.824
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 153
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -16.135,
                                                        20.131
                                                    ],
                                                    [
                                                        18.303,
                                                        -47.978
                                                    ],
                                                    [
                                                        109.37,
                                                        -47.978
                                                    ],
                                                    [
                                                        117.022,
                                                        -41.092
                                                    ],
                                                    [
                                                        117.022,
                                                        62.221
                                                    ],
                                                    [
                                                        110.135,
                                                        69.109
                                                    ],
                                                    [
                                                        -49.978,
                                                        69.109
                                                    ],
                                                    [
                                                        -49.978,
                                                        20.365
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 155.000006313279
                                    }
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    69.978,
                                    67.978
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 0,
                        "k": 1,
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 17
        },
        {
            "ty": 4,
            "nm": "c/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 24.00000097754,
            "op": 160.000006516934,
            "ip": 156.00000635401,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        139,
                        87.5,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        200,
                        150,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    223.563,
                                    137.086
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 2",
                    "ix": 2,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.721,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.601,
                                            0.001
                                        ],
                                        [
                                            0,
                                            17.602
                                        ],
                                        [
                                            -17.601,
                                            0.001
                                        ],
                                        [
                                            0,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    124.843,
                                    137.086
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 3",
                    "ix": 3,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            6.888
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -7.652,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            35.585,
                                            24.489
                                        ],
                                        [
                                            -27.934,
                                            24.489
                                        ],
                                        [
                                            -35.585,
                                            17.601
                                        ],
                                        [
                                            -35.585,
                                            -19.132
                                        ],
                                        [
                                            -27.934,
                                            -24.489
                                        ],
                                        [
                                            35.585,
                                            -24.489
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    55.586,
                                    112.598
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 4",
                    "ix": 4,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -51.768,
                                            34.055
                                        ],
                                        [
                                            -17.872,
                                            -34.055
                                        ],
                                        [
                                            51.768,
                                            -34.055
                                        ],
                                        [
                                            17.331,
                                            34.055
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    107.512,
                                    54.054
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 5",
                    "ix": 5,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            -6.122
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            6.887,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            6.888
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            -7.653,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -14.157,
                                            9.565
                                        ],
                                        [
                                            20.28,
                                            -58.544
                                        ],
                                        [
                                            111.348,
                                            -58.544
                                        ],
                                        [
                                            119,
                                            -51.658
                                        ],
                                        [
                                            119,
                                            51.656
                                        ],
                                        [
                                            112.113,
                                            58.544
                                        ],
                                        [
                                            -111.347,
                                            58.544
                                        ],
                                        [
                                            -119,
                                            51.656
                                        ],
                                        [
                                            -119,
                                            14.921
                                        ],
                                        [
                                            -111.347,
                                            9.565
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    139,
                                    78.544
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 6",
                    "ix": 6,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    151.627,
                                    137.086
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 7",
                    "ix": 7,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    54.438,
                                    137.086
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 18
        },
        {
            "ty": 4,
            "nm": "right/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 29.0000011811942,
            "ip": 24.00000097754,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        103.5,
                        79,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        237,
                        141,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -49.657,
                                                        9.565
                                                    ],
                                                    [
                                                        -15.22,
                                                        -58.544
                                                    ],
                                                    [
                                                        75.848,
                                                        -58.544
                                                    ],
                                                    [
                                                        83.5,
                                                        -51.658
                                                    ],
                                                    [
                                                        83.5,
                                                        51.656
                                                    ],
                                                    [
                                                        76.613,
                                                        58.544
                                                    ],
                                                    [
                                                        -83.5,
                                                        58.544
                                                    ],
                                                    [
                                                        -83.5,
                                                        9.799
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 24
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -47.344,
                                                        2.295
                                                    ],
                                                    [
                                                        -21.97,
                                                        -53.669
                                                    ],
                                                    [
                                                        69.848,
                                                        -53.919
                                                    ],
                                                    [
                                                        75.625,
                                                        -45.408
                                                    ],
                                                    [
                                                        75.5,
                                                        52.781
                                                    ],
                                                    [
                                                        64.863,
                                                        58.044
                                                    ],
                                                    [
                                                        -73.875,
                                                        58.294
                                                    ],
                                                    [
                                                        -73.271,
                                                        12.029
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 25
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -45.032,
                                                        -4.976
                                                    ],
                                                    [
                                                        -28.72,
                                                        -48.794
                                                    ],
                                                    [
                                                        63.848,
                                                        -49.294
                                                    ],
                                                    [
                                                        67.75,
                                                        -39.158
                                                    ],
                                                    [
                                                        67.5,
                                                        53.906
                                                    ],
                                                    [
                                                        53.113,
                                                        57.544
                                                    ],
                                                    [
                                                        -64.25,
                                                        58.044
                                                    ],
                                                    [
                                                        -63.042,
                                                        18.258
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 26
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -42.719,
                                                        -12.247
                                                    ],
                                                    [
                                                        -35.47,
                                                        -43.919
                                                    ],
                                                    [
                                                        57.848,
                                                        -44.669
                                                    ],
                                                    [
                                                        59.875,
                                                        -32.908
                                                    ],
                                                    [
                                                        59.5,
                                                        55.031
                                                    ],
                                                    [
                                                        41.363,
                                                        57.044
                                                    ],
                                                    [
                                                        -54.625,
                                                        57.794
                                                    ],
                                                    [
                                                        -49.062,
                                                        22.487
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 27
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -44.407,
                                                        -34.185
                                                    ],
                                                    [
                                                        -42.22,
                                                        -39.044
                                                    ],
                                                    [
                                                        51.848,
                                                        -40.044
                                                    ],
                                                    [
                                                        52,
                                                        -26.658
                                                    ],
                                                    [
                                                        51.5,
                                                        56.156
                                                    ],
                                                    [
                                                        29.613,
                                                        56.544
                                                    ],
                                                    [
                                                        -45,
                                                        57.544
                                                    ],
                                                    [
                                                        -44.25,
                                                        16.049
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 28
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.122
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        6.887,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -45.032,
                                                        -4.976
                                                    ],
                                                    [
                                                        -28.72,
                                                        -48.794
                                                    ],
                                                    [
                                                        63.848,
                                                        -49.294
                                                    ],
                                                    [
                                                        67.75,
                                                        -39.158
                                                    ],
                                                    [
                                                        67.5,
                                                        53.906
                                                    ],
                                                    [
                                                        53.113,
                                                        57.544
                                                    ],
                                                    [
                                                        -64.25,
                                                        58.044
                                                    ],
                                                    [
                                                        -63.042,
                                                        18.258
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 153.000006231818
                                    }
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    103.5,
                                    78.544
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 19
        },
        {
            "ty": 4,
            "nm": "left/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 28.0000011404634,
            "ip": 24.00000097754,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        90,
                        79,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                151,
                                141,
                                0
                            ],
                            "t": 26
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                151,
                                140,
                                0
                            ],
                            "t": 27.0000010997325
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -4.072,
                                                        21.891
                                                    ],
                                                    [
                                                        0,
                                                        6.888
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        8.36,
                                                        -58.544
                                                    ],
                                                    [
                                                        -34.291,
                                                        10.291
                                                    ],
                                                    [
                                                        -65.568,
                                                        11.066
                                                    ],
                                                    [
                                                        -69.64,
                                                        51.656
                                                    ],
                                                    [
                                                        -61.068,
                                                        58.544
                                                    ],
                                                    [
                                                        10.074,
                                                        58.544
                                                    ],
                                                    [
                                                        86.36,
                                                        -58.544
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 24
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -5.653,
                                                        4.962
                                                    ],
                                                    [
                                                        0,
                                                        6.888
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        12.875,
                                                        -53.419
                                                    ],
                                                    [
                                                        -31.992,
                                                        -9.975
                                                    ],
                                                    [
                                                        -48.987,
                                                        5.576
                                                    ],
                                                    [
                                                        -52.14,
                                                        51.666
                                                    ],
                                                    [
                                                        -44.487,
                                                        58.554
                                                    ],
                                                    [
                                                        25.155,
                                                        58.554
                                                    ],
                                                    [
                                                        76.265,
                                                        -53.419
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 25
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.653,
                                                        9.924
                                                    ],
                                                    [
                                                        0,
                                                        6.888
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        25.75,
                                                        -48.294
                                                    ],
                                                    [
                                                        -7.735,
                                                        0.678
                                                    ],
                                                    [
                                                        -25.987,
                                                        9.587
                                                    ],
                                                    [
                                                        -29.64,
                                                        51.677
                                                    ],
                                                    [
                                                        -21.987,
                                                        58.565
                                                    ],
                                                    [
                                                        48.78,
                                                        58.565
                                                    ],
                                                    [
                                                        82.89,
                                                        -48.294
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 26
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -5.153,
                                                        17.358
                                                    ],
                                                    [
                                                        0,
                                                        6.888
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        38.625,
                                                        -43.169
                                                    ],
                                                    [
                                                        18.316,
                                                        -42.822
                                                    ],
                                                    [
                                                        10.013,
                                                        -42.402
                                                    ],
                                                    [
                                                        -17.14,
                                                        51.688
                                                    ],
                                                    [
                                                        -9.487,
                                                        58.576
                                                    ],
                                                    [
                                                        72.405,
                                                        58.576
                                                    ],
                                                    [
                                                        89.515,
                                                        -43.169
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 27
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.347,
                                                        19.848
                                                    ],
                                                    [
                                                        0,
                                                        6.888
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        51.5,
                                                        -38.044
                                                    ],
                                                    [
                                                        13.779,
                                                        -20.015
                                                    ],
                                                    [
                                                        2.013,
                                                        -14.391
                                                    ],
                                                    [
                                                        0.36,
                                                        51.699
                                                    ],
                                                    [
                                                        8.013,
                                                        58.587
                                                    ],
                                                    [
                                                        96.03,
                                                        58.587
                                                    ],
                                                    [
                                                        96.14,
                                                        -38.044
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 28.0000011404634
                                    }
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    89.64,
                                    78.544
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 20
        },
        {
            "ty": 4,
            "nm": "l/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 160.000006516934,
            "ip": 28.0000011404634,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        68,
                        68,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100,
                                100,
                                100
                            ],
                            "t": 56
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 60
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 99
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 102
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                115,
                                115,
                                100
                            ],
                            "t": 149
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                107,
                                107,
                                100
                            ],
                            "t": 152
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                108,
                                108,
                                100
                            ],
                            "t": 154
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                100,
                                100,
                                100
                            ],
                            "t": 155.000006313279
                        }
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                200,
                                150,
                                0
                            ],
                            "t": 28
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                200,
                                150,
                                0
                            ],
                            "t": 32
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                185,
                                160,
                                0
                            ],
                            "t": 38
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                185,
                                160,
                                0
                            ],
                            "t": 56
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 60
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199.017,
                                160,
                                0
                            ],
                            "t": 61
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                198.964,
                                160,
                                0
                            ],
                            "t": 67
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 68
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 72
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 76
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 79
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 82
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 85
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 88
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 91
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 94
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 97
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 99
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 102
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                160,
                                195,
                                0
                            ],
                            "t": 106
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                160,
                                195,
                                0
                            ],
                            "t": 149
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                199,
                                160,
                                0
                            ],
                            "t": 152
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                136.044,
                                128.139,
                                0
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                125,
                                123.394,
                                0
                            ],
                            "t": 154
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                129,
                                131,
                                0
                            ],
                            "t": 155.000006313279
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.724,
                                                        0.724
                                                    ],
                                                    [
                                                        0,
                                                        1.105
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.105,
                                                        0
                                                    ],
                                                    [
                                                        -0.724,
                                                        -0.724
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        0,
                                                        47.978
                                                    ],
                                                    [
                                                        -43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -46.806,
                                                        46.806
                                                    ],
                                                    [
                                                        -47.978,
                                                        43.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        0.001
                                                    ],
                                                    [
                                                        -47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        -43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        47.978,
                                                        43.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 102
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.992,
                                                        0.724
                                                    ],
                                                    [
                                                        0,
                                                        1.105
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.514,
                                                        0
                                                    ],
                                                    [
                                                        -0.992,
                                                        -0.724
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        3.027,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        81.986,
                                                        31.978
                                                    ],
                                                    [
                                                        19.744,
                                                        31.978
                                                    ],
                                                    [
                                                        -42.498,
                                                        31.978
                                                    ],
                                                    [
                                                        -46.373,
                                                        30.806
                                                    ],
                                                    [
                                                        -47.978,
                                                        27.978
                                                    ],
                                                    [
                                                        -39.91,
                                                        -7.999
                                                    ],
                                                    [
                                                        -31.841,
                                                        -43.977
                                                    ],
                                                    [
                                                        -26.362,
                                                        -47.977
                                                    ],
                                                    [
                                                        69.548,
                                                        -47.977
                                                    ],
                                                    [
                                                        75.028,
                                                        -43.977
                                                    ],
                                                    [
                                                        87.465,
                                                        27.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 106
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.992,
                                                        0.724
                                                    ],
                                                    [
                                                        0,
                                                        1.105
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -3.026,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.514,
                                                        0
                                                    ],
                                                    [
                                                        -0.992,
                                                        -0.724
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        3.027,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        81.986,
                                                        31.978
                                                    ],
                                                    [
                                                        19.744,
                                                        31.978
                                                    ],
                                                    [
                                                        -42.498,
                                                        31.978
                                                    ],
                                                    [
                                                        -46.373,
                                                        30.806
                                                    ],
                                                    [
                                                        -47.978,
                                                        27.978
                                                    ],
                                                    [
                                                        -39.91,
                                                        -7.999
                                                    ],
                                                    [
                                                        -31.841,
                                                        -43.977
                                                    ],
                                                    [
                                                        -26.362,
                                                        -47.977
                                                    ],
                                                    [
                                                        69.548,
                                                        -47.977
                                                    ],
                                                    [
                                                        75.028,
                                                        -43.977
                                                    ],
                                                    [
                                                        87.465,
                                                        27.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 149
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.724,
                                                        0.724
                                                    ],
                                                    [
                                                        0,
                                                        1.105
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.105,
                                                        0
                                                    ],
                                                    [
                                                        -0.724,
                                                        -0.724
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -2.209
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        2.209,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.209
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        0,
                                                        47.978
                                                    ],
                                                    [
                                                        -43.978,
                                                        47.978
                                                    ],
                                                    [
                                                        -46.806,
                                                        46.806
                                                    ],
                                                    [
                                                        -47.978,
                                                        43.978
                                                    ],
                                                    [
                                                        -47.978,
                                                        0.001
                                                    ],
                                                    [
                                                        -47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        -43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        43.978,
                                                        -47.977
                                                    ],
                                                    [
                                                        47.978,
                                                        -43.977
                                                    ],
                                                    [
                                                        47.978,
                                                        43.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 152
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.457,
                                                        -9.282
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.913,
                                                        -0.861
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.827,
                                                        4.962
                                                    ],
                                                    [
                                                        -0.457,
                                                        9.282
                                                    ],
                                                    [
                                                        0,
                                                        3.444
                                                    ],
                                                    [
                                                        1.913,
                                                        0.861
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        47.412,
                                                        -37.728
                                                    ],
                                                    [
                                                        30.67,
                                                        -13.242
                                                    ],
                                                    [
                                                        13.928,
                                                        11.244
                                                    ],
                                                    [
                                                        4.802,
                                                        15.698
                                                    ],
                                                    [
                                                        -4.325,
                                                        20.153
                                                    ],
                                                    [
                                                        -7.521,
                                                        44.919
                                                    ],
                                                    [
                                                        -7.978,
                                                        62.243
                                                    ],
                                                    [
                                                        -4.152,
                                                        68.27
                                                    ],
                                                    [
                                                        -0.325,
                                                        69.131
                                                    ],
                                                    [
                                                        70.443,
                                                        69.131
                                                    ],
                                                    [
                                                        104.553,
                                                        -37.728
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 153
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0.068,
                                                        -8.513
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.913,
                                                        -0.861
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.067,
                                                        8.04
                                                    ],
                                                    [
                                                        -0.068,
                                                        8.513
                                                    ],
                                                    [
                                                        0,
                                                        3.444
                                                    ],
                                                    [
                                                        1.913,
                                                        0.861
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        47.412,
                                                        -37.728
                                                    ],
                                                    [
                                                        30.67,
                                                        -13.242
                                                    ],
                                                    [
                                                        13.928,
                                                        11.244
                                                    ],
                                                    [
                                                        3.645,
                                                        15.698
                                                    ],
                                                    [
                                                        -6.639,
                                                        20.153
                                                    ],
                                                    [
                                                        -8.109,
                                                        47.228
                                                    ],
                                                    [
                                                        -7.978,
                                                        62.243
                                                    ],
                                                    [
                                                        -4.152,
                                                        68.27
                                                    ],
                                                    [
                                                        -0.325,
                                                        69.131
                                                    ],
                                                    [
                                                        70.443,
                                                        69.131
                                                    ],
                                                    [
                                                        104.553,
                                                        -37.728
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 154
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            {
                                                "c": true,
                                                "i": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -1.489,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        -7.653,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        6.888
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -1.489
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        21.663,
                                                        -47.978
                                                    ],
                                                    [
                                                        -12.234,
                                                        20.132
                                                    ],
                                                    [
                                                        -40.325,
                                                        20.132
                                                    ],
                                                    [
                                                        -47.978,
                                                        25.488
                                                    ],
                                                    [
                                                        -47.978,
                                                        62.221
                                                    ],
                                                    [
                                                        -40.325,
                                                        69.109
                                                    ],
                                                    [
                                                        23.193,
                                                        69.109
                                                    ],
                                                    [
                                                        23.193,
                                                        22.826
                                                    ],
                                                    [
                                                        25.887,
                                                        20.132
                                                    ],
                                                    [
                                                        56.865,
                                                        20.132
                                                    ],
                                                    [
                                                        91.303,
                                                        -47.978
                                                    ]
                                                ]
                                            }
                                        ],
                                        "t": 155.000006313279
                                    }
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    67.978,
                                    67.978
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 21
        },
        {
            "ty": 4,
            "nm": "back tyre/Optima_ae Outlines",
            "mn": "",
            "sr": 1,
            "st": 25.0000010182709,
            "op": 160.000006516934,
            "ip": 150.000006109625,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        86.5,
                        38,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                236,
                                200,
                                0
                            ],
                            "t": 153
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                165,
                                200,
                                0
                            ],
                            "t": 156.00000635401
                        }
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    134.791,
                                    37.602
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Group 2",
                    "ix": 2,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "sh",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Group",
                            "nm": "Path 1",
                            "ix": 1,
                            "d": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "c": true,
                                    "i": [
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ],
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            9.721
                                        ],
                                        [
                                            -9.72,
                                            0
                                        ],
                                        [
                                            0,
                                            -9.722
                                        ],
                                        [
                                            9.721,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            17.602
                                        ],
                                        [
                                            -17.602,
                                            0.001
                                        ],
                                        [
                                            -0.001,
                                            -17.602
                                        ]
                                    ]
                                },
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 2,
                            "ml": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 8,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "fl",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Fill",
                            "nm": "Fill 1",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.1176,
                                    0.5294,
                                    0.9373
                                ],
                                "ix": 4
                            },
                            "r": 1,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    37.601,
                                    37.602
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 3,
                    "e": {
                        "a": 0,
                        "k": 100,
                        "ix": 2
                    },
                    "o": {
                        "a": 0,
                        "k": 0,
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 153
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    1
                                ],
                                "t": 156.00000635401
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 22
        },
        {
            "ty": 0,
            "nm": "truck",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 150.000006109625,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        200,
                        150,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        200,
                        150,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "w": 400,
            "h": 300,
            "refId": "comp_0",
            "ind": 23
        }
    ],
    "ddd": 0,
    "h": 300,
    "w": 400,
    "meta": {
        "a": "",
        "k": "",
        "d": "",
        "g": "@lottiefiles/toolkit-js 0.17.4",
        "tc": "#ffffff"
    },
    "v": "5.5.4",
    "fr": 29.9700012207031,
    "op": 160.000006516934,
    "ip": 0,
    "assets": [
        {
            "nm": "",
            "mn": "",
            "layers": [
                {
                    "ty": 4,
                    "nm": "back tyre/Optima_ae Outlines",
                    "mn": "",
                    "sr": 1,
                    "st": 0,
                    "op": 28.0000011404634,
                    "ip": 24.00000097754,
                    "hd": false,
                    "cl": "",
                    "ln": "",
                    "ddd": 0,
                    "bm": 0,
                    "tt": 0,
                    "hasMask": false,
                    "td": 0,
                    "ao": 0,
                    "ks": {
                        "a": {
                            "a": 0,
                            "k": [
                                86.5,
                                38,
                                0
                            ],
                            "ix": 1
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6
                        },
                        "sk": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        165,
                                        199,
                                        0
                                    ],
                                    "t": 24
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        189,
                                        199,
                                        0
                                    ],
                                    "t": 25
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        210,
                                        196,
                                        0
                                    ],
                                    "t": 26
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        214,
                                        191,
                                        0
                                    ],
                                    "t": 27
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        214,
                                        190,
                                        0
                                    ],
                                    "t": 76.0000030955435
                                }
                            ],
                            "ix": 2
                        },
                        "sa": {
                            "a": 0,
                            "k": 0
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 10
                        }
                    },
                    "ef": [],
                    "shapes": [
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 1",
                            "ix": 1,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    17.602
                                                ],
                                                [
                                                    -17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    -17.602
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.1176,
                                            0.5294,
                                            0.9373
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            134.791,
                                            37.602
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        },
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 2",
                            "ix": 2,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    17.602
                                                ],
                                                [
                                                    -17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    -17.602
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.1176,
                                            0.5294,
                                            0.9373
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            37.601,
                                            37.602
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        }
                    ],
                    "ind": 0
                },
                {
                    "ty": 4,
                    "nm": "enginee/Optima_ae Outlines",
                    "mn": "",
                    "sr": 1,
                    "st": 0,
                    "op": 24.00000097754,
                    "ip": 0,
                    "hd": false,
                    "cl": "",
                    "ln": "",
                    "ddd": 0,
                    "bm": 0,
                    "tt": 0,
                    "hasMask": false,
                    "td": 0,
                    "ao": 0,
                    "ks": {
                        "a": {
                            "a": 0,
                            "k": [
                                92,
                                70.5,
                                0
                            ],
                            "ix": 1
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6
                        },
                        "sk": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        201,
                                        0
                                    ],
                                    "t": 9
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        199,
                                        0
                                    ],
                                    "t": 10
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        196,
                                        0
                                    ],
                                    "t": 11
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        201,
                                        0
                                    ],
                                    "t": 12
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        203,
                                        0
                                    ],
                                    "t": 14
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        204,
                                        0
                                    ],
                                    "t": 15
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        207,
                                        0
                                    ],
                                    "t": 16
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        204,
                                        0
                                    ],
                                    "t": 17
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        154,
                                        201,
                                        0
                                    ],
                                    "t": 18.000000733155
                                }
                            ],
                            "ix": 2
                        },
                        "sa": {
                            "a": 0,
                            "k": 0
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 9
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        1
                                    ],
                                    "t": 10
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        2
                                    ],
                                    "t": 11
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 12
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -1
                                    ],
                                    "t": 15
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -2
                                    ],
                                    "t": 16
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -1
                                    ],
                                    "t": 17
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 18.000000733155
                                }
                            ],
                            "ix": 10
                        }
                    },
                    "ef": [],
                    "shapes": [
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 1",
                            "ix": 1,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    6.888
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -7.652,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    35.585,
                                                    24.489
                                                ],
                                                [
                                                    -27.934,
                                                    24.489
                                                ],
                                                [
                                                    -35.585,
                                                    17.601
                                                ],
                                                [
                                                    -35.585,
                                                    -19.132
                                                ],
                                                [
                                                    -27.934,
                                                    -24.489
                                                ],
                                                [
                                                    35.585,
                                                    -24.489
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.1176,
                                            0.5294,
                                            0.9373
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            55.585,
                                            44.489
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        }
                    ],
                    "ind": 1
                },
                {
                    "ty": 4,
                    "nm": "glass/Optima_ae Outlines",
                    "mn": "",
                    "sr": 1,
                    "st": 0,
                    "op": 24.00000097754,
                    "ip": 0,
                    "hd": false,
                    "cl": "",
                    "ln": "",
                    "ddd": 0,
                    "bm": 0,
                    "tt": 0,
                    "hasMask": false,
                    "td": 0,
                    "ao": 0,
                    "ks": {
                        "a": {
                            "a": 0,
                            "k": [
                                122,
                                89.5,
                                0
                            ],
                            "ix": 1
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6
                        },
                        "sk": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        220,
                                        152,
                                        0
                                    ],
                                    "t": 9
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        221,
                                        149,
                                        0
                                    ],
                                    "t": 11
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        220,
                                        152,
                                        0
                                    ],
                                    "t": 12
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        220,
                                        153,
                                        0
                                    ],
                                    "t": 15
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        220,
                                        155.243,
                                        0
                                    ],
                                    "t": 16
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        220,
                                        153.333,
                                        0
                                    ],
                                    "t": 17
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        220,
                                        152,
                                        0
                                    ],
                                    "t": 18.000000733155
                                }
                            ],
                            "ix": 2
                        },
                        "sa": {
                            "a": 0,
                            "k": 0
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 9
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        1
                                    ],
                                    "t": 10
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        2
                                    ],
                                    "t": 11
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 12
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -1
                                    ],
                                    "t": 15
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -2
                                    ],
                                    "t": 16
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -1
                                    ],
                                    "t": 17
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 18.000000733155
                                }
                            ],
                            "ix": 10
                        }
                    },
                    "ef": [],
                    "shapes": [
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 1",
                            "ix": 1,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -51.768,
                                                    34.055
                                                ],
                                                [
                                                    -17.872,
                                                    -34.055
                                                ],
                                                [
                                                    51.768,
                                                    -34.055
                                                ],
                                                [
                                                    17.331,
                                                    34.055
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.1176,
                                            0.5294,
                                            0.9373
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            71.768,
                                            54.054
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        }
                    ],
                    "ind": 2
                },
                {
                    "ty": 4,
                    "nm": "truck/Optima_ae Outlines",
                    "mn": "",
                    "sr": 1,
                    "st": 0,
                    "op": 24.00000097754,
                    "ip": 0,
                    "hd": false,
                    "cl": "",
                    "ln": "",
                    "ddd": 0,
                    "bm": 0,
                    "tt": 0,
                    "hasMask": false,
                    "td": 0,
                    "ao": 0,
                    "ks": {
                        "a": {
                            "a": 0,
                            "k": [
                                260,
                                159.5,
                                0
                            ],
                            "ix": 1
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6
                        },
                        "sk": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                321,
                                222,
                                0
                            ],
                            "ix": 2
                        },
                        "sa": {
                            "a": 0,
                            "k": 0
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 9
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        1
                                    ],
                                    "t": 10
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        2
                                    ],
                                    "t": 11
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 12
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -1
                                    ],
                                    "t": 15
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -2
                                    ],
                                    "t": 16
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        -1
                                    ],
                                    "t": 17
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        0
                                    ],
                                    "t": 18.000000733155
                                }
                            ],
                            "ix": 10
                        }
                    },
                    "ef": [],
                    "shapes": [
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 1",
                            "ix": 1,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -6.122
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    6.887,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    6.888
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    -7.653,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -14.157,
                                                    9.565
                                                ],
                                                [
                                                    20.28,
                                                    -58.544
                                                ],
                                                [
                                                    111.348,
                                                    -58.544
                                                ],
                                                [
                                                    119,
                                                    -51.658
                                                ],
                                                [
                                                    119,
                                                    51.656
                                                ],
                                                [
                                                    112.113,
                                                    58.544
                                                ],
                                                [
                                                    -111.347,
                                                    58.544
                                                ],
                                                [
                                                    -119,
                                                    51.656
                                                ],
                                                [
                                                    -119,
                                                    14.921
                                                ],
                                                [
                                                    -111.347,
                                                    9.565
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            139,
                                            78.544
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        },
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 2",
                            "ix": 2,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    17.602
                                                ],
                                                [
                                                    -17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    -17.602
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.1176,
                                            0.5294,
                                            0.9373
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            151.627,
                                            137.086
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        },
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 3",
                            "ix": 3,
                            "cix": 2,
                            "np": 3,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": true,
                                            "i": [
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    9.721
                                                ],
                                                [
                                                    -9.72,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -9.722
                                                ],
                                                [
                                                    9.721,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    17.602
                                                ],
                                                [
                                                    -17.602,
                                                    0.001
                                                ],
                                                [
                                                    -0.001,
                                                    -17.602
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 8,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "fl",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "nm": "Fill 1",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0.1176,
                                            0.5294,
                                            0.9373
                                        ],
                                        "ix": 4
                                    },
                                    "r": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 5
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            54.438,
                                            137.086
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        }
                    ],
                    "ind": 3
                },
                {
                    "ty": 4,
                    "nm": "sbreaker/Optima_ae Outlines",
                    "mn": "",
                    "sr": 1,
                    "st": 0,
                    "op": 24.00000097754,
                    "ip": 0,
                    "hd": false,
                    "cl": "",
                    "ln": "",
                    "ddd": 0,
                    "bm": 0,
                    "tt": 0,
                    "hasMask": false,
                    "td": 0,
                    "ao": 0,
                    "ks": {
                        "a": {
                            "a": 0,
                            "k": [
                                102,
                                20,
                                0
                            ],
                            "ix": 1
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6
                        },
                        "sk": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 1,
                            "k": [
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        26,
                                        218,
                                        0
                                    ],
                                    "t": 0
                                },
                                {
                                    "o": {
                                        "x": 0.167,
                                        "y": 0.167
                                    },
                                    "i": {
                                        "x": 0.833,
                                        "y": 0.833
                                    },
                                    "s": [
                                        437,
                                        218,
                                        0
                                    ],
                                    "t": 24.00000097754
                                }
                            ],
                            "ix": 2
                        },
                        "sa": {
                            "a": 0,
                            "k": 0
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 10
                        }
                    },
                    "ef": [],
                    "shapes": [
                        {
                            "ty": "gr",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Group",
                            "nm": "Group 1",
                            "ix": 1,
                            "cix": 2,
                            "np": 2,
                            "it": [
                                {
                                    "ty": "sh",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Shape - Group",
                                    "nm": "Path 1",
                                    "ix": 1,
                                    "d": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "c": false,
                                            "i": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    5.408,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -5.407
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    -5.407
                                                ],
                                                [
                                                    -5.406,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ],
                                                [
                                                    0,
                                                    0
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    87,
                                                    4.895
                                                ],
                                                [
                                                    2.937,
                                                    4.895
                                                ],
                                                [
                                                    -6.854,
                                                    -4.895
                                                ],
                                                [
                                                    -16.644,
                                                    4.895
                                                ],
                                                [
                                                    -87,
                                                    4.895
                                                ]
                                            ]
                                        },
                                        "ix": 2
                                    }
                                },
                                {
                                    "ty": "st",
                                    "bm": 0,
                                    "cl": "",
                                    "ln": "",
                                    "hd": false,
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "nm": "Stroke 1",
                                    "lc": 2,
                                    "lj": 2,
                                    "ml": 1,
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 6,
                                        "ix": 5
                                    },
                                    "d": [],
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "ix": 3
                                    }
                                },
                                {
                                    "ty": "tr",
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            102,
                                            19.895
                                        ],
                                        "ix": 2
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    }
                                }
                            ]
                        },
                        {
                            "ty": "tm",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Filter - Trim",
                            "nm": "Trim Paths 1",
                            "ix": 2,
                            "e": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            0
                                        ],
                                        "t": 0
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            100
                                        ],
                                        "t": 10.0000004073083
                                    }
                                ],
                                "ix": 2
                            },
                            "o": {
                                "a": 0,
                                "k": 0,
                                "ix": 3
                            },
                            "s": {
                                "a": 1,
                                "k": [
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            0
                                        ],
                                        "t": 0
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            0
                                        ],
                                        "t": 15
                                    },
                                    {
                                        "o": {
                                            "x": 0.167,
                                            "y": 0.167
                                        },
                                        "i": {
                                            "x": 0.833,
                                            "y": 0.833
                                        },
                                        "s": [
                                            100
                                        ],
                                        "t": 24.00000097754
                                    }
                                ],
                                "ix": 1
                            },
                            "m": 1
                        }
                    ],
                    "ind": 4
                }
            ],
            "id": "comp_0",
            "fr": 30
        }
    ]
}