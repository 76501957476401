import { Autocomplete, Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { CustomStyledTextField } from "./ColoredTextfield";
import { Controller } from "react-hook-form";

export default function LabeledTextField2({label,width,multiline,disabled,onKeyUp,min,maxRows,alignItems,height,onChange,control,rules,fontSize,labelStyle,name,endAdornment , value , textfieldStyles, callback,maxLength ,...rest}) {
    return (
    <FormControl sx={{  width:width || "100%",height:"100%",gap:0 }}>
      <Controller
       control={control}
       name={name}
       rules={rules}    
        render={({field,fieldState}) =>  <Grid container rowSpacing={label?"4px":0}>
        <Grid item xs={12}>
        <Typography style={{textAlign:"start",fontFamily:"Figtree",...labelStyle}}>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
        <TextField
      disabled={disabled}
        multiline={multiline||false}
        maxRows={maxRows}
        type="number"
        value={value}
        maxLength={6}
        onKeyDown={(e) =>{ 
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
         }
         }
        onKeyUp={(e)=>{
          // if(onKeyUp){
          //  onKeyUp(e);
          // }
          if(callback){
            console.log(e.target.value)
            callback(e.target.value);
          }
       }}
        // onChange={(e)=>{
        //   // if(onChange){
        //   //   onChange(e.target.value);
        //   // }
        //   // field.onChange(e);
        //   // if(callback){
        //   //   callback(e.target.value);
        //   // }
        //   console.log('onchange workingggg')
        // }}
        InputProps={{
          endAdornment : endAdornment,
          // maxLength: 6,
          style :{
            fontSize : fontSize || "16px",
            height:height,
            alignItems: alignItems,
            ...textfieldStyles,
          }
        }} sx={{padding:0,margin:0, 
          
          "& .MuiFormControl-root .MuiTextField-root": {
            display: 'flex',
            alignItems: 'center',
            justifyContent :"center",
            
          },
          "& .MuiInputAdornment-positionEnd": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            fieldset: {
              borderColor: '#2B6ED4 !important', // Default focused color
            },
          },
          "&.Mui-error": {
            "&.Mui-focused": {
              fieldset: {
                borderColor: 'red !important', // Color for focused and error state
              },
            },
          },
          "&.Mui-invalid": {
            fieldset: {
              border: 'red !important', // Default focused color
            },
          },
        },
        }} error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant="outlined" {...rest} size="small" margin="normal" fullWidth/>
        </Grid>
       </Grid>
        
    //     <Box sx={{display:"flex",alignItems:"start",flexDirection:"column",width:"100%"}}>
    //         <>{label}</>
    //         <CustomStyledTextField error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant="outlined" {...rest} size="small" margin="normal" fullWidth/>
    //  </Box>
     }
      />
        </FormControl>
     
    );
  }
  