import './PhoneField.css';
import CountryList from 'country-list-with-dial-code-and-flag'
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg'
import { useEffect } from 'react';
import { useState } from 'react';
function PhoneField({callback,countryCode,phone,disabled,manageUsersFlag, creditFlag , labelReq , errorMsgReg,customStyleClass}) {
  const [first, setfirst] = useState([])
  const [Country, setCountry] = useState("IN")
  const [DefaultCountry, setDefaultCountry] = useState([])
  const [DialCode, setDialCode] = useState([])
  const [PhoneNumber, setPhoneNumber] = useState("")
  const [Error, setError] = useState("")
  const [MaxLength, setMaxLength] = useState(10)
  
  // CountryList.getAll() 

  useEffect(() => {
    const defaultcountry = CountryList.findOneByCountryCode('in')
   const country =  CountryList.getAll()
   console.log(country);
    if (defaultcountry) {
    setDefaultCountry(`data:image/svg+xml;utf8,${encodeURIComponent(CountryFlagSvg[defaultcountry.code])}`)
    setDialCode(defaultcountry.dialCode)
    setfirst(country)
    }
  }, [])

  useEffect(() => {
    if (countryCode) {
      // const countryCode = defaultValue.substring(0, 3); 
      const defaultCountry = CountryList.findOneByDialCode(countryCode);
      if (defaultCountry) {
        setDefaultCountry(`data:image/svg+xml;utf8,${encodeURIComponent(CountryFlagSvg[defaultCountry.code])}`);
        setDialCode(defaultCountry.dialCode);
        setCountry(countryCode);
        if(phone){
          setPhoneNumber(phone); 
        }
        if (defaultCountry.dialCode === "+91") {
          setMaxLength(10);
        } else {
          setMaxLength(15);
        }
      }
    }
  }, [countryCode,phone]);
  
  
  const selectCountry = (e)=>{
    setCountry(e.target.value)
    // CountryList.findOneByCountryCode('in')
  let img =  CountryList.findOneByCountryCode(e.target.value);
  console.log(img.dialCode);
  if (img.dialCode === "+91") {
    setMaxLength(10)
  }else {
    setMaxLength(15)
  }
  setDialCode(img.dialCode)
    // var imgUrl= `data:image/svg+xml;utf8,${encodeURIComponent(CountryFlagSvg[img.code])}`
    setDefaultCountry(`data:image/svg+xml;utf8,${encodeURIComponent(CountryFlagSvg[img.code])}`)
    setError("")
    setPhoneNumber("")
  }
  function trimStr(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, "");
  }
  const validatePhoneNumber = (e)=>{
    SetCursorPointerPosition(e)
    console.log(DialCode);
    if (DialCode === "+91" && trimStr(e.target.value).length > 10) {
      return setPhoneNumber(e.target.value.slice(0,10));
    } 
    else if (DialCode !== "+91" && trimStr(e.target.value).length > 15) {
      return setPhoneNumber(e.target.value.slice(0,15));
    } 
    if (DialCode === "+91" && (e.target.value.length > 10 || (e.target.value.length !== 0 && e.target.value.length !== 10))) {
      setError("Enter Valid Phone Number (10 Digits)")
    }
    else if (DialCode !== "+91" && ((e.target.value?.length > 15 || e.target.value?.length < 7) && e.target.value?.length !== 0)) {
      setError("Enter Valid Phone Number (7-15 Digits)")
    }else {
      setError("")
    }
    setPhoneNumber(e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'))

  }
  const SetCursorPointerPosition = (e) => {
    const Position = e.target.selectionStart;
    setTimeout(() => {
      e.target.setSelectionRange(Position, Position);
    }, 0);
  };


  useEffect(() => {
    callback({
        error:Error,
        number:PhoneNumber,
        code:DialCode
    })
  }, [DialCode,PhoneNumber,Error])
  
  return (
    <>
    <div className={'input_wrapper '+ customStyleClass} style={(manageUsersFlag|| creditFlag)?{marginBottom:'-5px',marginTop:'0px' , width:'100%'}:{}}>
    {labelReq !== false ? 
      <p className="label_styles" style={manageUsersFlag?{fontSize:'12px'}:{}}>Phone Number</p> : null
    }
          <div className='phoneField'>
            <div className='countrySelect' style={manageUsersFlag?{height:'23px'}:{}}>
            <img id="myImg" src={DefaultCountry} width="107" height="98" alt='' />
            <select id='check' value={Country} disabled={disabled && disabled} onChange={(e)=> selectCountry(e)}>
          <option selected disabled>Country</option>/
          {first?.map((d,i)=>{
          return <option value={d.code} key={i}>
              {d.name}
            </option>
          })}           
          </select>
            </div>

            <span className='phoneFieldCode'>{DialCode}</span>
            <input type='text' style={{backgroundColor:'transparent',overflow:'hidden'}} className='phoneFieldInput' placeholder='Enter Your Phone Number' disabled={disabled && disabled} maxLength={MaxLength} value={PhoneNumber} onChange={(e)=> validatePhoneNumber(e)}></input>
          </div>
            <p className='help is-input-danger-message' style={{fontSize:'11.4px'}}>{        
          Error?Error:<span>&nbsp;</span>
          }</p>        
    </div>
    </>
  );
}

export default PhoneField;

