import Utils from "../util/Utils"

export const FFF = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["loaded_and_dispatch", "container_handover", "gatein_pol", "vgm_cut_off"];
            let vehicleKeys = ["cargo_pickup"]
            
            if ((booking["containerlist"] && booking["containerlist"].length > 0) || (booking["vorgin"] && booking["vorgin"].length > 0)) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                // if(booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door"){
                //     
                //     return booking["vorgin"].map((container) => vehicleKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
                // }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["loaded_and_dispatch"];
            let vehicleOriginKeys = ["cargo_pickup"]
            let otherMileStoneKeys = ["vgm_cut_off"];
            let findFrom = "containerlist";
            let keys = milestoneKeys;

            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }

            // if (booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door") {
            //     findFrom = "vorgin";
            //     keys = vehicleOriginKeys;
            // }

            if (booking[findFrom] && booking[findFrom].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(keys, booking[findFrom][index])
                }
                return booking[findFrom].map((container) => keys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_handover", "gatein_pol"]

            let otherMileStoneKeys = ["vgm_cut_off"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }

            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        isMilestoneAddsToThePath: (booking) => booking?.origin || booking?.dummy,
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Received",
            // },
            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "loaded_and_dispatch",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "loaded_and_dispatch";
                    let vehicleOriginKey = "cargo_pickup";
                    let findFrom = "containerlist";
                    let keys = key;
                    // if(booking.shipmentType.split("/")[0].includes("Dock") && booking.shipmentScope.split("-")[0] === "Door"){
                    //        findFrom = "vorgin";
                    //        keys = vehicleOriginKey;
                    // }
                    
                    if (isMilestoneAchieved) {
                        if (index > -1) {
                            return booking[findFrom][index][keys]
                        }
                        let dayDiff = booking[findFrom].map((container) => Utils.getDateDifferenceForProgress(container[keys], new Date()));
                        let maxIndex = 0;

                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking[findFrom][maxIndex][keys];
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "por",
        location: "por_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ por, pol, dummy }) => por && pol && por !== pol  || dummy,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_handover"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["por_to_pol_movement"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_pol"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).every((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "Handover",
                activeLabel: "Handover",
                estimate: "container_handover",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "container_handover";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "Gate-Out",
                activeLabel: "Gate-Out",
                estimate: "por_to_pol_movement",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "por_to_pol_movement";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "pol",
        location: "pol_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: (booking) => booking['pol'] || booking?.dummy,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_pol"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        vesselNameKey: "fl_vessel_name",
        vesselNumberKey: "fl_vessel_number",
        milestoneAdvancedBasedOn: ["sob_pol"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let transhipment = ["ts1_arrival", "ts2_arrival", "pod_arrival"];
            if (Utils.isValueExists(transhipment, booking)) {
                
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                
                if (index > -1) {
                    
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "Gate In",
                activeLabel: "Gate In",
                estimate: "gate_cut_off",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "gatein_pol";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "SOB",
                estimate: "etd_at_pol",
                actual: "sob_pol"
            }
        ]
    },
    {
        milestone: "ts1_port",
        location: "ts1_port_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isMilestoneAddsToThePath: ({ ts1_port }) => ts1_port && ts1_port.length > 0,
        milestoneAchievedBasedOn: ["ts1_arrival"],
        milestoneAdvancedBasedOn: ["ts1_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "ts1_vessel_number",
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts2_arrival", "ts3_arrival", "ts4_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                
                if (index > -1) {
                    
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts1_arrival_estimate",
                actual: "ts1_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts1_departure_estimate",
                actual: "ts1_departure"
            }
        ]
    },
    {
        milestone: "ts2_port",
        location: "ts2_port_location",
        dynamicIcon: true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ ts2_port }) => ts2_port && ts2_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["ts2_arrival"],
        milestoneAdvancedBasedOn: ["ts2_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts3_arrival", "ts4_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        vesselNameKey: "ts2_vessel_name",
        vesselNumberKey: "ts2_vessel_number",
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts2_arrival_estimate",
                actual: "ts2_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts2_departure_estimate",
                actual: "ts2_departure"
            }
        ]
    },
    {
        milestone: "ts3_port",
        location: "ts3_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts3_port }) => ts3_port && ts3_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts3_vessel_name",
        vesselNumberKey: "ts3_vessel_number",
        milestoneAchievedBasedOn: ["ts3_arrival"],
        milestoneAdvancedBasedOn: ["ts3_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts4_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts3_arrival_estimate",
                actual: "ts3_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts3_departure_estimate",
                actual: "ts3_departure"
            }
        ]
    },
    {
        milestone: "ts4_port",
        location: "ts1_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts4_port }) => ts4_port && ts4_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts4_vessel_name",
        vesselNumberKey: "ts4_vessel_number",
        milestoneAchievedBasedOn: ["ts4_arrival"],
        milestoneAdvancedBasedOn: ["ts4_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts4_arrival_estimate",
                actual: "ts4_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts4_departure_estimate",
                actual: "ts4_departure"
            }
        ]
    },
    {
        milestone: "pod",
        location: "pod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ pod, fpod, dummy }) => pod !== fpod || dummy,
        downStreamMilestone: (booking) => booking["fpod"] || booking?.dummy,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["pod_arrival"],
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["gateout_to_fpod"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_at_pod",
                actual: "pod_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "etd_pod",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "gateout_to_fpod";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }
                    if (isMilestoneAchieved) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "fpod",
        location: "fpod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: (booking) => booking["fpod"],
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["gateout_to_fpod"]
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneCompletedBasedOn: (booking, index) => {
            
            let milestoneKeys = ["gateout_to_fpod"];
            let dockMileStone = ["actual_delivery_date"];

            // if (booking.shipmentType.split("/")[1].includes("Dock")) {
            if (booking["vdes"] && booking["vdes"].length > 0) {
                return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            // } else {

            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            // }
            return false
        },
        downStreamMilestone: (booking) => booking["destination"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_fpod",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "gatein_fpod";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }

                    if (isMilestoneAchieved) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    return "";
                }
            },
            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "container_pickup_fpod",
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "container_pickup_fpod";
                    if (index > -1) {
                        return booking["containerlist"][index][key]
                    }

                    if (isMilestoneAchieved) {
                        let dayDiff = booking["containerlist"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["containerlist"][maxIndex][key];
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "destination",
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];
            // if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // } else {
            if (booking["containerlist"] && booking["containerlist"].length > 0) {

                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            // }
            return false
        },
        milestoneAdvancedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];
            // if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // } else {
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            // }
            return false
        },
        milestoneAchievedBasedOn: (booking, index) => {
            let milestoneKeys = ["container_delivery"];
            let dockMileStone = ["actual_delivery_date"];

            // if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // } else {
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            // }
            return false
        },
        downStreamMilestone: () => false,
        isMilestoneAddsToThePath: (booking) => booking?.destination || booking?.dummy,
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Delivered"
            // },
            {
                isLabel: false,
                label: "Estimated Delivery Date",
                activeLabel: "Delivered On",
                estimate: (booking, isMilestoneAchieved, index) => {
                    let key = "estimate_delivery_date_to_consignee";
                    let from = "containerlist";
                    // if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                    //     from = "vdes";

                    // }

                    if (isMilestoneAchieved) {
                        if (index > -1) {
                            return booking[from][index][key]
                        }
                        let dayDiff = booking[from].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking[from][maxIndex][key];
                    }
                    return "";
                },
                actual: (booking, isMilestoneAchieved, index) => {
                    let key = "container_delivery";
                    let from = "containerlist";
                    // if (booking.shipmentType.split("/")[1].includes("Dock") && booking.shipmentScope.split("-")[2] === "Door") {
                    //     from = "vdes"
                    //     key = "actual_delivery_date"
                    // }

                    if (isMilestoneAchieved) {
                        if (index > -1) {
                            return booking[from][index][key]
                        }
                        let dayDiff = booking[from].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking[from][maxIndex][key];
                    }
                    return "";
                }
            }
        ]
    },
]



export const LCL = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking) => {
            let dockMileStone = ["cargo_pickup"];
            let portMilestone = ["container_handover"];
            if (Utils.isValueExists(portMilestone, booking)) {
                return true;
            }
            // if (booking.shipmentScope.split("-")[0].toLowerCase() === "door") {
            //     if (booking["vorgin"] && booking["vorgin"].length > 0) {
            //         return booking["vorgin"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // }
            return false
        },
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["cargo_pickup"];
            // if (booking.shipmentScope.split("-")[0].toLowerCase() === "door") {
            //     if (booking["vorgin"] && booking["vorgin"].length > 0) {
            //         return booking["vorgin"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // }
            return false
        },
        milestoneCompletedBasedOn: ["cargo_pickup_date", "container_handover", "gatein_pol", "vgm_cut_off"],
        // isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[0].toLowerCase() === "door",
        milestoneDetails: [
            {
                isLabel: true,
                label: "",
            },
            {
                isLabel: false,
                label: "Picked-Up",
                estimate: "cargo_pickup_date",
                activeLabel: "Picked-Up",
                actual: (booking, isMilestoneAchieved) => {
                    let key = "cargo_pickup";
                    // if (booking["vorgin"] && booking["vorgin"].length > 0) {
                    //     let dayDiff = booking["vorgin"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                    //     
                    //     let maxIndex = 0;
                    //     for (let i = 1; i < dayDiff.length; i++) {
                    //         if (dayDiff[maxIndex] > dayDiff[i]) {
                    //             maxIndex = i;
                    //         }
                    //     }
                    //     return booking["vorgin"][maxIndex][key];
                    // }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "por",
        location: "por_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isPortMilestone: false,
        isMilestoneAddsToThePath: ({ por, pol }, booking) => por !== pol || booking?.dummy,
        milestoneAchievedBasedOn: ["container_handover"],
        milestoneAdvancedBasedOn: ["por_to_pol_movement"],
        milestoneCompletedBasedOn: ["gatein_pol"],
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Received",
            // },
            {
                isLabel: false,
                label: "Handover",
                activeLabel: "Handover",
                estimate: "container_handover",
                actual: "container_handover"
            },
            {
                isLabel: false,
                label: "Gate-Out",
                activeLabel: "Gate-Out",
                estimate: "por_to_pol_movement",
                actual: "por_to_pol_movement"
            }
        ]
    },
    {
        milestone: "pol",
        location: "pol_location",
        dynamicIcon: true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: () => true,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: ["gatein_pol"],
        vesselNameKey: "fl_vessel_name",
        vesselNumberKey: "fl_vessel_number",
        milestoneAdvancedBasedOn: ["sob_pol"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: ["reworking_pol_arrival", "ts1_arrival", "pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "Gate In",
                activeLabel: "Gate In",
                estimate: "gatein_pol",
                actual: "gatein_pol"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "SOB",
                estimate: "etd_at_pol",
                actual: "sob_pol"
            }
        ]
    },
    {
        milestone: "re-working",
        location: "reworking_pol_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ reworking_pol_port }) => reworking_pol_port && reworking_pol_port.length > 0,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: ["reworking_pol_arrival"],
        vesselNameKey: "reworking_pol_vessel_name",
        vesselNumberKey: "reworking_pol_vessel_number",
        milestoneAdvancedBasedOn: ["reworking_pol_departure"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: ["ts1_arrival", "pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "reworking_pol_arrival_estimate",
                actual: "reworking_pol_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "reworking_pol_departure_estimate",
                actual: "reworking_pol_departure"
            }
        ]
    },
    {
        milestone: "ts1_port",
        location: "ts1_port_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        isMilestoneAddsToThePath: ({ ts1_port }) => ts1_port && ts1_port.length > 0,
        milestoneAchievedBasedOn: ["ts1_arrival"],
        milestoneAdvancedBasedOn: ["ts1_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "ts1_vessel_number",
        milestoneCompletedBasedOn: ["reworking_pod_arrival", "pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts1_arrival_estimate",
                actual: "ts1_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts1_departure_estimate",
                actual: "ts1_departure"
            }
        ]
    },
    {
        milestone: "ts2_port",
        location: "ts2_port_location",
        dynamicIcon: true,
        isPortMilestone: true,
        isMilestoneAddsToThePath: ({ ts2_port }) => ts2_port && ts2_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        milestoneAchievedBasedOn: ["ts2_arrival"],
        milestoneAdvancedBasedOn: ["ts2_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts3_arrival", "ts4_arrival", "reworking_pod_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        vesselNameKey: "ts2_vessel_name",
        vesselNumberKey: "ts2_vessel_number",
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts2_arrival_estimate",
                actual: "ts2_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts2_departure_estimate",
                actual: "ts2_departure"
            }
        ]
    },
    {
        milestone: "ts3_port",
        location: "ts3_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts3_port }) => ts3_port && ts3_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts3_vessel_name",
        vesselNumberKey: "ts3_vessel_number",
        milestoneAchievedBasedOn: ["ts3_arrival"],
        milestoneAdvancedBasedOn: ["ts3_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["ts4_arrival", "reworking_pod_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts3_arrival_estimate",
                actual: "ts3_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts3_departure_estimate",
                actual: "ts3_departure"
            }
        ]
    },
    {
        milestone: "ts4_port",
        location: "ts4_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ ts4_port }) => ts4_port && ts4_port.length > 0,
        downStreamMilestone: () => true,
        isPortMilestone: true,
        vesselNameKey: "ts4_vessel_name",
        vesselNumberKey: "ts4_vessel_number",
        milestoneAchievedBasedOn: ["ts4_arrival"],
        milestoneAdvancedBasedOn: ["ts4_departure"],
        milestoneCompletedBasedOn: (booking, index) => {
            let milestoneKeys = ["gatein_fpod"]
            let otherMileStoneKeys = ["reworking_pod_arrival", "pod_arrival"];
            if (Utils.isValueExists(otherMileStoneKeys, booking)) {
                return true;
            }
            if (booking["containerlist"] && booking["containerlist"].length > 0) {
                if (index > -1) {
                    return Utils.isValueExists(milestoneKeys, booking["containerlist"][index])
                }
                return booking["containerlist"].map((container) => milestoneKeys.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            }
            return false
        },
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts4_arrival_estimate",
                actual: "ts4_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts4_departure_estimate",
                actual: "ts4_departure"
            }
        ]
    },
    {
        milestone: "re-working-pod",
        location: "reworking_pod_port_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ reworking_pod_port }) => reworking_pod_port && reworking_pod_port.length > 0,
        downStreamMilestone: () => true,
        milestoneAchievedBasedOn: ["reworking_pod_arrival"],
        vesselNameKey: "reworking_pod_vessel_name",
        vesselNumberKey: "reworking_pod_vessel_number",
        milestoneAdvancedBasedOn: ["reworking_pod_departure"],
        isPortMilestone: true,
        milestoneCompletedBasedOn: ["pod_arrival", "gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "reworking_pod_arrival_estimate",
                actual: "reworking_pod_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "reworking_pod_departure_estimate",
                actual: "reworking_pod_departure"
            }
        ]
    },
    {
        milestone: "pod",
        location: "pod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: ({ pod, fpod }) => pod !== fpod,
        downStreamMilestone: () => true,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ["pod_arrival"],
        milestoneAdvancedBasedOn: ["gateout_to_fpod"],
        milestoneCompletedBasedOn: ["gatein_fpod"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_at_pod",
                actual: "pod_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "etd_pod",
                actual: "gateout_to_fpod"
            }
        ]
    },
    {
        milestone: "fpod",
        location: "fpod_location",
        dynamicIcon: true,
        isMilestoneAddsToThePath: () => true,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ["gatein_fpod"],
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["atdfrompod"];
            // if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // }
            return false
        },
        milestoneCompletedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];
            // if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // }
            return false
        },
        downStreamMilestone: ({ shipmentScope }) => true,
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_fpod",
                actual: "gatein_fpod"
            },
            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "container_pickup_fpod",
                actual: (booking, isMilestoneAchieved) => {
                    let key = "atdfrompod";
                    if (isMilestoneAchieved) {
                        if (booking["vdes"] && booking["vdes"].length > 0) {
                            let dayDiff = booking["vdes"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                            let maxIndex = 0;
                            for (let i = 1; i < dayDiff.length; i++) {
                                if (dayDiff[maxIndex] > dayDiff[i]) {
                                    maxIndex = i;
                                }
                            }
                            return booking["vdes"][maxIndex][key];
                        }
                    }
                    return "";
                }
            }
        ]
    },
    {
        milestone: "destination",
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];
            // if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // }
            return false
        },
        milestoneAdvancedBasedOn: (booking) => {
            let dockMileStone = ["actual_delivery_date"];
            // if (booking.shipmentScope.split("-")[2].toLowerCase() === "door") {
            //     if (booking["vdes"] && booking["vdes"].length > 0) {
            //         return booking["vdes"].map((container) => dockMileStone.some((point) => Utils.getValueForTheGivenKey(point, container))).some((isTrue) => isTrue);
            //     }
            // }
            return false
        },
        downStreamMilestone: () => false,
        // isMilestoneAddsToThePath: ({ shipmentScope }) => shipmentScope.split("-")[2].toLowerCase() === "door",
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Delivery"
            // },
            {
                isLabel: false,
                label: "Estimated Delivery Date",
                activeLabel: "Delivered On",
                estimate: (booking, isMilestoneAchieved) => {
                    let key = "estimate_delivery_date_to_consignee";
                    if (booking["vdes"] && booking["vdes"].length > 0) {
                        let dayDiff = booking["vdes"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["vdes"][maxIndex][key];
                    }
                    return "";
                },
                actual: (booking, isMilestoneAchieved) => {
                    let key = "actual_delivery_date";
                    if (booking["vdes"] && booking["vdes"].length > 0) {
                        let dayDiff = booking["vdes"].map((container) => Utils.getDateDifferenceForProgress(container[key], new Date()));
                        let maxIndex = 0;
                        for (let i = 1; i < dayDiff.length; i++) {
                            if (dayDiff[maxIndex] > dayDiff[i]) {
                                maxIndex = i;
                            }
                        }
                        return booking["vdes"][maxIndex][key];
                    }
                    return "";
                }
            }
        ]
    },
]

export const AIR = [
    {
        downStreamMilestone: () => true,
        milestone: "origin",
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ["cargo_pickup_date"],
        milestoneAdvancedBasedOn: ["cargo_pickup_date"],
        milestoneCompletedBasedOn: ["loaded_on_board"],
        isMilestoneAddsToThePath: (booking) => booking.origin,
        milestoneDetails: [

            {
                isLabel: false,
                label: "Picked-Up",
                activeLabel: "Picked-Up",
                estimate: "etd_airport_of_departure",
                actual: "loaded_on_board"
            }
        ]
    },
    {
        milestone: "aod",
        location: "departure_airport_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,    
        isPortMilestone: true,
        flightKey: "flight_number_departure_airport",
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: ["arrival_at_departure_airport", "loaded_on_board","arrival_dispatch_airport"],
        milestoneAdvancedBasedOn: ["loaded_on_board"],
        milestoneCompletedBasedOn: ["ts1_arrival", "ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "Gate-In",
                activeLabel: "Gate-In",
                estimate: "arrival_at_departure_airport",
                actual: "arrival_dispatch_airport"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "etd_airport_of_departure",
                actual: "loaded_on_board"
            }
        ]
    },
    {
        milestone: "ts1_airport",
        location: "ts1_airport_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        flightKey:'flight_number_ts1',
        isMilestoneAddsToThePath: ({ ts1_airport }) => ts1_airport && ts1_airport.length > 0,
        milestoneAchievedBasedOn: ["ts1_arrival"],
        milestoneAdvancedBasedOn: ["ts1_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "flight_number_ts1",
        milestoneCompletedBasedOn: ["ts2_arrival", "ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts1_arrival_estimate",
                actual: "ts1_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts1_departure_estimate",
                actual: "ts1_departure"
            }
        ]
    },
    {
        milestone: "ts2_airport",
        location: "ts2_airport_location",
        dynamicIcon: true,
        downStreamMilestone: () => true,
        flightKey:'flight_number_ts2',
        isMilestoneAddsToThePath: ({ ts2_airport }) => ts2_airport && ts2_airport.length > 0,
        milestoneAchievedBasedOn: ["ts2_arrival"],
        milestoneAdvancedBasedOn: ["ts2_departure"],
        isPortMilestone: true,
        vesselNameKey: "ts1_vessel_name",
        vesselNumberKey: "flight_number_ts2",
        milestoneCompletedBasedOn: ["ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "ts2_arrival_estimate",
                actual: "ts2_arrival"
            },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "ATD",
                estimate: "ts2_departure_estimate",
                actual: "ts2_departure"
            }
        ]
    },
    {
        milestone: "aoa",
        location: "arrival_airport_location",
        dynamicIcon: true,
        downStreamMilestone: (booking) => booking.destination,
        isPortMilestone: false,
        isMilestoneAddsToThePath: () => true,
        milestoneAchievedBasedOn: ["ata_at_arrival_airport"],
        milestoneAdvancedBasedOn: ["ata_at_arrival_airport"],
        milestoneCompletedBasedOn: ["ata_at_arrival_airport"],
        milestoneDetails: [
            {
                isLabel: false,
                label: "ETA",
                activeLabel: "ATA",
                estimate: "eta_at_arrival_airport",
                actual: "ata_at_arrival_airport"//ata_at_arrival_airport
            },
            {
                isLabel: false,
                label: "Gate-Out",
                activeLabel: "Gate-Out",
                estimate: "cargo_pickup_airport_of_arrival",
                actual: "cargo_pickup_airport_of_arrival"
            }
        ]
    },
    {
        milestone: "destination",
        dynamicIcon: false,
        isPortMilestone: false,
        milestoneAchievedBasedOn: ["cargo_delivery"],
        downStreamMilestone: () => false,
        isMilestoneAddsToThePath: (booking) => booking.destination,
        milestoneDetails: [
            // {
            //     isLabel: true,
            //     label: "Cargo Delivery"
            // },
            {
                isLabel: false,
                label: "ETD",
                activeLabel: "Delivered",
                estimate: "eta_date_of_delivery_to_consignee",
                actual: "cargo_delivery"
            }
        ]
    },
]