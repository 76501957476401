import React, { useEffect, useState } from 'react';
import AguserApi from '../state/AguserApi';

export default function AcceptRegistration({ id }) {
    useEffect(() => {
        setTimeout(()=>{
            AguserApi.updateUserStatus(id,'ACCEPTED')
        } , 3000)
       
    }, [])
    return (
        <>
            <div className='box has-text-centered'>
                <strong>Registration Completed</strong>
                <p>
                    <strong>Thank You</strong>
                </p>
                <hr />
                <p>
                    <div className='box has-text-centered'>
                        You are now registered successfully. You can use the application once your registration is activated.
                    </div>
                </p>
                <div className='box has-text-centered'>
                    <p>
                        We appreciate your patience
                    </p>
                    <p>
                        <strong>Team Agraga</strong>
                    </p>
                </div>
            </div>

        </>
    )
}
