import { Box, Button, Checkbox, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import logo from "../assets/images/Logo-new.svg"
import PhoneNoField from '../mui-components/PhoneNoField'
import PhoneField from '../mui-components/PhoneField'
import Carousel from '../mui-components/Carousel'
import { Controller, useForm } from "react-hook-form";
import { aguserStore, configStore, loginStore } from '../state/CommonState'
import AguserApi from '../state/AguserApi'
import CircleIcon from '@mui/icons-material/Circle';
import Select from 'react-select'
import { City, Country, State } from "country-state-city";

// import { useMode } from '../context/AuthContext'
function SignUpPageNew({callback, source}) {
  // const {setMode : callback} = useMode();
  const [Countries, setCountries] = useState([]);
  const DefaultIndiaValue = {
    "name": "India",
    "isoCode": "IN",
    "flag": "🇮🇳",
    "phonecode": "91",
    "currency": "INR",
    "latitude": "20.00000000",
    "longitude": "77.00000000",
    "timezones": [
        {
            "zoneName": "Asia/Kolkata",
            "gmtOffset": 19800,
            "gmtOffsetName": "UTC+05:30",
            "abbreviation": "IST",
            "tzName": "Indian Standard Time"
        }
    ]
}
const [value, setValueState] = useState({
  country:DefaultIndiaValue,
  city:"",
  state:""
})
  const filterCountries = () => {
    const array = Country.getAllCountries().filter(
      (x, i) => State.getStatesOfCountry(x.isoCode).length !== 0
    );
    setCountries(array);
  };
  const [error, setError] = useState({
      password:"",
      email:"",
      confirmPassword:"",
      phone:""
    }
  )
    const [passwordCondition, setpasswordCondition] = useState({
      lower: false,
      uper: false,
      number:false,
      specialCharecter:false,
      length:false
    });
    const signupForm = useForm({
      defaultValues:{
          firstName: aguserStore.aguserRec.firstName ?? "",
          lastName : aguserStore.aguserRec.lastName ??  "",
          companyName : aguserStore.aguserRec.companyName ??  "",
          country : aguserStore.aguserRec.country ??  "",
          email:aguserStore.aguserRec.email ?? "",
          phone:aguserStore.aguserRec.phone ?? "",
          password :aguserStore.aguserRec.password ?? "",
          confirmPassword :aguserStore.aguserRec.password ?? "",
          code:aguserStore.aguserRec.phonePrefix ?? "",
      }
  })
  const {register,handleSubmit,clearErrors,watch,formState:{errors},setValue,getValues,reset,control} = signupForm;
  console.log(errors);
  console.log(error);
  const submit = async(data)=>{
    console.log("The sign up data ",data);
    if (error.email || error.password || error.confirmPassword || error.phone || error.code) {
      return
    }
    console.log("inside api",aguserStore.aguserRec);
    try{
       aguserStore.aguserRec.email = data.email.trim().toLowerCase();
       aguserStore.aguserRec.firstName = data.firstName;
       aguserStore.aguserRec.lastName = data.lastName;
       aguserStore.aguserRec.password = data.password;
       aguserStore.aguserRec.phone = data.phone;
       aguserStore.aguserRec.phonePrefix = data.code;
       aguserStore.aguserRec.country = data.country?.name;
       aguserStore.aguserRec.companyName = data.companyName;
       aguserStore.aguserRec.status = "NEWREG";
       aguserStore.aguserRec.role = ["CLIENT ADMIN"];
       localStorage.setItem("OtpVerify",data.email)
       loginStore.email = data.email
    //  AguserApi.NEWregisterNewUser(
    //    (user)=>{
    //     console.log("user",user);
    //     localStorage.setItem('ag_email', user.email)
    //     loginStore.email = user.email;
    //     loginStore.isShowClientDashBoard = user?.role
    //     loginStore.userRec.aguserRec = user
    //     const userRecString = JSON.stringify(user)
    //     localStorage.setItem("ag_remember_user",true);
    //     localStorage.setItem('ag_userrec', userRecString)
    //     localStorage.setItem('devicetoken', user.aguserRec.token)
    //     localStorage.setItem('tour', user.aguserRec.tour)
    //     callback('entity');  
    //     configStore.setModalMessage("You are successfully signed up");
    //    }
    //  )
    await AguserApi.GetOTP({email:data.email.trim().toLowerCase(),userRec:aguserStore.aguserRec}, (user)=>{
      console.log(user);
      callback("verifyEmail")
      source("signupOTP")
       configStore.setModalMessage("OTP has been sent your email Id");
      }
    )
    }catch(e){ 
       if(e.code==="ERR_NETWORK"){
        configStore.setModalMessage("Something went wrong");
        return ;
       }

       if(e.response?.status === 401 && e.response?.data?.error !== "Invalid Token" ){
        console.log("The erroror in signup is ", e.response?.data?.error)
        configStore.setModalMessage(e.response.data.error);
        return ;
     }
    }
}
const handleChange = (type,e)=>{
  console.log(e.target.value);
  if (type === "email") {
    e.target.value = e.target?.value?.toLowerCase()
    if ( e.target.value?.includes("@agraga.com")) {
      setError(prevState => {
        return {...prevState,...{email : "Agraga Users Not Allowed"}};
      })
      return
    }
   

    let emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let ok =   emailCheck.test(e.target.value)
    console.log(ok);
    ok?setError(prevState => {
      return {...prevState,...{email : ""}};
    }):      setError(prevState => {
      return {...prevState,...{email : "Invalid email address"}};
    })
  }
  if(type === "password"){
    checkStrength(e.target.value)
  }
  if (type === "confirmPassword" || type === "password") {
    if (getValues("password") !== getValues("confirmPassword")) {
      setError(prevState => {
        return {...prevState,...{confirmPassword : "The confirmed password does not match."}};
      });
    }
    else{
      setError(prevState => {
        return {...prevState,...{confirmPassword : ""}};
      });
    }
    
  }
}

const strength = { 
	1: "very Weak", 
	2: "Weak", 
	3: "Meduim", 
	4: "Strong", 
	5: "Strong+++", 
}; 
function checkStrength(pass) { 
  let count = 0; 
	if (pass.length >= 8) {
    count++
  setpasswordCondition(prevState => {
    return {...prevState,...{length : true}};
  });
}else {
    setpasswordCondition(prevState => {
      return {...prevState,...{length : false}};
    });
  }
	let regex1 = /[a-z]/; 
	if (regex1.test(pass)) {
      count++
      setpasswordCondition(prevState => {
        return {...prevState,...{lower : true}};
      });
    }else{
      setpasswordCondition(prevState => {
        return {...prevState,...{lower : false}};
      });
  }
	let regex2 = /[A-Z]/; 
	if (regex2.test(pass)){
     count++
     setpasswordCondition(prevState => {
      return {...prevState,...{uper : true}};
    });
    }else{
      setpasswordCondition(prevState => {
        return {...prevState,...{uper : false}};
      });
    }
	let regex3 = /[\d]/; 
	if (regex3.test(pass)){
     count++
     setpasswordCondition(prevState => {
      return {...prevState,...{number : true}};
    }); 
    }else{
      setpasswordCondition(prevState => {
        return {...prevState,...{number : false}};
      }); 
    }
	let regex4 = /[!@#$%^&*.?]/; 
	if (regex4.test(pass)){
     count++
     setpasswordCondition(prevState => {
      return {...prevState,...{specialCharecter : true}};
    });
    }else{
      setpasswordCondition(prevState => {
        return {...prevState,...{specialCharecter : false}};
      });
    }
    if (count < 5) {
      setError(prevState => {
        return {...prevState,...{password : "Please add all necessary characters to create safe password."}};
      });
    }
    else{
      setError(prevState => {
        return {...prevState,...{password : ""}};
      });
    }
	console.log(pass, "Pasword is " + strength[count],count); 
} 

useEffect(() => {
  console.log(passwordCondition);
  filterCountries()
  if (aguserStore.aguserRec.password) {
    checkStrength(aguserStore.aguserRec.password)
  }
}, [])




  return (
    <>
  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
    <div className='logo_container_for_mobile'>
         <img src={logo}></img>
    </div>
      <div className='field_wrapper signup_wrapper'>
      <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={"1rem"} gutterBottom>Let’s get started</Typography>
      <div className='signup_field_wrapper'>

        <div className="signup_container" >
            <InputField 
            placeholder={"Enter First Name"}
            label={"First Name"}
            label_class={"label_styles"}
            mandatory={true}
            input_class={"inputBox_styles"}
            errorsmsg={errors?.firstName?.message}
            input_wrapeer={"input_wrapeer"}
            callback={(e)=>console.log(e)}
            maxLength={30}
            children={{...register("firstName",{onChange:(e)=>handleChange("firstName",e),required: "Please enter your first name."})}}
            ></InputField>
            <InputField 
            placeholder={"Enter Last Name"}
            label={"Last Name"}
            label_class={"label_styles"}
            mandatory={false}
            input_class={"inputBox_styles"}
            hideErr={true}
            input_wrapeer={"input_wrapeer"}
            callback={(e)=>console.log(e)}
            maxLength={30}
            children={{...register("lastName",{onChange:(e)=>handleChange("lastName",e)})}}
            ></InputField>
        </div>
        <div className="signup_container">
        <InputField 
      placeholder={"Enter Company name"}
      label={"Company Name"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.companyName?.message}
      input_wrapeer={"input_wrapeer"}
      callback={(e)=>console.log(e)}
      maxLength={30}
      children={{...register("companyName",{onChange:(e)=>handleChange("companyName",e),required: "Please enter your Company name."})}}
      ></InputField>
        <div className={"input_wrapeer"}>

<p className={"label_styles"} >
        Country*
                  </p>
        <div className={""} >
            <Controller
            name={"country"}
            control={control}
            rules={{ required: "Please select your Country" }}
            defaultValue='India'
            render={({ field,formState:{errors}  }) => (
              <>
              <Select
                {...field}
                className={`selectBox_styles ${errors?.country?"border_red":""}`}
                classNamePrefix="select"
                // isClearable={true}
                isSearchable={true}
                options={Countries}
                placeholder={getValues("country")}
                // defaultValue={{ label: "name", value: "India" }}
                getOptionLabel={(options) => options['name']}
                getOptionValue={(options) => options['flag']}
                value={getValues("country") || setValue("country",DefaultIndiaValue)}
                onChange={(e)=> setValueState(prevState => {
                  setValue("country",e)
                  setValue("state","")
                  setValue("city","")
                  setValue("pan","")
                  setValue("tin","")
                  setValue("address","")
                  setValue("gst","")
                  // setError("city", {
                  //   type: "manual",
                  //   message: "City is Required",
                  // })
                  // setError("state", {
                  //   type: "manual",
                  //   message: "State is Required",
                  // })
                  if (getValues("country")) {
                  clearErrors("country")
                  }
                  console.log(getValues("country"));
                  return {...prevState,...{country : e,state:"",city:""}};
                })}
              />
                  <p className="help is-input-danger-message sm_none">{errors?.country?errors?.country?.message:<span>&nbsp;</span>}</p> 
              </>
            )}
            
          />
        </div>
        </div>
        </div>

        <div className="signup_container">
        <InputField 
      placeholder={"Enter your mail ID"}
      label={"Email"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      // disabled={true}
      errorsmsg={errors?.email?.message || error.email}
      input_wrapeer={"input_wrapeer"}
      callback={(e)=>console.log(e)}
      // onkeyup={(e)=> e.target.value = e.target?.value?.toLowerCase()}
      children={{...register("email",{onChange:(e)=>handleChange("email",e),required: "Please enter your email.",
      pattern:{
        value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message : `Invalid email address`
      },
    })}}
      ></InputField>
        <PhoneField creditFlag={true} customStyleClass={'marginBottom1rem'} callback={(e)=> {
          console.log(e)
          setValue("phone",e?.number)
          setValue("code",e?.code)
          setError(prevState => {
            return {...prevState,...{phone : e.error}};
          })
        }}></PhoneField>
        </div>

        <div className="signup_container">
       
      <InputField 
      placeholder={"Enter Password"}
      label={"Password"}
      label_class={"label_styles"}
      mandatory={true}
      errorsmsg={errors?.password?.message || error.password}
      input_class={"inputBox_styles width85 edgeEyeHide"}
      type={"password"}
      callback={(e)=>console.log(e)}
      container={"input_icon_styles"}
      icon_childer={true}
      id={"password"}
      input_wrapeer={"input_wrapeer"}
      children={{...register("password",{onChange:(e)=>handleChange("password",e),required: "Password is Required"})}}
      ></InputField>

      <InputField 
      placeholder={"Enter Confirm Password"}
      label={"Confirm Password*"}
      label_class={"label_styles"}
      mandatory={false}
      errorsmsg={errors?.confirmPassword?.message || error.confirmPassword}
      input_class={"inputBox_styles width85 edgeEyeHide"}
      input_wrapeer={"input_wrapeer"}
      type={"password"}
      callback={(e)=>console.log(e)}
      container={"input_icon_styles"}
      id={"confirmPassword"}
      icon_childer={true}
      children={{...register("confirmPassword",{onChange:(e)=>handleChange("confirmPassword",e),required: "Confirm Password is Required"})}}
      ></InputField>
        </div>
        <div className='input_wrapeer sm_phoneField'>{getValues("password")?<div className='password_wrapper'>
        <div>
      <p style={{fontSize:"10px",color:passwordCondition.lower?"#000":"red"}}><CircleIcon fontSize='2px'/> One lowercase character</p>
      <p style={{fontSize:"10px",color:passwordCondition.uper?"#000":"red"}}><CircleIcon fontSize='2px'/> One uppercase character</p>
      <p style={{fontSize:"10px",color:passwordCondition.number?"#000":"red"}}><CircleIcon fontSize='2px'/> One number</p>
        </div>
        <div>
      <p style={{fontSize:"10px",color:passwordCondition.specialCharecter?"#000":"red"}}><CircleIcon fontSize='2px'/> One symbol</p>
      <p style={{fontSize:"10px",color:passwordCondition.length?"#000":"red"}}><CircleIcon fontSize='2px'/> 8 characters minimum</p>
        </div>
      </div>:null}
      </div>
      </div>
        <Button variant="contained" className='btn_color' fullWidth  onClick={handleSubmit(submit)} 
        // onClick={()=>{callback('entity')}}
        >Sign Up</Button>
      </div>
      
      <p className='note_info'>Have an account? <span className='clr_blue' onClick={()=>{
            callback('login')
          }}>Login</span></p>
                        <p className='note_info2'>  <span className='clr_blue' onClick={()=>{
            callback('trackshipment') 
          }}>Back to Track Shipment</span></p>
    </div>

  </div>
    
    </>
  )
}


export default SignUpPageNew