import Api from "../state/Api"
import { configStore } from "../state/CommonState"
import { fileStore } from "../state/fileStore"
import { useSnapshot } from "valtio"
import { loader } from "../util/loader"

export default function AgFile2(props) {
    useSnapshot(fileStore)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    let parentid = ['mf1','td1','td2','td3','td4','td5','td6','td7','td8','td9','td10','td11','td12','td13','td16','td17','td18','td19']
    console.log('Inside Agfile2', props.source, props.cat, props.filetype)
    const {filetypes, form_data, callback, url = '/api/v1/common/receivefile'} = props
    var parent_container=null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(parentid.length>0){
            parent_container = e.target;
            do {
                if (!parent_container) break
                parent_container = parent_container.parentNode;

                if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                change(i[0].files,fileStore.props[parent_container.id])
            }
        }
    };
    if(parentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }

    function change(files,props){
        const {parent} = props
        const {filetypes, form_data, callback, url = '/api/v1/common/receivefile'} = props
        const formData = new FormData()
        formData.append('myFile', files[0])
        let keys = Object.keys(form_data)
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            formData.append(key, form_data[key])
        }
        let fetchurl = `${process.env.REACT_APP_API_SERVER}${url}`
        if (url.includes('pricing')) fetchurl = `${process.env.REACT_APP_PRICINGAPI_SERVER}${url}`
        fetch(fetchurl, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { configStore.setModalMessage(data.error) }}
                else {
                    //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                    callback(data)
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }
    
    return (
        <div>
        <input type="file" accept={filetypes} style={{fontSize: '0.8rem'}} 

        onChange={(e) => {
            // change(e.target.files,props)
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
        }} />
        </div>
    )
}

export function AgFile2withloader(props) {
    useSnapshot(fileStore)
    useSnapshot(loader)
    const {parent} = props
    if(parent){
        if(fileStore.props[parent]==undefined){
            fileStore.props[parent] = props
        }
    }
    let parentid = ['mf1','mf2','td1','td2','td3','td4','td5','td6','td7','td8','td9','td10','td11','td12','td13','td16','td17','td18','td19']
    console.log('Inside Agfile2', props.source, props.cat, props.filetype)
    const {filetypes, form_data, callback, url = '/api/v1/common/receivefile'} = props
    var parent_container=null
    var newHandle = function(e) { 
        let parent_container1 = null
        if(parentid.length>0){
            parent_container = e.target;
            do {
                if (!parent_container) break
                parent_container = parent_container.parentNode;

                if (parent_container && parentid.indexOf(parent_container.id) == -1) {
                    parent_container1 = null
                }else{
                    parent_container1 = parent_container
                }
            }
            while( parent_container1==null && parent_container !== document.body );
            parent_container = parent_container1
        }
    };
    var newHandle1 = function(e) { 
        if(parent && parent_container){
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if(e.clipboardData.files.length>0){
                change(i[0].files,fileStore.props[parent_container.id])
            }
        }
    };
    if(parentid.length>0){
        document.getElementsByTagName('body')[0].onclick = function(e) {newHandle(e)}
        document.getElementsByTagName('body')[0].onpaste = function(e) {newHandle1(e)}
    
    }

    function change(files,props){
        const {parent} = props
        const {filetypes, form_data, callback, url = '/api/v1/common/receivefile'} = props
        const formData = new FormData()
        formData.append('myFile', files[0])
        let keys = Object.keys(form_data)
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            formData.append(key, form_data[key])
        }
        loader.show()
        fetch(`${process.env.REACT_APP_API_SERVER}${url}`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                if (data.error) { if (data.error!='No Error' || data.error!='Invalid Token') { {
                    loader.hide()
                    configStore.setModalMessage(data.error) }}
                }
                else {
                    //console.log('setting the filelink',  `/clientfiles/${files[0].name}`)
                    callback(data)
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                loader.hide()
                console.error(error)
            })
    }
    
    return (
        <div>
        <input type="file" accept={filetypes} style={{fontSize: '0.8rem'}} 

        onChange={(e) => {
            // change(e.target.files,props)
            const pattern = /^[a-zA-Z0-9_ .()-]+$/;
            if(pattern.test(e.target.files[0]['name'])){
                change(e.target.files,props)
            }else{
                configStore.setModalMessage("Please Check File Name and Format")
                return e.target.value = ""
            }
        }} />
        </div>
    )
}