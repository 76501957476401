import { useEffect, useState } from "react"
import MonApi from "../state/mongo-api"
import { Col1, Col2, Col3, Cols } from "../Controls"
import { useSnapshot } from "valtio"
import { rtpStore } from "./pricing-store"
import PricingMarkupEdit from "./pricing-markup-edit"

export default function PricingMarkups(props) {
    useSnapshot(rtpStore)
    const [data, setData] = useState([])

    useEffect(() => {
        const f = async () => {
            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/metadata/markup/get`
            let ret = await MonApi.aget(url)
            rtpStore.markupMetadata = ret
        }
        f()
    }, [])
    useEffect(() => {

        let val = []
        let elem = { e1: {}, e2: {} }
        for (let i = 0; i < rtpStore.markupMetadata.length; i++) {
            let o = rtpStore.markupMetadata[i]
            if (i % 2 == 0) {
                elem = { e1: o, e2: {} }
                val.push(elem)
            } else {
                elem.e2 = o
            }
        }
        setData(val)
        //console.log('\n\n\n\nval=', val,'\n\n\n')
    }, [rtpStore.markupMetadata])

    if (rtpStore.markupView == 'edit') {
        return <PricingMarkupEdit />
    }
    return (
        <>
            {
                data.map((e, i) => {
                    return <Cols>
                        <Col1></Col1>
                        <Col3 style={{ borderBottom: '1px solid lightgrey' }}>{e.e1.chargeName}</Col3>
                        <Col1 style={{ borderBottom: '1px solid lightgrey' }}>
                            <button className="button is-dark is-inverted"
                                onClick={() => {
                                    rtpStore.markupView = 'edit'
                                    rtpStore.selectedMarkupCharge = e.e1
                                }}>
                                <span className="icon is-small">
                                    <i className="fas fa-pen-to-square"></i>
                                </span></button>
                        </Col1>
                        <Col1></Col1>
                        {
                            'chargeName' in e.e2 &&
                            <>
                                <Col3 style={{ borderBottom: '1px solid lightgrey' }}>{e.e2.chargeName}</Col3>

                                <Col1 style={{ borderBottom: '1px solid lightgrey' }}>
                                    <button className="button is-dark is-inverted"
                                        onClick={() => {
                                            rtpStore.markupView = 'edit'
                                            rtpStore.selectedMarkupCharge = e.e2
                                        }}>
                                        <span className="icon is-small">
                                            <i className="fas fa-pen-to-square"></i>
                                        </span></button>
                                </Col1>
                            </>
                        }
                    </Cols >
                })
            }
            <Cols>
                <Col1></Col1>
                <Col3 style={{ borderBottom: '1px solid lightgrey' }}>Transport</Col3>
                <Col1 style={{ borderBottom: '1px solid lightgrey' }}>
                    <button className="button is-dark is-inverted"
                        onClick={() => {
                            rtpStore.markupView = 'edit'
                            rtpStore.selectedMarkupCharge = {
                                chargeKey: 'transport',
                                chargeName: 'Transport'
                            }
                        }}>
                        <span className="icon is-small">
                            <i className="fas fa-pen-to-square"></i>
                        </span></button>
                </Col1>
            </Cols>
        </>
    )
}