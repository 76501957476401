export const HorizontalLine = ({ width,stroke = "black" }) => {
    return (
        <svg width={width} height="1" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="0" x2={width} y2="0" stroke={stroke||"black"} strokeWidth="1" />
        </svg>
    );
};

export const HorizontalDashedLine = ({ stroke = "black", width, dashArray,height="1",strokeWidth="1" }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <line
        x1="0"
        y1="0"
        x2={width}
        y2="0"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
      />
    </svg>
  );
};


