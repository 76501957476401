import {
  AgAirline,
  AgCommodity,
  AgInput,
  AgSelect,
  AgSelect1,
  AgUnloc,
  EmailListComp,
  VanishMessage,
} from "../components/AgComponents";
// import {
//    cargoStore
// } from './state/CommonState'

import Api from "../state/Api";
import { Columns, Col, Col2, Col3, Col4, Col6, Col12 } from "../Controls";
import CargoComp from "../CargoComp";
import AgTable from "../components/AgTable";
import {
  packingGroupList,
  getHazardousClassList,
  getStaticConsolidatorList,
  stuffingTypes,
  deStuffingTypes,
  commodityList,
  hazardousList,
} from "../StaticData";
import {
  configStore,
  quoteDataRec,
  toEmailStore,
  ccEmailStore,
  chargesStore1,
  airChargesStore,
  customerStore,
  entityStore,
  aguserStore,
  loginStore,
  multiSelectListStore,
  dataListStore,
  modalStore,
  EntityV2Store,
  AgmodalStore,
} from "../state/CommonState";
import { containerStore, cargoStore } from "../state/CommonState";
import {
  shipmentTypeList,
  shipmentScopeList,
  incoTermsList,
} from "../StaticData";
import { useSnapshot } from "valtio";
import ContainerComp from "../ContainerComp";
import ChargesComp from "../ChargesComp";
import { chargesData, chargesData1 } from "../StaticData";
import { chargesStore, fclChargesStore } from "../state/CommonState";
import FCLChargesComp from "../FCLChargesComp";
import Utils from "../util/Utils";
import ChargesComp1 from "../ChargesComp1";
import AgCustlist from "../components/AgCustlist";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import ChargesAirComp from "../ChargesAirComp";
import AddCustomer from "../AddCustomer";
import { Agyesno } from "../components/Agyesno";
import {
  AgMultiSelectWithCheckBoxes,
  AgSelectWithMap,
} from "../components/AgCustomComponents";
import {
  chargesValidationStore,
  QuotationValidationStore,
} from "../state/validation-stores";
import QuotationUtils from "../util/quotationUtil";
import AguserApi from "../state/AguserApi.js";
import { AgGoogleMap } from "../components/ag-address/ag-address";
import LCLChargesCompv2 from "./lcl-charges-v2";
import AirChargesCompv2 from "./air-charges-v2";
import FCLChargesCompv2 from "./fcl-charges-v2";
import VehicleView from "./vehicle-view";
import RoleUtils from "../util/RoleUtils.js";
import { PERMISSIONS } from "../util/constants.js";
import { taskStore } from "../mytasks/task-store.js";
import Taskapi, { Task } from "../mytasks/tasks-api.js";
import EntityApi from "../state/entity-api.js";
import EntityV2Api from "../state/entities-api.js";


export default function Quotev2() {
  useSnapshot(configStore);
  useSnapshot(customerStore);
  useSnapshot(multiSelectListStore);
  useSnapshot(dataListStore);
  const containerDetails = useSnapshot(containerStore);
  useSnapshot(modalStore);
  useSnapshot(entityStore);
  
useSnapshot(EntityV2Store)
useSnapshot(taskStore)
useSnapshot(quoteDataRec)
useSnapshot(QuotationValidationStore);
  const selectedConsolidatorList = getStaticConsolidatorList();
  const consolidatorListKey = "consolidatorList";
  const [space, setSpace] = useState("");
  const [cursor, setCursor] = useState(0);
  const ref = useRef(null);
  const [entitieslist, setEntitieslist] = useState([]);
  const [prList, setPrList] = useState({})
  useLayoutEffect(() => {
    let list = []
    if(quoteDataRec.isfromAgragaEnquiry == 'Yes'){
      let v2StoreList = EntityV2Store.list
      for (let i = 0; i < v2StoreList.length; i++) {
        if(v2StoreList[i]['entityName'] === 'YET TO BE ASSIGNED' || v2StoreList[i]['entityType'] === 'Customer'){
          list.push(v2StoreList[i])
        }
      }
    }else{
      for (let i = 0; i < EntityV2Store.Cuslist.length; i++) {
        if(EntityV2Store.Cuslist[i]['entityName'] !='YET TO BE ASSIGNED'){
          list.push(EntityV2Store.Cuslist[i])
        }
      }
    }
    if(JSON.stringify(EntityV2Store.list)!=list){
      setEntitieslist(list)
    }
  },[quoteDataRec.isfromAgragaEnquiry,EntityV2Store.list])
  const getExchangerates = async( ) =>{
    await Api.getExchangerates()
  }
  useEffect(() => {
    console.log("Inside useEffect of Quote ");
    getExchangerates()
    Api.getFCLCarrierList();
    AguserApi.getList();
  }, []);
  useEffect(() => {
    const pruserlist = (data) =>{
      let l = {}
      for (let i = 0; i < data.length; i++) {
        l[`${data[i]['firstName']} ${data[i]['lastName']} (${data[i]['email']})`] = data[i]['email']
      }
      if(JSON.stringify(prList)!=JSON.stringify(l)){
        setPrList(l)
      }
    }
    if(quoteDataRec && quoteDataRec.shipmentType){
      switch(true){
        case quoteDataRec.shipmentType.toLowerCase().indexOf("fcl") >= 0:
          AguserApi.getUsers4role_new("FCL Procurement", pruserlist)
          break
        case quoteDataRec.shipmentType.toLowerCase().indexOf("lcl") >= 0:
          AguserApi.getUsers4role_new("LCL Procurement", pruserlist)
          break
        case quoteDataRec.shipmentType.toLowerCase().indexOf("air") >= 0:
          AguserApi.getUsers4role_new("AIR Procurement", pruserlist)
          break
      }
    }

    console.log("=+",quoteDataRec.shipmentType)
    //AguserApi.getUsers4role_new("Procurement", pruserlist)
  }, [quoteDataRec.shipmentType]);
  useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor]);
  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    quoteDataRec.customTC = e.target.value;
  };
  // console.log(quoteDataRec);
  useLayoutEffect(() => {
    if (
      quoteDataRec?.shipmentType != "" &&
      quoteDataRec?.shipmentType.toLowerCase().includes("lcl")
    ) {
      if (!quoteDataRec?.quotationNum && quoteDataRec?.consolidatorList == null)
        quoteDataRec.consolidatorList = selectedConsolidatorList;
      multiSelectListStore.selectList[consolidatorListKey] =
        selectedConsolidatorList.map(({ consolidatorAbbrName,scacCode }) => ({
          id: consolidatorAbbrName,
          name: consolidatorAbbrName,
          scacCode:scacCode,
          checked: quoteDataRec.consolidatorList
            .map((addedConsolidator) => addedConsolidator.consolidatorAbbrName)
            .includes(consolidatorAbbrName),
        }));
    } else quoteDataRec.consolidatorList = null;
  }, [quoteDataRec.consolidatorList, quoteDataRec.shipmentType]);
  useEffect(() => {
    setSpace(space === "" ? " " : "");
  }, [containerStore.list, containerStore.newRec]);
  //console.log('from inside Quote. currency list = ', configStore.currencyList, configStore.getExchangeRate('USD'), 'erate')
  const _params = (label, name, type = "text", dataList = []) => {
    return {
      type,
      name,
      label,
      record: quoteDataRec,
      dataList,
    };
  };
  const factoryOrDock = (shipmentType) => {
    if (shipmentType.indexOf("FCL") < 0) return <></>;
    let label = "Stuffing Type";
    if (shipmentType.indexOf("De-stuffing") >= 0) label = "De-stuffing Type";
    let listOfValues = ["", "Factory", "Dock"];
    if (shipmentType.indexOf("Dock") >= 0) listOfValues = ["", "Dock"];
    if (shipmentType.indexOf("Factory") >= 0) listOfValues = ["", "Factory"];
    return (
      <Col3>
        <AgSelect
          {..._params(label, "factoryOrDockStuffing", "select", listOfValues)}
        />
      </Col3>
    );
  };
  const displayNonStackable = (st, sc) => Utils.displayNonStackable(st, sc);
  const portOfReceiptLable =
    quoteDataRec.shipmentType.indexOf("Air") >= 0
      ? "* Airport of Receipt"
      : "Place of Receipt";
  const portOfLoadingLabel =
    quoteDataRec.shipmentType.indexOf("Air") >= 0
      ? "* Airport of departure"
      : "* Port of Loading";
  const portOfDischargeLabel =
    quoteDataRec.shipmentType.indexOf("Air") >= 0
      ? "* Airport of Arrival"
      : "Port of Discharge";
  const displayReferenceNumber = () => {
    if (quoteDataRec.quotationNum)
      return "Reference#: " + quoteDataRec.quotationNum;
    return "";
  };

  if (quoteDataRec.shipmentType.indexOf("LCL") >= 0) {
    if (!quoteDataRec.carrier) quoteDataRec.carrier = "Agraga";
  }
  let isDisabled = {};
  let buttontext = 'Save'
  if(quoteDataRec.status == 'DRAFT(Enquiry)'){
    buttontext = 'SUBMIT'
  }else{
    buttontext = 'Save'
  }
  useEffect(() => {
    getEntityData()
    console.log("quoteDataRec",quoteDataRec);
  }, [quoteDataRec.entityId])
  const getEntityData = async ()=>{  
    if (!quoteDataRec.entityId) {
      return ""
    }
    // if (quoteDataRec.entityId.includes("TBA")) {
    //   quoteDataRec.entityId = "TBA";
    //   quoteDataRec.entityName = "TO BE ASSIGNED";
    //   quoteDataRec.salesperson = "";
    //   quoteDataRec.customerService = "";
    //   return ""
    // }

    let entity =  await EntityV2Api.viewEntity(quoteDataRec.entityId)
    console.warn("FOUND ENTITY = ", entity);
    if (entity) {
      let email = entity?.customer?.crossBorder?.relationshipManager;
      // quoteDataRec.entityId = entity?.entityId;
      // quoteDataRec.entityName = entity?.entityName;
      if(email){
        quoteDataRec.salesperson = aguserStore.getName4email(email);
      }
      else {
        quoteDataRec.salesperson = "";
      }
      email = entity?.customer?.crossBorder?.customerService;
          if (email){
        quoteDataRec.customerService = aguserStore.getName4email(email);
          }else {
        quoteDataRec.customerService = "";
      }
    }
  }
  if(quoteDataRec.shipmentType.toLowerCase().indexOf("fcl") != -1 && quoteDataRec.emptycontainerweight == undefined){
    quoteDataRec.emptycontainerweight = 'yes'
  }
  if(quoteDataRec.pick_up_by_amazon==undefined){
    quoteDataRec.fba = 'No'
  }
  if(quoteDataRec.pick_up_by_amazon==undefined){
    quoteDataRec.pick_up_by_amazon = 'No'
  }
  if(quoteDataRec.shipmentScope.toLowerCase().indexOf("to-port")==-1 && quoteDataRec.pick_up_by_amazon != 'No'){
    quoteDataRec.pick_up_by_amazon = 'No'
  }else if (quoteDataRec.shipmentScope.toLowerCase().indexOf("to-port")!=-1 && quoteDataRec.fba == 'Yes' && quoteDataRec.pick_up_by_amazon == 'No') {
    quoteDataRec.pick_up_by_amazon = 'Yes'
  }
  return (
    <>
      <div className="container is-fullheight">
        <div
          className="box"
          style={{
            top: 0,
            zIndex: 4,
            marginTop: "0px",
            backgroundColor: "#eeeeff",
            padding: "0.2rem",
          }}>
          <Columns>
            <Col3>
              <h1 className="title">Quotation</h1>
              {displayReferenceNumber()}
            </Col3>
            <Col3>
              <AgSelect1
                {..._params(
                  "* Shipment Type",
                  "shipmentType",
                  "select",
                  shipmentTypeList
                )}
                isValid={
                  QuotationValidationStore.quotationValidMap["shipmentType"]
                }
                validationMsg="- Please select one of shipment types."
                isDisabled={
                  quoteDataRec.quotationNum !== "" || configStore.disableShipmentType !== ""
                    ? { disabled: true }
                    : {}
                }
                callback={(stype) => {
                  cargoStore.resetNewCargo()
                  containerStore.list = []
                  cargoStore.cargoList = []
                  QuotationValidationStore.validate("shipmentType");
                  const data = chargesData[stype.substr(0, 3).toLowerCase()];
                  const data1 = chargesData1[stype.substr(0, 3).toLowerCase()];
                  console.log("Inside the new call back", stype, data1);
                  if (data1 && data1.initialLength) {
                    if (stype.indexOf("FCL") >= 0) {
                      fclChargesStore.colNames = data1.colNames;
                      fclChargesStore.initialLength = data1.initialLength;
                      fclChargesStore.list = data1.preList;
                      fclChargesStore.list = fclChargesStore.list.map((ele, index) => { 
                        return { ...ele, currency: data1.preList[index].currency }
                      })
                    } else if (stype.indexOf("Air") >= 0) {
                      airChargesStore.initialLength = data1.initialLength;
                      airChargesStore.list = data1.preList;
                      airChargesStore.list = airChargesStore.list.map((ele, index) => { 
                        return { ...ele, currency: data1.preList[index].currency }
                      })
                    } else {
                      //chargesStore.colNames = data.colNames
                      //chargesStore.initialLength = data.initialLength
                      //chargesStore.list = data.preList
                      chargesStore1.initialLength = data1.initialLength;
                      chargesStore1.list = data1.preList;
                      chargesStore1.list = chargesStore1.list.map((ele, index) => { 
                        return { ...ele, currency: data1.preList[index].currency }
                      });
                      quoteDataRec.carrier = "Agraga";
                    }
                  } else {
                    chargesStore.initialLength = 0;
                    chargesStore.list = [];
                    chargesStore1.initialLength = 0;
                    chargesStore1.list = [];
                    airChargesStore.initialLength = 0;
                    airChargesStore.list = [];
                  }
                }}
              />
            </Col3>
            <Col2>
              <AgSelect
                {..._params(
                  "* Shipment Scope",
                  "shipmentScope",
                  "select",
                  shipmentScopeList
                )}
                isValid={
                  QuotationValidationStore.quotationValidMap["shipmentScope"]
                }
                callback={() =>
                  QuotationValidationStore.validate("shipmentScope")
                }
                validationMsg="- Please select one of shipment scopes."
                isDisabled={
                  quoteDataRec.quotationNum !== "" ? { disabled: true } : {}
                }
              />
            </Col2>
            <Col4>
            {
                  (quoteDataRec.isfromAgragaEnquiry == 'Yes') ? 
                  <AgCustlist
                {..._params("* Customer", "entityId", "select",entitieslist)}
                isValid={QuotationValidationStore.quotationValidMap["entityId"]}
                validationMsg="- Please select one of customers."
                isDisabled={
                  quoteDataRec.quotationNum.indexOf("TBA") == -1 ? { disabled: true } : {}
                }
                // callback={(gst) => {
                //   QuotationValidationStore.validate("entityId");
                //   let entity = entityStore.list.filter((e) => e.gst === gst);
                //   console.log("FOUND ENTITY = ", entity);
                //   if (entity.length > 0) {
                //     let email = entity[0].salesPerson;
                //     quoteDataRec.salesperson = aguserStore.getName4email(email);
                //     email = entity[0].customerService;
                //     if (email)
                //       quoteDataRec.customerService =
                //         aguserStore.getName4email(email);
                //     quoteDataRec.customerEmail = entity[0].admin;
                //     quoteDataRec.gst = gst;
                //   }
                // }} 


                callback={(entityId) => {
                  QuotationValidationStore.validate("entityId");
                  let entity = EntityV2Store.Cuslist.filter((e) => e.entityId === entityId);
                  console.log("FOUND ENTITY = ", entity);
                  if (entity.length > 0) {
                    let email = entity[0].customer?.crossBorder?.relationshipManager;
                    quoteDataRec.entityId = entityId;
                    quoteDataRec.entityName = entity[0].entityName;
                    if(email?.length > 0){
                      quoteDataRec.salesperson = aguserStore.getName4email(email);
                    }else {
                      quoteDataRec.salesperson = "";
                    }
                    email = entity[0].customer?.crossBorder?.customerService;
                        if (email?.length > 0){
                      quoteDataRec.customerService = aguserStore.getName4email(email);
                        }else {
                      quoteDataRec.customerService = "";
                    }
                  }
                }}
              /> :
              <AgCustlist
                {..._params("* Customer", "entityId", "select", entitieslist)}
                isValid={QuotationValidationStore.quotationValidMap["entityId"]}
                validationMsg="- Please select one of customers."
                isDisabled={
                  quoteDataRec.quotationNum !== "" ? { disabled: true } : {}
                }
                callback={(entityId) => {
                  QuotationValidationStore.validate("entityId");
                  let entity = EntityV2Store.Cuslist.filter((e) => e.entityId === entityId);
                  console.log("FOUND ENTITY = ", entity);
                  if (entity.length > 0) {
                    let email = entity[0].customer?.crossBorder?.relationshipManager;
                    quoteDataRec.entityId = entityId;
                    quoteDataRec.entityName = entity[0].entityName;
                    if(email?.length > 0){
                      quoteDataRec.salesperson = aguserStore.getName4email(email);
                    }else {
                      quoteDataRec.salesperson = "";
                    }
                    email = entity[0].customer?.crossBorder?.customerService;
                        if (email?.length > 0){
                      quoteDataRec.customerService = aguserStore.getName4email(email);
                        }else {
                      quoteDataRec.customerService = "";
                    }
                  }
                }}
              />
}
            </Col4>
            {/*<Col>
                            <button className="button is-danger" onClick={() => {
                                configStore.addNewCustomerVisible = true
                            }}>
                                <span className="icon">
                                    <i className="fas fa-plus"></i>
                                </span>
                            </button>
                        </Col>*/}
          </Columns>
        </div>
        {configStore.addNewCustomerVisible ? (
          <Columns>
            <Col12>
              <AddCustomer />
            </Col12>
          </Columns>
        ) : (
          <></>
        )}
        <Columns>
          <Col3>
            <AgInput {..._params("Sales Person", "salesperson")} />
          </Col3>
          <Col3>
            {/* {
                            ['Port-to-Port', 'Port-to-Door'].includes(quoteDataRec.shipmentScope)
                            ? <AgUnloc {..._params('Origin', 'origin')} />
                            : <AgInput {..._params('Origin', 'origin')} />
                        } */}
            {quoteDataRec.shipmentScope === "Port-to-Port" ||
              quoteDataRec.shipmentScope === "Port-to-Door" ||
              quoteDataRec.shipmentScope === "" ? (
                <AgUnloc
                {..._params("* Origin", "origin")}
                isValid={QuotationValidationStore.quotationValidMap["origin"]}
                callback={(value, list) => {
                  QuotationValidationStore.validate("origin");
                  QuotationValidationStore.quotationValidMap.origin = list.some(ele => ele === value);
                }}
                validationMsg={QuotationValidationStore.originmsg}
              />
            ) : (
              <AgGoogleMap
                isDisabled={isDisabled}
                isValid={QuotationValidationStore.quotationValidMap["origin"]}
                callback={(val) => {
                  QuotationValidationStore.validate("origin")
                  QuotationValidationStore.quotationValidMap.origin = val !== "";
                }}
                label={"* Origin"}
                validationMsg={QuotationValidationStore.originmsg}
                record={quoteDataRec}
                name={"origin"}
                ShipmentType={quoteDataRec.shipmentScope}
              />
            )}

            {/* || quoteDataRec.shipmentScope === 'Port-to-Door' */}
            {/* <AgUnloc {..._params('* Origin', 'origin')}  isValid = {QuotationValidationStore.quotationValidMap['origin']} callback = {() => QuotationValidationStore.validate('origin') } validationMsg = {QuotationValidationStore.orginmsg} /> */}
          </Col3>
          <Col3>
            {/* {
                            ['Port-to-Port', 'Door-to-Port'].includes(quoteDataRec.shipmentScope)
                            ? <AgUnloc {..._params('Destination', 'destination')} />
                            : <AgInput {..._params('Destination', 'destination')} />
                        } */}
            {quoteDataRec.shipmentScope === "Door-to-Door" ||
              quoteDataRec.shipmentScope === "Port-to-Door" ? (
              <AgGoogleMap
                isDisabled={isDisabled}
                isValid={QuotationValidationStore.quotationValidMap["destination"]}
                callback={(val) => {
                  QuotationValidationStore.validate("destination")
                  QuotationValidationStore.quotationValidMap.destination = val !== "";
                }}
                label={"* Destination"}
                validationMsg={QuotationValidationStore.destinationmsg}
                record={quoteDataRec}
                name={"destination"}
                ShipmentType={quoteDataRec.shipmentScope}
              />
            ) : (
              <AgUnloc
                {..._params("* Destination", "destination")}
                isValid={QuotationValidationStore.quotationValidMap["destination"]}
                callback={(value, list) => {
                  QuotationValidationStore.validate("destination");
                  QuotationValidationStore.quotationValidMap.destination = list.some(ele => ele === value);
                }}
                validationMsg={QuotationValidationStore.destinationmsg}
              />
            )}

            {/* || quoteDataRec.shipmentScope === 'Door-to-Port' */}
          </Col3>
          <Col3>
            <AgInput
              {..._params(
                "* Cargo Readiness Date",
                "cargoReadinessDate",
                "date"
              )}
              isValid={
                QuotationValidationStore.quotationValidMap["cargoReadinessDate"]
              }
              callback={() =>
                QuotationValidationStore.validate("cargoReadinessDate")
              }
              validationMsg="- Please pick valid date."
            />
          </Col3>
        </Columns>
        <Columns>
          <Col3>
          <AgUnloc
              {..._params(portOfReceiptLable, "portOfReceipt")}
              isValid={
                QuotationValidationStore.quotationValidMap["portOfReceipt"]
              }
              callback={(value, list) => {
                QuotationValidationStore.validate("portOfReceipt");
                QuotationValidationStore.quotationValidMap.portOfReceipt = list.some(ele => ele === value);
              }}
            />
            
          </Col3>
          <Col3>
            <AgUnloc
              {..._params(portOfLoadingLabel, "portOfLoading")}
              isValid={
                QuotationValidationStore.quotationValidMap["portOfLoading"]
              }
              callback={(value, list) => {
                QuotationValidationStore.validate("portOfLoading");
                QuotationValidationStore.quotationValidMap.portOfLoading = list.some(ele => ele === value);
              }}
            />
          </Col3>
          <Col3>
            <AgUnloc
              {..._params(portOfDischargeLabel, "portOfDischarge")}
              isValid={
                QuotationValidationStore.quotationValidMap["portOfDischarge"]
              }
              callback={(value, list) => {
                QuotationValidationStore.validate("portOfDischarge");
                QuotationValidationStore.quotationValidMap.portOfDischarge = list.some(ele => ele === value);
              }}
            />
          </Col3>
            <Col3>
              <AgUnloc
                {..._params(
                  "* Final Place of Delivery",
                  "finalPlaceOfDelivery"
                )}
                isValid={
                  QuotationValidationStore.quotationValidMap[
                  "finalPlaceOfDelivery"
                  ]
                }
                callback={(value, list) => {
                  QuotationValidationStore.validate("finalPlaceOfDelivery");
                  QuotationValidationStore.quotationValidMap.finalPlaceOfDelivery = list.some(ele => ele === value);
                }}
                validationMsg="- Please select enter valid UN location."
              />
            </Col3>
        </Columns>
        <Columns>
          <Col3>
            <AgInput
              {..._params("Customer Reference #", "customerReferenceNum")}
            />
          </Col3>
          <Col3>
            <AgInput
              {..._params("* Valid Upto", "validUpto", "date")}
              isValid={QuotationValidationStore.quotationValidMap["validUpto"]}
              callback={() => QuotationValidationStore.validate("validUpto")}
              validationMsg="- Please pick valid data."
            />
          </Col3>
          <Col3>
            {/* <AgCommodity
              h={quoteDataRec.hazardous}
              {..._params("Commodity Type", "commodityType")}
            /> */}
            {
              (quoteDataRec.hazardous == 'Yes') ? 
              <AgSelect
              {..._params("Commodity Type", "commodityType", "select", hazardousList)}
            />
              :
              <AgSelect
              {..._params("Commodity Type", "commodityType", "select", commodityList)}
            />
            }
            
          </Col3>
          <Col3>
            <AgSelect
              {..._params("Incoterms", "incoTerms", "select", incoTermsList)}
            />
          </Col3>
        </Columns>
        <Columns>
          {!displayNonStackable(
            quoteDataRec.shipmentType,
            quoteDataRec.shipmentScope
          ) ? (
            <></>
          ) : (
            <Col3>
              <Agyesno
                label="Non Stackable"
                record={quoteDataRec}
                name="nonStackable"
              />
            </Col3>
          )}
          {/* <Col3>
                        <AgInput {..._params('Carrier', 'carrier')} />
                    </Col3> */}

          {quoteDataRec.shipmentType
            .toString()
            .toLowerCase()
            .includes("fcl") && (
              <Col3>
                <AgSelectWithMap
                  label={"* Carrier"}
                  record={quoteDataRec}
                  name="carrier"
                  dataMap={dataListStore["FCLCarrierList"].map((carrier) => ({
                    key: carrier["Display Name"],
                    value: `${carrier["Display Name"]} (${carrier["SCAC code"]})`,
                  }))}
                  isValid={QuotationValidationStore.quotationValidMap["carrier"]}
                  callback={() => QuotationValidationStore.validate("carrier")}
                  validationMsg="- Please pick valid carrier."
                />
              </Col3>
            )}

          {quoteDataRec.shipmentType
            .toString()
            .toLowerCase()
            .includes("lcl") && (
              <Col3>
                <AgMultiSelectWithCheckBoxes
                  label={"Consolidator"}
                  storeKey={consolidatorListKey}
                  callback={() => {
                    quoteDataRec.consolidatorList =
                      multiSelectListStore.selectList[consolidatorListKey]
                        .filter(({ checked }) => checked)
                        .map(({ id }) =>
                          selectedConsolidatorList.find(
                            ({ consolidatorAbbrName }) =>
                              consolidatorAbbrName === id
                          )
                        );
                  }}
                  withAllShortcut></AgMultiSelectWithCheckBoxes>
                {quoteDataRec?.consolidatorList?.length < 1 && <p class="help is-danger"> {`* Please select atleast one consolidator`}</p>}
              </Col3>
            )}

          <Col3>
            <AgInput
              {..._params("* Tentative transit Time", "transitTime", "number")}
              isValid={
                QuotationValidationStore.quotationValidMap["transitTime"]
              }
              callback={() => QuotationValidationStore.validate("transitTime")}
              validationMsg="- Please enter valid transit time."
            />
          </Col3>
          {(quoteDataRec.shipmentType.indexOf("Air") != -1) ? 
          <Col3>
            <AgAirline
                label="* Airline"
                record={quoteDataRec}
                name="airline"
                isValid={QuotationValidationStore.quotationValidMap["airline"]}
                callback={() => QuotationValidationStore.validate("airline")}
                validationMsg="- Please select one airline."
              />
              
            </Col3>:""}
            <Col3>
                <AgSelect1
                  {..._params(
                    "* Procurement",
                    "procurement",
                    "select",
                    prList
                  )}
                  isValid={
                    QuotationValidationStore.quotationValidMap["procurement"]
                  }
                  callback={() =>
                    QuotationValidationStore.validate("procurement")
                  }
                  validationMsg="- Please select valid procurement."
                />
              </Col3>
        </Columns>
        <Columns>
          {quoteDataRec.temperatureControlled.toLowerCase() === "yes" ? <></> : <Col3>
            <Agyesno
              label="Hazardous"
              record={quoteDataRec}
              name="hazardous"
              // isDisabled={

              // }
              callback={(value) => {
                if (value === "No") {
                  quoteDataRec.quoteClass = "";
                  quoteDataRec.packingGroup = "";
                  quoteDataRec.unnumber = "";
                } else {
                  containerStore.list=[]
                  console.log(containerStore.list,"Error");
                  console.log("Error: " + quoteDataRec);
                }
              }}
            />
          </Col3>}

          {quoteDataRec.hazardous === "Yes" ? (
            <>
              <Col3>
                <AgSelect
                  {..._params(
                    "* Class",
                    "quoteClass",
                    "select",
                    getHazardousClassList(quoteDataRec.shipmentType)
                  )}
                  isValid={
                    QuotationValidationStore.quotationValidMap["quoteClass"]
                  }
                  callback={() =>
                    QuotationValidationStore.validate("quoteClass")
                  }
                  validationMsg="- Please enter select valid class."
                />
              </Col3>
              <Col3>
                <AgSelect
                  {..._params(
                    "* Packing Group",
                    "packingGroup",
                    "select",
                    packingGroupList
                  )}
                  isValid={
                    QuotationValidationStore.quotationValidMap["packingGroup"]
                  }
                  callback={() =>
                    QuotationValidationStore.validate("packingGroup")
                  }
                  validationMsg="- Please enter select valid packing group."
                />
              </Col3>
              <Col3>
                <AgInput
                  {..._params("* UN Number", "unnumber", "text")}
                  isValid={
                    QuotationValidationStore.quotationValidMap["unnumber"]
                  }
                  callback={() => QuotationValidationStore.validate("unnumber")}
                  validationMsg="- Please enter valid Un Number "
                  placeholder="e.g., AB1234"
                />
              </Col3>
            </>
          ) : (
            <></>
          )}
        </Columns>
        <Columns>
          {quoteDataRec.isFromEnquiry &&
            quoteDataRec.shipmentType.toLowerCase().includes("fcl") && (
              <>
                <Col3>
                  <AgSelect1
                    {..._params(
                      "Stuffing type",
                      "stuffingType",
                      "select",
                      stuffingTypes
                    )}
                    isDisabled={{ disabled: true }}
                  />
                </Col3>
                <Col3>
                  <AgSelect1
                    {..._params(
                      "De-Stuffing type",
                      "deStuffingType",
                      "select",
                      deStuffingTypes
                    )}
                    isDisabled={{ disabled: true }}
                  />
                </Col3>
              </>
            )}
          {/* {
                      ( quoteDataRec.shipmentType    
                        .toString()
                        .toLowerCase()
                        .includes("fcl")) ? <>
                        <Col3 style={{display:'none'}}>
                        <Agyesno label='Do you require a Direct Carrier MBL?' record={quoteDataRec}
                            name='carrierMBL' 
                            // isDisabled = { quoteDataRec.carrierMBL.toLowerCase() === 'yes'  } callback = { (value) => {}}
                            isDisabled = {quoteDataRec.isFromEnquiry ? true : false}
                            />
                        </Col3>
                        </> : <></>
                    } */}

         
        </Columns>
        <Columns>
        {Utils.displayTemperatureControlled(quoteDataRec.shipmentType) &&
            modalStore.disableTemp === false ? (
            <>
              <Col3>
                <Agyesno
                  label={`${space}Temperature Controlled?`}
                  record={quoteDataRec}
                  name="temperatureControlled"
                  // isDisabled={
                  //   quoteDataRec.hazardous.toLowerCase() === "yes" ||
                  //     quoteDataRec.shipmentType.toLowerCase().includes("fcl")
                  //     ? containerStore.list.length > 0 ||
                  //     containerStore.newRec.containerType !== ""
                  //     : false
                  // }
                  callback={(value) => {
                    if (value === "No") {
                      quoteDataRec.minTemperature = 0;
                      quoteDataRec.maxTemperature = 0;
                      quoteDataRec.temperature = '';
                      quoteDataRec.cargoValue = 0;
                      containerStore.list=[]
                    } else {
                      containerStore.list=[]
                      console.log(containerStore.list,"Error:");
                      console.log("Error: Temperature" );
                    }
                  }}
                />
                {/*<AgSelect {..._params('Temperature Controlled?',
                                        'temperatureControlled', 'select', ['', 'Yes', 'No'])} />*/}
              </Col3>
            </>
          ) : (
            <></>
          )}
          {
            (() => {
              if (
                Utils.displayTemperatureControlled(quoteDataRec.shipmentType) &&
                quoteDataRec.temperatureControlled === "Yes"
              ) {
                if (quoteDataRec.shipmentType.toLowerCase().includes("air"))
                  return (
                    <>
                      <Col3>
                        <AgInput
                          {..._params(
                            "* Minimum Temperature",
                            "minTemperature",
                            "number"
                          )}
                          isValid={
                            QuotationValidationStore.quotationValidMap[
                            "minTemperature"
                            ]
                          }
                          callback={() =>
                            QuotationValidationStore.validate("minTemperature")
                          }
                          validationMsg={`- Please enter valid temperature should be greater than -35 .
                                                    `}
                        />
                      </Col3>
                      <Col3>
                        <AgInput
                          {..._params(
                            "* Maximum Temperature",
                            "maxTemperature",
                            "number"
                          )}
                          isValid={
                            QuotationValidationStore.quotationValidMap[
                            "maxTemperature"
                            ]
                          }
                          callback={() =>
                            QuotationValidationStore.validate("maxTemperature")
                          }
                          validationMsg="- Please enter valid temperature should be less than 30 "
                        />
                      </Col3>
                    </>
                  );
                return (
                  <>
                    <Col3>
                      <AgInput
                        {..._params("* Temperature", "temperature", "number")}
                        isValid={
                          QuotationValidationStore.quotationValidMap[
                          "temperature"
                          ]
                        }
                        callback={() =>
                          QuotationValidationStore.validate("temperature")
                        }
                        validationMsg={`- Please enter valid temperature and range should be between (-)35 ⁰C to (+)30 ⁰C.
                                                    `}
                      />
                    </Col3>
                    <Col3>
                      <AgInput
                        {..._params(
                          "* Cargo Value (USD)",
                          "cargoValue",
                          "number"
                        )}
                        isValid={
                          QuotationValidationStore.quotationValidMap[
                          "cargoValue"
                          ]
                        }
                        callback={() =>
                          QuotationValidationStore.validate("cargoValue")
                        }
                        validationMsg="- The value should be less than US$ 100,000."
                      />
                    </Col3>
                  </>
                );
              }
              return <></>;
            })()
            // (Utils.displayTemperatureControlled(quoteDataRec.shipmentType) &&
            //     quoteDataRec.temperatureControlled === 'Yes') ?
            //     <>
            //         <Col3>
            //             <AgInput {..._params('Temperature', 'temperature', 'number')} isDisabled = { quoteDataRec.isFromEnquiry ? {disabled: true} : {}}
            //                 isValid = {QuotationValidationStore.quotationValidMap['temperature']}  callback = {() => QuotationValidationStore.validate('temperature')} validationMsg = {`- Please enter valid temperature and range should be between -35 to +30.
            //                 `}/>
            //         </Col3>
            //         <Col3>
            //             <AgInput {..._params('Cargo Value (USD)', 'cargoValue', 'number')} isDisabled = { quoteDataRec.isFromEnquiry ? {disabled: true} : {}}
            //                 isValid = {QuotationValidationStore.quotationValidMap['cargoValue']}  callback = {() => QuotationValidationStore.validate('cargoValue')} validationMsg = "- The value should be less than 100,000."/>
            //         </Col3>
            //     </> : <></>
          }
        </Columns>
        <Columns>
        <Col3>
            <Agyesno
              label="FBA"
              record={quoteDataRec}
              name="fba"
              callback={(value) => {
                if (value === "Yes") {
                  if(quoteDataRec.shipmentScope.toLowerCase().indexOf("to-port")!=-1){
                    quoteDataRec.pick_up_by_amazon = 'Yes'
                  }
                }else{
                    quoteDataRec.pick_up_by_amazon = 'No'
                }
              }}
            />
          </Col3>
          {
            (quoteDataRec.fba!=undefined && quoteDataRec.fba=='Yes' && quoteDataRec.shipmentScope.toLowerCase().indexOf("to-port")!=-1)?<Col3>
            <Agyesno
            isDisabled={{disable:true}}
              label="Pick-up By Amazon"
              record={quoteDataRec}
              name="pick_up_by_amazon"
            />
          </Col3>:""
          }
        </Columns>
        <VehicleView vehicleDetails={quoteDataRec.vehicleDetails} />
        {quoteDataRec.shipmentType.indexOf("FCL") >= 0 ? (
          <ContainerComp />
        ) : (
          <></>
        )}

        {(Utils.displayNonStackable(
          quoteDataRec.shipmentType,
          quoteDataRec.shipmentScope
        ) && QuotationUtils.hasCargoDetail())||Utils.outGaugeContainerCheck(containerDetails.list) ? (
          <CargoComp />
        ) : (
          <></>
        )}

        {quoteDataRec.shipmentType.indexOf("LCL") >= 0 ? (
          <LCLChargesCompv2 />
        ) : (
          <></>
        )}
        {quoteDataRec.shipmentType.indexOf("Air") >= 0 ? (
          <AirChargesCompv2 />
        ) : (
          <></>
        )}
        {quoteDataRec.shipmentType.indexOf("FCL") >= 0 ? (
          <FCLChargesCompv2 />
        ) : (
          <></>
        )}
        <Columns>
          <Col12>
            <h3 className="title is-5">Custom Terms & Conditions</h3>
            {/* <textarea value={quoteDataRec.customTC}
                            onChange={(e) => quoteDataRec.customTC = e.target.value}
                            class="textarea" placeholder=""></textarea> */}
            <textarea
              ref={ref}
              value={quoteDataRec.customTC}
              onChange={handleChange}
              class="textarea"
              placeholder=""
            />
          </Col12>
        </Columns>
        <Columns>
          <Col12>
            <div className="field is-grouped" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {
                  (quoteDataRec.status == 'DRAFT(Enquiry)' && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.PROCUREMENT_ONLY_SUBMIT)) ?
                  <div className="control">
                  <button
                    className="button is-success"
                    disabled={configStore.isDisable} 
                    onClick={() => {
                      console.log("chec00000000000000", QuotationValidationStore.quotationValidMap)
                      chargesValidationStore.onSaveBtnClicked();
                      configStore.quoteCurrentView = "list";
                      configStore.modalVisible = false;
                      configStore.isDisable = true
                      Api.saveQuote1("DRAFT(Enquiry)");
                      AgmodalStore.pageVisible = false;
                      AgmodalStore.apiCall = true
                      // quoteDataRec.status = 'DRAFT(Enquiry)'
                    
                    }}>
                    Save 
                  </button>
                </div> : <></>
                }
              <div className="control">
                
                <button
                  className="button is-link"
                  disabled={configStore.isDisable}
                  onClick={() => {
                    chargesValidationStore.onSaveBtnClicked();
                    if(quoteDataRec.entityId === ""){
                      return configStore.setModalMessage(
                          `Please select the customer`
                        );
                    }

                    if (quoteDataRec.shipmentType.toLowerCase().includes("lcl")) {
                      if (cargoStore.cargoList.length < 0) {
                        configStore.setModalMessage(
                          `Please add atleast one cargo Details`
                        );
                      } else if (quoteDataRec.consolidatorList.length < 1) {
                        configStore.setModalMessage(
                          `Please check the fields`
                        );
                      } else {
                        if (QuotationValidationStore.isValid()){
                              if(quoteDataRec.status == 'DRAFT(Enquiry)'){
                               
                                configStore.quoteCurrentView = "list";
                                configStore.modalVisible = false;
                                configStore.isDisable = true
                                Api.saveQuote2("DRAFT")
                                
                              }else{
                                configStore.isDisable = true
                                Api.saveQuote("DRAFT")
                              }
                            }else{
                          configStore.setModalMessage(
                            QuotationValidationStore.message
                          );
                            }
                      }
                      return;
                    }
                    
                     else {
                      console.log(QuotationValidationStore.quotationValidMap.portOfLoading, "QuotationValidationStore");
                      if (QuotationValidationStore.isValid()){ 
                            if(quoteDataRec.status == 'DRAFT(Enquiry)'){
                             
                              configStore.quoteCurrentView = "list";
                              configStore.modalVisible = false;
                              configStore.isDisable = true
                              Api.saveQuote2("DRAFT")
                            }else{
                              configStore.isDisable = true
                              Api.saveQuote("DRAFT");
                            }
                          }else{
                        configStore.setModalMessage(
                          QuotationValidationStore.message
                        );
                          }
                    }
                    return;
                  }}>
                  {buttontext}
                </button>
              </div>
              <div className="control">
                <button class="button is-danger" onClick={() => {
                  AgmodalStore.pageVisible = false;
                  AgmodalStore.apiCall = true;
                }}>
                  Cancel
                </button>
              </div>
            </div>
          </Col12>
        </Columns>
      </div>
    </>
  );
}
