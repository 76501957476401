import { useSnapshot } from "valtio"
import Utils from "../util/Utils"
import { taskStore } from "./task-store"

export default function Tasklistsmall(props) {
    useSnapshot(taskStore)
    let actionList = props.actionList
    return (
        <div className="box">
        <tr>
            <th>Action Triggered</th>
            <th style={{width:'1rem'}}></th>
            <th>Target Date</th>
            <th style={{width:'1rem'}}></th>
            <th >Status</th>
        </tr>
        <tbody className="contractlist" style={{ fontSize: '0.8rem' }}>
        {
            actionList.map(e => {
                return (
                    <tr  onClick={() => {
                        taskStore.previous = taskStore.current
                        taskStore.current = e
                        taskStore.myActionVisible = true
                    }}>
                        <td>{e.actionName}</td>
                        <td style={{width:'1rem'}}></td>
                        <td>
                        {Utils.formatToDateTime1(e.dueDate, e.dueTime)}
                        </td>
                        <td style={{width:'1rem'}}></td>
                        <td>{e.status}</td>
                    </tr>
                )
            })
        }
        </tbody>
    </div>
    )
}