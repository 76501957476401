import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import InputField from '../../mui-components/InputField'
import { configStore, EntityV2Store, routerStore,ShipperConsigneeStore } from '../../state/CommonState'
import {CitySelect, CountrySelect,StateSelect} from '../components/Country'
import EntityV2Api from '../../state/entities-api'
import Select from 'react-select'
import { useSnapshot } from 'valtio'
import { PERMISSIONS, STAKEHOLDER_DEFAULT_VALUES } from '../../util/constants'
import RoleUtils from '../../util/RoleUtils'
function EditShipperConsignee(props) {
  useSnapshot(ShipperConsigneeStore)
    const Entity =  EntityV2Store.EntityRec
   const Shipper =  ShipperConsigneeStore.ShipperConsigneeRec
   const [LinkedEntityList, setLinkedEntityList] = useState([])
   const [LinkedEntity, setLinkedEntity] = useState([])
   const [EntityList, setEntityList] = useState([])
   const [tempData, settempData] = useState([])
   const [showList, setshowList] = useState(false)
   const [mobMinLength, setmobMinLength] = useState(10)
   const [mobMaxLength, setmobMaxLength] = useState(10)
   const [pinCodeLength, setpinCodeLength] = useState(6)
   const [loading, setloading] = useState(false)
    const [errors, setErrors] = useState({
      address: '',
      companyName:"",
      pincode:"",
      linkedEntity:"",
      firstName:"",
      lastName:"",
      emailId:"",
      mobileNumber:"",
      country:"",
      state:"",
      city:""
    });
    const validationConfig = {
      address: {
        regex: /.+/,
        message: "Address is required",
        required:true
      },
      companyName: {
        regex: /.+/,
        message: "Company name is required",
        required:true
      },
      pincode: {
        type:"number",
        regex: /^[0-9]{6}$/,
        message: "Pincode must be 6 digits",
        required:true
      },
      linkedEntity: {
        regex: /.+/,
        message: "Linked entity is required",
        required:false
      },
      firstName: {
        regex: /.+/,
        message: "First name is required",
        required:true
      },
      lastName: {
        regex: /.+/,
        message: "Last name is required",
        required:false
      },
      emailId: {
        regex:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email address",
        required:true
      },
      mobileNumber: {
        type:"number",
        regex: /^\d{10}$/,
        message: "Mobile number must be 10 digits",
        required:true
      },
      country: {
        regex: /.+/,
        message: "Country is required",
        required:true
      },
      state: {
        regex: /.+/,
        message: "State is required",
        required:true
      },
      city: {
        regex: /.+/,
        message: "City is required",
        required:true
      }
    };
    const validateField = (fieldName, value) => {
      if (mobMaxLength === 15) {
        validationConfig.mobileNumber.regex= /^\d{7,15}$/
        validationConfig.pincode.regex= /^[0-9]{3,10}$/
        validationConfig.mobileNumber.message = "Mobile number must between 7 to 15 digits"
        validationConfig.pincode.message = "Pin Code must between 10 digits"
      }
      const config = validationConfig[fieldName];
      console.log(config);
      return config?.required?config?.regex?.test(value)? '' : config.message:""
      
    };

    const handleChange = (e) => {
      let { name, value } = e.target;
      // console.log(name,value);
      const errorMessage = validateField(name, value);
      if (validationConfig[name]?.type === "number") {
        value = value.replace(/\D/g, '');
      }
      Shipper[name] = value
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: errorMessage || ''
      }));
      if (name == 'emailId' && value.includes('@agraga.com')) {
        setErrors(prevErrors => ({
          ...prevErrors,
         'emailId':'Agraga email Id is not allowed'
        }));
        Shipper.emailId = ''
      }
      if (name == 'emailId') {
        value = value.toLowerCase()
        Shipper.emailId = value
      }
    };
    const loseFocus = (e)=>{
      let { name, value } = e.target;
      console.log(name, value);
      if (name == 'emailId' && !validateField(name, value) && !value.includes('@agraga.com')) {   
              getEntity()
      }
    }

    useEffect(() => {
      console.log("Shipper",Shipper);
    }, [Shipper])

    const handleSubmit = async ()=>{
      setloading(true)
      console.log(errors);
      console.log(Shipper);
    const dd = await Promise.all([Object?.entries(Shipper)?.map(([key,value]) => {
    const errorMessage = validateField(key, value);
    console.log(errorMessage,key, value);
    if (errorMessage) {
      console.log('inside');
      setErrors(prevErrors => ({
        ...prevErrors,
        [key]:errorMessage|| ''
      }));
      return "true"
    }
    else{
      setErrors(prevErrors => ({
        ...prevErrors,
        [key]:''
      }));
      return "false"
    }
   })])
   console.log(dd);
   if (dd[0].includes('true')) {
    setloading(false)
    configStore.setModalMessage(`Fill all the Fields`)
    return ""
  }
  else {
    Shipper.entityId = Entity.entityId 
      try {
        if (props.mode === "new") {
          await EntityV2Api.AddShippers(Shipper, (response) => {
            console.log("response",response);
            setloading(false)
          });
        }
        if (props.mode === "edit") {
          await EntityV2Api.updateShipper(Shipper._id,Shipper, (response) => {
            console.log("response",response);
            setloading(false)
          });
        }
      } catch (error) {
        console.log(error);
      }
  }
    }

    useEffect(() => {
      
      getData()
    }, [])
    const getData = async()=>{
      try {
        await EntityV2Api.ListEntity(Entity.entityId,(response) => {
          console.log("response",response);
          if (response?.data.length > 0) {
            setLinkedEntity(response?.data.filter((r)=>r._id?.trim() === Shipper.linkedEntity))
            setLinkedEntityList(response?.data)
  
          } 
        });
      } catch (error) {
        console.log(error);
      }
    }
const getEntity =async ()=>{
  try {
    await EntityV2Api.GetEntityListWithEmail(Shipper.emailId,Entity.entityId, (response) => {
      // console.log("deenathaya21@gmail.com",response);
      if (response?.data?.length > 0) {
        setEntityList(response?.data)
        setshowList(true)
      }else if(response?.errors?.length > 0){
        setErrors(prevErrors => ({
          ...prevErrors,
          emailId: response.errors || ''
        }));
        Shipper.emailId = ''
      }

    });
  } catch (error) {
    console.log(error);
  }
}

const getValue = (d)=>{
  let data = JSON.parse(d?.target?.value)
  console.log(data);
  settempData(data)
  // Shipper.linkedEntity = data?.entityName.trim() 
//  setshowList(false)
}
useEffect(() => {
  if(Shipper.country.toLowerCase() === STAKEHOLDER_DEFAULT_VALUES.COUNTRY.toLowerCase()){
    setmobMinLength(10)
    setmobMaxLength(10)
    setpinCodeLength(6)
  } else {
    setmobMinLength(7)
    setmobMaxLength(15)
    setpinCodeLength(10)
    setErrors(prevErrors => ({
      ...prevErrors,
      pincode:''
    }));
    console.log("config",validationConfig);
  }
}, [Shipper.country])

  return (
    <>
    <Header  heading={props.mode === "new"?"Add  Shipper/Consignee":"Edit  Shipper/Consignee"}> </Header>
    <h1 style={{marginLeft:"3rem",marginTop:"1rem",fontWeight:900,fontSize:"20px"}}>Company Information</h1>
    <div className='shipper_container' >
    <InputField 
      placeholder={"Company Name"}
      label={"Company Name"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.companyName}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      name={'companyName'}
      value={Shipper?.companyName}
      callback={(e)=> handleChange(e)}
      maxLength={100}
      ></InputField>
      <CountrySelect errorsmsg={errors.country} value={Shipper.country} callback={(e)=>{

        // setFormData(prevState => ({
        //   ...prevState,
        //   state:"",
        //   country:e,
        //   city:""
        // }));
        Shipper.country = e?.name
        Shipper.state = ""
        Shipper.city = ""
        Shipper.pincode = ""
        Shipper.mobileNumber = ""
        setErrors(prevErrors => ({
          ...prevErrors,
          country:''
        }));
        }}></CountrySelect>
            <InputField 
      placeholder={"Address"}
      label={"Address"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.address}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      value={Shipper?.address}
      callback={(e)=> handleChange(e)}
      maxLength={255}
      name={'address'}
      ></InputField>
      <StateSelect countryValue={Shipper.country} errorsmsg={errors.state} value={Shipper.state} callback={(e)=> {
        // setFormData(prevState => ({
        //   ...prevState,
        //   state:e,
        //   city:""
        // }));
        Shipper.state =e?.name
        Shipper.city = ""
        setErrors(prevErrors => ({
          ...prevErrors,
          state:''
        }));
    }}></StateSelect>
      {Shipper.country ==="India"?<CitySelect stateValue={Shipper.state}  errorsmsg={errors.city} value={Shipper.city} callback={(e)=>{
        // setFormData(prevState => ({
        //   ...prevState,
        //   city:e
        // }));
        Shipper.city = e?.name
        setErrors(prevErrors => ({
          ...prevErrors,
          city:''
        }));
        }}></CitySelect>:<>
            <InputField 
      placeholder={"City"}
      label={"City"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.city}
      name={'city'}
      value={Shipper?.city}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      callback={(e)=>handleChange(e)}
      // maxLength={10}
      maxLength={30}
      ></InputField>
        </>}
    <InputField 
      placeholder={"Pin/Zip Code"}
      label={"Pin/Zip Code"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.pincode}
      name={'pincode'}
      value={Shipper?.pincode}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      callback={(e)=>handleChange(e)}
      // maxLength={10}
      maxLength={pinCodeLength}
      minLength={pinCodeLength}
      ></InputField>
          {/* <InputField 
      placeholder={"Linked Entity"}
      label={"Linked Entity"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.linkedEntity}
       name={'linkedEntity'}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      value={Shipper?.linkedEntity}
      callback={(e)=>handleChange(e)}
      maxLength={30}
      ></InputField> */}
      </div>
      <h1 style={{marginLeft:"3rem",marginTop:"1rem",fontWeight:900,fontSize:"20px"}}>Contact Information</h1>
      <div className='shipper_container'>
    <InputField 
      placeholder={"First Name"}
      label={"First Name"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.firstName}
      name={'firstName'}
      value={Shipper?.firstName}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      callback={(e)=>handleChange(e)}
      maxLength={100}
      ></InputField>
    <InputField 
      placeholder={"Last Name"}
      label={"Last Name"}
      label_class={"label_styles"}
      mandatory={false}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.lastName}
      name={'lastName'}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      value={Shipper?.lastName}
      callback={(e)=>handleChange(e)}
      maxLength={100}
      ></InputField>
    <InputField 
      placeholder={"Email ID"}
      label={"Email ID"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.emailId}
      name={'emailId'}
      value={Shipper?.emailId}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      callback={(e)=>handleChange(e)}
      onblur={(e)=> loseFocus(e)}
      maxLength={255}
      ></InputField>
    <InputField 
      placeholder={"Mobile Number"}
      label={"Mobile Number"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.mobileNumber}
      name={'mobileNumber'}
      value={Shipper?.mobileNumber}
      input_wrapeer={"input_wrapeer m0 wd_30"}
      callback={(e)=>handleChange(e)}
      maxLength={mobMaxLength}
      minLength={mobMinLength}
      ></InputField>
          </div>
          <h1 style={{marginLeft:"3rem",marginTop:"1rem",fontWeight:900,fontSize:"20px"}}>Entity Information</h1>
          <div className='shipper_container'>
          <div className={"input_wrapeer m0 wd_30"}>
    <p className={"label_styles"} >
    Linked Entity
              </p>
    <div className={""} >
          <>
          <Select
            // {...field}
            
            className={`selectBox_styles ${errors?.linkedEntity?"border_red":""}`}
            classNamePrefix="select"
            isClearable={true}
            // defaultValue={filer()}
            isSearchable={true}
            name='linkedEntity'
            options={LinkedEntityList}
            // placeholder={Shipper.linkedEntity}
            getOptionLabel={(options) => {return options['entityName'] + ' ' +options['_id']}}
            getOptionValue={(options) => {return options['entityName'] + ' ' +options['_id']}}
            value={LinkedEntity}  
            onChange={(e)=> { 
              Shipper.linkedEntity = e?e?._id?.trim():""
              setLinkedEntity(e)
              console.log(e?.entityName);
              // filer()
            // callback(e)
        }}
          />
             <p className="help is-input-danger-message">{errors?.linkedEntity?errors?.linkedEntity:<span>&nbsp;</span>}</p> 
          </>
    </div>
    </div>
          </div>
          <div style={{float:"right",marginRight:"3rem"}}>
          <button className="button" style={{marginRight:"1rem"}} onClick={()=>{
            routerStore.shipperManageView = "list"
          }}>Cancel</button>
          {(RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.UPDATE_SHIPPER)) &&<button disabled={loading} className="button is-link"onClick={handleSubmit}>{props.mode === "new"?"Save Details":"Update"}</button>}
            </div>
            {showList?<div className='exist_customer_entity_main_wrapper'><div className='exist_customer_entity_wrapper'>
                <div className='exist_customer_entity_container'>
                  <h1 style={{fontWeight:900}}>Link Existing Customer Entity</h1>
                  <table>
                        <tr>
                          
                          <th>Entity ID</th>
                          <th>Entity Name</th>
                          <th>Country</th>
                        </tr>
                        <tbody>
                        {EntityList?.map((d)=>{
                          return <>
                               <tr>
                                  <td>
                                    <div className='flexWithCenter'>
                                    <input type="radio" id="linkedRadio" name="fav_language" value={JSON.stringify(d)} onClick={(d)=> getValue(d)}/>
                                  <span>{d?._id}</span> 
                                    </div>

                                  </td>
                                  <td>{d?.entityName}</td>
                                  <td>{d?.entityCountry}</td>
                                </tr>
                          </>
                        })}
                        </tbody>
   
                </table>
                    <div style={{textAlign:"right",margin:"1rem"}}>
                    <button className="button" style={{fontWeight:500,margin:"0 1rem"}} onClick={()=> {
                      // setLinkedEntity([])
                      // Shipper.linkedEntity = ""
                      settempData({})
                      setshowList(false)
                      }} >Cancel</button>
                    {(RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.UPDATE_SHIPPER)) && <button className="button is-link" style={{backgroundColor:"#2c358a",fontWeight:500}} onClick={()=> { 
                      // console.log(LinkedEntity);
                      console.log(tempData);
                      if (tempData.hasOwnProperty('entityCountry')) {
                        setLinkedEntity(tempData)
                        Shipper.linkedEntity = tempData?._id.trim()
                      
                        setshowList(false)
                      }else{
                        configStore.setModalMessage("Choose One Entity For Continue this Process")
                      }

                      }}>Submit</button>}
                    </div>
              </div>
            </div></div>
            :null}

    </>
  )
}

export default EditShipperConsignee