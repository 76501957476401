import { configStore } from "../state/CommonState"
import Utils from "./Utils"

export default class QuoteCalc {
    constructor(quote) {
        this.quote = JSON.parse(JSON.stringify(quote))
        this.quoteValue = 0.0
    }
    recalc() {
        console.log('INSIDE RECALC. CONTAINER LIST =', JSON.stringify(this.quote.containerList))
        this.quoteValue = 0.0
        this.quote.cargoTotals = this.calculateCargoTotals(this.quote.cargoList)
        const st = this.quote.shipmentType
        let list
        let totalCharge
        console.log('INSIDE RECALC=', st, this.quote.chargesList.length)
        switch (true) {
            case st.startsWith('LCL'):
                ({ list, totalCharge } = this.calculateLCLCharges(this.quote.chargesList,
                    this.quote.cargoTotals))
                break
            case st.startsWith('Air'):
                ({ list, totalCharge } = this.calculateAirCharges(this.quote.chargesList,
                    this.quote.cargoTotals))
                break
            case st.startsWith('FCL'):
                ({ list, totalCharge } = this.calculateFCLCharges(this.quote.chargesList,
                    this.quote.containerList))
                break
        }
        this.quote.chargesList = list
        let usd = configStore.getExchangeRate('USD')
        if (!usd) usd = 1.0
        this.quote.chargesList = list
        this.quote.quoteValue = Utils.R2(totalCharge)
        this.quote.quoteValueUSD = Utils.R2(totalCharge / usd)
        //console.log('FROM FINAL CALCULATION======', this.quote.quoteValue)
        return this.quote
    }
    calculateCargoTotals(cargoList) {
        let totNumPackages = 0.0
        let totTotalVolume = 0.0
        let totTotalWeight = 0.0
        let totChargeableWeight = 0.0
        if (!cargoList || cargoList.length < 1) return {
            totNumPackages,
            totTotalWeight,
            totTotalVolume
        }
        for (let i = 0; i < cargoList.length; i++) {
            const e = cargoList[i]
            if (this.quote.shipmentType.toLowerCase().includes('air') || this.quote.shipmentScope !== 'Port-to-Port') {
                let l = parseInt(e.length)
                let w = parseInt(e.width)
                let h = parseInt(e.height)
                e.volPerPackage = (l * w * h) / 1000000.0
                e.volPerPackage = parseFloat(e.volPerPackage)
                e.totalWeight = parseFloat(parseInt(e.numPackages) * parseFloat(e.wtPerPackage))
                //if (i === 0) console.log('from inside the loop XXX', e.wtPerPackage, e.totalWeight)
                if(this.quote.shipmentType.toLowerCase().includes('air'))
                    e.totalVolume = ((parseInt(e.length)*parseInt(e.width)*parseInt(e.height)) / 1000000) * parseFloat(e.numPackages) * 166.67;
                else 
                    e.totalVolume = parseFloat(e.volPerPackage * e.numPackages);
                // let val1 = Utils.R((e.totalVolume * 1000000) / 6000);
                // let val2 = e.totalWeight;
                // e.chargeableWeight = val1 > val2 ? val1 : val2;
                // e.chargeableWeight = Math.round(e.chargeableWeight+0.4)
            }
            totNumPackages += parseInt(e.numPackages)
            totTotalWeight += parseFloat(e.totalWeight)
            totTotalVolume += parseFloat(e.totalVolume)
            // totChargeableWeight += parseFloat(e.chargeableWeight)
            totTotalWeight = Utils.R(totTotalWeight)
            totTotalVolume = Utils.R(totTotalVolume)
            totChargeableWeight = Math.ceil(Math.max(totTotalVolume, totTotalWeight));

        }
        return {
            totNumPackages,
            totTotalWeight,
            totTotalVolume,
            totChargeableWeight
        }
    }
    calculateLCLCharges(chargesList, cargoTotals) {
        let totalCharge = 0.0
        let list = [...chargesList]
        console.log('chargeslist ==========================')
        console.log(JSON.stringify(list))
        list.map((e, i) => {
            let erate = configStore.getExchangeRate(e.currency)
            console.log('INSIDE INSIDE ERATE=', erate)
            if (!erate || erate === 0) erate = 1.0
            if (!e.perCBM) e.perCBM = 0
            if (!e.perTon) e.perTon = 0
            if (!e.minimum) e.minimum = 0
            if (!e.maximum) e.maximum = 0
            if (!e.perShipment) e.perShipment = 0
            let tots = cargoTotals
            let val = Number(e.perCBM) * Number(tots.totTotalVolume)
            let temp = Number(e.perTon) * Number(tots.totTotalWeight) / 1000
            if (temp > val) val = temp
            temp = Number(e.minimum)
            if (temp > val) val = temp
            temp = Number(e.maximum)
            if (temp > 0 && temp < val) val = temp

            e.total = Utils.R(
                (Number(val) + Number(e.perShipment))
            )
            totalCharge += e.total * erate
        })
        return { list, totalCharge }
    }
    calculateAirCharges(chargesList, cargoTotals) {
        let totalCharge = 0.0
        let list = [...chargesList]
        list.map((e, i) => {
            let erate = configStore.getExchangeRate(e.currency)
            if (!erate || erate === 0) erate = 1.0
            if (!e.perKg) e.perKg = 0
            if (!e.minimum) e.minimum = 0
            if (!e.perAWB) e.perAWB = 0
            let tots = cargoTotals
            let val = Number(e.perKg) * Number(tots.totChargeableWeight)
            let temp = Number(e.minimum)
            if (temp > val) val = temp
            e.total = Utils.R(
                (Number(val) + Number(e.perAWB))
            )
            totalCharge += e.total * erate
        })
        return { list, totalCharge }
    }
    calculateFCLCharges(chargesList, containerList) {
        let totalCharge = 0.0
        let list = [...chargesList]
        list.map((e, i) => {
            let erate = configStore.getExchangeRate(e.currency)
            if (!erate || erate === 0) erate = 1.0
            e.totaloftotals = 0.0

            containerList.map(e1 => {
                let c = e1.containerType
                let perwmField = c + 'perWM'
                let pershipmentField = c + 'perShipment'
                let totalField = c + 'total'
                if (!e[perwmField]) e[perwmField] = 0
                if (!e[pershipmentField]) e[pershipmentField] = 0
                if (!e[totalField]) e[totalField] = 0
                let perwm = Number(e[perwmField])
                let pership = Number(e[pershipmentField])
                let num = Number(e1.numContainers) || 0
                //console.log(perwm, num, pership, erate)
                e[totalField] = Utils.R(
                    (perwm * num + pership))
                e.totaloftotals += e[totalField] * erate

            })
            totalCharge += e.totaloftotals
        })
        return { list, totalCharge }
    }
}