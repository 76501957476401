import { useSnapshot } from "valtio";
import { markupsStore, rtpStore } from "./pricing-store";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import PricingMarkupTable1 from "./pricing-markup-table1";
import PricingCountrymarkup from "./pricing-countrymarkup";
import PricingPortsmarkup from "./pricing-portsmarkup";
import MonApi from "../state/mongo-api";
import { useEffect } from "react";
import AirpricingPortsmarkup from "./airpricing-portsmarkup";
import AirpricingCountrymarkup from "./airpricing-countrymarkup";

export default function AirpricingMarkupEdit(props) {
    useSnapshot(rtpStore)
    useSnapshot(markupsStore)
    useEffect(() => {
        const f = async () => {
            console.log('\n\n\nAbout to get for key=', rtpStore.selectedMarkupCharge.chargeKey)
            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/markup/` + rtpStore.selectedMarkupCharge.chargeKey
            let ret = await MonApi.aget(url)
            markupsStore.current = ret
            console.log('GOT RET AS=', ret, '\n==================\n\n')

        }
        f()
    }, [rtpStore.selectedMarkupCharge])
    let rec = rtpStore.selectedMarkupCharge
    
    return (
        <>
            <h4 className="title is-5">
                {rtpStore.selectedMarkupCharge.chargeName}
            </h4>
            {
                rtpStore.selectedMarkupCharge.chargeKey == 'transport' ?
                    <section class="section section-padding" >
                        <Cols>
                            <Col2 style={{ color: 'darkblue' }}>Forward Air</Col2>
                            <Col5>
                                <input type="number" className="input is-small"
                                    value={markupsStore.current.forwardAir}
                                    style={{ width: '5rem' }}
                                    onChange={(ev) => {
                                        markupsStore.current.forwardAir = ev.target.value
                                    }} />
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col2 style={{ color: 'darkblue' }}>Insta Freight</Col2>
                            <Col5>
                                <input type="number" className="input is-small"
                                    value={markupsStore.current.instaFreight}
                                    style={{ width: '5rem' }}
                                    onChange={(ev) => {
                                        markupsStore.current.instaFreight = ev.target.value
                                    }} />
                            </Col5>
                        </Cols>
                        <Cols>
                            <Col2 style={{ color: 'darkblue' }}>India Transport</Col2>
                            <Col5>
                                <input type="number" className="input is-small"
                                    value={markupsStore.current.indiaTransport}
                                    style={{ width: '5rem' }}
                                    onChange={(ev) => {
                                        markupsStore.current.indiaTransport = ev.target.value
                                    }} />
                            </Col5>
                        </Cols>
                    </section>
                    :
                    <section class="section section-padding" >
                        <div class="blue-heading"></div>
                        <Cols>
                            <Col2 style={{ color: 'darkblue' }}>Default Mark-up</Col2>
                            <Col5>
                                <input type="number" className="input is-small"
                                    value={markupsStore.current.defaultMarkup}
                                    style={{ width: '5rem' }}
                                    onChange={(ev) => {
                                        markupsStore.current.defaultMarkup = ev.target.value
                                    }} />
                            </Col5>
                        </Cols>
                        <AirpricingCountrymarkup />
                        <AirpricingPortsmarkup />
                    </section>
            }
            <section>
                <Cols>
                    <Col5></Col5>
                    <Col1>
                        <button className="button is-danger"
                            onClick={() => {
                                rtpStore.markupView = 'list'
                            }}
                        >Cancel</button>
                    </Col1>
                    <Col2>
                        <button className="button islink"
                            onClick={() => {
                                const f = async () => {
                                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/markup/` + rec.chargeKey
                                    markupsStore.current._id = rec.chargeKey
                                    let ret = await MonApi.apost(url, markupsStore.current)
                                    rtpStore.markupView = 'list'
                                }
                                f()
                            }}
                        >Save</button>
                    </Col2>
                </Cols>
            </section>
        </>
    )
}