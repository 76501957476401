import { useSnapshot } from "valtio"
import { DtStore } from "../dt/DtStore"
import { dtactionStore } from "./dtaction-store"
import Dtnewinput from "../dt/cps/Dtnewinput"
import { Agmongofilenew } from "../components/AgFile"
import { loginStore } from "../state/CommonState"
import MonApi from "../state/mongo-api"
import Utils from "../util/Utils"
import { Col1, Col2, Col3, Col4, Col5, Col6, Col7, Col8, Cols } from "../Controls"
import { Col } from "reactstrap"
import DtactionsDocverify, { docverifyStages } from "./dtactions-docverify"
import { useEffect, useState } from "react"
import DtactionsPayment, { paymentStages } from "./dtactions-payment"
import DtactionsBalance, { balanceStages } from "./dtactions-balance"
import DtactionsFinaldocverify from "./dtactions-finaldocverify"
import DtactionReject from "./dtaction-reject";
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS, ROLES } from "../util/constants"
import EntityApi from "../state/entities-api";
import EntityApi1 from "../state/entity-api";

export default function DtactionsEdit(props) {
    const [submitCallback, setSubmitCallback] = useState(f => f)
    useSnapshot(DtStore)
    useSnapshot(dtactionStore)
    useSnapshot(loginStore)
    let dtStore = DtStore.DTBooking
    let action = dtactionStore.current
    let booking = action.booking
    console.log("book++++",booking)
    console.log("dsss",action)
    const [entityData,setEntiyData] = useState("")
    const [dtData, setdtData] = useState({})
        let server = process.env.REACT_APP_API_SERVER
    let client = loginStore.isClient()
    console.log('\n\nINITIAL VALUE OF SUBMIT CALLBACK=', dtactionStore.submitCallback)
    const [sidepanel,setSidepanel] = useState("History")
    const save = async () => {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions`
        console.log('\n\nVALUE OF SUBMIT CALLBACK=', dtactionStore.submitCallback)
        if (dtactionStore.submitCallback()) {
            let ret = await MonApi.apost(url, dtactionStore.current, 'PUT')
            dtactionStore.view = 'list'
            dtactionStore.listLoadCounter++
        }
    }
    const acknowledge = async () => {
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions/rejectacknowledge`
        let ret = await MonApi.apost(url, { _id:dtactionStore.current._id })
        dtactionStore.view = 'list'
        dtactionStore.listLoadCounter++
    }
    let pageTitle = 'Balance Payment'
    let showSubmit = true
    let showReject = false
    switch (action._id.actionName) {
        case 'Document Verification':
            pageTitle = 'Document Verification'
            break
        case 'Initiate Advance':
            pageTitle = 'Initiate Advance'
            break
    }
    let submitLabel = 'Submit'
    if (action._id.actionName.includes( 'Document Verification')) {
        console.log("S",action.stage,docverifyStages.COMPLETED)
        if (action.stage == docverifyStages.ALL_APPROVED) submitLabel = 'Acknowledge'
        if (action.stage == docverifyStages.COMPLETED) showSubmit = false
    }
    if (action._id.actionName == 'Initiate Advance') {
        if (action.stage == paymentStages.SUBMITTED) submitLabel = 'Acknowledge'
        if (action.stage == paymentStages.COMPLETED) showSubmit = false
        if (action.stage < paymentStages.COMPLETED) showReject = true
    }
    if (action._id.actionName == 'Balance Payment') {
        if (action.stage == balanceStages.SUBMITTED) submitLabel = 'Acknowledge'
        if (action.stage == balanceStages.COMPLETED) showSubmit = false
        if (action.stage < paymentStages.COMPLETED) showReject = true
    }
    if ((!(action.pendingWithRole === "SALES") || (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_BUSINESS_VIEW))) && (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_OPERATIONS_VIEW) && (!(action._id.actionName.includes('Initiate Advance')) || !(action._id.actionName.includes( 'Document Verification')) || !(action._id.actionName === 'Balance Payment')))) {
        showSubmit = false
        showReject = false
    }
    if ((!(action.pendingWithRole === "OPERATIONS") || (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_OPERATIONS))) && (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_FINANCE_BUSINESS_VIEW) && (!(action._id.actionName.includes('Initiate Advance')) || !(action._id.actionName.includes( 'Document Verification')) || !(action._id.actionName === 'Balance Payment')))) {
        showSubmit = false
        showReject = false
    }


    if(action.utrNo && action.status=="COMPLETED" && (action?._id?.actionName=="Initiate Advance" ||  action?._id?.actionName=="Balance Payment") ){
       showReject = false
       showSubmit = false
    }
    if( (action?.manualEnable=="OFF") && action?.status=="PENDING" && (action?._id?.actionName=="Initiate Advance" ||  action?._id?.actionName=="Balance Payment"))
    {
        showSubmit = false
    }
    
    
    if((action.manualEnable=="ON"||!action?.manualEnable ) && action.status=="PENDING" && (action?._id?.actionName=="Initiate Advance" ||  action?._id?.actionName=="Balance Payment") ){

        showSubmit=true
        showReject = true

    }

    if((!action.manualEnable) && action.status=="PENDING" && (action?._id?.actionName=="Initiate Advance" ||  action?._id?.actionName=="Balance Payment") ){
        showSubmit=false
    }

    

    useEffect(() => {
        EntityApi.viewEntity(dtactionStore.current.vendor.split(" (")[0]).then((data)=>{
            setEntiyData(data)
        })
        const entityId = action.entityId ? action.entityId : booking.entityId;
        EntityApi1.getEntityWithUser(entityId,entityDat=>{
            console.log(entityDat,'eeee');
            setdtData({
                rm : entityDat?.relationshipManager,
                cs : entityDat?.customerService,
                pan : entityDat?.pan
            })
        })
    }, [])

    if((entityData?.paymentEnable=="OFF"||!entityData?.paymentEnable) && action?.status=="PENDING" && (action?._id?.actionName=="Initiate Advance" ||  action?._id?.actionName=="Balance Payment")){
        showSubmit=true
    }

   if(action.status=="PENDING"&&!entityData?.paymentEnable && !action?.manualEnable && (action?._id?.actionName=="Initiate Advance" ||  action?._id?.actionName=="Balance Payment"))
   {
    showSubmit=true

    showReject = true

   }
  
    return (
        <>
        <DtactionReject />
            <div class="main-content" style={{ zoom: '0.9' }}>
                <div className="header-wrapper">
                    <div className="left-header">
                        <div className="page-heading">
                            <span className="heading">{action._id.actionName} / Booking / {booking._id}</span>
                        </div>
                    </div>
                </div>
                <section class="section section-padding" style={{ backgroundColor: '#eee', paddingTop: '0px' }}>
                    <Cols>
                        <Col7 style={{ zoom: '0.9' }}>
                            <Cols >
                                <Col4 style={{ color: 'darkblue' }}>Customer</Col4>
                                <Col7>{action.entityName}</Col7>
                            </Cols>
                            <Cols >
                                <Col4 style={{ color: 'darkblue' }}>Branch</Col4>
                                <Col7>{action.branch ? <span>{action.branch.state.split("/")[0]} - {action.branch.gst}</span> : null}</Col7>
                            </Cols>
                            <Cols>
                                <Col4 style={{ color: 'darkblue' }}>GST #</Col4>
                                <Col7>{action.branch ? <span>{action.branch.gst}</span> : null}</Col7>
                            </Cols>
                            <Cols>
                                <Col4 style={{ color: 'darkblue' }}>PAN</Col4>
                                <Col7>{dtData.pan}</Col7>
                            </Cols>
                            <Cols>
                            <Col4 style={{ color: 'darkblue' }}>Relationship Manager, Email, Contact</Col4>
                                <Col7>{dtData.rm}</Col7>
                            </Cols>
                            <Cols>
                                <Col4 style={{ color: 'darkblue' }}>Customer Service, Email, Contact</Col4>
                                <Col7>{dtData.cs}</Col7>
                            </Cols>
                            {
                                (action?.relationShipManagerSupply != undefined)?
                                <Cols>
                                <Col4 style={{ color: 'darkblue' }}>RelationShip Manager Supply, Email, Contact</Col4>
                                <Col7>{action.relationShipManagerSupply}</Col7>
                            </Cols>:""
                            }
                            <Cols>
                                <Col4 style={{ color: 'darkblue' }}>Vehicle Type</Col4>
                                <Col7>{action.vehicleType}</Col7>
                            </Cols>
                            {
                                (action.booking.details[0].pickup_2 != undefined && action.booking.details[0].pickup_2.trim().length>0)?
                                <>
                                <Cols>
                                <Col4 style={{ color: 'darkblue' }}>Pick-up Address 1</Col4>
                                <Col7>{action.booking.details[0].pickup}</Col7>
                            </Cols>
                            <Cols>
                                <Col4 style={{ color: 'darkblue' }}>Pick-up Address 2</Col4>
                                <Col7>{action.booking.details[0].pickup_2}</Col7>
                            </Cols>
                                </> 
                                :
                            <Cols>
                            <Col4 style={{ color: 'darkblue' }}>Pick-up Address</Col4>
                            <Col7>{action.booking.details[0].pickup}</Col7>
                        </Cols>       
                            }
                            
                        </Col7>
                        {
                            (sidepanel=="History")?<Col5>
                            <div><span style={{ fontWeight: 'bold', color: 'purple', fontSize: '1.1rem' }} onClick={()=>{setSidepanel("History")}}>History</span>{(action.rejecthistory!=undefined && action.rejecthistory.length>0)?<span onClick={()=>{setSidepanel("Reject History")}}>&nbsp;Reject History</span>:""}</div>
                            <table className="table is-fullwidth is-narrow" style={{ zoom: '0.8' }}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Event</th>
                                        <th>Whom</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        action?.history?.map(entry => {
                                            return (
                                                <tr>
                                                    <td>{('' + new Date(entry.timestamp)).split('GMT')[0]}</td>
                                                    <td>{entry.event}</td>
                                                    <td>{entry.email}</td>
                                                    <td>{entry.role}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Col5>:<Col5>
                            <div><span  onClick={()=>{setSidepanel("History")}}>History</span>{(action.rejecthistory!=undefined && action.rejecthistory.length>0)?<span style={{ fontWeight: 'bold', color: 'purple', fontSize: '1.1rem' }} onClick={()=>{setSidepanel("Reject History")}}>&nbsp;Reject History</span>:""}</div>
                            <table className="table is-fullwidth is-narrow" style={{ zoom: '0.8' }}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Event</th>
                                        <th>Whom</th>
                                        <th>Role</th>
                                        <th>Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        action.rejecthistory.map(entry => {
                                            return (
                                                <tr>
                                                    <td>{('' + new Date(entry.timestamp)).split('GMT')[0]}</td>
                                                    <td>{entry.event}</td>
                                                    <td>{entry.userid}</td>
                                                    <td>{entry.role}</td>
                                                    <td>{entry.reason}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Col5>
                        }
                        
                    </Cols>
                </section>
                {
                    (
                        function () {
                            if (action._id.actionName == 'Document Verification' || action._id.actionName == 'Reject - Document Verification') return <DtactionsDocverify setSubmitCallback={setSubmitCallback} />
                            else if (action._id.actionName == 'Final Document Verification') return <DtactionsFinaldocverify setSubmitCallback={setSubmitCallback} />
                            else if (action._id.actionName == 'Initiate Advance' || action._id.actionName == 'Reject - Initiate Advance') return <DtactionsPayment setSubmitCallback={setSubmitCallback} />
                            else return <DtactionsBalance setSubmitCallback={setSubmitCallback} />
                        }
                    )()
                }
                {
                    (action._id.actionName.indexOf('Reject')==-1)?
                    <Cols>
                    <Col8></Col8>
                    <Col1>
                    {action.utrNo && action.status=="COMPLETED"  ? null :   <button className="button is-danger " style={{ marginLeft: '3px' }}
                            onClick={(e) => {
                                dtactionStore.view = 'list'
                                action.utrNo=""
                                action.paymentAmt=""
                                action.paymentDate=""
                                action.remarks=""
                            }}
                        >Cancel</button>}
                      
                    </Col1>
                    {
                        (showSubmit) ?
                            <Col1>
                                <button className="button islink " style={{ marginLeft: '3px' }}
                                    onClick={(e) => {
                                        save()
                                    }}
                                >{submitLabel}</button>
                            </Col1>
                            : <></>
                    }
                    {
                        (showReject)?
                        <Col1>
                                <button className="button is-danger " style={{ marginLeft: '3px' }}
                                    onClick={(e) => {
                                        dtactionStore.modalVisible = true
                                    }}
                                >Reject</button>
                            </Col1>:""
                    }
                </Cols>:
                <Cols>
                <Col8></Col8>
                <Col1>
                    <button className="button is-danger " style={{ marginLeft: '3px' }}
                        onClick={(e) => {
                            dtactionStore.view = 'list'
                        }}
                    >Cancel</button>
                </Col1>
                {
                    (action.salesstatus && action.salesstatus=="PENDING" && (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.MYACTIONS_BUSINESS) || RoleUtils.isUserAuthorized(PERMISSIONS.NAV_MENU.ALL_ACCESS))) ?
                        <Col1>
                            <button className="button islink " style={{ marginLeft: '3px' }}
                                onClick={(e) => {
                                    acknowledge()
                                }}
                            >Acknowledge</button>
                        </Col1>
                        : <></>
                }
            </Cols>
                }
                
                <hr />
            </div>
        </>
    )
}