import { useState } from "react";
import { useSnapshot } from "valtio";
import { CustomProgressDottedBar, Line } from "../components/circle-line";
import {
  bookingsStore,
  configStore,
  contractsStore,
  loginStore,
} from "../state/CommonState";
import Utils from "../util/Utils";
import FCLChargeClientView from "./FCLChargeClientView";
import LCLChargeClientView from "./LCLChargeClientView";
import logo from "../aglogo.svg";
import schLogoMap from "./carrier-logo";
import LCLPricingSchedule from "../pricing/lcl.schedule";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import AirPricingSchedule from "../pricing/air.schedule";


export function DummySchedule({ stype, dummyContract, ...props }) {
  let client = loginStore.isClient();
  const [showCharges, setShowCharges] = useState(false);
  useSnapshot(contractsStore)
  useSnapshot(bookingsStore)
  let rec = contractsStore.current
  if (dummyContract) rec = dummyContract
  if (!rec) return <></>
  if (bookingsStore.current.createdFromPricing){
    if(stype=="LCL"){
      return <LCLPricingSchedule contract={bookingsStore.current.contract} isDummy={true} viewonly={true} nobutton={true}/>
    }
    if(stype=="AIR"){
      return <AirPricingSchedule contract={bookingsStore.current.contract} isDummy={true} viewonly={true} nobutton={true}/>
    }
}
  return (
    <div className="box">
      <div className="columns" style={{ fontSize: "0.8rem" }}>
        <div
          className="column is-1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px",
          }}
        >
          {stype.toLowerCase() === "lcl" ? (
            <img src={logo} />
          ) : (
            <>
            {
            (stype.toLowerCase().indexOf('fcl') != -1 && rec.carrier=="Agraga" && rec.scac && rec.scac.length>0)?
            <img src={schLogoMap[rec.scac]} alt={rec.scac}/>:
            <>
            {
              (stype.toLowerCase().indexOf('fcl') != -1 && rec.carrier=="Agraga" && (rec.scac==undefined || rec.scac.length==0))?
              <img src={logo} />:
              <img
            src={
              schLogoMap[
              rec.carrier
                .substring(
                  rec.carrier.lastIndexOf("(") + 1,
                  rec.carrier.lastIndexOf(")")
                )
                .trim()
              ]
            }
            alt={rec.carrier}
          />
            }
            </>
            }
            </>
          )}
        </div>
        <div className="column is-9">
          <div className="columns" style={{ flexDirection: "column" }}>
            <div style={{ alignSelf: "center" }}>
            {
                (rec.transitTime!=undefined && Number(rec.transitTime)>0)?<>
                <p style={{ color: "grey" }}>Transit Time</p>
              <p style={{ fontSize: "0.9rem", textAlign: "center" }}>
                <strong>{rec.transitTime} days</strong>
              </p>
                </>:<p style={{ color: "grey" }}>Schedule Awaited</p>
              }
            </div>
            <table
              className="schtable"
              style={{ width: "100%", textAlign: "center" }}
            >
              <tr>
                {rec.origin !== rec.portOfReceipt && (
                  <td style={{ textAlign: "center" }}>{rec.origin}</td>
                )}
                <td style={{ textAlign: "center" }}>{rec.portOfReceipt}</td>
                {rec.portOfReceipt !== rec.portOfLoading && (
                  <td style={{ textAlign: "center" }}>{rec.portOfLoading}</td>
                )}
                <td></td>
                <td></td>
                {rec.portOfDischarge !== rec.finalPlaceOfDelivery && (
                  <td style={{ textAlign: "center" }}>{rec.portOfDischarge}</td>
                )}
                <td style={{ textAlign: "center" }}>
                  {rec.finalPlaceOfDelivery}
                </td>
                {rec.destination !== rec.finalPlaceOfDelivery && (
                  <td style={{ textAlign: "center" }}>{rec.destination}</td>
                )}
              </tr>
              <tr>
                {rec.origin !== rec.portOfReceipt && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [
                        hide,
                        ["Door-to-Port", "Door-to-Door"].includes(
                          rec.shipmentScope
                        )
                          ? dotted
                          : false,
                      ]}
                    />
                  </td>
                )}
                <td>
                  <CustomProgressDottedBar
                    setStyle={(dotted, hide) => [
                      ["Door-to-Port", "Door-to-Door"].includes(
                        rec.shipmentScope
                      )
                        ? dotted
                        : rec.origin !== rec.portOfReceipt
                          ? false
                          : hide,
                      false,
                    ]}
                  />
                </td>
                {rec.portOfReceipt !== rec.portOfLoading && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [false, false]}
                    />
                  </td>
                )}
                <td>
                  <Line />
                </td>
                <td>
                  <Line />
                </td>
                {rec.portOfDischarge !== rec.finalPlaceOfDelivery && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [false, false]}
                    />
                  </td>
                )}
                <td>
                  <CustomProgressDottedBar
                    setStyle={(dotted, hide) => [
                      false,
                      ["Door-to-Door", "Port-to-Door"].includes(
                        rec.shipmentScope
                      )
                        ? dotted
                        : rec.destination !== rec.finalPlaceOfDelivery
                          ? false
                          : hide,
                    ]}
                  />
                </td>
                {rec.destination !== rec.finalPlaceOfDelivery && (
                  <td>
                    <CustomProgressDottedBar
                      setStyle={(dotted, hide) => [
                        ["Door-to-Door", "Port-to-Door"].includes(
                          rec.shipmentScope
                        )
                          ? dotted
                          : false,
                        hide,
                      ]}
                    />
                  </td>
                )}
              </tr>
            </table>
          </div>
        </div>
        <div className="column is-2" style={{ textAlign: "center" }}>
          <div style={{ fontSize: "1.0rem" }}>
            <strong>{Utils.N(rec.quoteValue)}</strong>
          </div>
          <div style={{ fontSize: "0.8rem" }}>
            {Utils.N(rec.quoteValueUSD, "USD")}
          </div>

          {(((!props.viewonly && rec.quoteStatus == 'ACCEPTED' && rec.status === 'SCHEDULES_UPLOADED')) && (RoleUtils.isUserAuthorized( PERMISSIONS.CREATE.BOOKING) || client)) ? (
            <><div style={{ fontSize: "0.6rem", color: "grey" }}>
            (Taxes, as Applicable)
          </div>
            <button
              className={
                configStore.scheduleOrCarrierform.toString() === "carrierform"
                  ? "button is-rounded is-link is-small is-hidden"
                  : "button is-rounded is-link is-small"
              }
              onClick={() => {
                bookingsStore.initialize();
                bookingsStore.current.schedule = {};
                bookingsStore.current.contract = { ...rec };
                bookingsStore.current.hasToShowDummySchedule = true;
                bookingsStore.current.contractNum = rec._id;
                configStore.scheduleOrCarrierform = "carrierform";
              }}
            >
              Proceed to Booking
            </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="columns" style={{ fontSize: "0.8rem" }}>
        {showCharges ? (
          <button
            className="button is-warning is-small is-fullwidth"
            onClick={() => setShowCharges(false)}
          >
            Hide Detailed Pricing
            <span className="icon" style={{ marginLeft: "5px" }}>
              <i className="fas fa-sort-up"></i>
            </span>
          </button>
        ) : (
          <button
            className="button is-info is-small is-fullwidth"
            onClick={() => setShowCharges(true)}
          >
            View Detailed Pricing
            <span className="icon" style={{ marginLeft: "5px" }}>
              <i className="fas fa-sort-down"></i>
            </span>
          </button>
        )}
      </div>
      {showCharges  ? (
        <div
          className="columns"
          style={{
            fontSize: "0.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {stype.toLowerCase() === "lcl" ? (
            <LCLChargeClientView dummyContract={dummyContract}/>
          ) : (
            <FCLChargeClientView />
          )}
          :
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
