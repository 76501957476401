import { useSnapshot } from "valtio"

export default function Dt30minselect(props) {
    const { label, record, name, dataList, callback = f => f, isValid = true, validationMsg, isDisabled  } = props
    const imRecord = useSnapshot(record)
    let a = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"]
    return (
        <div className="field">
            {
                label?<label className="label is-small " style={{ marginBottom: '0px' }}>{label}</label>:""
            }
            
            <div className="control">
                <div className={ isValid ? "select is-small" : "select is-small is-danger"} style={{width:"100%"}}>
                    <select {...props} name={name} value={imRecord[name]} style={{width:"100%"}}
                       {...isDisabled}
                        onChange={(e) => {
                            //console.log('FROM INSIDE AgSelect=', record, e.target.value)
                            record[name] = e.target.value
                            callback(e.target.value)
                        }} >
                            {
                                a.map((e) => (
                                    <option value={e}>{e}</option>
                                ))
                            }
                    </select>
                </div>
            </div>
            { !isValid && label && <p class="help is-danger"> { validationMsg || `* Please select valid ${label}` }</p> }
        </div>
    )
}