import { AgMuiSelect2 } from "./Controlled";
import LabeledTextField from "./LabeledTextField";

export function TextWithSelect({key,control,rules,name,selectName,options,unit,onChange,disabled,getValues}){
    //  return  disabled ? <div className="w-[170px] h-[25px] bg-[#F3F3F3] items-center border-solid border-[0.5px] border-[#D4D4D4]">{getValues(name)}</div> : 
    return <div style={{minHeight:"25px",display:"flex",flexDirection:"row"}}>
        <LabeledTextField
        key={key}
        disabled={disabled}
         onChange={()=>{
            if(onChange){
                onChange();
            }
        }} type="number" endAdornment={ <div style={{paddingLeft:"3px",backgroundColor:disabled?"white":"",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <p className="text-[13px] font-robot text-grey font-bold px-2 whitespace-nowrap">{unit}</p>
            {/* <AgMuiSelect2 padding={0} variant="standard" disableUnderline={true} showIconBorder={false}  options={options} control={control} name={selectName} 
            style = {{
                height:"25px",
                minWidth:"68px",
                fontSize:"13px",
                fontWeight:"bold",
                color: "#555555",
                borderLeft :0,
                padding:0,
                paddingRight: "8px",
                backgroundColor:"white",
                borderTopLeftRadius:"0px",
                borderBottomLeftRadius:"0px", 
                borderTopRightRadius:"3px",
                borderBottomRightRadius:"3px",
            }}
        
        /> */}
        </div>} disableUnderline={true}  width="170px" alignItems="center"  control={control} rules={rules} name={name} textfieldStyles={
            {   
                height:"25px",
                width : "170px",
                fontSize:"13px",
                padding:0
                // border:"0.5px solid #D4D4D4",
                // borderTopRightRadius:"0px",
                //     borderBottomRightRadius:"0px",
                //     borderTopLeftRadius:"3px",
                //     borderBottomLeftRadius:"3px", 
            }
        }  />
     </div>
}