
import { useState } from "react"
import { useSnapshot } from "valtio";
import { dtactionStore } from "./dtaction-store";
import MonApi from "../state/mongo-api";


export default function DtactionReject() {
    const [reason, setReason] = useState("");
    useSnapshot(dtactionStore)

    let modalClass = 'modal'
    if (dtactionStore.modalVisible) modalClass += ' is-active'
    const rejectsubmit = async () => {
        if(reason.trim().length>0){
            dtactionStore.modalVisible = false
            let url = `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions/reject`
            let ret = await MonApi.apost(url, { reason: reason,_id:dtactionStore.current._id })
            setReason("")
            dtactionStore.view = 'list'
            dtactionStore.listLoadCounter++
        }
    }
    return (
        <div className={modalClass} onClick={() => { dtactionStore.modalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >
              
                                
                                            <h2 style={{ color: "red" }}>REJECT REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                                onClick={()=>{
                                                    rejectsubmit()
                                                }}
                                            // type="submit" value={dtactionStore.currentMainComponent = <HomeMain/>}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => dtactionStore.modalVisible = false} >cancel</button>
                                       



                </section>
            </div>
        </div>
    )
}