import React, {  useState,useMemo,useEffect } from "react";
import { useTable,usePagination,useFilters, useGlobalFilter,useSortBy } from 'react-table'
import { GlobalFilter } from "./GlobalFilter";
import { ColumnFilter } from './ColumnFilter'
import ReactTablePagination from "../../components/ag-table/ReactTablePagination";
import { Sorting } from "../../components/ag-table/ag-sorting";
import { ShipperConsigneeStore, routerStore } from "../../state/CommonState";
function TableListView({ShippersData,count}) {

  // const [roomTableData ,setRoomTableData] = useState(ShippersData)
  const columns = useMemo(() => [
    {
      Header: 'Company Name',
      accessor: 'companyName',
      sticky: 'left'
    },
    {
      Header: 'Linked Entity',
      accessor: 'linkedEntity',
  
    },
    {
      Header: 'Address',
      accessor: 'address',
  
    },
    {
      Header: 'Country',
      accessor: 'country',
      sticky: 'left'
    },
  ], [])
  const data = useMemo(() => ShippersData, [ShippersData])


  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter
    }),
    []
  )



  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data:data || [],
      defaultColumn,
      initialState: { 
        pageIndex: 0,pageSize : 20 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )
  const { pageIndex, pageSize } = state
  const { globalFilter } = state
  const initialState = {
    queryPageIndex: 0,
    queryPageSize: 20,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {},
  }
  // const [
  //   {
  //     queryPageIndex,
  //     queryPageSize,
  //     totalCount,
  //     queryPageFilter,
  //     queryPageSortBy,
  //     queryPayload,
  //   },
  //   dispatch,
  // ] = React.useReducer(reducer, initialState)


  // const totalPageCount = Math.ceil(totalCount / queryPageSize)




  return (
          <>
        <div className="box">
          <div className="table-container" >
            <table className="table is-fullwidth is-small ">
              <thead className="is-scrollable-header has-background-white-ter is-unselectable">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}>
                        {column.render("Header")}
                        <Sorting column={column} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="entitiesV2List"
                style={{ fontSize: "0.9rem" }}
                {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => 
                       { let cellValue = cell["row"]["original"]
                        switch (cell["column"]["Header"]) {
                        case "Company Name":  return (
                          <td style={{color:"blue"}} onClick={()=> {
                            console.log(cellValue);
                            ShipperConsigneeStore.ShipperConsigneeRec.set(cellValue)
                            routerStore.shipperManageView = "edit"
                          }
                            }>
                          {cellValue.companyName}
                          </td>
                        )
                        case "Linked Entity": return (
                          <td>
                          {cellValue.linkedEntity || "--"}
                          </td>
                        )
    
                      default:
                      return (
                        <td {...cell.getCellProps()}>
                          <span>{cell.render("Cell")}</span>
                        </td>
                      )
                       }
                      }
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {page.length === 0 ?<p style={{textAlign:'center'}}>No Data Found</p>:""}
            {page.length > 0 && (
              <>
                <div className="columns" style={{ width: "100%" }}>
                  <div className="column is-12">
                    <ReactTablePagination
                      page={page}
                      gotoPage={gotoPage}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      pageOptions={pageOptions}
                      pageSize={pageSize}
                      pageIndex={pageIndex}
                      pageCount={pageCount}
                      setPageSize={setPageSize}
                      manualPageSize={[]}
                      dataLength={count}
                      rows={rows}
                    />
                  </div>
                  {/* <div className="column is-6" style={{ textAlign: "right" }}>
                    <div className="select">
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value))
                        }}>
                        {[5, 10, 20, 50, 100].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
          </>



  )
}


export default TableListView