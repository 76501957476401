import Api from "./Api"
import { entityStore, configStore, EntityV2Store } from "./CommonState"
const ENTITYURI = {
    LIST: `${process.env.REACT_APP_API_SERVER}/api/v1/entity`,
    GET: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/${id}`,
    GETENTITYWITHUSER: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/entitywithuser/${id}`,
    GETENTITYFORUSER: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/entityforuser/${id}`,
    PUT: `${process.env.REACT_APP_API_SERVER}/api/v1/entity`,
    POST: `${process.env.REACT_APP_API_SERVER}/api/v1/entity`,
    CREATEFORUSER: (email) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/addforuser/${email}`,
    CHANGE: (id) => `${process.env.REACT_APP_API_SERVER}/api/v1/entity/change/${id}`
}
export default class EntityApi {
    static getList(callback = f => f) {
        Api.fetch(ENTITYURI.LIST, (data) => {
            EntityV2Store.list = data  
            EntityV2Store.Cuslist= data.filter((r,i) => r.entityType === "Customer")
            callback(data)
        })
    }
    static getEntity(id,callback=f=>f) {
        Api.fetch(ENTITYURI.GET(id), (data) => {
            entityStore.entityRec.set(data)
            callback(data)
        })
    }
    static getEntityWithUser(id,callback=f=>f) {
        Api.fetch(ENTITYURI.GETENTITYWITHUSER(id), (data) => {
          callback(data)
        })
    }
    static saveEntity() {
        console.log('about to call api.put')
        Api.put(ENTITYURI.PUT, entityStore.entityRec, (data) => {
            configStore.setModalMessage('Entity Details Saved')
        })
    }
    static saveGivenEntity(entity) {
        console.log('about to call api.put')
        Api.put(ENTITYURI.PUT, entity, (data) => {
            //configStore.setModalMessage('Entity Details Saved')
        })
    }
    static createEntity() {
        console.log('about to call api.post')
        Api.post(ENTITYURI.POST, entityStore.entityRec, (data) => {
            configStore.setModalMessage('Entity Details Saved')
        })
    }
    static createEntityForUser(email, entity, callback=f=>f) {
        console.log('about to call api.post')
        Api.post(ENTITYURI.CREATEFORUSER(email), entity, (data) => {
            configStore.setModalMessage('New Entity created')
            callback(data)
        })
    }
    static changeEntity(id) {
        Api.fetch(ENTITYURI.CHANGE(id), (data) => {
            
        })
    }

    static EntityListForUser(id , callback) {
        Api.fetch(ENTITYURI.GETENTITYFORUSER(id), (data) => {
            callback(data)
        })
    }
}