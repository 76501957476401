import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import Utils, { Constants } from "../util/Utils"
import { PAGE_CHANGED, PAGE_FILTER_CHANGED, PAGE_SIZE_CHANGED, PAGE_SORT_CHANGED, PAYLOAD_CHANGED, reducer, TOTAL_COUNT_CHANGED } from "../components/ag-table/ag-reducer";
import ApiAgTable from "../components/ag-table/api_ag-table";
import { bookingsStore, breadCrumbStore, configStore, contractsStore } from "../state/CommonState";
import { truckLoader, TruckLoader } from "../mui-components/TruckLoader";
import filter2 from "../assets/images/filter2.svg";
import { usePagination, useSortBy, useTable } from "react-table";
// import { ClientUploadArchives, ClientUploadContract, ClientUploadQuote } from "../components/ag-table/columns";
import Header from "../entity-v2/components/Header";
import { Box, Menu, MenuItem } from "@mui/material";
import Api from "../state/Api";
import { enquiryStore } from "../enquiry/enquiry-data";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import { InputAdornment, TextField } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { Nodata } from "../mui-components/nodatafound";
import { debounce } from "lodash";

const Stages = [Constants.quotations,Constants.contracts];
const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: { 
        date:Utils.getCurrentDateString(),
        mode:Constants.quotations,
        type : "LCL",
        status : "ALL",
        // sort:    {customerReferenceNum: 1}
      }
};

const ClientUploadQuote = [
    {
      Header: "Quotation Date",
      accessor: "mailedOn",
    },
    {
      Header: "Customer Reference #",
      accessor: "customerReferenceNum",
    },
    {
        isComponent : true,
      Header : FilterMode,
      accessor:"shipmentType"
    },
    {
      Header: "Quotation Reference #",
      accessor: "quotationNum",
    },
    {
      Header: "Origin",
      accessor: "origin",
    },
    {
      Header: "Destination",
      accessor: (contract)=> contract.multidest?.length>1 ? "Multiple" : contract.destination,
    },
    {
      Header: "Valid Till",
      accessor: "validUpto",
    },
  ];
  const ClientUploadContract = [
    {
      Header: "Contract Effective Date",
      accessor: "contractCreatedOn",
    },
    {
      Header: "Customer Reference #",
      accessor: "customerReferenceNum",
    },
    {
        isComponent : true,
      Header : FilterMode,
      accessor:"shipmentType"
    },
    {
      Header: "Contract Reference #",
      accessor: "_id",
    },
    {
      Header: "Origin",
      accessor: "origin",
    },
    {
      Header: "Destination",
      accessor: (contract)=> contract.multidest?.length>1 ? "Multiple" : contract.destination,
    },
    {
      Header: "Valid Till",
      accessor: "validUpto",
    },
  ];


  function FilterMode2({options=[]}){
    return <div className="relative flex flex-1 text-left">
    <div>
      <button type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
        Mode
        <img src={filter2} />
      </button>
    </div>
    <div class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
      <div class="py-1" role="none">
      {options.map((opt)=>  <p className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1">{opt}</p>)}
      </div>
    </div>
  </div>
  
  }

  function FilterMode({onClickHandler,options=[]}){
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <div onClick={handleClick}  className="flex flex-1 flex-row w-full gap-1 bg-[transparent] border-0 hover:cursor-pointer">
        <p className="text-sm font-barlow text-grey font-medium">Mode</p>
        <img src={filter2} />
    </div>
   <Menu  
   anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <div className="flex flex-col rounded-[5px] gap-[5px]">
      {options.map((opt)=><div onClick={()=>{
        if(onClickHandler){
            onClickHandler(opt);
        }
        handleClose();
      }} className="text-sm w-[142px] bg-white hover:bg-blue-light hover:text-white hover:rounded-[5px] uppercase p-3 mx-2 hover:cursor-pointer">{opt}</div>)}
      </div>
   </Menu>
    </>
  }

export function DataTable(){
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    useReducer(reducer, initialState);
    const abortControllerRef = useRef(null);
    const [isLoading,setLoading] = useState(true);
    const [activeProduct, setActiveProduct] = useState('ALL');
    let productList = ['ALL','LCL', 'FCL', 'Air'];
    const [data1, setData1] = useState({ results: [] });
    const [searchText, setSearchText] = useState("")
    const [selectedType, setType] = useState(Constants.quotations);
  
    const deBouncingCall = useCallback(Utils.debounce((keyword)=>{
      dispatch({type:PAGE_FILTER_CHANGED,payload:keyword?.trim()})
      gotoPage(0);
    },400),[])
    useEffect(()=>{
      if(queryPayload.status){
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        setLoading(true)
    ApiAgTable.uploadCheduleData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, {...queryPayload,mode:selectedType,type:activeProduct},abortController.signal).then(res=>{
        res.clone().json().then((res) => {
            if (JSON.stringify(data1?.results) != JSON.stringify(res?.results)) {
                if(JSON.stringify(data1)!=JSON.stringify(res) && res.results && res.results.length > 0){
                    setData1(res);
                }else{
                    setData1({ count: 0, results: [], results1: [] });
                }
            }
            setLoading(false);
        }, err => {
            setLoading(false);
            console.log(err)
        })
    }).catch((e)=>{
        if(e.name==="AbortError"){
            setLoading(true)
        } else{
             setLoading(false);
        }
        
        setData1({ results: [] });
    })
    }
},[queryPayload,queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy , configStore.currentSelectedBranch , configStore.currentSelectedEntity,activeProduct,selectedType])

const totalPageCount = Math.ceil(totalCount / queryPageSize)

let column = ClientUploadQuote;

 if(selectedType === 'Quotations'){
    column = ClientUploadQuote
}else if(selectedType === 'Contracts'){
    column = ClientUploadContract
}

let columns = column;

const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
} = useTable({
    columns,
    data: data1?.results,
    initialState: { 
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
    },
    manualPagination: true,
    pageCount: data1 ? totalPageCount : null,
    autoResetSortBy: false,
    autoResetExpanded: false,
    autoResetPage: false
},
    useSortBy,
    usePagination,
);
const manualPageSize = [];


 useEffect(() => {
     dispatch({ type: PAGE_CHANGED, payload: pageIndex });
 }, [pageIndex,gotoPage]);

 useEffect(() => {
     dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
     gotoPage(0);
 }, [pageSize]);

 useEffect(() => {
     dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
     gotoPage(0);
 }, [sortBy]);

 useEffect(() => {
   deBouncingCall(searchText);
 }, [searchText]);

//  useEffect(() => {
//      dispatch({ type: PAYLOAD_CHANGED, payload: {  date:Utils.getCurrentDateString(),mode:selectedType,type:activeProduct } });
//      gotoPage(0);
//  }, [columns,activeProduct,selectedType]);

 useEffect(() => {
     if (data1?.count) {
         dispatch({
             type: TOTAL_COUNT_CHANGED,
             payload: data1?.count,
         });
     }
 }, [data1?.count]);


useEffect(()=>{
  breadCrumbStore.id = undefined;
},[])


useEffect(()=>{
  
        truckLoader.open = isLoading;
 },[isLoading]);

 
 



console.log(" the column is page are ",page )

    return <div className="flex flex-col flex-1 gap-2">
        <div className="flex flex-row justify-end">
    <TextField
            sx={{ width: "20%" }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={searchText}
            onChange={(e) => {
                setSearchText(e.target.value) 
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CiSearch style={{ fontSize: 20 }} />
                    </InputAdornment>
                ),
                style: { borderRadius: '57px', height: '35px', backgroundColor: "white" },
            }}
            placeholder="Search"
        />
    </div>
        <TruckLoader/>
             <div className="w-full h-[40px] bg-blue-dark rounded-t-md flex flex-row">
            {Stages.map((type,i)=><div onClick={()=>{
                 setType(type);
            }} className={` h-full text-white font-bold text-[18px] flex items-center justify-center w-[143px] hover:cursor-pointer ${type===Constants.quotations && "rounded-tl-md"} ${type===selectedType && "bg-yellow"}`}>{type}</div>)}
        </div>
        <div className="flex w-full bg-white rounded-[10px] h-[500px] p-3 flex-col overflow-x-auto gap-2">
          <div className="flex flex-col flex-1 p-1 min-w-[1300px]  overflow-y-auto">
          <div className="flex flex-row w-full p-3 bg-[#F1F7FB] rounded-md">
            {columns.map((col)=>
            <div className="px-[4px] w-full">{
            col.isComponent ? 
            <col.Header options={productList} onClickHandler={(type)=>{
                setActiveProduct(type);
            }}/> : <p className="flex flex-1 text-sm font-barlow text-grey font-medium">{col.Header}</p>
        }
            </div>
            )}
            </div>
            <div className="flex flex-col w-full h-full">
               {page?.length>0 ? page.map((currentPage)=>{
                let rec = currentPage.original;
               return <div onClick={()=>{
                setLoading(true);
                 Api.getContract(rec._id, (data) => {
                    if(Constants.quotations === selectedType){
                      breadCrumbStore.id = rec.quotationNum;
                    }else{
                      breadCrumbStore.id = rec._id
                    }
                    // if (loginStore.isClient()) {
                        // let l = 1
                        // loginStore.userRec.companyList.filter((e) => {
                        //     if (e?.entityId == data?.entityId) {
                        //         configStore.currentSelectedEntity = e
                        //         l = 0
                        //     }
                        // })
                        // if (l == 0) {
                        setLoading(false);
                            enquiryStore.initializeWithConract(data)
                            
                            //if (loginStore.isClient()) Api.createContractFromQuote(contractId)
                            configStore.homescreenCurrentView = 'view'
                             configStore.scheduleOrCarrierform = 'schedule'
                            let stype = enquiryStore.current.shipmentType.substr(0, 3).toLowerCase()
                            if (stype === 'lcl') configStore.homescreenCurrentShipmentType = 'LCL'
                            else if (stype === 'fcl') configStore.homescreenCurrentShipmentType = 'FCL'
                            else configStore.homescreenCurrentShipmentType = 'Air'
                            configStore.homescreenCurrentShipmentScope = enquiryStore.current.shipmentScope;
                            breadCrumbStore.addCrumb(Constants.chooseschedule);
                        // } 
                        // else {
                        //     window.location.href = window.location.href
                        //  }
                    // }
                })
               }} className="flex flex-row p-3 bg-white border-solid border-[#DDDDDD] border-t-0 border-l-0 border-r-0 hover:cursor-pointer hover:bg-[#F3F3F3]">
                      {columns.map((col)=><p className="flex flex-1 text-sm font-barlow text-grey font-medium px-[4px]">{ typeof col.accessor === "function" ? col.accessor(rec) : rec[col.accessor]}</p>)}
               </div>}
            ) : <Nodata/>}
            </div>
          </div>
          <ReactTablePagination
                        page={page}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        setPageSize={setPageSize}
                        manualPageSize={manualPageSize}
                        dataLength={totalCount}
                        rows={rows}
                    />
        {/* <table className="w-full table-auto ">
        <thead className="w-full p-3 bg-[#F1F7FB] min-h-[35px] rounded-md">
            <tr >
                <th  className="text-sm font-barlow text-grey font-medium">Quotation Date</th>
                <th  className="text-sm font-barlow text-grey font-medium">Customer reference</th>
                <th  className="text-sm font-barlow text-grey font-medium">Quotation reference</th>
                <th  className="text-sm font-barlow text-grey font-medium">Qrigin</th>
                <th  className="text-sm font-barlow text-grey font-medium">Destination</th>
                <th  className="text-sm font-barlow text-grey font-medium">Status</th>
                <th  className="text-sm font-barlow text-grey font-medium">Valid Till</th>
            </tr>
        </thead>
        <tbody>

        </tbody>
        </table> */}
        </div>
    </div>
}

const queryClient = new QueryClient()

export function QuotationAndContract(){
   return <QueryClientProvider client={queryClient}>
    <DataTable />
</QueryClientProvider>
}