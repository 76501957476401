import { Avatar, Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LabeledSelectField from './LabeledSelectField'
import { useForm } from 'react-hook-form';
import LabeledTextField from './LabeledTextField';
import BranchApi from '../state/branch-api';
import { City, Country, State } from 'country-state-city';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { EntityTypeV2 } from '../state/Types';
import { CUSTOMER_CATEGORY } from '../util/constants';
import EntityApi from '../state/entity-api';
import EntityV2Api from '../state/entities-api';
import UsersApis from '../state/users-api';
import { EntityV2Store, configStore, cususerStore, loginStore, newDashboardState } from '../state/CommonState';
import { debounce } from 'lodash';
import { useSnapshot } from 'valtio';
import TempOnBoard from '../pages/TempOnBoard';
import EntityUtils from '../util/entityUtils';
import AguserApi from '../state/AguserApi';

const   EntityCreationModal = (props) => {
    const [defaultValue, setdefaultValue] = useState({})
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [loading, setloading] = useState(false);
    const [Countries, setCountries] = useState([]);
    // const [loading , setloading] = useState(false);
    const [modalType, setModalType] = useState('EntityCreation');
    const [ entityData , setEntityData] = useState({});

   let Entity = EntityV2Store.EntityRec
   useSnapshot(EntityV2Store);
//    useSnapshot(newDashboardState);
   useSnapshot(loginStore);
//    let defalutformData = new EntityTypeV2()
//    defalutformData.customer.customerType = 'Enterprise'

    const defaultForm = useForm({
        defaultValues: new EntityTypeV2()
    });

    const { control, handleSubmit, register, unregister, reset, setValue, clearErrors, setError, resetField, formState: { errors , isValid}, getValues, watch } = defaultForm


    useEffect(() => {
        let country = Country.getAllCountries().filter(
            (x) => x.name === 'India'
        );
        const Statess = State.getStatesOfCountry(country[0]?.isoCode);
        setStates(Statess)
        filterCountries();
        setValue('customer.customerType' , 'Enterprise')

    }, [])

    const filterCountries = () => {
        const array = Country.getAllCountries().filter(
          (x, i) => State.getStatesOfCountry(x.isoCode).length !== 0
        );
        setCountries(array);
      };


    const submitHandler = async (data) => {
        if(!loading){
            console.log('data', data);
            setloading(true)
            data.entityType = 'Customer'
            data.scopeOfBusiness= ['Cross Border']
            data.createdBy = loginStore.email
            if(EntityUtils.isIndia(Entity) && data.branch[0].gst.length > 0 ){
                const extractedPan = EntityUtils.extractPanFromGST(data.branch[0].gst)
                if(extractedPan !== data.panNum){
                  setError(`branch[0].gst` , { message :'Pan and Gst Mismatch'})
                  return 
                }
              }
              if(isValid === false){
               return
              }
            await EntityV2Api.createEntityBycustomer(setloading, data, async (Data) => {
                if (Data && Data.EntityFound !== true) {
                    await AguserApi.getUser(loginStore.email,async (dataUser) => {
                        let userData = dataUser
                        let datas = {
                            from : userData.firstName + ' ' + userData.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
                           }
                        let  obj = {entityIdentifier : data.panNum , entityId : Data.data.entityId,  role : "Entity Admin" , designation : "" , gstList :[] , status : "ACTIVE" , remarks : "",isAdmin:true}
                        userData.mainEntityList.push(obj)   
                        cususerStore.cususerRec.set(userData)
                       await UsersApis.createUser(datas , async(UserCredata)=> {
                         console.log(UserCredata);
                         if(UserCredata.data.status === 201){
                            loginStore.userRec.aguserRec = UserCredata.data.userData
                            loginStore.userRec.email = UserCredata.data.userData.email
                         }
                         props.props(false)
                         setloading(false)
                         if(configStore.currentSelectedEntity?.entityId === ''){
                           await EntityV2Api.viewEntity(Data.data.entityId , async (data)=> {
                           let datas
                           datas = data
                           datas.branches=data.branch
                            configStore.currentSelectedEntity = datas
                            configStore.currentSelectedBranch = data.branch?.length>0 ?  data?.branch[0] : {"branchId" : "ALL"}
                            setloading(false)
                            // newDashboardState.selectedTab= 0
                            if(props.type === 'fromQuickQuote'){
                                await  EntityV2Api.EntityListForUser(loginStore.email , (data)=> {
                                    loginStore.userRec.companyList=data.data
                                    let userDatas = localStorage.getItem('ag_userrec')
                                   let userDataUpd= JSON.parse(userDatas)
                                   userDataUpd.companyList= data.data 
                                    localStorage.setItem('ag_userrec' , JSON.stringify(userDataUpd)) 
                                })
                            }            
                           })
                         }
    
                        } ,userData , 'cusCreation' )
                    }
                      )
                  
                }
               else if (Data.EntityFound === true ){
                    setEntityData(Data)
                    setModalType('UserPopUp')
                }
            })

        }
      
    }

    const handleChangeGst = async (value) => {
        console.log('branch[0].gst', value);
        setValue('branch[0].gst', value.toUpperCase())
    }

    const handleChangePan = async (value) => {
        // if (value.length === 10) {
        //     await EntityV2Api.checkPan(value.toUpperCase(), (response) => {
        //         if (response.status === true) {
        //             // setError('panNum', { message: 'PAN already registered' });

        //         } else {
        //             clearErrors("panNum")
        //         }
        //     });

        // }
        setValue('panNum', value.toUpperCase())
    }
    const debouncedhandleChangeTin = debounce((r)=>handleChangeTin(r), 500 )

    const handleChangeTin = async (value) => {
        console.log('loko', value);
        // if (value.length >= 9 && value.length <= 20) {
        //     await EntityV2Api.checkTin(value.toUpperCase(), (response) => {
        //         if (response.status === true) {
        //             // setError('tinNum', { message: 'Tin already registered' });

        //         } else {
        //             clearErrors("tinNum")
        //         }
        //     });

        // }
        setValue('tinNum', value.toUpperCase())
    }

    function getStates(r) {
        console.log(r);
        let country = Country.getAllCountries().filter(
            (x) => x.name === r.target.value
        );
        const Statess = State.getStatesOfCountry(country[0]?.isoCode);
        setStates(Statess)
        setCities([])
        setValue('state', '')
        setValue('city', '')
        setValue('entityCountryCode', country[0].isoCode)
        // setValue('entityCountry', r.target.value.name)
        Entity.entityCountry=r.target.value
    }

    function getCities(r) {
        let country = getValues('entityCountry')
        const EntityCountry = Country.getAllCountries().filter(
            (r) => r.name === country
        );
        console.log(r.target.value.split('/')[1]);
        let stateCode = r.target.value.split('/')[1]
        let cities = City.getCitiesOfState(EntityCountry[0].isoCode, stateCode)
        setCities(cities)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        bgcolor: '#f0f0f0',
        border: '2px solid grey.500',
        boxShadow: 24,
        borderRadius: '16px',
        pt: 2,
        px: 4,
        pb: 3,
    };

    // console.log(getValues());
    console.log('customerbh',Object.values(CUSTOMER_CATEGORY))

    return (
        <>
        {
            ( modalType === 'EntityCreation') ? <>
            <Modal
            open={true}
            onClose={() => props.props(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <form onSubmit={handleSubmit(submitHandler)}   >
                <Box sx={{ ...style, width: '50%' }}  >
                    <IconButton sx={{ position: 'absolute', top: '-25px', left: '50%', transform: 'translateX(-50%)', zIndex: 1, padding: '0.5%', backgroundColor: '#f0f0f0' }}>
                        <Avatar style={{ backgroundColor: '#266ed4' }}>
                            <AddBusinessIcon style={{ backgroundColor: '#266ed4' }} />
                        </Avatar>
                    </IconButton>
                    <Typography variant="h6" align='center' sx={{ fontWeight: 600 }} >Add New Entity</Typography>
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={2} pb={0}>
                            <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{
                                    required: "Entity Name is required", maxLength: {
                                        value: 100,
                                        message: "Entity Name cannot exceed 100 characters"
                                    }
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa", textTransform: "uppercase" }} fontSize="14px" label="Entity Name*" control={control} name="entityName" />
                            </Stack>
                            <Stack sx={{ width: '50%' }}>
                                <LabeledSelectField rules={{ required: "Country is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Country*" control={control} name="entityCountry" callback={(r) => getStates(r)} options={Countries} />
                            </Stack>
                        </Stack>
                        <Stack direction='row' spacing={2} pb={0}>
                        { (getValues('entityCountry').toLowerCase() === 'india') ?
                            <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{
                                    required: "PAN is required", pattern: {
                                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                        message: "Invalid PAN format. Eg: ABCDE1234F"
                                    }, maxLength: {
                                        value: 10,
                                        message: "Pan Number cannot exceed 10 characters"
                                    }
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="PAN*" control={control} name="panNum" callback={handleChangePan} />
                            </Stack> : <>
                            <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{
                                    required: "TIN is required", pattern: {
                                        value: /^[\w\s!@#$%^&*()-+=<>,.?/:;'[\]{}|~-]{1,20}$/,
                                        message: "Invalid TIN format."
                                    }, maxLength: {
                                        value: 20,
                                        message: "Tin Number cannot exceed 20 characters"
                                    }
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="TIN*" control={control} name="tinNum" callback={debounce((r)=>handleChangeTin(r), 500 )} />
                            </Stack>
                            </>

                        }
                           
                            <Stack sx={{ width: '50%' }}>
                                <LabeledSelectField rules={{ required: "Category is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Entity Category*" control={control} name="customer.customerType" options={Object.values(CUSTOMER_CATEGORY)} />
                            </Stack>
                        </Stack>
                        <Stack direction='row' spacing={2} pb={0}>
                        {
                            getValues('entityCountry').toLowerCase() === 'india' ?
                            <Stack sx={{ width: '50%' }} >
                                <LabeledTextField rules={{
                                    pattern: {
                                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                                        message: "Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK"
                                    },
                                    maxLength: {
                                        value: 15,
                                        message: "GST cannot exceed 15 characters"
                                    }
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa", textTransform: "uppercase" }} fontSize="14px" label="GST Number" control={control} name="branch[0].gst" callback={handleChangeGst} />
                            </Stack> : null

                        }
                        {
                            <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Address" control={control} name="branch[0].branchAddresses[0].address"  />
                            </Stack>

                        }
                        
                       
                            {
                            getValues('entityCountry').toLowerCase() !== 'india' ?
                            <Stack sx={{ width: '50%' }}>
                                <LabeledSelectField rules={{ }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="State" control={control} name="branch[0].state" callback={(r) => getCities(r)} options={states} />
                            </Stack> : null

                           }
                        </Stack>
                        <Stack direction='row' spacing={2} pb={0}>
                        { getValues('entityCountry').toLowerCase() === 'india' ?
                            <Stack sx={{ width: '50%' }}>
                                <LabeledSelectField rules={{ }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="State" control={control} name="branch[0].state" callback={(r) => getCities(r)} options={states} />
                            </Stack> : 
                            <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{  }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="City" control={control} name="branch[0].branchAddresses[0].city"  />
                            </Stack>
                        }
                        {
                            getValues('entityCountry').toLowerCase() === 'india' ?
                            <Stack sx={{ width: '50%' }}>
                                <LabeledSelectField rules={{  }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="City" control={control} name="branch[0].branchAddresses[0].city" options={cities} />
                            </Stack> : null

                        }
                       

                        </Stack>
                        <Stack direction='row' spacing={2} pb={0}>
                        {
                            getValues('entityCountry').toLowerCase() === 'india' ?
                            <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{
                                   pattern: {
                                        value: /^[0-9]/,
                                        message: "Only numbers are allowed"
                                    }, maxLength: {
                                        value: 6,
                                        message: "PinCode cannot exceed 6 characters"
                                    }
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Pincode" control={control} name="branch[0].branchAddresses[0].pincode" />
                            </Stack> : <Stack sx={{ width: '50%' }}>
                                <LabeledTextField rules={{
                                   pattern: {
                                        value: /^[0-9]/,
                                        message: "Only numbers are allowed"
                                    }, maxLength: {
                                        value: 10,
                                        message: "ZipCode cannot exceed 10 characters"
                                    }
                                }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} textfieldStyles={{ background: "#fafafa" }} fontSize="14px" label="Zipcode" control={control} name="branch[0].branchAddresses[0].zipcode" />
                            </Stack>
                        }
                        </Stack>


                    </Stack>

                    <Box width='100%' >
                        <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={2}>
                            <Button variant='outlined' p={2} onClick={() => { props.props(false); reset() }}>Cancel</Button>
                            <Button variant='contained' p={2} type="submit">Submit</Button>
                        </Stack>
                    </Box>
                </Box>
            </form>
        </Modal>

            </> : null
        }
        {
            ( modalType === 'UserPopUp') ? 
            <div style={{position:"absolute",right:"1%",top:"0.1%"}}>
            <TempOnBoard callback={ props.props} isNewUser={true} EntityData={entityData} type={'entityCreation'}/>
            </div> 
             : null
        }
        </>

       
    )
}

export default EntityCreationModal
