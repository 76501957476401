import { useEffect, useRef, useState } from "react"
import { useSnapshot } from "valtio"
import Utils from "../../util/Utils"
import { DtStore } from "../DtStore"

export default function Dtnewinput(props) {
    const { record,label, name, type = 'text', callback = f => f, isValid = true,  classnames, mindate } = props
    let { isDisabled } = props
    const imRecord = useSnapshot(record)
    useSnapshot(DtStore)
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    let ph = ''
    if(label){
        ph = `${label}`
    }else{
        switch (name) {
            case 'customer_ref_no':
                ph = 'Enter Customer Reference'
                break;
            case 'cargo_Value':
                ph = 'Enter Value'
                break;
            case 'vehicle_no':
                ph = 'Enter Vehicle Registration'
                break;
            case 'drivername':
                ph = 'Enter Driver Name'
                break;
            case 'drivernumber':
                ph = 'Enter Driver Contact'
                break;
            case 'vendor_assigned':
                ph = 'Enter Vendor Assigned'
                break;
            case 'vendor_agreed_cost':
                ph = 'Enter Assigned Cost'
                break;
            case 'additional_cost_reason':
                ph = 'Enter Reason'
                break;
            case 'vendor_additional_cost':
                ph = 'Enter Additional Cost'
                break;
            case 'relationship_manager':
                ph = 'Enter Relationship Manager'
                break;
            case 'customer_success':
                ph = 'Enter Customer Success'
                break;
            case 'operations_representative':
                ph = 'Enter Operations Rep'
                break;
            case 'total_cost':
                ph = 'Enter Total Cost'
                break;
            case 'cargo_Value':
                ph = 'Enter Cargo Value'
                break;
            default:
                ph = `Enter Value`
                break;
        }
    }

    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    let min = ''
    let max = '2120-01-01'
    if (type === 'date') {
        min = Utils.getCurrentDateString()
    }
    if(type === 'date'&& name == 'pickupdate'){
        min = Utils.getCurrentDateString()
        max = DtStore.DTBooking.valid_upto
    }
    if(type === 'date'&& ["ewaybilldate","pod_date"].indexOf(name)!=-1){
        min = mindate
    }
    if(type === 'date' && name=="pod_date"){
        max = Utils.getCurrentDateString()
    }
    let align = {}
    if (type === 'number') align = { align: 'right' }
    if (type === 'text') align = { align: 'right' }
    const handleChange = (e, name) => {
        if(name=='vehicle_no'){
            setCursor(e.target.selectionStart)
            record[name] = e.target.value.trim().replace(/[^0-9a-zA-Z]/g, '')
            callback(e.target.value)
        }else{
            setCursor(e.target.selectionStart)
            record[name] = e.target.value.trim()
            callback(e.target.value)
        }
        return e.target.value
        
    }
    return (
        <>
   

                {
                    (type === 'date' || type=="time") ? <><input style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                        record[name] = e.target.value
                        callback(e.target.value)
                    }}
                        {...isDisabled}
                        className={isValid ? classnames+" inputtime" : classnames+" inputtime"+" is-danger is-danger-border"}
                        type={type} min={min} max={max} onFocus={(e) => {
                            if(type === 'date'){
                                document.getElementById(name).showPicker()
                            }
                            
                        }}
                        placeholder={ph} /> {type=="time"?<img src="./assets/clock.png" className="clock"/>:""}</>: <>
                        {
                            (type === 'number' || type=='email') ? <input style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                                handleChange(e, name)
                            }}
                                {...isDisabled}
                                className={isValid ? classnames : classnames+" is-danger is-danger-border"}
                                type={type} min={min} max={max}
                                placeholder={ph} 
                                onKeyDown={(e) =>{
                                    if(type === 'number'){
                                    ["e", "E"].includes(e.key) && e.preventDefault()
                                    }
                                }
                                    
                                  }
                                /> : <input ref={ref} style={{ ...align }} {...props} id={name} name={name} value={imRecord[name]} onChange={(e) => {
                                    handleChange(e, name)
                                }}
                                    {...isDisabled}
                                    className={isValid ? classnames : classnames+" is-danger is-danger-border"}
                                    type={type} min={min} max={max}
                                    placeholder={ph} />
                        }
                    </>
                }



   </>
    )
}