export default function AgLabel(props) {
    return (
        <div className="field">
            <label className="label is-small">{props.label}</label>
            <div className="control">
                <span  className="is-small"
                    style={{fontSize: '0.9rem', width:'100%', 
                        justifyContent: 'left'}}>{props.value}</span>
            </div>
        </div>
    )
}