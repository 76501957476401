import { useState } from "react";
import { useSnapshot } from "valtio";
import { taskStore } from "./task-store";

export default function TaskDatetime(props) {
    useSnapshot(taskStore)
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    // today = dd + '/' + mm + '/' + yyyy;
    // today =  yyyy + '-' + 'mm' + 'dd';
    return (
        <>
        <div className="columns" style={{marginTop: '10px', marginBottom: '10px'}}>
            <div className="column is-2" style={{fontSize: '0.9rem', fontWeight:'bold'}}>Due Date</div>
            <div className="column is-2">
                <input type='date' min={`${yyyy}-${mm}-${dd}`} value={taskStore.current.dueDate} onChange={(e) => {
                    taskStore.current.dueDate = e.target.value
                    // taskStore.current.dueDate = today
                }}></input>
            </div>
            <div className="column is-2" style={{fontSize: '0.9rem', fontWeight:'bold'}}>Due Time</div>
            <div className="column is-2">
                <select style={{width:'3rem'}} value={taskStore.current.dueTime} onChange={(e) => {
                    taskStore.current.dueTime = e.target.value
                }}>
                    {
                        ['','0:00','1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00',
                        '11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00',
                        '20:00','21:00','22:00','23:00'].map(e=> <option value={e}>{e}</option>)
                    }
                </select> hrs
            </div>
        </div>
        </>
    )
}