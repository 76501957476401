import { useSnapshot } from "valtio"
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { contractsStore } from "../state/CommonState"

export default function AirChargesClientViewv3(props) {
    let { chargesName } = props
    useSnapshot(contractsStore)
    if (!chargesName) chargesName = 'chargesList'
    let rec = contractsStore.current
    if (props.dummyContract) {
        rec = props.dummyContract
    }

    const Tn = ({ i, name }) => {
        const e = props.data[i]
        //let num = (''+e[name]).replaceAll(',','')
        let num = e[name]
        num = isNaN(num) ? 0.0 : Number(num)
        return num != 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
    }
    const displayRec = (e) => {
        const perKg = parseFloat(e.perKg)
        const perAWB = parseFloat(e.perAWB)
        const total = parseFloat(e.total)
        if (perKg != 0 || perAWB != 0 || total != 0) return true
        return false
    }
    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < rec[chargesName].length; i++) {
            let e = rec[chargesName][i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        let size = 12
        if(listc.length && lists.length){
            size = 4
        }else if(listc.length){
            size = 6
        }else if(lists.length){
            size = 6
        }
        if(listc.length && !lists.length && !listo.length){
            size = 12
        }
        if(!listc.length && lists.length && !listo.length){
            size = 12
        }
        if(!listc.length && !lists.length && listo.length){
            size = 12
        }
        return <>
        <div class="columns">
            {
                (listo.length)?<div class={"column is-"+size}><AirChargesClientViewv3 data={listo} 
                chargeType='On Your Account' /></div>:""
            }
            {
                (listc.length)?<div class={"column is-"+size}><AirChargesClientViewv3 data={listc} 
                chargeType='On Consignee Account' /></div>:""
            }
            {
                (lists.length)?<div class={"column is-"+size}><AirChargesClientViewv3 data={lists} chargeType='On Shipper Account' /></div>:""
            }
            </div>
        </>
    }
    let counter = 1
    let finalTotal = 0.0
    props.data.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
    })
    return (
        <>
            <hr />
            <div className="table-container">
            <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='8'>
                                <h3 className="is-size-5 has-text-centered">{props.chargeType}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan='2' className="has-text-centered">Per Unit</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th style={{width: '20rem'}}>Charge Description</th>
                            <th>Currency</th>
                            <th style={{textAlign:'center'}}>Per Kg</th>
                            <th style={{textAlign:'center'}}>Minimum</th>
                            <th style={{textAlign:'center'}}>Per AWB</th>
                            <th style={{textAlign:'right'}}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            props.data.map((e, i) => {
                                {
                                    return (displayRec(e)) ? 
                                    <tr key={e.id}>
                                        <td >{counter++}</td>
                                        <td>{e.chargeDescription}</td>
                                        <td>{e.currency}</td>
                                        <td  style={{textAlign:'center'}}><Tn i={i} name='perKg' /></td>
                                        <td style={{textAlign:'center'}}><Tn i={i} name='minimum' /></td>
                                        <td style={{textAlign:'center'}}><Tn i={i} name='perAWB' /></td>
                                        <td style={{textAlign:'right'}}>{Utils.N(e.total, e.currency)}</td>
                                    </tr> : <></>
                                }
                            }
                            )
                        }
                        <tr>
                            <td colSpan={6}></td>
                            <td style={{textAlign: 'right'}}><strong>{Utils.N(finalTotal)}</strong></td>
                        </tr>
                        <tr>
                            <td colSpan={7} style={{textAlign: 'right'}}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
