

export function SelectedRadio(){
    return <div className="flex items-center justify-center h-[20px] w-[20px] rounded-[58px] bg-white border-solid border-2 border-blue-dark">
        <div className="h-[12px] w-[12px] rounded-[58px] bg-blue-dark"></div>
    </div>
}

export function UnSelectedRadio(){
    return <div className="h-[20px] w-[20px] rounded-[58px] bg-[#F5F5F5] border-solid border-2 border-[#D4D4D4]" ></div>
}