import { proxy, subscribe } from 'valtio'
import AguserApi from './state/AguserApi'
import { configStore, loginStore } from './state/CommonState'
import MyProfileNew from './agusers/my-profile-new'
import { useGlobalState } from './GlobalState'

export const tourStore = proxy({
    enable:false,
    show:false,
    step:0,
    stepfun: () =>{
        switch (tourStore.step) {
            case 5:
                AguserApi.getUser(loginStore.email)
                configStore.currentMainComponent = <MyProfileNew />
            break;
        }
    },
    nextstep : ()=>{
        tourStore.step  = tourStore.step+1
        tourStore.stepfun()
    },
    prevstep: ()=>{
        tourStore.step  = tourStore.step-1
        tourStore.stepfun()
    },
    changestep: (i)=>{
        switch (i) {
            case 1:
                tourStore.step=2
            break;
            case 2:
                tourStore.step=3
            break;
        }
        tourStore.stepfun()
    },
    setstep:(i)=>{
        tourStore.step = i
    },
    tourSteps:[
      {
          selector: '', 
          content: 'Welcome to Agraga! We have put together a Demo Tour for you to be able to navigate our Platform. You can "Skip" this Tour or Click on the "Close" button on the Top RHS, if you want to continue this Tour later.'
      },
      {
        selector: '.tour', 
        content: 'Move your cursor on the Entity icon to view the list of Entities mapped to you. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.tour1', 
        content: 'Select the Entity you wish to transact with. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.tour2', 
        content: 'Move your cursor on your Name to access "Sign Out" and "My Profile". To Continue the Tour, click "Next!"'
      },
      {
        selector: '.tour3', 
        content: 'Click on "My Profile" to access and edit your information. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.tour4', 
        content: 'You can change your password by clicking "Change Password". To Continue the Tour, click "Next!"'
      },
      {
        selector: '.tour5', 
        content: 'Click on "Add Entity" to map a new Entity to your User profile and transact against the same. To Continue the Tour, click "Next!"'
      },
      // {
      //   selector: '.tour6', 
      //   content: 'To invite a new User against Entities mapped to you, where you are the Client Admin, click on "Invite User". To Continue the Tour, click "Next!"'
      // },
      {
        selector: '.home-menu', 
        content: 'Click on "Home" to view detailed analytics of your Financial Status, Booking Information, Active & Expired Contracts, and past history of all your transactions. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.enquiry-menu', 
        content: 'Click on "Enquiries" to submit an Enquiry for LCL, FCL or Air Bookings. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.realtimepricing-menu', 
        content: 'Click on "Real-time Pricing" to explore end-to-end LCL solutions and place bookings, within seconds. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.createbooking-menu', 
        content: 'To access Cross-Border Quotations & Contracts and place Bookings, Click on "Cross-Border" under "Create Booking". To Continue the Tour, click "Next!"'
      },
      {
        selector: '.createbooking-menu', 
        content: '13.To access Domestic Transport Contracts and place Bookings, Click on "Domestic Transport" under Create Booking. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.bookings-menu', 
        content: 'To access Domestic Transport Contracts and place Bookings, Click on "Domestic Transport" under "Create Booking". To Continue the Tour, click "Next!"'
      },
      {
        selector: '.bookings-menu', 
        content: 'To access and view details of "Domestic Transport" Bookings placed by you or your team and to track their Status, click on "Domestic Transport" under "Bookings". To Continue the Tour, click "Next!"'
      },
      {
        selector: '.bookings-menu', 
        content: 'To Track the status of your "Cross-Border" Bookings and also access all the Documents against each of these Bookings, click on "Visibility". To Continue the Tour, click "Next!"'
      },
      {
        selector: '.visibility-menu', 
        content: 'To execute all the requirements against your Cross-Border Bookings, click on "My Actions" to view and complete pending tasks. To Continue the Tour, click "Next!"'
      },
      {
        selector: '.visibility-menu', 
        content: 'Click on "Access Control" to view and control the devices on which your Login is Active. Thank you for taking the Tour. '
      }
    ],
})