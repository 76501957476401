import { useSnapshot } from "valtio"
import { breadCrumbStore, configStore, loginStore } from "../state/CommonState"
import { useGlobalState } from "../GlobalState"
import { useState } from "react"
import Api from "../state/Api";
import { taskStore } from "../mytasks/task-store";

export default function AgModal() {
    const [reason, setReason] = useState("");
    const { changeCurrentMenu } = useGlobalState();
    useSnapshot(configStore)
    useSnapshot(taskStore)
    let modalClass = 'modal'
    if (configStore.modalVisible) modalClass += ' is-active'
    const ListAjvErrors = () => {
        if (configStore.ajvErrors && configStore.ajvErrors.length > 0) {
            return (
                <section className="modal-card-foot">
                    <ol>
                        {configStore.ajvErrors.map(e => <li>{e}</li>)}
                    </ol>
                </section>
            )
        }
        return <></>
    }
    const declinesubmit = () => {
        configStore.modalVisible = false
        Api.declineContract(configStore.setDecline, { reason: reason })
        setReason("")
        configStore.setModalMessage("Quotation is declined.")
        if(configStore.showQoute){
            configStore.showQoute = false;
            return;
        }
        changeCurrentMenu("Enquiries")
        breadCrumbStore.back();
    }
    const declinesubmit1 = () => {
        configStore.modalVisible = false
        Api.declineContract(configStore.setDecline, { reason: reason })
        setReason("")
        configStore.setModalMessage("Quotation is declined.")
        changeCurrentMenu("Upload Schedules")
        Api.getContracts()


    }

    const cancelMyaction = () => {
        taskStore.current.actionName = ""
        configStore.modalVisible = false
    }

    return (
        <div className={modalClass} onClick={() => { configStore.modalVisible = false }} style={{zIndex:1000000}}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: configStore.tc.length > 0 ? '70%' : '' }} >
                <section className="modal-card-body" >
                    {
                        (configStore.tc.length == 0 && configStore.setDecline.length == 0 && configStore.setMyacticecompleted.length == 0) ?
                            <>
                                <button className="delete" style={{ float: 'right' }}
                                    onClick={() => { configStore.modalVisible = false; configStore.isDisable = false }}></button>
                                <span>{configStore.modalMessage}</span></> :
                            <>
                                {
                                    (configStore.tc.length > 0) ?
                                        <>
                                            <article class="message is-info">
                                                <div class="message-header">
                                                    Terms and Conditions
                                                    <button class="delete is-large" onClick={() => { configStore.modalVisible = false }}></button>
                                                </div>
                                                <div class="message-body" >
                                                    <ol type="1" style={{ paddingLeft: '20px' }}>
                                                        {
                                                            configStore.tc.map(e => {
                                                                return <li>{e}</li>
                                                            })
                                                        }

                                                    </ol> </div>
                                            </article>
                                        </> : <></>
                                }
                                {
                                    (configStore.setDecline.length > 0 ) ?
                                        <>
                                            <h2 style={{ color: "red" }}>DECLINE REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                                onClick={()=>{
                                                    if(loginStore.isClient()){
                                                        declinesubmit()
                                                    }else{
                                                        declinesubmit1()
                                                    }
                                                    
                                                }}
                                            // type="submit" value={configStore.currentMainComponent = <HomeMain/>}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => configStore.modalVisible = false} >cancel</button>
                                        </>: <>
                                        
                                        </>
                                }
                                

{
                                    (configStore.setMyacticecompleted.length > 0) ?
                                        <>
                                            <h2 style={{ color: "black", textAlign:"center" }}>{configStore.setMyacticecompleted}</h2>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                                onClick={() => configStore.modalVisible = false}
                                            >Proceed</button>
                                            {
                                                (configStore.setMyacttioncancelbtn)?<>
                                                <button class="button is-warning" onClick={cancelMyaction} >cancel</button>
                                                </>:<></>
                                            }
                                            
                                        </>: <></>
                                }


                            </>
                    }


                </section>
                <ListAjvErrors />
            </div>
        </div>
    )
}