import { currencyList, chargesData } from "../StaticData"
import { TableEdit, TableSelect, TableLabel, AgInput, AgSelect } from "../components/AgComponents"
import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { EntityV2Store, configStore, entityStore } from "../state/CommonState"
import {
    quoteDataRec, fclChargesStore, containerStore
} from '../state/CommonState'

import Utils from "../util/Utils"
import { chargesValidationStore } from "../state/validation-stores"

export default function FCLChargesCompv2() {
    const [accountTotal, setAccountTotal] = useState({})
    const [shipTotal, setShipTotal] = useState({})
    const [conTotal, setConTotal] = useState({})
    const [onaccount, setonaccount] = useState(['On Your Account'])
    useSnapshot(quoteDataRec)
    useSnapshot(entityStore)

    const imContainerStore = useSnapshot(containerStore)
    const numContainers = imContainerStore.list.length
    const containerNames = imContainerStore.list.map(e => e.containerType)
    const data = chargesData[quoteDataRec.shipmentType]
    let [space, setSpace] = useState('')

    if (fclChargesStore.list.length > 0) {
        let oldchargeskey = []
        Object.entries(fclChargesStore.list[0]).filter(([key, value]) => {
            oldchargeskey.push(key)
        })
        let newchargeskey = []
        oldchargeskey.map(e => {
            if (e.indexOf("'") == -1) {
                newchargeskey.push(e)
            } else {
                containerNames.map(f => {
                    if (e.indexOf(f) > -1) {
                        newchargeskey.push(e)
                    }
                })
            }
        })
        let uniqueContainer = new Set(containerNames)
        if (oldchargeskey.sort().toString() != newchargeskey.sort().toString() && containerNames.length === uniqueContainer.size) {
            let newchargelist = []
            fclChargesStore.list.map((e) => {
                var result = Object.fromEntries(newchargeskey.map(col => [col, e[col]]));
                newchargelist.push(result)
            })
            fclChargesStore.list = newchargelist;
        }

    }

    let imChargesStore = useSnapshot(fclChargesStore)
    let a = imChargesStore.newRec
    let b = imChargesStore.editRec

    let list = fclChargesStore.list
    for (let i = 0; i < list.length; i++) {
        if (!list[i].onaccount || !list[i].onaccount.length) list[i].onaccount = 'On Your Account'
    }
    useEffect(() => {
        let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }

            if (cecisorigin && sscope.endsWith('port')) setonaccount(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) setonaccount(['On Your Account', `Shipper's Account`])
            else setonaccount(['On Your Account'])
            fclChargesStore.list.map((e) => {
                if(sscope.length!=0 && country.length!=0 && por.length!=0 && pod.length!=0){
                    if(e.onaccount!=undefined){
                        if (cecisorigin && sscope.endsWith('port')){
                            if(['On Your Account', `Consignee's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else if (cecisdestination && sscope.startsWith('port')){
                            if(['On Your Account', `Shipper's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else{
                            e.onaccount = "On Your Account"
                        }
                    }
                }
                return e
            })
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)
    }, [
        quoteDataRec.entity,quoteDataRec.entityId, quoteDataRec.portOfLoading,
        quoteDataRec.finalPlaceOfDelivery, quoteDataRec.shipmentScope
    ])
    const deleteRec = (id) => {
        if (id <= imChargesStore.initialLength) return
        const index = fclChargesStore.list.findIndex(e => e.id === id)
        let records = fclChargesStore.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        fclChargesStore.list = records
        //console.log('AFTER DELETE=', JSON.stringify(fclChargesStore.cargoList))
    }
    const Th2 = (props) => <th rowSpan="2" {...props} style={{ paddingTop: '1.5rem' }}>{props.children}</th>
    const Ts = ({ i, name, dataList }) => <TableSelect
        record={fclChargesStore.list[i]} name={name} dataList={dataList} isValid={chargesValidationStore.isValidCharge(name, i)} />
    const Te = ({ i, name }) => {
        const e = fclChargesStore.list[i]
        if (name === 'chargeDescription' && i < fclChargesStore.initialLength)
            return <TableLabel record={fclChargesStore.list[i]} name={name} />
        return (
            <TableEdit record={fclChargesStore.list[i]} name={name} isValid={chargesValidationStore.isValidCharge(name, i)}/>
        )
    }
    const Tn = ({ i, name }) => {
        const e = fclChargesStore.list[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <input disabled='true' style={{ width: '3rem', backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEdit record={fclChargesStore.list[i]} name={name} type='number' isValid={chargesValidationStore.isValidCharge(name, i)} />
        )
    }
    const Tsn = ({ name, dataList }) => <TableSelect
        record={fclChargesStore.newRec} name={name} dataList={dataList} />
    const Ten = ({ name }) => <TableEdit record={fclChargesStore.newRec} name={name} />
    const Tnn = ({ name }) => <TableEdit record={fclChargesStore.newRec} name={name} type='numeric' />
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0

    let grandTotal = {}
    let cTotal = {}
    let sTotal = {}
    let cPresent = false
    let sPresent = false
    containerStore.list.map(c => {
        grandTotal[c.containerType] = 0.0
        cTotal[c.containerType] = 0.0
        sTotal[c.containerType] = 0.0
    })
    fclChargesStore.list.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        containerStore.list.map(c => {
            let totalField = c.containerType + 'total'
            if (e.onaccount && e.onaccount.startsWith('Cons')) {
                cPresent = true
                cTotal[c.containerType] += parseFloat(e[totalField]) * erate
            }
            else if (e.onaccount && e.onaccount.startsWith('Ship')) {
                sPresent = true
                sTotal[c.containerType] += parseFloat(e[totalField]) * erate
            }
            else grandTotal[c.containerType] += parseFloat(e[totalField]) * erate
        })
    })
    if(fclChargesStore.list.length>0){
        fclChargesStore.defaultValueCalc() // Need to calculate for validation
    }
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {/* <th colSpan='2'>
                                <button className="button is-link is-small is-rounded" onClick={() => {
                                    fclChargesStore.calculateChargesTotals(quoteDataRec.emptycontainerweight);
                                    setSpace(space === '' ? ' ' : '')
                                }}>Click to Re-Calculate Charges</button>
                            </th> */}
                            <th colSpan='20'>
                                <h3 className="is-size-5 has-text-centered">Charges{space}</h3>
                            </th>
                        </tr>
                        {
                            (containerNames.length>0)?<tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map(e => (
                                <>
                                <td className="has-text-centered has-text-weight-bold" colSpan={3}>{e}</td>
                                <th></th>
                                </>
                            ))}
                            <th></th>
                        </tr>:""
                        }
                        <tr>
                            <th>ID</th>
                            <th>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th>Per Container</th>
                                    <th>Per BL</th>
                                    <th>Per Ton</th>
                                    <th style={{ textAlign: "right" }}>Total</th>
                                </>
                            ))}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fclChargesStore.list.map((e, i) => {
                                {
                                    return <tr key={e.id}>
                                        <td >{e.id}</td>
                                        <td><Te i={i} name='chargeDescription' /></td>
                                        <td><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                        <td><Ts i={i} name='currency' dataList={currencyList} /></td>
                                        {containerNames.map(e1 => (
                                            <>
                                                <td><Tn i={i} name={e1 + 'perWM'} /></td>
                                                <td><Tn i={i} name={e1 + 'perShipment'} /></td>
                                                <td><Tn i={i} name={e1 + 'perTon'} /></td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {
                                                        (e[e1 + 'total'] > 0 && e.currency != '') ?
                                                            Utils.N(e[e1 + 'total'], e.currency) : ''
                                                    }
                                                </td>
                                            </>
                                        ))}
                                        <td>
                                            <div className="field has-addons">

                                                {
                                                    (i >= fclChargesStore.initialLength) ?
                                                        <p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    i === fclChargesStore.list.length - 1 && (
                                                        <p className="control">
                                                            <button className="button is-success is-inverted"
                                                                onClick={() => {
                                                                    let obj = fclChargesStore.newRec
                                                                    obj = { ...obj }
                                                                    fclChargesStore.list.push({ ...obj, id: fclChargesStore.list.length + 1 })
                                                                    fclChargesStore.defaultValueCalc()
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon is-small">
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        <tr>
                            <td colSpan={4} style={{ textAlign: 'right' }}>On Your Account</td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                    {Utils.N(grandTotal[c.containerType])}
                                                </strong>
                                            </td>
                                        </>
                                    )
                                })
                            }
                            <td rowSpan={(cPresent && sPresent)?4:(cPresent || sPresent)?3:2} style={{ textAlign: 'center' }}>
                            <button className="button is-info is-inverted"
                                                        
                                                        onClick={() => {
                                                            fclChargesStore.calculateChargesTotals(quoteDataRec.emptycontainerweight);
                                    setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-calculator"></i>
                                                        </span>
                                                    </button>
                            </td>
                        </tr>
                        {
                            cPresent &&
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'right' }}>On Consignee's Account</td>
                                {
                                    containerStore.list.map(c => {
                                        return (
                                            <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <strong>
                                                        {Utils.N(cTotal[c.containerType])}
                                                    </strong>
                                                </td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        }
                        {
                            sPresent &&
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'right' }}>On Shipper's Account</td>
                                {
                                    containerStore.list.map(c => {
                                        return (
                                            <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <strong>
                                                        {Utils.N(sTotal[c.containerType])}
                                                    </strong>
                                                </td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
        </>
    )
}
