import { useEffect, useState } from "react"
import {  useSnapshot } from "valtio"
import Api from "../state/Api"
import { configStore, contractsStore, entityStore, loginStore, modalStore, enquiriesValidationStore, AgmodalStore } from "../state/CommonState"
import SchApi from "../state/sch-api"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import Utils from "../util/Utils"
import Newapi from "../state/new-api"
import ScheduleUtils from "../util/SchedulesUtils"
import QuotationUtils from "../util/quotationUtil"
import { useGlobalState } from "../GlobalState"
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data"
import { BookingLocationsv3 } from "./booking-locations-v3"
import QuoteCalcV2 from "../util/quote-calc-v2"
import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils";
import { ENTITY_STATUS, PERMISSIONS } from "../util/constants";
import { Button} from "@mui/material"
import EntityV2Api from "../state/entities-api"
import { DummySchedulev3 } from "./dummy-schedule-v3"
import AirScheduleCompv3 from "./AirScheduleComp-v3"
import CarrierWebFormv3 from "./CarrierWebForm-v3"
import LCLSchedulesv3 from "./LCLSchedules-v3"
import EnquiryConfirmationv3 from "./enquiry-confirmation-v3"
import { useForm } from "react-hook-form"
import { Route } from "../v3-componets/AgRoute"
import { Details } from "../v3-componets/AgDetails"


export default function HomeScreenLCLv3(props) {

    let client = loginStore.isClient()
    let isUserAuthorized = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CB_QUOTATION_CREATE_DUMMY)
    let isUserAuthorizedForSaveOnly = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.SAVE_UPLOAD_SCHEDULES_ONLY) 
    const { currentMenu } = useGlobalState();
    console.log('INSIDE HOMESCREEN LCL')
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(modalStore)
    const [show, setShow] = useState(false);
    const rec = contractsStore.current


    useEffect(()=>{
        loader.show()
        const callback = (data)=>{
            let k = new QuoteCalcV2(rec).recalc()
            if(JSON.stringify(k)!=JSON.stringify(rec)){
                contractsStore.current = k
            }
            loader.hide()
        }
        Api.getLiveExchangerates(callback)

        return()=>{
            enquiryStore.initialize();
            configStore.homescreenCurrentView = "list";
        }
    },[])







    console.log(contractsStore.current['quoteStatus'], "ddddddddddddddd")
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [fclSchedules, setFclSchedules] = useState([])
    const [activeProduct, setActiveProduct] = useState(configStore.homescreenCurrentShipmentType)
    let productList = ['LCL', 'FCL', 'Air']
    let e = enquiryStore.current
    if (loginStore.isClient() && contractsStore.current.isFromEnquiry && e.tsClientviewOn == undefined) {
        e.tsClientviewOn = Date.now()
        let id = contractsStore.current._id
        if (id.endsWith('C')) {
            id = id.substr(0, id.length - 1)
        }
        EnquiriesApi.updateToClientView(id)
    }

    // below line is a temporary fix to hide Air. To be removed.
    // productList = ['LCL', 'FCL']
    if (contractsStore.current['mode'] == 'CONTRACT' && contractsStore.current['quoteStatus'] != 'DECLINED' && contractsStore.current['quoteStatus'] != 'ACCEPTED') {
        contractsStore.current['quoteStatus'] = "ACCEPTED"
    }


    // let enquiryNum = configStore.enquiryModalNum

    const NmO_onClick = () => {
        let stat = EnquiriesApi.saveEnquiry()
        EnquiriesApi.getAllEnquiries()
        
        if (stat) {setActiveProduct("LCL")
        configStore.displaySchedules = false
        configStore.showEnquiries = true
        contractsStore.current = {}
        modalStore.checkingDestination = false
        configStore.homescreenCurrentShipmentType = "LCL"}
        
    }

    let enquiriesValidationStoreSnap = useSnapshot(enquiryStore.current)


    const onClick = () => {
        enquiriesValidationStore.cargoReadinessDate = enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate.trim() === ''
        enquiriesValidationStore.origin = enquiriesValidationStoreSnap.enqRoute.origin.trim() === ''
        enquiriesValidationStore.destination = enquiriesValidationStoreSnap.enqRoute.destination.trim() === ''


        configStore.displaySchedules = true
        if (props.viewonly) {
            if (activeProduct.toLowerCase() === "fcl" && !enquiryStore.current.containerDetails.every(QuotationUtils.isValidContainer)) {
                configStore.setModalMessage("Invalid Container details.");
                return;
            }
            if (activeProduct.toLowerCase() !== "fcl" || (activeProduct.toLowerCase() === "fcl" && QuotationUtils.hasCargoDetail(enquiryStore.current.shipmentType, enquiryStore.current.shipmentScope))) {
                if (enquiryStore.current.cargoDetails.length === 0 || !enquiryStore.current.cargoDetails.every(cargo => QuotationUtils.isValidCargo(activeProduct, enquiryStore.current.shipmentScope, cargo))) {
                    console.log("🚀 ~ file: home-screen-lcl.js ~ line 170 ~ HomeScreenLCL ~ enquiryStore", enquiryStore.current.cargoDetails)
                    configStore.setModalMessage("Invalid Cargo details.");
                    return;
                }
            }

            contractsStore.current = enquiryStore.current.mergeIntoContract(rec)


        } else {
            Api.getMergedContract()
        }
    }

    useEffect(()=>{
        if(contractsStore.current && configStore.displaySchedules && ((activeProduct === 'LCL' && schedules.length==0) || (activeProduct != 'LCL' && fclSchedules.length==0)) && (contractsStore.current.hasToShowDummySchedule==undefined || contractsStore.current.hasToShowDummySchedule==false) && contractsStore.current.status=='SCHEDULES_UPLOADED'){
            contractsStore.current.hasToShowDummySchedule = true
        }
        if(contractsStore.current && configStore.displaySchedules && ((activeProduct === 'LCL' && schedules.length>0) || (activeProduct != 'LCL' && fclSchedules.length>0)) && contractsStore.current.hasToShowDummySchedule!=undefined && contractsStore.current.hasToShowDummySchedule!=false && contractsStore.current.status=='SCHEDULES_UPLOADED'){
            contractsStore.current.hasToShowDummySchedule = false
        }
    },[fclSchedules,schedules])
    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            orginScope: false,
            destinationScope: false,
            originType: false,
            destinationType: false,
            showCargoDetailsOutGuage: false,
            showPalletLabel:false
        }
    })
    useEffect(()=>{loader.show()},[])
    useEffect(()=>{
        console.log("++++++++++++++++++++++34",enquiryStore.current)
        if(enquiryStore.current.enqRoute!=undefined && enquiryStore.current.enqRoute.origin != undefined && enquiryStore.current.enqRoute.origin.length>0){
            reset({
                mode: "LCL",
                orginScope: false,
                destinationScope: false,
                originType: false,
                destinationType: false,
                showCargoDetailsOutGuage: false,
                showPalletLabel:false
            })
            if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
                enquiryStore.current.shipmentType = 'FCL'
                setValue("mode",'FCL');
            }else{
                setValue("mode",enquiryStore.current.shipmentType);
            }
            setValue("multidest",enquiryStore.current.multidest)
            setValue("containerDetails",enquiryStore.current.containerDetails)
            setValue("fbaalways","Yes")
            setValue("origin",enquiryStore.current.enqRoute.origin)
            setValue("cargoreadinessdate",enquiryStore.current.enqRoute.cargoReadinessDate)
            setValue("scope",enquiryStore.current.shipmentScope)
            setValue("shipmentType",enquiryStore.current.shipmentType)
            setValue("customerreference",enquiryStore.current.enqRoute.customerReferenceNum)
            setValue("hazardous",enquiryStore.current.hazardous === "No" ? false :true)
            setValue("quoteClass",enquiryStore.current.quoteClass)
            setValue("packingGroup",enquiryStore.current.packingGroup)
            setValue("temperature",enquiryStore.current.temperature)
            setValue("cargoValue",enquiryStore.current.cargoValue)
            setValue("unnumber",enquiryStore.current.unnumber)
            setValue("nonStackable", enquiryStore.current.nonStackable === "No" ? false :true)
            setValue("temperatureControlled", enquiryStore.current.temperatureControlled === "No" ? false :true)
            setValue("minTemperature",enquiryStore.current.minTemperature)
            setValue("maxTemperature",enquiryStore.current.maxTemperature)
            setValue("stuffingType",enquiryStore.current.stuffingType)
            setValue("destuffingType",enquiryStore.current.destuffingType)
            setValue("status",enquiryStore.current.status)
            setValue("cargoDimensionUnit",enquiryStore.current.cargoDimensionUnit)
            setValue("enqRoute",enquiryStore.current.enqRoute)
            setValue("vehicledetails",enquiryStore.current.vehicles)
            setValue("isFba",enquiryStore.current.fba === "Yes" ? true :false)
            setValue("ior",enquiryStore.current.fbaIorRequired === "Yes" ? true :false)
            setValue("occ",enquiryStore.current.fbaOCC === "Yes" ? true :false)
            setValue("dcc",enquiryStore.current.fbaDCC === "Yes" ? true :false)
            setValue("survey",enquiryStore.current.fbaSurvey === "Yes" ? true :false)
            setValue("storageAtDestination",enquiryStore.current.fbaSAD === "Yes" ? true :false)
            setValue("palletizationandlabelling",enquiryStore.current.fbaPAL === "Yes" ? true :false)
            setValue("days",enquiryStore.current.fbaDays!=undefined?enquiryStore.current.fbaDays:0)
            setValue("pick_up_by_amazon",enquiryStore.current.pick_up_by_amazon === "Yes" ? true :false)
            // setValue("multidest",enquiryStore.current.multidest)
            setShow(true)
            loader.hide()
        }
    },[enquiryStore.current])
    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = cargoForm;
    const updatecusref = (e) =>{
        if(contractsStore.current['customerReferenceNum']!=e){
            contractsStore.current['customerReferenceNum'] = e
        }
    }
    useEffect(() => {
        if (!configStore.displaySchedules) configStore.showEnquiries = true
        if (props.viewonly || !props.viewonly) {
            const callbackcheck = async (data)=>{
                let d = []
                for (let i = 0; i < data.length; i++) {
                    if(ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), data[i], contractsStore.current)){
                        d.push(data[i])
                    }
                }
                if(d.length>0){
                    if(activeProduct === 'LCL'){
                        setSchedules(d)
                    }else{
                        setFclSchedules(d)
                    }
                    if(rec.status === "UPLOAD_SCHEDULES"){
                        SchApi.saveSchedules(rec._id, d)
                        await Newapi.releaseContractLock(rec._id)
                        rec.status = "SCHEDULES_UPLOADED"
                        setSaveEnabled(false)
                        configStore.scheduleOrCarrierform = 'schedule'
                    }
                }
            }
            (activeProduct === 'LCL') ?
                SchApi.getSchedules(rec._id, callbackcheck) :
                SchApi.getSchedules(rec._id, callbackcheck)
        }
        if (props.viewonly) {
            if (!configStore.displaySchedules) configStore.displaySchedules = true
        }
        {
            if (currentMenu == "Create Bookingv3" || currentMenu == "Upload Schedulesv3") {
                if (contractsStore.current._id) {
                    let reqdata = {}
                    let shipmentType = configStore.homescreenCurrentShipmentType.toUpperCase()
    
                    if (configStore.homescreenCurrentShipmentType == 'LCL') {
                        let currentDate = new Date()
                        let cargoReadinessDate = (contractsStore.current.cargoReadinessDate && contractsStore.current.cargoReadinessDate.length >= 10) ?
                            new Date(contractsStore.current.cargoReadinessDate) : currentDate
                        if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                        if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                        let startDays = (contractsStore.current.portOfLoading === contractsStore.current.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
                        let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
                        let endDate = Utils.mmddyyyy(Utils.newDate(contractsStore.current.validUpto))
                        reqdata = {
                            shipmentType: shipmentType,
                            startDate: startDate,
                            endDate: endDate
                        }
                    }
                    if (configStore.homescreenCurrentShipmentType == 'FCL') {
                        reqdata = {
                            shipmentType: shipmentType
                        }
                    }
                    if (shipmentType == 'LCL' || shipmentType == 'FCL') {
                        Api.getSchedulesList(contractsStore.current._id, reqdata, (data) => {
                            if (data.length > 0) {
                                if (shipmentType == 'LCL' && (schedules.join('') != data.join(''))) {
                                    setSchedules(data)
                                }
                                if (shipmentType == 'FCL') {
                                    setFclSchedules(data)
                                }
                            }
                        })
                    }
                }
            }
        }
    }, [rec._id], e.stuffingType, e.destuffingType)
    //console.log('>>>>>>>>>>>', rec, props.viewonly)
    if (!rec._id && props.viewonly) return <></>
    let contractId = rec._id
    if (rec.mode === 'QUOTE') contractId = contractId.substr(0, contractId.length - 1)
    let title = `Create Booking - ${contractId}`
    if (!props.viewonly) title = 'Enquiries'
    let displayCargo4fcl = false


    {
        let e = enquiryStore.current
        // let stype = activeProduct.toLowerCase()
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        if (stype === 'fcl' && enquiryStore.current.cargoDetails[0].packageType) {
                displayCargo4fcl = true
        }
        // else if (stype === 'fcl' && (modalStore.checkingOrigin === true && modalStore.checkingDestination === false)) {
        //     if (e.stuffingType === 'Dock') {
        //         displayCargo4fcl = true
        //     }
        // }
        // else if (stype === 'fcl' && (modalStore.checkingOrigin === true && modalStore.checkingDestination === true)) {
        //     displayCargo4fcl = true
        //     if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory') {
        //         displayCargo4fcl = false
        //     }
        // }
        // console.log('#########>>>', modalStore.checkingOrigin, modalStore.checkingDestination, e.stuffingType, e.stuffingType, stype, displayCargo4fcl)
    }

    { //assign shipping type to enquiry
        let e = enquiryStore.current
        let stype = activeProduct.toLowerCase()
        if (stype === 'lcl') {
            e.shipmentType = 'LCL'
        } else if (stype === 'air') {
            // if (e.temperatureControlled === 'Yes')
            //     e.shipmentType = 'Air (Temperature Controlled)'
            // else
            //     e.shipmentType = 'Air (Normal)'
            e.shipmentType = 'Air'
        } else {
            // if (e.temperatureControlled === 'Yes') {
            //     e.shipmentType = 'FCL (Temperature Controlled)'
            // } else {
            //     e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
            // }
            e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
        }
        console.log('*** SHIPMNET TYPE ***', e.shipmentType)
    }
    const parent = "agi4"
    var parent_container
    var newHandle = function (e) {
        let parent_container1 = null
        if (parent) {
            parent_container = e.target;
            do {
                parent_container = parent_container?.parentNode;

                if (!parent_container || parent_container === document.body) {
                    break;
                }

                if (parent_container.id != parent) {
                    parent_container1 = null;
                } else {
                    parent_container1 = parent_container;
                }
            } while (parent_container1 == null);

        }
    };
    var newHandle1 = function (e) {
        if (parent && parent_container) {
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if (e.clipboardData.files.length > 0) {
                change(i[0].files)
            }
        }
    };
    if (parent) {
        document.getElementsByTagName('body')[0].onclick = function (e) { newHandle(e) }
        document.getElementsByTagName('body')[0].onpaste = function (e) { newHandle1(e) }

    }

    function change(files) {
        contractsStore.current.hasToShowDummySchedule = false;
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('action', 'schedule_upload')
        formData.append('shipmentType', rec.shipmentType)
        formData.append('id', rec._id)
        fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/common/convertxltojson`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                if (data.error) { if (data.error != 'No Error' || data.error != 'Invalid Token') { 
                    configStore.setModalMessage(data.error) } }
                else {
                    let d = []
                    for (let i = 0; i < data.length; i++) {
                        if(ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), data[i], contractsStore.current)){
                            d.push(data[i])
                        }
                    }
                    if(d.length>0){
                        contractsStore.current['quoteStatus'] = "uploaded"
                        setSaveEnabled(true)
                        (activeProduct === 'LCL') ? setSchedules(d) : setFclSchedules(d)
                    }else{
                        (activeProduct === 'LCL') ?
                        setSchedules(data) :
                        setFclSchedules(data)
                    }
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    console.log(activeProduct, 'activeProduct');
    console.log(displayCargo4fcl, 'displayCargo4fcl2');

    return (
        <>
        {
            show && <>

            <EnquiryConfirmationv3 />
            <div style={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}>
            <h5 className="title is-4" style={{ color: '#2c358a', margin: '0 0 15px 15px' , fontFamily: 'product_sans_bold' }}>
                {title}
            </h5>
            </div>
            {
                (!props.viewonly) ?
                    <Agproducttab productList={productList}
                        activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
                    : <></>
            }
            <hr style={{marginTop:"0px"}} />
            <div className="columns">
                <div className="column is-12 m-0 p-0">
                <div className="w-full flex flex-col gap-[8px]">
       <form noValidate  style={{width:"100%",display:"flex",flexDirection:"column"}}>
       <div className="flex flex-row gap-[10px]">
          
       <Route form={cargoForm} viewonly={props.viewonly=='1'} callback={updatecusref}/>
       <Details form={cargoForm} viewonly={props.viewonly=='1'}/> 
    
       </div>
       </form>
       </div>
                </div>
                
                
            </div>
            <hr />
            {/* <div className="columns">
                <div className="column is-4">
                    <HomescreenRoutev2 {...props} />
                </div>
                <div className="column is-8">
                    {
                        (activeProduct === 'FCL') ?
                            <HomescreenContainerv2 {...props} /> :
                            <HomescreenCargov2 {...props} isFromUploadSchedules={true} />
                    }
                </div>
            </div> */}
            {/* {
                (displayCargo4fcl) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <HomescreenCargo {...props}
                             displayCargo4fcl={true}
                              />
                        </div>
                    </div> : <></>
            } */}
            {/* {
                (displayCargo4fcl
                ) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <HomescreenCargov2 {...props}
                                displayCargo4fcl={true}
                            />
                        </div>
                    </div> : <></>
            } */}
            {
                (!loginStore.isClient() && ['lcl', 'fcl'].includes(activeProduct.toLowerCase())) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <BookingLocationsv3 />
                        </div>
                    </div> : <></>
            }
            <div className="columns">
                <div className="column is-12">
                    {
                        (props.viewonly && loginStore.isClient()) ?
                            <button style={{ float: 'left' }}
                                className="button is-rounded is-small islink" onClick={() => {
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc'].map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                }}>Terms and Conditions</button> :
                            <></>


                    }
                    {
                        (props.viewonly && rec.quoteStatus != 'ACCEPTED' && configStore.displaySchedules && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client) && (contractsStore.current['quoteStatus'] === "saved"  || contractsStore.current?.hasToShowDummySchedule || configStore.homescreenCurrentShipmentType === 'Air' || (schedules.length>0 || fclSchedules.length>0)) && !saveEnabled && rec.status == "SCHEDULES_UPLOADED") ?
                            <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-danger" onClick={() => {
                                    EntityV2Api.viewEntity(rec.entityId , (data)=> {
                            if(data.status === ENTITY_STATUS.DEACTIVATED || data.status === ENTITY_STATUS.PENDING){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Declining the quotation `)
                            }else {
                                configStore.setModalDecline(rec._id)
                            }  
                                 } )
                                  
                                }}>Decline</button> :
                            <></>


                    }
                    {
                        (props.viewonly && rec.quoteStatus != 'ACCEPTED' && configStore.displaySchedules && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client) && ((contractsStore.current['quoteStatus'] === "saved"  || contractsStore.current?.hasToShowDummySchedule) || (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current['quoteStatus'] === "FINAL") || (schedules.length>0 || fclSchedules.length>0)) && !saveEnabled && rec.status == "SCHEDULES_UPLOADED") ?
                            <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-success" onClick={() => {
                                    console.log('fvgf', rec)
                                    EntityV2Api.viewEntity(rec.entityId , (data)=> {
                                        if(data.status === ENTITY_STATUS.DEACTIVATED){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Accepting the quotation`)
                            }else {   
                                    Api.createContractFromQuote(rec._id)
                                    Api.getContracts()
                                    configStore.setModalMessage("Quotation is accepted.")
                                    contractsStore.current['quoteStatus'] = "ACCEPTED"

                            }
                                    }
                                    )
                                }}>Accept</button> :
                            <></>
                    }

                    {/* {
                        (!props.viewonly && displayMoreOptions && enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate && enquiriesValidationStoreSnap.enqRoute.origin && enquiriesValidationStoreSnap.enqRoute.destination) ?
                            <button style={{ float: 'right' }} onClick={() => {
                                NmO_onClick()
                            }}
                                className="button is-small is-rounded islink">
                                Need more Options
                            </button> :
                            <></>

                    } */}

                    {
                        (!props.viewonly) ?
                            <button
                                style={{ float: 'right' }}
                                className="button is-rounded is-small islink"
                                onClick={() => {
                                    // if (configStore.addressvalidation) {
                                    onClick();
                                    if (enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate && enquiriesValidationStoreSnap.enqRoute.origin && enquiriesValidationStoreSnap.enqRoute.destination) {
                                        NmO_onClick()
                                    }
                                    // } else {
                                    //     configStore.setModalMessage('Please check input fields');

                                    // };

                                }}
                            >
                                Submit
                            </button> :
                            <>
                                {/* {
                                    (!configStore.displaySchedules) ?
                                        <button style={{ float: 'right' }}
                                            className="button is-rounded is-small islink" onClick={() => {
                                                onClick()
                                            }}>Re-calulate</button> :
                                        <></>
                                } */}
                            </>
                    }

                </div>
            </div >
            {
                (loginStore.userRec.aguserRec.role.indexOf('CLIENT') < 0 && 
                    rec.status === "UPLOAD_SCHEDULES" &&
                    configStore.homescreenCurrentShipmentType !== 'Air'
                    && configStore.scheduleOrCarrierform === 'schedule') ?
                    <div className="columns" style={{ border: '2px solid blue' }} id="agi4">
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} id="agi4">
                            <label style={{ float: 'right' }} for="schedulefile">Load Schedules, verify and Save</label>
                            {(contractsStore.current['quoteStatus'] === "FINAL") ?
                            <input id="schedulefile" type="file" accept=".csv" onChange={(e1) => {
                                const pattern = /^[a-zA-Z0-9_ .()-]+$/;
                                if (pattern.test(e1.target.files[0]['name'])) {
                                    change(e1.target.files)
                                } else {
                                    contractsStore.current['quoteStatus'] = ""
                                    configStore.setModalMessage("Please Check File Name and Format")
                                    return e1.target.value = ""
                                }
                            }} />:<></>
                        }
                            {
                                (activeProduct === 'LCL' ?
                                    <><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./LCL_Template.csv" download="LCL_Template.csv">Download LCL Template</a></> :

                                    <><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./FCL_Template.csv" download="FCL_Template.csv">Download FCL Template</a></>)
                            }
                            {
                                ((activeProduct === 'LCL' ? schedules : fclSchedules)
                                    .every(schedule => !ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), schedule, contractsStore.current)) &&
                                    !contractsStore.current?.hasToShowDummySchedule &&
                                    contractsStore.current.validUpto >= Utils.getCurrentDateString() && (isUserAuthorized || isUserAuthorizedForSaveOnly || client)) && contractsStore.current['quoteStatus'] === "FINAL"
                                && <button className="button is-info is-small" onClick={() => {
                                    setSaveEnabled(true)
                                    contractsStore.current['quoteStatus'] = "dummy"
                                    setSchedules([]);
                                    setFclSchedules([]);
                                    contractsStore.current.hasToShowDummySchedule = true;
                                }}>Create Dummy</button>
                            }
                            {
                                (saveEnabled && (isUserAuthorized || client) && (contractsStore.current['quoteStatus'] === "dummy" || contractsStore.current['quoteStatus'] === "uploaded")) ?
                                    <button className="button is-danger is-small" onClick={async () => {
                                        loader.show();
                                        (activeProduct === 'LCL') ?
                                            SchApi.saveSchedules(rec._id, schedules) :
                                            SchApi.saveSchedules(rec._id, fclSchedules)
                                        await Newapi.releaseContractLock(rec._id)
                                        rec.status = "SCHEDULES_UPLOADED"
                                        // contractsStore.current['quoteStatus'] = "saved"
                                        setSaveEnabled(false)
                                        // configStore.homescreenCurrentView = 'list'
                                        configStore.scheduleOrCarrierform = 'schedule'
                                        loader.hide();
                                    }}>
                                        Save Schedules
                                    </button>
                                    : <></>
                            }
                        </div>
                    </div> : <></>
            }
            {
                (configStore.displaySchedules) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (() => {
                                    if (configStore.scheduleOrCarrierform === 'schedule') {
                                        if (contractsStore.current?.hasToShowDummySchedule && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <DummySchedulev3 stype={activeProduct} />;
                                        if (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <AirScheduleCompv3 />;
                                        else return <LCLSchedulesv3 data={activeProduct === 'LCL' ? schedules : fclSchedules} />;
                                    } else return <CarrierWebFormv3 />;
                                })()
                                // (configStore.scheduleOrCarrierform === 'schedule') ?
                                //     (configStore.homescreenCurrentShipmentType === 'Air') ?
                                //         <AirScheduleComp />
                                //         :
                                //         <LCLSchedules data={activeProduct === 'LCL' ? schedules : fclSchedules} />
                                //     :
                                //     <CarrierWebForm />
                            }
                        </div>
                    </div>
                    : <></>
            }
            {/* { loginStore.isClient() ? <Button  onClick={(e) => {
                                   configStore.homescreenCurrentView = 'list'
                                }} sx={{backgroundColor:"white",textTransform:"capitalize",alignSelf:"flex-end",marginRight:"5px",width:"70px",marginBottom:"20px",height:"40px","&:hover":{
                        backgroundColor:"white"
                       }}} >Cancel</Button>  : <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-danger" onClick={() => {
                                    AgmodalStore.pageVisible = false
                                }}>Cancel</button> 
                                } */}
         </>
        }
        </>

    )
}