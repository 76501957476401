import { Box, Typography } from "@mui/material";
import shipIcon from "../assets/images/shipicon.svg";
import airplane from "../assets/images/airplane.svg";

export function ShipmentScopeTag({ scope, iconPlacement = "end", width, fontWeight, fontSize }) {
    return <Box sx={{ display: "flex", flexDirection: iconPlacement === "start" ? "row-reverse" : "row", height: "100%", width: "100%", alignItems: "center", gap: 1 }}>
        <Typography sx={{ fontFamily: "Figtree", fontSize: fontSize ?? "14px", fontWeight: fontWeight ?? "bold" }}>{scope?.substring(0, 4)}</Typography>
        {/* <img style={{ width: width || "100%", height: "100%" }} src={scope?.toUpperCase() === "AIR" ? airplane : shipIcon} /> */}
    </Box>
}
