import { Box, Typography } from "@mui/material";
import pin from "../assets/images/pin.svg"

export function PinnedText({content,highlightedContent=""}){
    return <Box sx={{display:"flex",flexDirection:"row",alignItems:"center",gap:2}}>
     <img src={pin} />
     <Typography sx={{color:"black"}}>:</Typography>
     <Typography sx={{color:"#555555",fontFamily:"Figtree",fontSize:"18px"}}>{content}<span style={{color:"#2C358A",paddingLeft:"3px",fontWeight:"bold"}}>{highlightedContent}</span></Typography>
    </Box>
 }
 