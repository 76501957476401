import { useSnapshot } from "valtio"
import { routerStore } from "../../state/CommonState"
import EditCredit from "./edit-credit"
export default function MainCredit(fn, mode) {
  const store = useSnapshot(routerStore)
  switch (store.creditsCurrentView) {

    case "edit":
      return <EditCredit mode="edit" fn={fn} />
    case "new":
      return <EditCredit mode="new" fn={fn} />
    default:
      return <EditCredit mode="edit" fn={fn} />
  }
}

