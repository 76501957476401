import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
} from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import './ag-table.css'
import { loginStore } from '../../state/CommonState';
import { Nodata } from "../../mui-components/nodatafound";

const ReactTablePagination = ({
  dataLength,
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
  rows
}) => {
  // console.log(pageOptions)
  const arrayPageIndex = (pageIndex - 2) < 0
    ? pageOptions.slice(0, pageIndex + 3)
    : pageOptions.slice((pageIndex - 2), (pageIndex + 3));

  return (
    <>
    {(rows && rows.length > 0) ? (
      <div className="columns" style={{width:"100%"}}>
          <div className="column is-6">
            <Pagination className="pagination" style={{margin:0}} dir="ltr">
              <div className="pagination">
                <PaginationLink
                  className="pagination-link pagination-link--arrow"
                  type="button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <ChevronDoubleLeftIcon className="pagination-link-icon" />
                </PaginationLink>
                <PaginationLink
                  className="pagination-link pagination-link--arrow"
                  type="button"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  <ChevronLeftIcon className="pagination-link-icon" />
                </PaginationLink>
                {arrayPageIndex.map(i => (
                  <PaginationItem
                    className="pagination-item"
                    active={pageIndex === i && !loginStore.isClient()}
                    key={i}
                  >
                    <PaginationLink
                      key={i}
                      className="pagination-link"
                      type="button"
                      style={ pageIndex === i ? {backgroundColor: "#2C358A",color:"white"} : {}}
                      onClick={() => gotoPage(i)}
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem className="pagination-item">
                  <PaginationLink
                    className="pagination-link pagination-link--arrow"
                    type="button"
                    onClick={nextPage}
                    disabled={!canNextPage}
                  >
                    <ChevronRightIcon className="pagination-link-icon" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className="pagination-item">
                  <PaginationLink
                    className="pagination-link pagination-link--arrow"
                    type="button"
                    onClick={() => gotoPage(pageOptions.length - 1)}
                    disabled={!canNextPage}
                  >
                    <ChevronDoubleRightIcon className="pagination-link-icon" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className="pagination-item pagination-info">
                  Show {pageSize * pageIndex + 1} to {pageSize * pageIndex + page.length} of {dataLength}
                </PaginationItem>
                {manualPageSize.length > 1 && (
                  <PaginationItem className="pagination-item">
                    <FormGroup className="pagination-select-form ">
                      <Input
                        className="pagination-item pagination-info"
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={pageSize}
                        onChange={(event) => {
                          setPageSize(Number(event.target.value));
                        }}
                      >
                        {manualPageSize.map(item => (
                          <option className="pagination-item pagination-item-option" key={item} value={item}>
                            Show {item}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </PaginationItem>
                )}
              </div>
            </Pagination>
          </div>
          <div className="column is-6" style={{ textAlign: "right" }}>
              <div className="select">
                  <select
                      value={pageSize}
                      onChange={(e) => {
                          setPageSize(Number(e.target.value));
                      }}
                  >
                      {[20, 50, 100].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                          </option>
                      ))}
                  </select>
              </div>
          </div>

      </div>
    ) : <></>}
    </>

    
  );
};

ReactTablePagination.propTypes = {
  dataLength: PropTypes.number.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

ReactTablePagination.defaultProps = {
  manualPageSize: [ 5, 10,20, 50, 100],
};

export default ReactTablePagination;