import { useSnapshot } from "valtio"
import {
    quoteDataRec, airChargesStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { contractsStore } from "../state/CommonState"

export default function AirChargesClientView() {
    useSnapshot(contractsStore)
    const rec = contractsStore.current

    const Tn = ({ i, name }) => {
        const e = rec.chargesList[i]
        console.log(name, e.currency, e[name])
        //let num = (''+e[name]).replaceAll(',','')
        let num = e[name]
        num = isNaN(num) ? 0.0 : Number(num)
        return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
    }

    let counter = 1
    let finalTotal = 0.0
    rec.chargesList.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
    })
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='8'>
                                <h3 className="is-size-5 has-text-centered">Charges</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th colSpan='2' className="has-text-centered">Per Unit</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th style={{ width: '20rem' }}>Charge Description</th>
                            <th>Currency</th>
                            <th style={{ textAlign: 'center' }}>Per Kg</th>
                            <th style={{ textAlign: 'center' }}>Minimum</th>
                            <th style={{ textAlign: 'center' }}>Per AWB</th>
                            <th style={{ textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rec.chargesList.map((e, i) => (
                                <tr key={e.id}>
                                    <td>{e.chargeDescription}</td>
                                    <td>{e.currency}</td>
                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perKg' /></td>
                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='minimum' /></td>
                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perAWB' /></td>
                                    <td style={{ textAlign: 'right' }}>{Utils.N(e.total, e.currency)}</td>
                                </tr>
                            )
                            )
                        }
                        <tr>
                            <td colSpan={5}></td>
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(finalTotal)}</strong></td>
                        </tr>
                        <tr>
                            <td colSpan={6} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
