import { Box, Button, Checkbox, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import { AntSwitch } from '../mui-components/AntSwitch'
import logo from "../assets/images/Logo-new.svg"
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import PhoneNoField from '../mui-components/PhoneNoField'
import Carousel from '../mui-components/Carousel'
import { EntityV2Store, aguserStore, configStore, loginStore } from '../state/CommonState'
import {  Controller, useForm } from "react-hook-form";
import SelectField from '../mui-components/SelectField'
import Select from 'react-select'
import { City, Country, State } from "country-state-city";
import { CUSTOMER_CATEGORY, STAKEHOLDER_DEFAULT_VALUES } from '../util/constants'
import { useSnapshot } from 'valtio'
import EntityV2Api from '../state/entities-api'
import AguserApi from '../state/AguserApi'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { useMode } from '../context/AuthContext'
import { APP_VERSION } from '../util/constants'
import { deburr } from 'lodash'

function EntityCreationNew({setEntityData,callback}) {
  // const {setMode : callback} = useMode();
  useSnapshot(EntityV2Store);
  const Entity = EntityV2Store.EntityRec;
  const DefaultIndiaValue = {
    "name": "India",
    "isoCode": "IN",
    "flag": "🇮🇳",
    "phonecode": "91",
    "currency": "INR",
    "latitude": "20.00000000",
    "longitude": "77.00000000",
    "timezones": [
        {
            "zoneName": "Asia/Kolkata",
            "gmtOffset": 19800,
            "gmtOffsetName": "UTC+05:30",
            "abbreviation": "IST",
            "tzName": "Indian Standard Time"
        }
    ]
}
    const [value, setValueState] = useState({
      country:DefaultIndiaValue,
      city:"",
      state:""
    })
    const [Countries, setCountries] = useState([]);
    const [states, setstates] = useState([]);
    const [Category, setCategory] = useState([]);
    const [loading, setloading] = useState(false);
    const [branchesForTypesCB, setbranchesForTypesCB] = useState([]);
    const [branchesForTypesDT, setbranchesForTypesDT] = useState([]);

    useEffect(() => {
      filterCountries()
      StatesforBranch()
    }, [value])
useEffect(() => {
  console.log(value);
}, [value])

    useEffect(() => {
      checkuser()
      var list = [];
      Object.values(CUSTOMER_CATEGORY).forEach(function(element) {
        if(element !== CUSTOMER_CATEGORY.CONTRACT_MANUFACTURER)
        list.push({ label:element, value: element })
        });
       setCategory(list)
    }, [])

    const checkuser = async ()=>{
      let type
      const  EntityFound = localStorage.getItem("EntityFound")
      const  GSTFound = localStorage.getItem("gst")
      const  EntityCountry = localStorage.getItem("EntityCountry")
      
      if (EntityFound || GSTFound) {
        if (EntityCountry === "India") {
          Entity.panNum =  EntityFound
        }else {
          Entity.tinNum =  EntityFound
        }
        if (EntityFound) {
          type = "pan"
        }
        else if (GSTFound) {
          type = "gst"
          Entity.documents[2].pkey = GSTFound 
        }
       await  EntityV2Api.checkPage(type,setloading,async(d)=>{
         console.log(d);
         if (d?.EntityFound) {
           setEntityData(d)
           localStorage.setItem("EntityFound",d?.Identifier)
           localStorage.setItem("EntityCountry",d?.country)
           callback("userpopup")
         }
         if (d?.GSTFound) {
           setEntityData(d)
           localStorage.setItem("gst",d?.gst)
           callback("userpopup")
         }
         });
      }
    }
   
    useEffect(() => {
      getAllBranchesList()
    }, [])

    const getAllBranchesList = async () => {
      let cbFilter = {
        vertical: "Cross-Border",
        division: "all",
        // role:""
      };
      let dtFilter = {
        vertical: "Domestic Transport",
        division: "all",
      };
      AguserApi.getbranchesForTypes(cbFilter, setbranchesForTypesCB);
      AguserApi.getbranchesForTypes(dtFilter, setbranchesForTypesDT);
    }; 

    let sortedbranchesForCB = branchesForTypesCB.sort();
    let sortedbranchesForDT = branchesForTypesDT.sort();
    const filterCountries = () => {
      const array = Country.getAllCountries().filter(
        (x, i) => State.getStatesOfCountry(x.isoCode).length !== 0
      );
      setCountries(array);
    };
    const StatesforBranch = (code) => {
      const states = State.getStatesOfCountry(value?.country?.isoCode);
      setstates(states);
    };

    const EntityForm = useForm({
      defaultValues:{
          entityName:"",
          pan : "",
          pin:"",
          gst:"",
          // industry : "",
          category : "",
          city:"",
          state:"",
          country:"",
          tin:"",
          zip:"",
          address:""
      }
  })
    const {register,handleSubmit,clearErrors,setValue,setError,watch,formState:{errors},getValues,reset,control} = EntityForm;
    const watchAllFields = watch()
    console.log(errors,watchAllFields);
    const submit = async(data)=>{
      // callback('userpopup')
      console.log("The sign up data ",data);
      Entity.entityName =data.entityName
      Entity.customer.customerType = data.category.value
      Entity.entityCountry = data.country?.name
      Entity.entityCountryCode = data.country?.isoCode
      Entity.entityType = "Customer"
      Entity.status = "INCOMPELETE"
      Entity.createdBy = loginStore?.email
      Entity.scopeOfBusiness = ['Cross Border']
      // Entity.scopeOfBusiness = ['Cross Border','Domestic Transport']
      console.log("sortedbranchesForCB",sortedbranchesForCB);
      if (data.city) {
          sortedbranchesForCB.includes(data.city.name)?Entity.customer.crossBorder.agragaBranch = data.city.name : Entity.customer.crossBorder.agragaBranch = "Chennai"
          // sortedbranchesForDT.includes(data.city.name)?Entity.customer.domesticTransport.agragaBranch = data.city.name : Entity.customer.domesticTransport.agragaBranch = "Chennai"
        
      }else{
      Entity.customer.crossBorder.agragaBranch = "Chennai"
      // Entity.customer.domesticTransport.agragaBranch = "Chennai"
      }
      Entity.branch[0].status = "PENDING"
      
      Entity.branch[0].state = data.state ? deburr(data.state.name) +"/"+data.state.isoCode:""
      Entity.branch[0].branchAddresses[0].city = data.city ? data.city.name.replace(/[^\x00-\x7F]/g, ''):""

      if (data.country?.name === "India") {
        Entity.documents[1].pkey = data.pan
        Entity.panNum = data.pan
        Entity.documents[2].pkey = data.gst
        Entity.branch[0].gst = data.gst
        Entity.branch[0].branchAddresses[0].pincode = data.pin
      }
      else{
        Entity.branch[0].branchAddresses[0].zipcode = data.zip
        Entity.branch[0].branchAddresses[0].address = data.address.replace(/[^\x00-\x7F]/g, '')
        Entity.tinNum = data.tin
      }
      console.log(Entity);

  await  EntityV2Api.createEntityForCustomer(setloading,async(d)=>{
  console.log(d);
  if (d?.AlreadyThere) {
    localStorage.clear()
    configStore.setModalMessage("Entity Already Added For You Please Login And Explore")
    callback("login")
    return ""
  }
  if (d?.GSTFound) {
    console.log("hhhhhhhhhhh");
    localStorage.clear()
    setEntityData(d)
    localStorage.setItem("gst",d?.gst)
    // localStorage.setItem("EntityCountry",d?.country)
    localStorage.setItem('ag_email', d?.deviceData?.aguserRec?.email)
    loginStore.email = d?.deviceData?.aguserRec?.email;
    loginStore.isShowClientDashBoard = d?.deviceData?.aguserRec?.role
    loginStore.userRec.aguserRec = d?.deviceData
    const userRecString = JSON.stringify(d?.deviceData)
    localStorage.setItem("ag_remember_user",true);
    localStorage.setItem('ag_userrec', userRecString)
    localStorage.setItem('devicetoken', d?.deviceData?.aguserRec.token)
    localStorage.setItem('tour', d?.deviceData?.aguserRec.tour)
    localStorage.setItem('version', APP_VERSION )
    window.location.reload()
    callback("userpopup")
    return ""
  }
  if (d?.EntityFound) {
    localStorage.clear()
    setEntityData(d)
    localStorage.setItem("EntityFound",d?.Identifier)
    localStorage.setItem("EntityCountry",d?.country)
    localStorage.setItem('ag_email', d?.deviceData?.aguserRec?.email)
    loginStore.email = d?.deviceData?.aguserRec?.email;
    loginStore.isShowClientDashBoard = d?.deviceData?.aguserRec?.role
    loginStore.userRec.aguserRec = d?.deviceData
    const userRecString = JSON.stringify(d?.deviceData)
    localStorage.setItem("ag_remember_user",true);
    localStorage.setItem('ag_userrec', userRecString)
    localStorage.setItem('devicetoken', d?.deviceData?.aguserRec.token)
    localStorage.setItem('tour', d?.deviceData?.aguserRec.tour)
    localStorage.setItem('version', APP_VERSION )
    window.location.reload()
    callback("userpopup")
  }
  else {
    configStore.setModalMessage("Entity Created Successfully")
    console.log("d",d);
    localStorage.setItem('ag_email', d?.deviceData?.email)
    loginStore.email = d?.deviceData?.email;
    loginStore.isShowClientDashBoard = d?.deviceData?.aguserRec?.role
    loginStore.userRec.aguserRec = d?.deviceData
    const userRecString = JSON.stringify(d?.deviceData)
    localStorage.setItem("ag_remember_user",true);
    localStorage.setItem('ag_userrec', userRecString)
    localStorage.setItem('devicetoken', d?.deviceData?.aguserRec.token)
    localStorage.setItem('tour', d?.deviceData?.aguserRec.tour)
    localStorage.setItem('version', APP_VERSION )
    localStorage.setItem('ag_current_entity', JSON.stringify(d?.deviceData?.companyList[0]))
    localStorage.setItem('ag_current_branch', JSON.stringify(d?.deviceData?.companyList[0]?.branches[0]))
    configStore.currentSelectedEntity=d?.deviceData?.companyList[0]
    configStore.currentSelectedBranch=d?.deviceData?.companyList[0]?.branches[0]
    window.location.reload()
  }
  });
 
     
    }
    function trimStr(str) {
      if (!str) return str;
      return str.replace(/^\s+/g, "");
    }
    const handleChange = (type,e)=>{
      SetCursorPointerPosition(e)
      console.log("type:",type);
      console.log("event:",e);
      if (type === "city") {
        console.log("City",e);
      }
      console.log(e.target.value);
      if (type === "pin") {
        console.log(e.target.onkeydown);
        
        if (trimStr(e.target.value).length > 6) {
          return setValue("pin",e.target.value.slice(0,6));
        } 
        setValue("pin",e.target.value)
      }
      if (type === "zip") {
        if (trimStr(e.target.value).length > 10) {
          return setValue("zip",e.target.value.slice(0,10));
        } 
        setValue("zip",e.target.value)
      }
      if (type === "tin") {
        e.target.value = e.target?.value?.toUpperCase()
        setValue("tin",e.target.value)
      }
      if (type === "pan") {
        e.target.value = e.target?.value?.toUpperCase()
        setValue("pan",e.target.value)
      }
      if (type === "gst") {
        e.target.value = e.target?.value?.toUpperCase()
        setValue("gst",e.target.value)
      }

    }
    const SetCursorPointerPosition = (e) => {
      const Position = e.target.selectionStart;
      setTimeout(() => {
        e.target.setSelectionRange(Position, Position);
      }, 0);
    };

  return (
    <>
  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
      <div className='field_wrapper' style={{width:"80%"}}>
      <Typography style={{position:"relative"}} variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={".5rem"} gutterBottom>Entity Creation     <p style={{position:"absolute",right:0,cursor:"pointer"}}><span className='clr_blue' onClick={()=>{
            callback('login')
            localStorage.clear()
    }}>
      <HighlightOffIcon/>
      </span>
    </p></Typography>
      <Typography variant="h6" fontFamily={"Figtree, sans-serif"} fontSize={"12px"} fontWeight={400} color={"#555"} marginBottom={"1rem"} gutterBottom>Signed in as {loginStore.email}</Typography>
      <InputField 
      placeholder={"Enter Entity Name"}
      label={"Entity Name"}
      label_class={"label_styles"}
      mandatory={true}
      input_class={"inputBox_styles"}
      errorsmsg={errors?.entityName?.message}
      input_wrapeer={"input_wrapeer"}
      maxLength={30}
      children={{...register("entityName",{onChange:(e)=>handleChange("entityName",e),required: "Please enter your Entity Name.",
      // pattern:{
      //   value:/^[A-Z0-9a-z\s]+$/,
      //   message : `Please Enter Valid Entity Name`
      // }
      
    })}}
      callback={(e)=>console.log(e)}
      ></InputField>
      <div style={{display:"flex",gap:"1rem"}}>
      <div style={{width:"50%"}}>
          <div className={"input_wrapeer"}>
        <p className={"label_styles"} >
        Country*
                  </p>
        <div className={""} >
            <Controller
            name={"country"}
            control={control}
            rules={{ required: "Please select your Country" }}
            defaultValue='India'
            render={({ field,formState:{errors}  }) => (
              <>
              <Select
                {...field}
                className={`selectBox_styles ${errors?.country?"border_red":""}`}
                classNamePrefix="select"
                // isClearable={true}
                isSearchable={true}
                options={Countries}
                placeholder={getValues("country")}
                // defaultValue={{ label: "name", value: "India" }}
                getOptionLabel={(options) => options['name']}
                getOptionValue={(options) => options['flag']}
                value={getValues("country") || setValue("country",DefaultIndiaValue)}
                onChange={(e)=> setValueState(prevState => {
                  setValue("country",e)
                  setValue("state","")
                  setValue("city","")
                  setValue("pan","")
                  setValue("tin","")
                  setValue("address","")
                  setValue("gst","")
                  // setError("city", {
                  //   type: "manual",
                  //   message: "City is Required",
                  // })
                  // setError("state", {
                  //   type: "manual",
                  //   message: "State is Required",
                  // })
                  if (getValues("country")) {
                  clearErrors("country")
                  }
                  console.log(getValues("country"));
                  return {...prevState,...{country : e,state:"",city:""}};
                })}
              />
                  <p className="help is-input-danger-message">{errors?.country?errors?.country?.message:<span>&nbsp;</span>}</p> 
              </>
            )}
            
          />
        </div>
          </div>
      </div>
      <div style={{width:"50%"}}>
      <InputField 
      placeholder={value.country?.name==="India"?"Enter PAN":"Enter TIN"}
      label={value.country?.name==="India"?"PAN":"TIN"}
      label_class={"label_styles"}
      mandatory={true}
      errorsmsg={value.country?.name==="India"?errors?.pan?.message:errors?.tin?.message}
      input_wrapeer={"input_wrapeer"}
      input_class={"inputBox_styles"}
      type={"text"}
      maxLength={value.country?.name==="India"?10:20}
      children={{...register(value.country?.name==="India"?"pan":"tin",value.country?.name==="India"?{required: "Please enter your PAN Number.",onChange:(e)=>handleChange("pan",e),
      pattern:{
        value:/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        message : `Invalid PAN format. Eg: ABCDE1234F`
      }
    }:{required: "Please enter your TIN Number.",onChange:(e)=>handleChange("tin",e),
    pattern:{
      value:/^([A-Za-z0-9-_ ]{1,20})$/,
      message : `Invalid TIN Number Format`
    }
  })}}
      callback={(e)=>console.log(e)}
      ></InputField>
      </div>
      </div>
      <div style={{width:"100%"}}>
     <div className={"input_wrapeer"}>
    <p className={"label_styles"} >
    Entity Category*
              </p>
    <div className={""} >
        <Controller
        name={"category"}
        control={control}
        defaultValue='Enterprise'
        rules={{ required: "Category is Required" }}
        render={({ field,formState:{errors}  }) => (
          <>
          <Select
            {...field}
            className={`selectBox_styles ${errors?.category?"border_red":""}`}
            classNamePrefix="select"
            // isClearable={true}
            isSearchable={true}
            options={Category}
            placeholder={getValues("category")}
            getOptionLabel={(options) => options['label']}
            getOptionValue={(options) => options['value']}
            value={getValues("category") || setValue("category",{label:"Enterprise",value:"Enterprise"})}
            // value={getValues("state")}
            // onChange={(e)=> setValueState(prevState => {
            //   setValue("category",e)
            //   if (getValues("category")) {
            //     clearErrors("category")
            //     }
            //   return {...prevState,...{state : e,city:""}};
            // })}
          />
             <p className="help is-input-danger-message">{errors?.category?errors?.category?.message:<span>&nbsp;</span>}</p> 
          </>
        )}
        
      />
    </div>
    </div> 
      </div>   
      <div style={{display:"flex",gap:"1rem"}}>
       <div style={{width:"50%"}}>
       {value.country?.name==="India"? 
       <>
       <div style={{display:"flex"}}>
       <InputField 
       placeholder={"Enter GST Number"}
       label={"GST Number"}
       label_class={"label_styles"}
       mandatory={false}
       errorsmsg={errors.gst?.message}  
       input_wrapeer={"input_wrapeer"}
       input_class={"inputBox_styles"}
       type={"text"}
       maxLength={15}
       children={{...register("gst",{onChange:(e)=>handleChange("gst",e),
       pattern:{
         value:/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
         message : `Invalid GST format. Eg:29ABCDE1234F1ZK`
       }
     })}}
       callback={(e)=>console.log(e)}
       ></InputField>
       <div>
        
       {/* <button
                        className="button is-link"
                        style={{
                          border: "1px solid #ffffff",
                          borderRadius: "8px",
                          height: "45px",
                          color: "white",
                          // left: "14em",
                          position: "relative",
                          width: "100px",
                          marginLeft: "15%",
                          marginTop:"15%"
                        }}
                        //{...(isViewMode() ? { disabled: true } : {})}
                        onClick={async (event) => {
                          console.log("dfs",watchAllFields.gst)
                        //loader.show()
                        // await EntityApi.verifyGST({
                        //     gstin:watchAllFields.gst
                        //   }, (response) => {
                        //   console.log("D1",response)
                        //   if(response.results.message=="Success" && response.results.code=='200'){
                        //    // loader.hide()
                        //     console.log("Ds",response.results.response[0].responseStatus )
                        //     if(response.results.response[0].responseStatus == "ERROR"){
                        //       setGstVerify("Please enter a valid GST number")
                        //     }else{
                              
                             
                        //       if(response.results.response[0].response.gstnStatus=="Active"){
                        //         setGstVerify("GST number verified succesfully")
                        //         setGstData(response.results.response[0].response)
                        //         dtactionStore.modalVisible1 = true
                        //       } else{
                        //         setGstVerify("Please enter a valid GST number")
                        //       }

                        //     }
                        //   } else
                        //   {
                        //     console.log("Testing Gst..")
                        //     setGstVerify("Please enter a valid GST number")
                        //   }
                        //   // if (response?.data?.entityId !== Entity?.entityId) {
                        //   //   setGstAvailable(response);
                        //   // }
                        //    console.log(pendingErrors);
                        // });
              
                       
                          //routerStore.entityCurrentViewNew = "edit"
                          event.stopPropagation()
                        }}
                        //{...(isViewMode() ? { disabled: true } : {})}
                        >
                       Verify
                      </button> */}
       </div>
       </div>
       
       </>
      
      :
      <InputField 
      placeholder={"Enter Address"}
      label={"Address"}
      label_class={"label_styles"}
      mandatory={false}
      errorsmsg={errors.address?.message}  
      input_wrapeer={"input_wrapeer"}
      input_class={"inputBox_styles"}
      type={"text"}
      maxLength={30}
      children={{...register("address",{onChange:(e)=>handleChange("address",e)})}}
      callback={(e)=>console.log(e)}
      ></InputField>
    }
    {(watchAllFields.gst || watchAllFields.address) ? <>
          {(watchAllFields.country?.name?.toLowerCase() === STAKEHOLDER_DEFAULT_VALUES.COUNTRY ) ? <div className={"input_wrapeer"}>

    <p className={"label_styles"} >
    City
              </p>
    <div className={""} >
        <Controller
        name={"city"}
        control={control}
        // rules={{ required: "Please select your City" }}
        render={({ field,formState:{errors}  }) => (
          <>
          <Select
            {...field}
            className={`selectBox_styles ${errors?.city?"border_red":""}`}
            classNamePrefix="select"
            // isClearable={true}
            isSearchable={true}
            options={City?.getCitiesOfState(value?.state?.countryCode,value?.state?.isoCode)}
            getOptionLabel={(options) => options['name']}
            getOptionValue={(options) => options['name']}
            value={getValues("city")}
            onChange={(e)=> setValueState(prevState => {
              setValue("city",e)
              if (getValues("city")) {
                clearErrors("city")
                }
              return {...prevState,...{city : e}};
            })}
          />
        <p className="help is-input-danger-message">{errors?.city?errors?.city?.message:<span>&nbsp;</span>}</p> 
          </>
        )}
        
      />
    </div>
          </div> 
          : 
          <InputField 
      placeholder={"Enter City"}
      label={"City"}
      label_class={"label_styles"}
      mandatory={false}
      errorsmsg={errors.city?.message}  
      input_wrapeer={"input_wrapeer"}
      input_class={"inputBox_styles"}
      type={"text"}
      maxLength={15}
      children={{...register("city.name",{onChange:(e)=>handleChange("city",e)})}}
      callback={(e)=>console.log(e)}
      ></InputField>} </> : null }

        </div>
        <div style={{width:"50%"}}>

        {((watchAllFields.gst || watchAllFields.address))&&<><div className={"input_wrapeer"}>
    <p className={"label_styles"} >
    State
              </p>
    <div className={""} >
        <Controller
        name={"state"}
        control={control}
        // rules={{ required: "Please select your State" }}
        render={({ field,formState:{errors}  }) => (
          <>
          <Select
            {...field}
            className={`selectBox_styles ${errors?.state?"border_red":""}`}
            classNamePrefix="select"
            // isClearable={true}
            isSearchable={true}
            options={states}
            getOptionLabel={(options) => options['name']}
            getOptionValue={(options) => options['name']}
            value={getValues("state")}
            onChange={(e)=> setValueState(prevState => {
              setValue("state",e)
              if (getValues("state")) {
                clearErrors("state")
                }
              setValue("city","")
              // setError("city", {
              //   type: "manual",
              //   message: "City is Required",
              // })
              return {...prevState,...{state : e,city:""}};
            })}
          />
             <p className="help is-input-danger-message">{errors?.state?errors?.state?.message:<span>&nbsp;</span>}</p> 
          </>
        )}
        
      />
    </div>
    </div>
    <InputField 
      placeholder={value.country?.name==="India"?"Enter PIN Code":"Enter ZIP Code"}
      label={value.country?.name==="India"?"PIN Code":"ZIP Code"}
      label_class={"label_styles"}
      mandatory={false}
      errorsmsg={value.country?.name==="India"?errors?.pin?.message:errors?.zip?.message}
      input_wrapeer={"input_wrapeer"}
      input_class={"inputBox_styles"}
      type={"number"}
      maxLength={value.country?.name==="India"?6:10}
      onkeydown={(e) =>
        console.log("ASgchvjhtgjgj,hdfgjyhkjktyrdgfhyydhfyfhkytyghkgj",e.key)
        // ["e", "E", "+", "-", "."]?.includes(e?.key) 
        // e?.preventDefault()
      }
      children={{...register(value.country?.name==="India"?"pin":"zip",value.country?.name==="India"?{onChange:(e)=> handleChange("pin",e),
      pattern:{
        value:/^[0-9]{6}$/,
        message : `Invalid Pin Code`
      }
    }:{onChange:(e)=> handleChange("zip",e),
    pattern:{
      value:/^[0-9]{10}$/,
      message : `Invalid Zip Code`
    }
  })}}
      callback={(e)=>console.log(e)}
      ></InputField></>}
        </div>
      </div>
        <Button variant="contained" className='btn_color' type='submit'  fullWidth onClick={handleSubmit(submit)}
          >Get Started</Button>
      </div>

    </div>

  </div>
    
    </>
  )
}



export default EntityCreationNew