import { useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import AgFile from "../components/AgFile"
import { configStore } from "../state/CommonState"
import { myactionsstore } from "../my-actions/store-myactions"
import AgLastUpload from "../components/AgLastUpload"
import Api from "../state/Api"
import Utils from "../util/Utils"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"

function CurrencyTable(props) {
    let { currencyList, setVisible } = props
    return (
        <div className="box">
            <div className="columns">
                <div className="column is-10"><div className="title is-5">Currency</div></div>
                <div className="column is-2" style={{ textAlign: "right" }}>
                    <button className="button islink"
                        onClick={(e) => {
                            setVisible(false)
                        }}>
                        <span className="icon ">
                            <i className="fas fa-xmark"></i>
                        </span>
                    </button>
                </div>
            </div>
            <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                <thead>
                    <th>Currency</th>
                    <th>ExchangeRate</th>
                    <th>updateOn</th>
                    <th>updatedBy</th>
                </thead>
                <tbody>
                    {
                        currencyList.map(r => {
                            return <tr>
                                <td>{r.currency}</td>
                                <td>{r.exchangeRate}</td>
                                <td>{(r.updateOn) ? Utils.formatToDateTime(Number(r.updateOn)) : ""}</td>
                                <td>{(r.updatedBy) ? r.updatedBy : ""}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default function Others() {
    const store = useSnapshot(myactionsstore)
    const [data, setData] = useState({})
    const [checkstatus, setcheckstatus] = useState(false)
    const [update, setUpdate] = useState([])
    const [visible, setVisible] = useState(false)
    const currencyList = configStore.currencyList

    console.log(currencyList)

    function cheak() {
        setcheckstatus(true)
        Api.getlastupload("currency", setUpdate)
    }
    if (!checkstatus) {
        cheak()
    }

    let errors = data.validationErrors
    if (visible) return <CurrencyTable setVisible={setVisible} currencyList={currencyList} />
    return (
        <div>
            <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                <tbody>
                    <tr id="tf8">
                        <td style={{
                            width: 0,
                            minWidth: "fit-content"
                        }}>
                            <button className="button"
                                onClick={(e) => {
                                    setVisible(true)
                                }}>
                                <span className="icon ">
                                    <i className="fas fa-eye"></i>
                                </span>
                            </button>
                        </td>
                        <td>
                            <div className="title is-6">Currency</div>
                        </td>
                        {
                            RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                        <td >
                            <AgFile source='entity-upload' cat='direct' parent="tf8"
                                filetype='currency'
                                callback={(filename) => {
                                    configStore.setModalMessage('Currency upload successful.')
                                }} />
                        </td>
                        }
                        <td><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="currency.xlsx" download="currency.xlsx">Download currency Template</a></td>
                    </tr>
                    {
                        (update.length > 0) ? <tr>
                            <td colSpan={4}>
                                <AgLastUpload data={update[0]} />
                            </td>
                        </tr> : <></>
                    }
                </tbody>
            </table>
        </div>
    )
}