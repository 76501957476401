import { currencyList, chargesData } from "../StaticData"
import { TableEdit, TableSelect, TableLabel, AgInput, AgSelect } from "../components/AgComponents"
import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import {
    quoteDataRec, airChargesStore, cargoStore, configStore, entityStore, EntityV2Store
} from '../state/CommonState'

import Utils from "../util/Utils"
import { chargesValidationStore } from "../state/validation-stores"

export default function AirChargesCompv2() {
    const [grandTotal, setGrandTotal] = useState(0.0)
    const [shipTotal, setShipTotal] = useState(0.0)
    const [conTotal, setConTotal] = useState(0.0)
    const [onaccount, setonaccount] = useState(['On Your Account'])
    useSnapshot(quoteDataRec)
    console.log('ChargesComp rendering')
    const data = chargesData[quoteDataRec.shipmentType]
    let [space, setSpace] = useState('')

    useSnapshot(entityStore)
    let imChargesStore = useSnapshot(airChargesStore)
    let a = imChargesStore.newRec
    let b = imChargesStore.editRec

    useEffect(() => {
        let list = airChargesStore.list
        for (let i = 0; i < list.length; i++) {
            if (!list[i].onaccount || !list[i].onaccount.length) list[i].onaccount = 'On Your Account'
        }
    }, [])
    useEffect(() => {
        let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }
            if (cecisorigin && sscope.endsWith('port')) setonaccount(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) setonaccount(['On Your Account', `Shipper's Account`])
            else setonaccount(['On Your Account'])
            airChargesStore.list.map((e, i) => {
                if(sscope.length!=0 && country.length!=0 && por.length!=0 && pod.length!=0){
                    if(e.onaccount!=undefined){
                        if (cecisorigin && sscope.endsWith('port')){
                            if(['On Your Account', `Consignee's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else if (cecisdestination && sscope.startsWith('port')){
                            if(['On Your Account', `Shipper's Account`].indexOf(e.onaccount)==-1){
                                e.onaccount = "On Your Account"
                            }
                        }else{
                            e.onaccount = "On Your Account"
                        }
                    }
                }
                return e
            })
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)
    }, [
        quoteDataRec.entity,quoteDataRec.entityId, quoteDataRec.portOfLoading,
        quoteDataRec.portOfDischarge, quoteDataRec.shipmentScope
    ])
    const deleteRec = (id) => {
        if (id <= imChargesStore.initialLength) return
        const index = airChargesStore.list.findIndex(e => e.id === id)
        let records = airChargesStore.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        airChargesStore.list = records
        //console.log('AFTER DELETE=', JSON.stringify(airChargesStore.cargoList))
    }
    const Th2 = (props) => <th rowSpan="2" {...props} style={{ paddingTop: '1.5rem' }}>{props.children}</th>
    const Ts = ({ i, name, dataList }) => <TableSelect
        record={airChargesStore.list[i]} name={name} dataList={dataList} isValid={chargesValidationStore.isValidCharge(name, i)} />
    const Te = ({ i, name }) => {
        const e = airChargesStore.list[i]
        if (name === 'chargeDescription' && i < airChargesStore.initialLength)
            return <TableLabel record={airChargesStore.list[i]} name={name} />
        return (
            <TableEdit record={airChargesStore.list[i]} name={name} isValid={chargesValidationStore.isValidCharge(name, i)}/>
        )
    }
    const Tn = ({ i, name }) => {
        const e = airChargesStore.list[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <input disabled='true' style={{ backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEdit record={airChargesStore.list[i]} name={name} type='number' isValid={chargesValidationStore.isValidCharge(name, i)} />
        )
    }
    const Tsn = ({ name, dataList }) => <TableSelect
        record={airChargesStore.newRec} name={name} dataList={dataList} />
    const Ten = ({ name }) => <TableEdit record={airChargesStore.newRec} name={name} />
    const Tnn = ({ name }) => <TableEdit
        record={airChargesStore.newRec} name={name} type='numeric' />
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0

    let finalTotal = 0.0
    /*airChargesStore.list.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
    })*/
    const calculateFinalTotal = () => {
        let tot = 0
        let shiptot = 0
        let contot = 0
        airChargesStore.list.map((e) => {
            let erate = configStore.getExchangeRate(e.currency)
            if (!erate || erate === 0) erate = 1.0
            if (e.onaccount && e.onaccount.startsWith('Cons')) contot += e.total * erate
            else if (e.onaccount && e.onaccount.startsWith('Ship')) shiptot += e.total * erate
            else {
                tot += e.total * erate
            }
        })
        setGrandTotal(tot)
        setShipTotal(shiptot)
        setConTotal(contot)
        return finalTotal
    }

    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {/* <th colSpan='2'>
                                <button className="button is-link is-small is-rounded" onClick={() => {
                                    cargoStore.calculateCargoTotals()
                                    airChargesStore.calculateChargesTotals()
                                    calculateFinalTotal()
                                    setSpace(space === '' ? ' ' : '')
                                }}>Click to Re-Calculate Charges</button>
                            </th> */}
                            <th colSpan='10'>
                                <h3 className="is-size-5 has-text-centered">Charges{space}</h3>
                            </th>
                        </tr>
                        {ifNotFCL() ?
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colSpan='2' className="has-text-centered">Per Unit</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr> : <></>
                        }
                        <tr>
                            <th>ID</th>
                            <th style={{ width: '20rem' }}>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            <th>Per Kg</th>
                            <th>Minimum</th>
                            <th>Per AWB</th>
                            <th style={{ textAlign: 'right' }}>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            airChargesStore.list.map((e, i) => {
                                {
                                    return <tr key={e.id}>
                                        <td >{e.id}</td>
                                        <td><Te i={i} name='chargeDescription' /></td>
                                        <td><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                        <td><Ts i={i} name='currency' dataList={configStore.getCurrencyList()} /></td>
                                        <td><Tn i={i} name='perKg' /></td>
                                        <td><Tn i={i} name='minimum' /></td>
                                        <td><Tn i={i} name='perAWB' /></td>
                                        <td style={{ textAlign: 'right' }}>{
                                            (e.total > 0 && e.currency != '') ? Utils.N(e.total, e.currency) : ''
                                        }</td>
                                        <td>
                                            <div className="field has-addons">
                                                {
                                                    (i >= airChargesStore.initialLength) ?
                                                        <p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    i === airChargesStore.list.length - 1 &&
                                                    (
                                                        <p className="control">
                                                            <button className="button is-success is-inverted"
                                                                onClick={() => {
                                                                    let obj = airChargesStore.newRec
                                                                    obj = { ...obj }
                                                                    airChargesStore.list.push({ ...obj, id: airChargesStore.list.length + 1 })
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon is-small">
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'right' }}>Total of On Your Account : </td>
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal)}</strong></td>
                            <td rowSpan={(conTotal && shipTotal)?4:(conTotal || shipTotal)?3:2} style={{ textAlign: 'center' }}>
                            <button className="button is-info is-inverted"
                                                        
                                                        onClick={() => {
                                                            cargoStore.calculateCargoTotals()
                                    airChargesStore.calculateChargesTotals()
                                    calculateFinalTotal()
                                    setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-calculator"></i>
                                                        </span>
                                                    </button>
                            </td>
                        </tr>
                        {
                            shipTotal > 0 &&
                            <tr>
                                <td colSpan={7} style={{ textAlign: 'right' }}>Total of On Shipper's Account : </td>
                                <td  style={{ textAlign: 'right' }}><strong>{Utils.N(shipTotal)}</strong></td>
                            </tr>
                        }
                        {
                            conTotal > 0 &&
                            <tr>
                                <td colSpan={7} style={{ textAlign: 'right' }}>Total of On Consignee's Account : </td>
                                <td  style={{ textAlign: 'right' }}><strong>{Utils.N(conTotal)}</strong></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
