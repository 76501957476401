import { useState } from "react";
import Api from "../state/Api";
import trackMyBookingOceanStore from "./TrackMyBooking-store";

export function DtUpdatePhoneNumber({ isOpen, onClose, data }) {
    const checkdrivernumstatus = async () => {
        if (trackMyBookingOceanStore.driverPhoneNumber.length === 10) {
            const data = await Api.checkDriverStatus(trackMyBookingOceanStore.driverPhoneNumber)
            trackMyBookingOceanStore.phoneNumberStatus = data.data
        } else {
            trackMyBookingOceanStore.phoneNumberStatus = 'Please Check Driver Number'
        }
    }
    const close = () => {
        onClose()
    }
    const Update = async () => {
        if (trackMyBookingOceanStore.driverPhoneNumber !== data.driverPhoneNumber) {
            await Api.updatePhoneNumber(data._id, trackMyBookingOceanStore.driverPhoneNumber, data.tripId);
            trackMyBookingOceanStore.setTrackBooking();
            onClose();
        }

    }
    let modalClass = 'modal'
    if (isOpen) modalClass += ' is-active'
    return (
        <>
            {
                (isOpen) ? <div className={modalClass} onClick={onClose}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: '30%' }} >
                        <section className="modal-card-body" >
                            <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Update Driver Number</h1>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                                    <label class='label' style={{flex: '0 0 150px', textAlign: 'center'}}>Driver Number :</label>
                                    <input style={{ width: '50%' }} class="input" type="number" placeholder="Enter Phone Number" onChange={(e) => {
                                        trackMyBookingOceanStore.driverPhoneNumber = e.target.value
                                    }} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px', marginTop: '15px' }}>
                                    <button class="button is-danger is-responsive" onClick={() => {
                                        trackMyBookingOceanStore.setTrackBooking();
                                        onClose();
                                    }} >Cancel</button>
                                    {
                                        trackMyBookingOceanStore.phoneNumberStatus != "ALLOWED" ?
                                            <button class="button is-warning is-responsive" onClick={checkdrivernumstatus}>Check Status</button> :
                                            <button class="button is-success is-responsive" onClick={Update}>Update</button>
                                    }

                                </div>
                            </div>
                            {/* <div class="columns m-1 is-vcentered is-multiline">
                                <div class="column is-12 pb-0 mb-0">
                                    <b><h3 className="title is-5">Update Driver Number</h3></b>
                                </div>
                                <div class="column is-3">
                                    Driver Phone:
                                </div>
                                <div class="column is-3">
                                    <input type="number" onChange={(e) => {
                                        trackMyBookingOceanStore.driverPhoneNumber = e.target.value
                                    }} />
                                </div>
                                <div class="column is-6">
                                    <p>{trackMyBookingOceanStore.phoneNumberStatus}</p>
                                </div>
                            </div>
                            <button class="button is-danger" onClick={() => {
                                trackMyBookingOceanStore.setTrackBooking();
                                onClose();
                            }} >Cancel</button>
                            {
                                trackMyBookingOceanStore.phoneNumberStatus != "ALLOWED" ?
                                    <button class="button is-warning" style={{ float: "right" }} onClick={checkdrivernumstatus}>Check Status</button> :
                                    <button class="button is-danger" style={{ float: "right" }} onClick={Update}>Update</button>
                            } */}

                        </section>
                    </div>
                </div> : <></>
            }
        </>
    )
}