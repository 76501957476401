import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSnapshot } from "valtio";
import Agproducttab from "../components/Agproducttab";
import { configStore } from "../state/CommonState";
import ChartApi from "./chart.api";
import chartStore from "./chart.store";

export function ChartOthers() {
    useSnapshot(configStore)
    useSnapshot(chartStore)
    let activeProduct = chartStore.activeProduct
    const setActiveProduct = (p) => chartStore.activeProduct = p
    //const [activeProduct, setActiveProduct] = useState('LCL')
    let productList = ['LCL', 'FCL', 'Air']
    const [data, setData] = useState({})
    const [prodData, setProdData] = useState([['Month', '# Bookings', 'CBM']])
    const [pieData, setPieData] = useState([['Country', 'Vol']])
    useEffect(() => {
        ChartApi.getProductData(setData)
        //setActiveProduct('LCL')
    }, [configStore.currentSelectedEntity, configStore.chartSelectedEntity])
    useEffect(() => {
        let prod = activeProduct.toLowerCase()
        if (data['month']) setProdData(data['month'][prod])
        if (data['country']) setPieData(data['country'][prod])
    }, [data, activeProduct])
    const getTitle = () => {
        if (activeProduct === 'LCL') return 'CBM'
        else if (activeProduct === 'FCL') return 'TEU'
        else return 'Chargeable Weight'
    }
    const getPieTitle = () => {
        if (activeProduct === 'LCL') return 'in Cubic Meters (CBM)'
        else if (activeProduct === 'FCL') return 'in TEUs'
        else return 'in Chargeable Weight (Kgs)'
    }
    const options = {
        vAxes: {
            0: { minValue: 0, title: getTitle(), textStyle: { color: 'black' } },
            1: { minValue: 0, title: '# Bookings', textStyle: { color: 'black' }, gridlines: { count: 3 } },
        },
        hAxis: { title: 'Month' },
        seriesType: 'bars',
        colors: ['#ee6622', '#ccccff'],
        pointSize: 5,
        pointShape: 'square',
        series: {
            0: { type: 'line', targetAxisIndex: 1 },
            1: { type: 'bar', targetAxisIndex: 0 },
        }
    };
    const pieOptions = {
        title: getPieTitle(),
        pieSliceText: 'value',
        legend: {
            position: 'labeled'
        }
    };
    let colWidth = 'column is-6'
    let chartHeight = {}
    if (chartStore.comboChartFull || chartStore.pieChartFull) {
        colWidth = 'column is-12'
        chartHeight = { height: '80vh' }
    }
    return (
        <>
            {
                ((chartStore.comboChartFull || chartStore.pieChartFull)) ?
                    <button style={{ float: 'right' }} className="button islink"
                        onClick={(e) => {
                            chartStore.comboChartFull = false
                            chartStore.pieChartFull = false
                        }}>
                        <span className="icon ">
                            <i className="fas fa-xmark"></i>
                        </span>
                    </button>
                    :
                    <></>
            }
            <div style={{ width: 'max-content', marginLeft: '6rem' }}>
                <Agproducttab productList={productList}
                    activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
            </div>
            <div className="columns" onClick={(e) => {

            }}>
                {
                    (!chartStore.pieChartFull) ?
                        <div className={colWidth} style={chartHeight}
                            onClick={(e) => chartStore.comboChartFull = true}>
                            <Chart
                                chartType="ComboChart"
                                width="100%"
                                height="100%"
                                data={prodData}
                                options={options}
                            />
                        </div>
                        :
                        <></>
                }
                {
                    (!chartStore.comboChartFull) ?
                        <div className={colWidth}
                            onClick={(e) => chartStore.pieChartFull = true}
                            style={{ justifyContent: 'center', alignContent: 'center', ...chartHeight }}>
                            <Chart
                                chartType="PieChart"
                                data={pieData}
                                options={pieOptions}
                                width="100%"
                                height="100%"
                            />
                        </div>
                        :
                        <></>
                }
            </div>

        </>
    )
}
