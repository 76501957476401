import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { QueryClient, QueryClientProvider } from "react-query";

import {
  BranchColumn,
  BranchColumn1,
  BranchColumn2,
} from "../../components/ag-table/columns";
import ReactTablePagination from "../../components/ag-table/ReactTablePagination";

import TableFilter from "../../components/ag-table/TableFilter";
import { Sorting } from "../../components/ag-table/ag-sorting";
import EntityApi from "../../state/entity-api";
import { BRANCH_STATUS,PERMISSIONS } from "../../util/constants";
import {
  reducer,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
  PAYLOAD_CHANGED,
} from "../../components/ag-table/ag-reducer";

import ApiAgTable from "../../components/ag-table/api_ag-table";

import {
  branchStore,
  configStore,
  customerStore,
  entityStore,
  EntityV2Store,
  loginStore,
  routerStore,
} from "../../state/CommonState";

import { useSnapshot } from "valtio";
import { loader } from "../../util/loader";

import Utils from "../../util/Utils";
import { BranchType } from "../../state/Types";
import BranchApi from "../../state/branch-api";
import EntityUtils from "../../util/entityUtils";
import TableHead from "../../util/dataTable";
import RoleUtils from "../../util/RoleUtils";

const queryClient = new QueryClient();

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: 0,
  queryPageFilter: "",
  queryPageSortBy: [],
  queryPayload: {},
};

const DataTable = (fn) => {
  useSnapshot(customerStore);
  useSnapshot(configStore);
  useSnapshot(loader);
  useSnapshot(entityStore);
  useSnapshot(branchStore);
  const Branch = branchStore.BranchRec;
  const Entity = EntityV2Store.EntityRec;
  const [customerlist, setCustomerlist] = useState([]);

  const [selectedEntityType, SetSelectedEntityType] = useState(`ALL`);
  const [selectedStatus, SetSelectedStatus] = useState(`ALL`);

  const [ch, setCh] = useState(0);

  const changech = (data) => {
    if (ch == 0) {
      setCh(1);
    } else {
      setCh(0);
    }
  };

  // let column  = BranchColumn
  let column;
  if (EntityUtils.isIndia(Entity)) {
    column = BranchColumn1;
  } else if (!EntityUtils.isIndia(Entity)) {
    column = BranchColumn2;
  }

  const [keyword, setKeyword] = useState("");
  const [data1, setData1] = useState({ results: [] });
  const onClickFilterCallback = (filter) => {
    setKeyword(filter);
  };
  let columns = column;
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);

  useEffect(() => {
    ApiAgTable.branchData(
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryPayload,
      EntityV2Store.EntityRec._id
    ).then((res) => {
      res
        .clone()
        .json()
        .then(
          (res) => {
            if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
              if (JSON.stringify(data1) != JSON.stringify(res)) {
                setData1(res);
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }, [
    queryPayload,
    queryPageIndex,
    queryPageSize,
    queryPageFilter,
    queryPageSortBy,
  ]);

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data1?.results || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data1 ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  const manualPageSize = [];

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
    gotoPage(0);
  }, [keyword, gotoPage]);

  React.useEffect(() => {
    if (data1?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data1.count,
      });
    }
  }, [data1?.count]);

  useEffect(() => {
    dispatch({
      type: PAYLOAD_CHANGED,
      payload: {
        status: selectedEntityType,
        gst: selectedStatus,
        date: Utils.getCurrentDateString(),
      },
    });
    gotoPage(0);
  }, [selectedEntityType, selectedStatus, ch]);
  
  
  const isRoleAuth = ((EntityUtils.isCustomer(Entity) && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_BRANCH_CUSTOMER)) ||
  (EntityUtils.isVendor(Entity) && 
      (EntityUtils.isRoadTransport(Entity) ?
          (RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_BRANCH_VENDOR_RT_ONLY) || RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_BRANCH_VENDOR)) :
          RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_BRANCH_VENDOR))))

  return (
    <>
      <span className="is-flex">
        <div
          class="back-div"
          onClick={() => {
            fn.fn.fn.fn(false);
          }}>
          <img src="./assets/Back.png" alt="Back" class="back" width="32px" />
        </div>

        <h1 className="title" style={{ marginLeft: "2%" }}>
          Branch List
        </h1>
      </span>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">{data1?.count} branches</p>
          </div>
          {EntityUtils.isIndia(Entity) && isRoleAuth ? <p className="level-item">
            <a
              href="/#"
              className="button is-link"
              onClick={() => {
                branchStore.BranchRec.set(new BranchType());
                routerStore.branchCurrentView = "new";
              }}>
              New Branch
            </a>
          </p>:null}

          <div class="level-item">
            <TableFilter
              onClickFilterCallback={onClickFilterCallback}
              defaultKeyword={keyword}
            />
          </div>
        </div>
      </nav>

      <div className="box">
        <div className="table-container" style={{ overflow: "initial" }}>
          <table className="table is-fullwidth is-small ">
          <TableHead headerGroups={headerGroups} sort={true}/>
            <tbody
              className="contractlist"
              style={{ fontSize: "0.9rem" }}
              {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        let e = cell["row"]["original"];

                        switch (cell["column"]["Header"]) {
                          case "GST Number":
                          case "UID Number":
                            return (
                              <td>
                                {cell["column"]["Header"] === "GST Number" ? (
                                  <a
                                    target="_blank"
                                    onClick={(event) => {
                                      console.log("event",event)
                                      BranchApi.viewBranch(e._id);
                                      routerStore.branchCurrentView = "view";
                                      event.stopPropagation();
                                    }}>
                                    {
                                      e.gst
                                      //  (Entity ) => EntityUtils.isIndia(Entity) ?  ({e.gst}) : ({e.uidRefNo})
                                    }
                                  </a>
                                ) : (
                                  <a
                                    target="_blank"
                                    onClick={(event) => {
                                      BranchApi.viewBranch(e._id);
                                      routerStore.branchCurrentView = "view";
                                      event.stopPropagation();
                                    }}>
                                    {
                                      e.uidRefNo
                                      //  (Entity ) => EntityUtils.isIndia(Entity) ?  ({e.gst}) : ({e.uidRefNo})
                                    }
                                  </a>
                                )}
                              </td>
                            );

                          // case "UID Number":
                          //   return (
                          //    <td>
                          //       <a
                          //         target="_blank"
                          //         onClick={(event) => {
                          //           BranchApi.viewBranch(e._id);
                          //           routerStore.branchCurrentView = "view";
                          //           event.stopPropagation();
                          //         }}>
                          //         {e.uidRefNo}
                          //       </a>
                          //     </td>
                          //   );
                          case "Actions":
                            return (
                              <td>
                                <div
                                  class="dropdown post-options is-right is-hoverable "
                                  onClick={null}>
                                  <div class="dropdown-trigger">
                                    <button
                                      class="button"
                                      aria-haspopup="true"
                                      aria-controls="dropdown-menu-post">
                                      <span class="icon is-small">
                                        <i
                                          class="fas fa-ellipsis-h"
                                          aria-hidden="true"></i>
                                      </span>
                                    </button>
                                  </div>
                                  {(e.status !== "PENDING" && isRoleAuth) && <div
                                    class="dropdown-menu"
                                    id="dropdown-menu-post"
                                    role="menu">
                                    <div class="dropdown-content">
                                      <a
                                        href="/#"
                                        class="dropdown-item"
                                        onClick={() => {
                                          console.log(e);
                                          BranchApi.viewBranch(e._id);
                                          routerStore.branchCurrentView =
                                            "edit";
                                        }}>
                                        Modify
                                      </a>
                                      {/* <a
                                        class="dropdown-item"
                                        // onClick={handleDeleteEntity(e)}
                                      >
                                        Delete
                                      </a>   */}
                                    </div>
                                  </div>}
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td {...cell.getCellProps()}>
                                <span>{cell.render("Cell")}</span>
                              </td>
                            );
                        }
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {rows.length > 0 && (
            <>
              <div className="columns" style={{ width: "100%" }}>
                <div className="column is-12">
                  <ReactTablePagination
                    page={page}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                    manualPageSize={manualPageSize}
                    dataLength={totalCount}
                    rows={rows}
                  />
                </div>
                {/* <div className="column is-6" style={{ textAlign: "right" }}>
                  <div className="select">
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}>
                      {[20, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const BranchesList = (fn) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable fn={fn} />
    </QueryClientProvider>
  );
};

export default BranchesList;
