import { ContentCopyRounded } from "@mui/icons-material";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import { useState } from "react";

export function CopyText({text,onClick}){
    const [open,setOpen] = useState(false)
    return <Box sx={{display:"inline-flex",flexDirection:"row",alignItems:"center",gap:1}}>
        <Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold"}}>{text}</Typography>
        <IconButton sx={{padding:0,color:"black"}} onClick={(e)=>{
            e.stopPropagation();
            navigator.clipboard.writeText(text).then((val)=>{
               setOpen(true);
            }).catch((error)=>{
                console.log("the error : ",error)
            });
            if(onClick){
                onClick();
            }
        }}>
            <ContentCopyRounded/>
        </IconButton>
      { open &&  <Snackbar
      anchorOrigin={{vertical:"bottom",horizontal:"center"}}
  open={open}
  autoHideDuration={3000}
  onClose={()=>{
    setOpen(false);
  }}
  message="Copied to clipboard"
/>}
    </Box>
}