import { createContext, useContext, useEffect, useState } from "react";
import EntityApi from "../state/entity-api";
import { bookingsStore, breadCrumbStore, contractsStore, loginStore } from "../state/CommonState";
import EntityV2Api from "../state/entities-api";
import { useForm } from "react-hook-form";
import Utils from "../util/Utils";
import { enquiryStore } from "../enquiry/enquiry-data";

const ShippingContext = createContext();

export const useShipping = () => useContext(ShippingContext);

export const ShippingContextProvider = ({children})=>{
    const brec = bookingsStore.current;
    const [isLoading,setIsloading] = useState(false);
    const aguser = loginStore.userRec.aguserRec;
    const {selectedSchedule,contract} = breadCrumbStore.state;
    const [canMoveToNextStep,setMoveToNextStep] = useState(true)
    const [bookingState,setBookingState] = useState({isExport:false,isImport:false,entityState:{}})
    useEffect(() => {
        async function setbookingdata () {
            setIsloading(true);
        EntityApi.getList()
        
        if (loginStore.isClient()) {
        brec.firstName = aguser.firstName
        brec.lastName = aguser.lastName
        brec.phone = aguser.phone
        brec.emailid = loginStore.email
        }else{
            brec.emailid = ""
        }
        brec.incoTerms = contractsStore.current.incoTerms
        brec.commodityType = contractsStore.current.commodityType
        brec.carrierMBL = 'No'
       
        let entityId = contract.entityId
        brec.entityId = entityId
        let entity = await EntityV2Api.viewEntity(entityId, (val)=>{
            // setentityData(val);
            setBookingState((prev)=>({...prev,entityState:val}))
        })
        console.log("entity" , entity);

        if (!entity) {
            // setCompanyName(brec.contract.gst);
            return;
        }
        let fromLoc = brec.contract.shipmentType?.toLowerCase().includes('air') ?
            brec.contract.originAirport
            : brec.contract.portOfReceipt
        // let fpodCountry = brec.contract.finalPlaceOfDelivery.substring(brec.contract.portOfReceipt.indexOf(',')+1,brec.contract.portOfReceipt.indexOf("(")).trim();
        console.log("fromLoc" , fromLoc)
        // if (fromLoc.toLowerCase().includes(entity.entityCountry.toLowerCase())) {
            // setHasToAutoPopulateEntityDetail(true);
            // brec.company = brec.contract.gst
            brec.company = entity.entityName
            // setCompanyName(entity.entityName)
            // brec.branchId=entity.branch[0].branchId
            // brec.address = entity.branch[0].branchAddresses[0].address
            brec.country = entity.branch[0].country
            let exportOrImport = Utils.getBookingsImportAndExportStatus({booking:bookingsStore.current});
            console.log("the export import . .. .  . . . .      ...  . .   ",exportOrImport)
            setBookingState((prev)=>({...prev,...exportOrImport}));
            setIsloading(false);
            // brec.pin = entity.branch[0].branchAddresses[0].pincode
            // setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
        // }
        }
        
    setbookingdata()
    }, [bookingsStore.current])
    const form = useForm({
        defaultValues : {
            shipperDetails :{ branchId:  "",
            firstName: "",
            lastName: "",
            phone: "",
            emailid: "",
            company:"",
            address: "",
            pin: "",
            country: "",} ,
        consigneeDetails:{
            _id:"",
            companyName: "",
            address: "",
            city: "",
            country: "",
            pincode: "",
            state: "",
            firstName: "",
            lastName: "",
            emailId: "",
            mobileNumber: "",
            linkedEntity:"",
            newShipper : false
        },
        invoiceDetails : {
            branchId : "",
            address : "",
            pin : "",
            country : ""
        },
        merchantID:"",
        fbaID:"",
        purchaseOrderID:"",
            incoterms :"",
            commoditytype : "",
            specialinstructions:"",
        }
    })
    return <ShippingContext.Provider value={{form,isLoading,bookingState,canMoveToNextStep,setMoveToNextStep}}>
        {children}
    </ShippingContext.Provider>
}