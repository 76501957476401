import { useSnapshot } from "valtio";
import { markupsStore, rtpStore } from "./pricing-store";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import PricingMarkupTable1 from "./pricing-markup-table1";
import PricingCountrymarkup from "./pricing-countrymarkup";
import PricingPortsmarkup from "./pricing-portsmarkup";
import MonApi from "../state/mongo-api";
import { useEffect } from "react";

export default function FBAMarkupEdit(props) {
    useSnapshot(rtpStore)
    useSnapshot(markupsStore)
    useEffect(() => {
        const f = async () => {
            console.log('\n\n\nAbout to get for key=', rtpStore.selectedMarkupCharge.chargeKey)
            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fbapricing/markup/fba`
            let ret = await MonApi.aget(url)
            markupsStore.current = ret
            if (!markupsStore.current) markupsStore.current = {
                fbatrident: 0
            }
            console.log('GOT RET AS=', ret, '\n==================\n\n')

        }
        f()
    }, [])
    let rec = rtpStore.selectedMarkupCharge

    return (
        <>
            <section class="section section-padding" >
                <Cols>
                    <Col2 style={{ color: 'darkblue' }}>Trident</Col2>
                    <Col5>
                        <input type="number" className="input is-small"
                            value={markupsStore.current.fbatrident}
                            style={{ width: '5rem' }}
                            onChange={(ev) => {
                                markupsStore.current.fbatrident = ev.target.value
                            }} />
                    </Col5>
                </Cols>
            </section>
            <section>
                <Cols>
                    <Col5></Col5>
                    <Col1>
                        <button className="button is-danger"
                            onClick={() => {
                                rtpStore.markupView = 'list'
                            }}
                        >Cancel</button>
                    </Col1>
                    <Col2>
                        <button className="button islink"
                            onClick={() => {
                                const f = async () => {
                                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fbapricing/markup/fba`
                                    markupsStore.current._id = 'fba'
                                    let ret = await MonApi.apost(url, markupsStore.current)
                                    rtpStore.markupView = 'list'
                                }
                                f()
                            }}
                        >Save</button>
                    </Col2>
                </Cols>
            </section>
        </>
    )
}