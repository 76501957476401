import { configStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import DtListQuote from "./DtListQuote"
import DtQuote from "./DtQuote"
import DtQuoteView from "./DtQuoteView"
import DtMail from "./DtMail"
import DtBooking from "./DtBooking"

export default function DtQuoteMain() {
    const a = useSnapshot(configStore)
    switch(a.dtCurrentView) {
        case 'list': return <DtListQuote />
        case 'pureView': return <DtQuoteView />
        case 'view': return <DtMail />
        case 'edit': return <DtQuote />
        case 'Booking': return <DtBooking />
    }
}