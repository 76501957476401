import { routerStore } from "../../state/CommonState";
import { useSnapshot } from "valtio";
import ListEntities from "./list-entities-v2";
import EditEntity from "./edit-entity-v2";

export default function MainEntity(fn , mode ) {
  const store = useSnapshot(routerStore);
  console.log(store.entityCurrentViewNew);
  if (fn.fn) {
    return <EditEntity mode={fn.type} fn={fn.fn}/>;
  }
  switch (store.entityCurrentViewNew) {
    case "list":
      return <ListEntities />;
    case "view":
      return <EditEntity mode="view" />;
    case "edit":
      return <EditEntity mode="edit" />;
    case "new":
      return <EditEntity mode="new" />;
    // case check:
    //   return <EditEntity mode="edit" />;
    default:
      return <ListEntities />;
  }
}
