import { useSnapshot } from "valtio"
import { visibilityStore } from "./visibilityStore"
import { loader } from "../util/loader"
import { useEffect, useState } from "react"
import { configStore, loginStore } from "../state/CommonState"
import Api from "../state/Api"
import ShipmentGraph from "../my-actions/shipment-graph"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"
import AgLastUpload, { AgLastUpload1, AgLastUpload2 } from "./AgLastUpload"
import MyactionUtils from "../my-actions/Myactionutils"
import { Popup } from "./popup"
import trackMyBookingOceanStore from "./TrackMyBooking-store"
import { Button } from "@mui/material"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import Taskfiletable from "./taskFileTable"


export function AirVisibity(props) {
    useSnapshot(visibilityStore)
    useSnapshot(loader)
    let e1 = props.dsrRec
    let nodes = props.graphNodes
    const [input, setInput] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [custReference, setCustReference] = useState('')
    const [showDocuments, setShowDocuments] = useState('')


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const nextdueget = (r) => {
        let ch = 0
        if (r["gatein_fpod"] && r["gatein_fpod"].length > 0) {
            ch = 16
        }
        if (r["gateout_to_fpod"] && r["gateout_to_fpod"].length > 0 && ch == 0) {
            ch = 15
        }
        if (r["pod_arrival"] && r["pod_arrival"].length > 0 && ch == 0) {
            ch = 14
        }
        if (r["reworking_pod_departure"] && r["reworking_pod_departure"].length > 0 && ch == 0) {
            ch = 13
        }
        if (r["reworking_pod_arrival"] && r["reworking_pod_arrival"].length > 0 && ch == 0) {
            ch = 12
        }
        if (r["ts4_departure"] && r["ts4_departure"].length > 0 && ch == 0) {
            ch = 11
        }
        if (r["ts4_arrival"] && r["ts4_arrival"].length > 0 && ch == 0) {
            ch = 10
        }
        if (r["ts3_departure"] && r["ts3_departure"].length > 0 && ch == 0) {
            ch = 9
        }
        if (r["ts3_arrival"] && r["ts3_arrival"].length > 0 && ch == 0) {
            ch = 8
        }
        if (r["ts2_departure"] && r["ts2_departure"].length > 0 && ch == 0) {
            ch = 7
        }
        if (r["ts2_arrival"] && r["ts2_arrival"].length > 0 && ch == 0) {
            ch = 6
        }
        if (r["ts1_departure"] && r["ts1_departure"].length > 0 && ch == 0) {
            ch = 5
        }
        if (r["ts1_arrival"] && r["ts1_arrival"].length > 0 && ch == 0) {
            ch = 4
        }
        if (r["reworking_pol_departure"] && r["reworking_pol_departure"].length > 0 && ch == 0) {
            ch = 3
        }
        if (r["reworking_pol_arrival"] && r["reworking_pol_arrival"].length > 0 && ch == 0) {
            ch = 2
        }
        if (r["sob_pol"] && r["sob_pol"].length > 0 && ch == 0) {
            ch = 1
        }
        if (ch == 16) {
            return ""
        }
        if (r["etd_at_pol"] && r["etd_at_pol"].length > 0 && ch < 1) {
            return MyactionUtils.getdsrdatediff(r["etd_at_pol"], 'etd_at_pol')
        }
        if (r["reworking_pol_arrival_estimate"] && r["reworking_pol_arrival_estimate"].length > 0 && ch < 2) {
            return MyactionUtils.getdsrdatediff(r["reworking_pol_arrival_estimate"], 'reworking_pol_arrival_estimate')
        }
        if (r["reworking_pol_departure_estimate"] && r["reworking_pol_departure_estimate"].length > 0 && ch < 3) {
            return MyactionUtils.getdsrdatediff(r["reworking_pol_departure_estimate"], 'reworking_pol_departure_estimate')
        }
        if (r["ts1_arrival_estimate"] && r["ts1_arrival_estimate"].length > 0 && ch < 4) {
            return MyactionUtils.getdsrdatediff(r["ts1_arrival_estimate"], 'ts1_arrival_estimate')
        }
        if (r["ts1_departure_estimate"] && r["ts1_departure_estimate"].length > 0 && ch < 5) {
            return MyactionUtils.getdsrdatediff(r["ts1_departure_estimate"], 'ts1_departure_estimate')
        }
        if (r["ts2_arrival_estimate"] && r["ts2_arrival_estimate"].length > 0 && ch < 6) {
            return MyactionUtils.getdsrdatediff(r["ts2_arrival_estimate"], 'ts2_arrival_estimate')
        }
        if (r["ts2_departure_estimate"] && r["ts2_departure_estimate"].length > 0 && ch < 7) {
            return MyactionUtils.getdsrdatediff(r["ts2_departure_estimate"], 'ts2_departure_estimate')
        }
        if (r["ts3_arrival_estimate"] && r["ts3_arrival_estimate"].length > 0 && ch < 8) {
            return MyactionUtils.getdsrdatediff(r["ts3_arrival_estimate"], 'ts3_arrival_estimate')
        }
        if (r["ts3_departure_estimate"] && r["ts3_departure_estimate"].length > 0 && ch < 9) {
            return MyactionUtils.getdsrdatediff(r["ts3_departure_estimate"], 'ts3_departure_estimate')
        }
        if (r["ts4_arrival_estimate"] && r["ts4_arrival_estimate"].length > 0 && ch < 10) {
            return MyactionUtils.getdsrdatediff(r["ts4_arrival_estimate"], 'ts4_arrival_estimate')
        }
        if (r["ts4_departure_estimate"] && r["ts4_departure_estimate"].length > 0 && ch < 11) {
            return MyactionUtils.getdsrdatediff(r["ts4_departure_estimate"], 'ts4_departure_estimate')
        }
        if (r["reworking_pod_arrival_estimate"] && r["reworking_pod_arrival_estimate"].length > 0 && ch < 12) {
            return MyactionUtils.getdsrdatediff(r["reworking_pod_arrival_estimate"], 'reworking_pod_arrival_estimate')
        }
        if (r["reworking_pod_departure_estimate"] && r["reworking_pod_departure_estimate"].length > 0 && ch < 13) {
            return MyactionUtils.getdsrdatediff(r["reworking_pod_departure_estimate"], 'reworking_pod_departure_estimate')
        }
        if (r["eta_at_pod"] && r["eta_at_pod"].length > 0 && ch < 14) {
            return MyactionUtils.getdsrdatediff(r["eta_at_pod"], 'eta_at_pod')
        }
        if (r["etd_pod"] && r["etd_pod"].length > 0 && ch < 15) {
            return MyactionUtils.getdsrdatediff(r["etd_pod"], 'etd_pod')
        }
        if (r["eta_fpod"] && r["eta_fpod"].length > 0 && ch < 16) {
            return MyactionUtils.getdsrdatediff(r["eta_fpod"], 'eta_fpod')
        }
        return ""
    }
    useEffect(() => {
        setCustReference(e1['customerReference']);
        setInput(true)
        setShowDocuments(false)
    }, [e1]);
    const refreshProject = (data) => {
        visibilityStore.recheck === 0 ? visibilityStore.recheck = 1 : visibilityStore.recheck = 0;
        loader.hide()
    }

    return <div className="box is-fullwidth" style={{ border: '1px solid lightgrey' }}>
        <div className="columns" style={{ fontSize: '0.8rem', color: '#000055' }}>
            <div className="column is-4" style={{ textAlign: 'left' }}>
                <div>Customer Reference# :
                    <input type="text" disabled={input} value={custReference} onChange={(async (e) => {
                        setCustReference(e.target.value);
                    })} /> 
                    {input ? <i class="fa fa-pencil" style={{paddingLeft:'10px'}} onClick={() => {
                        setInput(!input)
                    }}></i> : ''}
                    {!input && custReference.length > 0 ? <i class="fa fa-save" style={{paddingLeft:'10px'}} onClick={async () => {
                        if (custReference !== '' && custReference !== e1['customerReference']) {
                            await Api.updateCustomerReferenceId(e1["_id"], custReference)
                            setInput(!input)
                        }
                    }}></i> : ''}
                </div>
                {e1['_id'] ? <div>Booking ID : <strong>{e1['_id']}</strong></div> : ''}

            </div>
            <div className="column is-4" style={{ textAlign: 'left' }}>
                {(e1.tsUpdatedBy) ? <>
                    {
                        (e1['tsUpdatedOn'] && e1['tsUpdatedBy']) ? <AgLastUpload data={{ updatedOn: e1['tsUpdatedOn'], updatedBy: e1['tsUpdatedBy'] }} /> : <></>
                    }
                </> : <>
                    {
                        (e1['tsUpdatedOn'] && e1['tsUpdatedBy']) ? <AgLastUpload1 data={{ updatedOn: e1['tsUpdatedOn'], updatedBy: e1['tsUpdatedBy'] }} nextdue={nextdueget(e1)} /> : <>
                            {
                                <AgLastUpload2 nextdue={nextdueget(e1)} />
                            }
                        </>
                    }
                </>}
                {
                    e1.containerlist && e1.containerlist.length > 0 ? <>
                        <div className="select" style={{ position: "relative", left: "7em", top: "1rem", }}>
                            <select style={{ width: "10em", fontWeight: "bold" }} value={e1.containershow} onChange={(e) => {
                                for (let i = 0; i < visibilityStore.list.length; i++) {
                                    if (e1._id === visibilityStore.list[i]['_id']) {
                                        visibilityStore.list[i]['containershow'] = e.target.value
                                        i = visibilityStore.list.length + 1
                                    }
                                }
                            }}>
                                <option value="-1">All Container</option>
                                {
                                    e1.containerlist.map((e, i) => {
                                        return <option value={i}>{i + 1}. {e.container_types}{(e.container_number && e.container_number.length) ? "-" + e.container_number : ""}</option>
                                    })
                                }
                            </select>
                        </div>
                    </> : ""
                }
            </div>

            <div className="column is-4" style={{ textAlign: 'right' }}>
                <div>MAWB Number : <strong>{e1['MAWBNumber']}</strong></div>
                {/* {e1['carrierMBLNumber'] ? <div>Carrier MBL Number : <strong>{e1['carrierMBLNumber']}</strong></div> : ''}
                {e1['containerNumber'] ? <div>Container Number : <strong>{e1['containerNumber']}</strong></div> : ''} */}
                <div>
                    {
                        (!props.hideedit && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_VISIBILITY)) ?
                            <button className="button is-info is-inverted is-large" style={{ top: "0.7em" }} onClick={() => {
                                if (visibilityStore.error.length > 0) {
                                    visibilityStore.error = []
                                }
                                configStore.modalvisibledata = e1
                                configStore.quotationModalVisible = true
                            }}><span className="icon"><i className="fas fa-pen-to-square fas fa-lg "></i></span></button> : ""
                    }

                    {e1.intermo && e1.tsUpdatedBy!=='inActive/inCorrect AWB Number' ? <button className="button is-info is-inverted is-large" style={{ top: "0.7em" }} onClick={async () => {
                        loader.show()
                        await Api.getIntermoBookingsData(e1.MAWBNumber, refreshProject);
                    }}><span className="icon"><i className="fas fa-refresh fas fa-lg "></i></span></button> :
                        <button className="button is-info is-inverted is-large" style={{ top: "0.7em" }} onClick={() => {
                            trackMyBookingOceanStore.MAWBNumber = e1.MAWBNumber
                            trackMyBookingOceanStore.bookingId = e1._id
                            trackMyBookingOceanStore.customerReference = e1.customerReference
                            props.open()
                        }}><span className="icon"><i className="fas fa-pen-to-square fas fa-lg "></i></span></button>
                    }


                    {(e1?.tsUpdatedBy === 'inActive/inCorrect AWB Number' || !e1.tsUpdatedBy) ? <button className="button is-danger is-inverted is-large" style={{ top: "0.7em" }} onClick={() => {
                        setIsModalOpen(true)
                    }}>
                        <span className="icon"><i class="fa-solid fa-trash-can"></i>
                        </span></button> : ''}
                </div>
                <Popup isOpen={isModalOpen} onClose={closeModal} data={e1} refresh={props.callBack} />
            </div>

        </div>
        {nodes && nodes.length > 0?<div className="columns">
            <div className="column is-12">
                <ShipmentGraph graphNodes={nodes} />
            </div>
        </div> :e1?.tsUpdatedBy === 'Pending'?<div style={{ textAlign: 'center' }}>{'Awaiting for Tracking to Start'}</div>:''}
        {
            (e1?.tsUpdatedBy )?
                <div style={{ textAlign: 'center' }}>
                    {
                        (showDocuments) ?
                            loginStore.isClient() ?
                                <Button size='small' onClick={() => {
                                    setShowDocuments(false)
                                }} variant='contained' endIcon={<ArrowDropUp />} fullWidth sx={{
                                    display: "flex", textTransform: "capitalize", alignItems: "center", backgroundColor: "#ffdc7d", color: "black", "&:hover": {
                                        backgroundColor: "#ffdc7d"
                                    }
                                }} >  Hide Document Center</Button>

                                :

                                <button className='button is-warning is-small is-fullwidth'
                                    onClick>
                                    Hide Document Center
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-up"></i>
                                    </span>
                                </button> :
                            loginStore.isClient() ? <Button size="small" onClick={() => {
                                setShowDocuments(true)
                                // setShowDetails(false)
                            }} variant='contained' endIcon={<ArrowDropDown />} fullWidth sx={{ textTransform: "capitalize", alignItems: "center" }} >  Document Center</Button> :
                                <button className='button is-info is-small is-fullwidth'
                                    onClick={() => {
                                        setShowDocuments(true)
                                        // setShowDetails(false)
                                    }}>
                                    Document Center
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-down"></i>
                                    </span>
                                </button>

                    }

                    {
                        (showDocuments) ?
                            <div className="columns" style={{
                                fontSize: '0.8rem'
                            }}>
                                <div className="column is-12" >
                                    <Taskfiletable data={e1} />
                                </div>
                            </div> : <></>
                    }

                    
                </div>:<></>}


    </div>
}