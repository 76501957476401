import { Box, Typography, Button, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails, Collapse, FormControl, FormLabel, FormControlLabel, Checkbox, FormGroup, Dialog, DialogTitle, DialogContent, Tooltip } from "@mui/material";
import { ShipmentScopeTag } from "../mui-components/ShipmentScope";
import calender from "../assets/images/calender.svg"
import { Search, ExpandMoreRounded } from "@mui/icons-material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { SHIPMENT_SCOPE } from "../util/quotationUtil";
import AgAutoComplete, { AgMuiUn } from "../mui-components/AgAutoComplete";
import { useSnapshot } from "valtio";
import { breadCrumbStore, configStore, enquiryFormState, enquiryState, filtersOptions, filtersState, loginStore, stepperIndex } from "../state/CommonState";
import { useForm } from "react-hook-form";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import closeIcon from "../assets/images/close.svg";
import { AntSwitch } from "../mui-components/AntSwitch";
import routing from "../assets/images/routing.svg";
import container from "../assets/images/container.svg";
import company from "../assets/images/company.svg";
import { ChooseScheduleCard, ContractCard, ScheduleCard } from "../mui-components/ScheduleCard";
import { OptionBox } from "../mui-components/OptionBox";
import { EnquiryDetailsView } from "../mui-components/EnquiryDetailsView";
import { enquiryStore } from "../enquiry/enquiry-data";
import Utils from "../util/Utils";
import { CargoTypes } from "./CargoTypes";
import { DetailsPage } from "./DetailsPage";
import MonApi from "../state/mongo-api";
import PricingUtils from "../pricing/pricing.utils";
import rightarrow from "../assets/images/rightarrow2.svg";
import vehicleDetails from "../assets/images/vehicledetails.svg";
import cargoDetails from "../assets/images/cargodetails.svg";
import containerbox from "../assets/images/containerbox.svg";
import { CenteredCircularProgress } from "../mui-components/CircularProgress";
import { Nodata } from "../mui-components/nodatafound";
import { ScheduleGraph } from "../mui-components/ScheduleGraph";
import { PricingContextProvider } from "../context/PricingContext";

const SelectOption = forwardRef((props, ref) => {
    const { children, width, popupContent,flex, isMenu = true } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (e) => {
        e?.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        if (ref) {
            ref.current = { handleClose };
        }
    }, [props, ref])

    return <Box onClick={isMenu ? (e) => {
        handleClick(e);
    } : undefined} sx={{
        // boxShadow: "3px 3px 15px 0px #0000001A",
         backgroundColor: "white", borderRadius: "8px", padding: "6px 12px", 
         width: width || "fit-content",
        // flex,
          height: "55px", "&:hover": {
            cursor: isMenu ? "pointer" : ""
        }
    }}>
       <Box sx={{width:"100%"}}> {children}</Box>
        <>
       {  isMenu && <Menu
            sx={{backgroundColor:"transparent"}}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "center", horizontal: "left" }}
        >
            {/* <Box sx={{height:"100px",width:"100%",backgroundColor:"yellow"}}>treddhgghredfjhkjj</Box> */}
            {popupContent}
        </Menu>}
        </>
    </Box>
});



const items = [{ title: "LCL" }, { title: "FCL" }, { title: "AIR" }]




function GetMenuItems({ menuItems, onClick }) {
    return <>{menuItems?.map((item, i) => <MenuItem
        onClick={(e) => {
            if (onClick) {
                onClick(e, i);
            }
        }}
        sx={{
            textAlign: "start",
            wordBreak: "break-word",
            whiteSpace: "normal",
            maxWidth: "300px", margin: "8px", borderRadius: "4px",
            color: "#555555",
            "&:hover": {
                backgroundColor: "#2B3688",
                color: "white"
            }
        }}  >{item.title}</MenuItem>)}</>
}

function ShipmentDetails({serachHandler}) {
    const modeRef = useRef();
    const scopeRef = useRef();
    const originRef = useRef();
    const destinationRef = useRef();
    const crdRef = useRef();
    // const formState = useSnapshot(enquiryFormState);
    const enquiry = useSnapshot(enquiryState);
    const enquiryStoreState = useSnapshot(enquiryStore);
    // const {control,getValues,setValue,watch} = formState.form;

    const defaultForm = useForm(({
        defaultValues: {
            origin: "",
            destination: "",
            cargoreadinessdate: "",
            scope: ""
        }
    }))
    const { control, getValues, setValue, watch, clearErrors } = defaultForm;
    const scope = watch("scope");
    

    const scopes = Object.values(SHIPMENT_SCOPE);
    return <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between",gap:1.5, alignItems: "center" }}>
        <SelectOption isMenu={false} ref={modeRef} width="7%" popupContent={<GetMenuItems menuItems={items} onClick={(e, i) => {
            enquiryState.mode = items[i].title;
            modeRef?.current?.handleClose(e);
        }} />}>
            <OptionBox title="Mode" content={<Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree" }}>{enquiryStoreState.current.shipmentType}</Typography>} />
        </SelectOption>
        <SelectOption isMenu={false} ref={scopeRef} popupContent={<GetMenuItems menuItems={scopes.map((scope) => ({ title: scope }))} onClick={(e, i) => {
            enquiryStore.current.shipmentScope = scopes[i];
            scopeRef?.current?.handleClose(e);
        }} />} width="10%">
            <OptionBox key={scope} title="Scope" content={<Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree" }}>{enquiryStoreState.current.shipmentScope}</Typography>} />
        </SelectOption>
        <SelectOption  ref={originRef} width="30%" popupContent={<Box sx={{ width: "300px", height: "300px", paddingX: "8px" }}><AgMuiUn clearErrors={clearErrors} getValues={getValues} placeholder="choose origin" selectedValue={(e, val) => {
            if(val!==enquiryStore.current.enqRoute.origin){
                filtersState.filteredList = [];
            }
            enquiryStore.current.enqRoute.origin = val;
            originRef?.current?.handleClose(e);
            if(serachHandler){
                serachHandler();
            }
        }} control={control} isDoor={enquiryStoreState.current.shipmentScope  === SHIPMENT_SCOPE.D2D || enquiryStoreState.current.shipmentScope  === SHIPMENT_SCOPE.D2P}  name="origin" /></Box>} >
            <OptionBox title="Origin" content={
                <>
            <Tooltip title={enquiryStoreState.current.enqRoute.origin} componentsProps={{
    tooltip: {
      sx: {
        fontSize:"16px",
        bgcolor: "white",
        color: "black",
        boxShadow:"4px 4px 20px 0px #00000033"
      }
    }
  }} >
            <Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{enquiryStoreState.current.enqRoute.origin}</Typography>
            </Tooltip>
            </>
            } />
        </SelectOption>
        <SelectOption flex={1} ref={destinationRef} width="30%" popupContent={<Box sx={{ width: "300px", height: "300px", paddingX: "8px" }}><AgMuiUn clearErrors={clearErrors} getValues={getValues} placeholder="choose destination" selectedValue={(e, val) => {
             if(val!==enquiryStore.current.enqRoute.destination){
                filtersState.filteredList = [];
            }
            enquiryStore.current.enqRoute.destination = val;
            destinationRef?.current?.handleClose(e);
            if(serachHandler){
                serachHandler();
            }
        }} control={control} isDoor={enquiryStoreState.current.shipmentScope === SHIPMENT_SCOPE.D2D || enquiryStoreState.current.shipmentScope  === SHIPMENT_SCOPE.P2D} name="destination" /></Box>}>
           <OptionBox width="100%" title="Destination" content={
             <>
             <Tooltip title={enquiryStoreState.current.enqRoute.destination} componentsProps={{
     tooltip: {
       sx: {
         fontSize:"16px",
         bgcolor: "white",
         color: "black",
         boxShadow:"4px 4px 20px 0px #00000033"
       }
     }
   }} >
           <Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enquiryStoreState.current.enqRoute.destination}</Typography>
           </Tooltip>
           </>
            } />
        </SelectOption>
        {/* <SelectOption width="16%">
            <OptionBox title="Choose Departure" content={<span style={{ display: "flex", flexDirection: "row", gap: 1, height: "100%", alignItems: "center", width: "200px", width: "100%", justifyContent: "space-between" }}><img src={calender} alt="calender" /><Typography sx={{ color: "#555555", fontSize: "12px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "180px" }}>Dec 7, 2023 to Dec 17, 2023</Typography></span>} />
        </SelectOption> */}
        <SelectOption ref={crdRef} width="13%" popupContent={<CustomStyledTextField value={enquiryStoreState.current.enqRoute.cargoReadinessDate} size="small" type="date" onChange={(e) => {
             enquiryStore.current.enqRoute.cargoReadinessDate = e.target.value;
            crdRef?.current?.handleClose(e);
        }} />} >
            <OptionBox title="Cargo Readiness Date" content={<span style={{ display: "flex", flexDirection: "row", gap: 1, height: "100%", width: "100%", alignItems: "center", justifyContent: "start", gap:1 }}><img src={calender} alt="calender" /><Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{Utils.formatDateBooking(enquiryStoreState.current.enqRoute.cargoReadinessDate)}</Typography></span>} />
        </SelectOption>
        <Button onClick={()=>{
            if(serachHandler){
                serachHandler();
            }
        }} sx={{ textTransform: "capitalize" }} size="small" startIcon={<Search />} variant="contained" >Search</Button>
    </Box>
}




function getPriceAndDays(route){
    const price = route.charges["Final Discounted Offer"] && route.charges["Final Discounted Offer"]["total"] ? route.charges["Final Discounted Offer"]["total"] : route.dummyContract["quoteValue"];
    const days = route?.dummyContract?.schedules?.length > 0 ? route.minTransitTime : 0 ;
    console.log(price, "IIIIIIIIIIIII");
    return {price,days};
}



const ScheduleTabs = [ { title: "Cheapest" ,value:"CHEAPEST"},{ title: "Fastest", value : "FASTEST"}];

function getCheapestAndFastestRouteOfAll(routes=[]){
   let fastestCheapest = {fastest:{price:0,days:0},cheapest:{price:0,days:0}};
   if(routes.length){
        const {price:firstPrice,days:firstDays} = getPriceAndDays(routes[0]);
        fastestCheapest.fastest = {price:firstPrice,days:firstDays};
        fastestCheapest.cheapest = {price:firstPrice,days:firstDays};
        console.log(fastestCheapest.fastest, fastestCheapest.cheapest, "PPPPPPPPPPPP");
        for (let i = 1; i < routes.length; i++){
            const {price,days} = getPriceAndDays(routes[i]);
            const {days : previousFastestDays} = fastestCheapest.fastest;
            const {price : previousCheapestPrice} = fastestCheapest.cheapest;
            if(previousFastestDays===0){
                 fastestCheapest.fastest = {price,days};
            }else if(days<previousFastestDays){
            fastestCheapest.fastest = {price,days};
          }     

          if(price<previousCheapestPrice){
             fastestCheapest.cheapest = {price,days};
          }
      }
      return fastestCheapest;
   }
   return;
}

function cheapestFastest(routes=[]){
    let best = {
      cheapest: null,
      fastest: null
    };
    for (const route of routes){
        let provider = getPriceAndDays(route);
      if(provider.price < best.cheapest?.price || best.cheapest === null ){
        best.cheapest = provider
      }
      if((provider.days < best.fastest?.days && provider.days !== 0) || best.fastest === null){
        best.fastest = provider
      }
    }
    return best
  }

function ScheduleTabComponent2(){
    const [selectedIndex, setIndex] = useState(0);
    const [priceAndDays,setPriceAndDays] = useState({price:null,days:null});
    const divRef = useRef();
    const [fastestPriceAndDays,setFastestPriceAndDays] = useState({price:null,days:null})
    useEffect(()=>{
        filtersState.cheapestFastestFilter(ScheduleTabs[selectedIndex].value);
    },[selectedIndex])

    useEffect(()=>{
        if(filtersState.filteredList?.length){
             const {fastest,cheapest} = cheapestFastest(filtersState.filteredList);
             setPriceAndDays({...cheapest});
             setFastestPriceAndDays({...fastest});
             setTimeout(() => {
                if (divRef.current) {
                    divRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 200)
        }
    },[filtersState.filteredList])
    return <Box ref={divRef} sx={{height:"60px",display:"flex",flexDirection:"row",padding:"5px",gap:2}}>
{ScheduleTabs.map((tab, i) =>{ 
    let pricingAndDays = i===0? priceAndDays : fastestPriceAndDays;
    return <Box onClick={() => {
            if (selectedIndex !== i) {
                setIndex(i);
            }
        }} sx={{
            backgroundColor: selectedIndex === i ? "#F4B14A" : "white", border : selectedIndex === i ? "none" : "1px solid black",color: selectedIndex === i ? "white" : "black", paddingX: "24px", height: "100%",minWidth:"210px", display: "flex", flexDirection:"column",justifyContent: "space-around", alignItems: "start", borderRadius:"74px", "&:hover": {
                cursor: "pointer"
            }
        }}>
            <Typography sx={{ fontSize: '16px',fontWeight:"bold", fontFamily: "Figtree" }}>{tab.title}</Typography>
            <Typography sx={{ fontSize: '16px', fontFamily: "Figtree", color:selectedIndex === i ? "white":"black",fontWeight:"bold" }}>{Utils.N(pricingAndDays?.price)} <span style={{fontWeight:"normal"}}>{pricingAndDays?.days && pricingAndDays.days > 0 ? `( ${pricingAndDays.days} Days )` : ""}</span></Typography>
        </Box>})}
    </Box>
}

function ScheduleTabComponent() {
    const [selectedIndex, setIndex] = useState(0);
    return <Box sx={{ minHeight: "40px", width: "170px", borderRadius: "22px", backgroundColor: "white", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "5px 8px" }}>
        {ScheduleTabs.map((tab, i) => <Box onClick={() => {
            if (selectedIndex !== i) {
                setIndex(i);
            }
            filtersState.cheapestFastestFilter(tab.value);
        }} sx={{
            backgroundColor: selectedIndex === i ? "#2C358A" : "transparent", color: selectedIndex === i ? "white" : "#555555", paddingX: "10px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "22px", "&:hover": {
                cursor: "pointer"
            }
        }}>
            <Typography sx={{ fontSize: '14px', fontFamily: "Figtree" }}>{tab.title}</Typography>
        </Box>)}
    </Box>
}

const filters = [{ title: "Routing", src: routing, content: <RoutingFilters /> },
{ title: "Carriers", src: container, content: <CarriersFilter options={["Maesrk", "Cisco", "Evergreen", "Cisc"]} /> },
{ title: "Services", src: company, content: <ServiceFilters options={["Indamex", "Express"]} /> }
]

function Accordions() {
    const [selectedIndex, setIndex] = useState(-1);
    return <>
        {filters.map((filter, i) =>
            <CustomAccordion title={filter.title} src={filter.src} content={filter.content} open={i === selectedIndex} setAccordion={() => {
                if (i === selectedIndex) {
                    setIndex(-1)
                } else {
                    setIndex(i);
                }
            }} />
        )}
    </>
}

function CheckBoxGroup({ options }) {
    const [checkedItems, setCheckedItems] = useState(options.reduce((acc, cur) => {
        if (!acc[cur]) {
            acc[cur] = false;
        }
        return acc;
    }, {}));

    const handleCheckboxChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <FormControl component="fieldset">
            <FormGroup>
                {Object.keys(checkedItems).map((item) =>
                    <FormControlLabel

                        sx={{ fontFamily: "Figtree", fontSize: "14px" }}
                        control={<Checkbox size="small" sx={{ fontFamily: "Figtree", fontSize: "14px" }} checked={checkedItems[item]} onChange={handleCheckboxChange} name={item} />}
                        label={<Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{item}</Typography>}
                    />
                )}
            </FormGroup>
        </FormControl>
    );
}


function CarriersFilter({ options }) {
    return <CheckBoxGroup options={options} />
}

function ServiceFilters({ options }) {
    return <CheckBoxGroup options={options} />
}

function CustomAccordion({ title, src, content, open, setAccordion }) {

    return <Box sx={{ display: "flex", flexDirection: "column", paddingY: "5px"}}>
        <Box onClick={() => {
            setAccordion();
        }} sx={{
            backgroundColor: "#F5F5F5", "&:hover": {
                cursor: "pointer"
            }, borderRadius: '5px', border: "0.5px solid #AAAAAA", display: 'flex', flexDirection: "row", justifyContent: "space-between", padding: "4px 8px"
        }}>
            <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><img src={src} /><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography></span>
            <ExpandMoreRounded />
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit={false}><Box sx={{ display:"flex",flex:1,overflowY: "auto", padding: "8px" }}>{content}</Box></Collapse>
    </Box>
}

function FilterBox({ title, content }) {
    return <Box sx={{ display: "flex", flexDirection: "column", paddingY: "6px" }}>
        <Typography sx={{ fontFamily: "Figtree", color: "black" }}>{title}</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.8, padding: "4px 8px" }}>
            {content}
        </Box>
    </Box>
}

function RoutingFilters() {
    const options = useSnapshot(filtersOptions);
    
    return <Box sx={{ display: "flex", flexDirection: 'column' }}>
        <FilterBox title="Place of Receipt" content={<>
           {options.routing.por.map((title)=><FilterSwitch routeKey="por" title={title} />)}
        </>} />
        <FilterBox title="Port of loading" content={<>
            {options.routing.pol.map((title)=><FilterSwitch routeKey="pol" title={title} />)}
        </>} />
        <FilterBox title="Port of Discharge" content={<>
            {options.routing.pod.map((title)=><FilterSwitch routeKey="pod" title={title} />)}
        </>} />
        <FilterBox title="Final Place of Delivery" content={<>
            {options.routing.fpod.map((title)=><FilterSwitch routeKey="fpod" title={title} />)}
        </>} />
    </Box>
}



function FilterSwitch({ title, routeKey }) {
    return <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><AntSwitch onChange={(value)=>{
        let {checked} = value.target
         if(checked){
            filtersState.addFilterOption(["routing",routeKey],title)
         }else{
            filtersState.removeFilterOption(["routing",routeKey],title)
         }

    }} style={{ scale: "0.8" }} /><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography></span>
}


function CargoDetailsTag({onClick,src,title}){
    return <Box onClick={onClick} sx={{display:"flex",flexDirection:"row",alignItems:"center",gap:1,cursor:"pointer"}}>
        <img style={{height:"18px",width:"18px"}} src={src}/>
        <Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold"}}>{title}</Typography>
        <img  src={rightarrow} />
    </Box>
}


function getRequiredDetailsForGivenShipmentScope(){
    let mode = enquiryStore.current.shipmentType.toLowerCase();
    let requiredDetailsForGivenShipmentScope = [];
    let origin = enquiryStore.current.shipmentScope.split("-")[0].toLowerCase();
    let destination = enquiryStore.current.shipmentScope.split("-")[2].toLowerCase();
    let stuffing = enquiryStore.current.stuffingType.toLowerCase();
    let deStuffing = enquiryStore.current.destuffingType.toLowerCase();

 if(mode==="fcl"){
    requiredDetailsForGivenShipmentScope.push({title:"Container Details",src:containerbox});
 }

    if(mode==="lcl" || mode==="air" || (mode==="fcl" && (origin==="door" && stuffing === "dock" ) || (destination === "door" && deStuffing === "dock" ))){
        requiredDetailsForGivenShipmentScope.push({title:"Cargo Details",src:cargoDetails});
    }

    if(enquiryStore.vehicleDetailsNeeded){
        requiredDetailsForGivenShipmentScope.push({title:"Vehicle Details",src:vehicleDetails})
    }

    return requiredDetailsForGivenShipmentScope;
}

export function ViewSchedule({chooseSchedule=false,isSpot=false}) {
    useSnapshot(filtersState);
    const divRef = useRef();
    const selectedDetailForViewIndex = useRef(0);
    const defaultForm = useForm({
        defaultValues: {
            shipmentType: enquiryStore.current.shipmentType,
            originType: false,
            destinationType: false,
            hazardous: enquiryStore.current.hazardous === "No" ? false : true,
            quoteClass: enquiryStore.current.quoteClass,
            packingGroup: enquiryStore.current.packingGroup,
            temperature: enquiryStore.current.temperature,
            cargoValue: enquiryStore.current.cargoValue,
            unnumber: enquiryStore.current.unnumber,
            nonStackable:  enquiryStore.current.nonStackable === "No" ? false : true,
            temperatureControlled:  enquiryStore.current.temperatureControlled === "No" ? false : true,
            minTemperature: enquiryStore.current.minTemperature,
            maxTemperature: enquiryStore.current.maxTemperature,
            stuffingType: enquiryStore.current.stuffingType,
            destuffingType: enquiryStore.current.destuffingType,
            status: "ENQUIRY",
            cargoDimensionUnit: "Cms",
            enqRoute: enquiryStore.current.enqRoute,
            containerdetails:enquiryStore.current.containerDetails,
            cargodetails: enquiryStore.current.cargoDetails,
            vehicledetails : enquiryStore.current.vehicles
        }
    })

    useEffect(()=>{
        if(filtersState.filteredList?.length){
             setTimeout(() => {
                if (divRef.current) {
                    divRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 200)
        }
    },[filtersState.filteredList])

    // let chooseRoutes = [];

    // if(chooseSchedule){
        
    // }
    
    const enquiry = useSnapshot(enquiryStore)
    let mode = enquiry.current.shipmentType.toLowerCase();
    const [open,setOpen] = useState(false);
    const [isContract,setContract] = useState(false);
    const [loading,setLoading] = useState(false);
    let cargo;
    let container;
    if(chooseSchedule){
       if(mode === "air"){
        cargo = Utils.AirCargoCalculation(enquiry.current.cargoDetails);
       }else{
        cargo = Utils.CalculateCargo(enquiry.current.cargoDetails);
       }
    }

    useEffect(()=>{
          console.log("the enquiry in view schedule is ",enquiry.current);
    },[])
    return <Box sx={{  width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1 }}>
      {/* {chooseSchedule ?  
<EnquiryDetailsView options={[{ title: "Mode", value: enquiryStore.current.shipmentType }, { title: "Scope", value: enquiryStore.current.shipmentScope }, { title: "Origin", value: enquiryStore.current.enqRoute.origin, width:"320px" }, { title: "Destination", value: enquiryStore.current.enqRoute.destination, width:"320px" }, { title: "Cargo Readiness", value: enquiryStore.current.enqRoute.cargoReadinessDate }]} />
     :  */}
      {/* <ShipmentDetails serachHandler={async()=>{
            let pricingStore = {
                gst: '',
                origin: '',
                destination: ''
            }
            let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getroutes`
            if(enquiryStore.current.shipmentType.toUpperCase()==="AIR") url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/airpricing/getroutes`
            if (enquiryStore.current.shipmentType.toUpperCase() == 'FCL') url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getroutesv2`
            if (loginStore.isClient()) pricingStore.gst = configStore.currentSelectedEntity.gst;
            enquiryStore.current.gst = pricingStore.gst;
            enquiryStore.current.entity = pricingStore.gst;
            enquiryStore.current.createdBy = loginStore.userRec.email;
            enquiryStore.current.entityId = configStore.currentSelectedEntity.entityId;
            let curDate = Utils.getCurrentDateString();
            let crd = '' + enquiryStore.current.enqRoute.cargoReadinessDate
            crd = crd.trim();
            let otype = enquiryStore.current.shipmentScope.split("-")[0];
            let dtype = enquiryStore.current.shipmentScope.split("-")[2];
            let displayCargo4fcl = false;
            if (enquiryStore.current.shipmentType.toUpperCase() == 'FCL') {
                if (otype != 'Port' && enquiryStore.current.stuffingType != 'Factory') displayCargo4fcl = true
                if (otype != 'Port' && enquiryStore.current.destuffingType != 'Factory') displayCargo4fcl = true
            }
            if (crd == '') {
                crd = curDate
                enquiryStore.current.enqRoute.cargoReadinessDate = curDate
            }
            let payload = {
                entity: enquiryStore.current.entityId,
                origin: enquiryStore.current.enqRoute.origin,
                destination: enquiryStore.current.enqRoute.destination,
                sscope: `${otype}-to-${dtype}`,
                crd: enquiryStore.current.enqRoute.cargoReadinessDate,
                curDate,
                cargoDetails: enquiryStore.current.cargoDetails,
                vehicleDetails: enquiryStore.current.vehicleDetails,
                enquiry: enquiryStore.current,
                displayCargo4fcl
            }
setLoading(true)
            if (!PricingUtils.getTotalVehicleCount(payload.vehicleDetails) && enquiryStore.vehicleDetailsNeeded) {
                configStore.setModalMessage('At least one vehicle needs to be selected for India transport')
                // setDisable(false)
                setLoading(false)
                return
            }
            // if(mode.toLowerCase()==="air"){

            //     saveEnquiry();

            //     return;
            // }
            let ret;
            if (enquiryStore.current.hazardous == 'Yes' || enquiryStore.current.nonStackable == 'Yes') {
                ret = []
            } else {
            ret = await MonApi.apost(url, payload);
            if(mode.toLowerCase().includes("fcl")){
                ret = ret.finalset
            }
            }
           

            // loader.hide();
            if (ret?.error) {
                configStore.setModalMessage('' + ret.error, [], true, [], '', 5000)
                // setDisable(false)
                return;
            } else if(!ret || !ret.length) {
                //  setOpen(true);
                //  setDisable(false)
                setLoading(false)
                
                 return;
            }
            
                filtersState.routes = ret;
                filtersState.filteredList = [...ret];
            
            setLoading(false)
      }} /> */}
     {/* } */}
        <Box sx={{width: "100%", display: "flex", flexDirection: "row", gap: 1 }}>
            {/* <Box sx={{width: "20%", backgroundColor: "white", borderRadius: "10px", padding: "4px 8px", display:"flex",flexDirection:"column"}}>
                <Box sx={{ width: "100%", height: "8%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ fontSize: '14px', fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>Filters:</Typography>
                    <Button disableRipple disableFocusRipple disableTouchRipple variant="text" sx={{ color: "#2C358A", textTransform: "capitalize", fontSize: "12px", textDecoration: "underline", fontFamily: "Figtree", "&:hover": { backgroundColor: "transparent", textDecoration: "underline", } }}>Select all</Button>
                </Box>
                <Box sx={{ flex:1, display: "flex", flexDirection: "column"}}>
                    <FilterSwitch title="Direct only" />
                    <Accordions />
                </Box>
            </Box> */}
            <Box sx={{ flex:1,display: "flex", flexDirection: "column", gap: 1}}>
                {/* {  (chooseSchedule ||  isContract) 
                && <>
                { enquiryStore.current.shipmentType.toLowerCase() === "fcl" ? 
                <Box sx={{display:"flex",flexDirection:"row",backgroundColor:"white",borderRadius:"5px",width:"100%",alignItems:"center",justifyContent:"space-between",padding:'6px 12px'}}>
                <Box sx={{display:"flex",flexDirection:"row",gap:2}}>
                 <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"14px"}}>Container Details</Typography>
                 <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Container Type - <span style={{color:"#555555"}}>20'STD</span></Typography>
                 <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Weight - <span style={{color:"#555555"}}>20</span></Typography>
                 <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Volume - <span style={{color:"#555555"}}>200 Kgs</span></Typography>
                </Box>
                 <Button onClick={()=>{
                        setOpen(true);
                       }} size="small" sx={{alignSelf:"end",fontSize:"14px",textTransform:"capitalize",padding:0.4}} variant="contained">Edit</Button>
          </Box> 
                :   <Box sx={{display:"flex",flexDirection:"row",backgroundColor:"white",borderRadius:"5px",width:"100%",alignItems:"center",justifyContent:"space-between",padding:'6px 12px'}}>
                      <Box sx={{display:"flex",flexDirection:"row",gap:2}}>
                       <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",color:"black",fontSize:"14px"}}>Cargo Details</Typography>
                       { mode === "air" && <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Package Type - <span style={{color:"#555555"}}>{cargo?.productType?.join(", ")}</span></Typography>}
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Quantity - <span style={{color:"#555555"}}>{cargo?.quantity}</span></Typography>
                       {mode === "air" && <>
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Weight - <span style={{color:"#555555"}}>{cargo?.totalWeight} {enquiryStore.current.cargoDetails[0].weight.unit}</span></Typography>
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Dimensions - <span style={{color:"#555555"}}>{cargo?.dimensions?.totalLength} x {cargo?.dimensions?.totalWidth} x {cargo?.dimensions?.totalHeight} {enquiryStore.current.cargoDetails[0].dimensions.unit}</span></Typography>
                       </>}
                      { mode === "lcl" && 
                      <>
                      <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Weight - <span style={{color:"#555555"}}>{cargo?.totalWeight} {enquiryStore.current.cargoDetails[0].totalWeight.unit}</span></Typography>
                       <Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"14px"}}>Total Volume - <span style={{color:"#555555"}}>{cargo?.totalVolume} {enquiryStore.current.cargoDetails[0].totalVolume.unit}</span></Typography>
                       </>
                       }
                      </Box>
                       <Button onClick={()=>{
                        setOpen(true);
                       }} size="small" sx={{alignSelf:"end",fontSize:"14px",textTransform:"capitalize",padding:0.4}} variant="contained">Edit</Button>
                </Box> } 
                </>  
                } */}
                {!chooseSchedule && !isContract &&
                   <ContractCard onClick={()=>{
                       setContract(true)
                   }}/>
                }
                <Box sx={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                {/* <ScheduleTabComponent2 /> */}
                {/* <Box sx={{display:"flex",flexDirection:"row",gap:3,alignItems:"center"}}>
                    <>
                    {getRequiredDetailsForGivenShipmentScope().map((detail,i)=><CargoDetailsTag  onClick={()=>{
                        setOpen(true);
                        stepperIndex.index = i;
                    }} title={detail.title} src={detail.src} />)}
                    </>
                </Box> */}
                
                </Box>
                
                <Box ref={divRef} sx={{height:"100%",width:"100%", display: "flex", flexDirection: "column", gap: 1, paddingRight: "5px",
        }}>
            {/* <ScheduleGraph/> */}
                   { chooseSchedule ? <>
                     { loading ? <CenteredCircularProgress/> : filtersState.filteredList?.length === 0 ? <Nodata/> :  filtersState.filteredList.map((route)=>{
                       
                        const rate = route.charges["Final Discounted Offer"] && route.charges["Final Discounted Offer"]["total"] ? route.charges["Final Discounted Offer"]["total"] : route.dummyContract["quoteValue"];
                        return <>{route?.dummyContract?.schedules.length > 0 ? <>
                               {route?.dummyContract?.schedules.map((schedule,i)=>{
                                
                                return <PricingContextProvider><ChooseScheduleCard isSpot={isSpot} record={route} index={i} schedule={schedule} rate={rate} contract={route.dummyContract} /></PricingContextProvider>})}
                            </> : <PricingContextProvider><ChooseScheduleCard isSpot={isSpot} record={route} rate={rate} contract={route.dummyContract} /></PricingContextProvider>
                        }
                        </>
                     }) }
                   </> : <>
                    <ScheduleCard />
                    {/* <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard />
                    <ScheduleCard /> */}
                   </>}
                </Box>
            </Box>
        </Box>
      { open &&  <Dialog fullScreen fullWidth open={open} sx={{padding:"5% 8%"}}>
            <DialogTitle sx={{backgroundColor:"#E8E8E8"}}>
                <Box sx={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                    <Typography>Edit Details</Typography>
                    <Box onClick={()=>{
                    setOpen(false);
                }} sx={{display:"flex",alignSelf:"end",justifyContent:"center",alignItems:"center",backgroundColor:"#2B6ED4",borderRadius:"5px",width:"max-content",padding:"8px","&:hover":{
                    cursor : "pointer"
                }}}>
                    <img style={{width:"18px",height:"18px"}} src={closeIcon} />
                </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{backgroundColor:"#E8E8E8",height:"100%",width:"100%"}} >
               <Box sx={{height:"100%", width : "100%",  display: "flex", flexDirection: "row", gap: 1}}>
               <CargoTypes form={defaultForm}/>
               <Box sx={{flex:1,width:"100%",display:"flex",flexDirection:"column",backgroundColor:"white",borderRadius: "10px",overflowY:"auto"}}>
               <DetailsPage mode={enquiry.current.shipmentType} form={defaultForm} onSubmit={()=>{
                  setOpen(false);
                  return;
               }}/>
               </Box>
               </Box>
            </DialogContent>
        </Dialog>}
    </Box>
}