import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import MonApi from "../state/mongo-api";
import EntityApi from "../state/entities-api";
import { configStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { Col1, Col2, Col3, Col4, Col5, Col6, Col8, Cols } from "../Controls";
import { loader } from "../util/loader";
import { dtactionStore } from "../dtactions/dtaction-store";
import { taskStore } from "../mytasks/task-store";
import { Link, useNavigate } from "react-router-dom";
import Taskfileapproval, {
  validateFileApproval,
} from "../mytasks/task-file-approval";
import Taskapi from "../mytasks/tasks-api";
import PhoneNoField from "../mui-components/PhoneNoField";

export default function DtactionPayout() {
  let navigate = useNavigate();
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(0);
  const [number, setNumber] = useState();
  const [phno, setPhno] = useState();
  useSnapshot(taskStore);
  useSnapshot(configStore);
  let task = taskStore.current;

  const [error, setError] = useState({
    phone: "",
  });

  useEffect(() => {
    console.log(phno)
    if (phno !== "" && phno?.length < 10) {
      setError((prevState) => {
        console.log("S", prevState);
        return { ...prevState, ...{ phone: "Enter Valid Number" } };
      });
      //return "";
    } else {
      setError({ phone: "" });
    }
  }, [phno]);

  

  useSnapshot(dtactionStore);
  let booking = dtactionStore.current.booking;
  let modalClass = "modal";
  if (dtactionStore.modalVisible1) modalClass += " is-active";

  const save = async () => {
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/dtactions`;
    console.log("\n\nVALUE OF SUBMIT CALLBACK=", dtactionStore.submitCallback);
    if (dtactionStore.submitCallback()) {
      let ret = await MonApi.apost(url, dtactionStore.current, "PUT");
      dtactionStore.view = "list";
      dtactionStore.listLoadCounter++;
    }
  };

  function checkValue(event) {
    setAmount(handleDecimalsOnValue(event.target.value));
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const checkInput = (e) => {
    if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
      e.preventDefault();
    } else {
      setNumber(e.target.value);
    }
  };

  const makePayment = async (entity) => {
    console.log("about to Payment api call.", entity,phno);
    // if (phno == undefined || phno?.length < 10) {
    //   setError((prevState) => {
    //     console.log("S", prevState);
    //     return { ...prevState, ...{ phone: "Enter Valid Number" } };
    //   });
    //   return "";
    // } else {
    dtactionStore.modalVisible1 = false;
     //}
    //let entityData = await EntityApi.viewEntity(entity.id);
    let paymentData = {
      amount: task.totalAmount,
      mobileNumber: task.phoneNumber,
      bookingNum: task.bookingNum,
      actionName: "Invoice Acceptance & Payment Details (COD)",
      url: process.env.REACT_APP_API_SERVER,
    };
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/integration/phonePayInitiate`;
    let payoutData = await MonApi.apost(url, paymentData);
    loader.show();
    console.log("payoutData", payoutData);
    // console.log("v",payoutData.results.data.instrumentResponse.redirectInfo.url)
    if (payoutData?.status == "success") {
      loader.hide();
      //   navigate('/', { replace: true })
      //   navigate(payoutData.results.data.instrumentResponse.redirectInfo.url,{ replace: true })
      task.phonePay_paymentstatus = "Initiate"
      window.location.href = await payoutData.results.data.instrumentResponse
        .redirectInfo.url;
      setAmount(0);
      setNumber();
     // setPhno();
    } else {
      loader.hide();

      configStore.setModalMessage(payoutData.error);
      //dtactionStore.current.paymentAmt = 0;
      setAmount(0);
      setNumber();

      //setPhno();
    }
    // await Api.post(url,paymentData, (data) => {
    //   console.log("data",data)
    //   dtactionStore.setModalMessage("Payment has been done successfully..");
    //   // if (!data.branch.length > 0) {
    //   //   branchStore.BranchRec.set(new BranchType());
    //   //   let branchh = branchStore.BranchRec;
    //   //   branchh.country = data.entityCountry;
    //   //   data.branch.push(branchh);
    //   // }
    //   // branchStore.BranchRec.set(BranchType)

    //   //EntityV2Store.EntityRec.set(data);

    // });
  };

  const handleChange = (event) => {
    const { value } = event.target;
  };

  return (
    <div
      className={modalClass}
      // onClick={() => {
      //   dtactionStore.modalVisible1 = false;
      // } }
    >
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ borderRadius: "10px" }}
      >
        <section className="modal-card-body">
          <Cols>
            <Col3 style={{ color: "darkblue" }}>Payment Amount :</Col3>
            <Col5>
              <div class="field">
                <div
                  class="input-d-wrapper"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    position: "relative",
                  }}
                >
                  <input
                    className="input"
                    type="number"
                    value={task.totalAmount}
                    onChange={(event) => checkValue(event, "change")}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    disabled
                  />
                  <div
                    style={{
                      width: "70px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#1B6B93",
                      color: "#fff",
                      border: "0 10px 10px 0",
                      position: "absolute",
                      outline: "2px solid #1B6B93",
                      cursor: "pointer",
                    }}
                  >
                    INR
                    {/* <img
                      src="./assets/chevron-white.png"
                      alt="chevron"
                      class="chevron-3"
                    /> */}
                    {/* <div class="currency-div d-none">
                      <ul>
                        <li>INR</li>
                        <li>USD</li>
                        <li>DNR</li>
                        <li>EUR</li>
                        <li>DIR</li>
                        <li>QSD</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col5>
          </Cols>
          {/* <Cols>
            <Col3 style={{ color: "darkblue" }}>Phone Number:</Col3>
            <Col5>
              <div class="field">
                <PhoneNoField
                  mandatory={false}
                  //label={"Phone Number"}
                  label_class={"label_styles"}
                  input_class={"inputBox_styles"}
                  input_wrapeer={"input_wrapeer"}
                  placeholder={"Enter Phone Number"}
                  errorsmsg={error?.phone}
                  //value={phno}
                  // maxLength={12}
                  callback={(e, code) => {
                    console.log(e, code);
                    // console.log(e?.substring(code?.length+1));
                    const onlyPhoneNo = e?.replace("+" + code, "");
                    console.log(onlyPhoneNo);
                    setPhno(onlyPhoneNo);
                    // console.log("Extract ony ", e?.replace("+" + code, ""))
                    // setValue("phone", onlyPhoneNo);
                  }}
                  // children={{...register("phone")}}
                />
              </div>
            </Col5>
          </Cols> */}

          <br></br>
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={async () => {
              makePayment({});
            }}
            // type="submit" value={dtactionStore.currentMainComponent = <HomeMain/>}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={() => (
              (dtactionStore.modalVisible1 = false),
              setAmount(0)
            )}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}
