import { proxy } from 'valtio'
import Utils from '../util/Utils'
import {CargoDetails} from '../state/Types'

export const DtStore = proxy({
    message: null,
    MapUrl:"",
    DT: null,
    isViewParticular : false,
    DTBooking: null,
    valid: false,
    bview: "create",
    btabview:"Shipments In-Progress",
    view: "",
    modalVisible:false,
    modalVisible1:false,
    modalVisible2:false,
    initiateAdvancemodal:false,
    initiateBalancePaymentmodal:false,
    stoptrackingmodal:false,
    cancelid:"",
    updatenumer:{
        number:"",
        message:""
    },
    cargoValdationMap: {
        nonStackable:true,
        quoteClass:true,
        packingGroup:true,
        unnumber:true,
        cargo_details:{}
    },
    validate: (key, index) => {
        let cargo = DtStore.DTBooking;
        
        switch(key){
            case 'quoteClass': {
                if(cargo.hazardous === 'Yes'){
                    DtStore.cargoValdationMap[key] = cargo.quoteClass !== '';
                }
                break;
            }
            case 'packingGroup': {
                if(cargo.hazardous === 'Yes'){
                    DtStore.cargoValdationMap[key] = cargo.packingGroup !== '';
                }
                break;
            }
            case 'unnumber': {
                if(cargo.hazardous === 'Yes'){
                    DtStore.cargoValdationMap[key] = cargo.unnumber !== '';
                }
                break;
            }
            case 'packageType': {
                DtStore.cargoValdationMap['cargo_details'][index+'packageType'] = cargo.cargo_details[index].packageType !== '';
                break;
            }
            case 'numPackages': {
                DtStore.cargoValdationMap['cargo_details'][index+'numPackages'] = cargo.cargo_details[index].numPackages > 0;
                break;
            }
            case 'totalWeight': {
                DtStore.cargoValdationMap['cargo_details'][index+'totalWeight'] = cargo.cargo_details[index].totalWeight > 0;
                break;
            }
            case 'length': {
                DtStore.cargoValdationMap['cargo_details'][index+'length'] = cargo.cargo_details[index].length > 0;
                break;
            }
            case 'width': {
                DtStore.cargoValdationMap['cargo_details'][index+'width'] = cargo.cargo_details[index].width > 0;
                break;
            }
            case 'height': {
                DtStore.cargoValdationMap['cargo_details'][index+'height'] = cargo.cargo_details[index].height > 0;
                break;
            }
            default: 
                if(cargo.hazardous === 'Yes'){
                    DtStore.cargoValdationMap.quoteClass = cargo.quoteClass !== '';
                    DtStore.cargoValdationMap.packingGroup = cargo.packingGroup !== '';
                    DtStore.cargoValdationMap.unnumber = cargo.unnumber !== '';
                }else {
                    DtStore.cargoValdationMap.quoteClass = true;
                    DtStore.cargoValdationMap.packingGroup = true;
                    DtStore.cargoValdationMap.unnumber = true;
                }
        }
    },
    isValid: () => {
        let isValid;
        DtStore.cargoValdationMap.cargo_details = {};
        for (let index = 0; index < DtStore.DTBooking.cargo_details.length; index++) {
            let a = Object.keys(DtStore.DTBooking.cargo_details[index])
            for (let i = 0; i < a.length; i++) {
                DtStore.validate(a[i], index)
            }
            isValid = Object.values(DtStore.cargoValdationMap['cargo_details']).every((check) => check);
            if(isValid){
                if(DtStore.DTBooking.hazardous === 'Yes'){
                    if(DtStore.cargoValdationMap['quoteClass'] && DtStore.cargoValdationMap['packingGroup'] && DtStore.cargoValdationMap['unnumber']){
                        isValid = true;
                    }else{
                        isValid = false;
                    }
                }
            }
        }
        return isValid;
    },
    new: () => {
        return {
            "pricing_type": "vehicle",
            "tc": ["It is understood that the Owner has agreed and accepted all the terms & conditions mentioned with the goods consignment note issued & handed over.",
                "Handling charges (if any) will be applicable At Actuals",
                "Virya Logistics Technologies Private Limited (the “Company”) is not responsible for any breakage/ leakage/ damage to the goods in transit due to improper packaging/ loading/stacking of the material. No certificate will be issued by the Company, for such events.",
                "All liquids, oil, ghee, fruits and vegetables will be carried at Owner’s Risk only. Dangerous and Inflammable goods will not be carried by the Company. The Company shall not be responsible or liable for any goods declared as contraband by any Government Authority. The owner shall be total liable in such cases and shall indemnify the company for any loss, costs, damages etc., thereof.",
                "Owner is fully responsible for all consequences of any incorrect or false declarations/ documentations, while dispatching the goods. The Owner will take every precaution and be responsible to comply with the customer/ government regulations in force since, the Company is unaware about the nature of contents or their condition, packaging and the value of goods specified in the consignment note.",
                "The Company shall cause/ arrange/ facilitate transport the goods through any external carrier for transport, and such external carrier or its agent shall be solely responsible for the safe delivery of the goods to its destination. The Company shall not be liable in any manner in this regard.",
                "Delivery of the goods to the consignee will be made as soon as reasonably possible in the same order and condition as received from the Owner but, the Company will not be responsible or liable for any delay, damage, breakage, pilferage, evaporation, effects of temperature, weather conditions, strikes, lockouts, riots, civil or political disturbance, explosion, theft, fire or accident that results in any damage or destruction of the goods. The Company will not be responsible for any delay, damages or losses to the goods in case the vehicle carrying the material has been seized or detained or confiscated by appropriate Governmental Authorities.",
                "The maximum liability of the Company under this Contract shall not exceed INR 25,000.",
                "The goods should be unloaded by the Owner within 24 hours after arrival at the destination and failing to do so will entail the Owner to be liable for demurrage/ detention charges. No enquiry claim will be entertained by the Company regarding shortages/ damages/ leakages after 24 hours of the successful delivery of goods.",
                "In case of any claim regarding damages or shortages of the material, the same should be notified by the Owner to the Company in writing within 24 hours of delivery time, failing which the claim will be considered null and void.",
                "The goods will be delivered against all the charges to the Owner. In case the weight exceeds the specified value, the Owner will be liable to pay freight for extra (additional) weight and penalties at the check posts, if any, in addition to the earlier approved freight charges.",
                "In case the goods are required to be re-called due to any reason, the Company will be bound to carry the material against full freight charges.",
                "All Disputes will be subject to the exclusive jurisdiction of the courts/ tribunals in Chennai, India.",
                "Loading and Unloading of the cargo from the Vehicle are not under the scope of work of The Company.",
                "Onus of obtaining Transit Insurance is the responsibility of the Customer",
                "These commercials are exclusive of any applicable Taxes/ GST",
                "These conditions are subject to change without any prior notice, at the discretion of the Company.",
                "The Owner is liable to go through the above Terms & Conditions of the Company carefully before dispatching any of its goods."],
            gst: "",
            entityId: "",
            valid_upto: "",
            relationship_manager: "",
            commodity_type: "",
            multipickup:"No",
            details: [{
                pickup: "",
                pickup_2:"",
                delivery: [{ address: "" }],
                currency: "INR",
                delivery_ap: "No",
                vehicle: [
                    {
                        vehicle_type: "",
                        vehicle_amount: "",
                    }
                ]
            }],
            ctc: ""
        }
    },
    clone: (data) => {
        let l = {
            "pricing_type": data.pricing_type,
            "tc": data.tc,
            gst: data.gst,
            entityId: data.entityId,
            branchId: data.branchId,
            valid_upto: "",
            relationship_manager: data.relationship_manager,
            commodity_type: data.commodity_type,
            ctc: data.ctc,
            details: []
        }
        if(data['multipickup'] === undefined){
            data['multipickup'] = ""
        }
        l["multipickup"] = data['multipickup']
        for (let i = 0; i < data.details.length; i++) {
            let a = data.details[i]
            let k = {}
            if(a['pickup_2'] === undefined){
                a['pickup_2'] = ""
            }
            k['pickup'] = a['pickup']
            k["pickup_2"] = a['pickup_2']
            k['delivery'] = []
            for (let j = 0; j < a['delivery'].length; j++) {
                k['delivery'].push(
                    {
                        address: a['delivery'][j]['address']
                    }
                )
            }
            k['vehicle'] = []
            for (let j = 0; j < a['vehicle'].length; j++) {
                if (data.pricing_type === 'vehicle') {
                    k['vehicle'].push(
                        {
                            vehicle_type: a['vehicle'][j]['vehicle_type'],
                            vehicle_amount: a['vehicle'][j]['vehicle_amount']
                        }
                    )
                } else {
                    k['vehicle'].push(
                        {
                            vehicle_type: a['vehicle'][j]['vehicle_type'],
                            amt_per_ton: a['vehicle'][j]['amt_per_ton'],
                            minimum: a['vehicle'][j]['minimum'],
                        }
                    )
                }
            }
            k['currency'] = a['currency']
            k['delivery_ap'] = a['delivery_ap']
            l.details.push(k)
        }
        return l
    },
    createbooking: (data,contract_id=null) => {
        let d = {}
        d['bookingversion']="v2"
        d['drivername'] = ""
        d['drivernumber'] = ""
        d['driverstatus'] = ""
        d['tripId'] = ""
        d['publiclink'] = ""
        d['tripstatus'] = ""
        d['details'] = []
        if(contract_id){
            for (let i = 0; i < data.details.length; i++) {
                if(data.details[i]['contract_id']===contract_id){
                    d['details'].push(data.details[i])
                }
            }
        }else{
            let l = {}
            if(data['pickup_2'] === undefined){
                data['pickup_2'] = ""
            }
            l["contract_id"] = data._id
            l["pickup"] = data.pickup
            l["pickup_2"] = data['pickup_2']
            l["delivery"] = data.delivery
            l["delivery_ap"] = data.delivery_ap
            l["currency"] = data.currency
            l["vehicle"] = data.vehicle
            d['details'].push(l)
        }
        d['pickupdate'] = "" 
        d['branchId'] = data.branchId
        d['reportingfrom'] = "08:00 AM" 
        d['reportingto'] = "07:00 PM"
        d['commodity_type'] = data.commodity_type
        d['ctc'] = data.ctc
        d['gst'] = data.gst
        d['entityId'] = data.entityId
        d['pricing_type'] = data.pricing_type
        d['relationship_manager'] = data.relationship_manager
        d['valid_upto'] = data.valid_upto
        d['tc'] = data.tc
        d['vehicle'] = d['details'][0].vehicle[0].vehicle_type
        d['vehicle_no'] = ""
        if (data.pricing_type !== "vehicle") {
            d["totel_weight"] = ""
            d["weight_type"] = "tons"
        }
        for (let i = 0; i < d['details'][0]['delivery'].length; i++) {
            d['details'][0]['delivery'][i]["ewaybill"] = ""
            d['details'][0]['delivery'][i]["currency"] = ""
            d['details'][0]['delivery'][i]["cargo_Value"] = ""
            d['details'][0]['delivery'][i]["status"] = "Yet to Commence"
            d['details'][0]['delivery'][i]["lorryreceipt"] = ""
            d['details'][0]['delivery'][i]["proofofdelivery"] = ""
            d['details'][0]['delivery'][i]["ewaybillno"] = ""
            d['details'][0]['delivery'][i]["ewaybilldate"] = ""
            d['details'][0]['delivery'][i]["lorryrecieptNo"] = ""
            d['details'][0]['delivery'][i]["pod_date"] = ""
            d['details'][0]['delivery'][i]["delivery_contact"] = [{ contactperson: "", contactnumber: "", email: "" }]
        }
        d['pickupappointments'] = [{ contactperson: "", contactnumber: "", email: "" }]
        d['pickup2appointments'] = [{ contactperson: "", contactnumber: "", email: "" }]
        d['specialinstruction'] = ""
        d['required_documents1'] = [{ name: "Commercial Invoice (Non-Mandatory)", filename: "" }]
        d['required_documents2'] = [{ name: "Packing List (Non-Mandatory)", filename: "" }]
        d['customer_ref_no'] = ""
        d['erp'] = ""
        d['status'] = "Yet to Commence"
        d['bookingdate'] = Utils.yyyymmdd(new Date())
        d['vendor_assigned'] =""
        d['vendor_agreed_cost'] =""
        d['additional_cost_reason'] =""
        d['vendor_additional_cost'] =""
        d['vender_invoice'] =""
        d['relationship_manager'] =""
        d['customer_success'] =""
        d['operations_representative'] =""
        d['total_cost'] =""
        d['invoice'] =""
        d['cargo_details']= [new CargoDetails()]
        d['cargoDimensionUnit']='Cms'
        d["nonStackable"]='No'
        d["hazardous"]='No'
        d["quoteClass"]=''
        d["packingGroup"]=''
        d["unnumber"]=''
        return d
    },

    clonebooking: (data) => {
        let d = {}
        d['bookingversion']="v2"
        d['drivername'] = ""
        d['drivernumber'] = ""
        d['driverstatus'] = ""
        d['tripId'] = ""
        d['publiclink'] = ""
        d['tripstatus'] = ""
        d['details'] = data.details
        d['pickupdate'] = "" 
        d['reportingfrom'] = "08:00 AM" 
        d['reportingto'] = "07:00 PM"
        d['commodity_type'] = data.commodity_type
        d['ctc'] = data.ctc
        d['gst'] = data.gst
        d['branchId'] = data.branchId
        d['entityId'] = data.entityId
        d['pricing_type'] = data.pricing_type
        d['relationship_manager'] = data.relationship_manager
        d['valid_upto'] = data.valid_upto
        d['tc'] = data.tc
        d['vehicle'] = data.vehicle
        d['vehicle_no'] = ""
        if (data.pricing_type !== "vehicle") {
            d["totel_weight"] = data.totel_weight
            d["weight_type"] = data.weight_type
        }
        for (let i = 0; i < d['details'][0]['delivery'].length; i++) {
            d['details'][0]['delivery'][i]["ewaybill"] = ""
            d['details'][0]['delivery'][i]["status"] = "Yet to Commence"
            d['details'][0]['delivery'][i]["lorryreceipt"] = ""
            d['details'][0]['delivery'][i]["proofofdelivery"] = ""
            d['details'][0]['delivery'][i]["ewaybillno"] = ""
            d['details'][0]['delivery'][i]["ewaybilldate"] = ""
            d['details'][0]['delivery'][i]["lorryrecieptNo"] = ""
        }
        d['pickupappointments'] = data.pickupappointments
        if(data.pickup2appointments && data.pickup2appointments.length>0){
            d['pickup2appointments'] = data.pickup2appointments
        }
        d['specialinstruction'] = data.specialinstruction
        d['required_documents1'] = [{ name: "Commercial Invoice (Non-Mandatory)", filename: "" }]
        d['required_documents2'] = [{ name: "Packing List (Non-Mandatory)", filename: "" }]
        d['customer_ref_no'] = data.customer_ref_no
        d['erp'] = ""
        d['status'] = "Yet to Commence"
        d['bookingdate'] = Utils.yyyymmdd(new Date())
        d['vendor_assigned'] =""
        d['vendor_agreed_cost'] =""
        d['additional_cost_reason'] =""
        d['vendor_additional_cost'] =""
        d['vender_invoice'] =""
        d['relationship_manager'] =""
        d['customer_success'] =""
        d['operations_representative'] =""
        d['total_cost'] =""
        d['invoice'] =""
        data && data.cargo_details !== undefined ? d['cargo_details'] = data.cargo_details : d['cargo_details'] = [new CargoDetails()];
        data && data.cargoDimensionUnit !== undefined ? d['cargoDimensionUnit'] = data.cargoDimensionUnit : d['cargoDimensionUnit']='Cms';
        data && data.nonStackable !== undefined ? d['nonStackable'] = data.nonStackable : d['nonStackable'] = 'No';
        data && data.hazardous !== undefined ? d['hazardous'] = data.hazardous : d["hazardous"] = 'No';
        data && data.quoteClass !== undefined ? d['quoteClass'] = data.quoteClass : d["quoteClass"] = '';
        data && data.packingGroup !== undefined ? d['packingGroup'] = data.packingGroup : d['packingGroup'] = '';
        data && data.unnumber !== undefined ? d['unnumber'] = data.unnumber : d['unnumber'] ='';
        
        return d
    },

})

