import { useSnapshot } from "valtio";
import { configStore } from "../state/CommonState";
import DtBooking from "./DtBooking";
import DtContractList from "./DtContractList";

export default function DtContractMain() {
    const a = useSnapshot(configStore)
    switch(a.dtCurrentView) {
        case 'list': return <DtContractList />
        case 'Booking': return <DtBooking />
    }

}