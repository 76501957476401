import { containerTypeList, containerTypeListTC } from "./StaticData"
import { TableSelect, AgInput, AgSelect } from "./components/AgComponents"
import { TableEditWithValue } from './components/AgCustomComponents'
import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import {
    quoteDataRec, containerStore, configStore, fclChargesStore
} from './state/CommonState'
import { ContainerValidationStore } from "./state/validation-stores"
import Utils from "./util/Utils"

export default function ContainerComp() {
    let [space, setSpace] = useState('')
    const imContainerStore = useSnapshot(containerStore)
    const a = imContainerStore.newRec
    const b = imContainerStore.editRec
    const imQuoteDataRec = useSnapshot(quoteDataRec)
    let typeList = containerTypeList
    const [stuffingType, deStuffingType] = Utils.getStuffingAndDeStuffingType(quoteDataRec.shipmentType);
    if (quoteDataRec.temperatureControlled === 'Yes') typeList = containerTypeListTC
    // else if (stuffingType !== 'Factory' || deStuffingType !== 'Factory') typeList = containerTypeList.slice(0, 4)
    const deleteRec = (id) => {
        const index = containerStore.list.findIndex(e => e.id === id)
        let records = containerStore.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        containerStore.list = records
        //console.log('AFTER DELETE=', JSON.stringify(containerStore.cargoList))
    }

    const Th2 = (props) => <th rowSpan="2" {...props} style={{ paddingTop: '1.5rem' }}>{props.children}</th>
    const Ts = ({ i, name, dataList, ...otherProps }) => <TableSelect
        record={containerStore.list[i]} name={name} dataList={dataList}  {...otherProps} />
    const Te = ({ i, name }) => <TableEditWithValue record={containerStore.list[i]} name={name} />
    const Tn = ({ i, name, ...otherProps }) => <TableEditWithValue record={containerStore.list[i]} name={name} type='number'  {...otherProps} />
    const Tsn = ({ name, dataList }) => <TableSelect
        record={containerStore.newRec} name={name} dataList={dataList} />
    const Ten = ({ name }) => <TableEditWithValue record={containerStore.newRec} name={name} />
    function Tnn({ name }) {
        return <TableEditWithValue record={containerStore.newRec} name={name} type='number' />
    }
    const _tnn = (name) => {
        return { record: containerStore.newRec, name, type: 'number' }
    }
    return (
        <>
            <hr />
            <div className="table-container" onClick={(e) => e.stopPropagation()}>
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='5'>
                                <h3 className="is-size-5 has-text-centered">Container Requirements{space}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Container Type</th>
                            <th># of Containers</th>
                            <th style={{ width: '10rem' }}>Cargo Weight per Container (kgs)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            containerStore.list.map((e, i) => {
                                /*if (e.id !== imcontainerStore.editCargoId)*/ {
                                    return <tr key={i + 1}>
                                        <td >{i + 1}</td>
                                        <td><Ts i={i} name='containerType' dataList={typeList} isValid={ContainerValidationStore.isValidContainerValue('containerType', i)} /></td>
                                        {/* <td><Tn i={i} name='numContainers' isValid = {ContainerValidationStore.isValidContainerValue('numContainers',i)}/></td> */}
                                        {/* <td><Tn i={i} name='wtPerContainer' isValid = {ContainerValidationStore.isValidContainerValue('wtPerContainer',i)}/></td> */}
                                        <td><TableEditWithValue record={containerStore.list[i]} name='numContainers' type='number' isValid={ContainerValidationStore.isValidContainerValue('numContainers', i)} /></td>
                                        <td><TableEditWithValue record={containerStore.list[i]} name='wtPerContainer' type='number' isValid={ContainerValidationStore.isValidContainerValue('wtPerContainer', i)} /></td>
                                        <td>
                                            <div className="field has-addons">
                                                <p className="control">
                                                    <button className="button is-danger is-inverted"

                                                        onClick={() => {
                                                            deleteRec(e.id)
                                                            setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        <tr style={{ border: '2px solid grey' }}>
                            <td ></td>
                            <td><Tsn name='containerType' dataList={typeList} /></td>
                            <td><TableEditWithValue {..._tnn('numContainers')} /></td>
                            <td><TableEditWithValue {..._tnn('wtPerContainer')} /></td>
                            <td>
                                <div className="field has-addons">
                                    <p className="control">
                                    <button
                                            // title={containerStore.list.some(container => container.containerType === containerStore.newRec.containerType) ? "same container type is not allowed" : ''}
                                            disabled={containerStore.newRec.containerType === "" || containerStore.newRec.numContainers <= 0 || containerStore.newRec.wtPerContainer <= 0 } className="button is-success is-inverted"
                                            onClick={() => {
                                                let obj = containerStore.newRec
                                                obj = { ...obj }
                                                if (!containerStore.list.some(container => container.containerType === containerStore.newRec.containerType)) {
                                                    containerStore.list.push({ ...obj, id: containerStore.list.length + 1,unit:'Kgs' })
                                                    fclChargesStore.defaultValueCalc();  // Need to calculate for validation
                                                    containerStore.resetNewRec()
                                                    setSpace(space === '' ? ' ' : '')
                                                }else{
                                                    configStore.setModalMessage("Same ContainerType Is Not Allowed.")
                                                }
                                            }}>
                                            <span className="icon is-small">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </button>
                                    </p>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
                {/* <button onClick={() => {
                    console.log('List = ', JSON.stringify(containerStore.list))
                }}>Check Data</button> */}
            </div>
        </>
    )
}
