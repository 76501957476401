import Utils from "../util/Utils"

export function TableLine(props) {
    let { label, value } = props
    if (value) value = Utils.reformatSuffixedDate(value)
    if (value) {
        return (
            <tr>
                <td style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '10px',width:'50%' }}>{label}</td>
                <td style={{overflowWrap: 'anywhere'}}>{value}</td>
            </tr>
        )
    }
    return <></>
}