import { useSnapshot } from "valtio"
import { useState, useEffect } from "react"
import on from './on2.png'
import off from './off.png'
import { enquiryStore } from "../enquiry/enquiry-data"


export function Agfacdoc(props) {

    const { label, record, name, callback = f => f, isDisabled = false } = props
    // const imRecord = useSnapshot(record?record:{})
    useSnapshot(record)
    const [checked1, setChecked] = useState(record ? record[name] : props.value)
    //console.log('rendering agfacdoc again', checked1, record[name])
    useEffect(() => {
        setChecked(record[name])
    }, [record[name]])
    return (
        <table style={{border: '1px solid white', borderRadius: '13px', fontSize: '0.8rem', cursor : isDisabled ? "not-allowed" : "default"}}>
            <tr style={{border: 'none', padding: '0px'}}>
                <td style={{border: 'none', textAlign: 'center', padding: '0px'}} colSpan={3}>
                    <strong>{label}</strong>
                </td>
            </tr>
            <tr style={{border: 'none'}}>
                {checked1 === 'Factory' ?<td style={{border: 'none', padding: '0px', 
                    textAlign: 'right', fontStyle: 'italic',fontWeight:"bold"}}>Factory</td> : <td style={{border: 'none', padding: '0px', 
                    textAlign: 'right', fontStyle: 'italic'}}>Factory</td>
                }
                <td style={{border: 'none', width: '2rem', paddingTop: '3px', paddingLeft: '2px', paddingRight: '0px'}}>
                    <div style={{width: '1.7rem'}} onClick={ isDisabled ? null : () => {
                        
                         enquiryStore.current.containerDetails = [{containerType:"",numContainers:0,unit:"Kgs",wtPerContainer:0}]
                        if (props.disabled) return
                        const newval = checked1 === 'Dock' ? 'Factory' : 'Dock'
                        if (props.record) record[name] = newval
                        callback(newval)
                        setChecked(newval)
                    }}>
                        {
                            (checked1 === 'Dock') ?
                                <img height='36px' width='36px' src={on} ></img> :
                                <img height='36px' width='36px' src={off}></img>
                        }
                    </div>
                </td>
                {(checked1 === 'Dock') ?<td style={{border: 'none', padding: '0px', textAlign: 'left', fontStyle: 'italic',fontWeight:'bold'}}>CFS</td>:<td style={{border: 'none', padding: '0px', textAlign: 'left', fontStyle: 'italic'}}>CFS</td>}
            </tr>
        </table>
    )
    {/*<div className="columns">
            <div className="column is-12">{label}</div>
            <div style={{ display: 'flex'}}>
                <div>Factory</div>
                <div style={{width: '36px', height: '36px', flexShrink: '0'}} onClick={() => {
                    if (props.disabled) return
                    const newval = checked1 === 'CFS' ? 'Factory' : 'CFS'
                    if (props.record) record[name] = newval
                    callback(newval)
                    setChecked(newval)
                }}>
                    {
                        (checked1 === 'CFS') ?
                            <img height='36px' width='36px' src={on}></img> :
                            <img height='36px' width='36px' src={off}></img>
                    }
                </div>
                <div>CFS</div>
            </div>
                </div>*/}
}