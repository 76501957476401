import { useSnapshot } from "valtio";
import { configStore, loginStore } from "../state/CommonState";
import DtBooking from "./DtBooking";
import DtBookingList from "./DtBookingList";
import DtBookingListClient from "./DtBookingListClient";

export default function DtBookingMain() {
    const a = useSnapshot(configStore)
    useSnapshot(loginStore)
    switch(a.dtCurrentView) {
        case 'list': return loginStore.isClient()?<DtBookingListClient />:<DtBookingList />
        case 'Booking': return <DtBooking />
    }

}