import { useSnapshot } from "valtio";
import HomescreenCargo from "./homescreen-cargo";
import HomescreenContainer from "./homescreen-container";
import HomescreenRoute from "./homescreen-route";
import Agproducttab from "../components/Agproducttab";
import { useEffect, useState } from "react";
import { EntityV2Store, aguserStore, configStore, contractsStore, enquiriesValidationStore, entityStore, loginStore, modalStore } from "../state/CommonState";
import AgCustlist from "../components/AgCustlist";

import EnquiriesApi from "../state/enq-api";
import EnquiryConfirmationv2 from "../enquiryv2/enquiry-confirmation-v2";
import { enquiryStorev2 } from "../enquiryv2/enquiry-data-v2";
import HomescreenRoutev2 from "../enquiryv2/homescreen-route-v2";
import HomescreenContainerv2 from "../enquiryv2/homescreen-container-v2";
import HomescreenCargov2 from "../enquiryv2/homescreen-cargo-v2";
import { Cargo, enquiryStore } from "./enquiry-data";
import { useGlobalState } from "../GlobalState";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import QuoteCalcV2 from "../util/quote-calc-v2";
import Api from "../state/Api";
import { loader } from "../util/loader";
import QuotationUtils from "../util/quotationUtil";
import SchApi from "../state/sch-api";
import Utils from "../util/Utils";
import EnquiryConfirmation from "./enquiry-confirmation";
import AgCustlist1 from "../components/AgCustlist1";
import { Agyesno } from "../components/Agyesno";
import EntityApi from "../state/entity-api";
import { EntityType } from "../state/Types";
import { taskStore } from "../mytasks/task-store";
import ActrejectModal6 from "./enquirysuggestion";
import { ServerLists, lclWeightForPort, maxDimensions, maxWeightPerContainerFcl, maxcargoWeightPerContainer, weightPerPackage } from "../StaticData";
import { AgSelectCountry } from "../components/AgComponents";

export default function AgEnquiry(props) {
    const { currentMenu } = useGlobalState();
    console.log('INSIDE HOMESCREEN LCL')
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(modalStore)
    useSnapshot(loginStore)
    // useSnapshot(entityStore)
    const rec = contractsStore.current

    let client = loginStore.isClient()
    let isUserAuthorized = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CONVERT_QUOTATIONS_TO_CONTRACTS)

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [fclSchedules, setFclSchedules] = useState([])
    const [activeProduct, setActiveProduct] = useState('LCL')
    const [isChecked, setChecked] = useState(false);
    const [Countries,setCountries] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)
   
    useEffect(()=>{
        // let s = ServerLists.countryNameList.filter((e)=> e != "India")
        let s = ServerLists.countryNameList
        setCountries(s)
    },[])

    useEffect(()=>{
        setChecked(false)
    },[activeProduct])
  
    let productList = ['LCL', 'FCL', 'Air']
    let e = enquiryStore.current
    if (loginStore.isClient() && contractsStore.current.isFromEnquiry && e.tsClientviewOn == undefined) {
        e.tsClientviewOn = Date.now()
        let id = contractsStore.current._id
        if (id.endsWith('C')) {
            id = id.substr(0, id.length - 1)
        }
        EnquiriesApi.updateToClientView(id)
    }


    if (contractsStore.current['mode'] == 'CONTRACT' && contractsStore.current['quoteStatus'] != 'DECLINED' && contractsStore.current['quoteStatus'] != 'ACCEPTED') {
        contractsStore.current['quoteStatus'] = "ACCEPTED"
    }
    const _params = (label, name, type = "text", dataList = [],isChecked) => {
        return {
          type,
          name,
          label,
          record: enquiryStore,
          dataList,
          isChecked
        };
      };
      useEffect(()=>{

      },[configStore.currentSelectedEntity])


    const NmO_onClick = () => {
        // alert("ok")
        let rec = enquiryStore.current
        // console.clear()
        // console.log("ccccccc",rec)
        let stype = rec.shipmentType.substr(0, 3).toLowerCase()
        let scope = rec.shipmentScope
        if(rec.checkAgCustomer == 'Yes' && (rec.gst == '' || rec.gst == 'GSTTBA000000000' )){
            configStore.setModalMessage('Select Customer')
                return
        }
        if(rec.checkAgCustomer == 'Yes' && configStore.checkedEmptyDropdown == true){
            configStore.setModalMessage('Select Customer')
                return
        }
        if (rec.hazardous === 'Yes') {
            if (!rec.quoteClass.length) {
                configStore.setModalMessage('Quote class need to be specified')
                return
            }
            if (!rec.packingGroup?.length) {
                configStore.setModalMessage('Packing Group need to be specified')
                return
            }
            if (!rec.unnumber.length) {
                configStore.setModalMessage('UN Number need to be specified')
                return
            }
            if (rec.unnumber.length > 4) {
                configStore.setModalMessage('UN Number length should br below 4 characters')
                return
            }
        }
        if (stype === 'air' && rec.temperatureControlled === 'Yes') {
            rec.minTemperature = parseFloat(rec.minTemperature);
            rec.maxTemperature = parseFloat(rec.maxTemperature);

            if (rec.minTemperature === '' || isNaN(rec.minTemperature) || rec.minTemperature > 30 || rec.minTemperature < -35) {
                configStore.setModalMessage('Minimum Temperature range should be between -35 to 30.')
                return
            }
            if (rec.maxTemperature === '' || isNaN(rec.maxTemperature) || rec.maxTemperature > 30 || rec.maxTemperature < -35) {
                configStore.setModalMessage('Maximum Temperature range should be between -35 to 30.')
                return
            }
            if (rec.minTemperature != 0 && (rec.minTemperature >= rec.maxTemperature)) {
                configStore.setModalMessage('Minimum Temperature should not be greater than Maximum Temperature.')
                return
            }
        }

        if (stype === 'fcl' && rec.temperatureControlled === 'Yes') {

            if (rec.temperature === '' || isNaN(rec.temperature) || rec.temperature < -35 || rec.temperature > 30) {
                configStore.setModalMessage('Temperature range should be between -35 to 30.')
                return
            }
            if (!(rec.cargoValue > 0 && rec.cargoValue < 100000)) {
                configStore.setModalMessage('Cargo value should be within 5 digits')
                return
            }
        }
        if (stype === 'fcl') {
            let con = rec.containerDetails
            if (con.length < 1) {
                configStore.setModalMessage('Container details cannot be empty')
                return
            }
            for (let i = 0; i < con.length; i++) {
                let e = con[i]
                let container = enquiryStore.current.containerDetails.filter(container => container.containerType !== '' ).map((ele) => ele.containerType)
                let uniqueContainer = new Set(container)
                if (container.length !== uniqueContainer.size) {
                    configStore.setModalMessage('Same ContainerType Is Not Allowed.')
                    return false
                }
                if (!e.containerType.length) {
                    configStore.setModalMessage('Containers type need to be specified')
                    return
                }
                if (e.numContainers < 1) {
                    configStore.setModalMessage('Number of containers  need to be specified')
                    return
                }
                if (e.wtPerContainer < 1) {
                    configStore.setModalMessage('Weight per container need to be specified')
                    return
                }
                let maxValue = maxWeightPerContainerFcl[e.containerType][e.unit.toLowerCase()]
                if (parseFloat(e.wtPerContainer) > maxValue) {
                    configStore.setModalMessage(`Weight for ${e.containerType} container should be less than ${maxValue}.`)
                    return;
                }
            }
        }
        let displayCargo4fcl = false
        {
            let e = enquiryStore.current

            if (stype === 'fcl' && e.shipmentScope === 'Port-to-Door') {
                if (e.destuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
                if (e.stuffingType === 'Dock')
                    displayCargo4fcl = true
            }
            else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Port') {
                displayCargo4fcl = true
                if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory')
                    displayCargo4fcl = false
            }else if (stype === 'fcl' && e.shipmentScope === 'Door-to-Door') {
                displayCargo4fcl = false
                if (e.stuffingType === 'Dock' || e.destuffingType === 'Dock')
                    displayCargo4fcl = true
            }else if(stype === 'fcl'){
                displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            }
            console.log('#########>>>>>>>>>', stype, displayCargo4fcl,  e.shipmentScope )
        }
        let car = rec.cargoDetails


        if (stype !== 'fcl' || (stype === 'fcl' && displayCargo4fcl)) {
            for (let i = 0; i < car.length; i++) {
                let e = car[i]
                console.log(e.totalWeight, 'weight1');
                console.log(e.weight, 'weight2');
                if (!e.packageType.length) {
                    configStore.setModalMessage('Package Type cannot be empty')
                    return
                }
                /*if (stype === 'fcl') {
                    if (!e.packageType.length || !e.quantity || !e.weight.value || !e.volume.value) {
                        configStore.setModalMessage('Cargo weight and volume need to be specified')
                        return
                    }
                }*/
                if (stype === 'lcl' && scope === 'Port-to-Port') {
                    if (!e.quantity || parseInt(e.quantity) <= 0) {
                        configStore.setModalMessage('Cargo quantity need to be specified')
                        return
                    }
                    if (!e.totalWeight.value || parseFloat(e.totalWeight.value) <= 0) {
                        configStore.setModalMessage('Cargo weight need to be specified')
                        return
                    }else{
                        if(Number(e.totalWeight.value) > weightPerPackage[stype][e.totalWeight.unit.toLowerCase()]){
                            configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${weightPerPackage[stype][e.totalWeight.unit.toLowerCase()]}.`)
                            return;
                        }
                    }
                    if (!e.totalVolume.value || parseFloat(e.totalVolume.value) <= 0) {
                        configStore.setModalMessage('Total Volume need to be specified')
                        return
                    }


                } else /*if (stype === 'lcl' && scope !== 'Port-to-Port')*/ {
                 
                    if (!e.quantity || parseInt(e.quantity) <= 0) {
                        configStore.setModalMessage('Cargo quantity need to be specified')
                        return
                    }

                    if ((!e.totalWeight || parseFloat(e.totalWeight.value) <= 0) && (!e.weight.value || parseFloat(e.weight.value) <= 0)) {
                        configStore.setModalMessage('Cargo weight need to be specified')
                        return
                    }
                    if (scope != 'Port-to-Port' && (!e.dimensions.length || !e.dimensions.width || !e.dimensions.height || ['length', 'width', 'height'].some(val => parseFloat(e.dimensions[val]) <= 0))) {
                        configStore.setModalMessage('Cargo dimensions need to be specified')
                        return
                    }


                    if(["air","lcl"].indexOf(stype) != -1){
                        if(Number(e.weight.value) > weightPerPackage[stype][e.weight.unit.toLowerCase()]){
                            configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${weightPerPackage[stype][e.weight.unit.toLowerCase()]}.`)
                            return;
                        }
                    }
                    if(["air","lcl"].indexOf(stype) != -1){
                        let maxValue = maxDimensions[stype][rec.cargoDimensionUnit.toLowerCase()]
                        if (parseFloat(e.dimensions['length']) > maxValue['length']) {
                            configStore.setModalMessage(`Length for ${e.packageType} package should be less than ${maxValue['length']}.`)
                            return;
                        }
                        if (parseFloat(e.dimensions['width']) > maxValue['width']) {
                            configStore.setModalMessage(`Width for ${e.packageType} package should be less than ${maxValue['width']}.`)
                            return;
                        }
                        if (parseFloat(e.dimensions['height']) > maxValue['height']) {
                            configStore.setModalMessage(`Height for ${e.packageType} package should be less than ${maxValue['height']}.`)
                            return;
                        }
                }
                    
                    // if (stype === 'air' ? Utils.stdUnits(parseFloat(e.weight.value), e.weight.unit) > 6500 : Utils.stdUnits(parseFloat(e.weight.value), e.weight.unit) > 3000) {
                    //     configStore.setModalMessage(`Weight per ${e.packageType} package should be less than ${stype === 'air' ? 6500 : 3000}.`)
                    //     return;
                    // }
                }
            }
        }

        if(stype==="lcl" ){
            let totalWeight = 0;
            let totalVolume = 0;
            for(let i=0;i<enquiryStore.current.cargoDetails.length;i++){
                let cargo =  enquiryStore.current.cargoDetails[i];
                if( scope === 'Port-to-Port'){
                    totalWeight += parseFloat(cargo?.totalWeight?.value || 0.0);
                    totalVolume += parseFloat(cargo?.totalVolume?.value || 0.0)
                }else{
                    totalWeight += parseFloat(cargo?.weight?.value || 0.0);
                }
            }
            let weightUnit ;
            if(scope === "Port-to-Port"){
                weightUnit = enquiryStore.current.cargoDetails[0].totalWeight.unit;
            }else{
                weightUnit =  enquiryStore.current.cargoDetails[0].weight.unit;
            }
            let volumeUnit = enquiryStore.current.cargoDetails[0].totalVolume.unit;
            let maxWeight = lclWeightForPort["totalweight"][weightUnit.toLowerCase()];
            let maxVolume = lclWeightForPort["totalvolume"][volumeUnit.toLowerCase()]
            // console.log("totalvolume",totalVolume,"totalweight",totalWeight,"maxweight",maxWeight,"maxvoulume",maxVolume,"weightUnit",weightUnit,"volumeunit",volumeUnit,"unitdropdown",configStore.updateUnitDropdown)
            if(configStore.updateUnitDropdown == 'CBM'){
                maxVolume = 30
            }else{
                maxVolume = 1059
            }
            // console.log(">>>>>>",maxVolume,totalVolume)
            if(totalWeight>maxWeight){
                configStore.setModalMessage(`Total weight per shipment can't be greater than ${maxWeight} ${weightUnit}`)
                return;
            }else if( scope === "Port-to-Port" && totalVolume>maxVolume){
                configStore.setModalMessage(`Total volume per shipment can't be greater than ${maxVolume} ${volumeUnit}`)
                return;
            }
           
            // if(configStore.updateUnitDropdown == 'CBM'){
            //     if(scope === "Port-to-Port" && totalVolume>maxVolume){
            //         configStore.setModalMessage(`Total volume per shipment can't be greater than ${maxVolume} ${configStore.updateUnitDropdown}`)
            //         return;
            //     }
            // }else if(configStore.updateUnitDropdown == 'cu ft'){
            //     maxVolume = 1059
            //     if(scope === "Port-to-Port" && totalVolume>maxVolume){
            //         configStore.setModalMessage(`Total volume per shipment can't be greater than ${maxVolume} ${configStore.updateUnitDropdown}`)
            //         return;
            //     }
            // }
        }
if(loginStore.isClient() && stype==="fcl"){
    enquiryStore.current.shipmentType = `FCL (${enquiryStore.current.stuffingType}/${enquiryStore.current.destuffingType})`;
    let stuffingType = enquiryStore.current.stuffingType;
    let destuffingType = enquiryStore.current.destuffingType;
    let origin = enquiryStore.current.shipmentScope.split("-")[0];
       
    let destination = enquiryStore.current.shipmentScope.split("-")[2];
    
        if(!((origin?.toLowerCase()==="door" && stuffingType.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && destuffingType.toLowerCase() === "dock" ) || (enquiryStore.current.shipmentScope!=="Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock") )){
            enquiryStore.current.cargoDetails = [new Cargo()]
        }
}
        configStore.quotationModalVisible4 = true
        
    //     let stat =   EnquiriesApi.saveEnquiry()
    //     EnquiriesApi.getAllEnquiries()
        
    //     if (stat) {setActiveProduct("LCL")
    //     configStore.displaySchedules = false
    //     configStore.showEnquiries = true
    //     contractsStore.current = {}
    //     modalStore.checkingDestination = false
    //     configStore.homescreenCurrentShipmentType = "LCL"
    //     enquiryStore.current.entityName = ""
    //     enquiryStore.current.gst = ""
    // }
     
    
        
    }
    
    let enquiriesValidationStoreSnap = useSnapshot(enquiryStore.current)

    const onClick = () => {
        if(enquiriesValidationStore.origin === false && enquiriesValidationStore.destination === false && enquiriesValidationStore.cargoReadinessDate === false){
            enquiriesValidationStore.cargoReadinessDate = enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate.trim() === ''
            enquiriesValidationStore.origin = enquiriesValidationStoreSnap.enqRoute.origin.trim() === ''
            enquiriesValidationStore.destination = enquiriesValidationStoreSnap.enqRoute.destination.trim() === ''
        }
        enquiryStore.current.isfromAgragaEnquiry = 'Yes'
        if(isChecked == true){
            enquiryStore.current.checkAgCustomer = 'No'
            enquiryStore.current.entityName = "YET TO BE ASSIGNED"
            enquiryStore.current.entityId = "TBA"
            enquiryStore.current.gst = 'GSTTBA000000000'
            enquiryStore.current.state = ''
            enquiryStore.current.createdBy = loginStore.email

        }else{
            enquiryStore.current.checkAgCustomer = 'Yes'
        }

        configStore.displaySchedules = true
        if (props.viewonly) {
            if (activeProduct.toLowerCase() === "fcl" && !enquiryStore.current.containerDetails.every(QuotationUtils.isValidContainer)) {
                configStore.setModalMessage("Invalid Container details.");
                return;
            }
            if (activeProduct.toLowerCase() !== "fcl" || (activeProduct.toLowerCase() === "fcl" && QuotationUtils.hasCargoDetail(enquiryStore.current.shipmentType, enquiryStore.current.shipmentScope))) {
                if (enquiryStore.current.cargoDetails.length === 0 || !enquiryStore.current.cargoDetails.every(cargo => QuotationUtils.isValidCargo(activeProduct, enquiryStore.current.shipmentScope, cargo))) {
                    console.log("🚀 ~ file: home-screen-lcl.js ~ line 170 ~ HomeScreenLCL ~ enquiryStore", enquiryStore.current.cargoDetails)
                    configStore.setModalMessage("Invalid Cargo details.");
                    return;
                }
            }

            contractsStore.current = enquiryStore.current.mergeIntoContract(rec)


        } else {
            Api.getMergedContract()
        }
        configStore.setFullEnquiry = enquiryStore.current

    }


    useEffect(() => {
        if (!configStore.displaySchedules) configStore.showEnquiries = true
        if (props.viewonly || !props.viewonly) {
            (activeProduct === 'LCL') ?
                SchApi.getSchedules(rec._id, setSchedules) :
                SchApi.getSchedules(rec._id, setFclSchedules)
        }
        if (props.viewonly) {
            if (!configStore.displaySchedules) configStore.displaySchedules = true
        }
    }, [rec._id], e.stuffingType, e.destuffingType)
    if (!rec._id && props.viewonly) return <></>
    let contractId = rec._id
    if (rec.mode === 'QUOTE') contractId = contractId.substr(0, contractId.length - 1)
    let title = `Create Booking - ${contractId}`
    if (!props.viewonly) title = 'Enquiries'
    let displayCargo4fcl = false
    
    

    {
        let e = enquiryStore.current
        let stype = e.shipmentType.substr(0, 3).toLowerCase()
        if (stype === 'fcl' && (modalStore.checkingOrigin === false && modalStore.checkingDestination === true)) {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            if (e.destuffingType === 'Dock') {
                displayCargo4fcl = true
            }

        }
        else if (stype === 'fcl' && (modalStore.checkingOrigin === true && modalStore.checkingDestination === false)) {
            displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            if (e.stuffingType === 'Dock') {
                displayCargo4fcl = true
            }
        }
        else if (stype === 'fcl' && (modalStore.checkingOrigin === true && modalStore.checkingDestination === true)) {
            displayCargo4fcl = true
            if (e.stuffingType === 'Factory' && e.destuffingType === 'Factory') {
                displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
            }
        }
       else if(stype === 'fcl' &&(modalStore.checkingOrigin === false && modalStore.checkingDestination === false)){
        displayCargo4fcl = Utils.outGaugeContainerCheck(enquiryStore.current.containerDetails)
        }
        }

    { //assign shipping type to enquiry
        let b = enquiryStore.current
        let e = enquiryStore.current
        let stype = activeProduct.toLowerCase()
        if (stype === 'lcl') {
            e.shipmentType = 'LCL'
        } else if (stype === 'air') {
            // if (e.temperatureControlled === 'Yes')
            //     e.shipmentType = 'Air (Temperature Controlled)'
            // else
            //     e.shipmentType = 'Air (Normal)'
            e.shipmentType = 'Air'
        } else {
            // if (e.temperatureControlled === 'Yes') {
            //     e.shipmentType = 'FCL (Temperature Controlled)'
            // } else {
            //     e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
            // }
            e.shipmentType = `FCL (${e.stuffingType}/${e.destuffingType})`
        }
        console.log('*** SHIPMNET TYPE ***', b,e)
    }
    {
        if (currentMenu == "Create Booking" || currentMenu == "Upload Schedules") {
            if (contractsStore.current._id) {
                let reqdata = {}
                let shipmentType = configStore.homescreenCurrentShipmentType.toUpperCase()

                if (configStore.homescreenCurrentShipmentType == 'LCL') {
                    let currentDate = new Date()
                    let cargoReadinessDate = (contractsStore.current.cargoReadinessDate && contractsStore.current.cargoReadinessDate.length >= 10) ?
                        new Date(contractsStore.current.cargoReadinessDate) : currentDate
                    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                    let startDays = (contractsStore.current.portOfLoading === contractsStore.current.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
                    let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
                    let endDate = Utils.mmddyyyy(Utils.newDate(contractsStore.current.validUpto))
                    reqdata = {
                        shipmentType: shipmentType,
                        startDate: startDate,
                        endDate: endDate
                    }
                }
                if (configStore.homescreenCurrentShipmentType == 'FCL') {
                    reqdata = {
                        shipmentType: shipmentType
                    }
                }
                if (shipmentType == 'LCL' || shipmentType == 'FCL') {
                    Api.getSchedulesList(contractsStore.current._id, reqdata, (data) => {
                        if (data.length > 0) {
                            if (shipmentType == 'LCL' && (schedules.join('') != data.join(''))) {
                                setSchedules(data)
                            }
                            if (shipmentType == 'FCL') {
                                setFclSchedules(data)
                            }
                        }
                    })
                }
            }
        }
    }
    const parent = "agi4"
    var parent_container
    var newHandle = function (e) {
        let parent_container1 = null
        if (parent) {
            parent_container = e.target;
            do {
                parent_container = parent_container?.parentNode;

                if (!parent_container || parent_container === document.body) {
                    break;
                }

                if (parent_container.id != parent) {
                    parent_container1 = null;
                } else {
                    parent_container1 = parent_container;
                }
            } while (parent_container1 == null);

        }
    };
    var newHandle1 = function (e) {
        if (parent && parent_container) {
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if (e.clipboardData.files.length > 0) {
                change(i[0].files)
            }
        }
    };
    if (parent) {
        document.getElementsByTagName('body')[0].onclick = function (e) { newHandle(e) }
        document.getElementsByTagName('body')[0].onpaste = function (e) { newHandle1(e) }

    }

    function change(files) {
        contractsStore.current.hasToShowDummySchedule = false;
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('action', 'schedule_upload')
        formData.append('shipmentType', rec.shipmentType)
        formData.append('id', rec._id)
        fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/common/convertxltojson`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                setSaveEnabled(true)
                if (data.error) { if (data.error != 'No Error' || data.error != 'Invalid Token') { configStore.setModalMessage(data.error) } }
                else {
                    (activeProduct === 'LCL') ?
                        setSchedules(data) :
                        setFclSchedules(data)
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

   
    let disabled = {}
    // useEffect(()=>{
        if(isChecked == true  && enquiryStore.current.gst != 'GSTTBA000000000'){
            // console.clear()
            // console.log("YES")
            enquiryStore.current.gst = ''
            // entityStore.list = b
          
        }else{
            // console.clear()
            // console.log("No")
            // entityStore.list = []
        }
    // },[])
    if(activeProduct == 'LCL'){
        configStore.setFullEnquiry = enquiryStore.current
    }
    if(activeProduct == 'FCL'){
        // enquiryStore.current = configStore.setFullEnquiry
    }
    if(activeProduct == 'Air'){
        // enquiryStore.current = configStore.setFullEnquiry
    }
    
   
    const handleCheckboxChange = () => {
        // window.location.reload()
      setChecked(!isChecked);
    //   if(!isChecked){
    //     entityStore.list = b
    //   }else{
    //     entityStore.list = v
    //   }
    
      
    };
    // let old = enquiryStore.current
   
   
  
    // console.clear()
    // console.log("clear1",enquiryStore.current)
//     console.log("ActiveProduct",configStore.quotationModalVisible4)
//     // console.log(">?>?>",enquiryStore.current,old,configStore.setFullEnquiry)
//    console.log("VVVVVVVVVVVVVVVVv",enquiryStore.current)

    return (
        <>

        <EnquiryConfirmation />
        <ActrejectModal6/>
        <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
            {title}
        </h5>
        {/* <div className="columns">
            <div className="column is-4">
                    <label style={{display:"flex",gap:"1em"}}>
                    <strong>Customer Yet to be Assigned?</strong>
                <input
                id=""
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                />
                
            </label>
            </div>
         
            <div class="control has-icons-left">
{
    (isChecked) ? 
    
    <AgSelectCountry
    {..._params("Countries", "countryFromEnquiry", "select", Countries)}
  />
    
    
    :<></>
}
           
</div>
        </div> */}
        {
            (isChecked == false) ? 
              <AgCustlist1
              record={enquiryStore.current}
              name="gst"
              label="Customer *"
              isChecked={isChecked}
              dataList={EntityV2Store.Cuslist}
                
                // isValid={QuotationValidationStore.quotationValidMap["entity"]}
                validationMsg="- Please select one of customers."
                // isDisabled={
                //   quoteDataRec.quotationNum !== "" ? { disabled: true } : {}
                // }
                callback={(entityId) => {
                    enquiryStore.current.entityId = entityId;
                    enquiryStore.current.createdBy = loginStore.email
                //   QuotationValidationStore.validate("entity");
                //   let eentity = entityStore.list.filter((e) => e.gst === gst);
                
                // if(entity.length > 0){
                //     enquiryStore.current.gst = entity[0].gst;
                //     enquiryStore.current.createdBy = loginStore.email
                //     // enquiryStore.current.entityName = entity[0].entityName
                // }
                }}
              /> 
               : <></>
        }
       
        {
            (!props.viewonly) ?
                <Agproducttab productList={productList}
                    activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
                : <></>
        }
        <div className="columns">
            <div className="column is-4">
                <HomescreenRoute {...props} />
            </div>
            <div className="column is-8">
                {
                    (activeProduct === 'FCL') ?
                        <HomescreenContainer {...props} /> :
                        <HomescreenCargo {...props} isFromUploadSchedules={true} />
                }
            </div>
        </div>
        {/* {
            (displayCargo4fcl) ?
                <div className="columns">
                    <div className="column is-2">
                    </div>
                    <div className="column is-10">
                        <HomescreenCargo {...props}
                         displayCargo4fcl={true}
                          />
                    </div>
                </div> : <></>
        } */}
        {
            (displayCargo4fcl
            ) ?
                <div className="columns">
                    <div className="column is-2">
                    </div>
                    <div className="column is-10">
                        <HomescreenCargo {...props}
                            displayCargo4fcl={true}
                        />
                    </div>
                </div> : <></>
        }
        {/* {
            (!loginStore.isClient() && ['lcl', 'fcl'].includes(activeProduct.toLowerCase())) ?
                <div className="columns">
                    <div className="column is-2">
                    </div>
                    <div className="column is-10">
                        <BookingLocations />
                    </div>
                </div> : <></>
        } */}
        <div className="columns">
            <div className="column is-12">
               
               

              

               
                        <button
                            style={{ float: 'right' }}
                            className="button is-rounded is-small islink"
                            disabled={configStore.buttondisable}
                            onClick={async () => {
                                // configStore.quotationModalVisible4 = true
                                // if (configStore.addressvalidation) {
                                onClick();
                                if (!enquiriesValidationStore.cargoReadinessDate && configStore.addressvalidation && !enquiriesValidationStore.origin && !enquiriesValidationStore.destination) {
                                    await  NmO_onClick()
                                    // configStore.quotationModalVisible4 = true 
                                    // configStore.buttondisable = true
                                   
                                }else {
                                    configStore.setModalMessage('Please check input fields');

                                };

                            }}
                        >
                            Submit
                        </button> 
                       
                

            </div>
        </div >
    </>
    )
}


