import { Autocomplete, Box, FormControl, Grid, Typography } from "@mui/material";
import { CustomStyledTextField } from "./ColoredTextfield";
import { Controller } from "react-hook-form";

export default function LabeledTextField({label,width,multiline,disabled,onKeyDown,disableUnderline,min,onBlur,maxRows,alignItems,height,onChange,control,rules,fontSize,labelStyle,name,endAdornment,variant , textfieldStyles, callback,...rest}) {
    return (
    <FormControl sx={{  width:width || "100%",height:"100%",gap:0 }}>
      <Controller
       control={control}
       name={name}
       rules={rules}    
        render={({field,fieldState}) =>  <Grid container rowSpacing={label?"4px":0}>
        <Grid item xs={12}>
        <Typography style={{textAlign:"start",fontFamily:"Figtree",...labelStyle}}>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
        <CustomStyledTextField
       
      disabled={disabled}
        multiline={multiline||false}
        maxRows={maxRows}
        onKeyDown={(e) =>{ 
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
          if(onKeyDown){
            onKeyDown(e)
          }
         }
         }
        onKeyUp={(e)=>{
          if(callback){
            console.log(e.target.value)
            callback(e.target.value);
          }
       }}
        
       

        InputProps={{
          disableUnderline,
          endAdornment : endAdornment,
          style :{
            backgroundColor: "white",
            fontSize : fontSize || "16px",
            height:height,
            alignItems: alignItems,
            ...textfieldStyles,
          }
        }} sx={{padding:0,margin:0, 
          '& .MuiOutlinedInput-root': {
      padding: "0px !important", 
      paddingRight:"0px !important"
    },
          "& .MuiFormControl-root .MuiTextField-root": {
            display: 'flex',
            alignItems: 'center',
            justifyContent :"center",
            padding:"0px !important"
          },
          "& .MuiInputAdornment-positionEnd": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            fieldset: {
              borderColor: '#2C358A !important', // Default focused color
            },
          },
          "&.Mui-error": {
            "&.Mui-focused": {
              fieldset: {
                borderColor: 'red !important', // Color for focused and error state
              },
            },
          },
          "&.Mui-invalid": {
            fieldset: {
              border: 'red !important', // Default focused color
            },
          },
        },
        }} error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant={variant||"outlined"} {...rest}
        onBlur={(e)=>{
          field.onBlur(e);
          if(onBlur){
            onBlur(e.target.value)
          }
        }}
        onChange={(e)=>{
          if(onChange){
            onChange(e.target.value);
          }
          field.onChange(e);
          if(callback){
            callback(e.target.value);
          }
         
        }}
        size="small" margin="normal" fullWidth/>
        </Grid>
       </Grid>
        
    //     <Box sx={{display:"flex",alignItems:"start",flexDirection:"column",width:"100%"}}>
    //         <>{label}</>
    //         <CustomStyledTextField error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant="outlined" {...rest} size="small" margin="normal" fullWidth/>
    //  </Box>
     }
      />
        </FormControl>
     
    );
  }

  export function LabeledTextField1({label,width,multiline,disabled,onKeyDown,disableUnderline,min,onBlur,maxRows,alignItems,height,onChange,control,rules,fontSize,labelStyle,name,endAdornment,variant , textfieldStyles, callback,...rest}) {
    return (
    <FormControl sx={{  width:width || "100%",height:"100%",gap:0 }}>
      <Controller
       control={control}
       name={name}
       rules={rules}    
        render={({field,fieldState}) =>  <Grid container rowSpacing={label?"4px":0}>
        <Grid item xs={12}>
        <Typography style={{textAlign:"start",fontFamily:"Figtree",...labelStyle}}>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
        <CustomStyledTextField
       
      disabled={disabled}
        multiline={multiline||false}
        maxRows={maxRows}
        onKeyDown={(e) =>{ 
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
          if(onKeyDown){
            onKeyDown(e)
          }
         }
         }
        onKeyUp={(e)=>{
          if(callback){
            console.log(e.target.value)
            callback(e.target.value);
          }
       }}
        
       

        InputProps={{
          disableUnderline,
          endAdornment : endAdornment,
          style :{
            backgroundColor:"white",
            fontSize : fontSize || "16px",
            height:height,
            alignItems: alignItems,
            ...textfieldStyles,
          }
        }} sx={{padding:0,margin:0, 
          '& .MuiOutlinedInput-root': {
      padding: "0px !important", 
      paddingRight:"0px !important"
    },
          "& .MuiFormControl-root .MuiTextField-root": {
            display: 'flex',
            alignItems: 'center',
            justifyContent :"center",
            padding:"0px !important"
          },
          "& .MuiInputAdornment-positionEnd": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            fieldset: {
              borderColor: '#2C358A !important', // Default focused color
            },
          },
          "&.Mui-error": {
            "&.Mui-focused": {
              fieldset: {
                borderColor: 'red !important', // Color for focused and error state
              },
            },
          },
          "&.Mui-invalid": {
            fieldset: {
              border: 'red !important', // Default focused color
            },
          },
        },
        }} error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant={variant||"outlined"} {...rest}
        onBlur={(e)=>{
          e.target.value = e.target.value.substring(0,4)
          field.onBlur(e);
          if(onBlur){
            onBlur(e.target.value)
          }
        }}
        onChange={(e)=>{
          e.target.value = e.target.value.substring(0,4)
          console.log()
          if(onChange){
            onChange(e.target.value);
          }
          field.onChange(e);
          if(callback){
            callback(e.target.value);
          }
         
        }}
        size="small" margin="normal" fullWidth/>
        </Grid>
       </Grid>
        
    //     <Box sx={{display:"flex",alignItems:"start",flexDirection:"column",width:"100%"}}>
    //         <>{label}</>
    //         <CustomStyledTextField error={!!fieldState.error} helperText={fieldState.error?.message} {...field} variant="outlined" {...rest} size="small" margin="normal" fullWidth/>
    //  </Box>
     }
      />
        </FormControl>
     
    );
  }