import { useState } from "react"
import { useSnapshot } from "valtio"
import Newapi from "../../state/new-api"
import AsyncSelect from 'react-select/async';

export default function Dtaddress(props) {
  const { record, name, callback = f => f, isValid = true, validationMsg, isDisabled } = props
  let defaultValue = null
  let defaultOptions = []
  if(record[name].length>0){
    defaultValue = {value:record[name],label:record[name]}
    defaultOptions.push({value:record[name],label:record[name]})
  }
  const getdata = async (inputValue)=>{
    if(inputValue && inputValue.length>2){
      let s = encodeURI(inputValue)
      let url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchaddresslatlong/${s}`
      let results
      results = await Newapi.get(url)
      return results
    }else{
      return []
    }
  }

  const loadOptions = async (
    inputValue,
    callback
  ) => {
    let v = await getdata(inputValue)
    callback(v);
  };

  const change= (e)=>{
    if(e && e.value){
      record[name] = e.value
    }else{
      record[name] = ""
    }
    callback(record[name])

  }

  return (
    <div className="field">
      
      <div className= {!isValid?"control is-fullwidth is-select-danger":"control is-fullwidth"}>
        <AsyncSelect defaultOptions={defaultOptions} defaultValue={defaultValue} isClearable styles={{ menu: (base) => ({ ...base, position: 'relative' }) }} loadOptions={loadOptions} onChange={(val)=>change(val)} />
      </div>
    </div>
  )
}