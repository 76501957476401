import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { configStore } from "../state/CommonState";
import { useEffect, useState } from "react";
import { TsUnloc } from "../components/AgComponents";

export function Rwmodal(){
    useSnapshot(myactionsstore)
    useSnapshot(configStore)
    const [list, setList] = useState([])
    const [list1, setList1] = useState([])
    const [ch, setCh] = useState(true)
    const [rmkeys, setRmkeys] = useState([])
    const [sv, setSv] = useState(0)
    let ret = configStore.modalvisibledata
    if(((list && list.length==0) || (list1 && list1.length==0)) && ch){
        let rm = []
        let rl = []
        let rd = []
        let s = 0
        if(ret[`reworking_pol_port`]!=undefined && ret[`reworking_pol_port`]!=null && ret[`reworking_pol_port`].trim().length > 0){
            let l1 = {}
            s = 1
            l1['port'] = ret[`reworking_pol_port`]
            l1['arrival_estimate'] = ret[`reworking_pol_arrival_estimate`]
            l1['arrival'] = ret[`reworking_pol_arrival`]
            l1['departure_estimate'] = ret[`reworking_pol_departure_estimate`]
            l1['departure'] = ret[`reworking_pol_departure`]
            l1['vessel_name'] = ret[`reworking_pol_vessel_name`]
            l1['vessel_number'] = ret[`reworking_pol_vessel_number`]
            l1['rw_destuffing'] = ret[`reworking_pol_destuffing`]
            l1['rw_restuffing'] = ret[`reworking_pol_restuffing`]
            l1['rw_container_number'] = ret[`reworking_pol_container_number`]           
            rm.push(`reworking_pol_port`)
            rm.push(`reworking_pol_arrival_estimate`)
            rm.push(`reworking_pol_arrival`)
            rm.push(`reworking_pol_departure_estimate`)
            rm.push(`reworking_pol_departure`)
            rm.push(`reworking_pol_vessel_name`)
            rm.push(`reworking_pol_vessel_number`)
            rm.push(`reworking_pol_destuffing`)
            rm.push(`reworking_pol_restuffing`)
            rm.push(`reworking_pol_container_number`)
            rl.push(l1)
        }
        if(ret[`reworking_pod_port`]!=undefined && ret[`reworking_pod_port`]!=null && ret[`reworking_pod_port`].trim().length > 0){
            let l1 = {}
            s = 0
            l1['port'] = ret[`reworking_pod_port`]
            l1['arrival_estimate'] = ret[`reworking_pod_arrival_estimate`]
            l1['arrival'] = ret[`reworking_pod_arrival`]
            l1['departure_estimate'] = ret[`reworking_pod_departure_estimate`]
            l1['departure'] = ret[`reworking_pod_departure`]
            l1['vessel_name'] = ret[`reworking_pod_vessel_name`]
            l1['vessel_number'] = ret[`reworking_pod_vessel_number`]
            l1['rw_destuffing'] = ret[`reworking_pod_destuffing`]
            l1['rw_restuffing'] = ret[`reworking_pod_restuffing`]
            l1['rw_container_number'] = ret[`reworking_pod_container_number`]
            rm.push(`reworking_pod_port`)
            rm.push(`reworking_pod_arrival_estimate`)
            rm.push(`reworking_pod_arrival`)
            rm.push(`reworking_pod_departure_estimate`)
            rm.push(`reworking_pod_departure`)
            rm.push(`reworking_pod_vessel_name`)
            rm.push(`reworking_pod_vessel_number`)
            rm.push(`reworking_pod_destuffing`)
            rm.push(`reworking_pod_restuffing`)
            rm.push(`reworking_pod_container_number`)
            rd.push(l1)
        }

        setRmkeys(rm)
        setList(rl)
        setList1(rd)
        setSv(s)
        setCh(false)
    }


    useEffect(()=>{
        if(myactionsstore.rwModalVisible==true){
            setList([])
            setList1([])
            setRmkeys([])
            setCh(true)
        }
    },[myactionsstore.rwModalVisible])


    const onsubmit3 = () =>{
        console.log(rmkeys)
        let li = Object.keys(ret)
        let lvs = {}
        for (let i = 0; i < li.length; i++) {
            if(rmkeys.indexOf(li[i])==-1){
                lvs[li[i]] = ret[li[i]]
            }
        }
        for (let i = 0; i < list.length; i++) {
            lvs[`reworking_pol_port`] = list[i]['port']
            lvs[`reworking_pol_arrival_estimate`] = list[i]['arrival_estimate']
            lvs[`reworking_pol_arrival`] = list[i]['arrival']
            lvs[`reworking_pol_departure_estimate`] = list[i]['departure_estimate']
            lvs[`reworking_pol_departure`] = list[i]['departure']
            lvs[`reworking_pol_vessel_name`] = list[i]['vessel_name']
            lvs[`reworking_pol_vessel_number`] = list[i]['vessel_number']
            lvs[`reworking_pol_destuffing`] = list[i]['rw_destuffing']
            lvs[`reworking_pol_restuffing`] = list[i]['rw_restuffing']
            lvs[`reworking_pol_container_number`] = list[i]['rw_container_number']
        }
        for (let i = 0; i < list1.length; i++) {
            lvs[`reworking_pod_port`] = list1[i]['port']
            lvs[`reworking_pod_arrival_estimate`] = list1[i]['arrival_estimate']
            lvs[`reworking_pod_arrival`] = list1[i]['arrival']
            lvs[`reworking_pod_departure_estimate`] = list1[i]['departure_estimate']
            lvs[`reworking_pod_departure`] = list1[i]['departure']
            lvs[`reworking_pod_vessel_name`] = list1[i]['vessel_name']
            lvs[`reworking_pod_vessel_number`] = list1[i]['vessel_number']
            lvs[`reworking_pod_destuffing`] = list1[i]['rw_destuffing']
            lvs[`reworking_pod_restuffing`] = list1[i]['rw_restuffing']
            lvs[`reworking_pod_container_number`] = list1[i]['rw_container_number']
        }        
        configStore.modalvisibledata = lvs
        myactionsstore.rwModalVisible = false
    }
    const getUNCodeFromLocationString = (locString) =>{
        console.log(locString)
        if(locString.indexOf('(') != -1 && locString.indexOf(')') != -1){
            return locString
            .trim()
            .slice(locString.lastIndexOf("(") + 1, locString.length - 1);
        }else{
            return locString.trim()
        }
    }
      const isUnLoc2 =  (locString) => {
        locString = getUNCodeFromLocationString(locString);
        return locString.length === 5 && /^[A-Z0-9]+$/.test(locString)
      };
    const add = () =>{
        if(myactionsstore.tsunloc.length == 5 || isUnLoc2(myactionsstore.tsunloc)){
            myactionsstore.isvalid = false
            let l = {'port' : myactionsstore.tsunloc,
            'arrival_estimate':"",
            'arrival':"",
            'departure_estimate':"",
            'departure': "",
            "vessel_name":"",
            "vessel_number":"",
            "rw_destuffing":"",
            "rw_restuffing":"",
            "rw_container_number":"",
            "rw_sob":""
        }
        console.clear()
        console.log(sv)
        if(sv==0){
            setList([l])
            setSv(1)
        }else{
            setList1([l])
            setSv(0)
        }
        myactionsstore.tsunloc = ""
    }else{
        myactionsstore.isvalid = true
    }
}


const del = (id) =>{
    if(id==0){
        setList([])
        setSv(1)
    }else{
        setList1([])
        setSv(0)
    }
}

const up = () =>{
    let l = list1
    setList1(list)
    setList(l)
}

const down = () =>{
    let l = list
    setList(list1)
    setList1(l)
}

 
    let modalClass = 'modal'
    if (myactionsstore.rwModalVisible) modalClass += ' is-active'
    return (
        <>
        {
            (myactionsstore.rwModalVisible)?<div className={modalClass} onClick={() => { myactionsstore.rwModalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px',width:"75rem" }} >
                <section className="modal-card-body" style={{width: "30em", borderRadius:"7px",position:"relative",left:"23em"}} >
                    
                    {((list && list.length<1) || (list1 && list1.length<1))?
       
                        <div className="columns  ">
                            <div class="column is-6 m-0 p-0">
                                <TsUnloc label = 'Reworking Ports' record={myactionsstore} name="tsunloc"/>
                            </div>
                            <div class="column is-5 m-0 p-0" >
                            <select  name="unloc" id="unloc"style={{width:"10em",position:"relative",left:"2em",width:"12em"}}   value={sv}
                                onChange={(e) => {
                                    console.clear()
                                    console.log(e.target.value)
                                    setSv(e.target.value)
                                }} >
                                {
                                    (list && list.length<1)?<option value={0}>After POL</option>:""
                                }
                                {
                                    (list1 && list1.length<1)?<option value={1}>Before POD</option>:""
                                }
                                
                            </select>
                            </div>
                            <div class="column is-1 m-0 p-0">
                            <button  onClick={add} className="button is-success is-inverted pt-0">
                                <span className="icon is-small mt-0 p-0">
                                    <i className="fas fa-plus fas fa-lg"></i>
                                </span>
                            </button>
                            </div>
                            </div>
                            
                       
                    
                    :""
                    
                }

                    {
                    list.map((e,i) =>
                    <>
                    <div className="columns  is-vcentered m-0 p-0">
                    <div class="column is-3 m-0 p-0">
                            <label className="label is-small" style={{ marginBottom: '0px' }}>RW-1</label> 
                    </div>
                    <div class="column is-7 m-0 p-0">
                        <label className="label is-small" style={{ marginBottom: '0px' }}>{e.port}</label> 
                    </div>
                    {
                        (list1.length>0)?<>
                        <div class="column is-1 m-0 p-0">
                        <button className="button is-success is-inverted" onClick={down}>
                        <span class="icon-text has-text-danger">
                            <span class="icon">
                                <i class="fas fa-arrow-down fas fa-lg"></i>
                            </span>
                            </span>
                        </button></div>
                        <div class="column is-1 m-0 p-0">
                                <button className="button is-success is-inverted" onClick={()=>del(0)}>
                                <span class="icon-text has-text-danger">
                                    <span class="icon">
                                        <i class="fas fa-trash fas fa-lg"></i>
                                    </span>
                            </span>
                    </button></div>
                        </>:<><div class="column is-1 m-0 p-0"></div><div class="column is-1 m-0 p-0">
                                <button className="button is-success is-inverted" onClick={()=>del(0)}>
                                <span class="icon-text has-text-danger">
                                    <span class="icon">
                                        <i class="fas fa-trash fas fa-lg"></i>
                                    </span>
                            </span>
                    </button></div></>
                    }
                    </div>
                    <hr className="m-0 p-0"/>
                     </>
)}

{
                    list1.map((e,i) =>
                    <>
                    <div className="columns  is-vcentered m-0 p-0">
                    <div class="column is-3 m-0 p-0">
                            <label className="label is-small" style={{ marginBottom: '0px' }}>RW-2</label> 
                    </div>
                    <div class="column is-7 m-0 p-0">
                        <label className="label is-small" style={{ marginBottom: '0px' }}>{e.port}</label> 
                    </div>
                    {
                        (list.length>0)?<>
                        <div class="column is-1 m-0 p-0">
                        <button className="button is-success is-inverted" onClick={up}>
                        <span class="icon-text has-text-danger">
                            <span class="icon">
                                <i class="fas fa-arrow-up fas fa-lg"></i>
                            </span>
                            </span>
                        </button></div>
                        <div class="column is-1 m-0 p-0">
                                <button className="button is-success is-inverted" onClick={()=>del(1)}>
                                <span class="icon-text has-text-danger">
                                    <span class="icon">
                                        <i class="fas fa-trash fas fa-lg"></i>
                                    </span>
                            </span>
                    </button></div>
                        </>:<><div class="column is-1 m-0 p-0"></div><div class="column is-1 m-0 p-0">
                                <button className="button is-success is-inverted" onClick={()=>del(1)}>
                                <span class="icon-text has-text-danger">
                                    <span class="icon">
                                        <i class="fas fa-trash fas fa-lg"></i>
                                    </span>
                            </span>
                    </button></div></>
                    }
                    <hr className="m-0 p-0"/>
                    </div>
                     </>
)}
                    
                                
                        


                        <button class="button is-warning" onClick={() => myactionsstore.rwModalVisible = false} >cancel</button>
                        <button class="button is-danger" style={{ float: "right" }} onClick={onsubmit3}>Submit</button>
                    </section>
            </div>
        </div>:<></>
        }
        </>
    )
}